
import 'assets/css/sidebar/sidebar.css'
import Logo from 'assets/images/logo/logo.png';
import { FaTimes } from 'react-icons/fa';
import { SidebarData } from 'assets/data/sidebar/SidebarData';
import { Link , useLocation} from "react-router-dom";
import { connect } from "react-redux";

const Sidebar = (props) => {

    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }

    const closeSideBar = () => {
        props.setSideBarStyle({
            left: '-260px',
            transition: 'left .4s ease'
        });
    }

    const location = useLocation();
    const { pathname } = location;
    
    return (
        <div className="sidebar-container">
            <div className='logo-wrap'>
                <a href='/' className='logo'>
                    <img src={Logo} alt="logo..." />
                </a>
                <button className='close-nav' onClick={closeSideBar}>
                    < FaTimes /> 
                </button>
            </div>
            <ul className='sidebar-elements-wrap'>
            {
                SidebarData.map((item, index) => {
                    if(('Anasayfa' === item.title) && can('view-dashboard')){
                            return (
                                    <li key={index}>
                                        <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                            <span className='icon'>{item.icon}</span>
                                            {item.title}
                                        </Link>
                                    </li>
                                )
                    }

                    if(('Hastalar' === item.title) && can('view-hastalar')){
                        return (
                            
                                <li key={index}>
                                <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                    <span className='icon'>{item.icon}</span>
                                    {item.title}
                                </Link>
                            </li>
                            
                        
                        )
                    }


                   if(('Randevular' === item.title) && can('view-randevu')){
                        return (
                                <li key={index}>
                                <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                    <span className='icon'>{item.icon}</span>
                                    {item.title}
                                </Link>
                            </li>
                        )
                   }

                   if(('Giderler' === item.title) && can('view-giderler')){
                        return (
                                <li key={index}>
                                <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                    <span className='icon'>{item.icon}</span>
                                    {item.title}
                                </Link>
                            </li>
                        )
                   }

                   if(('Laboratuvar' === item.title) && can('view-laboratuvar')){
                        return (
                                <li key={index}>
                                <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                    <span className='icon'>{item.icon}</span>
                                    {item.title}
                                </Link>
                            </li>
                        )
                   }

                   if(('Raporlama' === item.title)  && can('view-raporlama')){
                        return (
                                <li key={index}>
                                <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                    <span className='icon'>{item.icon}</span>
                                    {item.title}
                                </Link>
                            </li>
                        )
                   }

                  if(('Stoklar' === item.title) && can('view-stok')){
                        return (
                                <li key={index}>
                                <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                    <span className='icon'>{item.icon}</span>
                                    {item.title}
                                </Link>
                            </li>
                        )
                   }

                  if(('Tanımlamalar' === item.title) && can('view-tanimlamalar')){
                        return (
                                <li key={index}>
                                <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                    <span className='icon'>{item.icon}</span>
                                    {item.title}
                                </Link>
                            </li>
                        )
                  }

                  if(('Kullanıcılar' === item.title) && can('view-kullanicilar')){
                        return (
                                <li key={index}>
                                <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                    <span className='icon'>{item.icon}</span>
                                    {item.title}
                                </Link>
                            </li>
                        )
                    }

                  if(('Sistem Geçmişi' === item.title) && can('view-loglar')){
                    return (
                            <li key={index}>
                            <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                <span className='icon'>{item.icon}</span>
                                {item.title}
                            </Link>
                        </li>
                    )
                } 

                  if(('Ayarlar' === item.title) && can('view-ayarlar')){
                        return (
                                <li key={index}>
                                <Link to={item.link}  className={pathname.split('/')[1].includes(item.link) ? 'active' : ''} >
                                    <span className='icon'>{item.icon}</span>
                                    {item.title}
                                </Link>
                            </li>
                        )
                 } 

                })
            }
            </ul>
        </div>
    );
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions) (Sidebar);