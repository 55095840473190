import * as yup from 'yup';  
import parse from "date-fns/parse";
const CreateComingJobValidationSchema = () => {
    return yup.object().shape({

        laboratuvar_id: yup.number()
                  .min(1,"Lütfen Laboratuvar Seçiniz.")
                  .required("Lütfen Laboratuvar Seçiniz."),

        hasta_id: yup.number()
                  .min(1,"Lütfen Hasta Seçiniz.")
                  .required("Lütfen Hasta Seçiniz."),


        doktor_id: yup.number()
                 .min(1,"Lütfen Doktor Seçiniz.")
                 .required("Lütfen Doktor Seçiniz."),

        islem_id: yup.number()
                 .min(1,"Lütfen İşlem Seçiniz.")
                 .required("Lütfen İşlem Seçiniz."),
             
        // gelis_tarihi: yup.date()
        //                     .transform(function (value, originalValue) {
        //                     if (this.isType(value)) {
        //                         return value;
        //                     }
        //                     const result = parse(originalValue, "dd.MM.yyyy", new Date());
        //                     return result;
        //                     })
        //                     .typeError("Lütfen geçerli bir Geliş Tarihi giriniz.")
        //                     .required(),
    
   });
}   

export default CreateComingJobValidationSchema;

