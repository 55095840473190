import { 
    FaTh,
    FaHospitalUser,
    FaRegCalendarAlt,
    FaExchangeAlt,
    FaFlask,
    FaChartBar,
    FaCog,
    FaTags,
    FaBoxes,
    FaHistory,
    FaUsers
 } from "react-icons/fa";
 import Appointment from 'pages/Appointment';
 import Dashboard from 'pages/Dashboard';
 import DefinitionLayout from 'pages/definition/DefinitionLayout';
 import Expense from 'pages/expense/Expense';
 import Laboratory from 'pages/laboratory/Laboratory';
 import Report from 'pages/Report';
 import Setting from 'pages/Setting';
import Patients from "pages/patients/Patients";
import Loglar from "pages/audit-log/Loglar";
import Users from "pages/users/Users";


export const SidebarData = [
    {
        title: 'Anasayfa',
        icon : <FaTh />,
        link : "anasayfa",
        component: <Dashboard />
    },
    {
        title: 'Hastalar',
        icon : <FaHospitalUser />,
        link : "hastalar",
        component: <Patients />
    },
    {
        title: 'Randevular',
        icon : <FaRegCalendarAlt />,
        link : "randevular",
        component: <Appointment />
    },
    {
        title: 'Giderler',
        icon : <FaExchangeAlt />,
        link : "giderler",
        component: <Expense />
    },
    {
        title: 'Laboratuvar',
        icon : <FaFlask />,
        link : "laboratuvar",
        component: <Laboratory />
    },
    {
        title: 'Raporlama',
        icon : <FaChartBar />,
        link : "raporlama",
        component: <Report />
    },
    {
        title: 'Stoklar',
        icon : <FaBoxes />,
        link : "stoklar",
        component: <Report />
    },
    {
        title: 'Tanımlamalar',
        icon : <FaTags />,
        link : "tanimlamalar",
        component: <DefinitionLayout />
    },
    {
        title: 'Kullanıcılar',
        icon: <FaUsers />,
        link: "kullanicilar",
        component: <Users />
    },
    {
        title: 'Sistem Geçmişi',
        icon : <FaHistory />,
        link : "loglar",
        component: <Loglar />
    },
    {
        title: 'Ayarlar',
        icon : <FaCog />,
        link : "ayarlar",
        component: <Setting />
    }
];