import React from 'react'; 
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LoadingImg from 'assets/images/loading.gif';

const LoadingOverlay = () => {
    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '20px',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));


    return (
        <StyledGridOverlay>
            <img src={LoadingImg} alt="Yükleniyor..." width={50}/>
            <Box sx={{ mt: 1, color: "#1172b6" }}>Yükleniyor...</Box>
        </StyledGridOverlay>
    );
}

export default LoadingOverlay;