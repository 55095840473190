import * as yup from 'yup';

const SettingsAppointmentValidationSchema = () => {
    return yup.object().shape({

        baslangic_saati: yup.string()
            .required("Lütfen geçerli bir Başlangıç Saati bilgisi giriniz.")
            .matches(/(\d){2}:(\d){2}/, 'Lütfen saat tanımına uyuyan ifade kullanınız "SS:DD" formatında olacak'),

        bitis_saati: yup.string()
            .required("Lütfen geçerli bir Bitiş Saati bilgisi giriniz.")
            .matches(/(\d){2}:(\d){2}/, 'Lütfen saat tanımına uyuyan ifade kullanınız "SS:DD" formatında olacak'),

        yenileme_suresi: yup.string()
            .required("Lütfen geçerli bir Yenilenme Süresi giriniz."),

    });
}

export default SettingsAppointmentValidationSchema;