import dayjs from 'dayjs';
const ConvertGlobalDateTime = (date = null) => {
    if(date !== null){
        try {
            let getFullDAte = dayjs(date).get('year')  + "-" + (dayjs(date).get('month') + 1) + "-" +  dayjs(date).get('date') + " " + dayjs(date).get('hour') + ":" + dayjs(date).get('minute');
            return getFullDAte;
        } catch (error) {
            return null;
        }
    }
}

export default ConvertGlobalDateTime;