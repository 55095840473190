import React, { useEffect, useState } from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS } from 'Utils/AppointmentEvents';
import 'assets/css/pages/appointment.css';
import trLocale from '@fullcalendar/core/locales/tr';
import { FormControl, TextField, MenuItem, Autocomplete, Tooltip, Checkbox, Switch, Alert } from '@mui/material';
import { useFormik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaExternalLinkAlt,
    FaTimes,
    FaTrash,
} from "react-icons/fa";
import CreateAppointmentValidationSchema from "business-layer/CreateAppointmentValidationSchema";
import axios from "axios";
import AxiosCrudActions from "services/AxiosCrudCore";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import GetSignFromMeetingStatus from "extensions/GetSignFromMeetingStatus";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { tr } from 'date-fns/locale'
import ConvertGlobalDateTime from "extensions/ConvertGlobalDateTime";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import GetAppointmentStatusValue from "extensions/GetAppointmentStatusValue";
import { Link } from "react-router-dom";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GetDateWithFormat from "extensions/GetDateWithFormat";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const Appointment = (props) => {
    const { auth } = props;
    const can = (permission) => {
        return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
    }
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const MySwal = withReactContent(Swal);
    const [allEvents, setAllEvents] = useState([]);

    //const [currentEvents, setCurrentEvents] = useState([]);
    // modal informations
    const [appointmentId, setAppointmentId] = useState(0);
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal) };
    const [patients, setPatients] = useState([]);
    const [patient, setPatient] = useState(null);
    const [patientInputValue, setPatientInputValue] = useState('');
    const [doctors, setDoctors] = useState([]);
    const [doctor, setDoctor] = useState(null);
    const [doctorInputValue, setDoctorInputValue] = useState('');
    const [treatments, setTreatments] = useState([]);
    const [treatment, setTreatment] = useState(null);
    const [treatmentInputValue, setTreatmentInputValue] = useState('');
    const [updateAppointmentsTable, setUpdateAppointmentsTable] = useState(false);
    const [calendar, setCalendar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedDoctors, setSelectedDoctors] = useState([]);
    const [filterDayDate, setFilterDayDate] = useState(new Date());
    const [isProcesssing, setIsProcessing] = useState(false);
    const [haveDoktorNotification, setHaveDoktorNotification] = useState(false);
    const [filterDate, setFilterDate] = useState({
        start: null,
        end: null
    });


    const [treatmentSetting, setTreatmentSetting] = useState(null);

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        if (!checked) {
            setSelectedDoctors(doctors);
        } else {
            setSelectedDoctors([]);
        }
        setChecked(event.target.checked);
    };

    let calendarRef = React.createRef();

    const handleDateSelect = (selectInfo) => {
        let calendarApi = selectInfo.view.calendar
        calendarApi.unselect() // clear date selection
        if (selectInfo?.view?.type && selectInfo.view.type !== 'dayGridMonth') {
            setCalendar({
                start: selectInfo.start,
                end: selectInfo.end,
                allDay: selectInfo.allDay
            })
            addNewAppointment();
        }
        else if (selectInfo?.view?.type && selectInfo.view.type === 'dayGridMonth') {
            MySwal.fire({
                title: "UYARI!",
                text: "Aylık görünümde randevu veremezsiniz.",
                icon: 'warning',
            })
        }
    }

    const handleEventDrop = (dropEvent) => {
        MySwal.fire({
            title: "Randevu Güncelleme",
            text: dropEvent?.event?.extendedProps?.patient?.hasta + " adlı hastanın randevu tarihi " + GetTrFullDateAndTimeWithFormat(dropEvent?.event?.start) + " olarak güncellensin mi?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Güncelle',
            cancelButtonText: 'Hayır,Vazgeç'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const updatedEvent = {
                    baslangic_tarihi: ConvertGlobalDateTime(dropEvent?.event?.start),
                    bitis_tarihi: ConvertGlobalDateTime(dropEvent?.event?.end),
                    hasta_id: dropEvent?.event?.extendedProps?.patient?.id,
                    doktor_id: dropEvent?.event?.extendedProps?.doctor?.id,
                    randevu_tedavi_id: dropEvent?.event?.extendedProps?.treatment?.id,
                    id: dropEvent?.event?.extendedProps?.dataId
                }
                AxiosCrudActions.UpdateAsync(`randevular/${dropEvent?.event?.extendedProps?.dataId}`, updatedEvent).then(result => {
                    if (result?.data?.status) {
                        setUpdateAppointmentsTable(!updateAppointmentsTable);
                    }
                });
            }
            else {
                dropEvent.revert();
            }
        })




        // values.baslangic_tarihi = ConvertGlobalDateTime(calendar?.start);
        // values.bitis_tarihi = ConvertGlobalDateTime(calendar?.end);
        // values.hasta_id = patient?.id;
        // values.doktor_id = doctor?.id;
        // values.randevu_tedavi_id = treatment?.id;
        // delete values.hasta_tipi;

        // AxiosCrudActions.UpdateAsync(`randevular/${appointmentId}`, values).then(result => {
        //     if (result?.data?.status) {
        //         setUpdateAppointmentsTable(!updateAppointmentsTable);
        //         toggle();
        //     }
        // });
    }

    const handleResize = (resizeEvent) => {

        MySwal.fire({
            title: "Randevu Güncelleme",
            text: resizeEvent?.event?.extendedProps?.patient?.hasta +
                " adlı hastanın randevu tarihi " +
                GetTrFullDateAndTimeWithFormat(resizeEvent?.event?.start) +
                " - " +
                GetTrFullDateAndTimeWithFormat(resizeEvent?.event?.end) +
                " olarak güncellensin mi?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Güncelle',
            cancelButtonText: 'Hayır,Vazgeç'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const updatedEvent = {
                    baslangic_tarihi: ConvertGlobalDateTime(resizeEvent?.event?.start),
                    bitis_tarihi: ConvertGlobalDateTime(resizeEvent?.event?.end),
                    hasta_id: resizeEvent?.event?.extendedProps?.patient?.id,
                    doktor_id: resizeEvent?.event?.extendedProps?.doctor?.id,
                    randevu_tedavi_id: resizeEvent?.event?.extendedProps?.treatment?.id,
                    id: resizeEvent?.event?.extendedProps?.dataId
                }
                AxiosCrudActions.UpdateAsync(`randevular/${resizeEvent?.event?.extendedProps?.dataId}`, updatedEvent).then(result => {
                    if (result?.data?.status) {
                        setUpdateAppointmentsTable(!updateAppointmentsTable);
                    }
                });
            }
            else {
                resizeEvent.revert();
            }
        })
    }

    const handleEventClick = (clickInfo) => {
        editNewAppointment(clickInfo);
    }

    const renderEventContent = (eventInfo) => {
        const isTitle = !eventInfo?.event?.overlap && eventInfo?.event?.extendedProps?.patient;
        const bGColor = eventInfo?.event?.overlap || eventInfo?.event?.extendedProps?.patient ? eventInfo?.event?.backgroundColor : '#1172b6';
        return (
            <Tooltip arrow
                style={{ backgroundColor: 'red' }}
                title=
                {
                    isTitle &&
                    <>
                        <div className="appointment-info-tooltip-wrap"><span className="appointment-info-tooltip">Hasta:</span>{eventInfo?.event?.extendedProps?.patient?.hasta}</div>
                        <div className="appointment-info-tooltip-wrap"><span className="appointment-info-tooltip">Durum:</span>{eventInfo?.event?.extendedProps?.appointmentStatus}</div>
                        <div className="appointment-info-tooltip-wrap"><span className="appointment-info-tooltip">Doktor:</span>{eventInfo?.event?.extendedProps?.doctor?.name}</div>
                        <div className="appointment-info-tooltip-wrap"><span className="appointment-info-tooltip">Tedavi:</span>{eventInfo?.event?.extendedProps?.treatment?.aciklama}</div>
                        <div className="appointment-info-tooltip-wrap">
                            <span className="appointment-info-tooltip">Başlangıç:</span>{eventInfo?.event?.extendedProps?.startStr}<br />
                            <span className="appointment-info-tooltip">Bitiş:</span>{eventInfo?.event?.extendedProps?.endStr}
                        </div>
                        <div className="appointment-info-tooltip-wrap"><span className="appointment-info-tooltip">Telefon:</span>{eventInfo?.event?.extendedProps?.patient?.tel_no}</div>
                    </>
                }>
                <div className="fc-event-cstm-style" style={{
                    backgroundColor: bGColor,
                    color: '#fff',
                    borderColor: bGColor,
                }}>
                    <span className="time">{eventInfo.timeText}</span>
                    <span className="title">{eventInfo.event.title}</span>
                    <span className="status">{eventInfo?.event?.extendedProps?.status}</span>
                </div>
            </Tooltip>
        )
    }

    const handleDatesSet = (dates) => {
        setFilterDate({
            start: GetDateWithFormat(dates?.start),
            end: GetDateWithFormat(dates?.end)
        })
    }

    const handleFilterDayDate = (dayDate) => {
        let nextDay = new Date(dayDate);
        nextDay.setDate(dayDate.getDate() + 1);
        setFilterDate({
            start: GetDateWithFormat(dayDate),
            end: GetDateWithFormat(nextDay)
        });
        calendarRef.current.getApi().changeView('timeGridDay', dayDate);
    }

    const initialAppointmentInfo = {
        hasta_id: 0,
        doktor_id: 0,
        randevu_tedavi_id: 0,
        not: '',
        randevu_durumu: null,
        sure: 15,
        hasta_tipi: 2
    }

    // yup schema
    let appointmentValidationSchema = CreateAppointmentValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialAppointmentInfo,
        validationSchema: appointmentValidationSchema,
        onSubmit: values => {
            values.baslangic_tarihi = ConvertGlobalDateTime(calendar?.start);
            values.bitis_tarihi = ConvertGlobalDateTime(calendar?.end);
            values.hasta_id = patient?.id;
            values.doktor_id = doctor?.id;
            values.randevu_tedavi_id = treatment?.id;
            delete values.hasta_tipi;
            setIsProcessing(true);
            if (appointmentId === 0) {
                AxiosCrudActions.CreateAsync('randevular', values).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateAppointmentsTable(!updateAppointmentsTable);
                        toggle();
                    }
                });
            }
            else if (appointmentId > 0) {
                AxiosCrudActions.UpdateAsync(`randevular/${appointmentId}`, values).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateAppointmentsTable(!updateAppointmentsTable);
                        toggle();
                    }
                });
            }
        },
    });

    const addNewAppointment = () => {
        formik.setValues({
            hasta_id: 0,
            doktor_id: 0,
            randevu_tedavi_id: treatmentSetting?.varsayilan_tedavi?.id,
            not: '',
            randevu_durumu: 4,
            hasta_tipi: 2
        }, false)
        formik.setErrors({});
        setAppointmentId(0);
        setPatient(null);
        setDoctor(null);
        setTreatment(treatmentSetting?.varsayilan_tedavi);
        toggle();
    }

    const editNewAppointment = (clickInfo) => {
        // console.log(clickInfo);
        const values = clickInfo?.event?.extendedProps;
        const eventInfo = clickInfo?.event;
        setCalendar({
            start: eventInfo.start,
            end: eventInfo.end,
            allDay: eventInfo.allDay
        });
        formik.setValues({
            not: values?.not ? values?.not : '',
            randevu_durumu: values?.appointmentStatus ? GetAppointmentStatusValue(values.appointmentStatus) : 4,
            hasta_tipi: 2,
            doktor_id: values?.doctor?.id ? values?.doctor?.id : '',
            hasta_id: values?.patient?.id ? values?.doctor?.id : '',
            randevu_tedavi_id: values?.treatment?.id ? values?.treatment?.id : '',
        }, false)
        formik.setErrors({});
        setAppointmentId(values?.dataId);
        setPatient(values?.patient);
        setDoctor(values?.doctor);
        setTreatment(values?.treatment);
        toggle();
    }

    const deleteAppointment = (e) => {
        e.preventDefault();
        MySwal.fire({
            title: patient?.hasta + " adlı hastanın Randevu bilgileri silinsin mi?",
            text: "Silme işlemini gerçekleştirdiğiniz taktirde işlemi geri alamazsınız!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Sil',
            cancelButtonText: 'Hayır,Vazgeç'
        }).then(async (result) => {
            if (result.isConfirmed) {
                AxiosCrudActions.DeleteAsync('/randevular', appointmentId)
                    .then(result => {
                        if (result?.status === 200 && result?.data?.status) {
                            setUpdateAppointmentsTable(!updateAppointmentsTable);
                            toggle();
                        }
                    });

            }
        })

    }
    // declare function for get data from server 

    const getPatients = async () => {
        await axios.get('hastalar', { params: { short: 1, allpatients: auth?.user?.user_permissions?.filter((f) => f === 'view-all-hastalar')?.length ? 1 : 0 } })
            .then(res => {
                if (res?.status === 200) {
                    setPatients(res?.data?.data);
                }
            }).catch(e => {
                setPatients([]);
            })
    }

    const getDoktors = async () => {
        await axios.get('kullanicilar', { params: { doktor: 1 } })
            .then(res => {
                if (res?.status === 200) {
                    setDoctors(res?.data?.data);
                    setSelectedDoctors(res?.data?.data || []);
                    setChecked(true);
                }

                if (res?.data?.data.length === 0) {
                    setLoading(false);
                    setHaveDoktorNotification(true);
                } else {
                    setHaveDoktorNotification(false);
                }
            }).catch(e => {
                setDoctors([]);
            })
    }

    const getAllAppointments = async () => {
        var ids = selectedDoctors.map(option => option.id);
        setLoading(true);

        await axios.get('randevular', {
            params: {
                allpatients: auth?.user?.user_permissions?.filter((f) => f === 'view-all-hastalar')?.length ? 1 : 0,
                doktorlar: JSON.stringify(ids),
                start: filterDate?.start,
                end: filterDate?.end
            },
        })
            .then(res => {
                if (res?.status === 200) {
                    setAllEvents(INITIAL_EVENTS);
                    res?.data?.data.map((event, i) => {
                        let newEvent = {
                            id: event?.id,
                            start: event?.baslangicTarihi,
                            end: event?.bitisTarihi,
                            allDay: false,
                            title: event?.hasta?.hasta,
                            backgroundColor: (treatmentSetting?.gecmis_randevu_durumu && new Date(event?.bitisTarihi) < new Date()) ?
                                (event?.randevuDurumu === "Gelmedi" ? treatmentSetting?.gelmeyen_hasta_rengi : treatmentSetting?.gecmis_randevu_rengi)
                                :
                                (event?.randevuRengi ? event.randevuRengi : '#1172b6'),
                            borderColor: event?.randevuRengi ? event.randevuRengi : '#1172b6',
                            status: GetSignFromMeetingStatus(event?.randevuDurumu),
                            patient: event?.hasta,
                            doctor: event?.doktor,
                            treatment: event?.randevuTedavi,
                            appointmentStatus: event?.randevuDurumu,
                            randevuRengi: event?.randevuRengi,
                            dataId: event?.id,
                            not: event?.not,
                            startStr: GetTrFullDateAndTimeWithFormat(event?.baslangicTarihi),
                            endStr: GetTrFullDateAndTimeWithFormat(event?.bitisTarihi)
                        }
                        setAllEvents((array) => [...array, newEvent]);
                    })
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                }
            }).catch(e => {
                setAllEvents(INITIAL_EVENTS);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            })
    }

    const getTreatmentSettings = async () => {
        await axios.get('ayarlar/randevu-ayarlari')
            .then(res => {
                if (res?.status === 200) {
                    setTreatmentSetting(res?.data?.data);
                }
            }).catch(e => {
                setTreatmentSetting(null);
            })
    }

    const getTreatment = async () => {
        await axios.get('randevu-tedaviler')
            .then(res => {
                if (res?.status === 200) {
                    setTreatments(res?.data?.data);
                }
            }).catch(e => {
                setTreatments([]);
            })
    }

    useEffect(() => {
        getPatients();
        getDoktors();
        getTreatment();
        getTreatmentSettings();
    }, []);

    useEffect(() => {
        if (selectedDoctors !== null && selectedDoctors.length > 0 && filterDate !== null && filterDate?.start && filterDate?.end && treatmentSetting !== null) {
            getAllAppointments();
        }
    }, [updateAppointmentsTable, selectedDoctors, filterDate])

    return (
        <>
            {can('view-randevu') ?
                <div className='appointment-container'>
                    {
                        isProcesssing ? <div style={{ width: '100%', height: '100vh', position: 'fixed', top: '0', left: '0', bottom: '0', right: '0', overflow: "hidden", zIndex: 99999, background: 'transparent' }}></div> : null
                    }
                    {
                        haveDoktorNotification === true ? <Alert severity="warning">Sistemde ekli doktor olmadığından randevu alınamaz!</Alert> : null
                    }
                    <div className="row mb-4 mt-3">


                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <FormControl fullWidth>
                                <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                    <DesktopDatePicker
                                        variant='outlined'
                                        label="Tarih"
                                        minDate={dayjs('01-01-1930')}
                                        inputFormat="dd-MM-yyyy"
                                        value={filterDayDate}
                                        onChange={(newValue) => {
                                            setFilterDayDate(newValue);
                                            handleFilterDayDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            size="small"
                                            name="filterDayDate"
                                            variant='outlined'
                                        />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                        {auth?.user?.user_permissions?.filter((f) => f === 'view-all-hastalar')?.length ? <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-md-8">
                                    <Autocomplete
                                        multiple
                                        size="small"
                                        id="doctorsAutocomplete"
                                        options={doctors}
                                        limitTags={2}
                                        value={selectedDoctors}
                                        disableCloseOnSelect
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        getOptionLabel={(option) => option?.name + ' - ' + option?.brans}
                                        onChange={(e, selectedValues) => {
                                            e.preventDefault();
                                            if (selectedValues.length !== doctors.length) {
                                                setChecked(false);
                                            } else {
                                                setChecked(true);
                                            }
                                            setSelectedDoctors(selectedValues);
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props} key={option?.id}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option?.name} - {option?.brans}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Doktor" placeholder="Doktor" />
                                        )}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <span>
                                        <Switch
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </span>
                                    <span>Tümünü Seç</span>
                                </div>
                            </div>
                        </div> : <></>}
                    </div>

                    <FullCalendar
                        height={'auto'}
                        locale={trLocale}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'timeGridDay,timeGridWeek,dayGridMonth',
                        }}
                        dayMaxEvents={true}
                        initialView='timeGridWeek'
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        slotMinTime={treatmentSetting?.baslangic_saati ? treatmentSetting?.baslangic_saati : "9:00:00"}
                        slotMaxTime={treatmentSetting?.bitis_saati ? treatmentSetting?.bitis_saati : "18:00:00"}
                        weekends={true}
                        events={allEvents}
                        initialEvents={INITIAL_EVENTS}
                        select={handleDateSelect}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}
                        eventDrop={handleEventDrop}
                        eventResize={handleResize}
                        slotDuration={{ minutes: treatmentSetting?.zaman_araligi ? parseInt(treatmentSetting.zaman_araligi) : 10 }}
                        slotLabelFormat={{
                            hour: "2-digit",
                            minute: "2-digit",
                            meridiem: false,
                            hour12: false,
                        }}
                        nowIndicator
                        datesSet={handleDatesSet}
                        ref={calendarRef}
                    />
                    {
                        loading &&
                        <div id="appointment-pre-loading-content">
                            <div className="loading">
                                <LoadingOverlay />
                            </div>
                        </div>
                    }

                    <Modal isOpen={modal}
                        toggle={toggle}
                        size="md"
                        modalTransition={{ timeout: 1 }}
                        backdropTransition={{ timeout: 1 }}
                    >
                        <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Randevu Bilgileri</ModalHeader>
                        <form onSubmit={formik.handleSubmit}>
                            <ModalBody>
                                <div className="row pr-1 pl-1 d-flex justify-content-center">
                                    {
                                        // appointmentId > 0 &&
                                        // <>
                                        //     <div className="d-flex justify-content-center align-items-center mb-4 mt-2">
                                        //         <Link to={`/hastalar/detay/${patient?.id}/tedavi`} style={{textDecoration: 'none'}}><Button className="cstm-fast-access-btn" variant="contained" color="primary">Hasta &nbsp;<FaExternalLinkAlt /></Button></Link>&nbsp;&nbsp;
                                        //         <Link to={`/hastalar/detay/${patient?.id}/tedavi`} style={{textDecoration: 'none'}}><Button className="cstm-fast-access-btn" variant="contained" color="secondary">Tedavi &nbsp;<FaExternalLinkAlt /></Button></Link>&nbsp;&nbsp;
                                        //         <Link to={`/hastalar/detay/${patient?.id}/hesap`} style={{textDecoration: 'none'}}><Button className="cstm-fast-access-btn" variant="contained" color="danger" >Hesap &nbsp;<FaExternalLinkAlt /></Button></Link>&nbsp;&nbsp;
                                        //         <Link to={`/hastalar/detay/${patient?.id}/randevu`} style={{textDecoration: 'none'}}><Button className="cstm-fast-access-btn" variant="contained" color="success">Randevu &nbsp;<FaExternalLinkAlt /></Button></Link>
                                        //     </div>
                                        // </>
                                    }
                                    <div className="col-11 ">
                                        <div className='pb-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name='hasta_tipi'
                                                    select
                                                    size="small"
                                                    InputLabelProps={{ style: { color: '#1976d2' } }}
                                                    label="Hasta Tipi"
                                                    defaultValue={formik.values.hasta_tipi === 1 ? 1 : 2}
                                                    value={formik.values.hasta_tipi === 1 ? 1 : 2}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    <MenuItem value={1}>Yeni Hasta</MenuItem>
                                                    <MenuItem value={2}>Kayıtlı Hasta</MenuItem>
                                                </TextField>
                                            </FormControl>
                                        </div>
                                        <div className="pb-3">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    options={patients}
                                                    loading={true}
                                                    getOptionLabel={option => option.hasta + (option?.hasta !== undefined ? ' - ' + option?.tc_no : '')}
                                                    noOptionsText={'Seçenek Bulunamadı'}
                                                    value={patient}
                                                    name="hasta_id"
                                                    disableClearable
                                                    label="Hasta"
                                                    onChange={(event, newValue) => {
                                                        event.preventDefault();
                                                        setPatient(newValue);
                                                        formik.setFieldValue('hasta_id', newValue.id, false);
                                                    }}
                                                    inputValue={patientInputValue}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    onInputChange={(event, newInputValue) => { setPatientInputValue(newInputValue); }}
                                                    size="small"
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {option?.hasta + (option?.tc_no !== undefined ? ' - ' + option?.tc_no : '')}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField {...params}
                                                        label="Hasta"
                                                        error={formik.errors.hasta_id && formik.touched.hasta_id}
                                                        helperText={formik.errors.hasta_id && formik.touched.hasta_id && <span className='cstm-input-error-validation-msg'>{formik.errors.hasta_id}</span>}
                                                    />}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="pb-3">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    options={doctors}
                                                    loading={true}
                                                    getOptionLabel={option => option.name + (option?.brans !== undefined ? ' - ' + option?.brans : '')}
                                                    noOptionsText={'Seçenek Bulunamadı'}
                                                    value={doctor}
                                                    name="doktor_id"
                                                    disableClearable
                                                    label="Doktor"
                                                    onChange={(event, newValue) => {
                                                        event.preventDefault();
                                                        setDoctor(newValue);
                                                        formik.setFieldValue('doktor_id', newValue.id, false);
                                                    }}
                                                    inputValue={doctorInputValue}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    onInputChange={(event, newInputValue) => { setDoctorInputValue(newInputValue); }}
                                                    size="small"
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {option.name + (option?.brans !== undefined ? ' - ' + option?.brans : '')}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField {...params}
                                                        label="Doktor"
                                                        error={formik.errors.doktor_id && formik.touched.doktor_id}
                                                        helperText={formik.errors.doktor_id && formik.touched.doktor_id && <span className='cstm-input-error-validation-msg'>{formik.errors.doktor_id}</span>}
                                                    />}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="pb-3">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    options={treatments}
                                                    loading={true}
                                                    getOptionLabel={option => option?.aciklama + ' - ' + option?.sure}
                                                    noOptionsText={'Seçenek Bulunamadı'}
                                                    value={treatment}
                                                    name="randevu_tedavi_id"
                                                    disableClearable
                                                    label="Tedavi"
                                                    onChange={(event, newValue) => {
                                                        event.preventDefault();
                                                        setTreatment(newValue);
                                                        formik.setFieldValue('randevu_tedavi_id', newValue.id, false);
                                                    }}
                                                    inputValue={treatmentInputValue}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    onInputChange={(event, newInputValue) => { setTreatmentInputValue(newInputValue); }}
                                                    size="small"
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {option?.aciklama + ' - ' + option?.sure}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField {...params}
                                                        label="Tedavi"
                                                        error={formik.errors.randevu_tedavi_id && formik.touched.randevu_tedavi_id}
                                                        helperText={formik.errors.randevu_tedavi_id && formik.touched.randevu_tedavi_id && <span className='cstm-input-error-validation-msg'>{formik.errors.randevu_tedavi_id}</span>}
                                                    />}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="pb-3">
                                            <FormControl fullWidth>
                                                <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                                    <DesktopDatePicker
                                                        id="baslangic_tarihi"
                                                        variant='outlined'
                                                        label="Randevu Başlangıç"
                                                        minDate={dayjs('01-01-1930')}
                                                        inputFormat="dd-MM-yyyy HH:mm"
                                                        value={calendar?.start}
                                                        onChange={(newValue) => {
                                                            setCalendar((calendar) => ({ ...calendar, start: newValue }));
                                                            formik.setFieldValue('baslangic_tarihi', newValue, false);
                                                        }}
                                                        renderInput={(params) => <TextField {...params}
                                                            size="small"
                                                            name="baslangic_tarihi"
                                                            variant='outlined'
                                                        // error={formik.errors.baslangic_tarihi && formik.touched.baslangic_tarihi}
                                                        // helperText={formik.errors.baslangic_tarihi && formik.touched.baslangic_tarihi && <span className='cstm-input-error-validation-msg'>{formik.errors.baslangic_tarihi}</span>}
                                                        />}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                        <div className="pb-3">
                                            <FormControl fullWidth>
                                                <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                                    <DesktopDatePicker
                                                        id="bitis_tarihi"
                                                        variant='outlined'
                                                        label="Randevu Bitiş"
                                                        minDate={calendar?.start}
                                                        inputFormat="dd-MM-yyyy HH:mm"
                                                        value={calendar?.end}
                                                        onChange={(newValue) => {
                                                            setCalendar((calendar) => ({ ...calendar, end: newValue }));
                                                            formik.setFieldValue('bitis_tarihi', newValue, false);
                                                        }}
                                                        renderInput={(params) => <TextField {...params}
                                                            size="small"
                                                            name="bitis_tarihi"
                                                            variant='outlined'
                                                        // error={formik.errors.bitis_tarihi && formik.touched.bitis_tarihi}
                                                        // helperText={formik.errors.bitis_tarihi && formik.touched.bitis_tarihi && <span className='cstm-input-error-validation-msg'>{formik.errors.bitis_tarihi}</span>}
                                                        />}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                        <div className="pb-3">
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="not"
                                                    variant='outlined'
                                                    label="Not"
                                                    autoComplete="off"

                                                    onChange={formik.handleChange}
                                                    value={formik.values.not}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                />
                                            </FormControl>
                                        </div>
                                        {
                                            appointmentId > 0 &&
                                            <>
                                                <div className='pb-3'>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            name='randevu_durumu'
                                                            select
                                                            size="small"
                                                            InputLabelProps={{ style: { color: '#1976d2' } }}
                                                            label="Randevu Durumu"
                                                            value={formik.values.randevu_durumu}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}>
                                                            <MenuItem value={1}>+ Geldi</MenuItem>
                                                            <MenuItem value={2}>Bekliyor</MenuItem>
                                                            <MenuItem value={3}>Tedavide</MenuItem>
                                                            <MenuItem value={4}>- Gelmedi</MenuItem>
                                                        </TextField>
                                                    </FormControl>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-between">
                                <div>
                                    {
                                        appointmentId > 0 &&
                                        <Button color="danger" type="button" onClick={deleteAppointment} disabled={!can('delete-randevu')} className="d-flex align-items-center">
                                            <FaTrash />&nbsp;Sil
                                        </Button>
                                    }
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <Button color="warning" style={{ color: 'white' }} type="button" onClick={toggle} className="d-flex align-items-center">
                                        <FaTimes />&nbsp;Vazgeç
                                    </Button>&nbsp;&nbsp;
                                    <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={appointmentId > 0 ? !can('edit-randevu') : !can('create-randevu')} className="d-flex align-items-center" >
                                        {
                                            isProcesssing ?
                                                (
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                        İşleniyor...
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <FaCloudUploadAlt /> &nbsp;
                                                        {appointmentId > 0 ? "Güncelle" : "Kaydet"}
                                                    </>
                                                )
                                        }
                                    </Button>
                                </div>
                            </ModalFooter>
                        </form>
                    </Modal>
                </div>
                : <NotPermission />}
        </>

    )
}
const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};
export default connect(authPermissions)(Appointment);
