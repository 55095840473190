
import React from 'react';
import 'assets/css/not-found/not-found.css';
import NotFoundImage from 'assets/images/not-found/not-found.png';

const NotFound = () => {
    return (
        <div className='not-found-container'>
            <div className='not-found-wrap'>
                <div className='not-found'>
                    <img src={NotFoundImage} alt="Not-Found..." />
                </div>
            </div>
        </div>
    )
}

export default NotFound;