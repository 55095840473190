import { useState } from 'react';
import 'assets/css/auth/login.css';
import Logo from 'assets/images/logo/logo.png';
import LoginIllustration from 'assets/images/illustration/12.png';
import {FaRegEyeSlash,FaRegEye,FaCheck} from 'react-icons/fa';
import { connect } from 'react-redux';
import { LoginAuthAction } from 'redux/actions/AuthAction';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import LoginValidationSchema from 'business-layer/AuthValidationSchema';

const Login = (props) => {

    // const {user,login} = props; redux tan  gelen state i user  ile alırsın
    const {login} = props;
    const [showPassword, setShowPassword] = useState(false);
    let navigate = useNavigate();

    // functions
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    // yup schema
    let validationSchema = LoginValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit: values => {
           login(values, navigate);
        },
    });
    

    return (
        <div className="login-page-wrapper">
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-xl-8 col-lg-7">
                        <div className="img-container">
                            <img src={LoginIllustration} alt="Login illustration" />
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-12">
                        <div className="form-container">
                            <div className="form-wrap">
                                <div className="logo"> 
                                    <img src={Logo} alt="logo..." />
                                </div>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <label >Email</label>
                                        <input 
                                            type="email"
                                            name="email"
                                            className="form-control cstm-inpt"
                                            autoComplete="off"
                                            placeholder="john@example.com"
                                            onChange={formik.handleChange}
                                            autoFocus={true}
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.errors.email && formik.touched.email &&  <span className="validation-error">{formik.errors.email}</span>}
                                    </div>
                                    <div className="form-group ">
                                        <label >Password</label>
                                        <div className="password-area">
                                            <input 
                                                name="password"
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                                type={showPassword ? "text": "password"}
                                                autoComplete="off"
                                                className="form-control cstm-inpt"
                                                placeholder="********" 
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.errors.password && formik.touched.password && <span className="validation-error">{formik.errors.password}</span>}
                                            <button type='button' className="toggle-password" onClick={togglePassword}>
                                                {showPassword ? <FaRegEye /> : <FaRegEyeSlash /> }
                                            </button>
                                        </div>
                                        
                                    </div>
                                    <div className="form-group rem-me-area">
                                        <input type="checkbox" className="form-check-input cstm-inpt"/> 
                                        <label className="rem-me"> BENİ HATIRLA</label>
                                    </div>
                                    <button type="submit" disabled={!formik.isValid || !formik.dirty} className="submit-btn button-click-animate"><FaCheck /> Giriş Yap</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.authState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (userState,navigate) => {
            dispatch(LoginAuthAction(userState,navigate))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);