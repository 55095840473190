import 'assets/css/pages/patient-detail-treatment.css';
import { useEffect, useMemo, useRef, useState,useReducer } from "react";
import axios from 'axios';
import { Button as ButtonM, Table, TableBody, TableCell, TableContainer, TableHead, TableRow , Paper, FormControl, TextField, Autocomplete, MenuItem, Box, Tab, Stack, Tooltip, Checkbox, InputAdornment, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import {TabList, TabContext, TabPanel } from '@mui/lab';
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import { useLocation, useParams } from 'react-router-dom';
import { FaCloudUploadAlt, FaEdit, FaLiraSign, FaLock, FaMapMarkerAlt, FaPercentage, FaTimes, FaTrash, FaTrashAlt, FaExchangeAlt } from 'react-icons/fa';
import GetTrDate from 'extensions/GetTRDate';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from 'services/AxiosCrudCore';
import { InitialToothState } from './InitialToothState';
import { StringParseWithSignToArray } from 'extensions/StringParseWithSignToArray';
import { IsExistToothImagePath } from 'extensions/IsExistImagePath';
import TopChinImg from 'assets/images/TopBottomChin/oral-ust-cene.svg';
import BottomChinImg from 'assets/images/TopBottomChin/oral-alt-cene.svg';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import EditTreatmentValidationSchema from 'business-layer/EditTreatmentValidationSchema';
import { useFormik } from 'formik';
import CurrencyMask from 'Utils/CurrencyMask';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {el, tr} from 'date-fns/locale'
import RateMask from 'Utils/RateMask';
import GetDateWithFormat from 'extensions/GetDateWithFormat';
import ConvertStringToFloat from "extensions/ConvertStringToFloat";
import { useNavigate } from 'react-router-dom';
import { ConvertTurkishToEnglishCharacter } from 'extensions/ConvertTurkishToEnglishCharacter';
import ConvertFloatToStringTRFormat from 'extensions/ConvertFloatToStringTRFormat';
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const PatientTreatment = (props) => {
    const { auth } = props;
    const can = (permission) => {
        return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
    }
    const navigate = useNavigate(); 
    const MySwal = withReactContent(Swal);
    const params = useParams();
    const location = useLocation();
    
    const patientId = params?.patientId;
    const doctorNoteInputRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [isProcesssingDoctorNote, setIsProcessingDoctorNote] = useState(false);
    const [isProcesssingTreatmentEdit, setIsProcessingTreatmentEdit] = useState(false);
    // doctor note modal 
    const [doctorNoteModalState, setDoctorNoteModalState] = useState(false);
    const [doctorNote, setDoctorNote] = useState(''); 
    const handleDoctorNoteModalToggle = () => setDoctorNoteModalState(!doctorNoteModalState);

    // doktorlar
    const [doktors, setDoktors] = useState([]);
    const [doktorValue, setDoktorValue] = useState(null);
    const [doktorInputValue, setDoktorInputValue] = useState('');
    // tarifeler
    const [tarifeler, setTarifeler] = useState([]);
    const [tarifeValue, setTarifeValue] = useState(null);
    const [tarifeInputValue, setTarifeInputValue] = useState(''); 
    // tedaviler
    const [tedaviler, setTedaviler] = useState([]);
    const [filteredTedaviler, setFilteredTedaviler] = useState([]);
    const [selectedTedavi, setSelectedTedavi] = useState(null);
    // loading animation
    const [loadingTreatments, setLoadingTreatments] = useState(true);
    const [treatmentsTotalAmount, setTreatmentsTotalAmount] = useState(0);
    // selected tedavi tipi
    const [selectedTedaviType, setSelectedTedaviType] = useState(2);
    // tab value
    const [tabValue, setTabValue] = useState('1');
    // patient made treatment list
    const [patientMadeTreatmentsList, setPatientMadeTreatmentsList] = useState([]);
    // updatePatientMadeTreatmentList state for update list
    const [updatePatientMadeTreatmentList, setUpdatePatientMadeTreatmentList] = useState(false);
    // 
    const topDaimitHead = ['18','17','16','15','14','13','12','11','21','22','23','24','25','26','27','28'];
    const subDaimitHead = ['48','47','46','45','44','43','42','41','31','32','33','34','35','36','37','38'];
    const topSuttHead   = ['55','54','53','52','51','61','62','63','64','65'];
    const subSuttHead   = ['85','84','83','82','81','71','72','73','74','75'];
    // tooth States 
    const [topDaimiDislerState, setTopDaimiDislerState] = useState(InitialToothState.initialTopDaimiDisler);
    const [BottomDaimiDislerState, setBottomDaimiDislerState] = useState(InitialToothState.initialBottomDaimiDisler);
    const [topSutDislerState, setTopSutDislerState] = useState(InitialToothState.initialTopSutDisler);
    const [bottomSutDislerState, setBottomSutDislerState] = useState(InitialToothState.initialBottomSutDisler);

    // koklu dis checkbox secimi
    const [handleTreatmentWithModal, setHandleTreatmentWithModal] = useState(false);
    const [kokluDisInfo, setKokluDisInfo] = useState(null); 
    const handleTreatmentWithModalToggle = () => setHandleTreatmentWithModal(!handleTreatmentWithModal);
    const [handleTreatmentModalSize, setHandleTreatmentModalSize] = useState("sm");

    const [bolumluProtezDisInfo, setBolumluProtezDisInfo] = useState(null);

    // static treatment code
    const disCekimiTreatmentCode = ["05-001-001","05-002-001","05-003-001","05-004-001"];
    const tumCeneDislerTreatmentCode = ["07-034-001","07-038-001","08-019-001"];
    // koklu dis modal
    const handleToothWithModalTreatmentCode = ["02-013-001","02-014-001","02-015-001","02-017-001","02-018-001","02-019-001","05-005-001","05-006-001","05-007-001","02-036-001"]; 
    const protezTreatmentCode = ["04-001-001","04-003-001","05-059-001"];  
    // 16 li modal
    const bolumluProtezTreatmentCode = ["04-002-001","04-004-001","04-017-001","04-036-001","04-007-001","04-031-001"];
    // implant cikartilmasi
    const implantCikartilmasiCode = ["05-035-001","05-036-001","05-052-001"];
    // default images codes
    const defaultImagesCode = ["05-057-001","05-058-001"];

    // edit treatment modal state
    const [editTreatmentModal, setEditTreatmentModal] = useState(false); 
    const handleEditTreatmentModalToggle = () => setEditTreatmentModal(!editTreatmentModal);
    const [editTreatmentSelectedDoctor, setEditTreatmentSelectedDoctor] = useState(null);
    const [editTreatmentSelectedDoctorInputValue, setEditTreatmentSelectedDoctorInputValue] = useState('');
    
    //edit selected treatments account 
    const [editSelTreatAccModal, setEditSelTreatAccModal] = useState(false);
    const handleEditSelTreatAccModalToggle = () => setEditSelTreatAccModal(!editSelTreatAccModal);
    const [editSelTreatAccProcessing, setEditSelTreatAccProcessing] = useState(false);

    const [selTreatAccState, setSelTreatAccState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            radio: 1,
            deger: 0,
            sum: 0
        },
      );
    // const [yuzdeArtirAzaltRadio, setYuzdeArtirAzaltRadio] = useState(1);
    // const [yuzdeArtirAzaltDeger, setYuzdeArtirAzaltDeger] = useState(0);


    const initialEditTreatmentInfo = {
        tarih: new Date(),
        fiyat: 0,
        kdv: 0,
        doktor_id: 0,
        aciklama: '',
        tedavi: '',
        id: 0
    }

    // yup schema
    let editTreatmentValidation = EditTreatmentValidationSchema();
    // formik schema
    const editTreatmentFormik = useFormik({
        initialValues: initialEditTreatmentInfo,
        validationSchema: editTreatmentValidation,
        onSubmit: values => {
            setIsProcessingTreatmentEdit(true);
            values.tarih = GetDateWithFormat(values?.tarih);
            values.doktor_id = editTreatmentSelectedDoctor?.id;
            delete values.tedavi;
            if(values?.id && values?.id > 0){
                AxiosCrudActions.UpdateAsync(`muayene-detay/${values?.id}`, values).then(result => {
                    setIsProcessingTreatmentEdit(false);
                    if (result?.data?.status) {
                        setUpdatePatientMadeTreatmentList(!updatePatientMadeTreatmentList);
                        handleEditTreatmentModalToggle();
                    }
                });
            }
        },
    });

    // edit Treatment 
   const editTreatment = (e, values = null) => {
       e.preventDefault();
       if(values !== null){
            editTreatmentFormik.setValues({
               id: values?.id ? values?.id : 0, 
               tarih: values?.tarih ? values.tarih : new Date(),
               fiyat: values?.fiyat ? ConvertStringToFloat(values.fiyat) : 0,
               kdv: values?.kdv ? values.kdv : 0,
               aciklama: values?.aciklama ? values.aciklama : '',
               doktor_id: values?.doktor,
               tedavi: values?.tedavi?.tedaviTanimi
           },true);
           editTreatmentFormik.setErrors({});
           setEditTreatmentSelectedDoctor(values?.doktor);
           handleEditTreatmentModalToggle();
       }
   }

    const getToothInitialValue = (toothNo) => {
        let obj = {
            "hasta_id": patientId,
            "doktor_id": doktorValue?.id,
            "tedavi_id": selectedTedavi?.id,
            "tedavi_tipi_id": selectedTedaviType,
            "disler": null,
            "kokler": null,
            // 1 ust 2 alt disler
            "konum" : null,
            // 1 daimi 2 sut
            "dis_tipi" : null
        }
        // konum
        if((toothNo <= 18 && toothNo) >= 11 || (toothNo >= 21 && toothNo <=28)){
            obj.konum = 1;
            obj.dis_tipi = 1;
        }else if((toothNo <= 48 && toothNo) >= 41 || (toothNo >= 31 && toothNo <=38)){
            obj.konum = 2;
            obj.dis_tipi = 1;
        }else if((toothNo <= 55 && toothNo) >= 51 || (toothNo >= 61 && toothNo <=65)){
            obj.konum = 1;
            obj.dis_tipi = 2;
        }else if((toothNo <= 85 && toothNo) >= 81 || (toothNo >= 71 && toothNo <=75)){
            obj.konum = 2;
            obj.dis_tipi = 2;
        }  
        return obj;
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getDoktors = async () => {
        await axios.get('kullanicilar', {params: {doktor: 1}})
            .then(res => {
                if(res?.status === 200 ){
                    setDoktors(res?.data?.data);
                }
            }).catch(e => {
                setDoktors([]);
            })
    }

    const getTarifeler = async () => {
        await axios.get('tarifeler')
            .then(res => {
                if(res?.status === 200 ){
                    setTarifeler(res?.data?.data);
                }
            }).catch(e => {
                setTarifeler([]);
            })
    }

    // tedaviler
    const getTedaviler = async () => {
        try {
            let tedavi_tipi = selectedTedaviType === 1 ? 1 : 2
            let data = await axios.get('hasta-tedaviler', {params: {tarife_id: tarifeValue.id, tedavi_tipi_id : tedavi_tipi }});
            if(data.status === 200 ){
                setTedaviler(data?.data?.data);
                setFilteredTedaviler(data?.data?.data);
            }
        } catch (error) {
             setTedaviler([]);
             setFilteredTedaviler([]);
        }   
        setLoadingTreatments(false);
    }

    // search filter
    const filterWithSearchTreatment = (value = null) => {
        if(value !== null && value !== ""){
            //setFilteredTedaviler(tedaviler.filter(a => a.tedavi_tanimi.toLowerCase().includes(value.toLowerCase())));
            setFilteredTedaviler(tedaviler.filter(a => ConvertTurkishToEnglishCharacter(a.tedavi_tanimi).toLowerCase().includes(ConvertTurkishToEnglishCharacter(value).toLowerCase())));
        }else{
            setFilteredTedaviler(tedaviler);
        }
    }

    const updateTopBottomToothState = (treatmentData) => {
        if(treatmentData?.tedavi?.sekil_durum !== 0){
            if(treatmentData?.dis_tipi === 1){
                let parsedDisler = StringParseWithSignToArray(treatmentData?.disler, '-');
                if(treatmentData?.konum === 1){
                    setTopDaimiDislerState(current => 
                        current.map(obj => {
                            if(parsedDisler.indexOf(obj.no.toString())!== -1){
                                if(disCekimiTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    return {...obj, defaultImg:  require('/src/assets/images/tooth-extraction/'+obj.no+'.png'), treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                                }else if(defaultImagesCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    return {...obj, defaultImg:  require('/src/assets/images/default-tooth-images/'+ treatmentData?.tedavi?.tedaviKod+ '-' +obj.no+'.png'), treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                                }
                                else if(protezTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1 || bolumluProtezTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    let tempDefaultImg = null; 
                                    try {
                                        tempDefaultImg = require('/src/assets/images/treatmentTeeth/'+ treatmentData?.tedavi?.tedaviKod + '-' +obj.no+'.png')
                                    } catch (error) {
                                        tempDefaultImg = obj.defaultImg;
                                    }
                                    return {...obj, defaultImg: tempDefaultImg, treatments: [{...treatmentData, disler: obj.no.toString()}]}
                                }else if(treatmentData?.tedavi?.tedaviKod === "05-052-001"){
                                    let tempTreatments = obj?.treatments.map((element) => {
                                        if(implantCikartilmasiCode.indexOf(element?.tedavi?.tedaviKod) > -1){
                                            return {...element, disler: null};
                                        }
                                        return element;
                                    })
                                    return {...obj, treatments: [...tempTreatments, {...treatmentData, disler: obj.no.toString()}]}
                                }
                                return {...obj, treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                            }
                            return obj;
                        }))                    
                }else{
                    setBottomDaimiDislerState(current => 
                        current.map(obj => {
                            if(parsedDisler.indexOf(obj.no.toString())!== -1){
                                if(disCekimiTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    return {...obj, defaultImg: require('/src/assets/images/tooth-extraction/'+obj.no+'.png'), treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                                }else if(defaultImagesCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    return {...obj, defaultImg:  require('/src/assets/images/default-tooth-images/'+ treatmentData?.tedavi?.tedaviKod+ '-' +obj.no+'.png'), treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                                }
                                else if(protezTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1 || bolumluProtezTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    let tempDefaultImg = null;
                                    try {
                                        tempDefaultImg = require('/src/assets/images/treatmentTeeth/'+ treatmentData?.tedavi?.tedaviKod + '-' +obj.no+'.png')
                                    } catch (error) {
                                        tempDefaultImg = obj.defaultImg;
                                    }
                                    return {...obj, defaultImg: tempDefaultImg, treatments: [{...treatmentData, disler: obj.no.toString()}]}
                                }else if(treatmentData?.tedavi?.tedaviKod === "05-052-001"){
                                    let tempTreatments = obj?.treatments.map((element) => {
                                        if(implantCikartilmasiCode.indexOf(element?.tedavi?.tedaviKod) > -1){
                                            return {...element, disler: null};
                                        }
                                        return element;
                                    })
                                    return {...obj, treatments: [...tempTreatments, {...treatmentData, disler: obj.no.toString()}]}
                                }
                                return {...obj, treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                            }
                            return obj;
                        }))
                    
                }
            }else{
                let parsedDisler = StringParseWithSignToArray(treatmentData?.disler, '-');
                if(treatmentData?.konum === 1){
                    setTopSutDislerState(current => 
                        current.map(obj => {
                            if(parsedDisler.indexOf(obj.no.toString())!== -1){
                                if(disCekimiTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    return {...obj, defaultImg:  require('/src/assets/images/tooth-extraction/'+obj.no+'.png'), treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                                }else if(defaultImagesCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    return {...obj, defaultImg:  require('/src/assets/images/default-tooth-images/'+ treatmentData?.tedavi?.tedaviKod+ '-' +obj.no+'.png'), treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                                }
                                else if(protezTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1 || bolumluProtezTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    let tempDefaultImg = null; 
                                    try {
                                        tempDefaultImg = require('/src/assets/images/treatmentTeeth/'+ treatmentData?.tedavi?.tedaviKod + '-' +obj.no+'.png')
                                    } catch (error) {
                                        tempDefaultImg = obj.defaultImg;
                                    }
                                    return {...obj, defaultImg: tempDefaultImg, treatments: [{...treatmentData, disler: obj.no.toString()}]}
                                }else if(treatmentData?.tedavi?.tedaviKod === "05-052-001"){
                                    let tempTreatments = obj?.treatments.map((element) => {
                                        if(implantCikartilmasiCode.indexOf(element?.tedavi?.tedaviKod) > -1){
                                            return {...element, disler: null};
                                        }
                                        return element;
                                    })
                                    return {...obj, treatments: [...tempTreatments, {...treatmentData, disler: obj.no.toString()}]}
                                }
                                return {...obj, treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                            }
                            return obj;
                        }))                    
                }else{
                    setBottomSutDislerState(current => 
                        current.map(obj => {
                            if(parsedDisler.indexOf(obj.no.toString())!== -1){
                                if(disCekimiTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    return {...obj, defaultImg: require('/src/assets/images/tooth-extraction/'+obj.no+'.png'), treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                                }else if(defaultImagesCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    return {...obj, defaultImg:  require('/src/assets/images/default-tooth-images/'+ treatmentData?.tedavi?.tedaviKod+ '-' +obj.no+'.png'), treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                                }
                                else if(protezTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1 || bolumluProtezTreatmentCode.indexOf(treatmentData?.tedavi?.tedaviKod) > -1){
                                    let tempDefaultImg = null;
                                    try {
                                        tempDefaultImg = require('/src/assets/images/treatmentTeeth/'+ treatmentData?.tedavi?.tedaviKod + '-' +obj.no+'.png')
                                    } catch (error) {
                                        tempDefaultImg = obj.defaultImg;
                                    }
                                    return {...obj, defaultImg: tempDefaultImg, treatments: [{...treatmentData, disler: obj.no.toString()}]}
                                }else if(treatmentData?.tedavi?.tedaviKod === "05-052-001"){
                                    let tempTreatments = obj?.treatments.map((element) => {
                                        if(implantCikartilmasiCode.indexOf(element?.tedavi?.tedaviKod) > -1){
                                            return {...element, disler: null};
                                        }
                                        return element;
                                    })
                                    return {...obj, treatments: [...tempTreatments, {...treatmentData, disler: obj.no.toString()}]}
                                }
                                return {...obj, treatments: [...obj.treatments, {...treatmentData, disler: obj.no.toString()}]}
                            }
                            return obj;
                        }))
                    
                }
            }    
        }
    }

    // patient Treatment list
    const getPatientTreatmentList = async () => {
        setTopDaimiDislerState(InitialToothState.initialTopDaimiDisler);
        setBottomDaimiDislerState(InitialToothState.initialBottomDaimiDisler);
        setTopSutDislerState(InitialToothState.initialTopSutDisler);
        setBottomSutDislerState(InitialToothState.initialBottomSutDisler);
        await axios.get(`/muayene-detay?hasta_id=${patientId}&tedavi_tipi_id=${selectedTedaviType}`)
            .then(res => {
                if(res?.status === 200){
                    setPatientMadeTreatmentsList(res?.data?.data);
                    setPatientMadeTreatmentsList(prevState => {
                        return prevState.map((el) => {
                            return {...el, selected: false}
                        })
                    });
                    res?.data?.data.map(element => {
                        return updateTopBottomToothState(element);
                        // updateTopBottomToothState(element);
                    });
                    setDoctorNote(res?.data?.hasta?.doktor_notu);
                    setTreatmentsTotalAmount(res?.data?.genel_toplam);
                    setDoktorValue(res?.data?.doktor);
                }
                setIsLoading(false);
            }).catch(e => {
                setPatientMadeTreatmentsList([]);
                setTopDaimiDislerState(InitialToothState.initialTopDaimiDisler);
                setBottomDaimiDislerState(InitialToothState.initialBottomDaimiDisler);
                setTopSutDislerState(InitialToothState.initialTopSutDisler);
                setBottomSutDislerState(InitialToothState.initialBottomSutDisler);
                setDoctorNote('');
                setTreatmentsTotalAmount(0);
                setIsLoading(false);
                setDoktorValue(null);
            })
    }

    //save data to server
    const saveData = async (element, obj) => {
        MySwal.fire({
            title: "Tedavi Eklensin mi",
            text: `${element.no || element.disNo} numaralı dişe ${selectedTedavi?.tedavi_tanimi} adlı tedavi yapılsın mı?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Ekle',
            cancelButtonText: 'Hayır, Vazgeç'
        }).then(async (result) => {
            if (result.isConfirmed) {
                AxiosCrudActions.CreateAsync('/muayene-detay', obj).then(result => {
                    if (result?.data?.status && result?.data?.data !== null) {
                        const treatmentData = result?.data?.data;
                        setPatientMadeTreatmentsList(prevState => [...prevState, treatmentData]);
                        updateTopBottomToothState(treatmentData);
                        setTreatmentsTotalAmount(result.data.genel_toplam);
                    }
                });
            }
        })
    }

    // handle Tooth Treatment
    const handleToothTreatment = (e, element = null) => {
        e.preventDefault();
        if(element !== null && selectedTedavi !== null){
            //initial object value
            let obj = getToothInitialValue(element.no);

            // set Koklu dis info null
            setKokluDisInfo(null);
            setBolumluProtezDisInfo(null);
            setHandleTreatmentModalSize("sm");
            //handle tooth with modal
            if(handleToothWithModalTreatmentCode.indexOf(selectedTedavi?.tedavi_kod) > -1){
                const parsedKokler = StringParseWithSignToArray(element.kok, '-');
                if(parsedKokler !== undefined && parsedKokler !== null){
                    if(parsedKokler.length > 1){
                        const koklerArr = parsedKokler.map((element,index) => {
                            return {kok: element, selected: true}
                        });
                        setKokluDisInfo({
                            tedaviTanim: selectedTedavi?.tedavi_tanimi,
                            tedaviKod : selectedTedavi?.tedavi_kod,
                            disNo: element?.no,
                            disImg: element?.defaultImg,
                            kokler: koklerArr,
                        });
                        handleTreatmentWithModalToggle();
                    }else{
                        obj.disler = element.no;
                        saveData(element,obj);
                    }
                }
                return 0;
            }
            // bolumlu protez
            else if(bolumluProtezTreatmentCode.indexOf(selectedTedavi?.tedavi_kod) > -1){
                let tempProtezObj = {
                    tedaviKod: selectedTedavi?.tedavi_kod,
                    no: element.no,
                    disler: []
                }       
                if(obj.dis_tipi === 1){
                    obj.konum === 1 ?
                    tempProtezObj.disler = [
                        {disNo: 18, selected: false},
                        {disNo: 17, selected: false},
                        {disNo: 16, selected: false},
                        {disNo: 15, selected: false},
                        {disNo: 14, selected: false},
                        {disNo: 13, selected: false},
                        {disNo: 12, selected: false},
                        {disNo: 11, selected: false},
                        {disNo: 21, selected: false},
                        {disNo: 22, selected: false},
                        {disNo: 23, selected: false},
                        {disNo: 24, selected: false},
                        {disNo: 25, selected: false},
                        {disNo: 26, selected: false},
                        {disNo: 27, selected: false},
                        {disNo: 28, selected: false}
                    ]
                    :
                    tempProtezObj.disler = [
                        {disNo: 48, selected: false},
                        {disNo: 47, selected: false},
                        {disNo: 46, selected: false},
                        {disNo: 45, selected: false},
                        {disNo: 44, selected: false},
                        {disNo: 43, selected: false},
                        {disNo: 42, selected: false},
                        {disNo: 41, selected: false},
                        {disNo: 31, selected: false},
                        {disNo: 32, selected: false},
                        {disNo: 33, selected: false},
                        {disNo: 34, selected: false},
                        {disNo: 35, selected: false},
                        {disNo: 36, selected: false},
                        {disNo: 37, selected: false},
                        {disNo: 38, selected: false}
                    ]
                }else{
                    obj.konum === 1 ?
                    tempProtezObj.disler = [
                        {disNo: 55, selected: false},
                        {disNo: 54, selected: false},
                        {disNo: 53, selected: false},
                        {disNo: 52, selected: false},
                        {disNo: 51, selected: false},
                        {disNo: 61, selected: false},
                        {disNo: 62, selected: false},
                        {disNo: 63, selected: false},
                        {disNo: 64, selected: false},
                        {disNo: 65, selected: false}
                    ]
                    :
                    tempProtezObj.disler = [
                        {disNo: 85, selected: false},
                        {disNo: 84, selected: false},
                        {disNo: 83, selected: false},
                        {disNo: 82, selected: false},
                        {disNo: 81, selected: false},
                        {disNo: 71, selected: false},
                        {disNo: 72, selected: false},
                        {disNo: 73, selected: false},
                        {disNo: 74, selected: false},
                        {disNo: 75, selected: false}
                    ] 
                }

                tempProtezObj.disler = tempProtezObj.disler.map((el,idx) => {
                    if(el.disNo === element.no){
                        return {...el, selected: true};
                    }
                    return el;
                })

                setBolumluProtezDisInfo(tempProtezObj);
                setHandleTreatmentModalSize("lg");
                handleTreatmentWithModalToggle();
            }
            //handle tooth standart
            else{
                // dis cekimi
                if( disCekimiTreatmentCode.indexOf(selectedTedavi?.tedavi_kod) > -1){
                    obj.disler = element.no;
                }
                // sabit pekiştirme
                else if(selectedTedavi?.tedavi_kod === "07-023-001"){
                    if(obj.dis_tipi === 1){
                        obj.konum === 1 
                        ?
                        obj.disler = "13-12-11-21-22-23" 
                        :
                        obj.disler = "43-42-41-31-32-33";
                    }else{
                        return MySwal.fire({
                            title: "Uyarı",
                            text: "Bu tedavi sadece daimi dişlere uygulanabilir.",
                            icon: 'error',
                            confirmButtonColor: '#1172b6'
                        })
                    }
                }
                // tum cene disleri 
                else if(tumCeneDislerTreatmentCode.indexOf(selectedTedavi?.tedavi_kod) > -1){
                    if(obj.dis_tipi === 1){
                        obj.konum === 1 
                        ?
                        obj.disler = "18-17-16-15-14-13-12-11-21-22-23-24-25-26-27-28" 
                        :
                        obj.disler = "48-47-46-45-44-43-42-41-31-32-33-34-35-36-37-38";
                    }else{
                        obj.konum === 1 
                        ?
                        obj.disler = "55-54-53-52-51-61-62-63-64-65" 
                        :
                        obj.disler = "85-84-83-82-81-71-72-73-74-75";
                    }
                }
                // protez
                else if(protezTreatmentCode.indexOf(selectedTedavi?.tedavi_kod) > -1){
                    if(obj.dis_tipi === 1){
                        obj.konum === 1 
                        ?
                        obj.disler = "18-17-16-15-14-13-12-11-21-22-23-24-25-26-27-28" 
                        :
                        obj.disler = "48-47-46-45-44-43-42-41-31-32-33-34-35-36-37-38";
                    }else{
                        obj.konum === 1 
                        ?
                        obj.disler = "55-54-53-52-51-61-62-63-64-65" 
                        :
                        obj.disler = "85-84-83-82-81-71-72-73-74-75";
                    }
                }
                // without shape
                else{
                    obj.disler = element.no;
                }
                saveData(element,obj);
            } 
        }else{
            MySwal.fire({
                title: "Uyarı",
                text: "Lütfen önce tedavi seçiniz.",
                icon: 'warning',
                confirmButtonColor: '#1172b6'
            })
        }
    }

    // check modal treatment
    const handleModalTreatment = (e) => {
        if(kokluDisInfo !== null && selectedTedavi !== null && kokluDisInfo?.tedaviKod === selectedTedavi?.tedavi_kod){
            let obj = getToothInitialValue(kokluDisInfo.disNo);
            const selectedKokler = kokluDisInfo.kokler.filter((element,index) => {
                return element.selected;
            });
            obj.disler = kokluDisInfo?.disNo;
            obj.kokler = selectedKokler.map(x => x.kok).join("-");
            handleTreatmentWithModalToggle();
            saveData(kokluDisInfo, obj);
        }else if(bolumluProtezDisInfo !== null && selectedTedavi !== null && bolumluProtezDisInfo?.tedaviKod === selectedTedavi?.tedavi_kod){
            const selectedDisler = bolumluProtezDisInfo.disler.filter((element,index) => {
                return element.selected;
            }).map(x => x.disNo).join("-");
            let obj = getToothInitialValue(bolumluProtezDisInfo.no);
            obj.disler = selectedDisler;
            handleTreatmentWithModalToggle();
            saveData({no: selectedDisler}, obj);
        }
    } 

    // delete treatment from list
    const deleteTreatmentFromList = (e, value = null) => {
        e.preventDefault();
        e.stopPropagation()
        if(value !== null && value?.id > 0){
            MySwal.fire({
                title: "Tedavi silinsin mi?",
                text: "silme işlemini gerçekleştirdiğinizde geri alamazsınız!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Hayır,Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    AxiosCrudActions.DeleteAsync('/muayene-detay', value.id)
                        .then(result => {
                            if (result?.status === 200 && result?.data?.status) {
                                setUpdatePatientMadeTreatmentList(!updatePatientMadeTreatmentList);
                            }
                        });
                }
            })
        }
    }

    const handleDoctorNote = (e) => {
        e.preventDefault(); //prevent default action 
        handleDoctorNoteModalToggle();
    }

    const saveDoctorNote = (e) => {
        e.preventDefault();
        setIsProcessingDoctorNote(true);
        let data = { doktor_notu: doctorNoteInputRef.current.value}
            AxiosCrudActions.UpdateAsync(`/doktor-notu/${patientId}`, data).then(result => {
                if (result?.status === 200) {
                    handleDoctorNoteModalToggle();
                    setDoctorNote(doctorNoteInputRef.current.value)
                }
                setTimeout(() => {
                    setIsProcessingDoctorNote(false);
                }, 1);
            });
    }

    const transferTedaviler = () => {
        MySwal.fire({
            title: "Uyarı",
            text: "Seçilen tedaviler,  tedavi bölümüne kopyalansın mı?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Kopyala',
            cancelButtonText: 'Hayır, Vazgeç'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let selectedIds = patientMadeTreatmentsList.filter((el) => el.selected).map((data) => data.id);
                let data = {
                    ids: JSON.stringify(selectedIds)
                }
                AxiosCrudActions.CreateAsync('/tedaviye-aktar', data).then(result => {
                    if (result?.data?.status && result?.data?.data !== null) {
                        setUpdatePatientMadeTreatmentList(!updatePatientMadeTreatmentList);
                        setSelectedTedaviType(2);
                    }
                });
            }
        })
    }

    // remove selected treatment rows
    const removeSelectedTreatmentRows = (e) => {
        e.preventDefault();
        MySwal.fire({
            title: "Uyarı! Seçilen Tedaviler silinsin mi?",
            text: "Silme işlemini gerçekleştirdiğinizde geri alamazsınız",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Evet, Sil",
            cancelButtonText: 'Hayır,Vazgeç'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let selectedIds = patientMadeTreatmentsList.filter((el) => el.selected).map((data) => data.id);
                AxiosCrudActions.DeleteManyAsync('/muayene-toplu-sil', JSON.stringify(selectedIds))
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdatePatientMadeTreatmentList(!updatePatientMadeTreatmentList);
                                    }
                                });
            }
        })
    }

     // edit selected treatment account
     const editSelectedTreatmentAccount = (e) => {
        e.preventDefault();
        setSelTreatAccState({
            radio: 1,
            deger: 0,
            sum: 0
        })
        handleEditSelTreatAccModalToggle();
    }

    // update selected treatment account post data
    const updateSelTreatPrice = (e) => {
        e.preventDefault();
        setEditSelTreatAccProcessing(true);
        let selectedIds = patientMadeTreatmentsList.filter((el) => el.selected).map((data) => data.id);
        let data = {
            ids: JSON.stringify(selectedIds),
            tip: !selTreatAccState.deger ? null : selTreatAccState.radio,
            yuzde: !selTreatAccState.deger ? null : selTreatAccState.deger,
            yuvarla: !selTreatAccState.deger ? 1 : null,
            deger: !selTreatAccState.deger ? selTreatAccState.sum : null,
        }
        AxiosCrudActions.UpdateAsync('/muayene-hesap', data).then(result => {
            if(result?.status === 200){
                setPatientMadeTreatmentsList(result?.data?.data);
                setPatientMadeTreatmentsList(prevState => {
                    return prevState.map((el) => {
                        return {...el, selected: false}
                    })
                });
                setTreatmentsTotalAmount(result?.data?.genel_toplam);
            }
            setTimeout(() => {
                setEditSelTreatAccProcessing(false);
            }, 1);
            handleEditSelTreatAccModalToggle();
        }).catch((e) => {
            setPatientMadeTreatmentsList([])
            setTreatmentsTotalAmount(0);
            setEditSelTreatAccProcessing(false);
        });
    }

    // get patient default tedavi 
    const getPatientDefaultTedavi = async () => {
        await axios.get('hasta-tarife', {params: {hasta_id: patientId}})
                .then(res => {
                    if(res?.status === 200 && res?.data?.data){
                        setTarifeValue(res?.data?.data);
                    }
                }).catch(e => {
                    setTarifeValue(null);
                })
    
    }

    // ilk acılısta hasta notunu cek

    useEffect(() => {
        if(selectedTedaviType > 0 && patientId > 0){
            getPatientTreatmentList();
        }
    }, [updatePatientMadeTreatmentList,selectedTedaviType]);

    useEffect(()=> {
        getTarifeler();
        getDoktors();
        getPatientDefaultTedavi();
    },[]);

    useEffect(() => {
        if(tarifeValue?.id > 0 && selectedTedaviType > 0){
            getTedaviler();
        }
       
    },[tarifeValue, selectedTedaviType]);

    
    return (
        <TabPanel value="1" className="patient-detail-treatment-container">
            {
                  isProcesssingDoctorNote || isProcesssingTreatmentEdit ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
            }
            <div className="row">
                {/* tedaviler alani */}
                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 mt-1">
                    <div className="cstm-border-top card p-2 pb-4 pt-2">
                        <div className="row mb-2">
                            <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="doktorId"
                                        options={doktors}
                                        loading={true}
                                        getOptionLabel={option => option?.name + ' - ' + option?.brans}
                                        noOptionsText={'Seçenek Bulunamadı'}
                                        value={doktorValue}
                                        label="Doktor"
                                        disableClearable
                                        onChange={(event, newValue) => { 
                                            event.preventDefault();
                                            setDoktorValue(newValue);
                                        }}
                                        inputValue={doktorInputValue}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onInputChange={(event, newInputValue) => { setDoktorInputValue(newInputValue); }}
                                        size="small"
                                        renderOption={(props, option) => {
                                            return (
                                            <li {...props} key={option.id}>
                                                {option?.name + ' - ' + option?.brans}
                                            </li>
                                            );
                                        }}
                                        renderInput={(params) => <TextField {...params}  InputLabelProps={{ style: { color: '#1976d2' } }} label="Doktor"/>}  
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="tarifeId"
                                        options={tarifeler}
                                        loading={true}
                                        getOptionLabel={option => option?.tarifeAdi}
                                        noOptionsText={'Seçenek Bulunamadı'}
                                        value={tarifeValue}
                                        disableClearable
                                        onChange={(event, newValue) => { 
                                            event.preventDefault();
                                            setTarifeValue(newValue);
                                        }}
                                        inputValue={tarifeInputValue}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onInputChange={(event, newInputValue) => { setTarifeInputValue(newInputValue); }}
                                        size="small"
                                        renderOption={(props, option) => {
                                            return (
                                            <li {...props} key={option.id}>
                                                {option?.tarifeAdi}
                                            </li>
                                            );
                                        }}
                                        renderInput={(params) => <TextField {...params} InputLabelProps={{ style: { color: '#1976d2' } }} label="Tarife"/>}  
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <TextField className='form-group'
                                    type="text"
                                    variant='outlined'
                                    autoComplete="off"
                                    onChange={(event) => {
                                        event.preventDefault();
                                        if(/^\s/.test(event.target.value))
                                            event.target.value = '';
                                        filterWithSearchTreatment(event.target.value);
                                    }}
                                    size='small'
                                    placeholder="tedavi ara..."
                                />
                            </FormControl>
                        </div>
                        {/* treatment list */}
                        <div>
                            <TableContainer component={Paper} sx={{ maxHeight: 430, marginTop: 1 }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow sx={{"& th": {color: "#fff",backgroundColor: "#50bdf3ad"}}} style={{position: 'sticky', top: '0', zIndex: '10'}}>
                                                <TableCell align="left" width='5%'>#</TableCell>
                                                <TableCell align="left" width='75%'>Tedavi Adı</TableCell>
                                                <TableCell align="left" width='20%'>Fiyat</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {
                                            useMemo(() =>
                                            <TableBody>
                                                {  
                                                    can('create-hastalar-tedavi') && can('view-hastalar-tedavi') ?
                                                        !loadingTreatments ? 
                                                        filteredTedaviler?.length > 0 ? 
                                                        filteredTedaviler.map((row, index) => (
                                                            <TableRow 
                                                                key={row.id} 
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    row?.id === selectedTedavi?.id ? setSelectedTedavi(null) : setSelectedTedavi(row); 
                                                                }}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } , '& td, & tr': {fontSize: '12px', fontWeight: 'normal'}}}
                                                                style={{backgroundColor: selectedTedavi?.id === row.id? '#1172b6': '#ffffff'}}>
                                                                    <TableCell align="left" width='5%' style={{color: selectedTedavi?.id === row.id ? '#ffffff': (row?.sekil_durum ? '#0b9aff' : '#000')}}>{index + 1}</TableCell>
                                                                    <TableCell align="left" width='75%' style={{color: selectedTedavi?.id === row.id ? '#ffffff': (row?.sekil_durum ? '#0b9aff' : '#000')}}>{row?.tedavi_tanimi}</TableCell>
                                                                    <TableCell align="left" width='20%' style={{color: selectedTedavi?.id === row.id ? '#ffffff': (row?.sekil_durum ? '#0b9aff' : '#000')}}>{row?.fiyat} TRY</TableCell>
                                                            </TableRow> 
                                                        )):
                                                        <TableRow width="100%">
                                                            <TableCell colSpan={3} height={"100px"} align="center">
                                                                <CustomNoRowsOverlay />
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow width="100%">
                                                            <TableCell colSpan={3} height={"100px"} align="center">
                                                                <LoadingOverlay />
                                                            </TableCell>
                                                        </TableRow>
                                                    : <TableRow width="100%"> <TableCell colSpan={3} height={"100px"} align="center"><NotPermission /> </TableCell></TableRow>
                                                }
                                            </TableBody>
                                            ,[filteredTedaviler, tedaviler, selectedTedavi])
                                        }
                                    </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
                {/* dis secimi */}
                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mt-1">
                    <div className="cstm-border-top card p-3 mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{minWidth: '150px'}}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='tedavi_tipi'
                                        select
                                        size="small"
                                        InputLabelProps={{ style: { color: '#1976d2' } }}
                                        label="Tedavi Tipi"
                                        defaultValue={2}
                                        value={selectedTedaviType ? selectedTedaviType : 2 }
                                        onChange={(e) => {
                                            e.preventDefault();
                                            setSelectedTedaviType(e.target.value);
                                        }}
                                        >
                                        <MenuItem value={1}>Diagnoz</MenuItem>
                                        <MenuItem value={2}>Tedavi</MenuItem>
                                        <MenuItem value={3}>Planlama 1</MenuItem>
                                        <MenuItem value={4}>Planlama 2</MenuItem>
                                        <MenuItem value={5}>Planlama 3</MenuItem>
                                        <MenuItem value={6}>Planlama 4</MenuItem>
                                        <MenuItem value={7}>Planlama 5</MenuItem>
                                    </TextField>
                                </FormControl>
                            </div>
                            <div>
                                {
                                    useMemo(() => 
                                        <Modal  isOpen={doctorNoteModalState} 
                                            toggle={handleDoctorNoteModalToggle} 
                                            size="md"
                                            modalTransition={{ timeout: 1 }}
                                            backdropTransition={{ timeout: 1 }}
                                            >
                                            <ModalHeader toggle={handleDoctorNoteModalToggle} style={{ color: "#1172b6", fontWeight: 200 }}>Doktor Notu</ModalHeader>
                                            <form>
                                                <ModalBody className="d-flex justify-content-center">
                                                    <div className='row col-12'>
                                                        <FormControl fullWidth>
                                                            {   
                                                                <TextField 
                                                                    multiline
                                                                    rows={8}
                                                                    placeholder="Not..."
                                                                    type="text"
                                                                    variant='outlined'
                                                                    autoComplete='off'
                                                                    defaultValue={doctorNote}
                                                                    inputRef={doctorNoteInputRef}
                                                                    //value={doctorNote} // bir state value verirsen onchange ile update etmen lazım o yuzden guncellemiyor yazamıyorsun
                                                                    //cunku bu statetin degeri bu o yuzden inputtaki deger hep statin degerinde kalır yazamanazsın degismez.
                                                                    //bir state value verirsen hep o statin degeri kalır ne yazsan hicbirsey yazmaz.
                                                                    //ya state i onchange ile update edersin value degissin yada value degerine degisken degil literal verirsin
                                                                />
                                                            }
                                                        </FormControl>
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="primary" type="button" onClick={saveDoctorNote} style={{ background: "#1172b6" }} disabled={!can('create-hastalar-tedavi')} className="d-flex align-items-center">
                                                    {
                                                        isProcesssingDoctorNote ? 
                                                        (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                                İşleniyor...
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <FaCloudUploadAlt /> &nbsp;
                                                                {"Kaydet"}
                                                            </>
                                                        )
                                                    }
                                                   </Button>{' '}
                                                   <Button color="danger" type="button" onClick={handleDoctorNoteModalToggle} className="d-flex align-items-center">
                                                        <FaTimes />&nbsp;Vazgeç
                                                   </Button>
                                                </ModalFooter>
                                            </form>
                                        </Modal>
                                    ,[doctorNoteModalState])
                                }
                                <Button outline color="success" type="button" onClick={handleDoctorNote} disabled={!can('create-hastalar-tedavi')}>Doktor Notu</Button>{' '}
                                <Button outline color="primary" type="button" onClick={() => { navigate('/hastalar') }}>Hastalar</Button>
                            </div>
                        </div>
                        <hr />
                        {/* tab panel */}
                        <div>
                         {can('view-hastalar-tedavi') ?
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleTabChange} variant="fullWidth">
                                        <Tab label="Daimi Dişler" value="1" />
                                        <Tab label="Süt Dişler" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <div style={{width:'100%', height: 'auto', position: 'relative', float:'left',overflow:'auto'}}>
                                        <table style={{minwidth:'600px',width:'100%'}}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div>
                                                            <div>
                                                                <div className='d-flex justify-content-center '>
                                                                    <table>
                                                                        <thead>
                                                                            <tr style={{borderTop: '1px solid black',borderBottom: '1px solid black', color: '#565656'}}>
                                                                                {
                                                                                    topDaimitHead.map((head, index) => {
                                                                                        return (
                                                                                            <th className='text-center' key={index}>{head}</th>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody >
                                                                            <tr>
                                                                                {
                                                                                    topDaimiDislerState.map((element,index) => {
                                                                                        return (
                                                                                            <td className='tooth-img top-tooth-img' key={index} onClick={e => handleToothTreatment(e,element)}>
                                                                                                <div>
                                                                                                    {element?.defaultImg && <img src={element.defaultImg} alt={`tooth${element.no}`} /> }
                                                                                                    {
                                                                                                        
                                                                                                        element?.treatments.map((child, childIndex) => ( 
                                                                                                            
                                                                                                            IsExistToothImagePath(child?.tedavi?.tedaviKod+'-'+child.disler+ (child?.kokler ? '-'+child.kokler : '') +'.png') && 
                                                                                                             <span
                                                                                                                key={childIndex} 
                                                                                                                style={{
                                                                                                                    position: 'absolute',
                                                                                                                    left: 0,
                                                                                                                    bottom: 0,
                                                                                                                    width: '100%',
                                                                                                                    height: '100%',
                                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                                    backgroundPosition:'center',
                                                                                                                    backgroundSize: 'auto auto',
                                                                                                                    backgroundImage:
                                                                                                                   `url(${require('/src/assets/images/treatmentTeeth/'+child?.tedavi?.tedaviKod+'-'+child.disler+ (child?.kokler ? '-'+child.kokler : '') +'.png')})`
                                                                                                                    }}>
                                                                                                            </span>
                                                                                                        ))  
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className='d-flex justify-content-center'>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                {
                                                                                    BottomDaimiDislerState.map((element,index) => {
                                                                                        return (
                                                                                            <td className='tooth-img bottom-tooth-img' key={index} onClick={e => handleToothTreatment(e,element)}>
                                                                                                <div>
                                                                                                    {element?.defaultImg && <img src={element.defaultImg} alt={`tooth${element.no}`} />}
                                                                                                    {
                                                                                                        element?.treatments.map((child, childIndex) => (
                                                                                                            IsExistToothImagePath(child?.tedavi?.tedaviKod+'-'+child.disler+ (child?.kokler ? '-'+child.kokler : '') +'.png') && 
                                                                                                            <span
                                                                                                                key={childIndex} 
                                                                                                                style={{
                                                                                                                    position: 'absolute',
                                                                                                                    left: 0,
                                                                                                                    top: 0,
                                                                                                                    width: '100%',
                                                                                                                    height: '100%',
                                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                                    backgroundPosition:'center',
                                                                                                                    backgroundSize: 'auto auto',
                                                                                                                    backgroundImage:
                                                                                                                    `url(${require('/src/assets/images/treatmentTeeth/'+child?.tedavi?.tedaviKod+'-'+child.disler+ (child?.kokler ? '-'+child.kokler : '') +'.png')})`}}>
                                                                                                            </span>
                                                                                                        ))  
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr style={{borderTop: '1px solid black',borderBottom: '1px solid black', color: '#565656'}}>
                                                                                {
                                                                                    subDaimitHead.map((head, index) => {
                                                                                        return (
                                                                                            <th className='text-center' key={index}>{head}</th>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                                <TabPanel value="2">
                                    <div style={{width:'100%', height: 'auto', position: 'relative', float:'left',overflow:'auto'}}>
                                        <table style={{minwidth:'600px',width:'100%'}}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div>
                                                            <div>
                                                                <div className='d-flex justify-content-center '>
                                                                    <table>
                                                                        <thead>
                                                                            <tr style={{borderTop: '1px solid black',borderBottom: '1px solid black', color: '#565656'}}>
                                                                                {
                                                                                    topSuttHead.map((head, index) => {
                                                                                        return (
                                                                                            <th className='text-center' key={index}>{head}</th>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody >
                                                                            <tr>
                                                                                {
                                                                                    topSutDislerState.map((element,index) => {
                                                                                        return (
                                                                                            <td className='tooth-img top-tooth-img' key={index} onClick={e => handleToothTreatment(e,element)}>
                                                                                                <div>
                                                                                                    {element?.defaultImg && <img src={element.defaultImg} alt={`tooth${element.no}`} /> }
                                                                                                    {
                                                                                                        
                                                                                                        element?.treatments.map((child, childIndex) => ( 
                                                                                                            
                                                                                                            IsExistToothImagePath(child?.tedavi?.tedaviKod+'-'+child.disler+ (child?.kokler ? '-'+child.kokler : '') +'.png') && 
                                                                                                             <span
                                                                                                                key={childIndex} 
                                                                                                                style={{
                                                                                                                    position: 'absolute',
                                                                                                                    left: 0,
                                                                                                                    bottom: 0,
                                                                                                                    width: '100%',
                                                                                                                    height: '100%',
                                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                                    backgroundPosition:'center',
                                                                                                                    backgroundSize: 'auto auto',
                                                                                                                    backgroundImage:
                                                                                                                   `url(${require('/src/assets/images/treatmentTeeth/'+child?.tedavi?.tedaviKod+'-'+child.disler+ (child?.kokler ? '-'+child.kokler : '') +'.png')})`
                                                                                                                    }}>
                                                                                                            </span>
                                                                                                        ))  
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className='d-flex justify-content-center'>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                {
                                                                                    bottomSutDislerState.map((element,index) => {
                                                                                        return (
                                                                                            <td className='tooth-img bottom-tooth-img' key={index} onClick={e => handleToothTreatment(e,element)}>
                                                                                                <div>
                                                                                                    {element?.defaultImg && <img src={element.defaultImg} alt={`tooth${element.no}`} />}
                                                                                                    {
                                                                                                        element?.treatments.map((child, childIndex) => (
                                                                                                            IsExistToothImagePath(child?.tedavi?.tedaviKod+'-'+child.disler+ (child?.kokler ? '-'+child.kokler : '') +'.png') && 
                                                                                                            <span
                                                                                                                key={childIndex} 
                                                                                                                style={{
                                                                                                                    position: 'absolute',
                                                                                                                    left: 0,
                                                                                                                    top: 0,
                                                                                                                    width: '100%',
                                                                                                                    height: '100%',
                                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                                    backgroundPosition:'center',
                                                                                                                    backgroundSize: 'auto auto',
                                                                                                                    backgroundImage:
                                                                                                                    `url(${require('/src/assets/images/treatmentTeeth/'+child?.tedavi?.tedaviKod+'-'+child.disler+ (child?.kokler ? '-'+child.kokler : '') +'.png')})`}}>
                                                                                                            </span>
                                                                                                        ))  
                                                                                                    }
                                                                                                </div> 
                                                                                            </td>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr style={{borderTop: '1px solid black',borderBottom: '1px solid black', color: '#565656'}}>
                                                                                {
                                                                                    subSuttHead.map((head, index) => {
                                                                                        return (
                                                                                            <th className='text-center' key={index}>{head}</th>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        : <NotPermission />}

                            {/* edit treatment modal */}
                            <Modal isOpen={editTreatmentModal} 
                                toggle={handleEditTreatmentModalToggle} 
                                size="md"
                                modalTransition={{ timeout: 1 }}
                                backdropTransition={{ timeout: 1 }}>
                                <ModalHeader toggle={handleEditTreatmentModalToggle} style={{ color: "#1172b6", fontWeight: 200 }}>Tedavi Bilgileri</ModalHeader>
                                <form onSubmit={editTreatmentFormik.handleSubmit}>
                                    <ModalBody>
                                        <div className="row">
                                            <div>
                                                <div className='mt-2'>
                                                    <FormControl fullWidth>
                                                        <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                                            <DesktopDatePicker
                                                                variant='outlined'
                                                                minDate={dayjs('01-01-1930')}
                                                                inputFormat="dd-MM-yyyy"
                                                                value={editTreatmentFormik.values.tarih}
                                                                onChange={(newValue) => {
                                                                    editTreatmentFormik.setFieldValue('tarih', newValue, true);
                                                                }}
                                                                name="tarih"
                                                                size="small"
                                                                renderInput={(params) => <TextField {...params} 
                                                                                                label="Tarih"
                                                                                                size="small"
                                                                                                InputLabelProps={{ style: { color: '#1976d2' } }}
                                                                                                variant='outlined'
                                                                                                error={editTreatmentFormik.errors.tarih && editTreatmentFormik.touched.tarih}
                                                                                                helperText={editTreatmentFormik.errors.tarih && editTreatmentFormik.touched.tarih && <span className='cstm-input-error-validation-msg'>{editTreatmentFormik.errors.tarih}</span>}
                                                                                            />}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </div>

                                                <div className="mt-2">
                                                    <FormControl fullWidth className="mt-3">
                                                        <TextField className='form-group'
                                                            variant='outlined'
                                                            label="Tedavi"
                                                            size='small'
                                                            defaultValue={editTreatmentFormik.values.tedavi}
                                                            InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                                            inputProps={
                                                                { readOnly: true, }
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>
                                                
                                                <div className="mt-4">
                                                    <FormControl fullWidth>
                                                        <TextField className='form-group'
                                                            type="text"
                                                            name="fiyat"
                                                            variant='outlined'
                                                            label="Fiyat"
                                                            autoComplete="off"
                                                            InputLabelProps={{ style: { color: editTreatmentFormik.errors.fiyat && editTreatmentFormik.touched.fiyat ? '#e53e3e' : '#1976d2' } }}
                                                            onChange={editTreatmentFormik.handleChange}
                                                            value={editTreatmentFormik.values.fiyat}
                                                            onBlur={editTreatmentFormik.handleBlur}
                                                            size='small'
                                                            InputProps={{
                                                                inputComponent: CurrencyMask,
                                                                startAdornment: <InputAdornment position="start" ><FaLiraSign /></InputAdornment>,
                                                            }}
                                                            error={editTreatmentFormik.errors.fiyat && editTreatmentFormik.touched.fiyat}
                                                            helperText={editTreatmentFormik.errors.fiyat && editTreatmentFormik.touched.fiyat && <span className='cstm-input-error-validation-msg'>{editTreatmentFormik.errors.fiyat}</span>}
                                                        />
                                                    </FormControl>
                                                </div>

                                                <div className='mt-4'>
                                                    <FormControl fullWidth>
                                                        <TextField className='form-group'
                                                            type="text"
                                                            name="kdv"
                                                            variant='outlined'
                                                            label="KDV"
                                                            autoComplete="off"
                                                            InputLabelProps={{ style: { color: editTreatmentFormik.errors.kdv && editTreatmentFormik.touched.kdv ? '#e53e3e' : '#1976d2' } }}
                                                            onChange={editTreatmentFormik.handleChange}
                                                            value={editTreatmentFormik.values.kdv}
                                                            onBlur={editTreatmentFormik.handleBlur}
                                                            size='small'
                                                            InputProps={{
                                                                inputComponent: RateMask,
                                                                startAdornment: <InputAdornment position="start" ><FaPercentage /></InputAdornment>,
                                                            }}
                                                            error={editTreatmentFormik.errors.kdv && editTreatmentFormik.touched.kdv}
                                                            helperText={editTreatmentFormik.errors.kdv && editTreatmentFormik.touched.kdv && <span className='cstm-input-error-validation-msg'>{editTreatmentFormik.errors.kdv}</span>}
                                                        />
                                                    </FormControl>
                                                </div>
                                                        
                                                <div className='mt-4'>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            id="editTreatmentDoktorId"
                                                            options={doktors}
                                                            loading={true}
                                                            getOptionLabel={option => option?.name + ' - ' + option?.brans}
                                                            noOptionsText={'Seçenek Bulunamadı'}
                                                            value={editTreatmentSelectedDoctor}
                                                            label="Doktor"
                                                            disableClearable
                                                            onChange={(event, newValue) => { 
                                                                event.preventDefault();
                                                                setEditTreatmentSelectedDoctor(newValue);
                                                            }}
                                                            inputValue={editTreatmentSelectedDoctorInputValue}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            onInputChange={(event, newInputValue) => { setEditTreatmentSelectedDoctorInputValue(newInputValue); }}
                                                            size="small"
                                                            renderOption={(props, option) => {
                                                                return (
                                                                <li {...props} key={option.id}>
                                                                    {option?.name + ' - ' + option?.brans}
                                                                </li>
                                                                );
                                                            }}
                                                            renderInput={(params) => <TextField {...params}  InputLabelProps={{ style: { color: '#1976d2' } }} label="Doktor"/>}  
                                                        />
                                                    </FormControl>
                                                </div>
                                                
                                                <div className="mt-4">
                                                    <FormControl fullWidth>
                                                        <TextField className='form-group'
                                                            type="text"
                                                            name="aciklama"
                                                            variant='outlined'
                                                            label="Açıklama"
                                                            autoComplete="off"
                                                            InputLabelProps={{ style: { color: editTreatmentFormik.errors.aciklama && editTreatmentFormik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                                                            onChange={editTreatmentFormik.handleChange}
                                                            value={editTreatmentFormik.values.aciklama}
                                                            onBlur={editTreatmentFormik.handleBlur}
                                                            size='small'
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start" ><FaMapMarkerAlt /></InputAdornment>,
                                                            }}
                                                            error={editTreatmentFormik.errors.aciklama && editTreatmentFormik.touched.aciklama}
                                                            helperText={editTreatmentFormik.errors.aciklama && editTreatmentFormik.touched.aciklama && <span className='cstm-input-error-validation-msg'>{editTreatmentFormik.errors.aciklama}</span>}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button color="primary" type="submit" style={{ background: "#1172b6" }} className="d-flex align-items-center">
                                            {
                                                isProcesssingTreatmentEdit ? 
                                                (
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                        İşleniyor...
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <FaCloudUploadAlt /> &nbsp;
                                                        {"Güncelle"}
                                                    </>
                                                )
                                            }
                                        </Button>{' '}
                                        <Button color="danger" type="button" onClick={handleEditTreatmentModalToggle} className="d-flex align-items-center">
                                            <FaTimes />&nbsp;Vazgeç
                                        </Button>
                                    </ModalFooter>
                                </form>
                            </Modal>
                            {/* edit treatment modal end */}
                                    
                            {/* koklu dis checkbox modal */}
                            <Modal isOpen={handleTreatmentWithModal} 
                                        toggle={handleTreatmentWithModalToggle} 
                                        size={handleTreatmentModalSize}
                                        modalTransition={{ timeout: 1 }}
                                        backdropTransition={{ timeout: 1 }}
                                        >
                                        <ModalHeader toggle={handleTreatmentWithModalToggle} style={{color:"#1172b6",fontWeight:200, fontSize: 20}}>
                                            {kokluDisInfo?.tedaviTanim}
                                        </ModalHeader>
                                        <form >
                                            <ModalBody>
                                                <div className='d-flex justify-content-center'>
                                                    {
                                                        kokluDisInfo && kokluDisInfo?.kokler && kokluDisInfo.kokler.map((element,index) => {
                                                            return (
                                                                <Checkbox
                                                                key={index} 
                                                                checked={element.selected} 
                                                                value={element.kok}
                                                                onChange={(e) => {
                                                                    setKokluDisInfo(prevState => {
                                                                        return {
                                                                            ...prevState,
                                                                            kokler: prevState.kokler.map((element, index ) => {
                                                                                if(element.kok === e.target.value){
                                                                                    return {...element, selected: e.target.checked}
                                                                                }
                                                                                return element;
                                                                            }) 
                                                                        }
                                                                    })
                                                                }}
                                                                inputProps={{ 'aria-label': 'controlled' }} />
                                                            )
                                                        })
                                                    }
                                                    {
                                                        bolumluProtezDisInfo && bolumluProtezDisInfo?.disler?.map((element, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <p className='text-center'>{element.disNo}</p>
                                                                    <Checkbox
                                                                        checked={element.selected} 
                                                                        value={element.disNo}
                                                                        onChange={((e) => {
                                                                            setBolumluProtezDisInfo(prevState => {
                                                                                return {
                                                                                    ...prevState,
                                                                                    disler: prevState.disler.map((el) => {
                                                                                        if(el.disNo.toString() === e.target.value.toString()){
                                                                                            return {disNo: e.target.value, selected: e.target.checked}
                                                                                        }
                                                                                        return el;
                                                                                    })
                                                                                }
                                                                            })
                                                                        })}
                                                                        inputProps={{ 'aria-label': 'controlled' }} 
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    kokluDisInfo?.disImg && <div className='d-flex justify-content-center'>
                                                        <img src={kokluDisInfo?.disImg} alt="Secilen Dis" />
                                                    </div>
                                                }
                                                
                                            </ModalBody>

                                            <ModalFooter>
                                                <Button color="danger" type="button" onClick={handleTreatmentWithModalToggle} className="d-flex align-items-center">
                                                    <FaTimes />&nbsp;Vazgeç
                                                </Button>
                                                <Button color="primary" type="button" onClick={handleModalTreatment} style={{background: "#1172b6"}}  className="d-flex align-items-center">
                                                    <FaCloudUploadAlt />&nbsp;{"Kaydet"}
                                                </Button>
                                            </ModalFooter>
                                        </form>
                            </Modal>
                            {/* koklu dis checkbox modal end */}

                            {/* Treatment list table */}
                            <div>
                                {/* Treatment table */}
                                <TableContainer component={Paper}>
                                        {
                                            (patientMadeTreatmentsList?.filter((data) => data?.selected)).length > 0 &&
                                            <div className='d-flex justify-content-between pt-4'>
                                                <div className="d-flex justify-content-between align-items-center mb-2" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                                                    <span className="d-flex align-items-center" style={{paddingLeft: '10px', fontSize: '14px',fontWeight: 'bold', color: '#1172b6', opacity: '0.8'}}>
                                                        <strong>
                                                            {(patientMadeTreatmentsList.filter((data) => data.selected)).length} satır seçildi.
                                                        </strong>
                                                    </span>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    {
                                                        selectedTedaviType > 2 ?
                                                        
                                                        <Tooltip title="Seçilen tedavileri tedaviye aktar" arrow > 
                                                        <ButtonM variant="outlined" sx={{padding:'5px 8px',minWidth: '28px'}}
                                                            onClick={transferTedaviler}
                                                            color="info"> <FaExchangeAlt /> &nbsp; Tedaviye aktar</ButtonM>
                                                        </Tooltip>  
                                                        
                                                        :''
                                                    }&nbsp;
                                                  
                                                    <Tooltip title="Seçilen tedavileri sil" arrow > 
                                                        <ButtonM variant="outlined" sx={{padding:'5px 8px',minWidth: '28px'}}
                                                            onClick={(e) => removeSelectedTreatmentRows(e)}
                                                            color="error"> <FaTrashAlt /> &nbsp; Toplu Sil</ButtonM>
                                                    </Tooltip> &nbsp;
                                                    <Tooltip title="seçilen tedavileri düzenle" arrow > 
                                                        <ButtonM variant="outlined" sx={{padding:'5px 8px',minWidth: '28px'}}
                                                            onClick={(e) => editSelectedTreatmentAccount(e)}
                                                            color="warning"><FaEdit />&nbsp; Hesabı Düzenle</ButtonM>
                                                    </Tooltip>
                                                </div>
                                                {/* edit selected treatment accound Modal */}
                                                <Modal isOpen={editSelTreatAccModal} 
                                                    toggle={handleEditSelTreatAccModalToggle} 
                                                    size="md"
                                                    modalTransition={{ timeout: 1 }}
                                                    backdropTransition={{ timeout: 1 }}
                                                    >
                                                    <ModalHeader toggle={handleEditSelTreatAccModalToggle} style={{ color: "#1172b6", fontWeight: 200 }}>Hesap Bilgileri</ModalHeader>
                                                    <form onSubmit={updateSelTreatPrice}>
                                                        <ModalBody>
                                                            <div className="d-flex justify-content-center">
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="mt-1">
                                                                            <div className='row'>
                                                                                <div className='col-8 d-flex justify-content-end'>
                                                                                    <span>Genel Toplam:</span>
                                                                                </div>
                                                                                <div className='col-4 d-flex justify-content-start'>
                                                                                    <strong>{treatmentsTotalAmount}</strong>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-8 d-flex justify-content-end'>
                                                                                    <span>Seçilen Tedavilerin Toplamı:</span>
                                                                                </div>
                                                                                <div className='col-4 d-flex justify-content-start'>
                                                                                    <strong>
                                                                                        {
                                                                                            ConvertFloatToStringTRFormat(patientMadeTreatmentsList.reduce((a, c) => a + (c?.selected ? ConvertStringToFloat(c?.fiyat) : 0.00), 0.00))
                                                                                        }
                                                                                    </strong>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-8 d-flex justify-content-end '>
                                                                                    <div className='d-flex align-items-center '>
                                                                                        <span>Yüzdelik hesapla: &nbsp;&nbsp;</span>
                                                                                        <span>
                                                                                            <RadioGroup value={selTreatAccState?.radio} 
                                                                                                onChange={(e) => {
                                                                                                    let total = patientMadeTreatmentsList.reduce((a, c) => a + (c?.selected ? ConvertStringToFloat(c?.fiyat) : 0.00), 0.00);
                                                                                                    let totalPercentage = ( total * selTreatAccState?.deger) / 100; 
                                                                                                    let result = (e.target.value.toString() === "1" ? (total + totalPercentage ) : (total - totalPercentage));
                                                                                                    setSelTreatAccState({
                                                                                                        radio: e.target.value,
                                                                                                        sum: result
                                                                                                    });
                                                                                                }}>
                                                                                                <div className="d-flex flex-column">
                                                                                                    <span><FormControlLabel value={1} control={<Radio />} label="Arttır" /></span>
                                                                                                    <span><FormControlLabel value={0} control={<Radio />} label="Azalt" /></span>
                                                                                                </div>
                                                                                            </RadioGroup>
                                                                                        </span> 
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-4 d-flex justify-content-start align-items-center'>
                                                                                    <FormControl fullWidth>
                                                                                        <TextField className='form-group'
                                                                                            type="text"
                                                                                            name="yuzdeDeger"
                                                                                            placeholder='Yüzde'
                                                                                            variant='outlined'
                                                                                            onChange={(e) => {
                                                                                                let total = patientMadeTreatmentsList.reduce((a, c) => a + (c?.selected ? ConvertStringToFloat(c?.fiyat) : 0.00), 0.00);
                                                                                                let totalPercentage = ( total * e.target.value) / 100; 
                                                                                                let result = selTreatAccState.radio.toString() === "1" ? (total + totalPercentage ) : (total - totalPercentage)
                                                                                                setSelTreatAccState({
                                                                                                    deger: e.target.value,
                                                                                                    sum: result 
                                                                                                });
                                                                                            }}
                                                                                            InputProps={{
                                                                                                inputComponent: RateMask,
                                                                                                startAdornment: <InputAdornment position="start" ><FaPercentage /></InputAdornment>,
                                                                                            }}
                                                                                            value={selTreatAccState?.deger}
                                                                                            size='small'/>
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-8 d-flex justify-content-end '>
                                                                                    <span style={{color: '#ffbd4a', textAlign: 'end'}}>Seçilen tedavilerin yuvarlanacağı toplam tutar:</span>
                                                                                </div>
                                                                                <div className='col-4 d-flex justify-content-start'>
                                                                                    <FormControl fullWidth>
                                                                                        <TextField className='form-group'
                                                                                            type="text"
                                                                                            name="toplamYuvarlanacakDeger"
                                                                                            placeholder='Toplam'
                                                                                            variant='outlined'
                                                                                            onChange={(e) => {
                                                                                                setSelTreatAccState({
                                                                                                    sum: e.target.value,
                                                                                                    deger: 0,
                                                                                                });
                                                                                            }}
                                                                                            InputProps={{
                                                                                                inputComponent: CurrencyMask,
                                                                                                startAdornment: <InputAdornment position="start" ><FaLiraSign /></InputAdornment>,
                                                                                            }}
                                                                                            value={selTreatAccState?.sum}
                                                                                            size='small'/>
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ModalBody>

                                                        <ModalFooter>
                                                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} className="d-flex align-items-center" >
                                                                {
                                                                    editSelTreatAccProcessing ? 
                                                                    (
                                                                        <>
                                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                                            İşleniyor...
                                                                        </>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <FaCloudUploadAlt /> &nbsp; Güncelle
                                                                        </>
                                                                    )
                                                                }
                                                            </Button>{' '}
                                                            <Button color="danger" type="button" onClick={handleEditSelTreatAccModalToggle} className="d-flex align-items-center">
                                                                <FaTimes />&nbsp;Vazgeç
                                                            </Button>
                                                        </ModalFooter>
                                                    </form>
                                                </Modal>
                                            </div>
                                        }
                                        <Table size='small' sx={{marginTop: 3, minWidth:1000, '&:last-child td, &:last-child th': { border: 0 } , '& td, & tr': {fontSize: '12px', fontWeight: 'normal'}}}>
                                            <TableHead>
                                                <TableRow sx={{"& th": {color: "#fff",backgroundColor: "#50bdf3ad"}}} style={{position: 'sticky', top: '0', zIndex: '10'}}>
                                                    <TableCell align="center" width='5%'>Tedavi seç</TableCell>
                                                    <TableCell align="center" width='10%'>Tarih</TableCell>
                                                    <TableCell align="center" width='7%'>Diş</TableCell>
                                                    <TableCell align="center" width='30%'>Tedavi</TableCell>
                                                    <TableCell align="center" width='20%'>Doktor</TableCell>
                                                    <TableCell align="center" width='15%'>Fiyat</TableCell>
                                                    <TableCell align="center" width='10%'>İşlem</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {
                                        
                                                patientMadeTreatmentsList.map((row) => (
                                                <TableRow key={row.id} sx={{ 
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        '&:hover': {cursor: !row?.fatura_kesildi ? 'pointer': 'not-allowed'},
                                                        '& tr, & td': {color: !row?.fatura_kesildi ? '#000000de': '#ff9800'} 
                                                    }} >
                                                    <TableCell align="center" width='10%'>
                                                        {
                                                            !row?.fatura_kesildi && <Checkbox
                                                            checked={row?.selected}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            onChange={(e) => {
                                                                setPatientMadeTreatmentsList(prevState => {
                                                                    return prevState.map((el) => {
                                                                        if(el?.id === row?.id){
                                                                            return {...el, selected: e.target.checked}
                                                                        }
                                                                        return el;
                                                                    })
                                                                })
                                                            }}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center" width='10%'>{GetTrDate(row?.tarih)}</TableCell>
                                                    <TableCell align="center" width='7%'>
                                                        {
                                                            StringParseWithSignToArray(row?.disler, '-').length > 1 
                                                            ?
                                                            <Tooltip title={row?.disler} arrow>
                                                                <img src={row?.konum === 1 ? TopChinImg : BottomChinImg}  alt="teeth" />
                                                            </Tooltip>
                                                            :
                                                            row?.disler
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center" width='35%'>{row?.tedavi?.tedaviTanimi + (row?.kokler ? ' - '+row.kokler : '')}</TableCell>
                                                    <TableCell align="center" width='20%'>{row?.doktor?.name}</TableCell>
                                                    <TableCell align="center" width='15%'>{row?.fiyat} TRY</TableCell>
                                                    <TableCell align={"center"} width='10%'>
                                                        {   
                                                            !row?.fatura_kesildi ? 
                                                            <Stack direction="row" spacing={1} >
                                                                <Tooltip title="düzenle" arrow > 
                                                                    <ButtonM variant="outlined" disabled={!can('edit-hastalar-tedavi')} sx={{padding:'5px 8px',minWidth: '28px'}}
                                                                        onClick={(e) => editTreatment(e, row)}
                                                                        color="warning"><FaEdit /></ButtonM>
                                                                </Tooltip>
                                                                <Tooltip title="sil" arrow>
                                                                    <ButtonM variant="outlined" disabled={!can('delete-hastalar-tedavi')}  sx={{padding:'0',minWidth: '28px'}}
                                                                        onClick={(e) => deleteTreatmentFromList(e, row)}
                                                                        color="error"><FaTrash /></ButtonM>
                                                                </Tooltip>
                                                            </Stack>
                                                            :
                                                                <Tooltip title="Fatura kesilmiş tedavi düzenlenemez." arrow > 
                                                                <span style={{paddingRight:'5px',color:'#ff9800',fontSize:'15px'}} ><FaLock /></span>
                                                            </Tooltip>
                                                            
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                                <TableRow>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center" colSpan={5} style={{color:"#1172B6",fontSize:"15px",fontWeight:"450"}}>
                                                        { !isLoading ? patientMadeTreatmentsList.length === 0 ? 
                                                            "Tedavi Yok !":"" : <LoadingOverlay />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                </TableContainer>
                                <div className='d-flex justify-content-end p-2'>
                                    <strong>Toplam:</strong>&nbsp; <span>{treatmentsTotalAmount} TRY</span>                
                                </div>
                            </div>
                             {/* Treatment list table end*/}

                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(PatientTreatment);
