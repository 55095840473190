
// top daimi disler
import tooth18Img from 'assets/images/teeth/18.png';
import tooth17Img from 'assets/images/teeth/17.png';
import tooth16Img from 'assets/images/teeth/16.png';
import tooth15Img from 'assets/images/teeth/15.png';
import tooth14Img from 'assets/images/teeth/14.png';
import tooth13Img from 'assets/images/teeth/13.png';
import tooth12Img from 'assets/images/teeth/12.png';
import tooth11Img from 'assets/images/teeth/11.png';
import tooth21Img from 'assets/images/teeth/21.png';
import tooth22Img from 'assets/images/teeth/22.png';
import tooth23Img from 'assets/images/teeth/23.png';
import tooth24Img from 'assets/images/teeth/24.png';
import tooth25Img from 'assets/images/teeth/25.png';
import tooth26Img from 'assets/images/teeth/26.png';
import tooth27Img from 'assets/images/teeth/27.png';
import tooth28Img from 'assets/images/teeth/28.png';

// bottom daimi disler
import tooth48Img from 'assets/images/teeth/48.png';
import tooth47Img from 'assets/images/teeth/47.png';
import tooth46Img from 'assets/images/teeth/46.png';
import tooth45Img from 'assets/images/teeth/45.png';
import tooth44Img from 'assets/images/teeth/44.png';
import tooth43Img from 'assets/images/teeth/43.png';
import tooth42Img from 'assets/images/teeth/42.png';
import tooth41Img from 'assets/images/teeth/41.png';
import tooth31Img from 'assets/images/teeth/31.png';
import tooth32Img from 'assets/images/teeth/32.png';
import tooth33Img from 'assets/images/teeth/33.png';
import tooth34Img from 'assets/images/teeth/34.png';
import tooth35Img from 'assets/images/teeth/35.png';
import tooth36Img from 'assets/images/teeth/36.png';
import tooth37Img from 'assets/images/teeth/37.png';
import tooth38Img from 'assets/images/teeth/38.png';

// top sut disler
import tooth55Img from 'assets/images/teeth/55.png';
import tooth54Img from 'assets/images/teeth/54.png';
import tooth53Img from 'assets/images/teeth/53.png';
import tooth52Img from 'assets/images/teeth/52.png';
import tooth51Img from 'assets/images/teeth/51.png';
import tooth61Img from 'assets/images/teeth/61.png';
import tooth62Img from 'assets/images/teeth/62.png';
import tooth63Img from 'assets/images/teeth/63.png';
import tooth64Img from 'assets/images/teeth/64.png';
import tooth65Img from 'assets/images/teeth/65.png';

// bottom sut disler
import tooth85Img from 'assets/images/teeth/85.png';
import tooth84Img from 'assets/images/teeth/84.png';
import tooth83Img from 'assets/images/teeth/83.png';
import tooth82Img from 'assets/images/teeth/82.png';
import tooth81Img from 'assets/images/teeth/81.png';
import tooth71Img from 'assets/images/teeth/71.png';
import tooth72Img from 'assets/images/teeth/72.png';
import tooth73Img from 'assets/images/teeth/73.png';
import tooth74Img from 'assets/images/teeth/74.png';
import tooth75Img from 'assets/images/teeth/75.png';

export const toothImages = {
    topDaimiTooth: {
        no18: tooth18Img,
        no17: tooth17Img,
        no16: tooth16Img,
        no15: tooth15Img,
        no14: tooth14Img,
        no13: tooth13Img,
        no12: tooth12Img,
        no11: tooth11Img,
        no21: tooth21Img,
        no22: tooth22Img,
        no23: tooth23Img,
        no24: tooth24Img,
        no25: tooth25Img,
        no26: tooth26Img,
        no27: tooth27Img,
        no28: tooth28Img
    },
    bottomDaimiTooth: {
        no48: tooth48Img,
        no47: tooth47Img,
        no46: tooth46Img,
        no45: tooth45Img,
        no44: tooth44Img,
        no43: tooth43Img,
        no42: tooth42Img,
        no41: tooth41Img,
        no31: tooth31Img,
        no32: tooth32Img,
        no33: tooth33Img,
        no34: tooth34Img,
        no35: tooth35Img,
        no36: tooth36Img,
        no37: tooth37Img,
        no38: tooth38Img
    },
    topSutTooth: {
        no55: tooth55Img,
        no54: tooth54Img,
        no53: tooth53Img,
        no52: tooth52Img,
        no51: tooth51Img,
        no61: tooth61Img,
        no62: tooth62Img,
        no63: tooth63Img,
        no64: tooth64Img,
        no65: tooth65Img,
    },
    bottomSutTooth: {
        no85: tooth85Img,
        no84: tooth84Img,
        no83: tooth83Img,
        no82: tooth82Img,
        no81: tooth81Img,
        no71: tooth71Img,
        no72: tooth72Img,
        no73: tooth73Img,
        no74: tooth74Img,
        no75: tooth75Img,
    }
}