import Sidebar from 'components/sidebar/Sidebar';
import HeaderNav from 'components/header-nav/HeaderNav';
import React, { useState, useEffect } from 'react';
import { Outlet} from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainLayout = () => {

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);
    const [sidebarStyleInfo, setSidebarStyleInfo] = useState({});

    useEffect(() => {
        const mediaWatcher = window.matchMedia("(max-width: 992px)")
        setIsNarrowScreen(mediaWatcher.matches);
        //watch for updates
        function updateIsNarrowScreen(e) {
            setIsNarrowScreen(e.matches);
        }
        mediaWatcher.addEventListener('change', updateIsNarrowScreen)
        // clean up after ourselves
        return function cleanup() {
            mediaWatcher.removeEventListener('change', updateIsNarrowScreen)
        }
    }, [isNarrowScreen]);

    return (      
        <div className='app-p-container'>
            <div className="app-p-sidebar-wrapper" style={isNarrowScreen ? sidebarStyleInfo : { left: '0', transition: 'left .4s ease' }}>
                <Sidebar setSideBarStyle={setSidebarStyleInfo} />
            </div>
            <div className='app-p-content-wrapper'>
                <div className='header-nav'>
                    <HeaderNav
                        setSideBarStyle={setSidebarStyleInfo}
                    />
                </div>
                <div style={{width: '100%',height: '20px',position: 'relative',float: 'left',backgroundColor: '#f3f3f3'}}></div>
                <div className='content-wrapper'>
                    <div>
                        <Outlet />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}


export default MainLayout;
