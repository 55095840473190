
import * as yup from 'yup';  

const CreatePatientValidationSchema = () => {
    return yup.object().shape({

        ad_soyad: yup.string()
                .min(5,"Ad-soyad en az 5 karakter olmalıdır.")
                .max(50,"Ad-soyad en fazla 50 karakter olmalıdır.")
                .required("Lütfen geçerli bir ad-soyad bilgisi giriniz."),

        tc_no: yup.string()
                .min(11,"Lütfen 11 haneli TC kimlik numarası giriniz.")
                .max(11,"Lütfen 11 haneli TC kimlik numarası giriniz.")
                .required("Lütfen 11 haneli TC kimlik numarası giriniz."),

        tel_no: yup.string()
                .required("Lütfen cep telefon numarası giriniz."),

        email: yup.string()
                .email("Lütfen geçerli bir email adresi giriniz.")
                .max(50, "email adresi max 50 karakter olmalıdır.")
                .matches(
                    /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@!#$%&'*+-/=?^_`{|}~.]+$/,
                    "E-posta yalnızca ingilizce karakter barındırabilir"
                ),

        doktor_id: yup.number()
            .required("Lütfen diş-hekimi seçiniz.")
            .min(1,"Lütfen diş-hekimi seçiniz."),

        tarife_id: yup.number()
            .required("Lütfen Tarife seçiniz.")
            .min(1,"Lütfen Tarife seçiniz."),



   });
}   

export default CreatePatientValidationSchema;

