import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { trTR } from "@mui/x-data-grid";
import { createTheme } from "@mui/material/styles";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import { FaUserLock, FaSave, FaUserFriends } from 'react-icons/fa';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, FormControl, TextField, Box, FormControlLabel, Checkbox
} from "@mui/material";
import { TabPanel, TreeItem, TreeView } from "@mui/lab";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import { ConvertTurkishToEnglishCharacter } from 'extensions/ConvertTurkishToEnglishCharacter';
import { useFormik } from "formik";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";


function SettingsUserPermission(props) {

  const { auth } = props;
  const can = (permission) => {
    return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
  }
  const MySwal = withReactContent(Swal)

  const theme = createTheme(
    trTR, // x-data-grid translations
    turkishTr // core translations
  );

  const navigate = useNavigate();

  // state

  const [dataUsers, setDataUsers] = useState([]);
  const [hasUser, setHasUser] = useState('');
  const [updateUserList, setUpdateUserList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcesssing, setIsProcessing] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [visibleHastaDetay, setVisibleHastaDetay] = useState(false);
  const [visiblePermission, setVisiblePermission] = useState(false);
  const [visibleNoSelect, setVisibleNoSelect] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const [permissionHastalar, setPermissionHastalar] = useState([]);
  const [permissionHastalarTedavi, setPermissionHastalarTedavi] = useState([]);
  const [permissionHastalarHesap, setPermissionHastalarHesap] = useState([]);
  const [permissionHastalarFatura, setPermissionHastalarFatura] = useState([]);
  const [permissionHastalarRecete, setPermissionHastalarRecete] = useState([]);
  const [permissionHastalarGoruntu, setPermissionHastalarGoruntu] = useState([]);
  const [permissionRandevu, setPermissionRandevu] = useState([]);
  const [permissionCari, setPermissionCari] = useState([]);
  const [permissionGiderler, setPermissionGiderler] = useState([]);
  const [permissionLab, setPermissionLab] = useState([]);
  const [permissionStok, setPermissionStok] = useState([]);
  const [permissionStokDetay, setPermissionStokDetay] = useState([]);
  const [permissionKullanicilar, setPermissionKullanicilar] = useState([]);
  const [permissionRaporlar, setPermissionRaporlar] = useState([]);
  const [permissionTanimlamalarDoktor, setPermissionTanimlamalarDoktor] = useState([]);
  const [permissionTanimlamalarTarife, setPermissionTanimlamalarTarife] = useState([]);
  const [permissionTanimlamalarDiagnoz, setPermissionTanimlamalarDiagnoz] = useState([]);
  const [permissionTanimlamalarRandevuTedavi, setPermissionTanimlamalarRandevuTedavi] = useState([]);
  const [permissionTanimlamalarIlac, setPermissionTanimlamalarIlac] = useState([]);
  const [permissionTanimlamalarLaboratuvar, setPermissionTanimlamalarLaboratuvar] = useState([]);
  const [permissionTanimlamalarEndikasyon, setPermissionTanimlamalarEndikasyon] = useState([]);
  const [permissionAyarlarRandevu, setPermissionAyarlarRandevu] = useState([]);
  const [permissionAyarlarFatura, setPermissionAyarlarFatura] = useState([]);
  const [permissionAyarlarIletisim, setPermissionAyarlarIletisim] = useState([]);
  const [permissionAyarlarGenel, setPermissionAyarlarGenel] = useState([]);
  const [permissionKullaniciYetkiler, setPermissionKullaniciYetkiler] = useState([]);
  const [permissionSystemHistory, setPermissionSystemHistory] = useState([]);
  const [permissionDashboard, setPermissionDashboard] = useState([]);



  const [checkHastalar, setCheckHastalar] = useState([false, false, false, false, false]);
  const [checkHastalarTedavi, setCheckHastalarTedavi] = useState([false, false, false, false]);
  const [checkHastalarHesap, setCheckHastalarHesap] = useState([false, false, false, false]);
  const [checkHastalarFatura, setCheckHastalarFatura] = useState([false, false, false, false]);
  const [checkHastalarRecete, setCheckHastalarRecete] = useState([false, false, false, false]);
  const [checkHastalarGoruntu, setCheckHastalarGoruntu] = useState([false, false, false, false]);
  const [checkRandevu, setCheckRandevu] = useState([false, false, false, false]);
  const [checkCari, setCheckCari] = useState([false, false, false, false]);
  const [checkGiderler, setCheckGiderler] = useState([false, false, false, false]);
  const [checkLab, setCheckLab] = useState([false, false, false]);
  const [checkStok, setCheckStok] = useState([false, false, false, false]);
  const [checkStokDetay, setCheckStokDetay] = useState([false, false, false]);
  const [checkKullanicilar, setCheckKullanicilar] = useState([false, false, false, false]);
  const [checkTanimlamalar, setCheckTanimlamalar] = useState([false, false, false, false, false, false, false]);//Sadece kontrol amaçlı
  const [checkAyarlar, setCheckAyarlar] = useState([false, false, false, false, false]);//Sadece kontrol amaçlı
  const [checkRaporlar, setCheckRaporlar] = useState([false]); //Sadece kontrol amaçlı
  const [checkRaporlarContent, setCheckRaporlarContent] = useState([false, false, false, false, false, false, false, false, false, false, false]);
  const [checkTanimlamalarDoktor, setCheckTanimlamalarDoktor] = useState([false, false, false, false]);
  const [checkTanimlamalarTarife, setCheckTanimlamalarTarife] = useState([false, false, false, false, false, false, false]);
  const [checkTanimlamalarDiagnoz, setCheckTanimlamalarDiagnoz] = useState([false, false, false, false]);
  const [checkTanimlamalarRandevuTedavi, setCheckTanimlamalarRandevuTedavi] = useState([false, false, false, false]);
  const [checkTanimlamalarIlac, setCheckTanimlamalarIlac] = useState([false, false, false, false]);
  const [checkTanimlamalarLaboratuvar, setCheckTanimlamalarLaboratuvar] = useState([false, false, false, false]);
  const [checkTanimlamalarEndikasyon, setCheckTanimlamalarEndikasyon] = useState([false, false, false, false]);
  const [checkAyarlarRandevu, setCheckAyarlarRandevu] = useState([false, false]);
  const [checkAyarlarFatura, setCheckAyarlarFatura] = useState([false, false]);
  const [checkAyarlarIletisim, setCheckAyarlarIletisim] = useState([false, false]);
  const [checkAyarlarGenel, setCheckAyarlarGenel] = useState([false, false]);
  const [checkKullaniciYetkiler, setCheckKullaniciYetkiler] = useState([false, false]);
  const [checkSystemHistory, setCheckSystemHistory] = useState([false]);
  const [checkDashboard, setCheckDashboard] = useState([false]);




  const initialPerProcessInfo = {
    kullanici_id: '',
    yetkiler: '',
  };



  const formik = useFormik({
    initialValues: initialPerProcessInfo,
    onSubmit: (values) => {

      let permissionAssign = [];
      permissionHastalar.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionHastalarTedavi.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionHastalarHesap.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionHastalarFatura.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionHastalarRecete.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionHastalarGoruntu.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionRandevu.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionCari.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionGiderler.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionLab.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionStok.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionStokDetay.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionKullanicilar.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionRaporlar.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionTanimlamalarDoktor.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionTanimlamalarTarife.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionTanimlamalarDiagnoz.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionTanimlamalarRandevuTedavi.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionTanimlamalarIlac.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionTanimlamalarLaboratuvar.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionTanimlamalarEndikasyon.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionAyarlarRandevu.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionAyarlarFatura.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionAyarlarIletisim.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionAyarlarGenel.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionKullaniciYetkiler.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionDashboard.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      permissionSystemHistory.forEach((yetki) => {
        permissionAssign.push(yetki.permission);
      })
      if ((permissionTanimlamalarDoktor.length > 0) || (permissionTanimlamalarTarife.length > 0) || (permissionTanimlamalarDiagnoz.length > 0)
        || (permissionTanimlamalarRandevuTedavi.length > 0) || (permissionTanimlamalarIlac.length > 0) || (permissionTanimlamalarLaboratuvar.length > 0)) {
        permissionAssign.push('view-tanimlamalar');
      }
      if ((permissionAyarlarRandevu.length > 0) || (permissionAyarlarFatura.length > 0)
        || (permissionAyarlarIletisim.length > 0) || (permissionAyarlarGenel.length > 0)) {
        permissionAssign.push('view-ayarlar');
      }
      if (permissionRaporlar.length > 0) {
        permissionAssign.push('view-raporlama');
      }

      values.kullanici_id = hasUser.id;
      values.yetkiler = JSON.stringify(permissionAssign);

      MySwal.fire({
        title: 'Emin misin?',
        text: hasUser.name + " kişisine seçilen yetkiler atanacak!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Ata',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsProcessing(true);

          AxiosCrudActions.CreateAsync("kullanici-yetki", values).then(
            (result) => {
              setUpdateUserList(!updateUserList);
              setIsProcessing(false);
            }
          );
        }
      });

    },
  });


  //------------------------------------------------------------------------Hastalar----------------------------------------------
  const AllHastalar = (event) => {
    for (let i = 0; i < 5; i++) {
      const removeIndex = permissionHastalar.findIndex((permission) => permission.id === i);
      permissionHastalar.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionHastalar.concat({ id: 0, permission: 'view-hastalar' }, { id: 1, permission: 'create-hastalar' }, { id: 2, permission: 'edit-hastalar' }, { id: 3, permission: 'delete-hastalar' }, { id: 4, permission: 'view-all-hastalar' });
      setPermissionHastalar(arr);
    }
    setCheckHastalar([event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked]);
  };
  const hastalarModulu = (event) => {
    if (checkHastalar[0] === false) {
      let arr = permissionHastalar.concat({ id: 0, permission: 'view-hastalar' });
      setPermissionHastalar(arr);
    } else {
      const removeIndex = permissionHastalar.findIndex((permission) => permission.id === 0);
      permissionHastalar.splice(removeIndex, 1);
    }
    setCheckHastalar([event.target.checked, checkHastalar[1], checkHastalar[2], checkHastalar[3], checkHastalar[4]]);
  };
  const hastaEkle = (event) => {
    if (checkHastalar[1] === false) {
      let arr = permissionHastalar.concat({ id: 1, permission: 'create-hastalar' });
      setPermissionHastalar(arr);
    } else {
      const removeIndex = permissionHastalar.findIndex((permission) => permission.id === 1);
      permissionHastalar.splice(removeIndex, 1);
    }
    setCheckHastalar([checkHastalar[0], event.target.checked, checkHastalar[2], checkHastalar[3], checkHastalar[4]]);
  };

  const hastaGuncelle = (event) => {
    if (checkHastalar[2] === false) {
      let arr = permissionHastalar.concat({ id: 2, permission: 'edit-hastalar' });
      setPermissionHastalar(arr);
    } else {
      const removeIndex = permissionHastalar.findIndex((permission) => permission.id === 2);
      permissionHastalar.splice(removeIndex, 1);
    }
    setCheckHastalar([checkHastalar[0], checkHastalar[1], event.target.checked, checkHastalar[3], checkHastalar[4]]);
  };
  const hastaSilme = (event) => {
    if (checkHastalar[3] === false) {
      let arr4 = permissionHastalar.concat({ id: 3, permission: 'delete-hastalar' });
      setPermissionHastalar(arr4);
    } else {
      const removeIndex = permissionHastalar.findIndex((permission) => permission.id === 3);
      permissionHastalar.splice(removeIndex, 1);
    }
    setCheckHastalar([checkHastalar[0], checkHastalar[1], checkHastalar[2], event.target.checked, checkHastalar[4]]);
  };

  const tumHastaGoruntulama = (event) => {
    if (checkHastalar[4] === false) {
      let arr4 = permissionHastalar.concat({ id: 4, permission: 'view-all-hastalar' });
      setPermissionHastalar(arr4);
    } else {
      const removeIndex = permissionHastalar.findIndex((permission) => permission.id === 4);
      permissionHastalar.splice(removeIndex, 1);
    }
    setCheckHastalar([checkHastalar[0], checkHastalar[1], checkHastalar[2], checkHastalar[3], event.target.checked]);
  };

  const hastaDetailVisible = (event) => {
    if (event.target.checked) {
      setVisibleHastaDetay(true);
    } else {
      setVisibleHastaDetay(false);
    }
  }



  const hastalar = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Hastalar Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalar[0]} onChange={hastalarModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalar[1]} onChange={hastaEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalar[2]} onChange={hastaGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalar[3]} onChange={hastaSilme}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Tüm Hastaları Görüntüleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalar[4]} onChange={tumHastaGoruntulama}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Detay"
        style={{ color: "teal" }}
        control={<Checkbox checked={visibleHastaDetay} onChange={hastaDetailVisible}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Hastalar Tedavi----------------------------------------------
  const AllHastalarTedavi = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionHastalarTedavi.findIndex((permission) => permission.id === i);
      permissionHastalarTedavi.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionHastalarTedavi.concat({ id: 0, permission: 'view-hastalar-tedavi' }, { id: 1, permission: 'create-hastalar-tedavi' }, { id: 2, permission: 'edit-hastalar-tedavi' }, { id: 3, permission: 'delete-hastalar-tedavi' });
      setPermissionHastalarTedavi(arr);
    }
    setCheckHastalarTedavi([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const hastalarDetayiModulu = (event) => {
    if (checkHastalarTedavi[0] === false) {
      let arr = permissionHastalarTedavi.concat({ id: 0, permission: 'view-hastalar-tedavi' });
      setPermissionHastalarTedavi(arr);
    } else {
      const removeIndex = permissionHastalarTedavi.findIndex((permission) => permission.id === 0);
      permissionHastalarTedavi.splice(removeIndex, 1);
    }
    setCheckHastalarTedavi([event.target.checked, checkHastalarTedavi[1], checkHastalarTedavi[2], checkHastalarTedavi[3]]);
  };

  const hastaTedaviEkle = (event) => {
    if (checkHastalarTedavi[1] === false) {
      let arr = permissionHastalarTedavi.concat({ id: 1, permission: 'create-hastalar-tedavi' });
      setPermissionHastalarTedavi(arr);
    } else {
      const removeIndex = permissionHastalarTedavi.findIndex((permission) => permission.id === 1);
      permissionHastalarTedavi.splice(removeIndex, 1);
    }
    setCheckHastalarTedavi([checkHastalarTedavi[0], event.target.checked, checkHastalarTedavi[2], checkHastalarTedavi[3]]);
  };

  const hastaTedaviGuncelle = (event) => {
    if (checkHastalarTedavi[2] === false) {
      let arr = permissionHastalarTedavi.concat({ id: 2, permission: 'edit-hastalar-tedavi' });
      setPermissionHastalarTedavi(arr);
    } else {
      const removeIndex = permissionHastalarTedavi.findIndex((permission) => permission.id === 2);
      permissionHastalarTedavi.splice(removeIndex, 1);
    }
    setCheckHastalarTedavi([checkHastalarTedavi[0], checkHastalarTedavi[1], event.target.checked, checkHastalarTedavi[3]]);
  };

  const hastaTedaviSil = (event) => {
    if (checkHastalarTedavi[3] === false) {
      let arr = permissionHastalarTedavi.concat({ id: 3, permission: 'delete-hastalar-tedavi' });
      setPermissionHastalarTedavi(arr);
    } else {
      const removeIndex = permissionHastalarTedavi.findIndex((permission) => permission.id === 3);
      permissionHastalarTedavi.splice(removeIndex, 1);
    }
    setCheckHastalarTedavi([checkHastalarTedavi[0], checkHastalarTedavi[1], checkHastalarTedavi[2], event.target.checked]);
  };

  const hastalarTedavi = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Hasta Tedavi Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarTedavi[0]} onChange={hastalarDetayiModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Tedavi Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarTedavi[1]} onChange={hastaTedaviEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Tedavi Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarTedavi[2]} onChange={hastaTedaviGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Tedavi Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarTedavi[3]} onChange={hastaTedaviSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Hastalar Hesap----------------------------------------------
  const AllHastalarHesap = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionHastalarHesap.findIndex((permission) => permission.id === i);
      permissionHastalarHesap.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionHastalarHesap.concat({ id: 0, permission: 'view-hastalar-hesap' }, { id: 1, permission: 'create-hastalar-hesap' }, { id: 2, permission: 'edit-hastalar-hesap' }, { id: 3, permission: 'delete-hastalar-hesap' });
      setPermissionHastalarHesap(arr);
    }
    setCheckHastalarHesap([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const hastalarHesapModulu = (event) => {
    if (checkHastalarHesap[0] === false) {
      let arr = permissionHastalarHesap.concat({ id: 0, permission: 'view-hastalar-hesap' });
      setPermissionHastalarHesap(arr);
    } else {
      const removeIndex = permissionHastalarHesap.findIndex((permission) => permission.id === 0);
      permissionHastalarHesap.splice(removeIndex, 1);
    }
    setCheckHastalarHesap([event.target.checked, checkHastalarHesap[1], checkHastalarHesap[2], checkHastalarHesap[3]]);
  };

  const hastaHesapEkle = (event) => {
    if (checkHastalarHesap[1] === false) {
      let arr = permissionHastalarHesap.concat({ id: 1, permission: 'create-hastalar-hesap' });
      setPermissionHastalarHesap(arr);
    } else {
      const removeIndex = permissionHastalarHesap.findIndex((permission) => permission.id === 1);
      permissionHastalarHesap.splice(removeIndex, 1);
    }
    setCheckHastalarHesap([checkHastalarHesap[0], event.target.checked, checkHastalarHesap[2], checkHastalarHesap[3]]);
  };

  const hastaHesapGuncelle = (event) => {
    if (checkHastalarHesap[2] === false) {
      let arr = permissionHastalarHesap.concat({ id: 2, permission: 'edit-hastalar-hesap' });
      setPermissionHastalarHesap(arr);
    } else {
      const removeIndex = permissionHastalarHesap.findIndex((permission) => permission.id === 2);
      permissionHastalarHesap.splice(removeIndex, 1);
    }
    setCheckHastalarHesap([checkHastalarHesap[0], checkHastalarHesap[1], event.target.checked, checkHastalarHesap[3]]);
  };

  const hastaHesapSil = (event) => {
    if (checkHastalarHesap[3] === false) {
      let arr = permissionHastalarHesap.concat({ id: 3, permission: 'delete-hastalar-hesap' });
      setPermissionHastalarHesap(arr);
    } else {
      const removeIndex = permissionHastalarHesap.findIndex((permission) => permission.id === 3);
      permissionHastalarHesap.splice(removeIndex, 1);
    }
    setCheckHastalarHesap([checkHastalarHesap[0], checkHastalarHesap[1], checkHastalarHesap[2], event.target.checked]);
  };

  const hastalarHesap = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Hasta Hesap Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarHesap[0]} onChange={hastalarHesapModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Hesap Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarHesap[1]} onChange={hastaHesapEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Hesap Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarHesap[2]} onChange={hastaHesapGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Hesap Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarHesap[3]} onChange={hastaHesapSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );



  //------------------------------------------------------------------------Hastalar Fatura----------------------------------------------
  const AllHastalarFatura = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionHastalarFatura.findIndex((permission) => permission.id === i);
      permissionHastalarFatura.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionHastalarFatura.concat({ id: 0, permission: 'view-hastalar-fatura' }, { id: 1, permission: 'create-hastalar-fatura' }, { id: 2, permission: 'view-hastalar-fatura-detay' }, { id: 3, permission: 'delete-hastalar-fatura' });
      setPermissionHastalarFatura(arr);
    }
    setCheckHastalarFatura([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const hastalarFaturaModulu = (event) => {
    if (checkHastalarFatura[0] === false) {
      let arr = permissionHastalarFatura.concat({ id: 0, permission: 'view-hastalar-fatura' });
      setPermissionHastalarFatura(arr);
    } else {
      const removeIndex = permissionHastalarFatura.findIndex((permission) => permission.id === 4);
      permissionHastalarFatura.splice(removeIndex, 1);
    }
    setCheckHastalarFatura([event.target.checked, checkHastalarFatura[1], checkHastalarFatura[2], checkHastalarFatura[3]]);
  };

  const hastaFaturaEkle = (event) => {
    if (checkHastalarFatura[1] === false) {
      let arr = permissionHastalarFatura.concat({ id: 1, permission: 'create-hastalar-fatura' });
      setPermissionHastalarFatura(arr);
    } else {
      const removeIndex = permissionHastalarFatura.findIndex((permission) => permission.id === 1);
      permissionHastalarFatura.splice(removeIndex, 1);
    }
    setCheckHastalarFatura([checkHastalarFatura[0], event.target.checked, checkHastalarFatura[2], checkHastalarFatura[3]]);
  };

  const hastaFaturaGuncelle = (event) => {
    if (checkHastalarFatura[2] === false) {
      let arr = permissionHastalarFatura.concat({ id: 2, permission: 'view-hastalar-fatura-detay' });
      setPermissionHastalarFatura(arr);
    } else {
      const removeIndex = permissionHastalarFatura.findIndex((permission) => permission.id === 2);
      permissionHastalarFatura.splice(removeIndex, 1);
    }
    setCheckHastalarFatura([checkHastalarFatura[0], checkHastalarFatura[1], event.target.checked, checkHastalarFatura[3]]);
  };

  const hastaFaturaSil = (event) => {
    if (checkHastalarFatura[3] === false) {
      let arr = permissionHastalarFatura.concat({ id: 3, permission: 'delete-hastalar-fatura' });
      setPermissionHastalarFatura(arr);
    } else {
      const removeIndex = permissionHastalarFatura.findIndex((permission) => permission.id === 3);
      permissionHastalarFatura.splice(removeIndex, 1);
    }
    setCheckHastalarFatura([checkHastalarFatura[0], checkHastalarFatura[1], checkHastalarFatura[2], event.target.checked]);
  };

  const hastalarFatura = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Hasta Fatura Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarFatura[0]} onChange={hastalarFaturaModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Fatura Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarFatura[1]} onChange={hastaFaturaEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Fatura Detay"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarFatura[2]} onChange={hastaFaturaGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Fatura Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarFatura[3]} onChange={hastaFaturaSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Hastalar Reçete----------------------------------------------
  const AllHastalarRecete = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionHastalarRecete.findIndex((permission) => permission.id === i);
      permissionHastalarRecete.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionHastalarRecete.concat({ id: 0, permission: 'view-hastalar-recete' }, { id: 1, permission: 'create-hastalar-recete' }, { id: 2, permission: 'edit-hastalar-recete' }, { id: 3, permission: 'delete-hastalar-recete' });
      setPermissionHastalarRecete(arr);
    }
    setCheckHastalarRecete([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const hastalarReceteModulu = (event) => {
    if (checkHastalarRecete[0] === false) {
      let arr = permissionHastalarRecete.concat({ id: 0, permission: 'view-hastalar-recete' });
      setPermissionHastalarRecete(arr);
    } else {
      const removeIndex = permissionHastalarRecete.findIndex((permission) => permission.id === 0);
      permissionHastalarRecete.splice(removeIndex, 1);
    }
    setCheckHastalarRecete([event.target.checked, checkHastalarRecete[1], checkHastalarRecete[2], checkHastalarRecete[3]]);
  };

  const hastaReceteEkle = (event) => {
    if (checkHastalarRecete[1] === false) {
      let arr = permissionHastalarRecete.concat({ id: 1, permission: 'create-hastalar-recete' });
      setPermissionHastalarRecete(arr);
    } else {
      const removeIndex = permissionHastalarRecete.findIndex((permission) => permission.id === 1);
      permissionHastalarRecete.splice(removeIndex, 1);
    }
    setCheckHastalarRecete([checkHastalarRecete[0], event.target.checked, checkHastalarRecete[2], checkHastalarRecete[3]]);
  };

  const hastaReceteGuncelle = (event) => {
    if (checkHastalarRecete[2] === false) {
      let arr = permissionHastalarRecete.concat({ id: 2, permission: 'edit-hastalar-recete' });
      setPermissionHastalarRecete(arr);
    } else {
      const removeIndex = permissionHastalarRecete.findIndex((permission) => permission.id === 2);
      permissionHastalarRecete.splice(removeIndex, 1);
    }
    setCheckHastalarRecete([checkHastalarRecete[0], checkHastalarRecete[1], event.target.checked, checkHastalarRecete[3]]);
  };

  const hastaReceteSil = (event) => {
    if (checkHastalarRecete[3] === false) {
      let arr = permissionHastalarRecete.concat({ id: 3, permission: 'delete-hastalar-recete' });
      setPermissionHastalarRecete(arr);
    } else {
      const removeIndex = permissionHastalarRecete.findIndex((permission) => permission.id === 3);
      permissionHastalarRecete.splice(removeIndex, 1);
    }
    setCheckHastalarRecete([checkHastalarRecete[0], checkHastalarRecete[1], checkHastalarRecete[2], event.target.checked]);
  };

  const hastalarRecete = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Hasta Reçete Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarRecete[0]} onChange={hastalarReceteModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Reçete Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarRecete[1]} onChange={hastaReceteEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Reçete Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarRecete[2]} onChange={hastaReceteGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Reçete Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarRecete[3]} onChange={hastaReceteSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Hastalar Görüntü----------------------------------------------
  const AllHastalarGoruntu = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionHastalarGoruntu.findIndex((permission) => permission.id === i);
      permissionHastalarGoruntu.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionHastalarGoruntu.concat({ id: 0, permission: 'view-hastalar-goruntu' }, { id: 1, permission: 'create-hastalar-goruntu' }, { id: 2, permission: 'delete-hastalar-goruntu' }, { id: 3, permission: 'view-hastalar-randevu' });
      setPermissionHastalarGoruntu(arr);
    }
    setCheckHastalarGoruntu([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const hastalarGoruntuModulu = (event) => {
    if (checkHastalarGoruntu[0] === false) {
      let arr = permissionHastalarGoruntu.concat({ id: 0, permission: 'view-hastalar-goruntu' });
      setPermissionHastalarGoruntu(arr);
    } else {
      const removeIndex = permissionHastalarGoruntu.findIndex((permission) => permission.id === 0);
      permissionHastalarGoruntu.splice(removeIndex, 1);
    }
    setCheckHastalarGoruntu([event.target.checked, checkHastalarGoruntu[1], checkHastalarGoruntu[2], checkHastalarGoruntu[3]]);
  };

  const hastaGoruntuEkle = (event) => {
    if (checkHastalarGoruntu[1] === false) {
      let arr = permissionHastalarGoruntu.concat({ id: 1, permission: 'create-hastalar-goruntu' });
      setPermissionHastalarGoruntu(arr);
    } else {
      const removeIndex = permissionHastalarGoruntu.findIndex((permission) => permission.id === 1);
      permissionHastalarGoruntu.splice(removeIndex, 1);
    }
    setCheckHastalarGoruntu([checkHastalarGoruntu[0], event.target.checked, checkHastalarGoruntu[2], checkHastalarGoruntu[3]]);
  };

  const hastaGoruntuSil = (event) => {
    if (checkHastalarGoruntu[2] === false) {
      let arr = permissionHastalarGoruntu.concat({ id: 2, permission: 'delete-hastalar-goruntu' });
      setPermissionHastalarGoruntu(arr);
    } else {
      const removeIndex = permissionHastalarGoruntu.findIndex((permission) => permission.id === 2);
      permissionHastalarGoruntu.splice(removeIndex, 1);
    }
    setCheckHastalarGoruntu([checkHastalarGoruntu[0], checkHastalarGoruntu[1], event.target.checked, checkHastalarGoruntu[3]]);
  };

  const hastaRandevuGosterme = (event) => {
    if (checkHastalarGoruntu[3] === false) {
      let arr = permissionHastalarGoruntu.concat({ id: 3, permission: 'view-hastalar-randevu' });
      setPermissionHastalarGoruntu(arr);
    } else {
      const removeIndex = permissionHastalarGoruntu.findIndex((permission) => permission.id === 3);
      permissionHastalarGoruntu.splice(removeIndex, 1);
    }
    setCheckHastalarGoruntu([checkHastalarGoruntu[0], checkHastalarGoruntu[1], checkHastalarGoruntu[2], event.target.checked]);
  };

  const hastalarGoruntu = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Hasta Görüntü Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarGoruntu[0]} onChange={hastalarGoruntuModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Görüntü Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarGoruntu[1]} onChange={hastaGoruntuEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Görüntü Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarGoruntu[2]} onChange={hastaGoruntuSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hasta Randevu Görüntüleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkHastalarGoruntu[3]} onChange={hastaRandevuGosterme}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Randevular----------------------------------------------
  const AllRandevular = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionRandevu.findIndex((permission) => permission.id === i);
      permissionRandevu.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionRandevu.concat({ id: 0, permission: 'view-randevu' }, { id: 1, permission: 'create-randevu' }, { id: 2, permission: 'edit-randevu' }, { id: 3, permission: 'delete-randevu' });
      setPermissionRandevu(arr);
    }
    setCheckRandevu([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const randevularModulu = (event) => {
    if (checkRandevu[0] === false) {
      let arr = permissionRandevu.concat({ id: 0, permission: 'view-randevu' });
      setPermissionRandevu(arr);
    } else {
      const removeIndex = permissionRandevu.findIndex((permission) => permission.id === 0);
      permissionRandevu.splice(removeIndex, 1);
    }
    setCheckRandevu([event.target.checked, checkRandevu[1], checkRandevu[2], checkRandevu[3]]);
  };

  const randevuEkle = (event) => {
    if (checkRandevu[1] === false) {
      let arr = permissionRandevu.concat({ id: 1, permission: 'create-randevu' });
      setPermissionRandevu(arr);
    } else {
      const removeIndex = permissionRandevu.findIndex((permission) => permission.id === 1);
      permissionRandevu.splice(removeIndex, 1);
    }
    setCheckRandevu([checkRandevu[0], event.target.checked, checkRandevu[2], checkRandevu[3]]);
  };

  const randevuGuncelle = (event) => {
    if (checkRandevu[2] === false) {
      let arr = permissionRandevu.concat({ id: 2, permission: 'edit-randevu' });
      setPermissionRandevu(arr);
    } else {
      const removeIndex = permissionRandevu.findIndex((permission) => permission.id === 2);
      permissionRandevu.splice(removeIndex, 1);
    }
    setCheckRandevu([checkRandevu[0], checkRandevu[1], event.target.checked, checkRandevu[3]]);
  };

  const randevuSil = (event) => {
    if (checkRandevu[3] === false) {
      let arr = permissionRandevu.concat({ id: 3, permission: 'delete-randevu' });
      setPermissionRandevu(arr);
    } else {
      const removeIndex = permissionRandevu.findIndex((permission) => permission.id === 3);
      permissionRandevu.splice(removeIndex, 1);
    }
    setCheckRandevu([checkRandevu[0], checkRandevu[1], checkRandevu[2], event.target.checked]);
  };

  const randevular = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Randevu Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkRandevu[0]} onChange={randevularModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Randevu Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkRandevu[1]} onChange={randevuEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Randevu Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkRandevu[2]} onChange={randevuGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Randevu Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkRandevu[3]} onChange={randevuSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Cari----------------------------------------------
  const AllCariler = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionCari.findIndex((permission) => permission.id === i);
      permissionCari.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionCari.concat({ id: 0, permission: 'view-cariler' }, { id: 1, permission: 'create-cariler' }, { id: 2, permission: 'edit-cariler' }, { id: 3, permission: 'delete-cariler' });
      setPermissionCari(arr);
    }
    setCheckCari([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const cariModulu = (event) => {
    if (checkCari[0] === false) {
      let arr = permissionCari.concat({ id: 0, permission: 'view-cariler' });
      setPermissionCari(arr);
    } else {
      const removeIndex = permissionCari.findIndex((permission) => permission.id === 0);
      permissionCari.splice(removeIndex, 1);
    }
    setCheckCari([event.target.checked, checkCari[1], checkCari[2], checkCari[3]]);
  };

  const cariEkle = (event) => {
    if (checkCari[1] === false) {
      let arr = permissionCari.concat({ id: 1, permission: 'create-cariler' });
      setPermissionCari(arr);
    } else {
      const removeIndex = permissionCari.findIndex((permission) => permission.id === 1);
      permissionCari.splice(removeIndex, 1);
    }
    setCheckCari([checkCari[0], event.target.checked, checkCari[2], checkCari[3]]);
  };

  const cariGuncelle = (event) => {
    if (checkCari[2] === false) {
      let arr = permissionCari.concat({ id: 2, permission: 'edit-cariler' });
      setPermissionCari(arr);
    } else {
      const removeIndex = permissionCari.findIndex((permission) => permission.id === 2);
      permissionCari.splice(removeIndex, 1);
    }
    setCheckCari([checkCari[0], checkCari[1], event.target.checked, checkCari[3]]);
  };

  const cariSil = (event) => {
    if (checkCari[3] === false) {
      let arr = permissionCari.concat({ id: 3, permission: 'delete-cariler' });
      setPermissionCari(arr);
    } else {
      const removeIndex = permissionCari.findIndex((permission) => permission.id === 3);
      permissionCari.splice(removeIndex, 1);
    }
    setCheckCari([checkCari[0], checkCari[1], checkCari[2], event.target.checked]);
  };



  const cariler = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Cari Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkCari[0]} onChange={cariModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Cari Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkCari[1]} onChange={cariEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Cari Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkCari[2]} onChange={cariGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Cari Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkCari[3]} onChange={cariSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />

    </Box>
  );


  //------------------------------------------------------------------------Hizmet & Ödeme----------------------------------------------
  const AllGiderler = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionGiderler.findIndex((permission) => permission.id === i);
      permissionGiderler.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionGiderler.concat({ id: 0, permission: 'view-giderler' }, { id: 1, permission: 'create-giderler' }, { id: 2, permission: 'edit-giderler' }, { id: 3, permission: 'delete-giderler' });
      setPermissionGiderler(arr);
    }
    setCheckGiderler([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const giderlerModulu = (event) => {
    if (checkGiderler[0] === false) {
      let arr = permissionGiderler.concat({ id: 0, permission: 'view-giderler' });
      setPermissionGiderler(arr);
    } else {
      const removeIndex = permissionGiderler.findIndex((permission) => permission.id === 0);
      permissionGiderler.splice(removeIndex, 1);
    }
    setCheckGiderler([event.target.checked, checkGiderler[1], checkGiderler[2], checkGiderler[3]]);
  };

  const giderlerEkle = (event) => {
    if (checkGiderler[1] === false) {
      let arr = permissionGiderler.concat({ id: 1, permission: 'create-giderler' });
      setPermissionGiderler(arr);
    } else {
      const removeIndex = permissionGiderler.findIndex((permission) => permission.id === 1);
      permissionGiderler.splice(removeIndex, 1);
    }
    setCheckGiderler([checkGiderler[0], event.target.checked, checkGiderler[2], checkGiderler[3]]);
  };

  const giderlerGuncelle = (event) => {
    if (checkGiderler[2] === false) {
      let arr = permissionGiderler.concat({ id: 2, permission: 'edit-giderler' });
      setPermissionGiderler(arr);
    } else {
      const removeIndex = permissionGiderler.findIndex((permission) => permission.id === 2);
      permissionGiderler.splice(removeIndex, 1);
    }
    setCheckGiderler([checkGiderler[0], checkGiderler[1], event.target.checked, checkGiderler[3]]);
  };

  const giderlerSil = (event) => {
    if (checkGiderler[3] === false) {
      let arr = permissionGiderler.concat({ id: 3, permission: 'delete-giderler' });
      setPermissionGiderler(arr);
    } else {
      const removeIndex = permissionGiderler.findIndex((permission) => permission.id === 3);
      permissionGiderler.splice(removeIndex, 1);
    }
    setCheckGiderler([checkGiderler[0], checkGiderler[1], checkGiderler[2], event.target.checked]);
  };

  const giderler = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Cari Detay Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkGiderler[0]} onChange={giderlerModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hizmet & Ödeme Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkGiderler[1]} onChange={giderlerEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hizmet & Ödeme Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkGiderler[2]} onChange={giderlerGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Hizmet & Ödeme Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkGiderler[3]} onChange={giderlerSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Laboratuvar----------------------------------------------
  const AllLaboratuvar = (event) => {
    for (let i = 0; i < 3; i++) {
      const removeIndex = permissionLab.findIndex((permission) => permission.id === i);
      permissionLab.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionLab.concat({ id: 0, permission: 'view-laboratuvar' }, { id: 1, permission: 'create-laboratuvar' }, { id: 2, permission: 'delete-laboratuvar' });
      setPermissionLab(arr);
    }
    setCheckLab([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const labModulu = (event) => {
    if (checkLab[0] === false) {
      let arr = permissionLab.concat({ id: 0, permission: 'view-laboratuvar' });
      setPermissionLab(arr);
    } else {
      const removeIndex = permissionLab.findIndex((permission) => permission.id === 0);
      permissionLab.splice(removeIndex, 1);
    }
    setCheckLab([event.target.checked, checkLab[1], checkLab[2]]);
  };

  const labEkle = (event) => {
    if (checkLab[1] === false) {
      let arr = permissionLab.concat({ id: 1, permission: 'create-laboratuvar' });
      setPermissionLab(arr);
    } else {
      const removeIndex = permissionLab.findIndex((permission) => permission.id === 1);
      permissionLab.splice(removeIndex, 1);
    }
    setCheckLab([checkLab[0], event.target.checked, checkLab[2]]);
  };

  const labSil = (event) => {
    if (checkLab[2] === false) {
      let arr = permissionLab.concat({ id: 2, permission: 'delete-laboratuvar' });
      setPermissionLab(arr);
    } else {
      const removeIndex = permissionLab.findIndex((permission) => permission.id === 2);
      permissionLab.splice(removeIndex, 1);
    }
    setCheckLab([checkLab[0], checkLab[1], event.target.checked]);
  };

  const laboratuvar = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Laboratuvar Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkLab[0]} onChange={labModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Laboratuvar Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkLab[1]} onChange={labEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />

      <FormControlLabel
        label="Laboratuvar Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkLab[2]} onChange={labSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Stoklar----------------------------------------------
  const AllStoklar = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionStok.findIndex((permission) => permission.id === i);
      permissionStok.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionStok.concat({ id: 0, permission: 'view-stok' }, { id: 1, permission: 'create-stok' }, { id: 2, permission: 'edit-stok' }, { id: 3, permission: 'delete-stok' });
      setPermissionStok(arr);
    }
    setCheckStok([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const stokModulu = (event) => {
    if (checkStok[0] === false) {
      let arr = permissionStok.concat({ id: 0, permission: 'view-stok' });
      setPermissionStok(arr);
    } else {
      const removeIndex = permissionStok.findIndex((permission) => permission.id === 0);
      permissionStok.splice(removeIndex, 1);
    }
    setCheckStok([event.target.checked, checkStok[1], checkStok[2], checkStok[3]]);
  };

  const stokEkle = (event) => {
    if (checkStok[1] === false) {
      let arr = permissionStok.concat({ id: 1, permission: 'create-stok' });
      setPermissionStok(arr);
    } else {
      const removeIndex = permissionStok.findIndex((permission) => permission.id === 1);
      permissionStok.splice(removeIndex, 1);
    }
    setCheckStok([checkStok[0], event.target.checked, checkStok[2], checkStok[3]]);
  };

  const stokGuncelle = (event) => {
    if (checkStok[2] === false) {
      let arr = permissionStok.concat({ id: 2, permission: 'edit-stok' });
      setPermissionStok(arr);
    } else {
      const removeIndex = permissionStok.findIndex((permission) => permission.id === 2);
      permissionStok.splice(removeIndex, 1);
    }
    setCheckStok([checkStok[0], checkStok[1], event.target.checked, checkStok[3]]);
  };

  const stokSil = (event) => {
    if (checkStok[3] === false) {
      let arr = permissionStok.concat({ id: 4, permission: 'delete-stok' });
      setPermissionStok(arr);
    } else {
      const removeIndex = permissionStok.findIndex((permission) => permission.id === 4);
      permissionStok.splice(removeIndex, 1);
    }
    setCheckStok([checkStok[0], checkStok[1], checkStok[2], event.target.checked]);
  };

  const stoklar = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Stoklar Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkStok[0]} onChange={stokModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="stok Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkStok[1]} onChange={stokEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="stok Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkStok[2]} onChange={stokGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="stok Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkStok[3]} onChange={stokSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Stok Hareketleri----------------------------------------------
  const AllStokDetay = (event) => {
    for (let i = 0; i < 3; i++) {
      const removeIndex = permissionStokDetay.findIndex((permission) => permission.id === i);
      permissionStokDetay.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionStokDetay.concat({ id: 0, permission: 'view-stok-detay' }, { id: 1, permission: 'create-stok-detay' }, { id: 2, permission: 'delete-stok-detay' });
      setPermissionStokDetay(arr);
    }
    setCheckStokDetay([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const stokDetayModulu = (event) => {
    if (checkStokDetay[0] === false) {
      let arr = permissionStokDetay.concat({ id: 0, permission: 'view-stok-detay' });
      setPermissionStokDetay(arr);
    } else {
      const removeIndex = permissionStokDetay.findIndex((permission) => permission.id === 0);
      permissionStokDetay.splice(removeIndex, 1);
    }
    setCheckStokDetay([event.target.checked, checkStokDetay[1], checkStokDetay[2]]);
  };

  const stokDetayEkle = (event) => {
    if (checkStokDetay[1] === false) {
      let arr = permissionStokDetay.concat({ id: 1, permission: 'create-stok-detay' });
      setPermissionStokDetay(arr);
    } else {
      const removeIndex = permissionStokDetay.findIndex((permission) => permission.id === 1);
      permissionStokDetay.splice(removeIndex, 1);
    }
    setCheckStokDetay([checkStokDetay[0], event.target.checked, checkStokDetay[2]]);
  };



  const stokDetaySil = (event) => {
    if (checkStokDetay[2] === false) {
      let arr = permissionStokDetay.concat({ id: 2, permission: 'delete-stok-detay' });
      setPermissionStokDetay(arr);
    } else {
      const removeIndex = permissionStokDetay.findIndex((permission) => permission.id === 2);
      permissionStokDetay.splice(removeIndex, 1);
    }
    setCheckStokDetay([checkStokDetay[0], checkStokDetay[1], event.target.checked]);
  };

  const stokDetay = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Stok Hareketleri Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkStokDetay[0]} onChange={stokDetayModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="stok Hareketi Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkStokDetay[1]} onChange={stokDetayEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />

      <FormControlLabel
        label="stok Hareketi Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkStokDetay[2]} onChange={stokDetaySil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );

  //------------------------------------------------------------------------Kullanicilar----------------------------------------------
  const AllKullanicilar = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionKullanicilar.findIndex((permission) => permission.id === i);
      permissionKullanicilar.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionKullanicilar.concat({ id: 0, permission: 'view-kullanicilar' }, { id: 1, permission: 'create-kullanicilar' }, { id: 2, permission: 'edit-kullanicilar' }, { id: 3, permission: 'delete-kullanicilar' });
      setPermissionKullanicilar(arr);
    }
    setCheckKullanicilar([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const kullaniciModulu = (event) => {
    if (checkKullanicilar[0] === false) {
      let arr = permissionKullanicilar.concat({ id: 0, permission: 'view-kullanicilar' });
      setPermissionKullanicilar(arr);
    } else {
      const removeIndex = permissionKullanicilar.findIndex((permission) => permission.id === 0);
      permissionKullanicilar.splice(removeIndex, 1);
    }
    setCheckKullanicilar([event.target.checked, checkKullanicilar[1], checkKullanicilar[2], checkKullanicilar[3]]);
  };

  const kullaniciEkle = (event) => {
    if (checkKullanicilar[1] === false) {
      let arr = permissionKullanicilar.concat({ id: 1, permission: 'create-kullanicilar' });
      setPermissionKullanicilar(arr);
    } else {
      const removeIndex = permissionKullanicilar.findIndex((permission) => permission.id === 1);
      permissionKullanicilar.splice(removeIndex, 1);
    }
    setCheckKullanicilar([checkKullanicilar[0], event.target.checked, checkKullanicilar[2], checkKullanicilar[3]]);
  };

  const kullaniciGuncelle = (event) => {
    if (checkKullanicilar[2] === false) {
      let arr = permissionKullanicilar.concat({ id: 2, permission: 'edit-kullanicilar' });
      setPermissionKullanicilar(arr);
    } else {
      const removeIndex = permissionKullanicilar.findIndex((permission) => permission.id === 2);
      permissionKullanicilar.splice(removeIndex, 1);
    }
    setCheckKullanicilar([checkKullanicilar[0], checkKullanicilar[1], event.target.checked, checkKullanicilar[3]]);
  };

  const kullaniciSil = (event) => {
    if (checkKullanicilar[3] === false) {
      let arr = permissionKullanicilar.concat({ id: 3, permission: 'delete-kullanicilar' });
      setPermissionKullanicilar(arr);
    } else {
      const removeIndex = permissionKullanicilar.findIndex((permission) => permission.id === 3);
      permissionKullanicilar.splice(removeIndex, 1);
    }
    setCheckKullanicilar([checkKullanicilar[0], checkKullanicilar[1], checkKullanicilar[2], event.target.checked]);
  };

  const kullanicilar = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Kullanıcı Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkKullanicilar[0]} onChange={kullaniciModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Kullanıcı Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkKullanicilar[1]} onChange={kullaniciEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Kullanıcı Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkKullanicilar[2]} onChange={kullaniciGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Kullanıcı Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkKullanicilar[3]} onChange={kullaniciSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );

  //------------------------------------------------------------------------Tanımlamalar----------------------------------------------
  const AllTanimlamalar = (event) => {

    setCheckTanimlamalar([event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const tanimlamalarDoktor = (event) => {

    setCheckTanimlamalar([event.target.checked, checkTanimlamalar[1], checkTanimlamalar[2], checkTanimlamalar[3], checkTanimlamalar[4], checkTanimlamalar[5], checkTanimlamalar[6]]);
  };

  const tanimlamalarTarife = (event) => {

    setCheckTanimlamalar([checkTanimlamalar[0], event.target.checked, checkTanimlamalar[2], checkTanimlamalar[3], checkTanimlamalar[4], checkTanimlamalar[5], checkTanimlamalar[6]]);
  };

  const tanimlamalarDiagnoz = (event) => {

    setCheckTanimlamalar([checkTanimlamalar[0], checkTanimlamalar[1], event.target.checked, checkTanimlamalar[3], checkTanimlamalar[4], checkTanimlamalar[5], checkTanimlamalar[6]]);
  };
  const tanimlamalarRandevuTedavi = (event) => {

    setCheckTanimlamalar([checkTanimlamalar[0], checkTanimlamalar[1], checkTanimlamalar[2], event.target.checked, checkTanimlamalar[4], checkTanimlamalar[5], checkTanimlamalar[6]]);
  };

  const tanimlamalarIlac = (event) => {

    setCheckTanimlamalar([checkTanimlamalar[0], checkTanimlamalar[1], checkTanimlamalar[2], checkTanimlamalar[3], event.target.checked, checkTanimlamalar[5], checkTanimlamalar[6]]);
  };

  const tanimlamalarLab = (event) => {

    setCheckTanimlamalar([checkTanimlamalar[0], checkTanimlamalar[1], checkTanimlamalar[2], checkTanimlamalar[3], checkTanimlamalar[4], event.target.checked, checkTanimlamalar[6]]);
  };

  const tanimlamalarEndikasyon = (event) => {

    setCheckTanimlamalar([checkTanimlamalar[0], checkTanimlamalar[1], checkTanimlamalar[2], checkTanimlamalar[3], checkTanimlamalar[4], checkTanimlamalar[5], event.target.checked]);
  };


  const tanimlamalar = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Tanımlamalar Doktor"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkTanimlamalar[0]} onChange={tanimlamalarDoktor}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Tanımlamalar Tarife"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkTanimlamalar[1]} onChange={tanimlamalarTarife}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />

      <FormControlLabel
        label="Tanımlamalar Diagnoz"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkTanimlamalar[2]} onChange={tanimlamalarDiagnoz}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />

      <FormControlLabel
        label="Tanımlamalar Randevu Tedavi"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkTanimlamalar[3]} onChange={tanimlamalarRandevuTedavi}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />

      <FormControlLabel
        label="Tanımlamalar İlaç"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkTanimlamalar[4]} onChange={tanimlamalarIlac}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />

      <FormControlLabel
        label="Tanımlamalar Laboratuvar"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkTanimlamalar[5]} onChange={tanimlamalarLab}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />

      <FormControlLabel
        label="Tanımlamalar Endikasyon"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkTanimlamalar[6]} onChange={tanimlamalarEndikasyon}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Ayarlar----------------------------------------------
  const AllAyarlar = (event) => {

    setCheckAyarlar([event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const ayarlarRandevu = (event) => {
    setCheckAyarlar([event.target.checked, checkAyarlar[1], checkAyarlar[2], checkAyarlar[3], checkAyarlar[4]]);
  };

  const ayarlarFatura = (event) => {

    setCheckAyarlar([checkAyarlar[0], event.target.checked, checkAyarlar[2], checkAyarlar[3], checkAyarlar[4]]);
  };

  const ayarlarIletisim = (event) => {

    setCheckAyarlar([checkAyarlar[0], checkAyarlar[1], event.target.checked, checkAyarlar[3], checkAyarlar[4]]);
  };
  const ayarlarGenel = (event) => {

    setCheckAyarlar([checkAyarlar[0], checkAyarlar[1], checkAyarlar[2], event.target.checked, checkAyarlar[4]]);
  };

  const ayarlarYetkiler = (event) => {

    setCheckAyarlar([checkAyarlar[0], checkAyarlar[1], checkAyarlar[2], checkAyarlar[3], event.target.checked]);
  };





  const ayarlar = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Ayarlar Randevu"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkAyarlar[0]} onChange={ayarlarRandevu}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Ayarlar E-Fatura"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkAyarlar[1]} onChange={ayarlarFatura}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />

      <FormControlLabel
        label="Ayarlar İletişim"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkAyarlar[2]} onChange={ayarlarIletisim}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />

      <FormControlLabel
        label="Ayarlar Genel"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkAyarlar[3]} onChange={ayarlarGenel}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />

      <FormControlLabel
        label="Ayarlar Yetkiler"
        style={{ color: "teal" }}
        control={<Checkbox checked={checkAyarlar[4]} onChange={ayarlarYetkiler}
          sx={{
            color: 'teal',
            '&.Mui-checked': {
              color: 'teal',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Raporlar----------------------------------------------
  const AllRaporlar = (event) => {
    setCheckRaporlar([event.target.checked]);
  };
  const raporlarGelirAnalizi = (event) => {
    if (checkRaporlarContent[0] === false) {
      let arr = permissionRaporlar.concat({ id: 0, permission: 'view-raporlama-gelir-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 0);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([event.target.checked, checkRaporlarContent[1], checkRaporlarContent[2], checkRaporlarContent[3], checkRaporlarContent[4], checkRaporlarContent[5], checkRaporlarContent[6], checkRaporlarContent[7], checkRaporlarContent[8], checkRaporlarContent[9], checkRaporlarContent[10]]);
  };
  const raporlarTedaviAnalizi = (event) => {
    if (checkRaporlarContent[1] === false) {
      let arr = permissionRaporlar.concat({ id: 1, permission: 'view-raporlama-tedavi-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 1);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], event.target.checked, checkRaporlarContent[2], checkRaporlarContent[3], checkRaporlarContent[4], checkRaporlarContent[5], checkRaporlarContent[6], checkRaporlarContent[7], checkRaporlarContent[8], checkRaporlarContent[9], checkRaporlarContent[10]]);
  };
  const raporlarTedaviSayacAnalizi = (event) => {
    if (checkRaporlarContent[2] === false) {
      let arr = permissionRaporlar.concat({ id: 2, permission: 'view-raporlama-tedavi-sayac-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 2);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], checkRaporlarContent[1], event.target.checked, checkRaporlarContent[3], checkRaporlarContent[4], checkRaporlarContent[5], checkRaporlarContent[6], checkRaporlarContent[7], checkRaporlarContent[8], checkRaporlarContent[9], checkRaporlarContent[10]]);
  };
  const raporlarHastaAnalizi = (event) => {
    if (checkRaporlarContent[3] === false) {
      let arr = permissionRaporlar.concat({ id: 3, permission: 'view-raporlama-hasta-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 3);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], checkRaporlarContent[1], checkRaporlarContent[2], event.target.checked, checkRaporlarContent[4], checkRaporlarContent[5], checkRaporlarContent[6], checkRaporlarContent[7], checkRaporlarContent[8], checkRaporlarContent[9], checkRaporlarContent[10]]);
  };
  const raporlarLaboratuvarAnalizi = (event) => {
    if (checkRaporlarContent[4] === false) {
      let arr = permissionRaporlar.concat({ id: 4, permission: 'view-raporlama-laboratuvar-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 4);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], checkRaporlarContent[1], checkRaporlarContent[2], checkRaporlarContent[3], event.target.checked, checkRaporlarContent[5], checkRaporlarContent[6], checkRaporlarContent[7], checkRaporlarContent[8], checkRaporlarContent[9], checkRaporlarContent[10]]);
  };
  const raporlarFaturalar = (event) => {
    if (checkRaporlarContent[5] === false) {
      let arr = permissionRaporlar.concat({ id: 5, permission: 'view-raporlama-faturalar' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 5);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], checkRaporlarContent[1], checkRaporlarContent[2], checkRaporlarContent[3], checkRaporlarContent[4], event.target.checked, checkRaporlarContent[6], checkRaporlarContent[7], checkRaporlarContent[8], checkRaporlarContent[9], checkRaporlarContent[10]]);
  };
  const raporlarBorcAnalizi = (event) => {
    if (checkRaporlarContent[6] === false) {
      let arr = permissionRaporlar.concat({ id: 6, permission: 'view-raporlama-borc-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 6);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], checkRaporlarContent[1], checkRaporlarContent[2], checkRaporlarContent[3], checkRaporlarContent[4], checkRaporlarContent[5], event.target.checked, checkRaporlarContent[7], checkRaporlarContent[8], checkRaporlarContent[9], checkRaporlarContent[10]]);
  };
  const raporlarHakedisAnalizi = (event) => {
    if (checkRaporlarContent[7] === false) {
      let arr = permissionRaporlar.concat({ id: 7, permission: 'view-raporlama-hakedis-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 7);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], checkRaporlarContent[1], checkRaporlarContent[2], checkRaporlarContent[3], checkRaporlarContent[4], checkRaporlarContent[5], checkRaporlarContent[6], event.target.checked, checkRaporlarContent[8], checkRaporlarContent[9], checkRaporlarContent[10]]);
  };

  const raporlarPlanlamaAnalizi = (event) => {
    if (checkRaporlarContent[8] === false) {
      let arr = permissionRaporlar.concat({ id: 8, permission: 'view-raporlama-planlama-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 8);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], checkRaporlarContent[1], checkRaporlarContent[2], checkRaporlarContent[3], checkRaporlarContent[4], checkRaporlarContent[5], checkRaporlarContent[6], checkRaporlarContent[7], event.target.checked, checkRaporlarContent[9], checkRaporlarContent[10]]);
  };

  const raporlarGiderAnalizi = (event) => {
    if (checkRaporlarContent[9] === false) {
      let arr = permissionRaporlar.concat({ id: 9, permission: 'view-raporlama-gider-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 9);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], checkRaporlarContent[1], checkRaporlarContent[2], checkRaporlarContent[3], checkRaporlarContent[4], checkRaporlarContent[5], checkRaporlarContent[6], checkRaporlarContent[7], checkRaporlarContent[8], event.target.checked, checkRaporlarContent[10]]);
  };

  const raporlarDefterAnalizi = (event) => {
    if (checkRaporlarContent[10] === false) {
      let arr = permissionRaporlar.concat({ id: 10, permission: 'view-raporlama-defter-analizi' });
      setPermissionRaporlar(arr);
    } else {
      const removeIndex = permissionRaporlar.findIndex((permission) => permission.id === 10);
      permissionRaporlar.splice(removeIndex, 1);
    }
    setCheckRaporlarContent([checkRaporlarContent[0], checkRaporlarContent[1], checkRaporlarContent[2], checkRaporlarContent[3], checkRaporlarContent[4], checkRaporlarContent[5], checkRaporlarContent[6], checkRaporlarContent[7], checkRaporlarContent[8], checkRaporlarContent[9], event.target.checked]);
  };



  //------------------------------------------------------------------------Tanımlamalar Doktor----------------------------------------------
  const AllTanimlamalarDoktor = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionTanimlamalarDoktor.findIndex((permission) => permission.id === i);
      permissionTanimlamalarDoktor.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionTanimlamalarDoktor.concat({ id: 0, permission: 'view-doktorlar' }, { id: 1, permission: 'create-doktorlar' }, { id: 2, permission: 'edit-doktorlar' }, { id: 3, permission: 'delete-doktorlar' });
      setPermissionTanimlamalarDoktor(arr);
    }
    setCheckTanimlamalarDoktor([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const tanimlamalarDoktorModulu = (event) => {
    if (checkTanimlamalarDoktor[0] === false) {
      let arr = permissionTanimlamalarDoktor.concat({ id: 0, permission: 'view-doktorlar' });
      setPermissionTanimlamalarDoktor(arr);
    } else {
      const removeIndex = permissionTanimlamalarDoktor.findIndex((permission) => permission.id === 0);
      permissionTanimlamalarDoktor.splice(removeIndex, 1);
    }
    setCheckTanimlamalarDoktor([event.target.checked, checkTanimlamalarDoktor[1], checkTanimlamalarDoktor[2], checkTanimlamalarDoktor[3]]);
  };

  const tanimlamalarDoktorEkle = (event) => {
    if (checkTanimlamalarDoktor[1] === false) {
      let arr = permissionTanimlamalarDoktor.concat({ id: 1, permission: 'create-doktorlar' });
      setPermissionTanimlamalarDoktor(arr);
    } else {
      const removeIndex = permissionTanimlamalarDoktor.findIndex((permission) => permission.id === 1);
      permissionTanimlamalarDoktor.splice(removeIndex, 1);
    }
    setCheckTanimlamalarDoktor([checkTanimlamalarDoktor[0], event.target.checked, checkTanimlamalarDoktor[2], checkTanimlamalarDoktor[3]]);
  };

  const tanimlamalarDoktorGuncelle = (event) => {
    if (checkTanimlamalarDoktor[2] === false) {
      let arr = permissionTanimlamalarDoktor.concat({ id: 2, permission: 'edit-doktorlar' });
      setPermissionTanimlamalarDoktor(arr);
    } else {
      const removeIndex = permissionTanimlamalarDoktor.findIndex((permission) => permission.id === 2);
      permissionTanimlamalarDoktor.splice(removeIndex, 1);
    }
    setCheckTanimlamalarDoktor([checkTanimlamalarDoktor[0], checkTanimlamalarDoktor[1], event.target.checked, checkTanimlamalarDoktor[3]]);
  };

  const tanimlamalarDoktorSil = (event) => {
    if (checkTanimlamalarDoktor[3] === false) {
      let arr = permissionTanimlamalarDoktor.concat({ id: 3, permission: 'delete-doktorlar' });
      setPermissionTanimlamalarDoktor(arr);
    } else {
      const removeIndex = permissionTanimlamalarDoktor.findIndex((permission) => permission.id === 3);
      permissionTanimlamalarDoktor.splice(removeIndex, 1);
    }
    setCheckTanimlamalarDoktor([checkTanimlamalarDoktor[0], checkTanimlamalarDoktor[1], checkTanimlamalarDoktor[2], event.target.checked]);
  };

  const tanimlamalarDoctor = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Doktor Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarDoktor[0]} onChange={tanimlamalarDoktorModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Doktor Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarDoktor[1]} onChange={tanimlamalarDoktorEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Doktor Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarDoktor[2]} onChange={tanimlamalarDoktorGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Doktor Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarDoktor[3]} onChange={tanimlamalarDoktorSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Tanımlamalar Tarife----------------------------------------------
  const AllTanimlamalarTarife = (event) => {
    for (let i = 0; i < 7; i++) {
      const removeIndex = permissionTanimlamalarTarife.findIndex((permission) => permission.id === i);
      permissionTanimlamalarTarife.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionTanimlamalarTarife.concat({ id: 0, permission: 'view-tarifeler' }, { id: 1, permission: 'create-tarifeler' }, { id: 2, permission: 'edit-tarifeler' }, { id: 3, permission: 'delete-tarifeler' }
        , { id: 4, permission: 'create-tedaviler' }, { id: 5, permission: 'edit-tedaviler' }, { id: 6, permission: 'delete-tedaviler' }
      );
      setPermissionTanimlamalarTarife(arr);
    }
    setCheckTanimlamalarTarife([event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const tanimlamalarTarifeModulu = (event) => {
    if (checkTanimlamalarTarife[0] === false) {
      let arr = permissionTanimlamalarTarife.concat({ id: 0, permission: 'view-tarifeler' });
      setPermissionTanimlamalarTarife(arr);
    } else {
      const removeIndex = permissionTanimlamalarTarife.findIndex((permission) => permission.id === 0);
      permissionTanimlamalarTarife.splice(removeIndex, 1);
    }
    setCheckTanimlamalarTarife([event.target.checked, checkTanimlamalarTarife[1], checkTanimlamalarTarife[2], checkTanimlamalarTarife[3], checkTanimlamalarTarife[4], checkTanimlamalarTarife[5], checkTanimlamalarTarife[6]]);
  };

  const tanimlamalarTarifeEkle = (event) => {
    if (checkTanimlamalarTarife[1] === false) {
      let arr = permissionTanimlamalarTarife.concat({ id: 1, permission: 'create-tarifeler' });
      setPermissionTanimlamalarTarife(arr);
    } else {
      const removeIndex = permissionTanimlamalarTarife.findIndex((permission) => permission.id === 1);
      permissionTanimlamalarTarife.splice(removeIndex, 1);
    }
    setCheckTanimlamalarTarife([checkTanimlamalarTarife[0], event.target.checked, checkTanimlamalarTarife[2], checkTanimlamalarTarife[3], checkTanimlamalarTarife[4], checkTanimlamalarTarife[5], checkTanimlamalarTarife[6]]);
  };

  const tanimlamalarTarifeGuncelle = (event) => {
    if (checkTanimlamalarTarife[2] === false) {
      let arr = permissionTanimlamalarTarife.concat({ id: 2, permission: 'edit-tarifeler' });
      setPermissionTanimlamalarTarife(arr);
    } else {
      const removeIndex = permissionTanimlamalarTarife.findIndex((permission) => permission.id === 2);
      permissionTanimlamalarTarife.splice(removeIndex, 1);
    }
    setCheckTanimlamalarTarife([checkTanimlamalarTarife[0], checkTanimlamalarTarife[1], event.target.checked, checkTanimlamalarTarife[3], checkTanimlamalarTarife[4], checkTanimlamalarTarife[5], checkTanimlamalarTarife[6]]);
  };

  const tanimlamalarTarifeSil = (event) => {
    if (checkTanimlamalarTarife[3] === false) {
      let arr = permissionTanimlamalarTarife.concat({ id: 3, permission: 'delete-tarifeler' });
      setPermissionTanimlamalarTarife(arr);
    } else {
      const removeIndex = permissionTanimlamalarTarife.findIndex((permission) => permission.id === 3);
      permissionTanimlamalarTarife.splice(removeIndex, 1);
    }
    setCheckTanimlamalarTarife([checkTanimlamalarTarife[0], checkTanimlamalarTarife[1], checkTanimlamalarTarife[2], event.target.checked, checkTanimlamalarTarife[4], checkTanimlamalarTarife[5], checkTanimlamalarTarife[6]]);
  };

  const tanimlamalarTedaviEkle = (event) => {
    if (checkTanimlamalarTarife[4] === false) {
      let arr = permissionTanimlamalarTarife.concat({ id: 4, permission: 'create-tedaviler' });
      setPermissionTanimlamalarTarife(arr);
    } else {
      const removeIndex = permissionTanimlamalarTarife.findIndex((permission) => permission.id === 4);
      permissionTanimlamalarTarife.splice(removeIndex, 1);
    }
    setCheckTanimlamalarTarife([checkTanimlamalarTarife[0], checkTanimlamalarTarife[1], checkTanimlamalarTarife[2], checkTanimlamalarTarife[3], event.target.checked, checkTanimlamalarTarife[5], checkTanimlamalarTarife[6]]);
  };

  const tanimlamalarTedaviGuncelle = (event) => {
    if (checkTanimlamalarTarife[5] === false) {
      let arr = permissionTanimlamalarTarife.concat({ id: 5, permission: 'edit-tedaviler' });
      setPermissionTanimlamalarTarife(arr);
    } else {
      const removeIndex = permissionTanimlamalarTarife.findIndex((permission) => permission.id === 5);
      permissionTanimlamalarTarife.splice(removeIndex, 1);
    }
    setCheckTanimlamalarTarife([checkTanimlamalarTarife[0], checkTanimlamalarTarife[1], checkTanimlamalarTarife[2], checkTanimlamalarTarife[3], checkTanimlamalarTarife[4], event.target.checked, checkTanimlamalarTarife[6]]);
  };

  const tanimlamalarTedaviSil = (event) => {
    if (checkTanimlamalarTarife[6] === false) {
      let arr = permissionTanimlamalarTarife.concat({ id: 6, permission: 'delete-tedaviler' });
      setPermissionTanimlamalarTarife(arr);
    } else {
      const removeIndex = permissionTanimlamalarTarife.findIndex((permission) => permission.id === 6);
      permissionTanimlamalarTarife.splice(removeIndex, 1);
    }
    setCheckTanimlamalarTarife([checkTanimlamalarTarife[0], checkTanimlamalarTarife[1], checkTanimlamalarTarife[2], checkTanimlamalarTarife[3], checkTanimlamalarTarife[4], checkTanimlamalarTarife[5], event.target.checked]);
  };
  const tanimlamalarTarifee = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Tarife Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarTarife[0]} onChange={tanimlamalarTarifeModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Tarife Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarTarife[1]} onChange={tanimlamalarTarifeEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Tarife Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarTarife[2]} onChange={tanimlamalarTarifeGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Tarife Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarTarife[3]} onChange={tanimlamalarTarifeSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Tedavi Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarTarife[4]} onChange={tanimlamalarTedaviEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Tedavi Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarTarife[5]} onChange={tanimlamalarTedaviGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Tedavi Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarTarife[6]} onChange={tanimlamalarTedaviSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Tanımlamalar Diagnoz----------------------------------------------
  const AllTanimlamalarDiagnoz = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionTanimlamalarDiagnoz.findIndex((permission) => permission.id === i);
      permissionTanimlamalarDiagnoz.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionTanimlamalarDiagnoz.concat({ id: 0, permission: 'view-diagnozlar' }, { id: 1, permission: 'create-diagnozlar' }, { id: 2, permission: 'edit-diagnozlar' }, { id: 3, permission: 'delete-diagnozlar' });
      setPermissionTanimlamalarDiagnoz(arr);
    }
    setCheckTanimlamalarDiagnoz([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const tanimlamalarDiagnozModulu = (event) => {
    if (checkTanimlamalarDiagnoz[0] === false) {
      let arr = permissionTanimlamalarDiagnoz.concat({ id: 0, permission: 'view-diagnozlar' });
      setPermissionTanimlamalarDiagnoz(arr);
    } else {
      const removeIndex = permissionTanimlamalarDiagnoz.findIndex((permission) => permission.id === 0);
      permissionTanimlamalarDiagnoz.splice(removeIndex, 1);
    }
    setCheckTanimlamalarDiagnoz([event.target.checked, checkTanimlamalarDiagnoz[1], checkTanimlamalarDiagnoz[2], checkTanimlamalarDiagnoz[3]]);
  };

  const tanimlamalarDiagnozEkle = (event) => {
    if (checkTanimlamalarDiagnoz[1] === false) {
      let arr = permissionTanimlamalarDiagnoz.concat({ id: 1, permission: 'create-diagnozlar' });
      setPermissionTanimlamalarDiagnoz(arr);
    } else {
      const removeIndex = permissionTanimlamalarDiagnoz.findIndex((permission) => permission.id === 1);
      permissionTanimlamalarDiagnoz.splice(removeIndex, 1);
    }
    setCheckTanimlamalarDiagnoz([checkTanimlamalarDiagnoz[0], event.target.checked, checkTanimlamalarDiagnoz[2], checkTanimlamalarDiagnoz[3]]);
  };

  const tanimlamalarDiagnozGuncelle = (event) => {
    if (checkTanimlamalarDiagnoz[2] === false) {
      let arr = permissionTanimlamalarDiagnoz.concat({ id: 2, permission: 'edit-diagnozlar' });
      setPermissionTanimlamalarDiagnoz(arr);
    } else {
      const removeIndex = permissionTanimlamalarDiagnoz.findIndex((permission) => permission.id === 2);
      permissionTanimlamalarDiagnoz.splice(removeIndex, 1);
    }
    setCheckTanimlamalarDiagnoz([checkTanimlamalarDiagnoz[0], checkTanimlamalarDiagnoz[1], event.target.checked, checkTanimlamalarDiagnoz[3]]);
  };

  const tanimlamalarDiagnozSil = (event) => {
    if (checkTanimlamalarDiagnoz[3] === false) {
      let arr = permissionTanimlamalarDiagnoz.concat({ id: 3, permission: 'delete-diagnozlar' });
      setPermissionTanimlamalarDiagnoz(arr);
    } else {
      const removeIndex = permissionTanimlamalarDiagnoz.findIndex((permission) => permission.id === 3);
      permissionTanimlamalarDiagnoz.splice(removeIndex, 1);
    }
    setCheckTanimlamalarDiagnoz([checkTanimlamalarDiagnoz[0], checkTanimlamalarDiagnoz[1], checkTanimlamalarDiagnoz[2], event.target.checked]);
  };

  const tanimlamalariDiagnoz = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Diagnoz Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarDiagnoz[0]} onChange={tanimlamalarDiagnozModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Diagnoz Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarDiagnoz[1]} onChange={tanimlamalarDiagnozEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Diagnoz Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarDiagnoz[2]} onChange={tanimlamalarDiagnozGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Diagnoz Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarDiagnoz[3]} onChange={tanimlamalarDiagnozSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Tanımlamalar Randevu Tedavi----------------------------------------------
  const AllTanimlamalarRandevuTedavi = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionTanimlamalarRandevuTedavi.findIndex((permission) => permission.id === i);
      permissionTanimlamalarRandevuTedavi.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionTanimlamalarRandevuTedavi.concat({ id: 0, permission: 'view-randevu-tedaviler' }, { id: 1, permission: 'create-randevu-tedaviler' }, { id: 2, permission: 'edit-randevu-tedaviler' }, { id: 3, permission: 'delete-randevu-tedaviler' });
      setPermissionTanimlamalarRandevuTedavi(arr);
    }
    setCheckTanimlamalarRandevuTedavi([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const tanimlamalarRandevuTedaviModulu = (event) => {
    if (checkTanimlamalarRandevuTedavi[0] === false) {
      let arr = permissionTanimlamalarRandevuTedavi.concat({ id: 0, permission: 'view-randevu-tedaviler' });
      setPermissionTanimlamalarRandevuTedavi(arr);
    } else {
      const removeIndex = permissionTanimlamalarRandevuTedavi.findIndex((permission) => permission.id === 0);
      permissionTanimlamalarRandevuTedavi.splice(removeIndex, 1);
    }
    setCheckTanimlamalarRandevuTedavi([event.target.checked, checkTanimlamalarRandevuTedavi[1], checkTanimlamalarRandevuTedavi[2], checkTanimlamalarRandevuTedavi[3]]);
  };

  const tanimlamalarRandevuTedaviEkle = (event) => {
    if (checkTanimlamalarRandevuTedavi[1] === false) {
      let arr = permissionTanimlamalarRandevuTedavi.concat({ id: 1, permission: 'create-randevu-tedaviler' });
      setPermissionTanimlamalarRandevuTedavi(arr);
    } else {
      const removeIndex = permissionTanimlamalarRandevuTedavi.findIndex((permission) => permission.id === 1);
      permissionTanimlamalarRandevuTedavi.splice(removeIndex, 1);
    }
    setCheckTanimlamalarRandevuTedavi([checkTanimlamalarRandevuTedavi[0], event.target.checked, checkTanimlamalarRandevuTedavi[2], checkTanimlamalarRandevuTedavi[3]]);
  };

  const tanimlamalarRandevuTedaviGuncelle = (event) => {
    if (checkTanimlamalarRandevuTedavi[2] === false) {
      let arr = permissionTanimlamalarRandevuTedavi.concat({ id: 2, permission: 'edit-randevu-tedaviler' });
      setPermissionTanimlamalarRandevuTedavi(arr);
    } else {
      const removeIndex = permissionTanimlamalarRandevuTedavi.findIndex((permission) => permission.id === 2);
      permissionTanimlamalarRandevuTedavi.splice(removeIndex, 1);
    }
    setCheckTanimlamalarRandevuTedavi([checkTanimlamalarRandevuTedavi[0], checkTanimlamalarRandevuTedavi[1], event.target.checked, checkTanimlamalarRandevuTedavi[3]]);
  };

  const tanimlamalarRandevuTedaviSil = (event) => {
    if (checkTanimlamalarRandevuTedavi[3] === false) {
      let arr = permissionTanimlamalarRandevuTedavi.concat({ id: 3, permission: 'delete-randevu-tedaviler' });
      setPermissionTanimlamalarRandevuTedavi(arr);
    } else {
      const removeIndex = permissionTanimlamalarRandevuTedavi.findIndex((permission) => permission.id === 3);
      permissionTanimlamalarRandevuTedavi.splice(removeIndex, 1);
    }
    setCheckTanimlamalarRandevuTedavi([checkTanimlamalarRandevuTedavi[0], checkTanimlamalarRandevuTedavi[1], checkTanimlamalarRandevuTedavi[2], event.target.checked]);
  };

  const tanimlamalariRandevuTedavi = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Randevu Tedavi Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarRandevuTedavi[0]} onChange={tanimlamalarRandevuTedaviModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Randevu Tedavi Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarRandevuTedavi[1]} onChange={tanimlamalarRandevuTedaviEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Randevu Tedavi Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarRandevuTedavi[2]} onChange={tanimlamalarRandevuTedaviGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Randevu Tedavi Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarRandevuTedavi[3]} onChange={tanimlamalarRandevuTedaviSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );


  //------------------------------------------------------------------------Tanımlamalar Ilac----------------------------------------------
  const AllTanimlamalarIlac = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionTanimlamalarIlac.findIndex((permission) => permission.id === i);
      permissionTanimlamalarIlac.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionTanimlamalarIlac.concat({ id: 0, permission: 'view-ilaclar' }, { id: 1, permission: 'create-ilaclar' }, { id: 2, permission: 'edit-ilaclar' }, { id: 3, permission: 'delete-ilaclar' });
      setPermissionTanimlamalarIlac(arr);
    }
    setCheckTanimlamalarIlac([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const tanimlamalarIlacModulu = (event) => {
    if (checkTanimlamalarIlac[0] === false) {
      let arr = permissionTanimlamalarIlac.concat({ id: 0, permission: 'view-ilaclar' });
      setPermissionTanimlamalarIlac(arr);
    } else {
      const removeIndex = permissionTanimlamalarIlac.findIndex((permission) => permission.id === 0);
      permissionTanimlamalarIlac.splice(removeIndex, 1);
    }
    setCheckTanimlamalarIlac([event.target.checked, checkTanimlamalarIlac[1], checkTanimlamalarIlac[2], checkTanimlamalarIlac[3]]);
  };

  const tanimlamalarIlacEkle = (event) => {
    if (checkTanimlamalarIlac[1] === false) {
      let arr = permissionTanimlamalarIlac.concat({ id: 1, permission: 'create-ilaclar' });
      setPermissionTanimlamalarIlac(arr);
    } else {
      const removeIndex = permissionTanimlamalarIlac.findIndex((permission) => permission.id === 1);
      permissionTanimlamalarIlac.splice(removeIndex, 1);
    }
    setCheckTanimlamalarIlac([checkTanimlamalarIlac[0], event.target.checked, checkTanimlamalarIlac[2], checkTanimlamalarIlac[3]]);
  };

  const tanimlamalarIlacGuncelle = (event) => {
    if (checkTanimlamalarIlac[2] === false) {
      let arr = permissionTanimlamalarIlac.concat({ id: 2, permission: 'edit-ilaclar' });
      setPermissionTanimlamalarIlac(arr);
    } else {
      const removeIndex = permissionTanimlamalarIlac.findIndex((permission) => permission.id === 2);
      permissionTanimlamalarIlac.splice(removeIndex, 1);
    }
    setCheckTanimlamalarIlac([checkTanimlamalarIlac[0], checkTanimlamalarIlac[1], event.target.checked, checkTanimlamalarIlac[3]]);
  };

  const tanimlamalarIlacSil = (event) => {
    if (checkTanimlamalarIlac[3] === false) {
      let arr = permissionTanimlamalarIlac.concat({ id: 3, permission: 'delete-ilaclar' });
      setPermissionTanimlamalarIlac(arr);
    } else {
      const removeIndex = permissionTanimlamalarIlac.findIndex((permission) => permission.id === 3);
      permissionTanimlamalarIlac.splice(removeIndex, 1);
    }
    setCheckTanimlamalarIlac([checkTanimlamalarIlac[0], checkTanimlamalarIlac[1], checkTanimlamalarIlac[2], event.target.checked]);
  };

  const tanimlamalarIlaci = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="İlaç Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarIlac[0]} onChange={tanimlamalarIlacModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="İlaç Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarIlac[1]} onChange={tanimlamalarIlacEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="İlaç Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarIlac[2]} onChange={tanimlamalarIlacGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="İlaç Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarIlac[3]} onChange={tanimlamalarIlacSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );



  //------------------------------------------------------------------------Tanımlamalar Laboratuvar----------------------------------------------
  const AllTanimlamalarLaboratuvar = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionTanimlamalarLaboratuvar.findIndex((permission) => permission.id === i);
      permissionTanimlamalarLaboratuvar.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionTanimlamalarLaboratuvar.concat({ id: 0, permission: 'view-laboratuvar-islem' }, { id: 1, permission: 'create-laboratuvar-islem' }, { id: 2, permission: 'edit-laboratuvar-islem' }, { id: 3, permission: 'delete-laboratuvar-islem' });
      setPermissionTanimlamalarLaboratuvar(arr);
    }
    setCheckTanimlamalarLaboratuvar([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const tanimlamalarLaboratuvarModulu = (event) => {
    if (checkTanimlamalarLaboratuvar[0] === false) {
      let arr = permissionTanimlamalarLaboratuvar.concat({ id: 0, permission: 'view-laboratuvar-islem' });
      setPermissionTanimlamalarLaboratuvar(arr);
    } else {
      const removeIndex = permissionTanimlamalarLaboratuvar.findIndex((permission) => permission.id === 0);
      permissionTanimlamalarLaboratuvar.splice(removeIndex, 1);
    }
    setCheckTanimlamalarLaboratuvar([event.target.checked, checkTanimlamalarLaboratuvar[1], checkTanimlamalarLaboratuvar[2], checkTanimlamalarLaboratuvar[3]]);
  };

  const tanimlamalarLaboratuvarEkle = (event) => {
    if (checkTanimlamalarLaboratuvar[1] === false) {
      let arr = permissionTanimlamalarLaboratuvar.concat({ id: 1, permission: 'create-laboratuvar-islem' });
      setPermissionTanimlamalarLaboratuvar(arr);
    } else {
      const removeIndex = permissionTanimlamalarLaboratuvar.findIndex((permission) => permission.id === 1);
      permissionTanimlamalarLaboratuvar.splice(removeIndex, 1);
    }
    setCheckTanimlamalarLaboratuvar([checkTanimlamalarLaboratuvar[0], event.target.checked, checkTanimlamalarLaboratuvar[2], checkTanimlamalarLaboratuvar[3]]);
  };

  const tanimlamalarLaboratuvarGuncelle = (event) => {
    if (checkTanimlamalarLaboratuvar[2] === false) {
      let arr = permissionTanimlamalarLaboratuvar.concat({ id: 2, permission: 'edit-laboratuvar-islem' });
      setPermissionTanimlamalarLaboratuvar(arr);
    } else {
      const removeIndex = permissionTanimlamalarLaboratuvar.findIndex((permission) => permission.id === 2);
      permissionTanimlamalarLaboratuvar.splice(removeIndex, 1);
    }
    setCheckTanimlamalarLaboratuvar([checkTanimlamalarLaboratuvar[0], checkTanimlamalarLaboratuvar[1], event.target.checked, checkTanimlamalarLaboratuvar[3]]);
  };

  const tanimlamalarLaboratuvarSil = (event) => {
    if (checkTanimlamalarLaboratuvar[3] === false) {
      let arr = permissionTanimlamalarLaboratuvar.concat({ id: 3, permission: 'delete-laboratuvar-islem' });
      setPermissionTanimlamalarLaboratuvar(arr);
    } else {
      const removeIndex = permissionTanimlamalarLaboratuvar.findIndex((permission) => permission.id === 3);
      permissionTanimlamalarLaboratuvar.splice(removeIndex, 1);
    }
    setCheckTanimlamalarLaboratuvar([checkTanimlamalarLaboratuvar[0], checkTanimlamalarLaboratuvar[1], checkTanimlamalarLaboratuvar[2], event.target.checked]);
  };

  const tanimlamalarLaboratuvari = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Laboratuvar Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarLaboratuvar[0]} onChange={tanimlamalarLaboratuvarModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Laboratuvar Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarLaboratuvar[1]} onChange={tanimlamalarLaboratuvarEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Laboratuvar Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarLaboratuvar[2]} onChange={tanimlamalarLaboratuvarGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Laboratuvar Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarLaboratuvar[3]} onChange={tanimlamalarLaboratuvarSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );

  //------------------------------------------------------------------------Tanımlamalar Endikasyon----------------------------------------------
  const AllTanimlamalarEndikasyon = (event) => {
    for (let i = 0; i < 4; i++) {
      const removeIndex = permissionTanimlamalarEndikasyon.findIndex((permission) => permission.id === i);
      permissionTanimlamalarEndikasyon.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionTanimlamalarEndikasyon.concat({ id: 0, permission: 'view-endikasyonlar' }, { id: 1, permission: 'create-endikasyonlar' }, { id: 2, permission: 'edit-endikasyonlar' }, { id: 3, permission: 'delete-endikasyonlar' });
      setPermissionTanimlamalarEndikasyon(arr);
    }
    setCheckTanimlamalarEndikasyon([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);

  };
  const tanimlamalarEndikasyonModulu = (event) => {
    if (checkTanimlamalarEndikasyon[0] === false) {
      let arr = permissionTanimlamalarEndikasyon.concat({ id: 0, permission: 'view-endikasyonlar' });
      setPermissionTanimlamalarEndikasyon(arr);
    } else {
      const removeIndex = permissionTanimlamalarEndikasyon.findIndex((permission) => permission.id === 0);
      permissionTanimlamalarEndikasyon.splice(removeIndex, 1);
    }
    setCheckTanimlamalarEndikasyon([event.target.checked, checkTanimlamalarEndikasyon[1], checkTanimlamalarEndikasyon[2], checkTanimlamalarEndikasyon[3]]);
  };

  const tanimlamalarEndikasyonEkle = (event) => {
    if (checkTanimlamalarEndikasyon[1] === false) {
      let arr = permissionTanimlamalarEndikasyon.concat({ id: 1, permission: 'create-endikasyonlar' });
      setPermissionTanimlamalarEndikasyon(arr);
    } else {
      const removeIndex = permissionTanimlamalarEndikasyon.findIndex((permission) => permission.id === 1);
      permissionTanimlamalarEndikasyon.splice(removeIndex, 1);
    }
    setCheckTanimlamalarEndikasyon([checkTanimlamalarEndikasyon[0], event.target.checked, checkTanimlamalarEndikasyon[2], checkTanimlamalarEndikasyon[3]]);
  };

  const tanimlamalarEndikasyonGuncelle = (event) => {
    if (checkTanimlamalarEndikasyon[2] === false) {
      let arr = permissionTanimlamalarEndikasyon.concat({ id: 2, permission: 'edit-endikasyonlar' });
      setPermissionTanimlamalarEndikasyon(arr);
    } else {
      const removeIndex = permissionTanimlamalarEndikasyon.findIndex((permission) => permission.id === 2);
      permissionTanimlamalarEndikasyon.splice(removeIndex, 1);
    }
    setCheckTanimlamalarEndikasyon([checkTanimlamalarEndikasyon[0], checkTanimlamalarEndikasyon[1], event.target.checked, checkTanimlamalarEndikasyon[3]]);
  };

  const tanimlamalarEndikasyonSil = (event) => {
    if (checkTanimlamalarEndikasyon[3] === false) {
      let arr = permissionTanimlamalarEndikasyon.concat({ id: 3, permission: 'delete-endikasyonlar' });
      setPermissionTanimlamalarEndikasyon(arr);
    } else {
      const removeIndex = permissionTanimlamalarEndikasyon.findIndex((permission) => permission.id === 3);
      permissionTanimlamalarEndikasyon.splice(removeIndex, 1);
    }
    setCheckTanimlamalarEndikasyon([checkTanimlamalarEndikasyon[0], checkTanimlamalarEndikasyon[1], checkTanimlamalarEndikasyon[2], event.target.checked]);
  };

  const tanimlamalarEndikasyoni = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Endikasyon Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarEndikasyon[0]} onChange={tanimlamalarEndikasyonModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Endikasyon Ekleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarEndikasyon[1]} onChange={tanimlamalarEndikasyonEkle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Endikasyon Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarEndikasyon[2]} onChange={tanimlamalarEndikasyonGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Endikasyon Silme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkTanimlamalarEndikasyon[3]} onChange={tanimlamalarEndikasyonSil}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );
  //------------------------------------------------------------------------Ayarlar Randevu----------------------------------------------
  const AllAyarlarRandevu = (event) => {
    for (let i = 0; i < 2; i++) {
      const removeIndex = permissionAyarlarRandevu.findIndex((permission) => permission.id === i);
      permissionAyarlarRandevu.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionAyarlarRandevu.concat({ id: 0, permission: 'view-randevu-ayarlar' }, { id: 1, permission: 'edit-randevu-ayarlar' });
      setPermissionAyarlarRandevu(arr);
    }
    setCheckAyarlarRandevu([event.target.checked, event.target.checked]);

  };
  const AyarlarRandevuModulu = (event) => {
    if (checkAyarlarRandevu[0] === false) {
      let arr = permissionAyarlarRandevu.concat({ id: 0, permission: 'view-randevu-ayarlar' });
      setPermissionAyarlarRandevu(arr);
    } else {
      const removeIndex = permissionAyarlarRandevu.findIndex((permission) => permission.id === 0);
      permissionAyarlarRandevu.splice(removeIndex, 1);
    }
    setCheckAyarlarRandevu([event.target.checked, checkAyarlarRandevu[1]]);
  };

  const AyarlarRandevuGuncelle = (event) => {
    if (checkAyarlarRandevu[1] === false) {
      let arr = permissionAyarlarRandevu.concat({ id: 1, permission: 'edit-randevu-ayarlar' });
      setPermissionAyarlarRandevu(arr);
    } else {
      const removeIndex = permissionAyarlarRandevu.findIndex((permission) => permission.id === 1);
      permissionAyarlarRandevu.splice(removeIndex, 1);
    }
    setCheckAyarlarRandevu([checkAyarlarRandevu[0], event.target.checked]);
  };



  const AyarlarRandevu = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Randevu Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkAyarlarRandevu[0]} onChange={AyarlarRandevuModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Randevu Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkAyarlarRandevu[1]} onChange={AyarlarRandevuGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );

  //------------------------------------------------------------------------Ayarlar Fatura----------------------------------------------
  const AllAyarlarFatura = (event) => {
    for (let i = 0; i < 2; i++) {
      const removeIndex = permissionAyarlarFatura.findIndex((permission) => permission.id === i);
      permissionAyarlarFatura.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionAyarlarFatura.concat({ id: 0, permission: 'view-e-fatura-ayarlar' }, { id: 1, permission: 'edit-e-fatura-ayarlar' });
      setPermissionAyarlarFatura(arr);
    }
    setCheckAyarlarFatura([event.target.checked, event.target.checked]);

  };
  const AyarlarFaturaModulu = (event) => {
    if (checkAyarlarFatura[0] === false) {
      let arr = permissionAyarlarFatura.concat({ id: 0, permission: 'view-e-fatura-ayarlar' });
      setPermissionAyarlarFatura(arr);
    } else {
      const removeIndex = permissionAyarlarFatura.findIndex((permission) => permission.id === 0);
      permissionAyarlarFatura.splice(removeIndex, 1);
    }
    setCheckAyarlarFatura([event.target.checked, checkAyarlarFatura[1]]);
  };

  const AyarlarFaturaGuncelle = (event) => {
    if (checkAyarlarFatura[1] === false) {
      let arr = permissionAyarlarFatura.concat({ id: 1, permission: 'edit-e-fatura-ayarlar' });
      setPermissionAyarlarFatura(arr);
    } else {
      const removeIndex = permissionAyarlarFatura.findIndex((permission) => permission.id === 1);
      permissionAyarlarFatura.splice(removeIndex, 1);
    }
    setCheckAyarlarFatura([checkAyarlarFatura[0], event.target.checked]);
  };



  const AyarlarFatura = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Fatura Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkAyarlarFatura[0]} onChange={AyarlarFaturaModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="E-Fatura Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkAyarlarFatura[1]} onChange={AyarlarFaturaGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );

  //------------------------------------------------------------------------Ayarlar Iletisim----------------------------------------------
  const AllAyarlarIletisim = (event) => {
    for (let i = 0; i < 2; i++) {
      const removeIndex = permissionAyarlarIletisim.findIndex((permission) => permission.id === i);
      permissionAyarlarIletisim.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionAyarlarIletisim.concat({ id: 0, permission: 'view-iletisim-ayarlar' }, { id: 1, permission: 'edit-iletisim-ayarlar' });
      setPermissionAyarlarIletisim(arr);
    }
    setCheckAyarlarIletisim([event.target.checked, event.target.checked]);

  };
  const AyarlarIletisimModulu = (event) => {
    if (checkAyarlarIletisim[0] === false) {
      let arr = permissionAyarlarIletisim.concat({ id: 0, permission: 'view-iletisim-ayarlar' });
      setPermissionAyarlarIletisim(arr);
    } else {
      const removeIndex = permissionAyarlarIletisim.findIndex((permission) => permission.id === 0);
      permissionAyarlarIletisim.splice(removeIndex, 1);
    }
    setCheckAyarlarIletisim([event.target.checked, checkAyarlarIletisim[1]]);
  };

  const AyarlarIletisimGuncelle = (event) => {
    if (checkAyarlarIletisim[1] === false) {
      let arr = permissionAyarlarIletisim.concat({ id: 1, permission: 'edit-iletisim-ayarlar' });
      setPermissionAyarlarIletisim(arr);
    } else {
      const removeIndex = permissionAyarlarIletisim.findIndex((permission) => permission.id === 1);
      permissionAyarlarIletisim.splice(removeIndex, 1);
    }
    setCheckAyarlarIletisim([checkAyarlarIletisim[0], event.target.checked]);
  };



  const AyarlarIletisim = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Iletisim Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkAyarlarIletisim[0]} onChange={AyarlarIletisimModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Iletisim Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkAyarlarIletisim[1]} onChange={AyarlarIletisimGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );

  //------------------------------------------------------------------------Ayarlar Genel----------------------------------------------
  const AllAyarlarGenel = (event) => {
    for (let i = 0; i < 2; i++) {
      const removeIndex = permissionAyarlarGenel.findIndex((permission) => permission.id === i);
      permissionAyarlarGenel.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionAyarlarGenel.concat({ id: 0, permission: 'view-genel-ayarlar' }, { id: 1, permission: 'edit-genel-ayarlar' });
      setPermissionAyarlarGenel(arr);
    }
    setCheckAyarlarGenel([event.target.checked, event.target.checked]);

  };
  const AyarlarGenelModulu = (event) => {
    if (checkAyarlarGenel[0] === false) {
      let arr = permissionAyarlarGenel.concat({ id: 0, permission: 'view-genel-ayarlar' });
      setPermissionAyarlarGenel(arr);
    } else {
      const removeIndex = permissionAyarlarGenel.findIndex((permission) => permission.id === 0);
      permissionAyarlarGenel.splice(removeIndex, 1);
    }
    setCheckAyarlarGenel([event.target.checked, checkAyarlarGenel[1]]);
  };

  const AyarlarGenelGuncelle = (event) => {
    if (checkAyarlarGenel[1] === false) {
      let arr = permissionAyarlarGenel.concat({ id: 1, permission: 'edit-genel-ayarlar' });
      setPermissionAyarlarGenel(arr);
    } else {
      const removeIndex = permissionAyarlarGenel.findIndex((permission) => permission.id === 1);
      permissionAyarlarGenel.splice(removeIndex, 1);
    }
    setCheckAyarlarGenel([checkAyarlarGenel[0], event.target.checked]);
  };



  const AyarlarGenel = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Genel Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkAyarlarGenel[0]} onChange={AyarlarGenelModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Genel Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkAyarlarGenel[1]} onChange={AyarlarGenelGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );

  //------------------------------------------------------------------------Ayarlar Yetkiler----------------------------------------------
  const AllKullaniciYetkiler = (event) => {
    for (let i = 0; i < 2; i++) {
      const removeIndex = permissionKullaniciYetkiler.findIndex((permission) => permission.id === i);
      permissionKullaniciYetkiler.splice(removeIndex, 1);
    }
    if (event.target.checked) {
      let arr = permissionKullaniciYetkiler.concat({ id: 0, permission: 'view-yetkiler' }, { id: 1, permission: 'edit-yetkiler' });
      setPermissionKullaniciYetkiler(arr);
    }
    setCheckKullaniciYetkiler([event.target.checked, event.target.checked]);

  };
  const KullaniciYetkilerModulu = (event) => {
    if (checkKullaniciYetkiler[0] === false) {
      let arr = permissionKullaniciYetkiler.concat({ id: 0, permission: 'view-yetkiler' });
      setPermissionKullaniciYetkiler(arr);
    } else {
      const removeIndex = permissionKullaniciYetkiler.findIndex((permission) => permission.id === 0);
      permissionKullaniciYetkiler.splice(removeIndex, 1);
    }
    setCheckKullaniciYetkiler([event.target.checked, checkKullaniciYetkiler[1]]);
  };

  const KullaniciYetkilerGuncelle = (event) => {
    if (checkKullaniciYetkiler[1] === false) {
      let arr = permissionKullaniciYetkiler.concat({ id: 1, permission: 'edit-yetkiler' });
      setPermissionKullaniciYetkiler(arr);
    } else {
      const removeIndex = permissionKullaniciYetkiler.findIndex((permission) => permission.id === 1);
      permissionKullaniciYetkiler.splice(removeIndex, 1);
    }
    setCheckKullaniciYetkiler([checkKullaniciYetkiler[0], event.target.checked]);
  };



  const KullaniciYetkileri = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Yetkiler Modülü"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkKullaniciYetkiler[0]} onChange={KullaniciYetkilerModulu}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
      <FormControlLabel
        label="Yetki Güncelleme"
        style={{ color: "indigo" }}
        control={<Checkbox checked={checkKullaniciYetkiler[1]} onChange={KullaniciYetkilerGuncelle}
          sx={{
            color: 'indigo',
            '&.Mui-checked': {
              color: 'indigo',
            },
          }}
        />}
      />
    </Box>
  );

  //------------------------------------------------------------------------Sistem Geçmişi----------------------------------------------

  const SystemHistory = (event) => {
    if (checkSystemHistory[0] === false) {
      let arr = permissionSystemHistory.concat({ id: 0, permission: 'view-loglar' });
      setPermissionSystemHistory(arr);
    } else {
      const removeIndex = permissionSystemHistory.findIndex((permission) => permission.id === 0);
      permissionSystemHistory.splice(removeIndex, 1);
    }
    setCheckSystemHistory([event.target.checked]);
  };

  //------------------------------------------------------------------------Dashboard----------------------------------------------

  const DashboardGorunum = (event) => {
    if (checkDashboard[0] === false) {
      let arr = permissionDashboard.concat({ id: 0, permission: 'view-dashboard' });
      setPermissionDashboard(arr);
    } else {
      const removeIndex = permissionDashboard.findIndex((permission) => permission.id === 0);
      permissionDashboard.splice(removeIndex, 1);
    }
    setCheckDashboard([event.target.checked]);
  };

  //-------------------------------------------------------------------------------------------------------------------

  const callUserPermission = (user) => {

    const selectUser = filteredUsers.find(Search => {
      return Search.id === user?.id;
    });

    const permissionAble = (permission) => {
      return (selectUser.permissions || []).find((p) => p === permission) ? true : false;
    }

    setHasUser(user);

    setCheckHastalar([permissionAble('view-hastalar'), permissionAble('create-hastalar'), permissionAble('edit-hastalar'), permissionAble('delete-hastalar'), permissionAble('view-all-hastalar')]);
    setCheckHastalarTedavi([permissionAble('view-hastalar-tedavi'), permissionAble('create-hastalar-tedavi'), permissionAble('edit-hastalar-tedavi'), permissionAble('delete-hastalar-tedavi')]);
    setCheckHastalarHesap([permissionAble('view-hastalar-hesap'), permissionAble('create-hastalar-hesap'), permissionAble('edit-hastalar-hesap'), permissionAble('delete-hastalar-hesap')]);
    setCheckHastalarFatura([permissionAble('view-hastalar-fatura'), permissionAble('create-hastalar-fatura'), permissionAble('view-hastalar-fatura-detay'), permissionAble('delete-hastalar-fatura')]);
    setCheckHastalarRecete([permissionAble('view-hastalar-recete'), permissionAble('create-hastalar-recete'), permissionAble('edit-hastalar-recete'), permissionAble('delete-hastalar-recete')]);
    setCheckHastalarGoruntu([permissionAble('view-hastalar-goruntu'), permissionAble('create-hastalar-goruntu'), permissionAble('delete-hastalar-goruntu'), permissionAble('view-hastalar-randevu')]);
    setCheckRandevu([permissionAble('view-randevu'), permissionAble('create-randevu'), permissionAble('edit-randevu'), permissionAble('delete-randevu')])
    setCheckCari([permissionAble('view-cariler'), permissionAble('create-cariler'), permissionAble('edit-cariler'), permissionAble('delete-cariler')])
    setCheckGiderler([permissionAble('view-giderler'), permissionAble('create-giderler'), permissionAble('edit-giderler'), permissionAble('delete-giderler')])
    setCheckLab([permissionAble('view-laboratuvar'), permissionAble('create-laboratuvar'), permissionAble('delete-laboratuvar')])
    setCheckStok([permissionAble('view-stok'), permissionAble('create-stok'), permissionAble('edit-stok'), permissionAble('delete-stok')])
    setCheckStokDetay([permissionAble('view-stok-detay'), permissionAble('create-stok-detay'), permissionAble('delete-stok-detay')])
    setCheckKullanicilar([permissionAble('view-kullanicilar'), permissionAble('create-kullanicilar'), permissionAble('edit-kullanicilar'), permissionAble('delete-kullanicilar')])


    setCheckRaporlarContent([permissionAble('view-raporlama-gelir-analizi'), permissionAble('view-raporlama-tedavi-analizi'), permissionAble('view-raporlama-tedavi-sayac-analizi'),
    permissionAble('view-raporlama-hasta-analizi'), permissionAble('view-raporlama-laboratuvar-analizi'), permissionAble('view-raporlama-faturalar'), permissionAble('view-raporlama-borc-analizi'),
    permissionAble('view-raporlama-hakedis-analizi'), permissionAble('view-raporlama-planlama-analizi'), permissionAble('view-raporlama-gider-analizi'), permissionAble('view-raporlama-defter-analizi')])
    setCheckTanimlamalarDoktor([permissionAble('view-doktorlar'), permissionAble('create-doktorlar'), permissionAble('edit-doktorlar'), permissionAble('delete-doktorlar')])
    setCheckTanimlamalarTarife([permissionAble('view-tarifeler'), permissionAble('create-tarifeler'), permissionAble('edit-tarifeler'), permissionAble('delete-tarifeler'),
    permissionAble('create-tedaviler'), permissionAble('edit-tedaviler'), permissionAble('delete-tedaviler')])
    setCheckTanimlamalarDiagnoz([permissionAble('view-diagnozlar'), permissionAble('create-diagnozlar'), permissionAble('edit-diagnozlar'), permissionAble('delete-diagnozlar')])
    setCheckTanimlamalarRandevuTedavi([permissionAble('view-randevu-tedaviler'), permissionAble('create-randevu-tedaviler'), permissionAble('edit-randevu-tedaviler'), permissionAble('delete-randevu-tedaviler')])
    setCheckTanimlamalarIlac([permissionAble('view-ilaclar'), permissionAble('create-ilaclar'), permissionAble('edit-ilaclar'), permissionAble('delete-ilaclar')])
    setCheckTanimlamalarLaboratuvar([permissionAble('view-laboratuvar-islem'), permissionAble('create-laboratuvar-islem'), permissionAble('edit-laboratuvar-islem'), permissionAble('delete-laboratuvar-islem')])
    setCheckTanimlamalarEndikasyon([permissionAble('view-endikasyonlar'), permissionAble('create-endikasyonlar'), permissionAble('edit-endikasyonlar'), permissionAble('delete-endikasyonlar')])
    setCheckKullaniciYetkiler([permissionAble('view-yetkiler'), permissionAble('edit-yetkiler')]);
    setCheckAyarlarRandevu([permissionAble('view-randevu-ayarlar'), permissionAble('edit-randevu-ayarlar')]);
    setCheckAyarlarFatura([permissionAble('view-e-fatura-ayarlar'), permissionAble('edit-e-fatura-ayarlar')]);
    setCheckAyarlarIletisim([permissionAble('view-iletisim-ayarlar'), permissionAble('edit-iletisim-ayarlar')]);
    setCheckAyarlarGenel([permissionAble('view-genel-ayarlar'), permissionAble('edit-genel-ayarlar')]);
    setCheckDashboard([permissionAble('view-dashboard')]);
    setCheckSystemHistory([permissionAble('view-loglar')]);


    if (permissionAble('view-hastalar-tedavi') || permissionAble('create-hastalar-tedavi') || permissionAble('edit-hastalar-tedavi') || permissionAble('delete-hastalar-tedavi')
      || permissionAble('view-hastalar-hesap') || permissionAble('create-hastalar-hesap') || permissionAble('edit-hastalar-hesap') || permissionAble('delete-hastalar-hesap')
      || permissionAble('view-hastalar-fatura') || permissionAble('view-hastalar-fatura-detay') || permissionAble('create-hastalar-fatura') || permissionAble('delete-hastalar-fatura')
      || permissionAble('view-hastalar-recete') || permissionAble('create-hastalar-recete') || permissionAble('edit-hastalar-recete') || permissionAble('delete-hastalar-recete')
      || permissionAble('view-hastalar-goruntu') || permissionAble('create-hastalar-goruntu') || permissionAble('delete-hastalar-goruntu') || permissionAble('view-hastalar-randevu')) {
      setVisibleHastaDetay(true);
    } else {
      setVisibleHastaDetay(false);
    }

    if (permissionAble('view-doktorlar') || permissionAble('create-doktorlar') || permissionAble('edit-doktorlar') || permissionAble('delete-doktorlar')
      || permissionAble('view-tarifeler') || permissionAble('create-tarifeler') || permissionAble('edit-tarifeler') || permissionAble('delete-tarifeler')
      || permissionAble('create-tedaviler') || permissionAble('edit-tedaviler') || permissionAble('delete-tedaviler') || permissionAble('view-diagnozlar')
      || permissionAble('create-diagnozlar') || permissionAble('edit-diagnozlar') || permissionAble('delete-diagnozlar') || permissionAble('view-randevu-tedaviler')
      || permissionAble('create-randevu-tedaviler') || permissionAble('edit-randevu-tedaviler') || permissionAble('delete-randevu-tedaviler') || permissionAble('view-ilaclar')
      || permissionAble('create-ilaclar') || permissionAble('edit-ilaclar') || permissionAble('delete-ilaclar') || permissionAble('view-laboratuvar-islem') || permissionAble('create-laboratuvar-islem')
      || permissionAble('edit-laboratuvar-islem') || permissionAble('delete-laboratuvar-islem') || permissionAble('view-endikasyonlar') || permissionAble('create-endikasyonlar')
      || permissionAble('edit-endikasyonlar') || permissionAble('delete-endikasyonlar')) {
      setCheckTanimlamalar([true, true, true, true, true, true, true]);
    } else {
      setCheckTanimlamalar([false, false, false, false, false, false, false])
    }

    if (permissionAble('view-randevu-ayarlar') || permissionAble('edit-randevu-ayarlar') || permissionAble('view-e-fatura-ayarlar') || permissionAble('edit-e-fatura-ayarlar')
      || permissionAble('view-iletisim-ayarlar') || permissionAble('edit-iletisim-ayarlar') || permissionAble('view-genel-ayarlar') || permissionAble('edit-genel-ayarlar')) {
      setCheckAyarlar([true, true, true, true, true]);
    } else {
      setCheckAyarlar([false, false, false, false, false]);
    }
    if (permissionAble('view-raporlama-gelir-analizi') || permissionAble('view-raporlama-tedavi-analizi') || permissionAble('view-raporlama-tedavi-sayac-analizi')
      || permissionAble('view-raporlama-hasta-analizi') || permissionAble('view-raporlama-laboratuvar-analizi') || permissionAble('view-raporlama-faturalar') || permissionAble('view-raporlama-borc-analizi')
      || permissionAble('view-raporlama-hakedis-analizi') || permissionAble('view-raporlama-planlama-analizi') || permissionAble('view-raporlama-gider-analizi') || permissionAble('view-raporlama-defter-analizi')) {
      setCheckRaporlar([true]);
    } else {
      setCheckRaporlar([false]);
    }

    let hastalarhasPermission = [];
    if (permissionAble('view-hastalar')) {
      hastalarhasPermission.push({ id: 0, permission: 'view-hastalar' });
    }
    if (permissionAble('create-hastalar')) {
      hastalarhasPermission.push({ id: 1, permission: 'create-hastalar' });
    }
    if (permissionAble('edit-hastalar')) {
      hastalarhasPermission.push({ id: 2, permission: 'edit-hastalar' });
    }
    if (permissionAble('delete-hastalar')) {
      hastalarhasPermission.push({ id: 3, permission: 'delete-hastalar' });
    }
    setPermissionHastalar(hastalarhasPermission);

    let hastalarTedaviPermission = [];
    if (permissionAble('view-hastalar-tedavi')) {
      hastalarTedaviPermission.push({ id: 0, permission: 'view-hastalar-tedavi' });
    }
    if (permissionAble('create-hastalar-tedavi')) {
      hastalarTedaviPermission.push({ id: 1, permission: 'create-hastalar-tedavi' });
    }
    if (permissionAble('edit-hastalar-tedavi')) {
      hastalarTedaviPermission.push({ id: 2, permission: 'edit-hastalar-tedavi' });
    }
    if (permissionAble('delete-hastalar-tedavi')) {
      hastalarTedaviPermission.push({ id: 3, permission: 'delete-hastalar-tedavi' });
    }
    setPermissionHastalarTedavi(hastalarTedaviPermission);



    let hastalarHesapPermission = [];
    if (permissionAble('view-hastalar-hesap')) {
      hastalarHesapPermission.push({ id: 0, permission: 'view-hastalar-hesap' });
    }
    if (permissionAble('create-hastalar-hesap')) {
      hastalarHesapPermission.push({ id: 1, permission: 'create-hastalar-hesap' });
    }
    if (permissionAble('edit-hastalar-hesap')) {
      hastalarHesapPermission.push({ id: 2, permission: 'edit-hastalar-hesap' });
    }
    if (permissionAble('delete-hastalar-hesap')) {
      hastalarHesapPermission.push({ id: 3, permission: 'delete-hastalar-hesap' });
    }
    setPermissionHastalarHesap(hastalarHesapPermission);


    let hastalarFaturaPermission = [];
    if (permissionAble('view-hastalar-fatura')) {
      hastalarFaturaPermission.push({ id: 0, permission: 'view-hastalar-fatura' });
    }
    if (permissionAble('create-hastalar-fatura')) {
      hastalarFaturaPermission.push({ id: 1, permission: 'create-hastalar-fatura' });
    }
    if (permissionAble('view-hastalar-fatura-detay')) {
      hastalarFaturaPermission.push({ id: 2, permission: 'view-hastalar-fatura-detay' });
    }
    if (permissionAble('delete-hastalar-fatura')) {
      hastalarFaturaPermission.push({ id: 3, permission: 'delete-hastalar-fatura' });
    }
    setPermissionHastalarFatura(hastalarFaturaPermission);



    let hastalarRecetePermission = [];
    if (permissionAble('view-hastalar-recete')) {
      hastalarRecetePermission.push({ id: 0, permission: 'view-hastalar-recete' });
    }
    if (permissionAble('create-hastalar-recete')) {
      hastalarRecetePermission.push({ id: 1, permission: 'create-hastalar-recete' });
    }
    if (permissionAble('edit-hastalar-recete')) {
      hastalarRecetePermission.push({ id: 2, permission: 'edit-hastalar-recete' });
    }
    if (permissionAble('delete-hastalar-recete')) {
      hastalarRecetePermission.push({ id: 3, permission: 'delete-hastalar-recete' });
    }
    setPermissionHastalarRecete(hastalarRecetePermission);


    let hastalarGoruntuPermission = [];
    if (permissionAble('view-hastalar-goruntu')) {
      hastalarGoruntuPermission.push({ id: 0, permission: 'view-hastalar-goruntu' });
    }
    if (permissionAble('create-hastalar-goruntu')) {
      hastalarGoruntuPermission.push({ id: 1, permission: 'create-hastalar-goruntu' });
    }
    if (permissionAble('delete-hastalar-goruntu')) {
      hastalarGoruntuPermission.push({ id: 2, permission: 'delete-hastalar-goruntu' });
    }
    if (permissionAble('view-hastalar-randevu')) {
      hastalarGoruntuPermission.push({ id: 3, permission: 'view-hastalar-randevu' });
    }
    setPermissionHastalarGoruntu(hastalarGoruntuPermission);


    let hastalarRandevuPermission = [];
    if (permissionAble('view-randevu')) {
      hastalarRandevuPermission.push({ id: 0, permission: 'view-randevu' });
    }
    if (permissionAble('create-randevu')) {
      hastalarRandevuPermission.push({ id: 1, permission: 'create-randevu' });
    }
    if (permissionAble('edit-randevu')) {
      hastalarRandevuPermission.push({ id: 2, permission: 'edit-randevu' });
    }
    if (permissionAble('delete-randevu')) {
      hastalarRandevuPermission.push({ id: 3, permission: 'delete-randevu' });
    }
    setPermissionRandevu(hastalarRandevuPermission);


    let hastalarCariPermission = [];
    if (permissionAble('view-cariler')) {
      hastalarCariPermission.push({ id: 0, permission: 'view-cariler' });
    }
    if (permissionAble('create-cariler')) {
      hastalarCariPermission.push({ id: 1, permission: 'create-cariler' });
    }
    if (permissionAble('edit-cariler')) {
      hastalarCariPermission.push({ id: 2, permission: 'edit-cariler' });
    }
    if (permissionAble('delete-cariler')) {
      hastalarCariPermission.push({ id: 3, permission: 'delete-cariler' });
    }
    setPermissionCari(hastalarCariPermission);


    let hastalarGiderPermission = [];
    if (permissionAble('view-giderler')) {
      hastalarGiderPermission.push({ id: 0, permission: 'view-giderler' });
    }
    if (permissionAble('create-giderler')) {
      hastalarGiderPermission.push({ id: 1, permission: 'create-giderler' });
    }
    if (permissionAble('edit-giderler')) {
      hastalarGiderPermission.push({ id: 2, permission: 'edit-giderler' });
    }
    if (permissionAble('delete-giderler')) {
      hastalarGiderPermission.push({ id: 3, permission: 'delete-giderler' });
    }
    setPermissionGiderler(hastalarGiderPermission);



    let hastalarLabPermission = [];
    if (permissionAble('view-laboratuvar')) {
      hastalarLabPermission.push({ id: 0, permission: 'view-laboratuvar' });
    }
    if (permissionAble('create-laboratuvar')) {
      hastalarLabPermission.push({ id: 1, permission: 'create-laboratuvar' });
    }
    if (permissionAble('delete-laboratuvar')) {
      hastalarLabPermission.push({ id: 2, permission: 'delete-laboratuvar' });
    }
    setPermissionLab(hastalarLabPermission);



    let hastalarStokPermission = [];
    if (permissionAble('view-stok')) {
      hastalarStokPermission.push({ id: 0, permission: 'view-stok' });
    }
    if (permissionAble('create-stok')) {
      hastalarStokPermission.push({ id: 1, permission: 'create-stok' });
    }
    if (permissionAble('edit-stok')) {
      hastalarStokPermission.push({ id: 2, permission: 'edit-stok' });
    }
    if (permissionAble('delete-stok')) {
      hastalarStokPermission.push({ id: 3, permission: 'delete-stok' });
    }
    setPermissionStok(hastalarStokPermission);


    let hastalarStokDetayPermission = [];
    if (permissionAble('view-stok-detay')) {
      hastalarStokDetayPermission.push({ id: 0, permission: 'view-stok-detay' });
    }
    if (permissionAble('create-stok-detay')) {
      hastalarStokDetayPermission.push({ id: 1, permission: 'create-stok-detay' });
    }
    if (permissionAble('delete-stok-detay')) {
      hastalarStokDetayPermission.push({ id: 2, permission: 'delete-stok-detay' });
    }
    setPermissionStokDetay(hastalarStokDetayPermission);


    let kullanicilarPermission = [];
    if (permissionAble('view-kullanicilar')) {
      kullanicilarPermission.push({ id: 0, permission: 'view-kullanicilar' });
    }
    if (permissionAble('create-kullanicilar')) {
      kullanicilarPermission.push({ id: 1, permission: 'create-kullanicilar' });
    }
    if (permissionAble('edit-kullanicilar')) {
      kullanicilarPermission.push({ id: 2, permission: 'edit-kullanicilar' });
    }
    if (permissionAble('delete-kullanicilar')) {
      kullanicilarPermission.push({ id: 3, permission: 'delete-kullanicilar' });
    }
    setPermissionKullanicilar(kullanicilarPermission);

    let hastalarRaporlarPermission = [];
    if (permissionAble('view-raporlama-gelir-analizi')) {
      hastalarRaporlarPermission.push({ id: 0, permission: 'view-raporlama-gelir-analizi' });
    }
    if (permissionAble('view-raporlama-tedavi-analizi')) {
      hastalarRaporlarPermission.push({ id: 1, permission: 'view-raporlama-tedavi-analizi' });
    }
    if (permissionAble('view-raporlama-tedavi-sayac-analizi')) {
      hastalarRaporlarPermission.push({ id: 2, permission: 'view-raporlama-tedavi-sayac-analizi' });
    }
    if (permissionAble('view-raporlama-hasta-analizi')) {
      hastalarRaporlarPermission.push({ id: 3, permission: 'view-raporlama-hasta-analizi' });
    }
    if (permissionAble('view-raporlama-laboratuvar-analizi')) {
      hastalarRaporlarPermission.push({ id: 4, permission: 'view-raporlama-laboratuvar-analizi' });
    }
    if (permissionAble('view-raporlama-faturalar')) {
      hastalarRaporlarPermission.push({ id: 5, permission: 'view-raporlama-faturalar' });
    }
    if (permissionAble('view-raporlama-borc-analizi')) {
      hastalarRaporlarPermission.push({ id: 6, permission: 'view-raporlama-borc-analizi' });
    }
    if (permissionAble('view-raporlama-hakedis-analizi')) {
      hastalarRaporlarPermission.push({ id: 7, permission: 'view-raporlama-hakedis-analizi' });
    }
    if (permissionAble('view-raporlama-planlama-analizi')) {
      hastalarRaporlarPermission.push({ id: 8, permission: 'view-raporlama-planlama-analizi' });
    }
    if (permissionAble('view-raporlama-gider-analizi')) {
      hastalarRaporlarPermission.push({ id: 9, permission: 'view-raporlama-gider-analizi' });
    }
    if (permissionAble('view-raporlama-defter-analizi')) {
      hastalarRaporlarPermission.push({ id: 10, permission: 'view-raporlama-defter-analizi' });
    }
    setPermissionRaporlar(hastalarRaporlarPermission);



    let hastalarDoktorPermission = [];
    if (permissionAble('view-doktorlar')) {
      hastalarDoktorPermission.push({ id: 0, permission: 'view-doktorlar' });
    }
    if (permissionAble('create-doktorlar')) {
      hastalarDoktorPermission.push({ id: 1, permission: 'create-doktorlar' });
    }
    if (permissionAble('edit-doktorlar')) {
      hastalarDoktorPermission.push({ id: 2, permission: 'edit-doktorlar' });
    }
    if (permissionAble('delete-doktorlar')) {
      hastalarDoktorPermission.push({ id: 3, permission: 'delete-doktorlar' });
    }
    setPermissionTanimlamalarDoktor(hastalarDoktorPermission);



    let hastalarTarifePermission = [];
    if (permissionAble('view-tarifeler')) {
      hastalarTarifePermission.push({ id: 0, permission: 'view-tarifeler' });
    }
    if (permissionAble('create-tarifeler')) {
      hastalarTarifePermission.push({ id: 1, permission: 'create-tarifeler' });
    }
    if (permissionAble('edit-tarifeler')) {
      hastalarTarifePermission.push({ id: 2, permission: 'edit-tarifeler' });
    }
    if (permissionAble('delete-tarifeler')) {
      hastalarTarifePermission.push({ id: 3, permission: 'delete-tarifeler' });
    }
    if (permissionAble('create-tedaviler')) {
      hastalarTarifePermission.push({ id: 4, permission: 'create-tedaviler' });
    }
    if (permissionAble('edit-tedaviler')) {
      hastalarTarifePermission.push({ id: 5, permission: 'edit-tedaviler' });
    }
    if (permissionAble('delete-tedaviler')) {
      hastalarTarifePermission.push({ id: 6, permission: 'delete-tedaviler' });
    }
    setPermissionTanimlamalarTarife(hastalarTarifePermission);





    let hastalarDiagnozPermission = [];
    if (permissionAble('view-diagnozlar')) {
      hastalarDiagnozPermission.push({ id: 0, permission: 'view-diagnozlar' });
    }
    if (permissionAble('create-diagnozlar')) {
      hastalarDiagnozPermission.push({ id: 1, permission: 'create-diagnozlar' });
    }
    if (permissionAble('edit-diagnozlar')) {
      hastalarDiagnozPermission.push({ id: 2, permission: 'edit-diagnozlar' });
    }
    if (permissionAble('delete-diagnozlar')) {
      hastalarDiagnozPermission.push({ id: 3, permission: 'delete-diagnozlar' });
    }
    setPermissionTanimlamalarDiagnoz(hastalarDiagnozPermission);




    let hastalarRandevuTedaviPermission = [];
    if (permissionAble('view-randevu-tedaviler')) {
      hastalarRandevuTedaviPermission.push({ id: 0, permission: 'view-randevu-tedaviler' });
    }
    if (permissionAble('create-randevu-tedaviler')) {
      hastalarRandevuTedaviPermission.push({ id: 1, permission: 'create-randevu-tedaviler' });
    }
    if (permissionAble('edit-randevu-tedaviler')) {
      hastalarRandevuTedaviPermission.push({ id: 2, permission: 'edit-randevu-tedaviler' });
    }
    if (permissionAble('delete-randevu-tedaviler')) {
      hastalarRandevuTedaviPermission.push({ id: 3, permission: 'delete-randevu-tedaviler' });
    }
    setPermissionTanimlamalarRandevuTedavi(hastalarRandevuTedaviPermission);




    let hastalarIlacPermission = [];
    if (permissionAble('view-ilaclar')) {
      hastalarIlacPermission.push({ id: 0, permission: 'view-ilaclar' });
    }
    if (permissionAble('create-ilaclar')) {
      hastalarIlacPermission.push({ id: 1, permission: 'create-ilaclar' });
    }
    if (permissionAble('edit-ilaclar')) {
      hastalarIlacPermission.push({ id: 2, permission: 'edit-ilaclar' });
    }
    if (permissionAble('delete-ilaclar')) {
      hastalarIlacPermission.push({ id: 3, permission: 'delete-ilaclar' });
    }
    setPermissionTanimlamalarIlac(hastalarIlacPermission);




    let hastalarLabIslemPermission = [];
    if (permissionAble('view-laboratuvar-islem')) {
      hastalarLabIslemPermission.push({ id: 0, permission: 'view-laboratuvar-islem' });
    }
    if (permissionAble('create-laboratuvar-islem')) {
      hastalarLabIslemPermission.push({ id: 1, permission: 'create-laboratuvar-islem' });
    }
    if (permissionAble('edit-laboratuvar-islem')) {
      hastalarLabIslemPermission.push({ id: 2, permission: 'edit-laboratuvar-islem' });
    }
    if (permissionAble('delete-laboratuvar-islem')) {
      hastalarLabIslemPermission.push({ id: 3, permission: 'delete-laboratuvar-islem' });
    }
    setPermissionTanimlamalarLaboratuvar(hastalarLabIslemPermission);



    let hastalarEndikasyonPermission = [];
    if (permissionAble('view-endikasyonlar')) {
      hastalarEndikasyonPermission.push({ id: 0, permission: 'view-endikasyonlar' });
    }
    if (permissionAble('create-endikasyonlar')) {
      hastalarEndikasyonPermission.push({ id: 1, permission: 'create-endikasyonlar' });
    }
    if (permissionAble('edit-endikasyonlar')) {
      hastalarEndikasyonPermission.push({ id: 2, permission: 'edit-endikasyonlar' });
    }
    if (permissionAble('delete-endikasyonlar')) {
      hastalarEndikasyonPermission.push({ id: 3, permission: 'delete-endikasyonlar' });
    }
    setPermissionTanimlamalarEndikasyon(hastalarEndikasyonPermission);



    let hastalarAyarlarRandevuPermission = [];
    if (permissionAble('view-randevu-ayarlar')) {
      hastalarAyarlarRandevuPermission.push({ id: 0, permission: 'view-randevu-ayarlar' });
    }
    if (permissionAble('edit-randevu-ayarlar')) {
      hastalarAyarlarRandevuPermission.push({ id: 1, permission: 'edit-randevu-ayarlar' });
    }
    setPermissionAyarlarRandevu(hastalarAyarlarRandevuPermission);


    let hastalarAyarlarFaturaPermission = [];
    if (permissionAble('view-e-fatura-ayarlar')) {
      hastalarAyarlarFaturaPermission.push({ id: 0, permission: 'view-e-fatura-ayarlar' });
    }
    if (permissionAble('edit-e-fatura-ayarlar')) {
      hastalarAyarlarFaturaPermission.push({ id: 1, permission: 'edit-e-fatura-ayarlar' });
    }
    setPermissionAyarlarFatura(hastalarAyarlarFaturaPermission);




    let hastalarAyarlarIletisimPermission = [];
    if (permissionAble('view-iletisim-ayarlar')) {
      hastalarAyarlarIletisimPermission.push({ id: 0, permission: 'view-iletisim-ayarlar' });
    }
    if (permissionAble('edit-iletisim-ayarlar')) {
      hastalarAyarlarIletisimPermission.push({ id: 1, permission: 'edit-iletisim-ayarlar' });
    }
    setPermissionAyarlarIletisim(hastalarAyarlarIletisimPermission);



    let hastalarAyarlarGenelPermission = [];
    if (permissionAble('view-genel-ayarlar')) {
      hastalarAyarlarGenelPermission.push({ id: 0, permission: 'view-genel-ayarlar' });
    }
    if (permissionAble('edit-genel-ayarlar')) {
      hastalarAyarlarGenelPermission.push({ id: 1, permission: 'edit-genel-ayarlar' });
    }
    setPermissionAyarlarGenel(hastalarAyarlarGenelPermission);


    let kullaniciYetkilerPermission = [];
    if (permissionAble('view-yetkiler')) {
      kullaniciYetkilerPermission.push({ id: 0, permission: 'view-yetkiler' });
    }
    if (permissionAble('edit-yetkiler')) {
      kullaniciYetkilerPermission.push({ id: 1, permission: 'edit-yetkiler' });
    }
    setPermissionKullaniciYetkiler(kullaniciYetkilerPermission);

    let hastalarAnasayfaPermission = [];
    if (permissionAble('view-dashboard')) {
      hastalarAnasayfaPermission.push({ id: 0, permission: 'view-dashboard' });
    }
    setPermissionDashboard(hastalarAnasayfaPermission);


    let systemhistoryPermission = [];
    if (permissionAble('view-loglar')) {
      systemhistoryPermission.push({ id: 0, permission: 'view-loglar' });
    }
    setPermissionSystemHistory(systemhistoryPermission);

    setVisibleNoSelect(false);
    setVisiblePermission(false);
    setTimeout(() => {
      setVisiblePermission(true);
    }, 300);

  }



  // search filter
  const filterWithSearchUser = (value = null) => {
    if (value !== null && value !== "") {
      setFilteredUsers(dataUsers.filter(a => ConvertTurkishToEnglishCharacter(a.name).toLowerCase().includes(ConvertTurkishToEnglishCharacter(value).toLowerCase())));
    } else {
      setFilteredUsers(dataUsers);
    }
  }
  // side effect
  useEffect(() => {
    axios.get("kullanici-yetki-getir").then((res) => {
      setIsLoading(false);
      try {
        if (res.data.data !== undefined && res.data.data !== null) {
          setDataUsers(res?.data?.data);
          setFilteredUsers(res?.data?.data);
        }
      } catch (error) {
        setDataUsers([]);
        setFilteredUsers([]);
      }
    });
  }, [updateUserList]);


  return (
    <TabPanel value="1">
      {
        isProcesssing ? <div style={{ width: '100%', height: '100vh', position: 'fixed', top: '0', left: '0', bottom: '0', right: '0', overflow: "hidden", zIndex: 99999, background: 'transparent' }}></div> : null
      }
      <div className="d-flex justify-content-between align-items-center">
        <span className="d-flex align-items-center">
          <strong className="fs-5 fw-normal text text-secondary">Tüm Kullanıcılar &nbsp;</strong>
        </span>
        <span>
          {visiblePermission ?
            can('view-yetkiler') ?
              <Button color="success" variant="outlined" type="submit" form="assignPermission" disabled={!can('edit-yetkiler')} >
                {
                  isProcesssing ?
                    (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        İşleniyor...
                      </>
                    )
                    :
                    (
                      <>
                        <FaSave /> &nbsp;
                        {"Kaydet"}
                      </>
                    )
                }
              </Button>
              : ''
            : ''}
          &nbsp;
          <Button color="primary" type="button" variant="outlined" startIcon={<FaUserFriends />} onClick={() => { navigate('/tanimlamalar/doktor') }} >
            Kullanıcılar
          </Button>
        </span>

      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 mt-1">
          <div className="cstm-border-top card p-2 pb-4 pt-2">
            <div>
              <FormControl fullWidth>
                <TextField className='form-group'
                  type="text"
                  variant='outlined'
                  autoComplete="off"
                  onChange={(event) => {
                    event.preventDefault();
                    if (/^\s/.test(event.target.value))
                      event.target.value = '';
                    filterWithSearchUser(event.target.value);
                  }}
                  size='small'
                  placeholder="Kullanıcı ara..."
                />
              </FormControl>
            </div>
            {/* treatment list */}
            <div>
              <TableContainer component={Paper} sx={{ maxHeight: 550, marginTop: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow sx={{ "& th": { color: "#fff", backgroundColor: "#50bdf3ad" } }} style={{ position: 'sticky', top: '0', zIndex: '10' }}>
                      <TableCell align="left" width='5%'>#</TableCell>
                      <TableCell align="left" width='45%'>Kullanıcı Adı</TableCell>
                      <TableCell align="left" width='20%'>Yetki Seçimi</TableCell>
                    </TableRow>
                  </TableHead>
                  {
                    useMemo(() =>
                      <>
                        {can('view-yetkiler') ?
                          <TableBody>
                            {
                              !isLoading ?
                                filteredUsers?.length > 0 ?
                                  filteredUsers.map((row, index) => (
                                    <TableRow
                                      key={row.id}
                                      // onClick={(e) => {
                                      //     e.preventDefault();
                                      //     row?.id === selectedRow?.id ? setSelectedRow(null) : setSelectedRow(row); 
                                      // }}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & tr': { fontSize: '12px', fontWeight: 'normal' } }}
                                      style={{ backgroundColor: selectedRow?.id === row.id ? '#cfd8dc' : 'white' }}
                                    >
                                      <TableCell align="left" width='5%' style={{ color: selectedRow?.id === row.id ? '#4B0082' : '' }} >{index + 1}</TableCell>
                                      <TableCell align="left" width='75%' style={{ color: selectedRow?.id === row.id ? '#4B0082' : '' }}>{row?.name}</TableCell>
                                      <TableCell align="left" width='20%' ><Button style={{ backgroundColor: "#e8eaf6" }} onClick={() => { callUserPermission(row); row?.id === selectedRow?.id ? setSelectedRow(null) : setSelectedRow(row); }} >
                                        <FaUserLock style={{ fontSize: "16px", color: selectedRow?.id === row.id ? '#4B0082' : '#1172b6' }} /></Button>
                                      </TableCell>
                                    </TableRow>
                                  )) :
                                  <TableRow width="100%">
                                    <TableCell colSpan={3} height={"100px"} align="center">
                                      <CustomNoRowsOverlay />
                                    </TableCell>
                                  </TableRow>
                                :
                                <TableRow width="100%">
                                  <TableCell colSpan={5} height={"100px"} align="center">
                                    <LoadingOverlay />
                                  </TableCell>
                                </TableRow>
                            }
                          </TableBody>
                          : <NotPermission />}
                      </>
                      , [filteredUsers, selectedRow])
                  }
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>

        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mt-1">
          <form id="assignPermission" onSubmit={formik.handleSubmit}>
            <div className="cstm-border-top card p-2 pb-4 pt-2">
              {visiblePermission ?
                <div>
                  {/* -------------------------------------------------------------------------------------------------- */}
                  <div className="row mt-2" style={{ border: "1px solid #e0e0e0", margin: "-30px 0", marginBottom: "10px", padding: "30px  0", justifyContent: "center" }}>
                    <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                      <FormControlLabel
                        label="Hastalar"
                        style={{ color: "#1976D2" }}
                        control={
                          <Checkbox
                            checked={checkHastalar[0] && checkHastalar[1] && checkHastalar[2] && checkHastalar[3]}
                            indeterminate={
                              (checkHastalar[0] !== checkHastalar[1]) || (checkHastalar[0] !== checkHastalar[2]) || (checkHastalar[1] !== checkHastalar[2]) ||
                              (checkHastalar[0] !== checkHastalar[3]) || (checkHastalar[1] !== checkHastalar[3]) || (checkHastalar[2] !== checkHastalar[3])
                            }
                            onChange={AllHastalar}
                            sx={{
                              color: '#1976D2',
                              '&.Mui-checked': {
                                color: '#1976D2',
                              },
                            }}
                          />
                        }
                      />
                      {hastalar}
                    </div>
                    <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                      <FormControlLabel
                        label="Randevular"
                        style={{ color: "#1976D2" }}
                        control={
                          <Checkbox
                            checked={checkRandevu[0] && checkRandevu[1] && checkRandevu[2] && checkRandevu[3]}
                            indeterminate={
                              (checkRandevu[0] !== checkRandevu[1]) || (checkRandevu[0] !== checkRandevu[2]) || (checkRandevu[1] !== checkRandevu[2]) ||
                              (checkRandevu[0] !== checkRandevu[3]) || (checkRandevu[1] !== checkRandevu[3]) || (checkRandevu[2] !== checkRandevu[3])
                            }
                            onChange={AllRandevular}
                            sx={{
                              color: '#1976D2',
                              '&.Mui-checked': {
                                color: '#1976D2',
                              },
                            }}
                          />
                        }
                      />
                      {randevular}
                    </div>
                    <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                      <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}
                        sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                      >
                        <FormControlLabel
                          label="Giderler"
                          style={{ color: "#1976D2" }}
                          control={
                            <Checkbox
                              checked={checkCari[0] && checkCari[1] && checkCari[2] && checkCari[3]}
                              indeterminate={
                                (checkCari[0] !== checkCari[1]) || (checkCari[0] !== checkCari[2]) || (checkCari[1] !== checkCari[2]) ||
                                (checkCari[0] !== checkCari[3]) || (checkCari[1] !== checkCari[3]) || (checkCari[2] !== checkCari[3])
                              }
                              onChange={AllCariler}
                              sx={{
                                color: '#1976D2',
                                '&.Mui-checked': {
                                  color: '#1976D2',
                                },
                              }}
                            />
                          }
                        />
                        {cariler}
                        <TreeItem nodeId="1" label="Hizmet & Ödeme">
                          <FormControlLabel
                            label="Hizmet & Ödeme"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkGiderler[0] && checkGiderler[1] && checkGiderler[2] && checkGiderler[3]}
                                indeterminate={
                                  (checkGiderler[0] !== checkGiderler[1]) || (checkGiderler[0] !== checkGiderler[2]) || (checkGiderler[1] !== checkGiderler[2]) ||
                                  (checkGiderler[0] !== checkGiderler[3]) || (checkGiderler[1] !== checkGiderler[3]) || (checkGiderler[2] !== checkGiderler[3])
                                }
                                onChange={AllGiderler}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {giderler}
                        </TreeItem>
                      </TreeView>
                    </div>
                    <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                      <FormControlLabel
                        label="Laboratuvar"
                        style={{ color: "#1976D2" }}
                        control={
                          <Checkbox
                            checked={checkLab[0] && checkLab[1] && checkLab[2]}
                            indeterminate={
                              (checkLab[0] !== checkLab[1]) || (checkLab[0] !== checkLab[2]) || (checkLab[1] !== checkLab[2])
                            }
                            onChange={AllLaboratuvar}
                            sx={{
                              color: '#1976D2',
                              '&.Mui-checked': {
                                color: '#1976D2',
                              },
                            }}
                          />
                        }
                      />
                      {laboratuvar}
                    </div>
                  </div>
                  {/* -------------------------------------------------------------------------------------------------- */}

                  {/* -------------------------------------------------------------------------------------------------- */}
                  {visibleHastaDetay ?
                    <div className="row mt-1" style={{ border: "1px solid #e0e0e0", margin: "-30px 0", marginBottom: "10px", padding: "30px  0", backgroundColor: "#f5f5f5", justifyContent: "center" }}>
                      <h5 style={{ color: "#607d8b" }}>Hasta Detay</h5>
                      <hr />
                      <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12"  >
                        <FormControlLabel
                          label="Hasta Tedavi"
                          style={{ color: "#1976D2" }}
                          control={
                            <Checkbox
                              checked={checkHastalarTedavi[0] && checkHastalarTedavi[1] && checkHastalarTedavi[2] && checkHastalarTedavi[3]}
                              indeterminate={
                                (checkHastalarTedavi[0] !== checkHastalarTedavi[1]) || (checkHastalarTedavi[0] !== checkHastalarTedavi[2]) || (checkHastalarTedavi[1] !== checkHastalarTedavi[2]) ||
                                (checkHastalarTedavi[0] !== checkHastalarTedavi[3]) || (checkHastalarTedavi[1] !== checkHastalarTedavi[3]) || (checkHastalarTedavi[2] !== checkHastalarTedavi[3])
                              }
                              onChange={AllHastalarTedavi}
                              sx={{
                                color: '#1976D2',
                                '&.Mui-checked': {
                                  color: '#1976D2',
                                },
                              }}
                            />
                          }
                        />
                        {hastalarTedavi}
                      </div>
                      <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                        <FormControlLabel
                          label="Hasta Hesap"
                          style={{ color: "#1976D2" }}
                          control={
                            <Checkbox
                              checked={checkHastalarHesap[0] && checkHastalarHesap[1] && checkHastalarHesap[2] && checkHastalarHesap[3]}
                              indeterminate={
                                (checkHastalarHesap[0] !== checkHastalarHesap[1]) || (checkHastalarHesap[0] !== checkHastalarHesap[2]) || (checkHastalarHesap[1] !== checkHastalarHesap[2]) ||
                                (checkHastalarHesap[0] !== checkHastalarHesap[3]) || (checkHastalarHesap[1] !== checkHastalarHesap[3]) || (checkHastalarHesap[2] !== checkHastalarHesap[3])
                              }
                              onChange={AllHastalarHesap}
                              sx={{
                                color: '#1976D2',
                                '&.Mui-checked': {
                                  color: '#1976D2',
                                },
                              }}
                            />
                          }
                        />
                        {hastalarHesap}
                      </div>

                      <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                        <FormControlLabel
                          label="Hasta Fatura"
                          style={{ color: "#1976D2" }}
                          control={
                            <Checkbox
                              checked={checkHastalarFatura[0] && checkHastalarFatura[1] && checkHastalarFatura[2] && checkHastalarFatura[3]}
                              indeterminate={
                                (checkHastalarFatura[0] !== checkHastalarFatura[1]) || (checkHastalarFatura[0] !== checkHastalarFatura[2]) || (checkHastalarFatura[1] !== checkHastalarFatura[2]) ||
                                (checkHastalarFatura[0] !== checkHastalarFatura[3]) || (checkHastalarFatura[1] !== checkHastalarFatura[3]) || (checkHastalarFatura[2] !== checkHastalarFatura[3])
                              }
                              onChange={AllHastalarFatura}
                              sx={{
                                color: '#1976D2',
                                '&.Mui-checked': {
                                  color: '#1976D2',
                                },
                              }}
                            />
                          }
                        />
                        {hastalarFatura}
                      </div>
                      <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                        <FormControlLabel
                          label="Hasta Reçete"
                          style={{ color: "#1976D2" }}
                          control={
                            <Checkbox
                              checked={checkHastalarRecete[0] && checkHastalarRecete[1] && checkHastalarRecete[2] && checkHastalarRecete[3]}
                              indeterminate={
                                (checkHastalarRecete[0] !== checkHastalarRecete[1]) || (checkHastalarRecete[0] !== checkHastalarRecete[2]) || (checkHastalarRecete[1] !== checkHastalarRecete[2]) ||
                                (checkHastalarRecete[0] !== checkHastalarRecete[3]) || (checkHastalarRecete[1] !== checkHastalarRecete[3]) || (checkHastalarRecete[2] !== checkHastalarRecete[3])
                              }
                              onChange={AllHastalarRecete}
                              sx={{
                                color: '#1976D2',
                                '&.Mui-checked': {
                                  color: '#1976D2',
                                },
                              }}
                            />
                          }
                        />
                        {hastalarRecete}
                      </div>
                      <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <FormControlLabel
                          label="Hasta Görüntü"
                          style={{ color: "#1976D2" }}
                          control={
                            <Checkbox
                              checked={checkHastalarGoruntu[0] && checkHastalarGoruntu[1] && checkHastalarGoruntu[2] && checkHastalarGoruntu[3]}
                              indeterminate={
                                (checkHastalarGoruntu[0] !== checkHastalarGoruntu[1]) || (checkHastalarGoruntu[0] !== checkHastalarGoruntu[2]) || (checkHastalarGoruntu[1] !== checkHastalarGoruntu[2]) ||
                                (checkHastalarGoruntu[0] !== checkHastalarGoruntu[3]) || (checkHastalarGoruntu[1] !== checkHastalarGoruntu[3]) || (checkHastalarGoruntu[2] !== checkHastalarGoruntu[3])
                              }
                              onChange={AllHastalarGoruntu}
                              sx={{
                                color: '#1976D2',
                                '&.Mui-checked': {
                                  color: '#1976D2',
                                },
                              }}
                            />
                          }
                        />
                        {hastalarGoruntu}
                      </div>
                    </div>
                    : ''}

                  {/* -------------------------------------------------------------------------------------------------- */}
                  <div className="row mt-1" style={{ border: "1px solid #e0e0e0", margin: "-30px 0", marginBottom: "10px", padding: "30px  0", justifyContent: "center" }}>
                    <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                      <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}
                        sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                      >
                        <FormControlLabel
                          label="Stoklar"
                          style={{ color: "#1976D2" }}
                          control={
                            <Checkbox
                              checked={checkStok[0] && checkStok[1] && checkStok[2] && checkStok[3]}
                              indeterminate={
                                (checkStok[0] !== checkStok[1]) || (checkStok[0] !== checkStok[2]) || (checkStok[1] !== checkStok[2]) ||
                                (checkStok[0] !== checkStok[3]) || (checkStok[1] !== checkStok[3]) || (checkStok[2] !== checkStok[3])
                              }
                              onChange={AllStoklar}
                              sx={{
                                color: '#1976D2',
                                '&.Mui-checked': {
                                  color: '#1976D2',
                                },
                              }}
                            />
                          }
                        />
                        {stoklar}
                        <TreeItem nodeId="1" label="Stok Hareketleri">
                          <FormControlLabel
                            label="Stok Hareketleri"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkStokDetay[0] && checkStokDetay[1] && checkStokDetay[2]}
                                indeterminate={
                                  (checkStokDetay[0] !== checkStokDetay[1]) || (checkStokDetay[0] !== checkStokDetay[2]) || (checkStokDetay[1] !== checkStokDetay[2])
                                }
                                onChange={AllStokDetay}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {stokDetay}
                        </TreeItem>
                      </TreeView>
                    </div>
                    <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                      <FormControlLabel
                        label="Kullanıcılar"
                        style={{ color: "#1976D2" }}
                        control={
                          <Checkbox
                            checked={checkKullanicilar[0] && checkKullanicilar[1] && checkKullanicilar[2] && checkKullanicilar[3]}
                            indeterminate={
                              (checkKullanicilar[0] !== checkKullanicilar[1]) || (checkKullanicilar[0] !== checkKullanicilar[2]) || (checkKullanicilar[1] !== checkKullanicilar[2]) ||
                              (checkKullanicilar[0] !== checkKullanicilar[3]) || (checkKullanicilar[1] !== checkKullanicilar[3]) || (checkKullanicilar[2] !== checkKullanicilar[3])
                            }
                            onChange={AllKullanicilar}
                            sx={{
                              color: '#1976D2',
                              '&.Mui-checked': {
                                color: '#1976D2',
                              },
                            }}
                          />
                        }
                      />
                      {kullanicilar}
                    </div>
                    <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                      <FormControlLabel
                        label="Tanımlamalar"
                        style={{ color: "#1976D2" }}
                        control={
                          <Checkbox
                            checked={checkTanimlamalar[0] && checkTanimlamalar[1] && checkTanimlamalar[2] && checkTanimlamalar[3] && checkTanimlamalar[4] && checkTanimlamalar[5] && checkTanimlamalar[6]}
                            indeterminate={
                              (checkTanimlamalar[0] !== checkTanimlamalar[1]) || (checkTanimlamalar[0] !== checkTanimlamalar[2]) || (checkTanimlamalar[1] !== checkTanimlamalar[2]) ||
                              (checkTanimlamalar[0] !== checkTanimlamalar[3]) || (checkTanimlamalar[1] !== checkTanimlamalar[3]) || (checkTanimlamalar[2] !== checkTanimlamalar[3]) ||
                              (checkTanimlamalar[0] !== checkTanimlamalar[4]) || (checkTanimlamalar[1] !== checkTanimlamalar[4]) || (checkTanimlamalar[2] !== checkTanimlamalar[4]) ||
                              (checkTanimlamalar[0] !== checkTanimlamalar[5]) || (checkTanimlamalar[3] !== checkTanimlamalar[4]) || (checkTanimlamalar[1] !== checkTanimlamalar[5]) ||
                              (checkTanimlamalar[2] !== checkTanimlamalar[5]) || (checkTanimlamalar[3] !== checkTanimlamalar[5]) || (checkTanimlamalar[4] !== checkTanimlamalar[5]) ||
                              (checkTanimlamalar[0] !== checkTanimlamalar[6]) || (checkTanimlamalar[1] !== checkTanimlamalar[6]) || (checkTanimlamalar[2] !== checkTanimlamalar[6]) ||
                              (checkTanimlamalar[3] !== checkTanimlamalar[6]) || (checkTanimlamalar[4] !== checkTanimlamalar[6]) || (checkTanimlamalar[5] !== checkTanimlamalar[6])
                            }
                            onChange={AllTanimlamalar}
                            sx={{
                              color: '#1976D2',
                              '&.Mui-checked': {
                                color: '#1976D2',
                              },
                            }}
                          />
                        }
                      />
                      {tanimlamalar}
                    </div>
                    <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                      <FormControlLabel
                        label="Ayarlar"
                        style={{ color: "#1976D2" }}
                        control={
                          <Checkbox
                            checked={checkAyarlar[0] && checkAyarlar[1] && checkAyarlar[2] && checkAyarlar[3] && checkAyarlar[4]}
                            indeterminate={
                              (checkAyarlar[0] !== checkAyarlar[1]) || (checkAyarlar[0] !== checkAyarlar[2]) || (checkAyarlar[1] !== checkAyarlar[2]) ||
                              (checkAyarlar[0] !== checkAyarlar[3]) || (checkAyarlar[1] !== checkAyarlar[3]) || (checkAyarlar[2] !== checkAyarlar[3]) ||
                              (checkAyarlar[0] !== checkAyarlar[4]) || (checkAyarlar[1] !== checkAyarlar[4]) || (checkAyarlar[2] !== checkAyarlar[4]) || (checkAyarlar[3] !== checkAyarlar[4])
                            }
                            onChange={AllAyarlar}
                            sx={{
                              color: '#1976D2',
                              '&.Mui-checked': {
                                color: '#1976D2',
                              },
                            }}
                          />
                        }
                      />
                      {ayarlar}
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                      <FormControlLabel
                        label="Raporlama"
                        style={{ color: "teal" }}
                        control={
                          <Checkbox
                            checked={checkRaporlar[0]}
                            onChange={AllRaporlar}
                            sx={{
                              color: 'teal',
                              '&.Mui-checked': {
                                color: 'teal',
                              },
                            }}
                          />} />
                      <FormControlLabel
                        label="Sistem Geçmişi"
                        style={{ color: "indigo" }}
                        control={
                          <Checkbox
                            checked={checkSystemHistory[0]}
                            onChange={SystemHistory}
                            sx={{
                              color: 'indigo',
                              '&.Mui-checked': {
                                color: 'indigo',
                              },
                            }}
                          />} />
                      <FormControlLabel
                        label="Anasayfa"
                        style={{ color: "indigo" }}
                        control={
                          <Checkbox
                            checked={checkDashboard[0]}
                            onChange={DashboardGorunum}
                            sx={{
                              color: 'indigo',
                              '&.Mui-checked': {
                                color: 'indigo',
                              },
                            }}
                          />} />
                    </div>

                  </div>
                  {/* -------------------------------------------------------------------------------------------------- */}

                  {(checkTanimlamalar[0] || checkTanimlamalar[1] || checkTanimlamalar[2] || checkTanimlamalar[3] || checkTanimlamalar[4] || checkTanimlamalar[5] || checkTanimlamalar[6] || checkKullaniciYetkiler[0] || checkKullaniciYetkiler[1]) ?
                    <div className="row mt-1" style={{ border: "1px solid #e0e0e0", margin: "-30px 0", marginBottom: "10px", padding: "30px  0", backgroundColor: "#f5f5f5", justifyContent: "center" }}>
                      <h5 style={{ color: "#607d8b" }}>Tanımlamalar</h5>
                      <hr />
                      {checkTanimlamalar[0] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                          <FormControlLabel
                            label="Tanımlamalar Doktor"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkTanimlamalarDoktor[0] && checkTanimlamalarDoktor[1] && checkTanimlamalarDoktor[2] && checkTanimlamalarDoktor[3]}
                                indeterminate={
                                  (checkTanimlamalarDoktor[0] !== checkTanimlamalarDoktor[1]) || (checkTanimlamalarDoktor[0] !== checkTanimlamalarDoktor[2]) || (checkTanimlamalarDoktor[1] !== checkTanimlamalarDoktor[2]) ||
                                  (checkTanimlamalarDoktor[0] !== checkTanimlamalarDoktor[3]) || (checkTanimlamalarDoktor[1] !== checkTanimlamalarDoktor[3]) || (checkTanimlamalarDoktor[2] !== checkTanimlamalarDoktor[3])
                                }
                                onChange={AllTanimlamalarDoktor}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {tanimlamalarDoctor}
                        </div>
                        : ""}
                      {checkTanimlamalar[2] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <FormControlLabel
                            label="Tanımlamalar Diagnoz"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkTanimlamalarDiagnoz[0] && checkTanimlamalarDiagnoz[1] && checkTanimlamalarDiagnoz[2] && checkTanimlamalarDiagnoz[3]}
                                indeterminate={
                                  (checkTanimlamalarDiagnoz[0] !== checkTanimlamalarDiagnoz[1]) || (checkTanimlamalarDiagnoz[0] !== checkTanimlamalarDiagnoz[2]) || (checkTanimlamalarDiagnoz[1] !== checkTanimlamalarDiagnoz[2]) ||
                                  (checkTanimlamalarDiagnoz[0] !== checkTanimlamalarDiagnoz[3]) || (checkTanimlamalarDiagnoz[1] !== checkTanimlamalarDiagnoz[3]) || (checkTanimlamalarDiagnoz[2] !== checkTanimlamalarDiagnoz[3])
                                }
                                onChange={AllTanimlamalarDiagnoz}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {tanimlamalariDiagnoz}
                        </div>
                        : ""}
                      {checkTanimlamalar[1] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <FormControlLabel
                            label="Tanımlamalar Tarife"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkTanimlamalarTarife[0] && checkTanimlamalarTarife[1] && checkTanimlamalarTarife[2] && checkTanimlamalarTarife[3] && checkTanimlamalarTarife[4] && checkTanimlamalarTarife[5] && checkTanimlamalarTarife[6]}
                                indeterminate={
                                  (checkTanimlamalarTarife[0] !== checkTanimlamalarTarife[1]) || (checkTanimlamalarTarife[0] !== checkTanimlamalarTarife[2]) || (checkTanimlamalarTarife[1] !== checkTanimlamalarTarife[2]) ||
                                  (checkTanimlamalarTarife[0] !== checkTanimlamalarTarife[3]) || (checkTanimlamalarTarife[1] !== checkTanimlamalarTarife[3]) || (checkTanimlamalarTarife[2] !== checkTanimlamalarTarife[3]) ||
                                  (checkTanimlamalarTarife[0] !== checkTanimlamalarTarife[4]) || (checkTanimlamalarTarife[1] !== checkTanimlamalarTarife[4]) || (checkTanimlamalarTarife[2] !== checkTanimlamalarTarife[4]) ||
                                  (checkTanimlamalarTarife[0] !== checkTanimlamalarTarife[5]) || (checkTanimlamalarTarife[3] !== checkTanimlamalarTarife[4]) || (checkTanimlamalarTarife[1] !== checkTanimlamalarTarife[5]) ||
                                  (checkTanimlamalarTarife[0] !== checkTanimlamalarTarife[6]) || (checkTanimlamalarTarife[2] !== checkTanimlamalarTarife[5]) || (checkTanimlamalarTarife[3] !== checkTanimlamalarTarife[5]) ||
                                  (checkTanimlamalarTarife[4] !== checkTanimlamalarTarife[5]) || (checkTanimlamalarTarife[1] !== checkTanimlamalarTarife[6]) || (checkTanimlamalarTarife[2] !== checkTanimlamalarTarife[6]) ||
                                  (checkTanimlamalarTarife[3] !== checkTanimlamalarTarife[6]) || (checkTanimlamalarTarife[4] !== checkTanimlamalarTarife[6]) || (checkTanimlamalarTarife[5] !== checkTanimlamalarTarife[6])
                                }
                                onChange={AllTanimlamalarTarife}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {tanimlamalarTarifee}
                        </div>
                        : ""}
                      {checkTanimlamalar[4] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <FormControlLabel
                            label="Tanımlamalar İlaç"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkTanimlamalarIlac[0] && checkTanimlamalarIlac[1] && checkTanimlamalarIlac[2] && checkTanimlamalarIlac[3]}
                                indeterminate={
                                  (checkTanimlamalarIlac[0] !== checkTanimlamalarIlac[1]) || (checkTanimlamalarIlac[0] !== checkTanimlamalarIlac[2]) || (checkTanimlamalarIlac[1] !== checkTanimlamalarIlac[2]) ||
                                  (checkTanimlamalarIlac[0] !== checkTanimlamalarIlac[3]) || (checkTanimlamalarIlac[1] !== checkTanimlamalarIlac[3]) || (checkTanimlamalarIlac[2] !== checkTanimlamalarIlac[3])
                                }
                                onChange={AllTanimlamalarIlac}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {tanimlamalarIlaci}
                        </div>
                        : ""}
                      {checkTanimlamalar[3] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <FormControlLabel
                            label="Tanımlamalar Randevu Tedavi"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkTanimlamalarRandevuTedavi[0] && checkTanimlamalarRandevuTedavi[1] && checkTanimlamalarRandevuTedavi[2] && checkTanimlamalarRandevuTedavi[3]}
                                indeterminate={
                                  (checkTanimlamalarRandevuTedavi[0] !== checkTanimlamalarRandevuTedavi[1]) || (checkTanimlamalarRandevuTedavi[0] !== checkTanimlamalarRandevuTedavi[2]) || (checkTanimlamalarRandevuTedavi[1] !== checkTanimlamalarRandevuTedavi[2]) ||
                                  (checkTanimlamalarRandevuTedavi[0] !== checkTanimlamalarRandevuTedavi[3]) || (checkTanimlamalarRandevuTedavi[1] !== checkTanimlamalarRandevuTedavi[3]) || (checkTanimlamalarRandevuTedavi[2] !== checkTanimlamalarRandevuTedavi[3])
                                }
                                onChange={AllTanimlamalarRandevuTedavi}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {tanimlamalariRandevuTedavi}
                        </div>
                        : ""}

                      {checkTanimlamalar[5] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <FormControlLabel
                            label="Tanımlamalar Laboratuvar"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkTanimlamalarLaboratuvar[0] && checkTanimlamalarLaboratuvar[1] && checkTanimlamalarLaboratuvar[2] && checkTanimlamalarLaboratuvar[3]}
                                indeterminate={
                                  (checkTanimlamalarLaboratuvar[0] !== checkTanimlamalarLaboratuvar[1]) || (checkTanimlamalarLaboratuvar[0] !== checkTanimlamalarLaboratuvar[2]) || (checkTanimlamalarLaboratuvar[1] !== checkTanimlamalarLaboratuvar[2]) ||
                                  (checkTanimlamalarLaboratuvar[0] !== checkTanimlamalarLaboratuvar[3]) || (checkTanimlamalarLaboratuvar[1] !== checkTanimlamalarLaboratuvar[3]) || (checkTanimlamalarLaboratuvar[2] !== checkTanimlamalarLaboratuvar[3])
                                }
                                onChange={AllTanimlamalarLaboratuvar}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {tanimlamalarLaboratuvari}
                        </div>
                        : ""}

                      <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                        <FormControlLabel
                          label="Tanımlamalar Endikasyon"
                          style={{ color: "#1976D2" }}
                          control={
                            <Checkbox
                              checked={checkTanimlamalarEndikasyon[0] && checkTanimlamalarEndikasyon[1] && checkTanimlamalarEndikasyon[2] && checkTanimlamalarEndikasyon[3]}
                              indeterminate={
                                (checkTanimlamalarEndikasyon[0] !== checkTanimlamalarEndikasyon[1]) || (checkTanimlamalarEndikasyon[0] !== checkTanimlamalarEndikasyon[2]) || (checkTanimlamalarEndikasyon[1] !== checkTanimlamalarEndikasyon[2]) ||
                                (checkTanimlamalarEndikasyon[0] !== checkTanimlamalarEndikasyon[3]) || (checkTanimlamalarEndikasyon[1] !== checkTanimlamalarEndikasyon[3]) || (checkTanimlamalarEndikasyon[2] !== checkTanimlamalarEndikasyon[3])
                              }
                              onChange={AllTanimlamalarEndikasyon}
                              sx={{
                                color: '#1976D2',
                                '&.Mui-checked': {
                                  color: '#1976D2',
                                },
                              }}
                            />
                          }
                        />
                        {tanimlamalarEndikasyoni}
                      </div>

                      <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <FormControlLabel
                          label="Kullanıcı Yetkileri"
                          style={{ color: "#1976D2" }}
                          control={
                            <Checkbox
                              checked={checkKullaniciYetkiler[0] && checkKullaniciYetkiler[1]}
                              indeterminate={checkKullaniciYetkiler[0] !== checkKullaniciYetkiler[1]}
                              onChange={AllKullaniciYetkiler}
                              sx={{
                                color: '#1976D2',
                                '&.Mui-checked': {
                                  color: '#1976D2',
                                },
                              }}
                            />
                          }
                        />
                        {KullaniciYetkileri}
                      </div>



                    </div>
                    : ""}
                  {/* -------------------------------------------------------------------------------------------------- */}
                  {(checkAyarlar[0] || checkAyarlar[1] || checkAyarlar[2] || checkAyarlar[3] || checkAyarlar[4] || checkAyarlar[5]) ?
                    <div className="row mt-1" style={{ border: "1px solid #e0e0e0", margin: "-30px 0", marginBottom: "10px", padding: "30px  0", backgroundColor: "#f5f5f5", justifyContent: "center" }}>
                      <h5 style={{ color: "#607d8b" }}>Ayarlar</h5>
                      <hr />
                      {checkAyarlar[0] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <FormControlLabel
                            label="Ayarlar Randevu"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkAyarlarRandevu[0] && checkAyarlarRandevu[1]}
                                indeterminate={checkAyarlarRandevu[0] !== checkAyarlarRandevu[1]}
                                onChange={AllAyarlarRandevu}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {AyarlarRandevu}
                        </div>
                        : ""}

                      {checkAyarlar[1] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <FormControlLabel
                            label="Ayarlar E-Fatura"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkAyarlarFatura[0] && checkAyarlarFatura[1]}
                                indeterminate={checkAyarlarFatura[0] !== checkAyarlarFatura[1]}
                                onChange={AllAyarlarFatura}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {AyarlarFatura}
                        </div>
                        : ""}

                      {checkAyarlar[2] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <FormControlLabel
                            label="Ayarlar İletişim"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkAyarlarIletisim[0] && checkAyarlarIletisim[1]}
                                indeterminate={checkAyarlarIletisim[0] !== checkAyarlarIletisim[1]}
                                onChange={AllAyarlarIletisim}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {AyarlarIletisim}
                        </div>
                        : ""}

                      {checkAyarlar[3] ?
                        <div className="col-xl-auto col-lg-4 col-md-6 col-sm-6 col-xs-12">
                          <FormControlLabel
                            label="Ayarlar Genel"
                            style={{ color: "#1976D2" }}
                            control={
                              <Checkbox
                                checked={checkAyarlarGenel[0] && checkAyarlarGenel[1]}
                                indeterminate={checkAyarlarGenel[0] !== checkAyarlarGenel[1]}
                                onChange={AllAyarlarGenel}
                                sx={{
                                  color: '#1976D2',
                                  '&.Mui-checked': {
                                    color: '#1976D2',
                                  },
                                }}
                              />
                            }
                          />
                          {AyarlarGenel}
                        </div>
                        : ""}
                    </div>
                    : ""}
                  {/* -------------------------------------------------------------------------------------------------- */}
                  {checkRaporlar[0] ?
                    <div className="row mt-1" style={{ border: "1px solid #e0e0e0", margin: "-30px 0", marginBottom: "10px", padding: "30px  0", backgroundColor: "#f5f5f5", justifyContent: "center" }}>
                      <h5 style={{ color: "#607d8b" }}>Raporlar</h5>
                      <hr />
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <FormControlLabel
                          label="Raporlama Gelir Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[0]}
                              onChange={raporlarGelirAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />
                        <FormControlLabel
                          label="Raporlama Tedavi Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[1]}
                              onChange={raporlarTedaviAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />
                        <FormControlLabel
                          label="Raporlama Tedavi Sayaç Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[2]}
                              onChange={raporlarTedaviSayacAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />
                        <FormControlLabel
                          label="Raporlama Gider Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[9]}
                              onChange={raporlarGiderAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <FormControlLabel
                          label="Raporlama Hasta Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[3]}
                              onChange={raporlarHastaAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />
                        <FormControlLabel
                          label="Raporlama Laboratuvar Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[4]}
                              onChange={raporlarLaboratuvarAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />
                        <FormControlLabel
                          label="Raporlama Faturalar Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[5]}
                              onChange={raporlarFaturalar}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />
                        <FormControlLabel
                          label="Raporlama Defter Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[10]}
                              onChange={raporlarDefterAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">

                        <FormControlLabel
                          label="Raporlama Borç Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[6]}
                              onChange={raporlarBorcAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />

                        <FormControlLabel
                          label="Raporlama Hakediş Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[7]}
                              onChange={raporlarHakedisAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />

                        <FormControlLabel
                          label="Raporlama Planlama Analizi"
                          style={{ color: "indigo" }}
                          control={
                            <Checkbox
                              checked={checkRaporlarContent[8]}
                              onChange={raporlarPlanlamaAnalizi}
                              sx={{
                                color: 'indigo',
                                '&.Mui-checked': {
                                  color: 'indigo',
                                },
                              }}
                            />} />
                      </div>
                    </div>
                    : ""}
                  {/* -------------------------------------------------------------------------------------------------- */}
                </div>
                : visibleNoSelect ? <div style={{ color: "orange" }} align="center">Lütfen Kullanıcı Seçiniz..</div> : <LoadingOverlay />}
            </div>
          </form>
        </div>

      </div>
    </TabPanel>
  )
}

const authPermissions = (state) => {
  return {
    auth: state.authState,
  };
};

export default connect(authPermissions)(SettingsUserPermission);