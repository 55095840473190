import React, { useEffect, useState } from "react";
import "assets/css/pages/patient-detail.css";
import { Badge, Button as ButtonM, Stack, Tooltip} from "@mui/material";
import { Card } from "reactstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { TabPanel } from "@mui/lab";
import {
    FaPlus,
    FaPrint,
    FaTrash,
    FaSearch,
} from "react-icons/fa";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { useNavigate, useParams } from "react-router-dom";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import preLoadingImg from 'assets/images/loading.gif';
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";
// date end

const PatientInvoice = (props) => {
    const {auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }

    const params = useParams();
    const MySwal = withReactContent(Swal);
    const [invoices, setInvoices] = useState([]);
    const [updateTable, setUpdateTable] = useState(false);
    const [total, setTotal] = useState(0.00);
    const [countTreatment, setCountTreatment] = useState(0);
    const [loading,setLoading] = useState(true);
    const [filteredInvoice, setFilteredInvoice] = useState([]);
    const [patientInfo, setPatientInfo] = useState(null);
    const patientId = params?.patientId ? params.patientId : 0;
    const navigate = useNavigate();

    const createNewInvoice = () => {
        if(countTreatment > 0){
            navigate(`olustur`);
        }
    }

    const invoiceDetail = (e, id = null) => {
        e.preventDefault();
        if(id !== null && id > 0){
            navigate(`kalemleri/${id}`);
        }
    }

    const deleteInvoice = (e, id = null) => {
        e.preventDefault();
        e.stopPropagation()
        if(id !== null && id > 0){
            MySwal.fire({
                title: "Kayıt silinsin mi?",
                text: "silme işlemini gerçekleştirdiğinizde geri alamazsınız!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Hayır,Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    AxiosCrudActions.DeleteAsync('/faturalar', id)
                        .then(result => {
                            if (result?.status === 200 && result?.data?.status) {
                                setUpdateTable(!updateTable);
                            }
                        });
                }
            })
        }
    }

    const getAllInvoice = async () => {
        await axios.get('/faturalar', {params: {hasta_id: patientId}})
                .then(res => {
                    if(res?.status === 200 ){
                        setInvoices(res?.data?.data);
                        setTotal(res?.data?.genelToplam);
                        setCountTreatment(res?.data?.count);
                        setPatientInfo(res?.data?.hasta);
                        setFilteredInvoice(res?.data?.data);
                    }
                }).catch(e => {
                    setInvoices([]);
                    setTotal(0.00);
                    setCountTreatment(0);
                    setPatientInfo(null)
                    setFilteredInvoice([]);
                })
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            
    }

    useEffect(() => {
        getAllInvoice();
    },[updateTable]);
    

    return (
        <>
            { can('view-hastalar-fatura') ?
                <TabPanel value="3">
                    <div className="patient-detail-container">
                        <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                            <div className="d-flex justify-content-between align-items-center mb-3" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                                <span className="d-flex align-items-center" style={{fontSize: '18px',fontWeight: 'normal', color: '#1172b6', opacity: '0.8'}}>
                                    <strong>
                                        Tüm Faturalar&nbsp;
                                    </strong>
                                </span>
                                <span className="d-inline-block">
                                    <Tooltip style={{marginRight: '15px', display: 'inline-block'}} placement="top-start" title={countTreatment > 0 ? countTreatment + " tedavinin kesilmemiş faturası mevcut" : "Kesilecek fatura bulunmamaktadır."} arrow >
                                        <Badge anchorOrigin={{vertical: 'top',horizontal: 'right',}} color="secondary" badgeContent={countTreatment} max={9}>
                                            <ButtonM color="success" variant="outlined" startIcon={<FaPlus />} onClick={createNewInvoice} disabled={(!(countTreatment > 0) || (! can('create-hastalar-fatura')))}>
                                                Yeni Fatura 
                                            </ButtonM>
                                        </Badge>
                                    </Tooltip>
                                    <ButtonM color="warning" variant="outlined" startIcon={<FaPrint />} className="d-inline-block ml-2">
                                        Yazdır
                                    </ButtonM>
                                </span>
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 900}} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" width='20%'>Ad Soyad</TableCell>
                                            <TableCell align="center" width='20%'>Fatura No</TableCell>
                                            <TableCell align="center" width='20%'>Fatura Tarihi</TableCell>
                                            <TableCell align="center" width='20%'>Fatura Tutarı</TableCell>
                                            <TableCell align="center" width='20%'>İşlem</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                    !loading ?
                                    filteredInvoice?.length > 0 ? 
                                    invoices.map((row) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center" width='20%'>{row?.ad_soyad}</TableCell>
                                            <TableCell align="center" width='20%'>{row?.faturaNo}</TableCell>
                                            <TableCell align="center" width='20%'>{GetTrFullDateAndTimeWithFormat(row?.faturaTarihi + row?.faturaSaati)}</TableCell>
                                            <TableCell align="center" width='20%'>{row?.toplam} TRY</TableCell>
                                            <TableCell align="center" width='20%'>
                                                <Stack direction="row" spacing={2} className="justify-content-center">
                                                    <Tooltip title="fatura detay" arrow>
                                                        <ButtonM variant="outlined"
                                                            disabled={! can('view-hastalar-fatura-detay')}
                                                            onClick={(e) => invoiceDetail(e, row?.id)}
                                                            color="info"><FaSearch /></ButtonM>
                                                    </Tooltip>
                                                    {/* <Tooltip title="fatura sil" arrow>
                                                        <ButtonM variant="outlined" 
                                                            disabled={! can('delete-hastalar-fatura')}
                                                            onClick={(e) => deleteInvoice(e, row?.id)}
                                                            color="error"><FaTrash /></ButtonM>
                                                    </Tooltip> */}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )):
                                    <TableRow width="100%">
                                        <TableCell colSpan={5} height={"100px"} align="center">
                                            <CustomNoRowsOverlay />
                                        </TableCell>
                                    </TableRow> :
                                    <TableRow width="100%">
                                        <TableCell>
                                            <div id="invoice-pre-loading-content">
                                                <div className="loading">
                                                    <img src={preLoadingImg} alt="loading" width={110} height={110} />
                                                    <div className="d-flex justify-content-center" style={{color: '#1172b6'}}>
                                                        <span>Yükleniyor...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="d-flex justify-content-end mt-4" style={{color: '#797979'}}>
                                <strong className="fw-semibold fs-5"> GENEL TOPLAM: {total} TRY</strong>
                            </div> 
                        </Card>
                    </div>
                </TabPanel>
           :<NotPermission/>}
        </>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions) (PatientInvoice);
