import React from "react";
import { Outlet } from "react-router-dom";

const PatientLayout = () => {
    return (
        <div className="patient-page-wrapper">
            <Outlet />
        </div>
    )
}

export default PatientLayout;