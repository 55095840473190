import React, { useEffect, useState } from 'react'
import 'assets/css/pages/reports/report-allowance-analysis.css';
import { FormControl, TextField, Autocomplete, Button as ButtonM, MenuItem } from '@mui/material';
import Box from "@mui/material/Box";
import {
    FaTimes,
    FaTh,
    FaClipboardList
} from "react-icons/fa";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import { trTR as turkishTr } from "@mui/material/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { tr } from 'date-fns/locale'
import axios from "axios";
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import GetTrDate from "extensions/GetTRDate";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

function AllowanceAnalysis(props) {

    const { auth } = props;
    const can = (permission) => {
        return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
    }

    const navigate = useNavigate();
    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );


    const [selectDoctor, setSelectDoctor] = useState({ id: 0, name: 'Hepsi', value: null });
    const [priceData, setPriceData] = useState({
        toplam: '0,00',
        hakedis: '0,00',
        kalan: "0,00",
        yapilan_odemeler: "0,00",
        toplam_hakedis: "0,00",
        toplam_kalan: "0,00",
        toplam_hakedis_odemeleri: "0,00",
        toplam_tahsilat: "0,00"
    });

    const [doctors, setDoctors] = useState([]);
    const MySwal = withReactContent(Swal);
    const [startDate, setStartDate] = useState(Date.now());
    const [endDate, setEndDate] = useState(Date.now());


    const columns = [
        {
            field: "ad_soyad",
            headerName: "Ad Soyad",
            minWidth: 100,
            maxWidth: 300,
            flex: 1,
            hideable: true,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "doktor",
            headerName: "Doktor",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.doktor ? params?.row?.doktor?.name : ""
                )
            }
        },
        {
            field: "tarih",
            headerName: "Ödeme Tarihi",
            minWidth: 100,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,

        },
        {
            field: "tutar",
            headerName: "Tutar",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        }
    ];

    // data-grid state
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);


    const getDoctors = () => {
        try {
            axios.get('/kullanicilar?doktor=1')
                .then(res => {
                    setDoctors(res.data.data);
                    setDoctors(state => [{ id: 0, name: "Hepsi", value: null }, ...state]);
                })
        } catch (error) {
            setDoctors([])
        }
    }

    const getDate = (date) => {
        if (date !== undefined && date !== null) {
            try {
                let getJustDate = dayjs(date).get('date') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('year');
                return getJustDate;
            } catch (error) {
                return null;
            }
        }
    }

    const getReverseDate = (date) => {
        if (date !== undefined && date !== null) {
            try {
                let getJustDate = dayjs(date).get('year') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('date');
                return getJustDate;
            } catch (error) {
                return null;
            }
        }
    }





    const FilterReports = () => {
        setIsLoading(true);
        axios.get("raporlama/hakedis-analizi", {
            params: {
                allpatients: auth?.user?.user_permissions?.filter((f) => f === 'view-all-hastalar')?.length ? 1 : 0,
                baslangic: getReverseDate(startDate) ? getReverseDate(startDate) : null,
                bitis: getReverseDate(endDate) ? getReverseDate(endDate) : null,
                doktor: selectDoctor?.id ? selectDoctor?.id : null,
            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    let datalist = res.data.data;
                    let total = {
                        toplam: res.data.toplam,
                        hakedis: res.data.hakedis,
                        kalan: res.data.kalan,
                        yapilan_odemeler: res.data.yapilan_odemeler,
                        toplam_hakedis: res.data.toplam_hakedis,
                        toplam_hakedis_odemeleri: res.data.toplam_hakedis_odemeleri,
                        toplam_kalan: res.data.toplam_kalan,
                        toplam_tahsilat: res.data.toplam_tahsilat
                    };
                    setPriceData(total);
                    setData(datalist);
                }
            } catch (error) {
                setData([]);
            }
        });
    }


    //side effect
    useEffect(() => {
        getDoctors();


        setIsLoading(true);
        axios.get("raporlama/hakedis-analizi", {
            params: {
                allpatients: auth?.user?.user_permissions?.filter((f) => f === 'view-all-hastalar')?.length ? 1 : 0,
                baslangic: getReverseDate(startDate) ? getReverseDate(startDate) : null,
                bitis: getReverseDate(endDate) ? getReverseDate(endDate) : null,
                doktor: selectDoctor?.id ? selectDoctor?.id : null,
            }
        }).then((res) => {

            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    let datalist = res.data.data;
                    let total = {
                        toplam: res.data.toplam,
                        hakedis: res.data.hakedis,
                        kalan: res.data.kalan,
                        yapilan_odemeler: res.data.yapilan_odemeler,
                        toplam_hakedis: res.data.toplam_hakedis,
                        toplam_hakedis_odemeleri: res.data.toplam_hakedis_odemeleri,
                        toplam_kalan: res.data.toplam_kalan,
                        toplam_tahsilat: res.data.toplam_tahsilat
                    };
                    setPriceData(total);
                    setData(datalist);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, []);

    return (

        <>
            {can('view-raporlama-hakedis-analizi') ?
                <div className="report-allowance-analysis-container">
                    <div className='d-flex justify-content-between'>
                        <span>
                            <h4 className='report-allowance-analysis-title'>Raporlama</h4>
                            <span className="d-flex align-items-center">
                                <div><strong className="fs-5 fw-normal text text-secondary">Hakediş Analizi&nbsp;</strong></div>
                            </span>
                        </span>
                        <div>
                            <ButtonM className='report-allowance-analysis-button-bgColor'
                                variant="contained"
                                type="submit" startIcon={<FaTh />}
                                onClick={() => { navigate('/raporlama') }}
                            >
                                Raporlama listesi
                            </ButtonM>
                        </div>
                    </div>
                    <div className='col-xl-9 col-lg-11 col-md-11 col-sm-11 col-xs-11 offset-xl-2 offset-md-1 offset-sm-1'>
                        <div className="row">
                            <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                                <FormControl fullWidth className="mt-2">
                                    <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                        <DesktopDatePicker
                                            variant='outlined'
                                            label="Başlangıç Tarihi"
                                            inputFormat="dd-MM-yyyy"
                                            minDate={dayjs('01-01-1930')}
                                            value={startDate}
                                            onChange={(newValue) => {
                                                setStartDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                size="small"
                                                name="baslangic"
                                                variant='outlined'
                                            />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                            <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                                <FormControl fullWidth className="mt-2">
                                    <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                        <DesktopDatePicker
                                            variant='outlined'
                                            label="Bitiş Tarihi"
                                            inputFormat="dd-MM-yyyy"
                                            minDate={dayjs('01-01-1930')}
                                            value={endDate}
                                            onChange={(newValue) => {
                                                setEndDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                size="small"
                                                name="bitis"
                                                variant='outlined'
                                            />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>

                            {auth?.user?.user_permissions?.filter((f) => f === 'view-all-hastalar')?.length ? <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                                <FormControl fullWidth className="mt-2">
                                    <Autocomplete
                                        options={doctors}
                                        getOptionLabel={(option) => option.name}
                                        loading={true}
                                        disableClearable
                                        value={selectDoctor}
                                        noOptionsText={"Seçenek Bulunamadı"}
                                        name="doktor"
                                        onChange={(event, newValue) => {
                                            setSelectDoctor(newValue);
                                        }}
                                        size="small"
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            label="Doktor"
                                        />
                                        }
                                    />
                                </FormControl>
                            </div> : <></>}
                            <ButtonM className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-9 m-2  report-allowance-analysis-button-bgColor'
                                variant="contained"
                                style={{ height: "40px", width: "40%", top: "6px", left: "11px", background: "#1172B6" }}
                                type="submit" startIcon={<FaClipboardList />}
                                onClick={FilterReports}
                            >
                                Raporu Göster
                            </ButtonM>

                        </div>
                    </div>


                    <div className='row mt-2'>
                        {
                            <ThemeProvider theme={theme}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: `${data.length > 0 ? "auto" : "400px"}`,
                                    }}
                                >
                                    <DataGrid
                                        autoHeight={data.length > 0 ? true : false}
                                        sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2 }}
                                        loading={isLoading}
                                        rows={data}
                                        columns={columns}
                                        // pagination
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        rowCount={totalCount} //serverdan gelen data total count gelecek
                                        page={page}
                                        onPageChange={(newPage) => setPage(newPage)}
                                        pagination
                                        //paginationMode="server"
                                        // sorting
                                        sortModel={sortModel}
                                        sortingMode="server"
                                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                        // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                        filterModel={filterModel}
                                        // filterMode="server"
                                        onFilterModelChange={(newFilterModel) =>
                                            setFilterModel(newFilterModel)
                                        }
                                        // selection checkbox
                                        //checkboxSelection={true}
                                        selectionModel={selectionModel}
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel(newSelectionModel);
                                        }}
                                        // features
                                        experimentalFeatures={{ newEditingApi: true }}
                                        // toolbar components
                                        components={{
                                            Toolbar: GridToolbar,
                                            NoRowsOverlay: CustomNoRowsOverlay,
                                            LoadingOverlay: LoadingOverlay,
                                        }}
                                        disableColumnFilter
                                        disableSelectionOnClick
                                        // component props
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                printOptions: {
                                                    fields: ["id", "islem_adi"],
                                                    fileName: "Laboratuvar Islemler",
                                                    hideFooter: true,
                                                    hideToolbar: true,
                                                    disableToolbarButton: false,
                                                }, //sadece firsname  ve lastname alanını alır.
                                                csvOptions: {
                                                    disableToolbarButton: false,
                                                    fields: ["id", "islem_adi"],
                                                    fileName: "Laboratuvar Islemler",
                                                    utf8WithBom: true,
                                                }, //sadece firstname ve lastName alanını alır.
                                                excelOptions: { disableToolbarButton: true },
                                                // quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                    />
                                </Box>
                            </ThemeProvider>
                        }


                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 mr-3 ml-3 d-flex justify-content-between' >

                            {auth?.user?.user_permissions?.filter((f) => f === 'view-all-hastalar')?.length ? <span style={{ border: "1px solid gray", padding: "15px", borderRadius: "5px" }}>
                                <h6 className='d-flex justify-content-center'>Genel İcmal</h6>
                                <hr />
                                <table>
                                    <tr>
                                        <td>Toplam Hasta Tahsilatları&nbsp;&nbsp;</td>
                                        <td><b style={{ color: 'teal' }}>:&nbsp;&nbsp;{priceData?.toplam_tahsilat}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Toplam Hakediş&nbsp;&nbsp;</td>
                                        <td><b style={{ color: 'teal' }}>:&nbsp;&nbsp;{priceData?.toplam_hakedis}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Toplam Hakediş Ödemeleri&nbsp;&nbsp;</td>
                                        <td><b style={{ color: 'teal' }}>:&nbsp;&nbsp;{priceData?.toplam_hakedis_odemeleri}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Fark&nbsp;&nbsp;</td>
                                        <td><b style={{ color: 'teal' }}>:&nbsp;&nbsp;{priceData?.toplam_kalan}</b></td>
                                    </tr>
                                </table>
                            </span>
                                : <></>}
                            <span>
                                <table>
                                    <tr>
                                        <td><b>Hasta Tahsilatları&nbsp;&nbsp;</b></td>
                                        <td><b style={{ color: '#e53935' }}>:&nbsp;&nbsp;{priceData?.toplam}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Hakediş&nbsp;&nbsp;</b></td>
                                        <td><b style={{ color: '#e53935' }}>:&nbsp;&nbsp;{priceData?.hakedis}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Hakediş Ödemeleri&nbsp;&nbsp;</b></td>
                                        <td><b style={{ color: '#e53935' }}>:&nbsp;&nbsp;{priceData?.yapilan_odemeler}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Fark&nbsp;&nbsp;</b></td>
                                        <td><b style={{ color: '#e53935' }}>:&nbsp;&nbsp;{priceData?.kalan}</b></td>
                                    </tr>
                                </table>
                            </span>
                        </div>
                    </div>
                </div>
                : <NotPermission />}
        </>
    )
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(AllowanceAnalysis);