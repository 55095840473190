import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "assets/css/pages/definition.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { TabPanel } from "@mui/lab";
import { Card } from "reactstrap";
import { Button as ButtonM, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow , Stack, Tooltip, FormControl, TextField, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaTimes,
    FaEdit,
    FaTrash,
    FaSearch,
    FaTablets,
    FaPills,
    FaPaperPlane
} from "react-icons/fa";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";
import CreateEndikasyonValidationSchema from "business-layer/CreateEndikasyonValidationSchema";
import CreateMedicineValidationSchema from "business-layer/CreateMedicineValidationSchema";


const DefinitionEndikasyon = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const columns = [
        {
            field: "id",
            headerName: "Sıra",
            flex: 1,
            minWidth: 130,
            maxWidth: 130,
            align:"center",
            hideable: true,
            editable: false,
            headerAlign: "center"
        },
        {
            field: "endikasyon_adi",
            headerName: "Endikasyon Adı",
            align: "center",
            headerAlign: "center",
            minWidth: 300,
            flex: 3,
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "actions",
            headerName: "İşlemler",
            flex: 1,
            minWidth: 300,
            align: "center",
            headerAlign: "center",
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const deleteEndikasyon = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.endikasyon_adi + " silinsin mi?",
                        text: "silme işlemini gerçekleştirdiğinizde geri alamazsınız!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, Sil',
                        cancelButtonText: 'Hayır,Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync('/endikasyonlar', params.row.id)
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });
                        }
                    })
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="endikasyon detay" arrow>
                            <ButtonM variant="outlined" onClick={(e) => detailEndikasyon(params?.row)} color="primary"><FaSearch /></ButtonM>
                        </Tooltip>
                        <Tooltip title="endikasyon düzenle" arrow>
                            <ButtonM variant="outlined" onClick={(e) => editEndikasyon(e, params?.row)} disabled={! can('edit-endikasyonlar')} color="warning"><FaEdit /></ButtonM>
                        </Tooltip>
                        <Tooltip title="endikasyon sil" arrow>
                            <ButtonM variant="outlined" onClick={deleteEndikasyon} disabled={! can('delete-endikasyonlar')} color="error"><FaTrash /></ButtonM>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];

    const [isProcesssing, setIsProcessing] = useState(false);

    const [endikasyonDetailModal, setEndikasyonDetailModal] = useState(false);
    const endikasyonDetailToggle = () => setEndikasyonDetailModal(!endikasyonDetailModal);
    const [endikasyonDetail, setEndikasyonDetail] = useState(null);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [endikasyonId, setEndikasyonId] = useState(0);

    const [ilaclar, setIlaclar] = useState([]);
    const [filteredMedicines, setFilteredMedicines] = useState([]);
    const [endikasyonMedicines, setEndikasyonMedicines] = useState([]);
    // editing medicine state
    const [updateMedicine, setUpdateMedicine] = useState(null);
    const [editMedicineModal, setEditMedicineModal] = useState(false);
    const editMedicineToggle = () => setEditMedicineModal(!editMedicineModal);

    const initialEndikasyonInfo = {
        endikasyon_adi: ''
    }

    // yup schema
    let endikasyonValidationSchema = CreateEndikasyonValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialEndikasyonInfo,
        validationSchema : endikasyonValidationSchema,
        onSubmit: values => {
            if(endikasyonMedicines.length <= 0){
                MySwal.fire({
                    title: "Lütfen ilaç ekleyiniz.",
                    icon: 'warning',
                })   
            }else{
                MySwal.fire({
                    title: values?.endikasyon_adi + " - adlı endikasyon" +  `${endikasyonId > 0 ? " güncellensin " : " eklensin "}`  + " mi?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: `Evet, ${ endikasyonId > 0 ? "Güncelle" : "Kaydet"}`,
                    cancelButtonText: 'Hayır,Vazgeç'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setIsProcessing(true);
                        values.ilaclar = JSON.stringify(endikasyonMedicines);
                        if (endikasyonId === 0) {
                            AxiosCrudActions.CreateAsync('endikasyonlar', values).then(result => {
                                setIsProcessing(false);
                                if (result?.data?.status) {
                                    setUpdateDataGrid(!updateDataGrid);
                                    toggle();
                                }
                            });
                    
                        } else if (endikasyonId > 0) {
                            AxiosCrudActions.UpdateAsync(`endikasyonlar/${endikasyonId}`, values).then(result => {
                                setIsProcessing(false);
                                if (result?.data?.status) {
                                    setUpdateDataGrid(!updateDataGrid);
                                    toggle();
                                }
                            });
                        }
                    }
                })
            }
        },
    });

    let medicineValidationSchema = CreateMedicineValidationSchema();

    const medicineFormik = useFormik({
        initialValues: {
            ilac_adi: "",
            kullanim: "",
            doz: "",
            aciklama: ""
        },
        validationSchema : medicineValidationSchema,
    });

    const detailEndikasyon = (values = null) => {
        if(values !== undefined && values !== null){
            setEndikasyonDetail(values);
            endikasyonDetailToggle();
        }
    }

    const addNewEndikasyon = (event) => {
        event.preventDefault();
        setEndikasyonMedicines([]);
        formik.setValues({
            endikasyon_adi: ''
        }, false)
        formik.setErrors({});
        setEndikasyonId(0);
        toggle();
    }

    const editEndikasyon = (event, values) => {
        event.preventDefault();
        setEndikasyonMedicines(values?.ilaclar)
        formik.setValues({
            endikasyon_adi: values?.endikasyon_adi ? values?.endikasyon_adi : '',
        }, true)
        formik.setErrors({});
        setEndikasyonId(values?.id);
        toggle();
    }

     // ilaclar
     const getMedicines = async () => {
        await axios.get('ilaclar', {params: {active: true}})
                    .then(res => {
                        setIlaclar(res?.data?.data);
                        setFilteredMedicines(res?.data?.data);
                    })
                    .catch(e => {
                        setIlaclar([]);
                        setFilteredMedicines([]);
                    });
    }

    // add medicine to Endikasyon List
    const addMedicineToEndikasyonList = (e, values = null) => {
        if(values !== null){
            let flag = endikasyonMedicines.some(el => el.id === values.id);
            if(!flag){
                setEndikasyonMedicines(prevState => [...prevState,values] );
            }
        }
    }

    // edit medicine in endikasyon list
    const editMedicineInEndikasyonList = (e, values = null) => {
        e.preventDefault();
        e.stopPropagation();
        if(values !== null){
            setUpdateMedicine(values);
            editMedicineToggle();
        }
    } 

    const updateUsingInfoMedicine = (e) => {
        e.preventDefault();
        if(updateMedicine !== undefined && updateMedicine !== null){
            const newState = endikasyonMedicines.map(obj => {
                // 👇️ if id equals 2, update country property
                if (obj?.id === updateMedicine?.id) {
                  return {...obj, doz:updateMedicine?.doz, kullanim: updateMedicine?.kullanim, aciklama: updateMedicine?.aciklama };
                }
                // 👇️ otherwise return object as is
                return obj;
            });
            setEndikasyonMedicines(newState);
            editMedicineToggle();
        }
       
    }

    // delete medicine from Endikasyon List
    const deleteMedicineToEndikasyonList = (e, values = null) => {
        e.preventDefault();
        if(values !== null){
            let removedElementFromList = endikasyonMedicines.filter(el => el.id !== values.id);
            setEndikasyonMedicines(removedElementFromList);
        }
    }

    // search filter
    const filterWithSearchMedicine = (value = null) => {
        if(value !== null && value !== ""){
            setFilteredMedicines(ilaclar.filter(a => a.ilac_adi.toLowerCase().includes(value.toLowerCase())));
        }else{
            setFilteredMedicines(ilaclar);
        }
    }


    useEffect(()=> {
        getMedicines();
    }, [])

    // data-grid state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    // side effect
    useEffect(() => {
        axios.get("endikasyonlar", {
            params: {
                filterModel: JSON.stringify(filterModel),
                pageSize: pageSize,
                page: page,
                sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    setData(res.data.data);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, [sortModel, filterModel, pageSize, page, updateDataGrid]);


    return (
        <TabPanel value="7" className="definitions-container">
            {
                isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
            }
            <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                    <strong className="fs-5 fw-normal text text-secondary">Tüm Endikasyonlar &nbsp;</strong>
                </span>
                {can('view-endikasyonlar') ? 
                    <ButtonM color="success" variant="outlined" startIcon={<FaPlus />} disabled={! can('create-endikasyonlar')}  onClick={addNewEndikasyon}>
                        Yeni Endikasyon
                    </ButtonM>
                :''}
                <Modal isOpen={modal} 
                       
                       toggle={toggle} 
                       size="xl"
                       modalTransition={{ timeout: 1 }}
                       backdropTransition={{ timeout: 1 }}
                       >
                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Endikasyon Bilgileri</ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                {/* ilac listesi */}
                                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <div className="mb-1">
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="endikasyon_adi"
                                                variant='outlined'
                                                label="Endikasyon Adı"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.endikasyon_adi && formik.touched.endikasyon_adi ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.endikasyon_adi}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaTablets /></InputAdornment>,
                                                }}
                                                error={formik.errors.endikasyon_adi && formik.touched.endikasyon_adi}
                                                helperText={formik.errors.endikasyon_adi && formik.touched.endikasyon_adi && <span className='cstm-input-error-validation-msg'>{formik.errors.endikasyon_adi}</span>}
                                            />
                                        </FormControl>
                                    </div>
                                    <Card className="p-3 cstm-border-top mt-3" style={{height: '400px'}}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 style={{color: '#1172b6'}}>İlaç Listesi :</h6>
                                            <div>
                                                <FormControl fullWidth>
                                                    <TextField className='form-group'
                                                        type="text"
                                                        variant='outlined'
                                                        autoComplete="off"
                                                        onChange={(event) => {
                                                            event.preventDefault();
                                                            if(/^\s/.test(event.target.value))
                                                                event.target.value = '';
                                                            filterWithSearchMedicine(event.target.value);
                                                        }}
                                                        size='small'
                                                        placeholder="ilaç ara..."
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <TableContainer component={Paper}>
                                            <Table size="small" stickyHeader style={{height: '100%', overflow: 'scroll'}}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left" width='70%'>İlaç Adı</TableCell>
                                                        <TableCell align="center" width='30%'>İşlem</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {
                                                    useMemo(() =>
                                                        can('create-endikasyonlar') ?
                                                            <TableBody>
                                                                {
                                                                filteredMedicines.map((row) => (
                                                                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        style={{backgroundColor: endikasyonMedicines.some(el => el.id === row.id) ? '#1172b6': '#ffffff'}}>
                                                                        <TableCell align="left" width='70%'
                                                                            style={{color: endikasyonMedicines.some(el => el.id === row.id) ? '#ffffff': '#000'}}>{row?.ilac_adi}</TableCell>
                                                                        <TableCell align="center" width='30%' >
                                                                            <Stack direction="row" spacing={2} className="justify-content-center">
                                                                                <Tooltip title={endikasyonMedicines.some(el => el.id === row.id) ? "Listeden çıkar" : "Listeye ekle" }  arrow>
                                                                                    {
                                                                                        endikasyonMedicines.some(el => el.id === row.id) 
                                                                                        ? 
                                                                                        <ButtonM onClick={(e) => deleteMedicineToEndikasyonList(e, row)} style={{color: 'white'}}><FaTrash /></ButtonM>
                                                                                        :
                                                                                        <ButtonM variant="outlined" onClick={(e) => addMedicineToEndikasyonList(e, row)} color="success"><FaPlus /></ButtonM>
                                                                                    }
                                                                                </Tooltip>
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow> 
                                                                ))}
                                                            </TableBody>
                                                        :''
                                                    ,[filteredMedicines,endikasyonMedicines])
                                                }
                                            </Table>
                                        </TableContainer>
                                    </Card> 
                                </div>

                                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 mt-3"> 
                                    {/* selected medicines */}
                                    <h6 className="text-center">Endikasyon ilaç Bilgileri</h6>
                                        <TableContainer component={Paper} sx={{height: 440}}>
                                            <Table sx={{ minWidth: 600}} stickyHeader size="small" className="cstm-border-top">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" width='20%'>İlaç Adı</TableCell>
                                                        <TableCell align="center" width='20%'>Doz</TableCell>
                                                        <TableCell align="center" width='20%'>Kullanım</TableCell>
                                                        <TableCell align="center" width='20%'>Açıklama</TableCell>
                                                        <TableCell align="center" width='20%'>İşlem</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        endikasyonMedicines.length > 0 ?  endikasyonMedicines.map((row) => (
                                                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell align="center" width='20%' style={{whiteSpace: 'nowrap'}}>{row?.ilac_adi}</TableCell>
                                                                <TableCell align="center" width='20%' style={{whiteSpace: 'nowrap'}}>{row?.doz}</TableCell>
                                                                <TableCell align="center" width='20%' style={{whiteSpace: 'nowrap'}}>{row?.kullanim}</TableCell>
                                                                <TableCell align="center" width='20%' style={{whiteSpace: 'nowrap'}}>{row?.aciklama}</TableCell>
                                                                <TableCell align="center" width='20%'>
                                                                <Stack direction="row" spacing={2} className="justify-content-center">
                                                                    <Tooltip title="ilaç düzenle" arrow>
                                                                        <ButtonM variant="outlined" 
                                                                            onClick={(e) =>  editMedicineInEndikasyonList(e, row) }
                                                                            color="warning"><FaEdit /></ButtonM>
                                                                    </Tooltip>
                                                                    <Tooltip title="listeden çıkar" arrow>
                                                                        <ButtonM variant="outlined" 
                                                                            onClick={(e) => deleteMedicineToEndikasyonList(e, row)}
                                                                            color="error"><FaTimes /></ButtonM>
                                                                    </Tooltip>
                                                                </Stack>
                                                            </TableCell>
                                                            </TableRow>
                                                        )):
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={5} style={{color:"#1172B6",fontSize:"15px",fontWeight:"450"}}>
                                                                Lütfen ilaç ekleyiniz.
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    <Modal isOpen={editMedicineModal} 
                                                        toggle={editMedicineToggle} 
                                                        size="md"
                                                        modalTransition={{ timeout: 1 }}
                                                        backdropTransition={{ timeout: 1 }}
                                                        >
                                                        <ModalHeader toggle={editMedicineToggle} style={{ color: "#1172b6", fontWeight: 200 }}>İlaç Kullanım Bilgileri</ModalHeader>
                                                        <form onSubmit={medicineFormik.handleSubmit}>
                                                            <ModalBody>
                                                                <div className="row d-flex justify-content-center">
                                                                    <div className="col-10 p-3">
                                                                        <div>
                                                                            <FormControl fullWidth>
                                                                                <TextField className='form-group'
                                                                                    type="text"
                                                                                    variant='outlined'
                                                                                    autoComplete="off"
                                                                                    value={updateMedicine?.ilac_adi || ''}
                                                                                    disabled
                                                                                    size='small'
                                                                                    label="İlaç Adı"
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                                                    }}
                                                                                    InputLabelProps={{
                                                                                        style: { color: 'rgb(25, 118, 210)' },
                                                                                    }}
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <FormControl fullWidth>
                                                                                <TextField className='form-group'
                                                                                    type="text"
                                                                                    variant='outlined'
                                                                                    autoComplete="off"
                                                                                    value={updateMedicine?.doz || ''}
                                                                                    size='small'
                                                                                    label="Doz"
                                                                                    onChange={e => {
                                                                                        e.preventDefault();
                                                                                        setUpdateMedicine(prev => ({...prev, doz: e.target.value}));
                                                                                    }}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                                                    }}
                                                                                    InputLabelProps={{
                                                                                        style: { color: 'rgb(25, 118, 210)' },
                                                                                    }}
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <FormControl fullWidth>
                                                                                <TextField className='form-group'
                                                                                    type="text"
                                                                                    variant='outlined'
                                                                                    autoComplete="off"
                                                                                    value={updateMedicine?.kullanim || ''}
                                                                                    size='small'
                                                                                    label="Kullanım"
                                                                                    onChange={e => {
                                                                                        e.preventDefault();
                                                                                        setUpdateMedicine(prev => ({...prev, kullanim: e.target.value}));
                                                                                    }}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                                                    }}
                                                                                    InputLabelProps={{
                                                                                        style: { color: 'rgb(25, 118, 210)' },
                                                                                    }}
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <FormControl fullWidth>
                                                                                <TextField className='form-group'
                                                                                    multiline
                                                                                    rows={2}
                                                                                    type="text"
                                                                                    variant='outlined'
                                                                                    autoComplete="off"
                                                                                    value={updateMedicine?.aciklama || ''}
                                                                                    size='small'
                                                                                    label="Açıklama"
                                                                                    onChange={e => {
                                                                                        e.preventDefault();
                                                                                        setUpdateMedicine(prev => ({...prev, aciklama: e.target.value}));
                                                                                    }}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                                                    }}
                                                                                    InputLabelProps={{
                                                                                        style: { color: 'rgb(25, 118, 210)' },
                                                                                    }}
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button color="primary" type="submit" style={{ background: "#1172b6" }} className="d-flex align-items-center" onClick={updateUsingInfoMedicine}>
                                                                    <FaPaperPlane />&nbsp; Kaydet
                                                                </Button>{' '}
                                                                <Button color="danger" type="button" onClick={editMedicineToggle} className="d-flex align-items-center">
                                                                    <FaTimes />&nbsp;Vazgeç
                                                                </Button>
                                                            </ModalFooter>
                                                        </form>
                                                    </Modal>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={endikasyonId > 0 ? ! can('edit-endikasyonlar') : ! can('create-endikasyonlar')} className="d-flex align-items-center" >
                                {
                                    isProcesssing ? 
                                    (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                            İşleniyor...
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <FaCloudUploadAlt /> &nbsp;
                                            {endikasyonId > 0 ? "Güncelle" :"Kaydet"}
                                        </>
                                    )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>

                {/* endikasyon detail  */}
                <Modal isOpen={endikasyonDetailModal} 
                       toggle={endikasyonDetailToggle} 
                       size="lg"
                       modalTransition={{ timeout: 1 }}
                       backdropTransition={{ timeout: 1 }}
                       >
                    <ModalHeader toggle={endikasyonDetailToggle} style={{ color: "#1172b6", fontWeight: 200 }}>{endikasyonDetail?.endikasyon_adi} - endikasyon bilgileri</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650}} stickyHeader size="small">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell align="center" width='25%'>İlaç Adı</TableCell>
                                            <TableCell align="center" width='25%'>Kullanım</TableCell>
                                            <TableCell align="center" width='25%'>Doz</TableCell>
                                            <TableCell align="center" width='25%'>Açıklama</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {
                                            endikasyonDetail?.ilaclar.map((row) => (
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center" width='25%'>{row?.ilac_adi}</TableCell>
                                                    <TableCell align="center" width='25%'>{row?.kullanim}</TableCell>
                                                    <TableCell align="center" width='25%'>{row?.doz}</TableCell>
                                                    <TableCell align="center" width='25%'>
                                                        <span style={{whiteSpace:'noWrap',overflow: 'scroll'}}>{row?.aciklama}</span>
                                                    </TableCell>

                                                </TableRow>
                                            ))
                                        }
                                        </TableBody>
                                    </Table>
                            </TableContainer>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="danger" type="button" onClick={endikasyonDetailToggle} className="d-flex align-items-center">
                            <FaTimes />&nbsp;Kapat
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>

            {
                useMemo(() => 
                <>
                    {can('view-endikasyonlar') ?  
                        <ThemeProvider theme={theme}>
                            <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                                <DataGrid
                                    autoHeight={data.length > 0 ? true : false}
                                    sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                    loading={isLoading}
                                    rows={data}
                                    columns={columns}
                                    // pagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowCount={totalCount} //serverdan gelen data total count gelecek
                                    page={page}
                                    onPageChange={(newPage) => setPage(newPage)}
                                    pagination
                                    paginationMode="server"
                                    // sorting
                                    sortModel={sortModel}
                                    sortingMode="server"
                                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                    // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                    filterModel={filterModel}
                                    filterMode="server"
                                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                    // selection checkbox
                                    //checkboxSelection={true}
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                    }}
                                    // features
                                    experimentalFeatures={{ newEditingApi: true }}
                                    // toolbar components
                                    components={{
                                        Toolbar: GridToolbar,
                                        NoRowsOverlay: CustomNoRowsOverlay,
                                        LoadingOverlay: LoadingOverlay,
                                    }}
                                    disableColumnFilter
                                    disableSelectionOnClick
                                    // component props
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            printOptions: {
                                                fields: ["id", "endikasyon_adi"],
                                                fileName: "Endikasyonlar",
                                                hideFooter: true,
                                                hideToolbar: true,
                                                disableToolbarButton: true,
                                            }, //sadece firsname  ve lastname alanını alır.
                                            csvOptions: {
                                                disableToolbarButton: false,
                                                fields: ["id","endikasyon_adi"],
                                                fileName: 'Endikasyonlar',
                                                utf8WithBom: true,
                                            }, //sadece firstname ve lastName alanını alır.
                                            excelOptions: { disableToolbarButton: true },
                                            // quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                />
                            </Box>
                        </ThemeProvider>
                    :<NotPermission/>}
                </>
                    , [sortModel, filterModel, pageSize, page, updateDataGrid, data]
                )
            }
        </TabPanel>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};
  
export default connect(authPermissions)(DefinitionEndikasyon);
