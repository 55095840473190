import * as yup from 'yup';  
import parse from "date-fns/parse";
const CreateServiceReceivedValidationSchema = () => {
    return yup.object().shape({

        // tarih: yup.date()
        //         .transform(function (value, originalValue) {
        //         if (this.isType(value)) {
        //             return value;
        //         }
        //         const result = parse(originalValue, "dd.MM.yyyy", new Date());
        //         return result;
        //         })
        //         .typeError("Lütfen geçerli bir tarih giriniz")
        //         .required(),
     
        tutar:yup.number().min(1,'Sıfır değeri eklenemez').required('Lütfen para tutarı girin'),
        aciklama: yup.string()
                .min(4,"Açıklama en az 4 karakter olmalıdır.")
                .max(50,"Açıklama en fazla 50 karakter olmalıdır.")
                .required("Lütfen geçerli bir açıklama bilgisi giriniz."),   

   });
}   

export default CreateServiceReceivedValidationSchema;