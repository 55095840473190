import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import { Button as ButtonM } from "@mui/material";
import "assets/css/pages/definition.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { TabPanel } from "@mui/lab";
import { InputAdornment, FormControl, TextField, MenuItem, FormHelperText } from '@mui/material';
import { useFormik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaTimes,
    FaEdit,
    FaTrash,
    FaCheck,
    FaClock,
    FaExclamation,
    FaHandHoldingMedical,
    FaExclamationCircle,
    FaPenNib,
    FaPaintBrush
} from "react-icons/fa";
import CreateAppointmentTreatmentValidationSchema from "business-layer/CreateAppointmentTreatmentValidationSchema";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const DefinitionRandevuTedavi = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const columns = [
        {
            field: "id",
            headerName: "Sıra",
            align: "center",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            hideable: true,
            editable: false,
            headerAlign: "center",
        },
        {
            field: "aciklama",
            headerName: "Açıklama",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "sure",
            headerName: "Süre",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "status",
            headerName: "Durum",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.durum ? <FaCheck color="#1ca345" /> : <FaTimes color="#de3f44" />
                )
            }
        },
        {
            field: "renk",
            headerName: "Renk",
            minWidth: 300,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    <div style={{
                        width: '100%',
                        height: '80%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: params?.row?.arkaplan_rengi ? params?.row?.arkaplan_rengi : "#1172b6",
                        color: params?.row?.yazi_rengi ? params?.row?.yazi_rengi : "#ffffff",
                        textAlign: 'center',
                        padding: '6px 0'
                    }}>
                        {params?.row?.aciklama}
                    </div>
                )
            }
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 250,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const deleteAppointmentTreatment = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.aciklama + " silinsin mi?",
                        text: "silme işlemini gerçekleştirdiğinizde durumu pasif hale gelecektir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, Sil',
                        cancelButtonText: 'Hayır,Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync('/randevu-tedaviler', params.row.id)
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });

                        }
                    })
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="randevu tedavi düzenle" arrow>
                            <ButtonM variant="outlined" onClick={(e) => editNewAppointmentTreatment(e, params?.row)} disabled={! can('edit-randevu-tedaviler')} color="warning"><FaEdit /></ButtonM>
                        </Tooltip>
                        <Tooltip title="randevu tedavi sil" arrow>
                            <ButtonM variant="outlined" onClick={deleteAppointmentTreatment}  disabled={! can('delete-randevu-tedaviler')} color="error"><FaTrash /></ButtonM>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];

    const [modal, setModal] = useState(false);
    const [treatmentId, setTreatmentId] = useState(0);
    const toggle = () => setModal(!modal);

    const initialAppointmentTreatment = {
        aciklama: '',
        sure: 15,
        arkaplan_rengi: '#1172b6',
        yazi_rengi: '#ffffff'
    }

    // yup schema
    let appointmentTreatmentValidationSchema = CreateAppointmentTreatmentValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialAppointmentTreatment,
        validationSchema :  appointmentTreatmentValidationSchema,
        onSubmit: values => {
            setIsProcessing(true);
            if (treatmentId === 0) {
                AxiosCrudActions.CreateAsync('randevu-tedaviler', values).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            } else if (treatmentId > 0) {
                AxiosCrudActions.UpdateAsync(`randevu-tedaviler/${treatmentId}`, values).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            }
        },
    });

    const addNewAppointmentTreatment = (event) => {
        event.preventDefault();
        formik.setValues({
            aciklama: "",
            sure: 15,
            arkaplan_rengi: '#1172b6',
            yazi_rengi: '#ffffff',
        }, false)
        formik.setErrors({});
        setTreatmentId(0);
        toggle();
    }

    const editNewAppointmentTreatment = (event, values) => {
        event.preventDefault();
        formik.setValues({
            aciklama: values?.aciklama ? values?.aciklama : '',
            sure: values?.sure ? values?.sure : 0,
            arkaplan_rengi: values?.arkaplan_rengi ? values?.arkaplan_rengi : '#1172b6',
            yazi_rengi: values?.yazi_rengi ? values?.yazi_rengi : '#ffffff',
            durum: values?.durum ? values?.durum : 0,
        }, true)
        formik.setErrors({});
        setTreatmentId(values?.id);
        toggle();
    }

    // data-grid state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [isProcesssing, setIsProcessing] = useState(false);
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    // side effect
    useEffect(() => {
        axios.get("randevu-tedaviler", {
            params: {
                filterModel: JSON.stringify(filterModel),
                pageSize: pageSize,
                page: page,
                sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    setData(res.data.data);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, [sortModel, filterModel, pageSize, page, updateDataGrid]);


    return (
        <TabPanel value="4" className="definitions-container">
            {
                isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
            }
            <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                    <strong className="fs-5 fw-normal text text-secondary">Tüm Randevu Tedaviler &nbsp;</strong>
                </span>
                {can('view-randevu-tedaviler') ?   
                    <ButtonM color="success" variant="outlined" startIcon={<FaPlus />} disabled={! can('create-randevu-tedaviler')} onClick={addNewAppointmentTreatment}>
                        Yeni Randevu Tedavi
                    </ButtonM>
                :''}

                <Modal isOpen={modal} 
                       toggle={toggle} 
                       size="md"
                       modalTransition={{ timeout: 1 }}
                       backdropTransition={{ timeout: 1 }}
                       >
                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Randevu Tedavi Bilgileri</ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="pb-3">
                                    <FormControl fullWidth className="mt-3">
                                        <TextField className='form-group'
                                            type="text"
                                            name="aciklama"
                                            variant='outlined'
                                            label="Açıklama"
                                            autoComplete="off"
                                            InputLabelProps={{ style: { color: formik.errors.aciklama && formik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                                            onChange={formik.handleChange}
                                            value={formik.values.aciklama}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><FaHandHoldingMedical /></InputAdornment>,
                                            }}
                                            error={formik.errors.aciklama && formik.touched.aciklama}
                                            helperText={formik.errors.aciklama && formik.touched.aciklama && <span className='cstm-input-error-validation-msg'>{formik.errors.aciklama}</span>}
                                        />
                                    </FormControl>
                                </div>
                                <div className="mt-2">
                                    <FormControl fullWidth>
                                        <TextField
                                            name='sure'
                                            select
                                            InputLabelProps={{ style: { color: '#1976d2' } }}
                                            label="Tedavi Süresi"
                                            size='small'
                                            defaultValue={formik.values.sure}
                                            value={formik.values.sure}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><FaClock /></InputAdornment>,
                                            }}
                                        >
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                            <MenuItem value={45}>45</MenuItem>
                                            <MenuItem value={60}>60</MenuItem>
                                            <MenuItem value={75}>75</MenuItem>
                                            <MenuItem value={90}>90</MenuItem>
                                            <MenuItem value={105}>105</MenuItem>
                                            <MenuItem value={120}>120</MenuItem>
                                            <MenuItem value={135}>135</MenuItem>
                                            <MenuItem value={150}>150</MenuItem>
                                            <MenuItem value={165}>165</MenuItem>
                                            <MenuItem value={180}>180</MenuItem>
                                            <MenuItem value={195}>195</MenuItem>
                                            <MenuItem value={210}>210</MenuItem>
                                            <MenuItem value={225}>225</MenuItem>
                                            <MenuItem value={240}>240</MenuItem>
                                        </TextField>
                                        <FormHelperText><FaExclamationCircle /> Randevu takviminde, tedavi için ayrılması gereken zaman.</FormHelperText>
                                    </FormControl>
                                </div>

                                <div className="mt-3">
                                    <FormControl fullWidth>
                                        <TextField className='form-group'
                                            type="color"
                                            name="arkaplan_rengi"
                                            variant='outlined'
                                            label="Arka Plan Rengi"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            value={formik.values.arkaplan_rengi}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><FaPaintBrush /></InputAdornment>,
                                            }}
                                        />
                                    </FormControl>
                                </div>

                                <div className="mt-4">
                                    <FormControl fullWidth>
                                        <TextField className='form-group'
                                            type="color"
                                            name="yazi_rengi"
                                            variant='outlined'
                                            label="Yazı Rengi"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            value={formik.values.yazi_rengi}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><FaPenNib /></InputAdornment>,
                                            }}
                                        />
                                    </FormControl>
                                </div>

                            </div>
                            <div className="mt-4">
                                {
                                    treatmentId > 0 &&
                                    <FormControl fullWidth>
                                        <TextField
                                            name='durum'
                                            select
                                            InputLabelProps={{ style: { color: '#1976d2' } }}
                                            label="Durum"
                                            size='small'
                                            defaultValue={formik.values.durum ? 1 : 0}
                                            value={formik.values.durum ? 1 : 0}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><FaExclamation /></InputAdornment>,
                                            }}>
                                            <MenuItem value={0}>Pasif</MenuItem>
                                            <MenuItem value={1}>Aktif</MenuItem>
                                        </TextField>
                                    </FormControl>
                                }
                            </div>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={treatmentId > 0 ? ! can('edit-randevu-tedaviler'): ! can('create-randevu-tedaviler')} className="d-flex align-items-center" >
                                {
                                    isProcesssing ? 
                                    (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                            İşleniyor...
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <FaCloudUploadAlt /> &nbsp;
                                            {treatmentId > 0 ? "Güncelle" :"Kaydet"}
                                        </>
                                    )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>

            {
                useMemo(() =>
                <>
                {can('view-randevu-tedaviler') ?   
                    <ThemeProvider theme={theme}>
                        <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                            <DataGrid
                                autoHeight={data.length > 0 ? true : false}
                                sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                loading={isLoading}
                                rows={data}
                                columns={columns}
                                // pagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowCount={totalCount} //serverdan gelen data total count gelecek
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                pagination
                                paginationMode="server"
                                // sorting
                                sortModel={sortModel}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                filterModel={filterModel}
                                filterMode="server"
                                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                // selection checkbox
                                //checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                // features
                                experimentalFeatures={{ newEditingApi: true }}
                                // toolbar components
                                components={{
                                    Toolbar: GridToolbar,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    LoadingOverlay: LoadingOverlay,
                                }}
                                disableColumnFilter
                                disableSelectionOnClick
                                // component props
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: {
                                            fields: ["id", "aciklama", "sure"],
                                            fileName: "randevu-tedavi",
                                            hideFooter: true,
                                            hideToolbar: true,
                                            disableToolbarButton: true,
                                        }, //sadece firsname  ve lastname alanını alır.
                                        csvOptions: {
                                            disableToolbarButton: false,
                                            fields: ["aciklama", "sure"],
                                            fileName: "randevu-tedavi",
                                            utf8WithBom: true,
                                        }, //sadece firstname ve lastName alanını alır.
                                        excelOptions: { disableToolbarButton: true },
                                        // quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Box>
                    </ThemeProvider>
                :<NotPermission/>}
                </>
                    , [sortModel, filterModel, pageSize, page, updateDataGrid, data]
                )
            }

        </TabPanel>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
  export default connect(authPermissions)(DefinitionRandevuTedavi);
