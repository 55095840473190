
import * as yup from 'yup';  

const CreateTreatmentValidationSchema = () => {
    return yup.object().shape({

        tedavi_tanimi: yup.string()
            .min(2, "Tedavi Tanımı en az 2 karakter olmalıdır.")
            .required("Lütfen geçerli bir Tedavi Tanımı bilgisi giriniz."),

        tedavi_kategori_id: yup.number()
            .min(1, "Lütfen Kategori seçiniz.")
            .required("Lütfen Kategori seçiniz."),
        
        tarife_id: yup.number()
            .required("Lütfen Tarife seçiniz."),
       

        fiyat: yup.number()
            .required("Lütfen Fiyat giriniz."),

        kdv: yup.number()
            .min(0, "Kdv oranı negatif değer alamaz.")
            .max(100, "Kdv oranı 100 değerini aşamaz.")
            .integer("Kdv oranı sadece tam sayı alabilir.")
            .required("Lütfen Kdv giriniz."),

        masraf: yup.string()
           .required("Lütfen Masraf giriniz."),
     
      

   });
}   

export default CreateTreatmentValidationSchema;

