
import * as yup from 'yup';  

const CreateLabProcessValidationSchema = () => {
    return yup.object().shape({

           islem_adi: yup.string()
                  .min(2,"İşlem bilgisi en az 2 karakter olmalıdır.")
                  .max(50,"İşlem bilgisi en fazla 50 karakter olmalıdır.")
                  .required("Lütfen geçerli işlem bilgisi giriniz."),
   });
}   

export default CreateLabProcessValidationSchema;

