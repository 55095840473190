import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import { Button as ButtonM } from "@mui/material";
import "assets/css/pages/definition.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { TabPanel } from "@mui/lab";
import CreateDiagnozValidationSchema from "business-layer/CreateDiagnozValidationSchema";
import { InputAdornment, FormControl, TextField, MenuItem, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaDiagnoses,
    FaTimes,
    FaEdit,
    FaTrash,
    FaCheck,
    FaExclamation
} from "react-icons/fa";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const DefinitionDiagnoz = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const columns = [
        {
            field: "id",
            headerName: "Sıra",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            align: "center",
            hideable: true,
            editable: false,
            headerAlign: "center"
        },
        {
            field: "kategori",
            headerName: "Kategori",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return params?.row?.kategori?.kategori_adi ? params?.row?.kategori?.kategori_adi : <FaTimes color="#de3f44" />;
            },
            valueGetter: (params) => `${params?.row?.kategori?.kategori_adi}`
        },
        {
            field: "tedavi_tanimi",
            headerName: "Tanım",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "tedavi_kod",
            headerName: "Kod",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.tedavi_kod ? params?.row?.tedavi_kod : <FaTimes color="#de3f44" />
                )
            },
            valueGetter: (params) => `${params?.row?.tedavi_kod ? params?.row?.tedavi_kod : ''}`
        },
        {
            field: "sekil_durum",
            headerName: "Şekil",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.sekil_durum ? <FaCheck color="#1ca345" /> : <FaTimes color="#de3f44" />
                )
            }
        },
        {
            field: "status",
            headerName: "Durum",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.durum ? <FaCheck color="#1ca345" /> : <FaTimes color="#de3f44" />
                )
            }
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 250,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const deleteDiagnoz = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.tedavi_tanimi + " silinsin mi?",
                        text: "silme işlemini gerçekleştirdiğinizde durumu pasif hale gelecektir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, Sil',
                        cancelButtonText: 'Hayır,Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync('/diagnoz', params.row.id)
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });

                        }
                    })
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Diagnoz düzenle" arrow>
                            <ButtonM variant="outlined" onClick={(e) => editDiagnoz(e, params?.row)} disabled={! can('edit-diagnozlar')} color="warning"><FaEdit /></ButtonM>
                        </Tooltip>
                        <Tooltip title="Diagnoz sil" arrow>
                            <ButtonM variant="outlined" onClick={deleteDiagnoz} disabled={! can('delete-diagnozlar')} color="error"><FaTrash /></ButtonM>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];


    const [modal, setModal] = useState(false);
    const [diagnozId, setDiagnozId] = useState(0);

    const [tedaviKategori, setTedaviKategori] = useState({id: 1, kategori_adi: 'Cerrahi'});
    const [tedaviKategoriInputValue, setTedaviKategoriInputValue] = useState('');

    const [addEditKategoriTedavi, setAddEditKategoriTedavi] = useState({id: 1, kategori_adi: 'Cerrahi'});
    const [addEditKategoriTedaviInputValue, setAddEditKategoriTedaviInputValue] = useState('');

    const [tedaviSekilleri, setTedaviSekilleri] = useState([]);
    const [tedaviSekilli, setTedaviSekilli] = useState('');
    const [tedaviSekilInputValue, setTedaviSekilInputValue] = useState('');

    const [isProcesssing, setIsProcessing] = useState(false);

    const toggle = () => setModal(!modal);




    const initialDiagnozInfo = {
        tedavi_tanimi: '',
        tedavi_kategori_id: 1,
        tedavi_sekil: 0,
    }

    // yup schema
    let diagnozValidationSchema = CreateDiagnozValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialDiagnozInfo,
        validationSchema: diagnozValidationSchema,
        onSubmit: values => {
            values.tedavi_kategori_id = addEditKategoriTedavi?.id;
            values.tedavi_sekil = tedaviSekilli?.id;
            setIsProcessing(true);
            if (diagnozId === 0) {
                AxiosCrudActions.CreateAsync('diagnoz', values).then(result => {
                    setIsProcessing(false); 
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            } else if (diagnozId > 0) {
                AxiosCrudActions.UpdateAsync(`diagnoz/${diagnozId}`, values).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            }
        },
    });

    const addNewDiagnoz = (event) => {
        event.preventDefault();
        formik.setValues({
            tedavi_tanimi: '',
            tedavi_kategori_id: 1,
            tedavi_sekil: 0,
        }, false)
        formik.setErrors({});
        setAddEditKategoriTedavi({id: 1, kategori_adi: 'Cerrahi'});
        setTedaviSekilli(null);
        setDiagnozId(0);
        toggle();
    }

    const editDiagnoz = (event, values) => {
        event.preventDefault();
        formik.setValues({
            tedavi_tanimi: values?.tedavi_tanimi ? values?.tedavi_tanimi : '',
            tedavi_kategori_id: values?.kategori?.id ? values?.kategori?.id : 0,
            tedavi_sekil: values?.tedavi_sekil ? values?.tedavi_sekil : 0,
            durum: values?.durum ? values?.durum : 0,
        }, true)
        formik.setErrors({});
        setAddEditKategoriTedavi(values?.kategori);
        setTedaviSekilli(values?.sekil);
        setDiagnozId(values?.id);
        toggle();
    }

    const getTedaviler = async () => {
        try {
            let tedaviler = await axios.get('/diagnoz');
            if (tedaviler.status === 200) {
                let tedavisekilleri = tedaviler.data.data;
                setTedaviSekilleri(tedavisekilleri);
            } else {
                setTedaviSekilleri([]);
            }
        } catch (error) {
            setTedaviSekilleri([])
        }
    }

    // data-grid state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    // side effect
    useEffect(() => {
        axios.get("diagnoz", {
            params: {
                filterModel: JSON.stringify(filterModel),
                tedavi_kategori_id: tedaviKategori?.id ? tedaviKategori?.id : 1,
                pageSize: pageSize,
                page: page,
                sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    setData(res.data.data);
                }
            } catch (error) {
                setData([]);
            }
        });
        getTedaviler();
    }, [sortModel, filterModel, pageSize, page, updateDataGrid, tedaviKategori]);


    return (
        <TabPanel value="3" className="definitions-container">
            {
                isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
            }
            <div className="d-flex justify-content-between align-items-center mt-3">
            {can('view-diagnozlar') ? 
            <> 
                <div>
                    <FormControl fullWidth sx={{minWidth: '160px'}}>
                        <Autocomplete
                            name='kategori_id'
                            size="small"
                            options={[
                                {id: 1, kategori_adi: 'Cerrahi'},
                                {id: 2, kategori_adi: 'Endodonti'},
                                {id: 3, kategori_adi: 'Oral Diagnoz'},
                                {id: 4, kategori_adi: 'Ortodonti'},
                                {id: 5, kategori_adi: 'Pedodonti'},
                                {id: 6, kategori_adi: 'Periodontoloji'},
                                {id: 7, kategori_adi: 'Protez'},
                                {id: 8, kategori_adi: 'Restoratif'}
                            ]}
                            getOptionLabel={option => option?.kategori_adi}
                            disableClearable
                            value={tedaviKategori}
                            onChange={(event, newValue) => {
                                setTedaviKategori(newValue)
                            }}
                            onBlur={formik.handleBlur}
                            inputValue={tedaviKategoriInputValue}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onInputChange={(event, newInputValue) => {
                                setTedaviKategoriInputValue(newInputValue);
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Kategori"
                            />}
                        />
                    </FormControl>
                </div>
                <div>
                    <ButtonM color="success" variant="outlined" disabled={!can('create-diagnozlar')} startIcon={<FaPlus />} onClick={addNewDiagnoz}>
                        Yeni Diagnoz
                    </ButtonM>
                </div>
                </> 
                :''}
                <Modal isOpen={modal} 
                       toggle={toggle} 
                       size="md"
                       modalTransition={{ timeout: 1 }}
                       backdropTransition={{ timeout: 1 }}
                       >
                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Diagnoz Bilgileri</ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                                    <FormControl fullWidth className="mt-3">
                                        <TextField className='form-group'
                                            type="text"
                                            name="tedavi_tanimi"
                                            variant='outlined'
                                            label="Diagnoz Tanımı"
                                            autoComplete="off"
                                            InputLabelProps={{ style: { color: formik.errors.tedavi_tanimi && formik.touched.tedavi_tanimi ? '#e53e3e' : '#1976d2' } }}
                                            onChange={formik.handleChange}
                                            value={formik.values.tedavi_tanimi}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><FaDiagnoses /></InputAdornment>,
                                            }}
                                            error={formik.errors.tedavi_tanimi && formik.touched.tedavi_tanimi}
                                            helperText={formik.errors.tedavi_tanimi && formik.touched.tedavi_tanimi && <span className='cstm-input-error-validation-msg'>{formik.errors.tedavi_tanimi}</span>}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className="mt-3">
                                        <Autocomplete
                                            name="tedavi_kategori_id"
                                            options={[
                                                {id: 1, kategori_adi: 'Cerrahi'},
                                                {id: 2, kategori_adi: 'Endodonti'},
                                                {id: 3, kategori_adi: 'Oral Diagnoz'},
                                                {id: 4, kategori_adi: 'Ortodonti'},
                                                {id: 5, kategori_adi: 'Pedodonti'},
                                                {id: 6, kategori_adi: 'Periodontoloji'},
                                                {id: 7, kategori_adi: 'Protez'},
                                                {id: 8, kategori_adi: 'Restoratif'},
                                            ]}
                                            getOptionLabel={option => option?.kategori_adi}
                                            disableClearable
                                            loading={true}
                                            noOptionsText={'Seçenek Bulunamadı'}
                                            value={addEditKategoriTedavi}
                                            onChange={(event, newValue) => {
                                                event.preventDefault();
                                                setAddEditKategoriTedavi(newValue);
                                            }}
                                            onBlur={formik.handleBlur}
                                            inputValue={addEditKategoriTedaviInputValue}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            onInputChange={(event, newInputValue) => {
                                                setAddEditKategoriTedaviInputValue(newInputValue);
                                            }}
                                            size="small"
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Kategori"
                                                error={formik.errors.tedavi_kategori_id && formik.touched.tedavi_kategori_id}
                                                helperText={formik.errors.tedavi_kategori_id && formik.touched.tedavi_kategori_id && <span className='cstm-input-error-validation-msg'>{formik.errors.tedavi_kategori_id}</span>}
                                            />}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className="mt-3">
                                        <Autocomplete
                                            options={tedaviSekilleri}
                                            getOptionLabel={option => option?.tedavi_tanimi}
                                            loading={true}
                                            noOptionsText={'Seçenek Bulunamadı'}
                                            value={tedaviSekilli}
                                            name="tedavi_sekil"
                                            onChange={(event, newValue) => {
                                                event.preventDefault();
                                                setTedaviSekilli(newValue);
                                                
                                            }}
                                            inputValue={tedaviSekilInputValue}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            onInputChange={(event, newInputValue) => {
                                                setTedaviSekilInputValue(newInputValue);
                                            }}
                                            onBlur={formik.handleBlur}
                                            size="small"
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.id}>
                                                        {option.tedavi_tanimi}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Tedavi Şekli"
                                            />}
                                        />
                                    </FormControl>
                                </div>
                                <div>
                                    {
                                        diagnozId > 0 &&
                                        <FormControl fullWidth>
                                            <TextField
                                                name='durum'
                                                select
                                                InputLabelProps={{ style: { color: '#1976d2' } }}
                                                label="Durum"
                                                size='small'
                                                defaultValue={formik.values.durum ? 1 : 0}
                                                value={formik.values.durum ? 1 : 0}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><FaExclamation /></InputAdornment>,
                                                }}>
                                                <MenuItem value={0}>Pasif</MenuItem>
                                                <MenuItem value={1}>Aktif</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    }
                                </div>
                            </div>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={diagnozId > 0 ? !can('edit-diagnozlar'): !can('create-diagnozlar')} className="d-flex align-items-center" >
                                {
                                    isProcesssing ? 
                                    (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                            İşleniyor...
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <FaCloudUploadAlt /> &nbsp;
                                            {diagnozId > 0 ? "Güncelle" :"Kaydet"}
                                        </>
                                    )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>

            {
                useMemo(() =>
                <>
                    {can('view-diagnozlar') ?   
                        <ThemeProvider theme={theme}>

                            <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>

                                <DataGrid
                                    autoHeight={data.length > 0 ? true : false}
                                    sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                    loading={isLoading}
                                    rows={data}
                                    columns={columns}
                                    // pagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowCount={totalCount} //serverdan gelen data total count gelecek
                                    page={page}
                                    onPageChange={(newPage) => setPage(newPage)}
                                    pagination
                                    paginationMode="server"
                                    // sorting
                                    sortModel={sortModel}
                                    sortingMode="server"
                                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                    // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                    filterModel={filterModel}
                                    filterMode="server"
                                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                    // selection checkbox
                                    //checkboxSelection={true}
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                    }}
                                    // features
                                    disableColumnFilter
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                    // toolbar components
                                    components={{
                                        Toolbar: GridToolbar,
                                        NoRowsOverlay: CustomNoRowsOverlay,
                                        LoadingOverlay: LoadingOverlay,
                                    }}
                                    // component props
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            printOptions: {
                                                fields: ["id", "Kategori", "Tanım", "Kod", "Şekil", "Durum"],
                                                fileName: "Diagnoz",
                                                hideFooter: true,
                                                hideToolbar: true,
                                                disableToolbarButton: true,
                                            }, //sadece firsname  ve lastname alanını alır.
                                            csvOptions: {
                                                disableToolbarButton: false,
                                                fields: ["kategori", "tedavi_tanimi", "tedavi_kod"],
                                                fileName: 'Diagnoz',
                                                utf8WithBom: true,
                                            }, //sadece firstname ve lastName alanını alır.
                                            excelOptions: { disableToolbarButton: true },
                                            // quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                />
                            </Box>
                        </ThemeProvider>
                    :<NotPermission/>}
                </>
                    , [sortModel, filterModel, pageSize, page, updateDataGrid, data,tedaviKategori]
                )
            }


        </TabPanel>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
  export default connect(authPermissions)(DefinitionDiagnoz);
