import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import GetTrDate from "extensions/GetTRDate";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { GetTriggerActionName } from "extensions/GetTriggerActionName";
import { styled } from '@mui/material/styles';
import { tooltipClasses }  from '@mui/material/Tooltip';


const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
});


export const HastaRecetelerLogTableColumns = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        flex: 1,
        headerAlign: "center",
        minWidth:100,
        maxWidth:100,
        hideable: true,
        editable: false,
        filterable: false
    },
    {
        field: "hasta",
        headerName: "Hasta",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.hasta?.hasta ? params?.row?.hasta?.hasta : ""
            );
        },
        valueGetter: (params) => `${params?.row?.hasta?.hasta ? params?.row?.hasta?.hasta : ""}`
    },
    {
        field: "doktor",
        headerName: "Doktor",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.doktor?.name ? params?.row?.doktor?.name : ""
            );
        },
        valueGetter: (params) => `${params?.row?.doktor?.name ? params?.row?.doktor?.name : ""}`
    },
    {
        field: "protokol_no",
        headerName: "Protokol No",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "recete_tarihi",
        headerName: "Recete Tarihi",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrDate(params?.row?.recete_tarihi)
            )
        },
        valueGetter: (params) => `${GetTrDate(params?.row?.recete_tarihi)}`
    },
    
    // {
    //     field: "faturaKalemleri",
    //     headerName: "Fatura Kalemleri",
    //     minWidth: 130,
    //     flex: 1,
    //     editable: false,
    //     hideable: false, // cant hide this column
    //     sortable: false,
    //     hide: false, //when data grid loaded, this column will hide
    //     filterable: false,
    //     renderCell: (params) => {
    //         return(
    //             <NoMaxWidthTooltip sx={{maxWidth: 'none'}}  title={
    //                 <TableContainer component={Paper}>
    //                     <Table sx={{ minWidth: 1000}} size="small" aria-label="simple table">
    //                     <TableHead>
    //                         <TableRow>
    //                             <TableCell align="center">Tarih</TableCell>
    //                             <TableCell align="center">Doktor</TableCell>
    //                             <TableCell align="center">Tedavi</TableCell>
    //                             <TableCell align="center">Dişler</TableCell>
    //                             <TableCell align="center">KDV</TableCell>
    //                             <TableCell align="center">Tutar</TableCell>
    //                             <TableCell align="center">Toplam</TableCell>
    //                             <TableCell align="center">Açıklama</TableCell>
    //                         </TableRow>
    //                     </TableHead>
    //                     <TableBody>
    //                         {params?.row?.faturaKalemleri.map((row) => (
    //                         <TableRow
    //                             key={row.id}
    //                             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    //                         >
    //                             <TableCell component="th" scope="row">{GetTrDate(row.tarih)}</TableCell>
    //                             <TableCell align="center">{row?.doktor?.name + (row?.doktor?.brans ? ' ' + row?.doktor?.brans : '')}</TableCell>
    //                             <TableCell align="center">{row.tedavi + ' - ' + row?.kokler}</TableCell>
    //                             <TableCell align="center">{row.disler}</TableCell>
    //                             <TableCell align="center">{row.kdv}</TableCell>
    //                             <TableCell align="center">{row.tutar}</TableCell>
    //                             <TableCell align="center">{row.toplam}</TableCell>
    //                             <TableCell align="center">{row.aciklama}</TableCell>
    //                         </TableRow>
    //                         ))}
    //                     </TableBody>
    //                     </Table>
    //                 </TableContainer>
    //             } arrow>
    //                 <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>+ {params?.row?.faturaKalemleri.length}</span>
    //             </NoMaxWidthTooltip>
    //         )
    //     }
    // },
    // {
    //     field: "aciklama",
    //     headerName: "Açıklama",
    //     minWidth: 200,
    //     flex: 1,
    //     editable: false,
    //     hideable: false, // cant hide this column
    //     sortable: false,
    //     hide: false, //when data grid loaded, this column will hide
    //     filterable: true,
    //     renderCell: (params) => {
    //         return(
    //             <Tooltip title={params.row.aciklama} arrow>
    //                 <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.aciklama}</span>
    //             </Tooltip>
    //         )
    //     }        
    // },

    {
        field: "action",
        headerName: "İşlem",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTriggerActionName(params?.row?.action)
            )
        },
        valueGetter: (params) => GetTriggerActionName(params?.row?.action)
    },
    {
        field: "update_text",
        headerName: "Güncelleme İşlemi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "user",
        headerName: "Kullanıcı",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.user?.name ? params?.row?.user?.name : ""
            )
        },
        valueGetter: (params) => `${params?.row?.user?.name ? params?.row?.user?.name : ""}`,
    },
    {
        field: "created_at",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrFullDateAndTimeWithFormat(params?.row?.created_at)
            )
        },
        valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params?.row?.created_at)}`
    },
];