import axios from 'axios';

const AuthActionType = {
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAIL: "LOGIN_FAIL",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAIL: "LOGOUT_FAIL",
    REMOVE_LOGIN_INFO : "REMOVE_LOGIN_INFO"
};

const LoginAuthAction = (userState, navigate) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('/login', userState);
            const {data} = res;
            dispatch({type: AuthActionType.LOGIN_SUCCESS, payload: data});
            navigate("/anasayfa", {replace: true});
        } catch (error) {
            if(error.response){
                dispatch({type: AuthActionType.LOGIN_FAIL, payload: error?.response?.data?.error});
            }
        }
    }
}

const LogoutAuthAction = (navigate) => {
    return async (dispatch) => {
        try {
            const res = await axios.get('/logout');
            const {data} = res;
            dispatch({type: AuthActionType.LOGOUT_SUCCESS, payload: data.message});
            navigate("/login", {replace: true}); 
        } catch (error) {
            if(error.response){
                dispatch({type: AuthActionType.LOGOUT_FAIL, payload: error?.response?.data?.error});
                navigate("/login", {replace: true});
            }
        }
    }
}

const RemoveLoginInfo = () => {
    return async (dispatch) => {
        dispatch({type: AuthActionType.REMOVE_LOGIN_INFO, payload: {}})
    }
}

export { LoginAuthAction, AuthActionType, LogoutAuthAction, RemoveLoginInfo};