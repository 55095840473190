import ReactDOM from 'react-dom/client';
import App from 'App';
import { Provider } from 'react-redux';
import axios from 'axios';
import store from 'redux/store';
import { RemoveLoginInfo } from 'redux/actions/AuthAction';


//axios.defaults.baseURL = "https://api.yavuzdent.com.tr/api";
//axios.defaults.baseURL = "https://api.ayzadental.com/api";
// axios.defaults.baseURL = "https://www.ciftcialir.com/api";
axios.defaults.baseURL = "https://apiyildizli.ayzadental.com/api";
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        store.dispatch(RemoveLoginInfo())
    }
    return Promise.reject(error);
});


const root = ReactDOM.createRoot(document.getElementById('main-layout'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
