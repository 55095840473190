import React from 'react';
import { InputAdornment, FormControl, Card, TextField, Autocomplete,Button as ButtonM } from '@mui/material';
import { FaUserCircle, FaTh } from 'react-icons/fa';
import 'assets/css/pages/patients.css';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {tr} from 'date-fns/locale'
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

function Bills(props) {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const navigate = useNavigate(); 
    return (
        <>
        {can('view-raporlama-faturalar') ?   
            <div className="add-patient-container">
                <form>
                    <Card variant='outlined' className='p-3'>
                        <div className="d-flex justify-content-between align-items-center">
                                <span className="d-flex align-items-center">
                                    <h4 className="fs-3 fw-light mb-3">Faturalar</h4>
                                </span>
                                <span className="d-flex align-items-center">
                                        <ButtonM className='report-income-analysis-button-bgColor'
                                                variant="contained"
                                                type="submit" startIcon={<FaTh />} sx={{mb:2}}
                                                onClick={() => { navigate('/raporlama') }}
                                            >
                                                Raporlama listesi
                                            </ButtonM>
                                </span>
                        </div>
                        {/* personal information area */}
                        <div className="cstm-border-top card p-3 mb-3">
                            <h4 className="card-form-panel-title mb-4">Gelişmiş Arama</h4>
                            <div className='row'>
                                <div className='form-group'>
                                    <div className='row mt-2'>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="ad_soyad"
                                                    variant='outlined'
                                                    label="Ad Soyad"
                                                    autoComplete="off"
                                                    id="outlined-start-adornment"
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaUserCircle /></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="tc_no"
                                                    autoComplete="off"
                                                    label="TC kimlik"
                                                    variant='outlined'
                                                    size='small'
                                                    inputProps={{ maxLength: 11 }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="ad_soyad"
                                                    variant='outlined'
                                                    label="Ad Soyad"
                                                    autoComplete="off"
                                                    id="outlined-start-adornment"
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaUserCircle /></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="tc_no"
                                                    autoComplete="off"
                                                    label="TC kimlik"
                                                    variant='outlined'
                                                    size='small'
                                                    inputProps={{ maxLength: 11 }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="ad_soyad"
                                                    variant='outlined'
                                                    label="Ad Soyad"
                                                    autoComplete="off"
                                                    id="outlined-start-adornment"
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaUserCircle /></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="tc_no"
                                                    autoComplete="off"
                                                    label="TC kimlik"
                                                    variant='outlined'
                                                    size='small'
                                                    inputProps={{ maxLength: 11 }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="ad_soyad"
                                                    variant='outlined'
                                                    label="Ad Soyad"
                                                    autoComplete="off"
                                                    id="outlined-start-adornment"
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaUserCircle /></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="tc_no"
                                                    autoComplete="off"
                                                    label="TC kimlik"
                                                    variant='outlined'
                                                    size='small'
                                                    inputProps={{ maxLength: 11 }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="ad_soyad"
                                                    variant='outlined'
                                                    label="Ad Soyad"
                                                    autoComplete="off"
                                                    id="outlined-start-adornment"
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaUserCircle /></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="tc_no"
                                                    autoComplete="off"
                                                    label="TC kimlik"
                                                    variant='outlined'
                                                    size='small'
                                                    inputProps={{ maxLength: 11 }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                            <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                                    <DesktopDatePicker
                                                        variant='outlined'
                                                        label="Doğum Tarihi"
                                                        inputFormat="dd-MM-yyyy"
                                                        minDate={dayjs('01-01-1930')}
                                                        renderInput={(params) => <TextField {...params}
                                                            size="small"
                                                            name="dogum_tarihi"
                                                            variant='outlined'
                                                        />}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    getOptionLabel={option => option.name}
                                                    loading={true}
                                                    noOptionsText={'Seçenek Bulunamadı'}
                                                    name="doctor"
                                                    label="Hekim"
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    size="small"
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {option.name}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Hekim"
                                                    />}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    getOptionLabel={option => option.name}
                                                    loading={true}
                                                    noOptionsText={'Seçenek Bulunamadı'}
                                                    name="doctor"
                                                    label="Hekim"
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    size="small"
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {option.name}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Hekim"
                                                    />}

                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Contact information area*/}
                        <div className="cstm-border-top card p-3 mb-3">
                            <h4 className="card-form-panel-title mb-4">Tarih Aralığı</h4>
                            {/* <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                    <FormControl fullWidth>
                                        <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                            <DesktopDatePicker
                                                variant='outlined'
                                                label="Doğum Tarihi"
                                                inputFormat="dd-MM-yyyy"
                                                minDate={dayjs('01-01-1930')}
                                                renderInput={(params) => <TextField {...params}
                                                    size="small"
                                                    name="dogum_tarihi"
                                                    variant='outlined'
                                                />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                    <FormControl fullWidth>
                                        <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                            <DesktopDatePicker
                                                variant='outlined'
                                                label="Doğum Tarihi"
                                                inputFormat="dd-MM-yyyy"
                                                minDate={dayjs('01-01-1930')}
                                                renderInput={(params) => <TextField {...params}
                                                    size="small"
                                                    name="dogum_tarihi"
                                                    variant='outlined'
                                                />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>
                            </div> */}
                        </div>
                    </Card>
                </form>
            </div>
        :<NotPermission/>}
        </>
    )
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
export default connect(authPermissions)(Bills);