import React from "react";

const Setting = () => {
    return (
        <>
           I am the Setting Page
        </>
    )
}

export default Setting;