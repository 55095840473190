
import * as yup from 'yup';  

const CreateAppointmentTreatmentValidationSchema = () => {
    return yup.object().shape({

      aciklama: yup.string()
            .min(4,"Açıklama en az 4 karakter olmalıdır.")
            .max(50,"Açıklama en fazla 50 karakter olmalıdır.")
            .required("Lütfen geçerli bir açıklama bilgisi giriniz."),

   });
}   

export default CreateAppointmentTreatmentValidationSchema;