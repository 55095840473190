
const ConvertStringToFloat = (number = null) => {
    if(number !== undefined && number !== null){
        try {
            return Number.parseFloat(number.replaceAll('.', '').replaceAll(',','.'));
        } catch (error) {
            return 0.00;
        }
    }else{
        return 0.00;
    }
}

export default ConvertStringToFloat;