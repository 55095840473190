import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import { Button as ButtonM, FormHelperText } from "@mui/material";
import "assets/css/pages/definition.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { TabPanel } from "@mui/lab";
import { InputAdornment, FormControl, TextField, MenuItem,Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaTimes,
    FaEdit,
    FaTrash,
    FaCheck,
    FaPills,
    FaExclamation,
    FaSyncAlt,
    FaPen,
    FaQuestionCircle,
    FaLiraSign,
    FaPercent,
    FaMinus
} from "react-icons/fa";
import RateMask from 'Utils/RateMask';
import CurrencyMask from 'Utils/CurrencyMask';
import CreateTreatmentValidationSchema from "business-layer/CreateTreatmentValidationSchema";
import CreateTariffValidationSchema from "business-layer/CreateTariffValidationSchema";
import ConvertStringToFloat from "extensions/ConvertStringToFloat";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const DefinitionTarife = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const columns = [
        {
            field: "id",
            headerName: "Sıra",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            align:"center",
            hideable: true,
            editable: false,
            headerAlign: "center"
        },
        {
            field: "tedaviKategori",
            headerName: "Kategori",
            minWidth: 110,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.tedaviKategori?.kategori_adi ? params?.row?.tedaviKategori?.kategori_adi : ""
                )
            },
            valueGetter: (params) => `${params?.row?.tedaviKategori?.kategori_adi}`
        },
        {
            field: "tedaviTanimi",
            headerName: "Tedavi",
            minWidth: 500,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "fiyat",
            headerName: "Fiyat",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            valueGetter: (params) => `${params?.row?.fiyat} TL`
        },
        {
            field: "kdv",
            headerName: "KDV",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            valueGetter: (params) => `${params?.row?.kdv} %`
        },
        {
            field: "masraf",
            headerName: "Masraf",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "tedaviKod",
            headerName: "KOD",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.tedaviKod ? params.row.tedaviKod : <FaTimes color="#de3f44" />
                )
            },
            valueGetter: (params) => `${params?.row?.tedaviKod}`
        },
        {
            field: "sekil_durum",
            headerName: "Şekil",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.sekil_durum ? <FaCheck color="#1ca345" /> : <FaTimes color="#de3f44" />
                )
            }
        },
        {
            field: "durum",
            headerName: "Durum",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.durum ? <FaCheck color="#1ca345" /> : <FaTimes color="#de3f44" />
                )
            }
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const deleteTreatment = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.tedaviTanimi + " silinsin mi?",
                        text: "silme işlemini gerçekleştirdiğinizde durumu pasif hale gelecektir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, Sil',
                        cancelButtonText: 'Hayır,Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync('/tedaviler', params.row.id)
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });

                        }
                    })
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="tedavi düzenle" arrow>
                            <ButtonM variant="outlined" onClick={(e) => editTreatment(e, params?.row)} disabled={! can('edit-tedaviler')} color="warning"><FaEdit /></ButtonM>
                        </Tooltip>
                        <Tooltip title="tedavi sil" arrow>
                            <ButtonM variant="outlined" onClick={deleteTreatment} disabled={! can('delete-tedaviler')} color="error"><FaTrash /></ButtonM>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];

    // tedaviKategori state for data grid
    const [tedaviKategoriId, setTedaviKategoriId] = useState({id: 1, text: 'Cerrahi'});
    const [tedaviKategoriIdInputValue, setTedaviKategoriIdInputValue] = useState('');
    // tedaviKategori state for add and edit modal kategori 
    const [addEditTedaviKategoriId, setAddEditTedaviKategoriId] = useState({id: 1, text: 'Cerrahi'});
    const [addEdittedaviKategoriIdInputValue, setAddEdittedaviKategoriIdInputValue] = useState('');
    // tarifeler state for data grid
    const [tarifeler, setTarifeler] = useState([]);
    const [tedaviTarifeId, setTedaviTarifeId] = useState(null);
    const [tedaviTarifeIdInputValue, setTedaviTarifeIdInputValue] = useState(''); 
    // tarife state for add and edit modal
    const [addEditTedaviTarifeId, setAddEditTedaviTarifeId] = useState(null);
    const [addEditTedaviTarifeIdInputValue, setAddEditTedaviTarifeIdInputValue] = useState(''); 
    // tedavisekiller state for add and edit modal 
    const [tedaviSekiller, setTedaviSekiller] = useState([]);
    const [tedaviSekilId, setTedaviSekilId] = useState();
    const [tedaviSekilIdInputValue, setTedaviSekilIdInputValue] = useState('');
    // tedaviId state for add and edit tedavi 
    const [tedaviId, setTedaviId] =  useState(0);
    // default tarife state
    const [defaultTarifeTedavi, setDefaultTarifeTedavi] = useState(null);
    // tarifeId state for add and edit tedavi 
    const [tarifeId, setTarifeId] = useState(0);
    // modal state
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    // modal state for add and edit tarife
    const [tarifeModal, setTarifeModal] = useState(false);
    const tarifeToggle = () => setTarifeModal(!tarifeModal);
    // aktarılacak tarifeId for add and edit tarife 
    const [addEditAktarilacakTarifeId, setAddEditAktarilacakTarifeId] = useState(null);
    const [addEditAktarilacakTarifeIdInputValue, setAddEditAktarilacakTarifeIdInputValue] = useState(''); 
    const [updateTariffs, setUpdateTariffs] = useState(false);

    const [isProcesssingTarife, setIsProcessingTarife] = useState(false);
    const [isProcesssingTedavi, setIsProcessingTedavi] = useState(false);
    // remove selected tariff and update patient tariff
    const [isProcessingRemoveTariff, setIsProcessingRemoveTariff] = useState(false);
    const [patientNewTariffId, setPatientNewTariffId] = useState(null);
    const [patientNewTariffIdInputValue, setPatientNewTariffIdInputValue] = useState(''); 
    const [patientNewTariffModal, setPatientNewTariffModal] = useState(false);
    const patientNewTariffToggle = () => setPatientNewTariffModal(!patientNewTariffModal);
    // tarife formik 
    const initialTariffInfo = {
        tarife_adi:'',
        aktarilacak_tarife: 0,
        degisim_type: 1,
        fiyat_degisim: 0,
        varsayilan: 0,
    }
     // yup schema
     let tariffValidationSchema = CreateTariffValidationSchema();
     // formik schema
     const tarifeFormik = useFormik({
         initialValues: initialTariffInfo,
         validationSchema: tariffValidationSchema,
         onSubmit: values => {
            setIsProcessingTarife(true);
             if (tarifeId === 0) {
                 values.aktarilacak_tarife = addEditAktarilacakTarifeId?.id ? addEditAktarilacakTarifeId?.id : null;
                 AxiosCrudActions.CreateAsync('tarifeler', values).then(result => {
                    setIsProcessingTarife(false); 
                    if (result?.data?.status) {
                         setUpdateTariffs(!updateTariffs);
                         tarifeToggle();
                     }
                 });
             } else if (tarifeId > 0) {
                 AxiosCrudActions.UpdateAsync(`tarifeler/${tarifeId}`, values).then(result => {
                     setIsProcessingTarife(false);
                     if (result?.data?.status) {
                        setUpdateTariffs(!updateTariffs);
                        setTedaviTarifeId({id: tedaviTarifeId.id, tarifeAdi: tarifeFormik.values.tarife_adi});
                        tarifeToggle();
                     }
                 });
             }
         },
     });

    //tarife formik end


    const initialTreatmentInfo = {
        tedavi_kategori_id : 1,
        tarife_id : 0,
        tedavi_sekil : 0,
        tedavi_tanimi : '',
        fiyat : 0,
        kdv : 0,
        masraf : 0
    }

    // yup schema
    let treatmentValidationSchema = CreateTreatmentValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialTreatmentInfo,
        validationSchema: treatmentValidationSchema,
        onSubmit: values => {
            values.tedavi_kategori_id = addEditTedaviKategoriId?.id ? addEditTedaviKategoriId?.id : null;
            values.tedavi_sekil = tedaviSekilId?.id ? tedaviSekilId?.id : null;
            values.tarife_id = addEditTedaviTarifeId?.id ? addEditTedaviTarifeId?.id : null;
            setIsProcessingTedavi(true);
            if (tedaviId === 0) {
                AxiosCrudActions.CreateAsync('tedaviler', values).then(result => {
                    setIsProcessingTedavi(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            } else if (tedaviId > 0) {
                AxiosCrudActions.UpdateAsync(`tedaviler/${tedaviId}`, values).then(result => {
                    setIsProcessingTedavi(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            }
        },
    });

    const addNewTreatment = (event) => {
        event.preventDefault();
        formik.setValues({
            tedavi_kategori_id : 1,
            tarife_id : 0,
            tedavi_sekil : 0,
            tedavi_tanimi : '',
            fiyat : 0,
            kdv : 0,
            masraf : 0,
        },false);
        formik.setErrors({});
        setAddEditTedaviKategoriId({id: 1, text: 'Cerrahi'});
        setTedaviSekilId({id: 0, tedaviTanimi: "Seçiniz"});
        setAddEditTedaviTarifeId(defaultTarifeTedavi);
        setTedaviId(0);
        toggle();
    }

    const editTreatment = (event, values) => {
        event.preventDefault();
        formik.setValues({
            tedavi_kategori_id : values?.tedavi_kategori_id ? values?.tedavi_kategori_id  : 1,
            tarife_id : values?.tarife_id ?  values?.tarife_id : 0,
            tedavi_sekil : values?.tedavi_sekil ? values?.tedavi_sekil : 0,
            tedavi_tanimi : values?.tedaviTanimi ? values?.tedaviTanimi : '',
            fiyat : values?.fiyat ? ConvertStringToFloat(values?.fiyat) : 0,
            kdv : values?.kdv ? values?.kdv : 0,
            masraf : values?.masraf ? values?.masraf : 0,
            durum : values?.durum ? values?.durum : 0,
        },true)
        formik.setErrors({});
        setAddEditTedaviKategoriId({id: values?.tedaviKategori?.id , text: values?.tedaviKategori?.kategori_adi});
        setAddEditTedaviTarifeId(values?.tarife ? values?.tarife : null);
        setTedaviSekilId(values?.sekil ? values?.sekil : {id: 0, tedaviTanimi: "Seçiniz"});
        setTedaviId(values?.id);
        toggle();
    }


    const getTarifeler = async () => {
        try {
                let data = await axios.get('tarifeler');
                if(data.status === 200 ){
                    setTarifeler(data.data.data);
                }
                else{
                    setTarifeler([]);
                }
            
        } catch (error) {
            setTarifeler([]);
        }  
    }

    const getTedaviSekiller = async () => {
        await axios.get('tedaviler', {params:{tarife_id: addEditTedaviTarifeId.id}}).then(res => {
                    setTedaviSekiller(res.data.data);
                    setTedaviSekiller(state => [{id: 0, tedaviTanimi: "Seçiniz"}, ...state]);
                    if(!tedaviId){
                        setTedaviSekilId({id: 0, tedaviTanimi: "Seçiniz"})
                    }else{
                        setTedaviSekilId(prevState => prevState)
                    }   
                }).catch(err => {
                    setTedaviSekiller([]);
                })
    }

    const getDefaultTedaviTarife = async () => {
        await axios.get('ayarlar/genel-ayarlar')
                    .then((res) => {
                        setDefaultTarifeTedavi(res.data.data.varsayilan_tarife);
                        setTedaviTarifeId(res.data.data.varsayilan_tarife)
                    })
                    .catch((error) => {
                        setDefaultTarifeTedavi(null);
                    });
    }

    const addNewTariff = (event) => {
        event.preventDefault();
        tarifeFormik.setValues({
            tarife_adi:'',
            aktarilacak_tarife: 0,
            degisim_type: 1,
            fiyat_degisim: '',
            varsayilan: 0,
        },false);
        tarifeFormik.setErrors({});
        setAddEditAktarilacakTarifeId(defaultTarifeTedavi);
        setTarifeId(0);
        tarifeToggle();
    };

    const editTariff = (event) => {
        event.preventDefault();
        if(tedaviTarifeId?.id && tedaviTarifeId.id > 0){
            tarifeFormik.setValues({
                tarife_adi : tedaviTarifeId?.tarifeAdi ? tedaviTarifeId?.tarifeAdi : '',
                degisim_type: 1,
            },true)
            tarifeFormik.setErrors({});
            setTarifeId(tedaviTarifeId?.id);
            tarifeToggle();
        }
    };

    const deleteTariff = (e) => {
        e.preventDefault();
        MySwal.fire({
            title:  tedaviTarifeId?.tarifeAdi,
            text: "Seçilen tarife silinsin mi? Silme işlemini gerçekleştirdiğinizde işlemi geri alamazsınız!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Sil',
            cancelButtonText: 'Hayır,Vazgeç'
        }).then(async (result) => {
            if (result.isConfirmed) {
                patientNewTariffToggle();
            }
        })
    };

    const updatePatientNewTariff = (e) => {
        e.preventDefault();
        setIsProcessingRemoveTariff(true)
        AxiosCrudActions.DeleteAsync('tarifeler',  tedaviTarifeId?.id ,{yeni_tarife: patientNewTariffId?.id}).then(result => {
            setIsProcessingRemoveTariff(false);
            if (result?.status === 200 && result?.data?.status) {
                patientNewTariffToggle();
                getDefaultTedaviTarife();
            }
        }).catch((err) => {
            setIsProcessingRemoveTariff(false);
        });
    }

    // data-grid state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    useEffect(()=> {
        getTarifeler();
    },[updateTariffs])

    useEffect(()=> {
        if(addEditTedaviTarifeId?.id && addEditTedaviTarifeId?.id > 0){        
            getTedaviSekiller();
        }
    }, [addEditTedaviTarifeId])

    useEffect(()=> {
        getDefaultTedaviTarife();
    },[])

    // side effect
    useEffect(() => {
        if(tedaviTarifeId?.id){
            axios.get("tedaviler/kategori-tedavi", {
                params: {
                    filterModel: JSON.stringify(filterModel),
                    pageSize: pageSize,
                    page: page,
                    sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                    sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
                    tedavi_kategori_id: tedaviKategoriId?.id ? tedaviKategoriId.id : 1,
                    tarife_id: tedaviTarifeId?.id ? tedaviTarifeId?.id : defaultTarifeTedavi?.id
                }
            }).then((res) => {
                setIsLoading(false);
                try {
                    if (res.data.data !== undefined && res.data.data !== null) {
                        setTotalCount(res.data.totalCount);
                        setData(res.data.data);
                    }
                } catch (error) {
                    setData([]);
                }
            });
        }
    }, [sortModel, filterModel, pageSize, page, updateDataGrid,tedaviKategoriId,tedaviTarifeId]);


    return (
        <TabPanel value="2" className="definitions-container">
            {
                isProcesssingTarife || isProcesssingTedavi  ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
            }
            {can('view-tarifeler') ? 
            <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3 mb-3 d-flex justify-content-between align-items-center">
                    <FormControl fullWidth>
                        <Autocomplete
                            id="tedaviTarifeId"
                            options={tarifeler}
                            loading={true}
                            getOptionLabel={option => option.tarifeAdi}
                            noOptionsText={'Seçenek Bulunamadı'}
                            value={tedaviTarifeId !== null ? tedaviTarifeId : defaultTarifeTedavi}
                            label="Tarife"
                            disableClearable
                            onChange={(event, newValue) => { 
                                event.preventDefault();
                                setTedaviTarifeId(newValue);
                            }}
                            inputValue={tedaviTarifeIdInputValue}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onInputChange={(event, newInputValue) => { setTedaviTarifeIdInputValue(newInputValue); }}
                            size="small"
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.tarifeAdi}
                                  </li>
                                );
                              }}
                            renderInput={(params) => <TextField {...params}  label="Tarife"/>}  
                        />
                    </FormControl>
                    <div className="d-flex ">
                        <Tooltip title="tarife ekle" arrow>
                            <span style={{width : '36px',
                                        color: '#28a745',
                                        padding: '0 5px',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        border: '1px',
                                        borderStyle: 'solid',
                                        borderColor: 'green',
                                        height: '38px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRight: 'none',
                                        cursor: 'pointer'}}
                                    onClick={ can('create-tarifeler') ? addNewTariff :''}
                                >
                                <FaPlus />
                            </span>
                        </Tooltip>

                        <Tooltip title="tarife düzenle" arrow>
                            <span style={{width : '36px',
                                        color: '#ffc107',
                                        padding: '0 5px',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        border: '1px',
                                        borderStyle: 'solid',
                                        borderColor: 'green',
                                        height: '38px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRight: 'none',
                                        cursor: 'pointer'}}
                                    onClick={ can('edit-tarifeler') ? editTariff :''}
                                >
                                <FaPen />
                            </span>
                        </Tooltip>
                         <Tooltip title="tarife sil" arrow>
                            <span style={{width : '36px',
                                        color: '#dc3545',
                                        padding: '0 5px',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        border: '1px',
                                        borderStyle: 'solid',
                                        borderColor: 'green',
                                        height: '38px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer'}}
                                    onClick={ can('delete-tarifeler') ? deleteTariff :''}
                                >
                                <FaTrash />
                            </span>
                         </Tooltip>
                        
                        
                    </div>
                </div>  
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3 mb-3">
                    <FormControl fullWidth>
                        <Autocomplete
                            options={[
                                {id: 1, text: 'Cerrahi'},
                                {id: 2, text: 'Endodonti'},
                                {id: 3, text: 'Oral Diagnoz'},
                                {id: 4, text: 'Ortodonti'},
                                {id: 5, text: 'Pedodonti'},
                                {id: 6, text: 'Periodontoloji'},
                                {id: 7, text: 'Protez'},
                                {id: 8, text: 'Restoratif'},
                            ]}
                            getOptionLabel={option => option.text}
                            noOptionsText={'Sonuç bulunamadı.'}
                            value={tedaviKategoriId}
                            label="Tedavi Kategori"
                            disableClearable
                            onChange={(event, newValue) => { setTedaviKategoriId(newValue); }}
                            inputValue={tedaviKategoriIdInputValue}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onInputChange={(event, newInputValue) => { setTedaviKategoriIdInputValue(newInputValue); }}
                            size="small"
                            renderInput={(params) => <TextField {...params}  label="Tedavi Kategori" />}  
                        />
                    </FormControl>
                </div>  
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <div className="text-end mt-3">
                        <ButtonM color="success" variant="outlined" startIcon={<FaPlus />} onClick={addNewTreatment} disabled={! can('create-tedaviler')} style={{marginRight: '10px', width:'180px', marginBottom: '5px' }}>
                            Yeni Tedavi
                        </ButtonM>
                    </div>
                </div>
            </div>
            :''}
            {/* modal */}
            <Modal isOpen={modal}
                   toggle={toggle}
                   size="lg"
                   modalTransition={{ timeout: 1 }}
                   backdropTransition={{ timeout: 1 }}
                   >
                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Tedavi Bilgileri</ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                            <div className="row d-flex justify-content-center">
                                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 pb-2 pt-2">
                                    <div className="mt-1">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                options={tarifeler}
                                                loading={true}
                                                getOptionLabel={option => option.tarifeAdi}
                                                noOptionsText={'Seçenek Bulunamadı'}
                                                value={addEditTedaviTarifeId}
                                                disabled={tedaviId !== null && tedaviId > 0}
                                                name="tarife_id"
                                                disableClearable
                                                label="Tedavi Tarife"
                                                onChange={(event, newValue) => { 
                                                    event.preventDefault();
                                                    setAddEditTedaviTarifeId(newValue);
                                                }}
                                                inputValue={addEditTedaviTarifeIdInputValue}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                onInputChange={(event, newInputValue) => { setAddEditTedaviTarifeIdInputValue(newInputValue); }}
                                                size="small"
                                                renderOption={(props, option) => {
                                                    return (
                                                    <li {...props} key={option.id}>
                                                        {option.tarifeAdi}
                                                    </li>
                                                    );
                                                }}
                                                renderInput={(params) => <TextField {...params}  label="Tedavi Tarife"/>}  
                                            />
                                        </FormControl>
                                    </div>

                                    <div className="mt-4">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                options={[
                                                    {id: 1, text: 'Cerrahi'},
                                                    {id: 2, text: 'Endodonti'},
                                                    {id: 3, text: 'Oral Diagnoz'},
                                                    {id: 4, text: 'Ortodonti'},
                                                    {id: 5, text: 'Pedodonti'},
                                                    {id: 6, text: 'Periodontoloji'},
                                                    {id: 7, text: 'Protez'},
                                                    {id: 8, text: 'Restoratif'},
                                                ]}
                                                getOptionLabel={option => option.text}
                                                noOptionsText={'Sonuç bulunamadı.'}
                                                value={addEditTedaviKategoriId}
                                                name="tedavi_kategori_id"
                                                label="Tedavi Kategori"
                                                disableClearable
                                                onChange={(event, newValue) => { setAddEditTedaviKategoriId(newValue); }}
                                                inputValue={addEdittedaviKategoriIdInputValue}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                onInputChange={(event, newInputValue) => { setAddEdittedaviKategoriIdInputValue(newInputValue); }}
                                                size="small"
                                                renderInput={(params) => <TextField {...params}  label="Tedavi Kategori"  InputLabelProps={{ style: { color:'#1976d2' } }}/>}  
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="mt-4">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                options={tedaviSekiller}
                                                getOptionLabel={option => option.tedaviTanimi}
                                                noOptionsText={'Sonuç bulunamadı.'}
                                                value={tedaviSekilId}
                                                name="tedavi_sekil"
                                                label="Tedavi Şekli"
                                                onChange={(event, newValue) => { setTedaviSekilId(newValue); }}
                                                inputValue={tedaviSekilIdInputValue}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id }
                                                onInputChange={(event, newInputValue) => { setTedaviSekilIdInputValue(newInputValue); }}
                                                size="small"
                                                renderOption={(props, option) => {
                                                    return (
                                                      <li {...props} key={option.id}>
                                                        {option.tedaviTanimi}
                                                      </li>
                                                    );
                                                  }}
                                                renderInput={(params) => <TextField {...params}
                                                                                    InputLabelProps={{ style: { color:'#1976d2' } }}
                                                                                    label="Tedavi Şekli" 
                                                                                    />}  
                                            />
                                            <FormHelperText style={{color: '#856404'}}><FaQuestionCircle /> Dişe uygulandığında hangi tedavinin çizimi kullanılsın? Boş bırakırsanız görsel değişiklik olmaz</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className="mt-1">
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="tedavi_tanimi"
                                                variant='outlined'
                                                label="Tedavi Tanımı"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.tedavi_tanimi && formik.touched.tedavi_tanimi ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.tedavi_tanimi}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                }}
                                                error={formik.errors.tedavi_tanimi && formik.touched.tedavi_tanimi}
                                                helperText={formik.errors.tedavi_tanimi && formik.touched.tedavi_tanimi && <span className='cstm-input-error-validation-msg'>{formik.errors.tedavi_tanimi}</span>}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="mt-2">
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="fiyat"
                                                variant='outlined'
                                                label="Tedavi Fiyat"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.fiyat && formik.touched.fiyat ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.fiyat}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    inputComponent: CurrencyMask,
                                                    startAdornment: <InputAdornment position="start" ><FaLiraSign /></InputAdornment>,
                                                }}
                                                error={formik.errors.fiyat && formik.touched.fiyat}
                                                helperText={formik.errors.fiyat && formik.touched.fiyat && <span className='cstm-input-error-validation-msg'>{formik.errors.fiyat}</span>}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="mt-2">
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="kdv"
                                                variant='outlined'
                                                label="Tedavi KDV"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.kdv && formik.touched.kdv ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.kdv}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    inputComponent: RateMask,
                                                    startAdornment: <InputAdornment position="start" ><FaPercent /></InputAdornment>,
                                                }}
                                                error={formik.errors.kdv && formik.touched.kdv}
                                                helperText={formik.errors.kdv && formik.touched.kdv && <span className='cstm-input-error-validation-msg'>{formik.errors.kdv}</span>}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="mt-2">
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="masraf"
                                                variant='outlined'
                                                label="Laboratuvar Masrafı"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.masraf && formik.touched.masraf ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.masraf}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    inputComponent: CurrencyMask,
                                                    startAdornment: <InputAdornment position="start" ><FaLiraSign /></InputAdornment>,
                                                }}
                                                error={formik.errors.masraf && formik.touched.masraf}
                                                helperText={formik.errors.masraf && formik.touched.masraf && <span className='cstm-input-error-validation-msg'>{formik.errors.masraf}</span>}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="mt-4">
                                    {
                                        tedaviId > 0 &&
                                        <FormControl fullWidth>
                                            <TextField
                                                name='durum'
                                                select
                                                InputLabelProps={{ style: { color: '#1976d2' } }}
                                                label="Durum"
                                                size='small'
                                                defaultValue={formik.values.durum ? 1 : 0}
                                                value={formik.values.durum ? 1 : 0}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><FaExclamation /></InputAdornment>,
                                                }}>
                                                <MenuItem value={0}>Pasif</MenuItem>
                                                <MenuItem value={1}>Aktif</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    }
                                </div>
                                </div>
                            </div>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={ tedaviId > 0 ? ! can('edit-tedaviler') : ! can('create-tedaviler') } className="d-flex align-items-center" >
                                {
                                    isProcesssingTedavi ? 
                                    (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                            İşleniyor...
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <FaCloudUploadAlt /> &nbsp;
                                            {tedaviId > 0 ? "Güncelle" :"Kaydet"}
                                        </>
                                    )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
            </Modal>
            {/* modal end*/}

            {/* modal tarife*/}
            <Modal isOpen={tarifeModal}
                   toggle={tarifeToggle}
                   size="md"
                   modalTransition={{ timeout: 1 }}
                   backdropTransition={{ timeout: 1 }}
                   >
                    <ModalHeader toggle={tarifeToggle} style={{ color: "#1172b6", fontWeight: 200 }}>Tarife Bilgileri</ModalHeader>
                    <form onSubmit={tarifeFormik.handleSubmit}>
                        <ModalBody>
                            <div className="row d-flex justify-content-center">
                                <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 ">
                                    <div>
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="tarife_adi"
                                                variant='outlined'
                                                label="Tarife Adi"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: tarifeFormik.errors.tarife_adi && tarifeFormik.touched.tarife_adi ? '#e53e3e' : '#1976d2' } }}
                                                onChange={tarifeFormik.handleChange}
                                                value={tarifeFormik.values.tarife_adi}
                                                onBlur={tarifeFormik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                }}
                                                error={tarifeFormik.errors.tarife_adi && tarifeFormik.touched.tarife_adi}
                                                helperText={tarifeFormik.errors.tarife_adi && tarifeFormik.touched.tarife_adi && <span className='cstm-input-error-validation-msg'>{tarifeFormik.errors.tarife_adi}</span>}
                                            />
                                        </FormControl>
                                    </div>    
                                     {
                                        tarifeId === 0 &&
                                        <React.Fragment>
                                            <div className="mt-4">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        name='varsayilan'
                                                        select
                                                        InputLabelProps={{ style: { color: '#1976d2' } }}
                                                        label="Varsayılan"
                                                        size='small'
                                                        defaultValue={tarifeFormik.values.varsayilan ? 1 : 0}
                                                        value={tarifeFormik.values.varsayilan ? 1 : 0}
                                                        onChange={tarifeFormik.handleChange}
                                                        onBlur={tarifeFormik.handleBlur}
                                                        >
                                                        <MenuItem value={1}>Evet</MenuItem>
                                                        <MenuItem value={0}>Hayır</MenuItem>
                                                    </TextField>
                                                </FormControl>
                                            
                                            </div>
                                            <div className="mt-4">
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        options={tarifeler}
                                                        loading={true}
                                                        getOptionLabel={option => option.tarifeAdi}
                                                        disableClearable
                                                        noOptionsText={'Seçenek Bulunamadı'}
                                                        value={addEditAktarilacakTarifeId}
                                                        name="aktarilacak_tarife"
                                                        label="Aktarılacak Tarife"
                                                        onChange={(event, newValue) => { 
                                                            event.preventDefault();
                                                            setAddEditAktarilacakTarifeId(newValue);
                                                        }}
                                                        inputValue={addEditAktarilacakTarifeIdInputValue}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        onInputChange={(event, newInputValue) => { setAddEditAktarilacakTarifeIdInputValue(newInputValue); }}
                                                        size="small"
                                                        renderOption={(props, option) => {
                                                            return (
                                                            <li {...props} key={option.id}>
                                                                {option.tarifeAdi}
                                                            </li>
                                                            );
                                                        }}
                                                        renderInput={(params) => <TextField {...params}  label="Aktarılacak Tarife"/>}  
                                                    />
                                                    <FormHelperText style={{color: '#856404'}}><FaQuestionCircle /> Yeni tarife, seçilen tarifedeki tedavilerden oluşturulacak</FormHelperText>
                                                </FormControl>
                                            </div>
                                        </React.Fragment>
                                     }
                                    <div className="mt-2">
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="fiyat_degisim"
                                                variant='outlined'
                                                label="Fiyat Değişim Oranı"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: tarifeFormik.errors.fiyat_degisim && tarifeFormik.touched.fiyat_degisim ? '#e53e3e' : '#1976d2' } }}
                                                onChange={tarifeFormik.handleChange}
                                                value={tarifeFormik.values.fiyat_degisim}
                                                onBlur={tarifeFormik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    inputComponent: RateMask,
                                                    startAdornment: <InputAdornment position="start" ><FaPercent /></InputAdornment>,
                                                }}
                                                error={tarifeFormik.errors.fiyat_degisim && tarifeFormik.touched.fiyat_degisim}
                                                helperText={tarifeFormik.errors.fiyat_degisim && tarifeFormik.touched.fiyat_degisim && <span className='cstm-input-error-validation-msg'>{tarifeFormik.errors.fiyat_degisim}</span>}
                                            />
                                            <FormHelperText style={{color: '#856404'}}><FaQuestionCircle /> Yeni tarife fiyatlarını yüzdelik oranda toplu güncelleme. Fiyatların aynı kalmasını istiyorsanız boş bırakabilirsiniz</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className="mt-3">
                                        <div className='form-group patient-cstm-radio-btns'>
                                            <div className='patient-cstm-gender-radio'>
                                                <input 
                                                    type="radio"
                                                    onChange={e => tarifeFormik.setFieldValue('degisim_type',e.target.value) }
                                                    defaultChecked={tarifeFormik.values.degisim_type ? true : false}
                                                    name="degisim_type"
                                                    value="1"
                                                /><label><FaPlus /> Arttır</label>
                                            </div>
                                            <div className='patient-cstm-gender-radio'>
                                                <input 
                                                    type="radio"
                                                    defaultChecked={tarifeFormik.values.degisim_type ? false : true}
                                                    onChange={e => tarifeFormik.setFieldValue('degisim_type',e.target.value) }
                                                    name="degisim_type"
                                                    value="0"
                                                     /><label><FaMinus/> Azalt</label>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={tarifeId > 0 ? ! can('edit-tarifeler') : ! can('create-tarifeler')}  className="d-flex align-items-center" >
                                {
                                    isProcesssingTarife ? 
                                    (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                            İşleniyor...
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <FaCloudUploadAlt /> &nbsp;
                                            {tarifeId > 0 ? "Güncelle" :"Kaydet"}
                                        </>
                                    )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={tarifeToggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
            </Modal>
            {/* modal end*/}

            {/* remove tariff and select new tariff  */}
            {
            <Modal isOpen={patientNewTariffModal}
                toggle={patientNewTariffToggle}
                size="md"
                modalTransition={{ timeout: 1 }}
                backdropTransition={{ timeout: 1 }}
                >
                <ModalHeader toggle={patientNewTariffToggle} style={{ color: "#1172b6", fontWeight: 200 }}>Tarife Hasta Taşı</ModalHeader>
                <form onSubmit={updatePatientNewTariff}>
                    <ModalBody>
                        <div className="row d-flex justify-content-center">
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 pb-2 pt-2">
                                <span className="d-flex align-items-center" style={{fontSize: '14px',fontWeight: 'bold', color: '#1172b6', opacity: '0.8'}}>
                                    <strong>
                                        Silinecek tarifedeki hastalar hangi tarifeye aktarılsın?  &nbsp;
                                    </strong>
                                </span>
                                <div className="mt-3">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            options={tarifeler.filter((el) => el?.id !== tedaviTarifeId?.id )}
                                            loading={true}
                                            getOptionLabel={option => option.tarifeAdi}
                                            noOptionsText={'Seçenek Bulunamadı'}
                                            value={patientNewTariffId}
                                            name="tarife_id"
                                            disableClearable
                                            label="Tedavi Tarife"
                                            onChange={(event, newValue) => { 
                                                event.preventDefault();
                                                setPatientNewTariffId(newValue);
                                            }}
                                            inputValue={patientNewTariffIdInputValue}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onInputChange={(event, newInputValue) => { setPatientNewTariffIdInputValue(newInputValue); }}
                                            size="small"
                                            renderOption={(props, option) => {
                                                return (
                                                <li {...props} key={option.id}>
                                                    {option.tarifeAdi}
                                                </li>
                                                );
                                            }}
                                            renderInput={(params) => <TextField {...params}  label="Tedavi Tarife"/>}  
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={ ! can('delete-tarifeler') } className="d-flex align-items-center" >
                            {
                                isProcessingRemoveTariff ? 
                                (
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                        İşleniyor...
                                    </>
                                )
                                :
                                (
                                    <>
                                        <FaCloudUploadAlt /> &nbsp; Taşı ve Sil
                                    </>
                                )
                            }
                        </Button>{' '}
                        <Button color="danger" type="button" onClick={patientNewTariffToggle} className="d-flex align-items-center">
                            <FaTimes />&nbsp;Vazgeç
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
            }

            {
                useMemo(() =>
                <>
                {can('view-tarifeler') ?   
                    <ThemeProvider theme={theme}>
                        <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                            <DataGrid
                                autoHeight={data.length > 0 ? true : false}
                                sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                loading={isLoading}
                                rows={data}
                                columns={columns}
                                // pagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowCount={totalCount} //serverdan gelen data total count gelecek
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                pagination
                                paginationMode="server"
                                // sorting
                                sortModel={sortModel}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                filterModel={filterModel}
                                filterMode="server"
                                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                // selection checkbox
                                //checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                // features
                                disableColumnFilter
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                                // toolbar components
                                components={{
                                    Toolbar: GridToolbar,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    LoadingOverlay: LoadingOverlay,
                                }}
                                // component props
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: {
                                            fields: ["id", "tedaviKategori", "tedaviTanimi", "fiyat", "kdv", "masraf","kod"],
                                            fileName: "Tedaviler",
                                            hideFooter: true,
                                            hideToolbar: true,
                                            disableToolbarButton: true
                                        }, //sadece firsname  ve lastname alanını alır.
                                        csvOptions: {
                                            disableToolbarButton: false,
                                            fields: ["tedaviKategori", "tedaviTanimi", "fiyat", "kdv", "masraf","kod"],
                                            fileName: 'Tedaviler',
                                            utf8WithBom: true,
                                        }, //sadece firstname ve lastName alanını alır.
                                        excelOptions: { disableToolbarButton: true },
                                        // quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Box>
                    </ThemeProvider>
                :<NotPermission/>}
                </>
                    , [sortModel, filterModel, pageSize, page, updateDataGrid, data,tedaviKategoriId,tedaviTarifeId]
                )
            }


        </TabPanel>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
  export default connect(authPermissions)(DefinitionTarife);
