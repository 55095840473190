import * as yup from 'yup';

const SettingsCompanyEBillsValidationSchema = () => {
    return yup.object().shape({

        unvan: yup.string()
            .required("Lütfen geçerli bir Unvan bilgisi giriniz."),
    
        kullanici_adi: yup.string()
            .required("Lütfen geçerli bir Kullanıcı Adı bilgisi giriniz."),
            
        // sifre: yup.string()
        //     .required("Lütfen geçerli bir Şifre bilgisi giriniz."),
        
        telefon: yup.string()
            .required("Lütfen geçerli bir Telefon bilgisi giriniz."),
     
        email: yup.string()
           .email("Lütfen geçerli bir email adresi giriniz.")
           .max(50, "email adresi max 50 karakter olmalıdır.")
           .matches(
               /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@!#$%&'*+-/=?^_`{|}~.]+$/,
               "E-posta yalnızca ingilizce karakter barındırabilir"
           ).required("Lütfen geçerli bir Email adresi giriniz."),
        
        adres: yup.string()
            .required("Lütfen geçerli bir Adres bilgisi giriniz."),
            
        posta_kodu: yup.string()
            .required("Lütfen geçerli bir Posta Kodu bilgisi giriniz."),

        // il_id: yup.string()
        //     .required("Lütfen İl seçiniz.")
        //     .min(1,"Lütfen İl seçiniz."),

        // ilce_id: yup.string()
        //     .required("Lütfen İlçe seçiniz.")
        //     .min(1,"Lütfen İlçe seçiniz."),

        bina_no: yup.string()
            .required("Lütfen geçerli bir Bina Numarası giriniz."),
            
        vergi_dairesi: yup.string()
            .required("Lütfen geçerli bir Vergi Dairesi bilgisi giriniz."),
        
        vkn: yup.string()
            .required("Lütfen geçerli bir Vergi Kimlik Numarası giriniz."),
            
        mersis_no: yup.string()
            .required("Lütfen geçerli bir Mersis Numarası giriniz."),

        ticaret_sicil_no: yup.string()
            .required("Lütfen geçerli bir Ticaret Sicil Numarası giriniz."),

    });
}

export default SettingsCompanyEBillsValidationSchema;