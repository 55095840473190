const GetSignFromMeetingStatus = (status = null) => {
    if(status !== null){
        try {
            switch(status){
                case "Geldi":
                    return '(+)';
                case "Gelmedi":
                    return '(-)';
                case "Tedavide":
                    return '(T)';
                case "Bekliyor":
                    return '(B)';
                default:
                    return '(-)';
            }
        } catch (error) {
            return '-';
        }
    }
}

export default GetSignFromMeetingStatus;