
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import CreatePatientValidationSchema from 'business-layer/CreatePatientValidationSchema';
import React, { useEffect, useState } from 'react';
import {  useFormik } from 'formik';
import { InputAdornment,FormControl, Card, TextField, Autocomplete, MenuItem ,Button as ButtonM  } from '@mui/material';
import {FaUserCircle,FaRegIdCard,FaCloudUploadAlt,FaMale,FaSignal, FaFemale, FaEnvelope, 
       FaRegAddressBook,FaShareAlt, FaHeartbeat, FaCopyright, FaWaveSquare, FaUserFriends} from 'react-icons/fa';
import 'assets/css/pages/patients.css';
import defaultImage from 'assets/images/default.png';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {tr} from 'date-fns/locale'
import axios from 'axios';
import AxiosCrudActions from 'services/AxiosCrudCore';
import { useNavigate } from 'react-router-dom';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import {moment} from 'moment';
// import {tr} from 'dayjs/locale/tr';
import CustomPhoneMask from 'Utils/PhoneMask';
import NumberMask from 'Utils/NumberMask';
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const CreatePatient = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const navigate = useNavigate(); 
    const [file, setFile] = useState(defaultImage);
    const [originalImageFile, setOriginalImageFile] = useState(null);
    const [birthday, setBirthDay] = useState(new Date()); 
    const [cities, setCities] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [city, setCity] = useState(null);
    const [cityInputValue, setCityInputValue] = useState('');
    const [district_id, setDistrictId] = useState(null);
    const [districtInputValue, setDistrictInputValue] = useState('');
    const [doctor, setDoctor] = useState(null);
    const [doctorInputValue, setDoctorInputValue] = useState(''); 
    const [adres, setAdres] = useState('');
    const [tarifeler, setTarifeler] = useState([]);
    const [tarife, setTarife] = useState(null);
    const [tarifeInputValue, setTarifeInputValue] = useState('');
    const [isProcesssing, setIsProcessing] = useState(false);

    const handleImageChange = (e) => {
        setOriginalImageFile(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const removeImage = (e) => {
        setOriginalImageFile(null);
        setFile(defaultImage);
    }

    const handleAdress = (event) => {
        setAdres(event.target.value);
        formik.values.adres = event.target.value;
    }

    const getDate = (date) => {
        if(date !== undefined && date !== null){
            try {
                let getJustDate = dayjs(date).get('year') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('date');
                return getJustDate;
            } catch (error) {
                return null;
            }
        }
    }

    // yup schema
    let validationSchema = CreatePatientValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: {
            ad_soyad: '',
            tc_no: '',
            cinsiyet: 'Erkek',
            dogum_tarihi: '',
            tel_no: '',
            doktor_id: 0,
            uyruk: 'TR',
            city_id: 0,
            district_id:0,
            adres: '',
            tarife_id: 0,
            tedavi_durumu: 0,
            referans_adi: '',
            kan_grubu: 0,
            kurum_sicil_no: '',
            email: '',
            kontrol_periyodu: 0,
            foto: null,
            durum: 1,
        },
        validationSchema,
        onSubmit: values => {
           values.foto = originalImageFile;
           values.dogum_tarihi = getDate(birthday);
           values.city_id = city?.id;
           values.district_id = district_id?.id;
           values.doktor_id = doctor?.id;
           values.tarife_id = tarife?.id;
           setIsProcessing(true); 
           AxiosCrudActions.CreateAsync('hastalar',values).then(result => {
                setIsProcessing(false);
                if(result?.data?.status){
                    navigate('/hastalar');
                }
           });
        },
    });


    const getCities = async () => {
        try {
            let data = await axios.get('cities');
            if(data.status === 200 ){
                setCities(data.data.data);
            }
            else{
                setCities([]);
            }
            
        } catch (error) {
            setCities([]);
        }   
    }

    const getDistricts = async (city_id) => {

        if(city_id !== undefined && city_id !== null && city_id > 0){
            try {
                let data = await axios.get(`cities/${city_id}`);
                if(data.status === 200 ){
                    setDistricts(data.data.data);
                }
                else{
                    setDistricts([]);
                }
                
            } catch (error) {
                setDistricts([]);
            }   
        }
       else{
        setDistricts([]);
       }
    }

    const getDoctors = async () => {
        try {
            let data = await axios.get('kullanicilar?doktor=1');
            if(data.status === 200 ){
                let result = data.data.data;
                let allDoctors = (result).map(({ id, name }) => {return {'id': id, 'name': name}});
                setDoctors(allDoctors);
            }   
            else{
                setDoctors([]);
            }
            
        } catch (error) {
            setDoctors([]);
        }   
    }

    const getTarifeler = async () => {
        try {   
            let tarifeler = await axios.get('/tarifeler');
            if(tarifeler.status === 200){
                setTarifeler(tarifeler.data.data);
            }else{
                setTarifeler([]);
            }
        } catch (error) {
            setTarifeler([])
        }
    }

    const handleRadioButtons = e => formik.values.cinsiyet = e.target.value;

    useEffect(() => {
        getCities();
        getDoctors();
        getTarifeler();
    },[]);

    return (
        <>
         {can('create-hastalar') ? 
             <div className="add-patient-container">
             {
                 isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
             } 
             <form onSubmit={formik.handleSubmit}>
                  <Card variant='outlined' className='p-3'>
                     <div className="d-flex justify-content-between align-items-center">
                             <span className="d-flex align-items-center">
                                 <h4 className='fs-3 fw-light mb-3'>Yeni Hasta Ekle</h4>
                             </span>
                             <span className="d-flex align-items-center">
                                 <ButtonM color="primary" variant="outlined" startIcon={<FaUserFriends />}
                                         onClick={() => { navigate('/hastalar') }}> Hastalar</ButtonM>
                             </span>
                     </div>
                     {/* personal information area */}
                     <div className="cstm-border-top card p-3 mb-3">
                         <h4 className="card-form-panel-title mb-4">KİŞİSEL BİLGİLER</h4>
                         <div className='row'>
                            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-5 col-12'>
                                 <div>
                                     <div className='patient-image-preview-wrap'>
                                         <img src={file} alt="preview"/>
                                     </div>
                                     <FormControl fullWidth>
                                         <input 
                                             type="file" 
                                             name='foto'
                                             id="upload"
                                             hidden
                                             onChange={handleImageChange}/>
                                         <div className='d-flex justify-content-center'>
                                             <label className='patient-image-upload-btn' htmlFor="upload"><FaCloudUploadAlt /> Yükle</label>
                                             <label className='patient-image-remove-btn' onClick={removeImage}>Kaldır</label>
                                         </div>
                                     </FormControl>
                                 </div>
                             </div>
                             <div className='col-xl-9 col-lg-8 col-md-8 col-sm-7 col-12'>
                                 <div className='row mt-2'>
                                     <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                         <FormControl fullWidth>
                                             <TextField className='form-group'
                                                 type="text"
                                                 name="ad_soyad"
                                                 variant='outlined'
                                                 label="Ad Soyad"
                                                 autoComplete="off"
                                                 InputLabelProps={{style: {color: formik.errors.ad_soyad && formik.touched.ad_soyad ? '#e53e3e' : '#1976d2'}}}
                                                 onChange={formik.handleChange}
                                                 value={formik.values.ad_soyad}
                                                 onBlur={formik.handleBlur}
                                                 id="outlined-start-adornment"
                                                 size='small'
                                                 InputProps={{
                                                     startAdornment: <InputAdornment position="start" ><FaUserCircle /></InputAdornment>,
                                                 }}
                                                 error={formik.errors.ad_soyad && formik.touched.ad_soyad}
                                                 helperText={formik.errors.ad_soyad && formik.touched.ad_soyad && <span className='cstm-input-error-validation-msg'>{formik.errors.ad_soyad}</span>}
                                             />
                                         </FormControl>
                                     </div>
                                     <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                         <FormControl fullWidth>
                                             <TextField className='form-group'
                                                 type="text"
                                                 name="tc_no"
                                                 autoComplete="off"
                                                 value={formik.values.tc_no}
                                                 onChange={formik.handleChange}
                                                 onBlur={formik.handleBlur}
                                                 label="TC kimlik"
                                                 variant='outlined'
                                                 InputLabelProps={{style: {color: formik.errors.tc_no && formik.touched.tc_no ? '#e53e3e' : '#1976d2'}}}
                                                 size='small'
                                                 inputProps={{ maxLength: 11 }}
                                                 InputProps={{
                                                     inputComponent: NumberMask,
                                                     startAdornment: <InputAdornment position="start"><FaRegIdCard /></InputAdornment>,
                                                 }}
                                                 helperText={formik.errors.tc_no && formik.touched.tc_no && <span className='cstm-input-error-validation-msg'>{formik.errors.tc_no}</span>}
                                                 error={formik.errors.tc_no && formik.touched.tc_no}
                                             />
                                         </FormControl>
                                     </div>
                                     <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                         <FormControl fullWidth>
                                             <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                                 <DesktopDatePicker
                                                     variant='outlined'
                                                     label="Doğum Tarihi"
                                                     inputFormat="dd-MM-yyyy"
                                                     minDate={dayjs('01-01-1930')}
                                                     value={birthday}
                                                     onChange={(newValue) => {
                                                         setBirthDay(newValue);
                                                     }}
                                                     renderInput={(params) => <TextField {...params} 
                                                                                 size="small"
                                                                                 name="dogum_tarihi"
                                                                                 variant='outlined'
                                                                                 />}
                                                 />
                                             </LocalizationProvider>
                                         </FormControl>
                                     </div>
                                     <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                         <FormControl fullWidth>
                                             <Autocomplete
                                                 options={doctors}
                                                 getOptionLabel={option => option.name}
                                                 loading={true}
                                                 noOptionsText={'Seçenek Bulunamadı'}
                                                 value={doctor}
                                                 name="doctor"
                                                 label="Hekim"
                                                 onChange={(event, newValue) => { 
                                                     setDoctor(newValue);
                                                     formik.setFieldValue('doktor_id', newValue.id, true); //update formik value and validate true
                                                 }}
                                                 inputValue={doctorInputValue}
                                                 isOptionEqualToValue={(option, value) => option.id === value.id}
                                                 onInputChange={(event, newInputValue) => { setDoctorInputValue(newInputValue); }}
                                                 size="small"
                                                 renderOption={(props, option) => {
                                                     return (
                                                       <li {...props} key={option.id}>
                                                         {option.name}
                                                       </li>
                                                     );
                                                 }}
                                                 renderInput={(params) => <TextField 
                                                                             {...params}
                                                                             label="Hekim"
                                                                             error={formik.errors.doktor_id && formik.touched.doktor_id}
                                                                             helperText={formik.errors.doktor_id && formik.touched.doktor_id && <span className='cstm-input-error-validation-msg'>{formik.errors.doktor_id}</span>}
                                                                          />}  
                                                
                                             />
                                         </FormControl>
                                     </div>
                                     <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                         <div className='form-group patient-cstm-radio-btns'>
                                             <div className='patient-cstm-gender-radio'>
                                                 <input 
                                                     type="radio"
                                                     onChange={e => handleRadioButtons(e)}
                                                     defaultChecked
                                                     name="cinsiyet"
                                                     value="Erkek"
                                                 /><label><FaMale /> Erkek</label>
                                             </div>
                                             <div className='patient-cstm-gender-radio'>
                                                 <input 
                                                     type="radio"
                                                     onChange={e => handleRadioButtons(e)}
                                                     name="cinsiyet"
                                                     value="Kadın"
                                                      /><label><FaFemale/> Kadın</label>
                                             </div>  
                                         </div>
                                     </div>
                                 </div> 
                             </div>
                         </div>
                     </div>
                     {/* Contact information area*/}
                     <div className="cstm-border-top card p-3 mb-3">
                         <h4 className="card-form-panel-title">İLETİŞİM BİLGİLERİ</h4>
                         <div className="form-group">
                             <div className='row mt-3'>
                                 <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12'> 
                                     <div className='mb-4'>
                                         <FormControl fullWidth>
                                             <TextField className='form-group'
                                                 type="text"
                                                 name="tel_no"
                                                 autoComplete="off"
                                                 value={formik.values.tel_no}
                                                 onChange={formik.handleChange}
                                                 onBlur={formik.handleBlur}
                                                 label="Tel No"
                                                 variant='outlined'
                                                 InputLabelProps={{style: {color: formik.errors.tel_no && formik.touched.tel_no ? '#e53e3e' : '#1976d2'}}}
                                                 size='small'
                                                 InputProps={{
                                                     inputComponent: CustomPhoneMask,
                                                     startAdornment: <InputAdornment position="start"><FaRegIdCard /></InputAdornment>,
                                                 }}
                                                 helperText={formik.errors.tel_no && formik.touched.tel_no && <span className='cstm-input-error-validation-msg'>{formik.errors.tel_no}</span>}
                                                 error={formik.errors.tel_no && formik.touched.tel_no}
                                             />
                                         </FormControl>
                                     </div>
                                     <div className='mb-4'>
                                         <FormControl fullWidth>
                                             <TextField className='form-group'
                                                 type="text"
                                                 name="email"
                                                 variant='outlined'
                                                 label="Email"
                                                 autoComplete="off"
                                                 InputLabelProps={{style: {color: formik.errors.email && formik.touched.email ? '#e53e3e' : '#1976d2'}}}
                                                 onChange={formik.handleChange}
                                                 value={formik.values.email}
                                                 onBlur={formik.handleBlur}
                                                 id="outlined-start-adornment"
                                                 size='small'
                                                 InputProps={{
                                                     startAdornment: <InputAdornment position="start" ><FaEnvelope /></InputAdornment>,
                                                 }}
                                                 error={formik.errors.email && formik.touched.email}
                                                 helperText={formik.errors.email && formik.touched.email && <span className='cstm-input-error-validation-msg'>{formik.errors.email}</span>}
                                             />
                                         </FormControl>
                                     </div>
                                 </div>
                                 <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12'> 
                                     <div className='mb-4'>
                                         <FormControl fullWidth>
                                             <Autocomplete
                                                 options={cities}
                                                 loading={true}
                                                 getOptionLabel={option => option.il}
                                                 noOptionsText={'Seçenek Bulunamadı'}
                                                 value={city}
                                                 name="Şehir"
                                                 label="Şehir"
                                                 onChange={(event, newValue) => { 
                                                     event.preventDefault();
                                                     setCity(newValue);
                                                     setDistrictId(null);
                                                     setDistricts([]);
                                                     getDistricts(newValue?.id);
                                                 }}
                                                 inputValue={cityInputValue}
                                                 isOptionEqualToValue={(option, value) => option.id === value.id}
                                                 onInputChange={(event, newInputValue) => { setCityInputValue(newInputValue); }}
                                                 size="small"
                                                 renderInput={(params) => <TextField {...params}  label="Şehir" />}  
                                             />
                                         </FormControl>
                                     </div>
                                     <div className='mb-4'>
                                         <FormControl fullWidth>
                                             <Autocomplete
                                                 options={districts}
                                                 getOptionLabel={option => option.ilce}
                                                 noOptionsText={'Lütfen Şehir Seçiniz.'}
                                                 value={district_id}
                                                 name="İlçe"
                                                 label="İlçe"
                                                 onChange={(event, newValue) => { setDistrictId(newValue); }}
                                                 inputValue={districtInputValue}
                                                 isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                 onInputChange={(event, newInputValue) => { setDistrictInputValue(newInputValue); }}
                                                 size="small"
                                                 renderInput={(params) => <TextField {...params}  label="İlçe" />}  
                                             />
                                         </FormControl>
                                     </div>       
                                 </div>
                                 <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12'>
                                      <FormControl fullWidth>
                                         <TextField
                                             id="outlined-multiline-flexible"
                                             label="Adres"
                                             multiline
                                             minRows={3}
                                             name="Adres"
                                             placeholder="Adres..."
                                             autoComplete="off"
                                             InputLabelProps={{style: {color: formik.errors.adres && formik.touched.adres ? '#e53e3e' : '#1976d2'}}}
                                             value={adres}
                                             onChange={handleAdress}
                                             onBlur={formik.handleBlur}
                                             InputProps={{
                                                 startAdornment: <InputAdornment position="start" ><FaRegAddressBook /></InputAdornment>,
                                             }}
                                             error={formik.errors.adres && formik.touched.adres}
                                             helperText={formik.errors.adres && formik.touched.adres && <span className='cstm-input-error-validation-msg'>{formik.errors.adres}</span>}
                                         />     
                                     </FormControl>       
                                 </div>
                             </div>
                         </div>
                     </div>
                     {/* other information area */}
                     <div className="cstm-border-top card p-3 mb-3">
                         <h4 className="card-form-panel-title">DiĞER BİLGİLER</h4>
                         <div className="form-group">
                             <div className='row mt-3'>
                                 <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                     <FormControl fullWidth>
                                         <Autocomplete
                                             options={tarifeler}
                                             getOptionLabel={option => option.tarifeAdi}
                                             loading={true}
                                             noOptionsText={'Seçenek Bulunamadı'}
                                             value={tarife}
                                             name="tarife_id"
                                             label="Tarife"
                                             onChange={(event, newValue) => { 
                                                 setTarife(newValue);
                                                 formik.setFieldValue('tarife_id', newValue?.id, true); //update formik value and validate true
                                             }}
                                             inputValue={tarifeInputValue}
                                             isOptionEqualToValue={(option, value) => option.id === value.id}
                                             onInputChange={(event, newInputValue) => { 
                                                 setTarifeInputValue(newInputValue);
                                             }}
                                             size="small"
                                             renderOption={(props, option) => {
                                                 return (
                                                   <li {...props} key={option.id}>
                                                     {option.tarifeAdi}
                                                   </li>
                                                 );
                                             }}
                                             renderInput={(params) => <TextField 
                                                                         {...params}
                                                                         label="Tarife" 
                                                                         error={formik.errors.tarife_id && formik.touched.tarife_id}
                                                                         helperText={formik.errors.tarife_id && formik.touched.tarife_id && <span className='cstm-input-error-validation-msg'>{formik.errors.tarife_id}</span>}
                                                                     />}  
                                         />
                                     </FormControl>
                                 </div>
                                 <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                     <FormControl fullWidth>
                                         <TextField
                                             name='tedavi_durumu'
                                             select
                                             InputLabelProps={{style: {color: '#1976d2'}}}
                                             label="Tedavi Durumu"
                                             size='small'
                                             defaultValue={formik.values.tedavi_durumu}
                                             value={formik.values.tedavi_durumu}
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             InputProps={{
                                                 startAdornment: <InputAdornment position="start"><FaSignal /></InputAdornment>,
                                             }}>
                                             <MenuItem value={0}>Seçiniz</MenuItem>
                                             <MenuItem value={1}>Tedaviye Başlandı</MenuItem>
                                             <MenuItem value={2}>Tedavi Devam Ediyor</MenuItem>
                                             <MenuItem value={3}>Tedavi Yarım Kaldı</MenuItem>
                                             <MenuItem value={4}>Tedavi Tamamlandı</MenuItem>
                                         </TextField>
                                     </FormControl>
                                 </div>
 
                                 <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                         <FormControl fullWidth>
                                             <TextField className='form-group'
                                                 type="text"
                                                 name="referans_adi"
                                                 variant='outlined'
                                                 label="Referans Adı"
                                                 autoComplete="off"
                                                 InputLabelProps={{style: {color: formik.errors.referans_adi && formik.touched.referans_adi ? '#e53e3e' : '#1976d2'}}}
                                                 onChange={formik.handleChange}
                                                 value={formik.values.referans_adi}
                                                 onBlur={formik.handleBlur}
                                                 id="outlined-start-adornment"
                                                 size='small'
                                                 InputProps={{
                                                     startAdornment: <InputAdornment position="start" ><FaShareAlt /></InputAdornment>,
                                                 }}
                                                 error={formik.errors.referans_adi && formik.touched.referans_adi}
                                                 helperText={formik.errors.referans_adi && formik.touched.referans_adi && <span className='cstm-input-error-validation-msg'>{formik.errors.referans_adi}</span>}
                                             />
                                         </FormControl>
                                     </div>
 
                                     <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                     <FormControl fullWidth>
                                         <TextField
                                             name='kan_grubu'
                                             select
                                             InputLabelProps={{style: {color: '#1976d2'}}}
                                             label="Kan Grubu"
                                             size='small'
                                             defaultValue={formik.values.kan_grubu}
                                             value={formik.values.kan_grubu}
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             InputProps={{
                                                 startAdornment: <InputAdornment position="start"><FaHeartbeat /></InputAdornment>,
                                             }}>
                                             <MenuItem value={0}>Seçiniz</MenuItem>
                                             <MenuItem value={1}>0 Rh -</MenuItem>
                                             <MenuItem value={2}>0 Rh +</MenuItem>
                                             <MenuItem value={3}>A Rh -</MenuItem>
                                             <MenuItem value={4}>A Rh +</MenuItem>
                                             <MenuItem value={5}>B Rh -</MenuItem>
                                             <MenuItem value={6}>B Rh +</MenuItem>
                                             <MenuItem value={7}>AB Rh -</MenuItem>
                                             <MenuItem value={8}>AB Rh +</MenuItem>
                                         </TextField>
                                     </FormControl>
                                 </div>
                                 <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                         <FormControl fullWidth>
                                             <TextField className='form-group'
                                                 type="text"
                                                 name="kurum_sicil_no"
                                                 variant='outlined'
                                                 label="Kurum Sicil No"
                                                 autoComplete="off"
                                                 InputLabelProps={{style: {color: formik.errors.kurum_sicil_no && formik.touched.kurum_sicil_no ? '#e53e3e' : '#1976d2'}}}
                                                 onChange={formik.handleChange}
                                                 value={formik.values.kurum_sicil_no}
                                                 onBlur={formik.handleBlur}
                                                 id="outlined-start-adornment"
                                                 size='small'
                                                 InputProps={{
                                                     startAdornment: <InputAdornment position="start" ><FaCopyright /></InputAdornment>,
                                                 }}
                                                 error={formik.errors.kurum_sicil_no && formik.touched.kurum_sicil_no}
                                                 helperText={formik.errors.kurum_sicil_no && formik.touched.kurum_sicil_no && <span className='cstm-input-error-validation-msg'>{formik.errors.kurum_sicil_no}</span>}
                                             />
                                         </FormControl>
                                     </div>
                                     <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'>
                                     <FormControl fullWidth>
                                         <TextField
                                             name='kontrol_periyodu'
                                             select
                                             InputLabelProps={{style: {color: '#1976d2'}}}
                                             label="Konrol Periyodu"
                                             size='small'
                                             defaultValue={formik.values.kontrol_periyodu}
                                             value={formik.values.kontrol_periyodu}
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             InputProps={{
                                                 startAdornment: <InputAdornment position="start"><FaWaveSquare /></InputAdornment>,
                                             }}>
                                             <MenuItem value={0} selected>Seçiniz</MenuItem>
                                             <MenuItem value={1}>1 Ay</MenuItem>
                                             <MenuItem value={2}>2 Ay</MenuItem>
                                             <MenuItem value={3}>3 Ay</MenuItem>
                                             <MenuItem value={4}>4 Ay</MenuItem>
                                             <MenuItem value={5}>5 Ay</MenuItem>
                                             <MenuItem value={6}>6 Ay</MenuItem>
                                             <MenuItem value={7}>12 Ay</MenuItem>
                                             <MenuItem value={8}>18 Ay</MenuItem>
                                             <MenuItem value={9}>24 Ay</MenuItem>
                                             <MenuItem value={10}>36 Ay</MenuItem>
                                         </TextField>
                                     </FormControl>
                                 </div>
                             </div>
                         </div>
                         <div className='d-flex justify-content-end'>
                             <button  type="submit" style={{ background: "#1172b6" }} className='btn btn-primary border-none outline-none'>
                                 {
                                     isProcesssing ? 
                                     (
                                         <>
                                             <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                             İşleniyor...
                                         </>
                                     )
                                     :
                                     (
                                         <>
                                             <FaCloudUploadAlt /> &nbsp;
                                             {"Kaydet"}
                                         </>
                                     )
                                 }
                             </button>
                         </div>
                     </div>
                 </Card>
             </form>
         </div>
         :<NotPermission/>}
        </>
    )
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(CreatePatient);



