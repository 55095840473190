import React, { Fragment } from 'react';
import bg from 'assets/images/logo/logo.png';
import { createGlobalStyle } from 'styled-components';
import GetTrDate from 'extensions/GetTRDate';

const GlobalStyles = createGlobalStyle`
    @media all {
        .page-break {
            display: none;
        }
    }

    @media print {
        @page {
            size: 148mm 210mm;
            margin: 15mm 15mm;
        }

        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }

        .page-break {
            margin-top: 1rem;
            display: block;
            page-break-before: auto;
        }
    }

    html, body {
        height: 100vh; /* Use 100% here to support printing more than a single page*/
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden!important;
        background-image: url(${bg})!important;
        background-repeat: no-repeat!important;
        background-size: auto 60px!important;
        background-position: top center!important;
        width:100%!important;
    }

    .p-p-detail-print-container{
        width:100%!important;
        height: 100%!important;
        position: relative!important;
        float:left!important;
        padding-top: 60px!important;
        padding-bottom:60px!important;
    }

    .p-p-detail-print-header{
        color: #1172b6;
        text-align:center;
        margin-bottom: 30px!important;
        margin-top: 40px!important;
        font-size: 18px!important;
    }

    .p-p-detail-print-u-info{
        width:100%;
        height: auto;
        position: relative;
        float:left;
    }

    .p-p-detail-print-u-info > div{
        width:100%;
        height:auto;
        position: relative;
        float:left;
        display: flex;
        justify-content: flex-end;
    }

    .p-p-detail-print-u-info > div strong{
        width: 100px!important;
        height: auto;
        position: relative;
        float: right;
        text-align: right;
        color: #467ea4;
        font-size:13px!important;
        display:inline-block!important;
    }

    .p-p-detail-print-u-info > div span{
        width: 350px!important;
        height: auto;
        position: relative;
        float: left;
        text-align: left;
        color: #000;
        font-size:13px!important;
        display:inline-block!important;
        padding-left:10px;
        opacity: 0.9;
    }

    .p-p-detail-print-table{
        width:100%!important;
        height:auto!important;
        position:relative;
        float:left;
        border: 1px solid black;
        border-collapse: collapse;
        margin-top: 30px;
    }

    .p-p-detail-print-table,
    .p-p-detail-print-table tr,
    .p-p-detail-print-table td,
    .p-p-detail-print-table th{
        border: 1px solid #eee; 
    }

    .p-p-detail-print-table th{
        text-align: center;
        font-size: 12px!important;
    }

    .p-p-detail-print-table td{
        text-align: center;
        font-size: 12px!important;
    }

    .p-p-detail-print-line{
        width:100%;
        height: 3px;
        position: relative;
        float:left;
        background-color: #1172b6;
        opacity: 0.7;
        margin-top: 20px;
    }

    .p-p-detail-print-table-h{
        width:100%;
        height: auto;
        position: relative;
        float:left;
        color: #1172b6;
        text-align:center;
        margin-top: 20px!important;
        font-size: 16px!important;
    }

    .p-p-detail-print-contact-info {
        width:100%;
        height: auto;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        text-align:center;
        color: #1172b6;
        font-size:12px;
    }
`;

export default class DetailExportPDF extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      return (
        <Fragment>
            <GlobalStyles />
            <div className="p-p-detail-print-container">
                <h4 className='p-p-detail-print-header'>REÇETE</h4>
                <div className='p-p-detail-print-u-info'>
                    <div>
                        <strong>Ad Soyad: </strong> 
                        <span>{this.props?.prescriptionInfo?.hasta?.hasta}</span>
                    </div>
                    <div>
                        <strong>Protokol No: </strong> 
                        <span>{this.props?.prescriptionInfo?.protokolNo}</span>
                    </div>
                    <div>
                        <strong>Tarih: </strong>
                        <span>{GetTrDate(this.props?.prescriptionInfo?.receteTarihi)}</span>
                    </div>
                </div>
                <div className='p-p-detail-print-line'>

                </div>

                <div className='p-p-detail-print-table-h'>İlaç Listesi</div>
                <table className='p-p-detail-print-table'>
                    <thead>
                        <tr>
                            <th>Sıra</th>
                            <th>İlaç Adı</th>
                            <th>Doz</th>
                            <th>Kullanım</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.medicinesInfo.map((medicine, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{medicine?.ilac_adi}</td>
                                        <td>{medicine?.doz}</td>
                                        <td>{medicine?.kullanim}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <div className='p-p-detail-print-contact-info'>
                    <span>
                        <strong>Adres: </strong>
                        {this.props?.contactInfo?.adres}
                    </span>
                    <div className='d-flex justify-content-around'>
                        <span><strong>Tel: </strong>{this.props?.contactInfo?.telefon}</span>
                        <span><strong>Web: </strong>{this.props?.contactInfo?.web_site}</span>
                    </div>
                </div>
            </div>
        </Fragment>
      );
    }
  }