import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import { Button as ButtonM, Tooltip } from "@mui/material";
import "assets/css/pages/laboratory.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { TabPanel } from "@mui/lab";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { FaCheck } from "react-icons/fa";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const ComingJobs = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const columns = [
        {
            field: "id",
            headerName: "Sıra",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            align:"center",
            hideable: true,
            editable: false,
            headerAlign: "center",
            // cellClassName: "lab-job-blink_me"
            cellClassName: (params) => {
                return params?.row?.durum ? "" : "lab-job-blink_me text text-warning";
            }
            
        },
        {
            field: "laboratuvar",
            headerName: "Laboratuvar",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            cellClassName: (params) => {
                return params?.row?.durum ? "" : "lab-job-blink_me text text-warning";
            },
            renderCell: (params) => {
                return (
                    params?.row?.laboratuvar?.lab_adi ? params.row.laboratuvar.lab_adi : ""
                )
            },
            valueGetter: (params) => `${params?.row?.laboratuvar?.lab_adi}`
        },
        {
            field: "hasta",
            headerName: "Hasta",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            cellClassName: (params) => {
                return params?.row?.durum ? "" : "lab-job-blink_me text text-warning";
            },
            renderCell: (params) => {
                return (
                    params?.row?.hasta?.hasta ? params.row.hasta.hasta : ""
                )
            },
            valueGetter: (params) => `${params?.row?.hasta?.hasta}`
        },
        {
            field: "doktor",
            headerName: "Doktor",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            cellClassName: (params) => {
                return params?.row?.durum ? "" : "lab-job-blink_me text text-warning";
            },
            renderCell: (params) => {
                return (
                    params?.row?.doktor?.name ? params.row.doktor.name : ""
                )
            },
            valueGetter: (params) => `${params?.row?.doktor?.name}`
        },
        {
            field: "islem",
            headerName: "İşlem",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            cellClassName: (params) => {
                return params?.row?.durum ? "" : "lab-job-blink_me text text-warning";
            },
            renderCell: (params) => {
                return (
                    params?.row?.islem?.islem_adi ? params.row.islem.islem_adi : ""
                )
            },
            valueGetter: (params) => `${params?.row?.islem?.islem_adi}`
        },
        {
            field: "gelis_tarihi",
            headerName: "Tarih",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            cellClassName: (params) => {
                return params?.row?.durum ? "" : "lab-job-blink_me text text-warning";
            },
            renderCell: (params) => {
                return (
                    params?.row?.gelis_tarihi ? GetTrFullDateAndTimeWithFormat(params.row.gelis_tarihi) : "" 
                )
            },
            valueGetter: (params) => GetTrFullDateAndTimeWithFormat(params.row.gelis_tarihi)
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 250,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const acceptJob = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.hasta?.hasta + " adlı hastanın " + params?.row?.islem?.islem_adi + " işlemi geldi olarak işaretlensin mi?",
                        text: "işlemi gerçekleştirdiğinizde geri alamazsınız.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, İşaretle',
                        cancelButtonText: 'Hayır,Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.UpdateAsync(`/laboratuvar/${params.row.id}`, {durum: 1})
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });
                        }
                    })
                };

                if(!params?.row?.durum){
                    return (
                        <Stack direction="row" spacing={2}>
                            <Tooltip title="gelen iş olarak işaretle" arrow>
                                <ButtonM variant="outlined" onClick={acceptJob} color="success"><FaCheck /></ButtonM>
                            </Tooltip>
                        </Stack>
                    );
                }

            },
        }
    ];

    // data-grid state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    // side effect
    useEffect(() => {
        axios.get("laboratuvar", {
            params: {
                filterModel: JSON.stringify(filterModel),
                pageSize: pageSize,
                page: page,
                sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    setData(res.data.data);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, [sortModel, filterModel, pageSize, page, updateDataGrid]);


    return (
        <>
         {can('view-laboratuvar') ? 
            <TabPanel value="2" className="definitions-container">
                <ThemeProvider theme={theme}>
                            <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                                <DataGrid
                                    autoHeight={data.length > 0 ? true : false}
                                    sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                    loading={isLoading}
                                    rows={data}
                                    columns={columns}
                                    // pagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowCount={totalCount} //serverdan gelen data total count gelecek
                                    page={page}
                                    onPageChange={(newPage) => setPage(newPage)}
                                    pagination
                                    paginationMode="server"
                                    // sorting
                                    sortModel={sortModel}
                                    sortingMode="server"
                                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                    // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                    filterModel={filterModel}
                                    filterMode="server"
                                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                    // selection checkbox
                                    //checkboxSelection={true}
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                    }}
                                    // features
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                    // toolbar components
                                    components={{
                                        Toolbar: GridToolbar,
                                        NoRowsOverlay: CustomNoRowsOverlay,
                                        LoadingOverlay:LoadingOverlay
                                    }}
                                    disableColumnFilter
                                    // component props
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            printOptions: {
                                                fields: ["id", "ilac_adi", "doz", "kullanim"],
                                                fileName: "İlaclar",
                                                hideFooter: true,
                                                hideToolbar: true,
                                                disableToolbarButton: true,
                                            }, //sadece firsname  ve lastname alanını alır.
                                            csvOptions: {
                                                disableToolbarButton: false,
                                                fields: ["laboratuvar", "hasta", "doktor","islem","gelis_tarihi"],
                                                fileName: 'Gelen_Isler',
                                                utf8WithBom: true,
                                            }, //sadece firstname ve lastName alanını alır.
                                            excelOptions: { disableToolbarButton: true },
                                            // quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                />
                            </Box>
                        </ThemeProvider>


            </TabPanel>
         :<NotPermission/>}
        </>
    );
};


const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(ComingJobs);
