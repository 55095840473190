import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import "assets/css/pages/patients.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import {
  FaPlus, FaEdit, FaTrash, FaSearch, FaList, FaArrowsAltV, FaTimes, FaExclamation, FaMinus, FaBoxes, FaSyncAlt,
  FaHeading, FaCloudUploadAlt, FaSortAmountUp, FaLiraSign, FaFileInvoice, FaMapMarkerAlt, FaStickyNote
} from 'react-icons/fa';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import GetTrDate from "extensions/GetTRDate";
import { useFormik } from 'formik';
import { InputAdornment, FormControl, TextField, MenuItem, Autocomplete, Button, Alert } from '@mui/material';
import { Button as ButtonM, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CreateStockProcessValidationSchema from 'business-layer/CreateStockProcessValidationSchema';
import NumberMask from 'Utils/NumberMask';
import CurrencyMask from 'Utils/CurrencyMask';
import { tr } from 'date-fns/locale';
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

function MovementsStock(props) {
  const { auth } = props;
  const can = (permission) => {
    return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
  }
  const location = useLocation();
  const navigate = useNavigate()
  const { state } = location;

  const MySwal = withReactContent(Swal)

  const theme = createTheme(
    trTR, // x-data-grid translations
    turkishTr // core translations
  );


  const columns = [

    {
      field: "tip",
      headerName: "Türü",
      align: "center",
      flex: 1,
      center: "center",
      headerAlign: "center",
      minWidth: 100,
      maxWidth: 150,
      hideable: true,
      editable: false,
      renderCell: (params) => {
        return params?.row?.tip === 1 ? <Button variant="outlined" color="success"> Giriş </Button> : <Button variant="outlined" color="error"> Çıkış </Button>;
      }
    },
    {
      field: "miktar",
      headerName: "Miktar",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      editable: false,
      hideable: false, // cant hide this column
      sortable: true,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
      renderCell: (params) => {
        return params?.row?.tip === 1 ? <span style={{ color: "green" }}>{'+' + params?.row?.miktar}</span> : <span style={{ color: "red" }}>{'-' + params?.row?.miktar}</span>;
      }
    },
    {
      field: "tarih",
      headerName: "Tarih",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      editable: false,
      hideable: false, // cant hide this column
      sortable: false,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
      renderCell: (params) => {
        return GetTrDate(params.row.tarih)
      }
    },
    {
      field: "birim_fiyat",
      headerName: "Birim Fiyatı",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      editable: false,
      hideable: true, // cant hide this column
      sortable: true,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
      renderCell: (params) => {
        return parseInt(params?.row?.birim_fiyat) !== 0 ? params?.row?.birim_fiyat : <FaTimes color="#de3f44" />
      }
    },
    {
      field: "temin_yeri",
      headerName: "Temin Yeri",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      editable: false,
      hideable: false, // cant hide this column
      sortable: false,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
      renderCell: (params) => {
        return (
          params?.row?.temin_yeri ? params?.row?.temin_yeri : <FaTimes color="#de3f44" />
        )
      }
    },
    {
      field: "kullanan_personel",
      headerName: "Talep Eden",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      editable: false,
      hideable: false, // cant hide this column
      sortable: false,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
      renderCell: (params) => {
        return (
          params?.row?.kullanan_personel !== null ? params?.row?.kullanan_personel.name : <FaTimes color="#de3f44" />
        )
      }
    },
    {
      field: "actions",
      headerName: "İşlemler",
      minWidth: 250,
      headerAlign: "center",
      flex: 1,
      align: "center",
      editable: false,
      disableExport: true,
      hideable: false,
      sortable: false,
      hide: false,
      filterable: false,
      //disableClickEventBubbling: true,
      renderCell: (params) => {
        const deleteStockMovement = (e) => {
          e.preventDefault();
          e.stopPropagation();
          MySwal.fire({
            title: 'Emin misin?',
            text: "Bunu geri alamazsınız!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Sil',
            cancelButtonText: 'Vazgeç'
          }).then(async (result) => {
            if (result.isConfirmed) {
              AxiosCrudActions.DeleteAsync('stok-hareketleri', params.row.id)
                .then(result => {
                  if (result?.status === 200 && result?.data?.status) {
                    setUpdateDataGrid(!updateDataGrid);
                  }
                });
            }
          })
        };
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="detay" arrow>
              <Button variant="outlined" onClick={(e) => editStockProcess(e, params?.row)} disabled={!can('edit-stok-detay')} color="warning"><FaEdit /></Button>
            </Tooltip>
            <Tooltip title="stok sil" arrow>
              <Button variant="outlined" onClick={deleteStockMovement} disabled={!can('delete-stok-detay')} color="error"><FaTrash /></Button>
            </Tooltip>
          </Stack>
        );
      }
    }
  ];

  // state
  const [updateDataGrid, setUpdateDataGrid] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [modalPlus, setModalPlus] = useState(false);
  const [modalMinus, setModalMinus] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [selectionModel, setSelectionModel] = useState([]); //selected row
  const [date, setDate] = useState(new Date());
  const [stockType, setStockType] = useState();
  const [processType, setProcessType] = useState();
  const [mevcutStock, setMevcutStock] = useState("");
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(0);

  const [isProcesssing, setIsProcessing] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);

  const togglePlus = () => setModalPlus(!modalPlus);
  const toggleMinus = () => setModalMinus(!modalMinus);


  const getDate = (date) => {
    if (date !== undefined && date !== null) {
      try {
        let getJustDate = dayjs(date).get('year') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('date');
        return getJustDate;
      } catch (error) {
        return null;
      }
    }
  }


  const initialStockInfo = {
    miktar: '',
    birim_fiyat: '',
    tarih: '',
    fatura_no: '',
    aciklama: '',
    temin_yeri: '',
    user_id: '',
  }

  // yup schema
  let stockProcessValidationSchema = CreateStockProcessValidationSchema();
  // formik schema
  const formik = useFormik({
    initialValues: initialStockInfo,
    validationSchema: stockProcessValidationSchema,
    onSubmit: values => {
      values.tarih = getDate(date);
      if (stockType === 0) {
        values.user_id = userId?.id;
      }
      values.tip = stockType;
      setIsProcessing(true);
      if (processType === 1) {
        values.stok_id = state?.data?.id;
        AxiosCrudActions.CreateAsync('stok-hareketleri', values).then(result => {
          setIsProcessing(false);
          if (result?.data?.status) {
            setUpdateDataGrid(!updateDataGrid);
            setModalPlus(false);
            setModalMinus(false);
          }
        });
      } else {
        AxiosCrudActions.UpdateAsync(`stok-hareketleri/${values?.id}`, values).then(result => {
          setIsProcessing(false);
          if (result?.data?.status) {
            setUpdateDataGrid(!updateDataGrid);
            setModalPlus(false);
            setModalMinus(false);
          }
        });
      }

    },
  });


  const stockProcessPlus = (event) => {
    event.preventDefault();
    setStockType(1);
    setProcessType(1);
    formik.setValues({
      miktar: '',
      birim_fiyat: '',
      tarih: '',
      fatura_no: '',
      aciklama: '',
      temin_yeri: '',
    }, false)
    setUserId(null);
    setDate(new Date());
    formik.setErrors({});
    togglePlus();
  }


  const stockProcessMinus = (event) => {
    event.preventDefault();
    setStockType(0);
    setProcessType(1);
    formik.setValues({
      miktar: '',
      tarih: '',
      aciklama: ''
    }, false)
    setUserId(null);
    setDate(new Date());
    formik.setErrors({});
    toggleMinus();
  }

  const editStockProcess = (event, values) => {
    event.preventDefault();
    setProcessType(0);
    if (values?.tip === 1) {
      togglePlus();
      setStockType(1);
      formik.setValues({
        miktar: values?.miktar ? values?.miktar : '',
        birim_fiyat: values?.birim_fiyat ? values?.birim_fiyat : '',
        tarih: values?.tarih ? values?.tarih : new Date(),
        fatura_no: values?.fatura_no ? values?.fatura_no : '',
        temin_yeri: values?.temin_yeri ? values?.temin_yeri : '',
        aciklama: values?.aciklama ? values?.aciklama : '',
        tip: values?.tip ? values?.tip : '',
        id: values?.id,
      }, false);
      setDate(values?.tarih ? values?.tarih : new Date());
      formik.setErrors({});
      setUserId(null);
    } else {
      toggleMinus();
      setStockType(0);
      formik.setValues({
        miktar: values?.miktar ? values?.miktar : '',
        birim_fiyat: '',
        tarih: values?.tarih ? values?.tarih : new Date(),
        fatura_no: '',
        temin_yeri: '',
        aciklama: values?.aciklama ? values?.aciklama : '',
        tip: values?.tip ? values?.tip : '',
        id: values?.id,
      }, false);
      setDate(values?.tarih ? values?.tarih : new Date());
      setUserId(values?.kullanan_personel);
      formik.setErrors({});
    }

  }



  const getUsers = () => {
    try {
      axios.get('/kullanicilar?doktor=1')
        .then(res => {
          let result = res.data.data;
          let allUsers = (result).map(({ id, name }) => { return { 'id': id, 'name': name } });
          setUsers(allUsers);
        })
    } catch (error) {
      setUsers([]);
    }
  }


  const handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  // side effect
  useEffect(() => {
    getUsers();
  }, []);
  // side effect
  useEffect(() => {
    axios.get(`stok-hareketleri/${state?.data?.id}`, {
      params: {
        filterModel: JSON.stringify(filterModel),
        pageSize: pageSize,
        page: page,
        sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
        sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
      }
    }).then((res) => {
      setIsLoading(false);
      try {
        if (res.data.data !== undefined && res.data.data !== null) {
          setTotalCount(res.data.totalCount);
          setData(res.data.data);
          setMevcutStock(res?.data?.mevcut);
        }
      } catch (error) {
        setData([]);
      }
    });
  }, [sortModel, filterModel, pageSize, page, updateDataGrid]);



  return (
    <div className="patients-container">
      {/* {
        isProcesssing ? <div style={{ width: '100%', height: '100vh', position: 'fixed', top: '0', left: '0', bottom: '0', right: '0', overflow: "hidden", zIndex: 99999, background: 'transparent' }}></div> : null
      } */}
      <div className="d-flex justify-content-between align-items-center">

        {
          can('view-stok-detay') ?
            <>
              <span className="d-flex align-items-center">
                <strong className="fs-5 fw-normal text text-secondary"><span style={{ color: "#9C27B0" }}>{state?.data?.stok_adi}</span> Stok Hareketleri &nbsp;</strong>
                <span style={{ color: mevcutStock < 0 ? "#f4511e" : "#7cb342" }}>
                  [ Mevcut Stok : {mevcutStock === "" ? <FaSyncAlt /> : mevcutStock} ]
                </span>
              </span>
              <span>

                <Button color="error" variant="outlined" disabled={!can('create-stok-detay')} startIcon={<FaMinus />} onClick={(e) => { stockProcessMinus(e) }}>
                  Stok Çıkışı
                </Button>
                &nbsp;
                <Button color="success" variant="outlined" disabled={!can('create-stok-detay')} startIcon={<FaPlus />} onClick={(e) => { stockProcessPlus(e) }}>
                  Stok Girişi
                </Button>
                &nbsp;
                <Button color="info" variant="outlined" startIcon={<FaBoxes />} onClick={() => { navigate('/stoklar') }}>
                  Stoklar
                </Button>
              </span>
            </>
            : ''
        }

      </div>


      <Modal isOpen={modalPlus}
        toggle={togglePlus}
        size="lg"
        modalTransition={{ timeout: 1 }}
        backdropTransition={{ timeout: 1 }}
      >
        <ModalHeader toggle={togglePlus} style={{ color: "#1172b6", fontWeight: 200 }}>Stok Girişi {processType === 0 ? 'Güncelleme' : ''}</ModalHeader>
        <form onSubmit={formik.handleSubmit}>
          <ModalBody >
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="number"
                    name="miktar"
                    variant='outlined'
                    label="Miktar"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: formik.errors.miktar && formik.touched.miktar ? '#e53e3e' : '#1976d2' } }}
                    onChange={formik.handleChange}
                    value={formik.values.miktar}
                    onBlur={formik.handleBlur}
                    size='small'
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaSortAmountUp /></InputAdornment>,
                    }}
                    error={formik.errors.miktar && formik.touched.miktar}
                    helperText={formik.errors.miktar && formik.touched.miktar && <span className='cstm-input-error-validation-msg'>{formik.errors.miktar}</span>}
                  />
                </FormControl>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    disabled
                    style={{ backgroundColor: "#f5f5f5" }}
                    value={state?.data?.birim?.name}
                    size='small'
                  />
                </FormControl>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="text"
                    name="birim_fiyat"
                    variant='outlined'
                    label="Birim Fiyat"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: formik.errors.birim_fiyat && formik.touched.birim_fiyat ? '#e53e3e' : '#1976d2' } }}
                    onChange={formik.handleChange}
                    value={formik.values.birim_fiyat}
                    onBlur={formik.handleBlur}
                    size='small'
                    InputProps={{
                      inputComponent: CurrencyMask,
                      startAdornment: <InputAdornment position="start" ><FaLiraSign /></InputAdornment>,
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker
                      variant='outlined'
                      label="Tarihi"
                      inputFormat="dd-MM-yyyy"
                      minDate={dayjs('01-01-1930')}
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params}
                        size="small"
                        name="tarih"
                        variant='outlined'
                      />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="text"
                    name="fatura_no"
                    variant='outlined'
                    label="Fatura No"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: formik.errors.fatura_no && formik.touched.fatura_no ? '#e53e3e' : '#1976d2' } }}
                    onChange={formik.handleChange}
                    onKeyDown={handleKeyDown}
                    value={formik.values.fatura_no}
                    onBlur={formik.handleBlur}
                    size='small'
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaFileInvoice /></InputAdornment>,
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="text"
                    name="temin_yeri"
                    variant='outlined'
                    label="Temin Yeri"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: formik.errors.temin_yeri && formik.touched.temin_yeri ? '#e53e3e' : '#1976d2' } }}
                    onChange={formik.handleChange}
                    value={formik.values.temin_yeri}
                    onBlur={formik.handleBlur}
                    size='small'
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaMapMarkerAlt /></InputAdornment>,
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    id="outlined-multiline-flexible"
                    label="Açıklama"
                    multiline
                    minRows={2}
                    name="aciklama"
                    placeholder="Açıklama..."
                    autoComplete="off"
                    InputLabelProps={{ style: { color: formik.errors.aciklama && formik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                    value={formik.values.aciklama}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaStickyNote /></InputAdornment>,
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" type="submit" style={{ background: can('create-stok-detay') ? "#1172b6" : "#65A4D0", color: "white" }} disabled={!can('create-stok-detay')} className="d-flex align-items-center" >
              {
                isProcesssing ?
                  (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                      İşleniyor...
                    </>
                  )
                  :
                  (
                    <>
                      <FaCloudUploadAlt /> &nbsp;
                      {processType === 1 ? "Kaydet" : "Güncelle"}
                    </>
                  )
              }
            </Button>
            <ButtonM color="danger" type="button" onClick={togglePlus} className="d-flex align-items-center">
              <FaTimes />&nbsp;{'Vazgeç'}
            </ButtonM>
          </ModalFooter>
        </form>
      </Modal>

      <Modal isOpen={modalMinus}
        toggle={toggleMinus}
        size="lg"
        modalTransition={{ timeout: 1 }}
        backdropTransition={{ timeout: 1 }}
      >
        <ModalHeader toggle={toggleMinus} style={{ color: "#1172b6", fontWeight: 200 }}>Stok Çıkışı {processType === 0 ? 'Güncelleme' : ''}</ModalHeader>
        <form onSubmit={formik.handleSubmit}>
          <ModalBody >
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="number"
                    name="miktar"
                    variant='outlined'
                    label="Miktar"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: formik.errors.miktar && formik.touched.miktar ? '#e53e3e' : '#1976d2' } }}
                    onChange={formik.handleChange}
                    value={formik.values.miktar}
                    onBlur={formik.handleBlur}
                    size='small'
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaSortAmountUp /></InputAdornment>,
                    }}
                    error={formik.errors.miktar && formik.touched.miktar}
                    helperText={formik.errors.miktar && formik.touched.miktar && <span className='cstm-input-error-validation-msg'>{formik.errors.miktar}</span>}
                  />
                </FormControl>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    disabled
                    style={{ backgroundColor: "#f5f5f5" }}
                    value={state?.data?.birim?.name}
                    size='small'
                  />
                </FormControl>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker
                      variant='outlined'
                      label="Tarihi"
                      inputFormat="dd-MM-yyyy"
                      minDate={dayjs('01-01-1930')}
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params}
                        size="small"
                        name="tarih"
                        variant='outlined'
                      />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <Autocomplete
                    options={users}
                    getOptionLabel={option => option.name}
                    loading={true}
                    noOptionsText={'Seçenek Bulunamadı'}
                    value={userId}
                    name="user_id"
                    onChange={(event, newValue) => {
                      setUserId(newValue);
                      formik.setFieldValue('user_id', newValue.id, true); //update formik value and validate true
                    }}
                    size="small"
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField
                      {...params}
                      label="Talep Eden"
                      error={formik.errors.user_id && formik.touched.user_id}
                      helperText={formik.errors.user_id && formik.touched.user_id && <span className='cstm-input-error-validation-msg'>{formik.errors.user_id}</span>}
                    />}

                  />
                </FormControl>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    id="outlined-multiline-flexible"
                    label="Açıklama"
                    multiline
                    minRows={2}
                    name="aciklama"
                    placeholder="Açıklama..."
                    autoComplete="off"
                    InputLabelProps={{ style: { color: formik.errors.aciklama && formik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                    value={formik.values.aciklama}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaStickyNote /></InputAdornment>,
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" type="submit" style={{ background: can('create-stok-detay') ? "#1172b6" : "#65A4D0", color: "white" }} disabled={!can('create-stok-detay')} className="d-flex align-items-center" >
              {
                isProcesssing ?
                  (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                      İşleniyor...
                    </>
                  )
                  :
                  (
                    <>
                      <FaCloudUploadAlt /> &nbsp;
                      {processType === 1 ? "Kaydet" : "Güncelle"}
                    </>
                  )
              }
            </Button>
            <ButtonM color="danger" type="button" onClick={toggleMinus} className="d-flex align-items-center">
              <FaTimes />&nbsp;{'Vazgeç'}
            </ButtonM>
          </ModalFooter>
        </form>
      </Modal>


      {
        useMemo(() =>
          <>
            {can('view-stok-detay') ?
              <ThemeProvider theme={theme}>
                <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                  <DataGrid
                    autoHeight={data.length > 0 ? true : false}
                    sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                    loading={isLoading}
                    rows={data}
                    columns={columns}
                    // pagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowCount={totalCount} //serverdan gelen data total count gelecek
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    pagination
                    //paginationMode="server"
                    // sorting
                    //sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                    filterModel={filterModel}
                    filterMode="server"
                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                    // selection checkbox
                    //checkboxSelection={true}
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    // features
                    experimentalFeatures={{ newEditingApi: true }}
                    // toolbar components
                    components={{
                      Toolbar: GridToolbar,
                      NoRowsOverlay: CustomNoRowsOverlay,
                      LoadingOverlay: LoadingOverlay
                    }}
                    //getRowId={(row) => row?.id}
                    //component props
                    disableColumnFilter
                    disableSelectionOnClick

                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: {
                          fields: ["miktar", "tarih", "temin_yeri", "birim_fiyat"],
                          fileName: "Stok Hareketleri",
                          hideFooter: true,
                          hideToolbar: true,
                          disableToolbarButton: false,
                        }, //sadece firsname  ve lastname alanını alır.
                        csvOptions: {
                          disableToolbarButton: false,
                          fields: ["miktar", "tarih", "temin_yeri", "birim_fiyat"],
                          fileName: "Stok Hareketleri",
                          utf8WithBom: true,
                        }, //sadece firstname ve lastName alanını alır.
                        excelOptions: { disableToolbarButton: true },
                        // quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                  />
                </Box>
              </ThemeProvider>
              : <NotPermission />}
          </>
          , [sortModel, filterModel, pageSize, page, updateDataGrid, data]
        )
      }
    </div>
  )
}

const authPermissions = (state) => {
  return {
    auth: state.authState,
  };
};

export default connect(authPermissions)(MovementsStock);


