import * as yup from 'yup';

const CreateDiagnozValidationSchema = () => {
    return yup.object().shape({

        durum: yup.boolean(),

        tedavi_tanimi: yup.string()
            .min(2, "Diagnoz Tanımı en az 2 karakter olmalıdır.")
            .max(50, "Diagnoz Tanımı en fazla 50 karakter olmalıdır.")
            .required("Lütfen geçerli bir Diagnoz Tanımı bilgisi giriniz."),

        tedavi_kategori_id: yup.number()
            .min(1, "Lütfen Kategori seçiniz.")
            .required("Lütfen Kategori seçiniz."),


    });
}

export default CreateDiagnozValidationSchema;
