import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import { Button as ButtonM } from "@mui/material";
import "assets/css/pages/laboratory.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { TabPanel } from "@mui/lab";
import { FormControl, TextField, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaTimes,
    FaTrash,
} from "react-icons/fa";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import CreateGoingJobValidationSchema from "business-layer/CreateGoingJobValidationSchema";
// date 
import dayjs from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { tr } from 'date-fns/locale'
import ConvertGlobalDateTime from "extensions/ConvertGlobalDateTime";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";
// date end

const GoingJobs = (props) => {
    const { auth } = props;
    const can = (permission) => {
        return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
    }
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const columns = [
        {
            field: "id",
            headerName: "Sıra",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            align: "center",
            hideable: true,
            editable: false,
            headerAlign: "center"
        },
        {
            field: "laboratuvar",
            headerName: "Laboratuvar",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.laboratuvar?.lab_adi ? params.row.laboratuvar.lab_adi : ""
                )
            },
            valueGetter: (params) => `${params?.row?.laboratuvar?.lab_adi}`
        },
        {
            field: "hasta",
            headerName: "Hasta",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.hasta?.hasta ? params.row.hasta.hasta : ""
                )
            },
            valueGetter: (params) => `${params?.row?.hasta?.hasta}`
        },
        {
            field: "doktor",
            headerName: "Doktor",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.doktor?.name ? params.row.doktor.name : ""
                )
            },
            valueGetter: (params) => `${params?.row?.doktor?.name}`
        },
        {
            field: "islem",
            headerName: "İşlem",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.islem?.islem_adi ? params.row.islem.islem_adi : ""
                )
            },
            valueGetter: (params) => `${params?.row?.islem?.islem_adi}`
        },
        {
            field: "gelis_tarihi",
            headerName: "Tarih",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.gelis_tarihi ? GetTrFullDateAndTimeWithFormat(params.row.gelis_tarihi) : ""
                )
            },
            valueGetter: (params) => GetTrFullDateAndTimeWithFormat(params.row.gelis_tarihi)
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 250,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const deleteGoingJob = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.hasta?.hasta + " adlı hastanın " + params?.row?.islem?.islem_adi + " işlemi silinsin mi?",
                        text: "silme işlemini gerçekleştirdiğinizde geri alamazsınız.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, Sil',
                        cancelButtonText: 'Hayır,Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync('/laboratuvar', params.row.id)
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });

                        }
                    })
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="işi sil" arrow>
                            <ButtonM variant="outlined" disabled={!can('delete-laboratuvar')} onClick={deleteGoingJob} color="error"><FaTrash /></ButtonM>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];


    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [labValue, setLabValue] = useState(null);
    const [labInputValue, setLabInputValue] = useState('');
    const [patientValue, setPatientValue] = useState(null);
    const [patientInputValue, setPatientInputValue] = useState('');
    const [doktorValue, setDoktorValue] = useState(null);
    const [doktorInputValue, setDoktorInputValue] = useState('');
    const [processValue, setProcessValue] = useState(null);
    const [processInputValue, setProcessInputValue] = useState('');

    const [laboratories, setLaboratories] = useState([]);
    const [patients, setPatients] = useState([]);
    const [doktors, setDoktors] = useState([]);
    const [processes, setProcesses] = useState([]);
    const [isProcesssing, setIsProcessing] = useState(false);


    const initialGoingJobInfo = {
        laboratuvar_id: 0,
        hasta_id: 0,
        doktor_id: 0,
        islem_id: 0,
        gelis_tarihi: new Date()
    };

    // yup schema
    let GoingJobValidationSchema = CreateGoingJobValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialGoingJobInfo,
        validationSchema: GoingJobValidationSchema,
        onSubmit: values => {
            values.gelis_tarihi = ConvertGlobalDateTime(values?.gelis_tarihi);
            values.laboratuvar_id = labValue?.id;
            values.hasta_id = patientValue?.id;
            values.doktor_id = doktorValue?.id;
            values.islem_id = processValue?.id;
            setIsProcessing(true);
            AxiosCrudActions.CreateAsync('laboratuvar', values).then(result => {
                setIsProcessing(false);
                if (result?.data?.status) {
                    setUpdateDataGrid(!updateDataGrid);
                    toggle();
                }
            });
        },
    });

    const addNewGoingJob = (event) => {
        event.preventDefault();
        setLabValue(null);
        setPatientValue(null);
        setDoktorValue(null);
        setProcessValue(null);
        formik.setValues({
            laboratuvar_id: 0,
            hasta_id: 0,
            doktor_id: 0,
            islem_id: 0,
            gelis_tarihi: new Date()
        }, false)
        formik.setErrors({});
        toggle();
    }

    // declare function for get data from server 
    const getLaboratories = async () => {
        await axios.get('cariler', { params: { lab: 1 } })
            .then(res => {
                if (res?.status === 200) {
                    setLaboratories(res?.data?.data);
                }
            }).catch(e => {
                setLaboratories([]);
            })
    }

    const getPatients = async () => {
        await axios.get('hastalar', { params: { short: 1, allpatients: auth?.user?.user_permissions?.filter((f) => f === 'view-all-hastalar')?.length ? 1 : 0 } })
            .then(res => {
                console.log(res);
                if (res?.status === 200) {
                    setPatients(res?.data?.data);
                }
            }).catch(e => {
                setPatients([]);
            })
    }

    const getDoktors = async () => {
        await axios.get('kullanicilar', { params: { doktor: 1 } })
            .then(res => {
                if (res?.status === 200) {
                    setDoktors(res?.data?.data);
                }
            }).catch(e => {
                setDoktors([]);
            })
    }

    const getProcesses = async () => {
        await axios.get('laboratuvar-islem')
            .then(res => {
                if (res?.status === 200) {
                    setProcesses(res?.data?.data);
                }
            }).catch(e => {
                setProcesses([]);
            })
    }

    // assign value from server.
    useEffect(() => {
        getLaboratories();
        getPatients();
        getDoktors();
        getProcesses();
    }, [])

    // data-grid state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    // side effect
    useEffect(() => {
        axios.get("laboratuvar", {
            params: {
                filterModel: JSON.stringify(filterModel),
                pageSize: pageSize,
                page: page,
                sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    setData(res.data.data);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, [sortModel, filterModel, pageSize, page, updateDataGrid]);


    return (
        <TabPanel value="1" className="definitions-container">
            {
                isProcesssing ? <div style={{ width: '100%', height: '100vh', position: 'fixed', top: '0', left: '0', bottom: '0', right: '0', overflow: "hidden", zIndex: 99999, background: 'transparent' }}></div> : null
            }
            <div className="d-flex justify-content-end align-items-center">

                {can('view-laboratuvar') ?
                    <>

                        <ButtonM color="success" variant="outlined" disabled={!can('create-laboratuvar')} startIcon={<FaPlus />} onClick={addNewGoingJob}>
                            Yeni İş
                        </ButtonM>
                    </>
                    : ''}

                <Modal isOpen={modal}
                    toggle={toggle}
                    size="md"
                    modalTransition={{ timeout: 1 }}
                    backdropTransition={{ timeout: 1 }}
                >
                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Giden iş Bilgileri</ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="pb-3">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="laboratoryId"
                                            options={laboratories}
                                            loading={true}
                                            getOptionLabel={option => option?.lab_adi}
                                            noOptionsText={'Seçenek Bulunamadı'}
                                            value={labValue}
                                            label="Laboratuvar"
                                            disableClearable
                                            onChange={(event, newValue) => {
                                                event.preventDefault();
                                                setLabValue(newValue);
                                                formik.setFieldValue('laboratuvar_id', newValue.id, true);
                                            }}
                                            inputValue={labInputValue}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onInputChange={(event, newInputValue) => {
                                                setLabInputValue(newInputValue);
                                            }}
                                            size="small"
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.id}>
                                                        {option?.lab_adi}
                                                    </li>
                                                );
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                label="Laboratuvar"
                                                name="laboratuvar_id"
                                                onBlur={formik.handleBlur}
                                                error={formik.errors.laboratuvar_id && formik.touched.laboratuvar_id}
                                                helperText={formik.errors.laboratuvar_id && formik.touched.laboratuvar_id && <span className='cstm-input-error-validation-msg'>{formik.errors.laboratuvar_id}</span>}
                                            />
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div className="pb-3">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="patientId"
                                            options={patients}
                                            loading={true}
                                            getOptionLabel={option => option?.hasta}
                                            noOptionsText={'Seçenek Bulunamadı'}
                                            value={patientValue}
                                            label="Hasta"
                                            disableClearable
                                            onChange={(event, newValue) => {
                                                event.preventDefault();
                                                setPatientValue(newValue);
                                                formik.setFieldValue('hasta_id', newValue.id, true);
                                            }}
                                            inputValue={patientInputValue}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onInputChange={(event, newInputValue) => { setPatientInputValue(newInputValue); }}
                                            size="small"
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.id}>
                                                        {option?.hasta}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                label="Hasta"
                                                name="hasta_id"
                                                onBlur={formik.handleBlur}
                                                error={formik.errors.hasta_id && formik.touched.hasta_id}
                                                helperText={formik.errors.hasta_id && formik.touched.hasta_id && <span className='cstm-input-error-validation-msg'>{formik.errors.hasta_id}</span>}
                                            />}
                                        />
                                    </FormControl>
                                </div>
                                <div className="pb-3">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="doktorId"
                                            options={doktors}
                                            loading={true}
                                            getOptionLabel={option => option?.name}
                                            noOptionsText={'Seçenek Bulunamadı'}
                                            value={doktorValue}
                                            label="Doktor"
                                            disableClearable
                                            onChange={(event, newValue) => {
                                                event.preventDefault();
                                                setDoktorValue(newValue);
                                                formik.setFieldValue('doktor_id', newValue.id, true);
                                            }}
                                            inputValue={doktorInputValue}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onInputChange={(event, newInputValue) => { setDoktorInputValue(newInputValue); }}
                                            size="small"
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.id}>
                                                        {option?.name}
                                                    </li>
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            renderInput={(params) => <TextField {...params}
                                                label="Doktor"
                                                name="doktor_id"
                                                onBlur={formik.handleBlur}
                                                error={formik.errors.doktor_id && formik.touched.doktor_id}
                                                helperText={formik.errors.doktor_id && formik.touched.doktor_id && <span className='cstm-input-error-validation-msg'>{formik.errors.doktor_id}</span>}
                                            />}
                                        />
                                    </FormControl>

                                </div>
                                <div className="pb-3">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="processId"
                                            options={processes}
                                            loading={true}
                                            getOptionLabel={option => option?.islem_adi}
                                            noOptionsText={'Seçenek Bulunamadı'}
                                            value={processValue}
                                            label="İşlem"
                                            disableClearable
                                            onChange={(event, newValue) => {
                                                event.preventDefault();
                                                setProcessValue(newValue);
                                                formik.setFieldValue('islem_id', newValue.id, true);
                                            }}
                                            inputValue={processInputValue}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onInputChange={(event, newInputValue) => { setProcessInputValue(newInputValue) }}
                                            size="small"
                                            onBlur={formik.handleBlur}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.id}>
                                                        {option?.islem_adi}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                label="İşlem"
                                                name="islem_id"
                                                onBlur={formik.handleBlur}
                                                error={formik.errors.islem_id && formik.touched.islem_id}
                                                helperText={formik.errors.islem_id && formik.touched.islem_id && <span className='cstm-input-error-validation-msg'>{formik.errors.islem_id}</span>}
                                            />}
                                        />
                                    </FormControl>
                                </div>

                                <div className="pb-3">
                                    <FormControl fullWidth>
                                        <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                            <DesktopDatePicker
                                                variant='outlined'
                                                label="İş Geliş Tarihi"
                                                minDate={dayjs('01-01-1930')}
                                                inputFormat="dd-MM-yyyy HH:mm"
                                                value={formik.values.gelis_tarihi}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue('gelis_tarihi', newValue, true);
                                                }}
                                                renderInput={(params) => <TextField {...params}
                                                    size="small"
                                                    name="gelis_tarihi"
                                                    variant='outlined'
                                                    error={formik.errors.gelis_tarihi && formik.touched.gelis_tarihi}
                                                    helperText={formik.errors.gelis_tarihi && formik.touched.gelis_tarihi && <span className='cstm-input-error-validation-msg'>{formik.errors.gelis_tarihi}</span>}
                                                />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={!can('create-laboratuvar')} className="d-flex align-items-center" >
                                {
                                    isProcesssing ?
                                        (
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                İşleniyor...
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <FaCloudUploadAlt /> &nbsp;
                                                {"Kaydet"}
                                            </>
                                        )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>

            {
                useMemo(() =>
                    <>
                        {can('view-laboratuvar') ?
                            <ThemeProvider theme={theme}>
                                <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                                    <DataGrid
                                        autoHeight={data.length > 0 ? true : false}
                                        sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                        loading={isLoading}
                                        rows={data}
                                        columns={columns}
                                        // pagination
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        rowCount={totalCount} //serverdan gelen data total count gelecek
                                        page={page}
                                        onPageChange={(newPage) => setPage(newPage)}
                                        pagination
                                        paginationMode="server"
                                        // sorting
                                        sortModel={sortModel}
                                        sortingMode="server"
                                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                        // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                        filterModel={filterModel}
                                        filterMode="server"
                                        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                        // selection checkbox
                                        //checkboxSelection={true}
                                        selectionModel={selectionModel}
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel(newSelectionModel);
                                        }}
                                        // features
                                        experimentalFeatures={{ newEditingApi: true }}
                                        // toolbar components
                                        components={{
                                            Toolbar: GridToolbar,
                                            NoRowsOverlay: CustomNoRowsOverlay,
                                            LoadingOverlay: LoadingOverlay
                                        }}
                                        disableColumnFilter
                                        disableSelectionOnClick
                                        // component props
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                printOptions: {
                                                    fields: ["id", "ilac_adi", "doz", "kullanim"],
                                                    fileName: "İlaclar",
                                                    hideFooter: true,
                                                    hideToolbar: true,
                                                    disableToolbarButton: true,
                                                }, //sadece firsname  ve lastname alanını alır.
                                                csvOptions: {
                                                    disableToolbarButton: false,
                                                    fields: ["laboratuvar", "hasta", "doktor", "islem", "gelis_tarihi"],
                                                    fileName: 'Giden_Isler',
                                                    utf8WithBom: true,
                                                }, //sadece firstname ve lastName alanını alır.
                                                excelOptions: { disableToolbarButton: true },
                                                // quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                    />
                                </Box>
                            </ThemeProvider>
                            : <NotPermission />}
                    </>
                    , [sortModel, filterModel, pageSize, page, updateDataGrid, data]
                )
            }


        </TabPanel>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(GoingJobs);
