
import * as yup from 'yup';  

const CreateEndikasyonValidationSchema = () => {
    return yup.object().shape({

           endikasyon_adi: yup.string()
                  .min(2,"Endikasyon adı en az 2 karakter olmalıdır.")
                  .max(250,"Endikasyon adı en fazla 250 karakter olmalıdır.")
                  .required("Endikasyon alanı zorunludur."),
   });
}   

export default CreateEndikasyonValidationSchema;

