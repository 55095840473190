import dayjs from 'dayjs';
const GetTrFullDateAndTimeWithFormat = (date = null) => {
    if(date !== null){
        try {
            let getJustDate = dayjs(date).format('DD-MM-YYYY HH:mm:ss');
            // dayjs(date).get('date') + "-" + (dayjs(date).get('month') + 1) + "-" +   dayjs(date).get('year') + " " + dayjs(date).get('hours')
            return getJustDate;
        } catch (error) {
            return null;
        }
    }
}

export default GetTrFullDateAndTimeWithFormat;