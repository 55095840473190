import { toothImages } from "./ToothImages";

export const InitialToothState = {
    initialTopDaimiDisler : [
        {no : 18, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no18, kok: '1-2-3', treatments: []},
        {no : 17, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no17, kok: '1-2-3', treatments: []},
        {no : 16, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no16, kok: '1-2-3',treatments: []},
        {no : 15, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no15, kok: '1',treatments: []},
        {no : 14, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no14, kok: '1-2',treatments: []},
        {no : 13, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no13, kok: '1',treatments: []},
        {no : 12, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no12, kok: '1',treatments: []},
        {no : 11, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no11, kok: '1',treatments: []},
        {no : 21, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no21, kok: '1',treatments: []},
        {no : 22, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no22, kok: '1',treatments: []},
        {no : 23, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no23, kok: '1',treatments: []},
        {no : 24, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no24, kok: '1-2',treatments: []},
        {no : 25, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no25, kok: '1',treatments: []},
        {no : 26, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no26, kok: '1-2-3',treatments: []},
        {no : 27, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no27, kok: '1-2-3',treatments: []},
        {no : 28, defaultImgShow: true, defaultImg: toothImages.topDaimiTooth.no28, kok: '1-2-3',treatments: []}
    ],

    initialBottomDaimiDisler : [
        {no : 48, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no48, kok: '1-2', treatments: []},
        {no : 47, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no47, kok: '1-2', treatments: []},
        {no : 46, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no46, kok: '1-2',treatments: []},
        {no : 45, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no45, kok: '1',treatments: []},
        {no : 44, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no44, kok: '1',treatments: []},
        {no : 43, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no43, kok: '1',treatments: []},
        {no : 42, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no42, kok: '1',treatments: []},
        {no : 41, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no41, kok: '1',treatments: []},
        {no : 31, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no31, kok: '1',treatments: []},
        {no : 32, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no32, kok: '1',treatments: []},
        {no : 33, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no33, kok: '1',treatments: []},
        {no : 34, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no34, kok: '1',treatments: []},
        {no : 35, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no35, kok: '1',treatments: []},
        {no : 36, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no36, kok: '1-2',treatments: []},
        {no : 37, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no37, kok: '1-2',treatments: []},
        {no : 38, defaultImgShow: true, defaultImg: toothImages.bottomDaimiTooth.no38, kok: '1-2',treatments: []}
    ],

    initialTopSutDisler : [
        {no : 55, defaultImg: toothImages.topSutTooth.no55, kok: '1-2-3', treatments: []},
        {no : 54, defaultImg: toothImages.topSutTooth.no54, kok: '1-2-3', treatments: []},
        {no : 53, defaultImg: toothImages.topSutTooth.no53, kok: '1',treatments: []},
        {no : 52, defaultImg: toothImages.topSutTooth.no52, kok: '1',treatments: []},
        {no : 51, defaultImg: toothImages.topSutTooth.no51, kok: '1',treatments: []},
        {no : 61, defaultImg: toothImages.topSutTooth.no61, kok: '1',treatments: []},
        {no : 62, defaultImg: toothImages.topSutTooth.no62, kok: '1',treatments: []},
        {no : 63, defaultImg: toothImages.topSutTooth.no63, kok: '1',treatments: []},
        {no : 64, defaultImg: toothImages.topSutTooth.no64, kok: '1-2-3',treatments: []},
        {no : 65, defaultImg: toothImages.topSutTooth.no65, kok: '1-2-3',treatments: []}
    ],

    initialBottomSutDisler : [
        {no : 85, defaultImg: toothImages.bottomSutTooth.no85, kok: '1-2', treatments: []},
        {no : 84, defaultImg: toothImages.bottomSutTooth.no84, kok: '1-2', treatments: []},
        {no : 83, defaultImg: toothImages.bottomSutTooth.no83, kok: '1',treatments: []},
        {no : 82, defaultImg: toothImages.bottomSutTooth.no82, kok: '1',treatments: []},
        {no : 81, defaultImg: toothImages.bottomSutTooth.no81, kok: '1',treatments: []},
        {no : 71, defaultImg: toothImages.bottomSutTooth.no71, kok: '1',treatments: []},
        {no : 72, defaultImg: toothImages.bottomSutTooth.no72, kok: '1',treatments: []},
        {no : 73, defaultImg: toothImages.bottomSutTooth.no73, kok: '1',treatments: []},
        {no : 74, defaultImg: toothImages.bottomSutTooth.no74, kok: '1-2',treatments: []},
        {no : 75, defaultImg: toothImages.bottomSutTooth.no75, kok: '1-2',treatments: []}
    ],
};