
import React, { useEffect, useState } from "react";
import "assets/css/pages/patient-detail.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { TabPanel } from "@mui/lab";
import { Button as ButtonM, Table, TableBody, TableCell, TableContainer, TableHead,
        TableRow , Paper, Stack, Tooltip, FormControl, TextField, Autocomplete} from "@mui/material";
import { Card } from "reactstrap";
import {  FaPrint, FaTimes,FaCloudUploadAlt} from "react-icons/fa";
import CreatePatientInvoiceValidationSchema from "business-layer/CreatePatientInvoiceValidationSchema";
import { useFormik } from "formik";
import AxiosCrudActions from "services/AxiosCrudCore";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import preLoadingImg from 'assets/images/loading.gif';
import ConvertFloatToStringTRFormat from "extensions/ConvertFloatToStringTRFormat";
import ConvertStringToFloat from "extensions/ConvertStringToFloat";
import GetTrDate from "extensions/GetTRDate";
import NumberMask from 'Utils/NumberMask';
import CustomPhoneMask from 'Utils/PhoneMask';
import TopChinImg from 'assets/images/TopBottomChin/oral-ust-cene.svg';
import BottomChinImg from 'assets/images/TopBottomChin/oral-alt-cene.svg';
import { StringParseWithSignToArray } from "extensions/StringParseWithSignToArray";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const CreatePatientInvoice = (props) => {
    const {auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const navigate = useNavigate();
    const params = useParams();
    const MySwal = withReactContent(Swal);

    const patientId = params?.patientId;
    const [invoiceInfo, setInvoiceInfo] = useState(null);
    const [treatments, setTreatments] = useState([]);
    const [city, setCity] = useState(null);
    const [cityInputValue, setCityInputValue] = useState('');
    const [district_id, setDistrictId] = useState(null);
    const [districtInputValue, setDistrictInputValue] = useState('');
    
    const [isProcesssing, setIsProcessing] = useState(false);
    const [cities, setCities] = useState([]); 
    const [districts, setDistricts] = useState([]); 
    const [loading,setLoading] = useState(true);

    const initialInvoiceInfo = {
        hasta_id: patientId,
        ad_soyad: '',
        tc_no : '',
        adres: '',
        tel_no: '',
        email: '',
        il_id: 0,
        ilce_id: 0,
        aciklama: '',
        vergi_numarasi: '',
        vergi_dairesi: '',
    };

    // yup schema
    let createPatientInvoiceValidationSchema = CreatePatientInvoiceValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialInvoiceInfo,
        validationSchema : createPatientInvoiceValidationSchema,
        onSubmit: values => {
            values.hasta_id = patientId;
            values.il_id = city?.id; 
            values.ilce_id = district_id?.id;
            values.fatura_kalemleri = JSON.stringify(treatments.map((obj) => obj.id));
            MySwal.fire({
                title: "Fatura Oluştur",
                text: "Fatura oluşturma işlemi gerçekleştirilsin mi?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Oluştur',
                cancelButtonText: 'Hayır, Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setIsProcessing(true);
                    AxiosCrudActions.CreateAsync('faturalar', values).then(result => {
                        setIsProcessing(false);
                        if (result?.data?.status) {
                            navigate(`/hastalar/detay/${patientId}/fatura`);
                        }
                    });
                }
            })
            
        },
    });

    const getCities = async () => {
        try {
            let data = await axios.get('cities');
            if(data.status === 200 ){
                setCities(data.data.data);
            }
            else{
                setCities([]);
            }
            
        } catch (error) {
            setCities([]);
        }   
    }

    const getDistricts = async (city_id) => {
        if(city_id !== undefined && city_id !== null && city_id > 0){
            try {
                let data = await axios.get(`cities/${city_id}`);
                if(data.status === 200 ){
                    setDistricts(data.data.data);
                }
                else{
                    setDistricts([]);
                }
                
            } catch (error) {
                setDistricts([]);
            }   
        }
       else{
        setDistricts([]);
       }
    }

    
    const getAllTreatmentsOfPatient = async () => {
        await axios.get(`/fatura-kalemleri/${params?.patientId}`)
                    .then(res => {
                        if(res?.status === 200 ){
                            setTreatments(res?.data?.kalemler);
                            setInvoiceInfo(res?.data?.data);
                            formik.setValues({
                                ad_soyad: res?.data?.data?.hasta?.ad_soyad || '',
                                tc_no : res?.data?.data?.hasta?.tc_no || '',
                                adres: res?.data?.data?.hasta?.adres || '',
                                il_id: res?.data?.data?.hasta?.il?.id || '',
                                ilce_id: res?.data?.data?.hasta?.ilce?.id || '',
                                tel_no: res?.data?.data?.hasta?.tel_no || '',
                                email: res?.data?.data?.hasta?.email || '',
                                aciklama: '',
                                vergi_numarasi: '',
                                vergi_dairesi: '',
                            }, true)
                            formik.setErrors({});
                            setCity(res?.data?.data?.hasta?.il)
                            setDistrictId(res?.data?.data?.hasta?.ilce)
                            if(res?.data?.data?.hasta?.il?.id && res?.data?.data?.hasta?.il?.id > 0){
                                getDistricts(res?.data?.data?.hasta?.il?.id);
                            }
                        }
                    }).catch(e => {
                        setTreatments([]);
                        setCity(null);
                        setDistrictId(null);
                        setInvoiceInfo(null);
                    })
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
    }   

    const removeFromList = (e, id = null) => {
        e.preventDefault();
        MySwal.fire({
            title: "Uyarı",
            text: "Tedavi Listeden çıkarılsın mı?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Çıkar',
            cancelButtonText: 'Hayır, Vazgeç'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsProcessing(true);
                if(treatments.length > 1 ){
                    let tutarToplam = ConvertFloatToStringTRFormat(treatments.reduce(function(prev, current) { return prev + (current.id !== id ? ConvertStringToFloat(current.tutar) : 0) }, 0));
                    let kdvToplam = ConvertFloatToStringTRFormat(treatments.reduce(function(prev, current) { return prev + (current.id !== id ? ConvertStringToFloat(current.kdv) : 0) }, 0));
                    let genelToplam = ConvertFloatToStringTRFormat(treatments.reduce(function(prev, current) { return prev + (current.id !== id ? ConvertStringToFloat(current.toplam) : 0) }, 0));
                    setInvoiceInfo(current => ({...current, kdv: kdvToplam}));
                    setInvoiceInfo(current => ({...current, ara_toplam: tutarToplam}));
                    setInvoiceInfo(current => ({...current, genel_toplam: genelToplam}));
                    setTreatments((current) => current.filter((treatment) => treatment.id !== id));
                    setIsProcessing(false);
                }else{
                    MySwal.fire({
                        title: "Uyarı",
                        text: "Fatura oluşturabilmeniz için min 1 tedavi eklenmesi zorunludur.?",
                        icon: 'warning',
                    })
                }
            }
        })
    } 

    useEffect(() => {
        getCities();
    },[])

    useEffect(() => {
        if(patientId !== null && patientId > 0){
            getAllTreatmentsOfPatient();
        }
    }, []);

    return (
        <>
        { can('create-hastalar-fatura') ?
            <TabPanel value="3">
                {
                    isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
                }
                <div className="patient-detail-container ">
                    <form onSubmit={formik.handleSubmit}>
                    <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                        <div className="d-flex justify-content-between align-items-center mb-3" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                            <span className="d-flex align-items-center" style={{fontSize: '16px',fontWeight: 'normal', color: '#1172b6', opacity: '0.8'}}>
                                <strong>
                                    Yeni Fatura Oluştur&nbsp;
                                </strong>
                            </span>
                            <span>
                                <ButtonM color="warning" variant="outlined" type="submit" className="d-flex align-items-center" disabled={! can('create-hastalar-fatura')} >
                                                    {
                                                        isProcesssing ? 
                                                        (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                                İşleniyor...
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <FaPrint /> &nbsp;
                                                                {"E-fatura Oluştur"}
                                                            </>
                                                        )
                                                    }
                                </ButtonM>
                            </span>
                        </div>
                        <div className="mb-2">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <TextField className='form-group'
                                            type="text"
                                            name="ad_soyad"
                                            label="Ad Soyad"
                                            variant='outlined'
                                            autoComplete="off"
                                            InputLabelProps={{shrink:true, style: {color: formik.errors.ad_soyad && formik.touched.ad_soyad ? '#e53e3e' : '#1976d2'}}}
                                            onChange={formik.handleChange}
                                            value={formik.values.ad_soyad}
                                            onBlur={formik.handleBlur}
                                            id="outlined-start-adornment"
                                            size='small'
                                            error={formik.errors.ad_soyad && formik.touched.ad_soyad}
                                            helperText={formik.errors.ad_soyad && formik.touched.ad_soyad && <span className='cstm-input-error-validation-msg'>{formik.errors.ad_soyad}</span>}
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <TextField className='form-group'
                                            type="text"
                                            name="tc_no"
                                            label="TC Kimlik / VKN:"
                                            variant='outlined'
                                            autoComplete="off"
                                            InputLabelProps={{shrink: true, style: {color: formik.errors.tc_no && formik.touched.tc_no ? '#e53e3e' : '#1976d2'}}}
                                            onChange={formik.handleChange}
                                            value={formik.values.tc_no}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            inputProps={{ maxLength: 11 }}
                                            InputProps={{
                                                inputComponent: NumberMask,
                                            }}
                                            error={formik.errors.tc_no && formik.touched.tc_no}
                                            helperText={formik.errors.tc_no && formik.touched.tc_no && <span className='cstm-input-error-validation-msg'>{formik.errors.tc_no}</span>}
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <TextField className='form-group'
                                            type="text"
                                            name="tel_no"
                                            label="Tel No:"
                                            variant='outlined'
                                            autoComplete="off"
                                            InputLabelProps={{shrink:true,style: {color: formik.errors.tel_no && formik.touched.tel_no ? '#e53e3e' : '#1976d2'}}}
                                            onChange={formik.handleChange}
                                            value={formik.values.tel_no}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputProps={{
                                                inputComponent: CustomPhoneMask
                                            }}
                                            error={formik.errors.tel_no && formik.touched.tel_no}
                                            helperText={formik.errors.tel_no && formik.touched.tel_no && <span className='cstm-input-error-validation-msg'>{formik.errors.tel_no}</span>}
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <TextField className='form-group'
                                            type="text"
                                            name="email"
                                            label="Email:"
                                            variant='outlined'
                                            autoComplete="off"
                                            InputLabelProps={{shrink: true, style: {color: formik.errors.email && formik.touched.email ? '#e53e3e' : '#1976d2'}}}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            error={formik.errors.email && formik.touched.email}
                                            helperText={formik.errors.email && formik.touched.email && <span className='cstm-input-error-validation-msg'>{formik.errors.email}</span>}
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            options={cities}
                                            loading={true}
                                            getOptionLabel={option => option.il}
                                            noOptionsText={'Seçenek Bulunamadı'}
                                            value={city}
                                            name="il_id"
                                            onChange={(event, newValue) => { 
                                                event.preventDefault();
                                                setCity(newValue);
                                                setDistrictId(null);
                                                setDistricts([]);
                                                getDistricts(newValue?.id);
                                                formik.setFieldValue('il_id', newValue.id, true); //update formik value and validate true
                                            }}
                                            inputValue={cityInputValue}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            onInputChange={(event, newInputValue) => { setCityInputValue(newInputValue); }}
                                            size="small"
                                            disableClearable
                                            renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true, style: {color: '#1976d2'}}} label="Şehir" />
                                            }  
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            options={districts}
                                            getOptionLabel={option => option.ilce}
                                            noOptionsText={'Lütfen Şehir Seçiniz.'}
                                            value={district_id}
                                            name="ilce_id"
                                            disableClearable
                                            onChange={(event, newValue) => { 
                                                setDistrictId(newValue); 
                                                formik.setFieldValue('ilce_id', newValue.id, false); //update formik value and validate true
                                            }}
                                            inputValue={districtInputValue}
                                            isOptionEqualToValue={(option, value) =>  option?.id === value?.id}
                                            onInputChange={(event, newInputValue) => { setDistrictInputValue(newInputValue); }}
                                            size="small"
                                            renderInput={(params) => <TextField {...params} 
                                                                        label="İlçe"
                                                                        InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                                                        error={formik.errors.ilce_id && formik.touched.ilce_id}
                                                                        helperText={formik.errors.ilce_id && formik.touched.ilce_id && <span className='cstm-input-error-validation-msg'>{formik.errors.ilce_id}</span>}
                                                                        />
                                            }  
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <TextField className='form-group'
                                            type="text"
                                            name="adres"
                                            label="Adres:"
                                            variant='outlined'
                                            autoComplete="off"
                                            InputLabelProps={{shrink:true, style: {color: formik.errors.adres && formik.touched.adres ? '#e53e3e' : '#1976d2'}}}
                                            onChange={formik.handleChange}
                                            value={formik.values.adres}
                                            onBlur={formik.handleBlur}
                                            id="outlined-start-adornment"
                                            size='small'
                                            error={formik.errors.adres && formik.touched.adres}
                                            helperText={formik.errors.adres && formik.touched.adres && <span className='cstm-input-error-validation-msg'>{formik.errors.adres}</span>}
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <TextField className='form-group'
                                            type="text"
                                            label="Vergi Numarası"
                                            name="vergi_numarasi"
                                            variant='outlined'
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <TextField className='form-group'
                                            type="text"
                                            label="Vergi Dairesi"
                                            name="vergi_dairesi"
                                            variant='outlined'
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                    <FormControl fullWidth>
                                        <TextField
                                            type="text"
                                            label="Açıklama"
                                            name="aciklama"
                                            variant='outlined'
                                            autoComplete="off"
                                            InputLabelProps={{shrink: true, style: {color: formik.errors.aciklama && formik.touched.aciklama ? '#e53e3e' : '#1976d2'}}}
                                            onChange={formik.handleChange}
                                            value={formik.values.aciklama}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <h4 className="fs-4 fw-normal p-2 mb-2 mt-2" style={{color: '#1172b6'}}>Fatura Kalemleri:</h4>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 1000}} size="small" className="cstm-border-top">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" width='15%'>Tarih</TableCell>
                                        <TableCell align="center" width='15%'>Diş</TableCell>
                                        <TableCell align="center" width='20%'>Tedavi</TableCell>
                                        <TableCell align="center" width='15%'>Doktor</TableCell>
                                        <TableCell align="center" width='10%'>Tutar</TableCell>
                                        <TableCell align="center" width='5%'>KDV</TableCell>
                                        <TableCell align="center" width='15%'>Toplam</TableCell>
                                        <TableCell align="center" width='5%'>İşlem</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { !loading ? treatments.map((row) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center" width='15%'>{GetTrDate(row?.tarih)}</TableCell>
                                            <TableCell align="center" width='15%'>
                                                {
                                                    StringParseWithSignToArray(row?.disler, '-').length > 1 
                                                    ?
                                                    <Tooltip title={row?.disler} arrow>
                                                        <img src={row?.konum === 1 ? TopChinImg : BottomChinImg}  alt="teeth" />
                                                    </Tooltip>
                                                    :
                                                    row?.disler
                                                }
                                            </TableCell>
                                            <TableCell align="center" width='20%'>{row?.tedavi}</TableCell>
                                            <TableCell align="center" width='15%'>{row?.doktor?.name}</TableCell>
                                            <TableCell align="center" width='15%'>{row?.tutar}</TableCell>
                                            <TableCell align="center" width='5%'>{row?.kdv}</TableCell>
                                            <TableCell align="center" width='15%'>{row?.toplam}</TableCell>
                                            <TableCell align="center" width='20%'>
                                            <Stack direction="row" spacing={2} className="justify-content-center">
                                                <Tooltip title="Listeden Çıkar" arrow>
                                                    <ButtonM variant="outlined"  disabled={! can('delete-hastalar-fatura')}
                                                        onClick={(e) => removeFromList(e, row?.id)}
                                                        color="error"><FaTimes /></ButtonM>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                        </TableRow>
                                    )):
                                    <TableRow width="100%">
                                    <TableCell>
                                            <div id="invoice-pre-loading-content">
                                                <div className="loading">
                                                    <img src={preLoadingImg} alt="loading" width={110} height={110} />
                                                    <div className="d-flex justify-content-center" style={{color: '#1172b6'}}>
                                                        <span>Yükleniyor...</span>
                                                    </div>
                                                </div>
                                            </div>
                                    </TableCell>
                                    </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="mt-4 d-flex justify-content-end">
                        <div style={{width: '300px', fontSize: '18px', opacity: '0.8'}}>
                                <p>
                                    <strong style={{width: '130px',display: 'inline-block', textAlign:'end', paddingRight: '10px'}}>Ara Toplam:</strong> 
                                    <span>{invoiceInfo?.ara_toplam || 0.00} TRY</span> <br/>   
                                </p>   
                                <p>
                                    <strong style={{width: '130px',display: 'inline-block', textAlign:'end', paddingRight: '10px'}}>KDV Toplam:</strong>
                                    <span>{invoiceInfo?.kdv || 0.00} TRY</span> <br/>   
                                </p>
                                <p>
                                    <strong style={{width: '130px',display: 'inline-block', textAlign:'end', paddingRight: '10px'}}>Genel Toplam:</strong>
                                    <span>{invoiceInfo?.genel_toplam || 0.00} TRY</span>     
                                </p> 
                        </div>
                        </div>
                    </Card>
                    </form>
                </div>
            </TabPanel>
        :<NotPermission/>}
        </>
    );
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};


export default connect(authPermissions)(CreatePatientInvoice);