import * as yup from 'yup';

const SettingsContactValidationSchema = () => {
    return yup.object().shape({

        web_site: yup.string()
            .min(2, "Web Sitesi en az 2 karakter olmalıdır.")
            .max(50, "Web Sitesi en fazla 50 karakter olmalıdır.")
            .required("Lütfen geçerli bir Web Site bilgisi giriniz."),

        telefon: yup.string()
            .required("Lütfen geçerli bir Telefon bilgisi giriniz."),

        email: yup.string()
            .email("Lütfen geçerli bir email adresi giriniz.")
            .max(50, "email adresi max 50 karakter olmalıdır.")
            .matches(
                /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@!#$%&'*+-/=?^_`{|}~.]+$/,
                "E-posta yalnızca ingilizce karakter barındırabilir"
            )
            .required("Lütfen geçerli bir Email bilgisi giriniz."),

        adres: yup.string()
            .required("Lütfen geçerli bir Adres bilgisi giriniz."),




    });
}

export default SettingsContactValidationSchema;