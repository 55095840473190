import React, { useEffect, useState } from "react";
import { TabPanel } from "@mui/lab";
import 'assets/css/pages/d-doktor.css';
import { Button as ButtonM } from "@mui/material";
import { FaAddressCard, FaCloudUploadAlt, FaExclamationCircle, FaInfoCircle, FaLowVision, FaPaintBrush, FaPercentage, FaPhoneAlt,
         FaPlus, FaRegAddressBook, FaRegEdit, FaRegEnvelopeOpen, FaShareAlt, FaTimes, FaUnlock, FaUserCircle,FaUserLock } from "react-icons/fa";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CreateDoctorValidationSchema from "business-layer/CreateDoctorValidationSchema";
import {  useFormik } from 'formik';
import AxiosCrudActions from "services/AxiosCrudCore";
import { InputAdornment,FormControl, TextField } from '@mui/material';
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import withReactContent from 'sweetalert2-react-content'
import CustomPhoneMask from 'Utils/PhoneMask';
import RateMask from "Utils/RateMask";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const DefinitionDoktor = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const [loading, setLoading] = useState(true);
    const [doctors, setDoctors] = useState([]);
    const [adres, setAdres] = useState('');
    const [modal, setModal] = useState(false);
    const [doctorId, setDoctorId] = useState(0);
    const [filteredDoctors, setFilteredDoctors] = useState([]);
    const [isProcesssing, setIsProcessing] = useState(false);

    const toggle = () => setModal(!modal);


    const handleAdress = (event) => {
        setAdres(event.target.value);
        formik.values.adres = event.target.value;
    }

    const getDoctors = () => {
        try {
            axios.get('/kullanicilar?doktor=1')
            .then(res => {
                setDoctors(res.data.data);
                setLoading(false);
                setFilteredDoctors(res.data.data)
            })
        } catch (error) {
            setDoctors([])
            setLoading(false);
            setFilteredDoctors([]);
        }
    }

    const initialDoctorInfo = {
        name: '',
        email: '',
        password: '',
        password_confirmation:'',
        tel_no: '',
        brans: '',
        hakedis: 0,
        renk: '#1172b6',
        adres: '',
        isPasswordValid: true,
    }

    // yup schema
    let doctorValidationSchema = CreateDoctorValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialDoctorInfo,
        validationSchema : doctorValidationSchema, 
        onSubmit: values => {
            delete values.isPasswordValid;
            setIsProcessing(true);
            if(doctorId === 0){
                AxiosCrudActions.CreateAsync('kullanicilar',values).then(result => {
                    setIsProcessing(false);
                    if(result?.data?.status){
                        setLoading(true);
                        getDoctors();
                        toggle();
                    }
                });
            }else if(doctorId > 0){
                AxiosCrudActions.UpdateAsync(`kullanicilar/${doctorId}`,values).then(result => {
                    setIsProcessing(false);
                    if(result?.data?.status){
                        setLoading(true);
                        getDoctors();
                        toggle();
                    }
                });
            }
        },
    });

    const addNewDoctor = (event) => {
        event.preventDefault();
        formik.values.name = '';
        formik.values.email = '';
        formik.values.password = '';
        formik.values.password_confirmation = '';
        formik.values.tel_no = '';
        formik.values.brans =  '';
        formik.values.hakedis = 0;
        formik.values.renk = '#1172b6';
        formik.values.adres = '';
        formik.values.isPasswordValid = true;
        formik.setErrors({});
        setDoctorId(0);
        setAdres('');
        toggle();
    }

    const editDoctor = (event,values) => {
        event.preventDefault();
        formik.values.name = values?.name ? values?.name : '';
        formik.values.email = values?.email ? values?.email : '';
        formik.values.password = '';
        formik.values.password_confirmation = '';
        formik.values.tel_no = values?.tel_no ? values?.tel_no : '';
        formik.values.brans = values?.brans ? values?.brans : '';
        formik.values.hakedis = values?.hakedis ? values?.hakedis : 0;
        formik.values.renk = values?.renk ? values?.renk : '#1172b6';
        formik.values.adres = values?.adres ? values?.adres : '';
        //formik.setFieldValue('isPasswordValid', false, true);
        formik.values.isPasswordValid = false;
        formik.setErrors({});
        setAdres(values?.adres ? values.adres : '');
        setDoctorId(values?.id);
        toggle();
    }


    const deleteDoctor = (e, id) => {
        e.preventDefault(); 
        e.stopPropagation();
        MySwal.fire({
            title: "Silmek istediğinizden emin misiniz?",
            text: "silme işlemini gerçekleştirdiğinizde durumu pasif hale gelecektir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Sil',
            cancelButtonText : 'Hayır,Vazgeç'
        }).then(async (result)  => {
            if (result.isConfirmed) {
                AxiosCrudActions.DeleteAsync('/kullanicilar', id , {doktor: "1"})
                                .then(result => {
                                    if(result?.status === 200 && result?.data?.status){
                                        setLoading(true);
                                        getDoctors();
                                    }
                                });
            }
        })
    }

    useEffect(() => {
        getDoctors();
    },[]);

    return (
        <>
        {can('view-doktorlar') ?  
            <TabPanel value="1">
                {
                    loading ? 
                    filteredDoctors?.length > 0 ? 
                    <div className="doctor-page-loading">
                        <CustomNoRowsOverlay />
                    </div> 
                    :
                    <div className="doctor-page-loading">
                    <LoadingOverlay/>
                    </div> 
                    :
                
                            

                    <div className="d-doctor-container">
                    {
                    isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
                    }
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center">
                            <strong className="fs-5 fw-normal text text-secondary">Tüm Doktorlar &nbsp;</strong> 
                        </span>
                        <span>
                            <ButtonM color="success" type="button" variant="outlined" startIcon={<FaPlus />} disabled={! can('create-doktorlar')} onClick={addNewDoctor}>
                                Yeni Doktor
                            </ButtonM>
                            &nbsp;
                            <ButtonM color="warning" type="button" variant="outlined" startIcon={<FaUserLock />} disabled={! can('view-yetkiler')} onClick={() => { navigate('/tanimlamalar/kullanici-izinleri') }} >
                                Kullanıcı Yetkilendirme
                            </ButtonM>
                        </span>
                        <Modal isOpen={modal} 
                            toggle={toggle} 
                            size="lg"
                            modalTransition={{ timeout: 1 }}
                            backdropTransition={{ timeout: 1 }}
                            >
                            <ModalHeader toggle={toggle} style={{color:"#1172b6",fontWeight:200}}>Doktor Bilgileri</ModalHeader>
                            <form onSubmit={formik.handleSubmit}>
                                <ModalBody>
                                    <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-3">
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="text"
                                                name="name"
                                                variant='outlined'
                                                label="Ad Soyad"
                                                autoComplete="off"
                                                InputLabelProps={{style: {color: formik.errors.name && formik.touched.name ? '#e53e3e' : '#1976d2'}}}
                                                onChange={formik.handleChange}
                                                value={formik.values.name}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaUserCircle /></InputAdornment>,
                                                }}
                                                error={formik.errors.name && formik.touched.name}
                                                helperText={formik.errors.name && formik.touched.name && <span className='cstm-input-error-validation-msg'>{formik.errors.name}</span>}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="text"
                                                name="brans"
                                                variant='outlined'
                                                label="Branş"
                                                autoComplete="off"
                                                InputLabelProps={{style: {color: formik.errors.brans && formik.touched.brans ? '#e53e3e' : '#1976d2'}}}
                                                onChange={formik.handleChange}
                                                value={formik.values.brans}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaShareAlt /></InputAdornment>,
                                                }}
                                                error={formik.errors.brans && formik.touched.brans}
                                                helperText={formik.errors.brans && formik.touched.brans && <span className='cstm-input-error-validation-msg'>{formik.errors.brans}</span>}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="text"
                                                name="tel_no"
                                                variant='outlined'
                                                label="Cep Telefon"
                                                autoComplete="off"
                                                InputLabelProps={{style: {color: formik.errors.tel_no && formik.touched.tel_no ? '#e53e3e' : '#1976d2'}}}
                                                onChange={formik.handleChange}
                                                value={formik.values.tel_no}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    inputComponent: CustomPhoneMask,
                                                    startAdornment: <InputAdornment position="start" ><FaPhoneAlt /></InputAdornment>,
                                                }}
                                                error={formik.errors.tel_no && formik.touched.tel_no}
                                                helperText={formik.errors.tel_no && formik.touched.tel_no && <span className='cstm-input-error-validation-msg'>{formik.errors.tel_no}</span>}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="text"
                                                name="email"
                                                variant='outlined'
                                                label="Email"
                                                autoComplete="off"
                                                InputLabelProps={{style: {color: formik.errors.email && formik.touched.email ? '#e53e3e' : '#1976d2'}}}
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaRegEnvelopeOpen /></InputAdornment>,
                                                }}
                                                error={formik.errors.email && formik.touched.email}
                                                helperText={formik.errors.email && formik.touched.email && <span className='cstm-input-error-validation-msg'>{formik.errors.email}</span>}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-3">
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="password"
                                                name="password"
                                                variant='outlined'
                                                label="Şifre"
                                                autoComplete="off"
                                                InputLabelProps={{style: {color: formik.errors.password && formik.touched.password ? '#e53e3e' : '#1976d2'}}}
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaUnlock /></InputAdornment>,
                                                }}
                                                error={formik.errors.password && formik.touched.password}
                                                helperText={formik.errors.password && formik.touched.password && <span className='cstm-input-error-validation-msg'>{formik.errors.password}</span>}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="password"
                                                name="password_confirmation"
                                                variant='outlined'
                                                label="Şifre Tekrar"
                                                autoComplete="off"
                                                InputLabelProps={{style: {color: formik.errors.password_confirmation && formik.touched.password_confirmation ? '#e53e3e' : '#1976d2'}}}
                                                onChange={formik.handleChange}
                                                value={formik.values.password_confirmation}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaUnlock /></InputAdornment>,
                                                }}
                                                error={formik.errors.password_confirmation && formik.touched.password_confirmation}
                                                helperText={formik.errors.password_confirmation && formik.touched.password_confirmation && <span className='cstm-input-error-validation-msg'>{formik.errors.password_confirmation}</span>}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="color"
                                                name="renk"
                                                variant='outlined'
                                                label="Randevu Rengi"
                                                autoComplete="off"
                                                InputLabelProps={{style: {color: formik.errors.renk && formik.touched.renk ? '#e53e3e' : '#1976d2'}}}
                                                onChange={formik.handleChange}
                                                value={formik.values.renk}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaPaintBrush /></InputAdornment>,
                                                }}
                                                error={formik.errors.renk && formik.touched.renk}
                                                helperText={formik.errors.renk && formik.touched.renk && <span className='cstm-input-error-validation-msg'>{formik.errors.renk}</span>}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="text"
                                                name="hakedis"
                                                variant='outlined'
                                                label="Hakediş Yüzdesi"
                                                autoComplete="off"
                                                InputLabelProps={{style: {color: formik.errors.hakedis && formik.touched.hakedis ? '#e53e3e' : '#1976d2'}}}
                                                onChange={formik.handleChange}
                                                value={formik.values.hakedis}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    inputComponent: RateMask,
                                                    startAdornment: <InputAdornment position="start" ><FaPercentage /></InputAdornment>
                                                }}
                                                error={formik.errors.hakedis && formik.touched.hakedis}
                                                helperText={formik.errors.hakedis && formik.touched.hakedis && <span className='cstm-input-error-validation-msg'>{formik.errors.hakedis}</span>}
                                            />
                                        </FormControl>
                                    </div>
                                    </div>
                                    <div>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="outlined-multiline-flexible"
                                                label="Adres"
                                                multiline
                                                minRows={3}
                                                name="Adres"
                                                placeholder="Adres..."
                                                autoComplete="off"
                                                InputLabelProps={{style: {color: formik.errors.adres && formik.touched.adres ? '#e53e3e' : '#1976d2'}}}
                                                value={adres}
                                                onChange={handleAdress}
                                                onBlur={formik.handleBlur}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaRegAddressBook /></InputAdornment>,
                                                }}
                                                error={formik.errors.adres && formik.touched.adres}
                                                helperText={formik.errors.adres && formik.touched.adres && <span className='cstm-input-error-validation-msg'>{formik.errors.adres}</span>}
                                            />     
                                        </FormControl>    
                                    </div>
                                </ModalBody>
    
                                <ModalFooter>
                                <Button color="primary" type="submit"  style={{ background:"#1172b6" }}  disabled={ doctorId > 0 ? ! can('edit-doktorlar'): ! can('create-doktorlar')} className="d-flex align-items-center" >
                                    {
                                        isProcesssing ? 
                                        (
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                İşleniyor...
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <FaCloudUploadAlt /> &nbsp;
                                                {doctorId > 0 ? "Güncelle" :"Kaydet"}
                                            </>
                                        )
                                    }
                                </Button>{' '}
                                <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                    <FaTimes />&nbsp;Vazgeç
                                </Button>
                                </ModalFooter>
                            </form>
                        </Modal>


                    </div>

                    {/* doctor list */}
                    <div className="doctor-list-card">
                        <div className="row">
                            {
                                doctors.map((item,index) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="card-wrap">
                                            <div className="card-divide">
                                                <div className="info-avatar" style={{backgroundColor: item?.renk ? item.renk : "#1172b6"}}>{item?.name?.charAt(0)}</div>
                                                <div className="info-wrap">
                                                    <h4 className="header header-title">{item?.name}</h4>
                                                    <h6 className="sub-header">{item?.brans}</h6>
                                                    <div className="phone"><span><FaPhoneAlt /> &nbsp;&nbsp; {item?.tel_no}</span></div>
                                                    <div className="email"><span><FaRegEnvelopeOpen />&nbsp;&nbsp; {item?.email}</span></div>
                                                    <div className={item?.durum === 1 ? "status active" : "status passive"}>
                                                        <span>{item?.durum === 1 ? <FaInfoCircle />  : <FaExclamationCircle />}&nbsp;&nbsp; <small>{item?.durum === 1 ? "Aktif" : "Pasif"}</small></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="action-buttons">
                                                <button type="button" onClick={(e) => editDoctor(e,item)} disabled={! can('edit-doktorlar')} className="edit"><FaRegEdit /></button>
                                                <button type="button" onClick={(e) => deleteDoctor(e,item?.id)} disabled={! can('delete-doktorlar')} className="delete"><FaTimes /></button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            
                        </div>
                    </div>
                </div>
                }
            
            </TabPanel>
        :<NotPermission/>}
        </>
    );
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(DefinitionDoktor);