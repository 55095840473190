import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MainLayout from 'layout/MainLayout';
import Appointment from 'pages/Appointment';
import Dashboard from 'pages/Dashboard';
import EmptyLayout from 'layout/EmptyLayout';
import Login from 'components/auth/Login';
import IsAuthenticatedRoute from 'routes/IsAuthenticatedRoute';
import PrivateRoute from 'routes/PrivateRoute';
import PermissionRoute from 'routes/PermissionRoute';
import NotFound from 'pages/NotFound';
import { RemoveLoginInfo } from 'redux/actions/AuthAction';
import { connect } from 'react-redux';
import axios from 'axios';
import LoadingPage from 'pages/LoadingPage';
import ToastComponent from 'components/toast-messages/Toast';

//-> Hastalar
import CreatePatient from 'pages/patients/CreatePatient';
import PatientLayout from 'pages/patients/PatientLayout';
import Patients from 'pages/patients/Patients';
import EditPatient from 'pages/patients/EditPatient';

//-> Hasta detay
import DetailPatient from 'pages/patients/detail/DetailPatient';
import PatientTreatment from 'pages/patients/detail/treatment/PatientTreatment';
import PatientAccount from 'pages/patients/detail/PatientAccount';
import PatientInvoice from 'pages/patients/detail/invoice/PatientInvoice';
import PatientImageArchive from 'pages/patients/detail/PatientImageArchive';
import PatientAppointment from 'pages/patients/detail/PatientAppointment';

//->Ayarlar
import SettingsLayout from 'pages/settings/SettingsLayout';
import SettingsRandevu from 'pages/settings/SettingsRandevu';
import SettingsFirmaEfatura from 'pages/settings/SettingsFirmaEfatura';
import SettingsIletisim from 'pages/settings/SettingsIletisim';
import SettingsGenel from 'pages/settings/SettingsGenel';

//->Tanımlamalar
import DefinitionLayout from 'pages/definition/DefinitionLayout';
import DefinitionDoktor from 'pages/definition/DefinitionDoktor';
import DefinitionTarife from 'pages/definition/DefinitionTarife';
import DefinitionDiagnoz from 'pages/definition/DefinitionDiagnoz';
import DefinitionRandevuTedavi from 'pages/definition/DefinitionRandevuTedavi';
import DefinitionIlac from 'pages/definition/DefinitionIlac';
import DefinitionLaboratuvar from 'pages/definition/DefinitionLaboratuvar';

//->Giderler
import Expense from 'pages/expense/Expense';
import ExpenseDetail from 'pages/expense/ExpenseDetail';

//->Laboratuvar
import Laboratory from 'pages/laboratory/Laboratory';
import ComingJobs from 'pages/laboratory/ComingJobs';
import GoingJobs from 'pages/laboratory/GoingJobs';
import PatientInvoiceDetail from 'pages/patients/detail/invoice/PatientInvoiceDetail';
import CreatePatientInvoice from 'pages/patients/detail/invoice/CreatePatientInvoice';
import PatientPrescription from 'pages/patients/detail/prescription/PatinetPrescription';
import PatientPrescriptionDetail from 'pages/patients/detail/prescription/PatientPrescriptionDetail';
import CreatePatientPrescription from 'pages/patients/detail/prescription/CreatePatientPrescription';

//->Raporlar
import Report from 'pages/Report';
import IncomeAnalysis from 'pages/reports/IncomeAnalysis';
import PatientAnalysis from 'pages/reports/PatientAnalysis';
import TreatmentAnalysis from 'pages/reports/TreatmentAnalysis';
import TreatmentCounterAnalysis from 'pages/reports/TreatmentCounterAnalysis';
import LaboratoryAnalysis from 'pages/reports/LaboratoryAnalysis';
import Bills from 'pages/bills/Bills';
import Stocks from 'pages/stock/Stocks';
import MovementsStock from 'pages/stock/MovementsStock';
import SettingsUserPermission from 'pages/SettingsUserPermission';
import Loglar from 'pages/audit-log/Loglar';
import Users from 'pages/users/Users';
import Anamnez from 'pages/patients/detail/Anamnez';
import DefinitionEndikasyon from 'pages/definition/DefinitionEndikasyon';
import DebtAnalysis from 'pages/reports/DebtAnalysis';
import AllowanceAnalysis from 'pages/reports/AllowanceAnalysis';
import PlanningAnalysis from 'pages/reports/PlanningAnalysis';
import ExpenseAnalysis from 'pages/reports/ExpenseAnalysis';
import NotebookAnalysis from 'pages/reports/NotebookAnalysis';


const App = (props) => {


    const { errorState, removeLoginInfo } = props;
    const [isReady, setIsReady] = useState(false);

    errorState?.message !== "" && ToastComponent.ToastMessage(errorState?.message, ToastComponent.MessageType.ERROR);

    useEffect(() => {
        const authDataFromLS = localStorage.getItem('auth');
        if (authDataFromLS !== undefined && authDataFromLS !== null) {
            const authObj = JSON.parse(authDataFromLS);
            if (authObj?.user?.access_token !== undefined && authObj?.user?.access_token !== null) {
                axios.post("validateToken")
                    .then(res => {
                        setTimeout(() => {
                            setIsReady(true);
                        }, 1000);
                    })
                    .catch(error => {
                        removeLoginInfo();
                        setTimeout(() => {
                            setIsReady(true);
                        }, 1000);
                    });
            } else {
                removeLoginInfo();
                setTimeout(() => {
                    setIsReady(true);
                }, 1000);
            }
        } else {
            removeLoginInfo();
            setTimeout(() => {
                setIsReady(true);
            }, 1000);
        }
    }, [])

    return (
        <BrowserRouter>
            {
                isReady ?

                    <Routes>
                        {/* return from / redirect to dashboard */}
                        <Route exact path="/" element={<Navigate to="/anasayfa" />} />
                        {/* MainLayout Routes */}
                        <Route path="/" element={<MainLayout />}>
                            <Route element={<PrivateRoute />}>
                                <Route exact index path="anasayfa" element={<Dashboard />} />
                                <Route element={<PermissionRoute permission="view-hastalar" />}>
                                    <Route exact path="hastalar" element={<PatientLayout />}>
                                        <Route exact index element={<Patients />} />
                                        <Route element={<PermissionRoute permission="create-hastalar" />}>
                                            <Route exact path='ekle' element={<CreatePatient />} />
                                        </Route>
                                        <Route element={<PermissionRoute permission="edit-hastalar" />}>
                                            <Route exact path='duzenle/:patientId' element={<EditPatient />} />
                                        </Route>
                                        <Route element={<PermissionRoute permission="view-hastalar-tedavi" />}>
                                            <Route exact path="detay/:patientId" element={<DetailPatient />}>
                                                <Route exact path='tedavi' element={<PatientTreatment />} />
                                                <Route element={<PermissionRoute permission="view-hastalar-hesap" />}>
                                                    <Route exact path='hesap' element={<PatientAccount />} />
                                                </Route>

                                                <Route element={<PermissionRoute permission="view-hastalar-tedavi" />}>
                                                    <Route exact path='anamnez' element={<Anamnez />} />
                                                </Route>

                                                <Route element={<PermissionRoute permission="view-hastalar-goruntu" />}>
                                                    <Route exact path='goruntu' element={<PatientImageArchive />} />
                                                </Route>

                                                <Route element={<PermissionRoute permission="view-hastalar-randevu" />}>
                                                    <Route exact path='randevu' element={<PatientAppointment />} />
                                                </Route>
                                                <Route element={<PermissionRoute permission="view-hastalar-fatura" />}>
                                                    <Route exact path='fatura' element={<PatientInvoice />} />
                                                </Route>

                                                <Route element={<PermissionRoute permission="create-hastalar-fatura" />}>
                                                    <Route exact path='fatura/olustur' element={<CreatePatientInvoice />} />
                                                </Route>

                                                <Route element={<PermissionRoute permission="view-hastalar-fatura-detay" />}>
                                                    <Route exact path='fatura/kalemleri/:id' element={<PatientInvoiceDetail />} />
                                                </Route>

                                                <Route element={<PermissionRoute permission="view-hastalar-recete" />}>
                                                    <Route exact path='recete' element={<PatientPrescription />} />
                                                </Route>

                                                <Route element={<PermissionRoute permission="create-hastalar-recete" />}>
                                                    <Route exact path='recete/olustur' element={<CreatePatientPrescription />} />
                                                </Route>

                                                <Route element={<PermissionRoute permission="edit-hastalar-recete" />}>
                                                    <Route exact path='recete/detay/:id' element={<PatientPrescriptionDetail />} />
                                                </Route>
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>
                                <Route element={<PermissionRoute permission="view-randevu" />}>
                                    <Route exact path="randevular" element={<Appointment />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-cariler" />}>
                                    <Route exact path="giderler" element={<Expense />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-giderler" />}>
                                    <Route exact path="giderler/cari-detay/:cariId" element={<ExpenseDetail />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-laboratuvar" />}>
                                    {/* laboratoryLayout is  <Laboratory />*/}
                                    <Route exact path="laboratuvar" element={<Laboratory />}>
                                        <Route exact path="gelen-isler" element={<ComingJobs />} />
                                        <Route exact path="giden-isler" element={<GoingJobs />} />
                                    </Route>
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama" />}>
                                    <Route exact path="raporlama" element={<Report />} />
                                </Route>
                                {/**/}
                                <Route element={<PermissionRoute permission="view-raporlama-gelir-analizi" />}>
                                    <Route exact path="raporlama/gelir-analizi" element={<IncomeAnalysis />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama-gider-analizi" />}>
                                    <Route exact path="raporlama/gider-analizi" element={<ExpenseAnalysis />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama-tedavi-analizi" />}>
                                    <Route exact path="raporlama/tedavi-analizi" element={<TreatmentAnalysis />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama-tedavi-sayac-analizi" />}>
                                    <Route exact path="raporlama/tedavi-sayac-analizi" element={<TreatmentCounterAnalysis />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama-hasta-analizi" />}>
                                    <Route exact path="raporlama/hasta-analizi" element={<PatientAnalysis />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama-laboratuvar-analizi" />}>
                                    <Route exact path="raporlama/laboratuvar-analizi" element={<LaboratoryAnalysis />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama-borc-analizi" />}>
                                    <Route exact path="raporlama/borc-analizi" element={< DebtAnalysis />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama-hakedis-analizi" />}>
                                    <Route exact path="raporlama/hakedis-analizi" element={<AllowanceAnalysis />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama-planlama-analizi" />}>
                                    <Route exact path="raporlama/planlama-analizi" element={<PlanningAnalysis />} />
                                </Route>
                                <Route element={<PermissionRoute permission="view-raporlama-defter-analizi" />}>
                                    <Route exact path="raporlama/defter-analizi" element={<NotebookAnalysis />} />
                                </Route>
                                {/* <Route element={<PermissionRoute permission="view-raporlama-faturalar" />}>
                                    <Route exact path="raporlama/faturalar" element={<Bills />} />
                                </Route> */}


                                <Route exact path="tanimlamalar" element={<DefinitionLayout />}>
                                    <Route element={<PermissionRoute permission="view-tanimlamalar" />}>
                                        <Route exact path="doktor" element={<DefinitionDoktor />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-yetkiler" />}>
                                        <Route exact path="kullanici-izinleri" element={<SettingsUserPermission />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-tarifeler" />}>
                                        <Route exact path="tarife" element={<DefinitionTarife />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-diagnozlar" />}>
                                        <Route exact path="diagnoz" element={<DefinitionDiagnoz />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-randevu-tedaviler" />}>
                                        <Route exact path="randevu-tedavi" element={<DefinitionRandevuTedavi />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-ilaclar" />}>
                                        <Route exact path="ilac" element={<DefinitionIlac />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-laboratuvar-islem" />}>
                                        <Route exact path="laboratuvar" element={<DefinitionLaboratuvar />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-endikasyonlar" />}>
                                        <Route exact path="endikasyon" element={<DefinitionEndikasyon />} />
                                    </Route>
                                </Route>

                                <Route element={<PermissionRoute permission="view-stok" />}>
                                    <Route exact path="stoklar" element={<Stocks />} />
                                    <Route element={<PermissionRoute permission="view-stok-detay" />}>
                                        <Route exact path="stok-hareketleri/:stockId" element={<MovementsStock />} />
                                    </Route>
                                </Route>

                                <Route element={<PermissionRoute permission="view-kullanicilar" />}>
                                    <Route exact path="kullanicilar" element={<Users />} />
                                </Route>

                                <Route element={<PermissionRoute permission="view-loglar" />}>
                                    <Route exact path="loglar" element={<Loglar />} />
                                </Route>

                                <Route exact path="ayarlar" element={<SettingsLayout />}>
                                    <Route element={<PermissionRoute permission="view-ayarlar" />}>
                                        <Route exact path="randevu" element={<SettingsRandevu />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-e-fatura-ayarlar" />}>
                                        <Route exact path="efatura-bilgileri" element={<SettingsFirmaEfatura />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-iletisim-ayarlar" />}>
                                        <Route exact path="iletisim" element={<SettingsIletisim />} />
                                    </Route>
                                    <Route element={<PermissionRoute permission="view-genel-ayarlar" />}>
                                        <Route exact path="genel" element={<SettingsGenel />} />
                                    </Route>
                                </Route>

                            </Route>

                        </Route>
                        {/* EmptyLayout Routes */}
                        <Route path='/' element={<EmptyLayout />}>
                            <Route element={<IsAuthenticatedRoute />}>
                                <Route exact path="login" element={<Login />} />
                            </Route>
                        </Route>

                        <Route element={<EmptyLayout />}>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                    :
                    <LoadingPage />
            }
        </BrowserRouter>
    );
}

const mapStateToProps = (state) => {
    return {
        errorState: state.authError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeLoginInfo: () => {
            dispatch(RemoveLoginInfo())
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);