


import * as yup from 'yup';  

const CurrentAccountValidationSchema = () => {
    return yup.object().shape({

        cari_adi: yup.string()
                  .min(6,"Cari adi en az 6 karakter olmalıdır.")
                  .max(50,"Cari adi en fazla 50 karakter olmalıdır.")
                  .required("Lütfen geçerli Cari adi bilgisi giriniz."),

        

   });
}   

export default CurrentAccountValidationSchema;