import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "assets/css/pages/expense.css";
import { Button as ButtonM} from "@mui/material";
import { Card } from "reactstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { InputAdornment, FormControl, TextField, MenuItem } from '@mui/material';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaTimes,
    FaEdit,
    FaTrash,
    FaRegChartBar,
    FaPills,
    FaLiraSign,
} from "react-icons/fa";
import CreateServiceReceivedValidationSchema from "business-layer/CreateServiceReceivedValidationSchema";
import { useFormik } from "formik";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {tr} from 'date-fns/locale'
import CurrencyMask from 'Utils/CurrencyMask';
import AxiosCrudActions from "services/AxiosCrudCore";
import GetDateWithFormat from "extensions/GetDateWithFormat";
import CreatePaymentMadeValidationSchema from "business-layer/CreatePaymentMadeValidationSchema";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import GetTrDate from "extensions/GetTRDate";
import ConvertStringToFloat from "extensions/ConvertStringToFloat";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const ExpenseDetail = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const params = useParams();
    const {state} = useLocation();
    const [cariId, setCariId] = useState(params?.cariId);
    const [totalDebt, setTotalDebt] = useState(0);
    const [remainingDebt, setRemainingDebt] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [overpaid, setOverpaid] = useState(0);
    const [paymentsMade, setPaymentsMade] = useState([]);
    const [servicesReceived, setServicesReceived] = useState([]);
    const [updateTables, setUpdateTables] = useState(false);
    // serviceReceived state
    const [serviceReceivedId, setServiceReceivedId] = useState(0);
    const [paymentMadeId, setPaymentMadeId] = useState(0);
    const [loadingReceived, setLoadingReceived] = useState(true);
    const [loadingPayment, setLoadingPayment] = useState(true);
    const [filteredReceived, setFilteredReceived] = useState([]);
    const [filteredPayment, setFilteredPayment] = useState([]);

    const [isProcesssingReceived, setIsProcessingReceived] = useState(false);
    const [isProcesssingPayment, setIsProcessingPayment] = useState(false);
    

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [paymentsModal, setPaymentsModal] = useState(false);
    const paymentToggle = () => setPaymentsModal(!paymentsModal);
    // swal
    const MySwal = withReactContent(Swal)

     // modal state for add and edit services 
     const initialServiceReceived = {
        tarih: new Date(),
        tutar: 0,
        aciklama: '',
        tur: 0,
        cari_id: cariId
    }
     // yup schema
     let serviceReceivedValidation = CreateServiceReceivedValidationSchema();
     // formik schema
     const serviceReceivedFormik = useFormik({
         initialValues: initialServiceReceived,
         validationSchema: serviceReceivedValidation,
         onSubmit: values => {
            values.tarih = GetDateWithFormat(values?.tarih);
            setIsProcessingReceived(true);
             if (serviceReceivedId === 0) {
                 AxiosCrudActions.CreateAsync('giderler', values).then(result => {
                     setIsProcessingReceived(false);
                     if (result?.data?.status) {
                         setUpdateTables(!updateTables);
                         toggle();
                     }
                 });
             } else if (serviceReceivedId > 0) {
                 AxiosCrudActions.UpdateAsync(`giderler/${serviceReceivedId}`, values).then(result => {
                     setIsProcessingReceived(false);
                     if (result?.data?.status) {
                        setUpdateTables(!updateTables);
                        toggle();
                     }
                 });
             }
         },
     });

     const addNewService = (event) => {
        event.preventDefault();
        serviceReceivedFormik.setValues({
            tarih: new Date(),
            tutar: 0,
            aciklama: '',
            tur: 0,
            cari_id: cariId
        },false);
        serviceReceivedFormik.setErrors({});
        setServiceReceivedId(0);
        toggle();
     }


    const editServices = (e, values = null) => {
        e.preventDefault();
        if(values !== null){
            serviceReceivedFormik.setValues({
                tarih: values?.tarih ? values.tarih : new Date(),
                tutar: values?.tutar ? ConvertStringToFloat(values.tutar) : 0,
                aciklama: values?.aciklama ? values.aciklama : '',
                tur: 0,
                cari_id: cariId
            },true);
            serviceReceivedFormik.setErrors({});
            setServiceReceivedId(values?.id);
            toggle();
        }
    }

    const deleteServices = (e, id = null) => {
        e.preventDefault();
        e.stopPropagation()
        if(id !== null && id > 0){
            MySwal.fire({
                title: "Kayıt silinsin mi?",
                text: "silme işlemini gerçekleştirdiğinizde geri alamazsınız!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Hayır,Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    AxiosCrudActions.DeleteAsync('/giderler', id)
                        .then(result => {
                            if (result?.status === 200 && result?.data?.status) {
                                setUpdateTables(!updateTables);
                            }
                        });

                }
            })
        }
    }


    //payment add edit and delete 
     // modal state for add and edit services 
     const initialPaymentInfo = {
        tarih: new Date(),
        tutar: 0,
        aciklama: '',
        odeme_sekli: 1,
        tur: 1,
        cari_id: cariId
    }
     // yup schema
     let paymentMadeValidation = CreatePaymentMadeValidationSchema();
     // formik schema
     const paymentMadeFormik = useFormik({
         initialValues: initialPaymentInfo,
         validationSchema: paymentMadeValidation,
         onSubmit: values => {
            values.tarih = GetDateWithFormat(values?.tarih);
            setIsProcessingPayment(true);
             if (paymentMadeId === 0) {
                 AxiosCrudActions.CreateAsync('giderler', values).then(result => {
                     setIsProcessingPayment(false);
                     if (result?.data?.status) {
                         setUpdateTables(!updateTables);
                         paymentToggle();
                     }
                 });
             } else if (paymentMadeId > 0) {
                 AxiosCrudActions.UpdateAsync(`giderler/${paymentMadeId}`, values).then(result => {
                     setIsProcessingPayment(false);
                     if (result?.data?.status) {
                        setUpdateTables(!updateTables);
                        paymentToggle();
                     }
                 });
             }
         },
     });

     const addNewPaymentMade = (event) => {
        event.preventDefault();
        paymentMadeFormik.setValues({
            tarih: new Date(),
            tutar: 0,
            aciklama: '',
            odeme_sekli: 1,
            tur: 1,
            cari_id: cariId
        },false);
        paymentMadeFormik.setErrors({});
        setPaymentMadeId(0);
        paymentToggle();
     }


    const editPaymentMade = (e, values = null) => {
        e.preventDefault();
        if(values !== null){
            paymentMadeFormik.setValues({
                tarih: values?.tarih ? values.tarih : new Date(),
                tutar: values?.tutar ? ConvertStringToFloat(values.tutar) : 0,
                aciklama: values?.aciklama ? values.aciklama : '',
                odeme_sekli: values?.odeme_sekli?.id ? values.odeme_sekli.id : 1,
                tur: 1,
                cari_id: cariId
            },true);
            paymentMadeFormik.setErrors({});
            setPaymentMadeId(values?.id);
            paymentToggle();
        }
    }

    const deletePaymentMade = (e, id = null) => {
        e.preventDefault();
        e.stopPropagation()
        if(id !== null && id > 0){
            MySwal.fire({
                title: "Kayıt silinsin mi?",
                text: "silme işlemini gerçekleştirdiğinizde geri alamazsınız!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Hayır,Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    AxiosCrudActions.DeleteAsync('/giderler', id)
                        .then(result => {
                            if (result?.status === 200 && result?.data?.status) {
                                setUpdateTables(!updateTables);
                            }
                        });

                }
            })
        }
    }
    // payment add edit adn delet end

    const currentMovements = async () => {
        if(cariId !== null && cariId > 0){
            await axios.get('giderler', {params: {cari_id : cariId}})
                            .then(res => {
                                if(res.status === 200 ){
                                    let payments = res?.data?.data.filter(obj => {
                                        return obj.tur === 1
                                    })
                                    let services = res?.data?.data.filter(obj => {
                                        return obj.tur === 0
                                    })
                                    setServicesReceived(services);
                                    setPaymentsMade(payments);
                                    setTotalDebt(res?.data?.toplamBorc);
                                    setRemainingDebt(res?.data?.kalanBorc);
                                    setTotalPaid(res?.data?.toplamOdenen);
                                    setOverpaid(res?.data?.fazlaOdenen);
                                    setFilteredReceived(services);  
                                    setFilteredPayment(payments);
                                }
                        }).catch(e => {
                                setServicesReceived([]);
                                setPaymentsMade([]);
                                setTotalDebt(0);
                                setRemainingDebt(0);
                                setTotalPaid(0);
                                setOverpaid(0);
                                setFilteredReceived([]);  
                                setFilteredPayment([]);
                        })
        }
        setLoadingReceived(false);
        setLoadingPayment(false);
    }

    useEffect(() => {
        currentMovements();
    },[updateTables]);

    return (
        <>
        {can('view-giderler') ?  
            <div className="expense-container">
                {
                    isProcesssingReceived || isProcesssingPayment ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
                }
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <span className="d-flex align-items-center">
                    <strong className="fs-5 fw-normal text text-secondary">
                        {state?.cari_adi} - {state?.hesap_tipi}  &nbsp;
                    </strong>
                    </span>
                    <ButtonM color="error" variant="outlined" startIcon={<FaRegChartBar />}>
                        Raporlama
                    </ButtonM>
                </div>

                <div className="row">
                    {/* alinan mal ve hizmetler */}
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                            <div className="d-flex justify-content-between align-items-center mb-3" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                                <span className="d-flex align-items-center" style={{fontSize: '14px',fontWeight: 'bold', color: '#1172b6', opacity: '0.8'}}>
                                    <strong>
                                        Alınan Mal-Hizmet  &nbsp;
                                    </strong>
                                </span>
                                <ButtonM color="success" variant="outlined" disabled={!can('create-giderler')} onClick={addNewService} startIcon={<FaPlus />}>
                                    Yeni Hizmet
                                </ButtonM>
                                <Modal isOpen={modal} 
                                    toggle={toggle} 
                                    size="md"
                                    modalTransition={{ timeout: 1 }}
                                    backdropTransition={{ timeout: 1 }}
                                    >
                                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Yeni Mal-Hizmet Bilgileri</ModalHeader>
                                    <form onSubmit={serviceReceivedFormik.handleSubmit}>
                                        <ModalBody>
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                                                    <FormControl fullWidth className="mt-3">
                                                        <TextField className='form-group'
                                                            type="text"
                                                            name="tutar"
                                                            variant='outlined'
                                                            label="tutar"
                                                            autoComplete="off"
                                                            InputLabelProps={{ style: { color: serviceReceivedFormik.errors.tutar && serviceReceivedFormik.touched.tutar ? '#e53e3e' : '#1976d2' } }}
                                                            onChange={serviceReceivedFormik.handleChange}
                                                            value={serviceReceivedFormik.values.tutar}
                                                            onBlur={serviceReceivedFormik.handleBlur}
                                                            size='small'
                                                            InputProps={{
                                                                inputComponent: CurrencyMask,
                                                                startAdornment: <InputAdornment position="start" ><FaLiraSign /></InputAdornment>,
                                                            }}
                                                            error={serviceReceivedFormik.errors.tutar && serviceReceivedFormik.touched.tutar}
                                                            helperText={serviceReceivedFormik.errors.tutar && serviceReceivedFormik.touched.tutar && <span className='cstm-input-error-validation-msg'>{serviceReceivedFormik.errors.tutar}</span>}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div>
                                                    <FormControl fullWidth>
                                                    <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                                            <DesktopDatePicker
                                                                variant='outlined'
                                                                label="Tarih"
                                                                inputFormat="dd-MM-yyyy"
                                                                minDate={dayjs('01-01-1930')}
                                                                value={serviceReceivedFormik.values.tarih}
                                                                onChange={(newValue) => {
                                                                    serviceReceivedFormik.setFieldValue('tarih', newValue, false);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} 
                                                                                            size="small"
                                                                                            name="tarih"
                                                                                            variant='outlined'
                                                                                            error={serviceReceivedFormik.errors.tarih && serviceReceivedFormik.touched.tarih}
                                                                                            helperText={serviceReceivedFormik.errors.tarih && serviceReceivedFormik.touched.tarih && <span className='cstm-input-error-validation-msg'>{serviceReceivedFormik.errors.tarih}</span>}
                                                                                            />}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </div>
                                                <div>
                                                    <FormControl fullWidth className="mt-3">
                                                        <TextField className='form-group'
                                                            type="text"
                                                            name="aciklama"
                                                            variant='outlined'
                                                            label="Açıklama"
                                                            autoComplete="off"
                                                            InputLabelProps={{ style: { color: serviceReceivedFormik.errors.aciklama && serviceReceivedFormik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                                                            onChange={serviceReceivedFormik.handleChange}
                                                            value={serviceReceivedFormik.values.aciklama}
                                                            onBlur={serviceReceivedFormik.handleBlur}
                                                            size='small'
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                            }}
                                                            error={serviceReceivedFormik.errors.aciklama && serviceReceivedFormik.touched.aciklama}
                                                            helperText={serviceReceivedFormik.errors.aciklama && serviceReceivedFormik.touched.aciklama && <span className='cstm-input-error-validation-msg'>{serviceReceivedFormik.errors.aciklama}</span>}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </ModalBody>

                                        <ModalFooter>
                                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} className="d-flex align-items-center" >
                                                {
                                                    isProcesssingReceived ? 
                                                    (
                                                        <>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                            İşleniyor...
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <FaCloudUploadAlt /> &nbsp;
                                                            {serviceReceivedId > 0 ? "Güncelle" :"Kaydet"}
                                                        </>
                                                    )
                                                }
                                            </Button>{' '}
                                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                                <FaTimes />&nbsp;Vazgeç
                                            </Button>
                                        </ModalFooter>
                                    </form>
                                </Modal>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                {
                                    remainingDebt !== null && !remainingDebt.toString().includes("-") &&
                                    <div className="total-remaining-info-btn">
                                        <span>Kalan Borç: &nbsp;&nbsp;&nbsp;</span>
                                        <span>{remainingDebt}</span>
                                        <span> TRY</span>
                                    </div>
                                }
                                <div className="total-pay-info-btn">
                                    <span>Toplam Borç: &nbsp;&nbsp;&nbsp;</span>
                                    <span>{totalDebt}</span>
                                    <span> TRY</span>
                                </div>
                            </div>
                            {/* table */}
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650}} size="small">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell align="center" width='25%'>Tarih</TableCell>
                                        <TableCell align="center" width='25%'>Açıklama</TableCell>
                                        <TableCell align="center" width='25%'>Tutar</TableCell>
                                        <TableCell align="center" width='25%'>İşlemler</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                    !loadingReceived ?
                                    filteredReceived?.length > 0 ?
                                    servicesReceived.map((row) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center" width='25%'>{GetTrDate(row.tarih)}</TableCell>
                                            <TableCell align="center" width='25%'>{row.aciklama}</TableCell>
                                            <TableCell align="center" width='25%'>{row.tutar}</TableCell>
                                            <TableCell align="center" width='25%'>
                                                <Stack direction="row" spacing={2}>
                                                    <Tooltip title="hizmet düzenle" arrow>
                                                        <ButtonM variant="outlined"
                                                            disabled={!can('edit-giderler')}
                                                            onClick={(e) => editServices(e, row)}
                                                            color="warning"><FaEdit /></ButtonM>
                                                    </Tooltip>
                                                    <Tooltip title="hizmet sil" arrow>
                                                        <ButtonM variant="outlined"
                                                            disabled={!can('delete-giderler')} 
                                                            onClick={(e) => deleteServices(e, row?.id)}
                                                            color="error"><FaTrash /></ButtonM>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )):
                                    <TableRow width="100%">
                                        <TableCell colSpan={5} height={"100px"} align="center">
                                            <CustomNoRowsOverlay />
                                        </TableCell>
                                    </TableRow> 
                                    :
                                    <TableRow width="100%">
                                        <TableCell colSpan={5} height={"100px"} align="center">
                                            <LoadingOverlay />
                                        </TableCell>
                                    </TableRow>
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </div>
                    {/* yapilan odemeler */}
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                            <div className="d-flex justify-content-between align-items-center mb-3" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                                <span className="d-flex align-items-center" style={{fontSize: '14px',fontWeight: 'bold', color: '#1172b6', opacity: '0.8'}}>
                                    <strong>
                                        Yapılan Ödemeler  &nbsp;
                                    </strong>
                                </span>
                                <ButtonM color="success" variant="outlined"  disabled={!can('create-giderler')} onClick={addNewPaymentMade} startIcon={<FaPlus />}>
                                    Yeni Ödeme
                                </ButtonM>
                                <Modal isOpen={paymentsModal} 
                                    toggle={paymentToggle} 
                                    size="md"
                                    modalTransition={{ timeout: 1 }}
                                    backdropTransition={{ timeout: 1 }}
                                    >
                                    <ModalHeader toggle={paymentToggle} style={{ color: "#1172b6", fontWeight: 200 }}>Yeni Ödeme Bilgileri</ModalHeader>
                                    <form onSubmit={paymentMadeFormik.handleSubmit}>
                                        <ModalBody>
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                                                    <FormControl fullWidth className="mt-3">
                                                        <TextField className='form-group'
                                                            type="text"
                                                            name="tutar"
                                                            variant='outlined'
                                                            label="tutar"
                                                            autoComplete="off"
                                                            InputLabelProps={{ style: { color: paymentMadeFormik.errors.tutar && paymentMadeFormik.touched.tutar ? '#e53e3e' : '#1976d2' } }}
                                                            onChange={paymentMadeFormik.handleChange}
                                                            value={paymentMadeFormik.values.tutar}
                                                            onBlur={paymentMadeFormik.handleBlur}
                                                            size='small'
                                                            InputProps={{
                                                                inputComponent: CurrencyMask,
                                                                startAdornment: <InputAdornment position="start" ><FaLiraSign /></InputAdornment>,
                                                            }}
                                                            error={paymentMadeFormik.errors.tutar && paymentMadeFormik.touched.tutar}
                                                            helperText={paymentMadeFormik.errors.tutar && paymentMadeFormik.touched.tutar && <span className='cstm-input-error-validation-msg'>{paymentMadeFormik.errors.tutar}</span>}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div>
                                                    <FormControl fullWidth>
                                                        <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                                            <DesktopDatePicker
                                                                variant='outlined'
                                                                label="Tarih"
                                                                inputFormat="dd-MM-yyyy"
                                                                minDate={dayjs('01-01-1930')}
                                                                value={paymentMadeFormik.values.tarih}
                                                                onChange={(newValue) => {
                                                                    paymentMadeFormik.setFieldValue('tarih', newValue, false);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} 
                                                                                            size="small"
                                                                                            name="tarih"
                                                                                            variant='outlined'
                                                                                            error={paymentMadeFormik.errors.tarih && paymentMadeFormik.touched.tarih}
                                                                                            helperText={paymentMadeFormik.errors.tarih && paymentMadeFormik.touched.tarih && <span className='cstm-input-error-validation-msg'>{paymentMadeFormik.errors.tarih}</span>}
                                                                                            />}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </div>
                                                <div className="mt-4">
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            name='odeme_sekli'
                                                            select
                                                            InputLabelProps={{ style: { color: '#1976d2' } }}
                                                            label="Ödeme Şekli"
                                                            size='small'
                                                            defaultValue={paymentMadeFormik.values?.odeme_sekli ? paymentMadeFormik.values?.odeme_sekli : 1 }
                                                            value={paymentMadeFormik.values?.odeme_sekli ? paymentMadeFormik.values?.odeme_sekli : 1}
                                                            onChange={paymentMadeFormik.handleChange}
                                                            onBlur={paymentMadeFormik.handleBlur}
                                                            InputProps={{
                                                                // startAdornment: <InputAdornment position="start"><FaExclamation /></InputAdornment>,
                                                            }}>
                                                            <MenuItem value={1}>Nakit</MenuItem>
                                                            <MenuItem value={2}>Kredi Kartı</MenuItem>
                                                            <MenuItem value={3}>Havale</MenuItem>
                                                        </TextField>
                                                    </FormControl>
                                                </div>
                                                <div>
                                                    <FormControl fullWidth className="mt-3">
                                                        <TextField className='form-group'
                                                            type="text"
                                                            name="aciklama"
                                                            variant='outlined'
                                                            label="Açıklama"
                                                            autoComplete="off"
                                                            InputLabelProps={{ style: { color: paymentMadeFormik.errors.aciklama && paymentMadeFormik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                                                            onChange={paymentMadeFormik.handleChange}
                                                            value={paymentMadeFormik.values.aciklama}
                                                            onBlur={paymentMadeFormik.handleBlur}
                                                            size='small'
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                            }}
                                                            error={paymentMadeFormik.errors.aciklama && paymentMadeFormik.touched.aciklama}
                                                            helperText={paymentMadeFormik.errors.aciklama && paymentMadeFormik.touched.aciklama && <span className='cstm-input-error-validation-msg'>{paymentMadeFormik.errors.aciklama}</span>}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </ModalBody>

                                        <ModalFooter>     
                                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} className="d-flex align-items-center" >
                                            {
                                                isProcesssingPayment ? 
                                                (
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                        İşleniyor...
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <FaCloudUploadAlt /> &nbsp;
                                                        {paymentMadeId > 0 ? "Güncelle" :"Kaydet"}
                                                    </>
                                                )
                                            }
                                        </Button>{' '}
                                            <Button color="danger" type="button" onClick={paymentToggle} className="d-flex align-items-center">
                                                <FaTimes />&nbsp;Vazgeç
                                            </Button>
                                        </ModalFooter>
                                    </form>
                                </Modal>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                {
                                    overpaid !== null && !overpaid.toString().includes("-") &&
                                    <div className="total-overpaid-info-btn">
                                        <span>Fazla Ödenen: &nbsp;&nbsp;&nbsp;</span>
                                        <span>{overpaid}</span>
                                    <span> TRY</span>
                                </div> 
                                }
                                <div className="total-pay-info-btn">
                                    <span>Toplam Ödenen: &nbsp;&nbsp;&nbsp;</span>
                                    <span>{totalPaid}</span>
                                    <span> TRY</span>
                                </div>
                            </div>
                            {/* table */}
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650}} size="small">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell align="center" width='20%'>Tarih</TableCell>
                                        <TableCell align="center" width='20%'>Ödeme Türü</TableCell>
                                        <TableCell align="center" width='20%'>Açıklama</TableCell>
                                        <TableCell align="center" width='20%'>Tutar</TableCell>
                                        <TableCell align="center" width='20%'>İşlemler</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                    !loadingPayment ?
                                    filteredPayment?.length > 0 ?
                                    paymentsMade.map((row) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center" width='20%'>{GetTrDate(row.tarih)}</TableCell>
                                            <TableCell align="center" width='20%'>{row?.odeme_sekli?.name}</TableCell>
                                            <TableCell align="center" width='20%'>{row.aciklama}</TableCell>
                                            <TableCell align="center" width='20%'>{row.tutar}</TableCell>
                                            <TableCell align="center" width='20%'>
                                                <Stack direction="row" spacing={2}>
                                                    <Tooltip title="ödeme düzenle" arrow>
                                                        <ButtonM variant="outlined"
                                                            disabled={!can('edit-giderler')}
                                                            onClick={(e) => editPaymentMade(e, row)}
                                                            color="warning"><FaEdit /></ButtonM>
                                                    </Tooltip>
                                                    <Tooltip title="ödeme sil" arrow>
                                                        <ButtonM variant="outlined" 
                                                            disabled={!can('delete-giderler')}
                                                            onClick={(e) => deletePaymentMade(e, row?.id)}
                                                            color="error"><FaTrash /></ButtonM>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )):
                                    <TableRow width="100%">
                                        <TableCell colSpan={5} height={"100px"} align="center">
                                            <CustomNoRowsOverlay />
                                        </TableCell>
                                    </TableRow> 
                                    :
                                    <TableRow width="100%">
                                        <TableCell colSpan={5} height={"100px"} align="center">
                                            <LoadingOverlay />
                                        </TableCell>
                                    </TableRow>
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </div>
                </div>
            
            </div>
         :<NotPermission/>}
         </>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(ExpenseDetail);
