import { TabPanel } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "assets/css/pages/patient-detail.css";
import { Button as ButtonM} from "@mui/material";
import { Card } from "reactstrap";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper}  from '@mui/material';
import axios from 'axios';
import {FaPlus} from "react-icons/fa";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const PatientAppointment = (props) => {
    const {auth} = props;
    const can = (permission) => {
      return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const navigate = useNavigate();
    const params = useParams();
    const patientId = params?.patientId;
    const [pastAppointments, setPastAppointments] = useState([]);
    const [futureAppointments, setFutureAppointments] = useState([]);
    const [patientName, setPatientName] = useState('');

    const [loadingPastAppointment, setLoadingPastAppointment] = useState(true);
    const [loadingFutureAppointment, setLoadingFutureAppointment] = useState(true);

    const [filteredPastAppointment, setFilteredPastAppointment] = useState([]);
    const [filteredFutureAppointment, setFilteredFutureAppointment] = useState([]);

    const newAppointment = (e) => {
        e.preventDefault();
        navigate('/randevular', {state: {hasta_id : patientId}});
    }

    const getAppointmentsByPatientId = async () => {
        if(patientId !== null && patientId > 0){
            await axios.get(`/hasta-randevular/${patientId}`)
                            .then(res => {
                                if(res.status === 200 ){
                                    setPastAppointments(res?.data?.gecmis_randevular);
                                    setFutureAppointments(res?.data?.gelecek_randevular);
                                    setPatientName(res?.data?.hasta)
                                    setFilteredPastAppointment(res?.data?.gecmis_randevular)
                                    setFilteredFutureAppointment(res?.data?.gelecek_randevular)
                                }
                        }).catch(e => {
                            setPastAppointments([]);
                            setFutureAppointments([]);
                            setPatientName('');
                            setLoadingPastAppointment(false);
                            setLoadingFutureAppointment(false);
                            setFilteredPastAppointment([])
                            setFilteredFutureAppointment([])
                        })
                
        }
        setLoadingPastAppointment(false);
        setLoadingFutureAppointment(false);
    }

    useEffect(() => {
        getAppointmentsByPatientId();
    },[]);

    return (
        <>{ can('view-hastalar-randevu') ?
        <TabPanel value="7" className="patient-detail-container">
            <div className="row">
                {/* Geçmis Randevular */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                        <div className="d-flex justify-content-between align-items-center mb-3" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                            <span className="d-flex align-items-center" style={{fontSize: '14px',fontWeight: 'bold', color: '#1172b6', opacity: '0.8'}}>
                                <strong>
                                    Geçmiş Randevular  &nbsp;
                                </strong>
                            </span>
                            <ButtonM color="success" variant="outlined" onClick={newAppointment} startIcon={<FaPlus />}>
                                Yeni Randevu
                            </ButtonM>
                        </div>
                        {/* table */}
                        <TableContainer component={Paper} style={{paddingBottom: '10px'}}>
                            <Table sx={{ minWidth: 700}} size="small">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="center" width='20%'>Doktor</TableCell>
                                    <TableCell align="center" width='20%'>Tedavi</TableCell>
                                    <TableCell align="center" width='25%'>Tarih</TableCell>
                                    <TableCell align="center" width='15%'>Durum</TableCell>
                                    <TableCell align="center" width='20%'>Not</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                !loadingPastAppointment ?
                                filteredPastAppointment?.length > 0 ?
                                pastAppointments.map((row, index) => (
                                    <TableRow key={index} sx={{ 
                                          '&:last-child td, &:last-child th': { border: 0 } ,
                                          '& tr, & td, & th' : {backgroundColor: (row?.durum === 'Gelmedi' ? '#ffa6a7' : '#fff')},
                                          '& td' : {color: (row?.durum === 'Gelmedi' ? 'white' : '#000')}, 
                                          '& *' : {fontSize: '12px'} 
                                        }} >
                                        <TableCell align="center" width='20%'>{row?.doktor}</TableCell>
                                        <TableCell align="center" width='20%'>{row?.tedavi}</TableCell>
                                        <TableCell align="center" width='25%'>{GetTrFullDateAndTimeWithFormat(row?.tarih)}</TableCell>
                                        <TableCell align="center" width='15%'>{row?.durum}</TableCell>
                                        <TableCell align="center" width='20%'>{row?.not}</TableCell>
                                    </TableRow>
                                )):
                                <TableRow width="100%">
                                    <TableCell colSpan={5} height={"100px"} align="center">
                                        <CustomNoRowsOverlay />
                                    </TableCell>
                                </TableRow> 
                                :
                                <TableRow width="100%">
                                    <TableCell colSpan={5} height={"100px"} align="center">
                                        <LoadingOverlay />
                                    </TableCell>
                                </TableRow>
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </div>
                {/* gelecek Randevular */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                        <div className="d-flex justify-content-between align-items-center mb-3" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                            <span className="d-flex align-items-center" style={{fontSize: '14px',fontWeight: 'bold', color: '#1172b6', opacity: '0.8'}}>
                                <strong>
                                    Gelecek Randevular
                                </strong>
                            </span>
                        </div>
                        <TableContainer component={Paper} style={{paddingBottom: '10px'}}>
                            <Table sx={{ minWidth: 650}} size="small">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="center" width='20%'>Doktor</TableCell>
                                    <TableCell align="center" width='20%'>Tedavi</TableCell>
                                    <TableCell align="center" width='25%'>Tarih</TableCell>
                                    <TableCell align="center" width='15%'>Durum</TableCell>
                                    <TableCell align="center" width='20%'>Not</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                ! loadingFutureAppointment ?
                                filteredFutureAppointment?.length > 0 ?
                                futureAppointments.map((row) => (
                                    <TableRow key={row.id} sx={{ 
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '& *' : {fontSize: '12px'} 
                                             }}>
                                        <TableCell align="center" width='20%'>{row?.doktor}</TableCell>
                                        <TableCell align="center" width='20%'>{row?.tedavi}</TableCell>
                                        <TableCell align="center" width='25%'>{GetTrFullDateAndTimeWithFormat(row?.tarih)}</TableCell>
                                        <TableCell align="center" width='15%'>{row?.durum}</TableCell>
                                        <TableCell align="center" width='20%'>{row?.not}</TableCell>
                                    </TableRow>
                                )):
                                <TableRow width="100%">
                                    <TableCell colSpan={5} height={"100px"} align="center">
                                        <CustomNoRowsOverlay />
                                    </TableCell>
                                </TableRow> 
                                :
                                <TableRow width="100%">
                                    <TableCell colSpan={5} height={"100px"} align="center">
                                        <LoadingOverlay />
                                    </TableCell>
                                </TableRow>
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </div>
            </div>
        </TabPanel>
         :<NotPermission/>}</>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(PatientAppointment);

