import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';


const IsAuthenticatedRoute = (props) => {
    const {auth} = props;
    return auth.isLoggedIn ? <Navigate to="/" /> : <Outlet /> ;
}

const mapStateToProps = (state) => {
    return {
        auth: state.authState,
    }
}

export default connect(mapStateToProps, null)(IsAuthenticatedRoute);
