import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import "assets/css/pages/patients.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import { FaPlus, FaEdit, FaTrash, FaSearch } from 'react-icons/fa';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import GetTrDate from "extensions/GetTRDate";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";


const Patients = (props) => {
    const { auth } = props;
    const can = (permission) => {
        return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
    }

    // console.log(auth?.user?.user_permissions);
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const navigate = useNavigate();
    const columns = [
        {
            field: "id",
            headerName: "ID",
            align: "center",
            flex: 1,
            center: "center",
            headerAlign: "center",
            minWidth: 100,
            maxWidth: 100,
            hideable: true,
            editable: false,
        },
        {
            field: "ad_soyad",
            headerName: "Ad Soyad",
            minWidth: 180,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "tc_no",
            headerName: "TC No",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "tel_no",
            headerName: "Tel No",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "kayit_tarihi",
            headerName: "Kayıt Tarihi",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: true, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return GetTrFullDateAndTimeWithFormat(params.row.kayit_tarihi)
            },
            valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params.row.kayit_tarihi)}`,
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 250,
            headerAlign: "center",
            align: "center",
            flex: 1,
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const editPatient = (e) => {
                    e.preventDefault();
                    e.stopPropagation()
                    const editURL = `/hastalar/duzenle/${params.row.id}`;
                    navigate(editURL, { state: { data: params.row } });
                };

                const deletePatient = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: 'Emin misin?',
                        text: "Bunu geri alamazsınız!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, Sil',
                        cancelButtonText: 'Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync('/hastalar', params.row.id)
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });

                        }
                    })
                };

                const detailPatient = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`/hastalar/detay/${params.row.id}`, { state: { data: params.row.id, patientData: params.row } });
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="hasta detay" arrow>
                            <Button variant="outlined" onClick={detailPatient} disabled={!can('view-hastalar-tedavi')} tooltip="hasta detay" color="info"><FaSearch /></Button>
                        </Tooltip>
                        <Tooltip title="hasta düzenle" arrow>
                            <Button variant="outlined" onClick={editPatient} disabled={!can('edit-hastalar')} color="warning"><FaEdit /></Button>
                        </Tooltip>
                        <Tooltip title="hasta sil" arrow>
                            <Button variant="outlined" onClick={deletePatient} disabled={!can('delete-hastalar')} color="error"><FaTrash /></Button>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];

    // state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);




    // side effect
    useEffect(() => {

        axios.get("hastalar", {
            params: {
                allpatients: auth?.user?.user_permissions?.filter((f) => f === 'view-all-hastalar')?.length ? 1 : 0,
                filterModel: JSON.stringify(filterModel),
                pageSize: pageSize,
                page: page,
                sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
            }
        }).then((res) => {

            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    setData(res.data.data);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, [sortModel, filterModel, pageSize, page, updateDataGrid]);


    return (
        <>
            {can('view-hastalar') ?
                <div className="patients-container">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center">
                            <strong className="fs-5 fw-normal text text-secondary">Tüm Hastalar &nbsp;</strong>
                        </span>
                        <Button color="success" variant="outlined" disabled={!can('create-hastalar')} onClick={() => { navigate('/hastalar/ekle') }} startIcon={<FaPlus />}>
                            Yeni hasta
                        </Button>
                    </div>
                    <ThemeProvider theme={theme}>
                        <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                            <DataGrid
                                autoHeight={data.length > 0 ? true : false}
                                sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                loading={isLoading}
                                rows={data}
                                columns={columns}
                                // pagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowCount={totalCount} //serverdan gelen data total count gelecek
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                pagination
                                paginationMode="server"
                                // sorting
                                sortModel={sortModel}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                filterModel={filterModel}
                                filterMode="server"
                                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                // selection checkbox
                                //checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                disableSelectionOnClick //disable row selection
                                // features
                                experimentalFeatures={{ newEditingApi: true }}
                                // toolbar components
                                components={{
                                    Toolbar: GridToolbar,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    LoadingOverlay: LoadingOverlay
                                }}
                                disableColumnFilter
                                // component props
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: {
                                            fields: ["ad_soyad", "tc_no", "tel_no", "kayit_tarihi"],
                                            fileName: "Hastalar",
                                            hideFooter: true,
                                            hideToolbar: true,
                                            disableToolbarButton: true,
                                        },
                                        csvOptions: {
                                            disableToolbarButton: false,
                                            fields: ["ad_soyad", "tc_no", "tel_no", "kayit_tarihi"],
                                            fileName: 'Hastalar',
                                            utf8WithBom: true,
                                        }, //sadece firstname ve lastName alanını alır.
                                        excelOptions: { disableToolbarButton: true },
                                        // quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Box>
                    </ThemeProvider>
                </div>
                : <NotPermission />}
        </>
    );
};
const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};
export default connect(authPermissions)(Patients);





