import { TabPanel } from "@mui/lab";
import React, { useEffect, useState } from "react";
import AxiosCrudActions from "services/AxiosCrudCore";
import { useFormik } from 'formik';
import {
    InputAdornment, FormControl, TextField, Button as ButtonM,Autocomplete
} from '@mui/material';
import {
    FaHashtag, FaPhoneAlt, FaMobileAlt, FaFax,FaSave, FaEnvelopeOpen,FaEnvelopeOpenText,FaRegEdit,
    FaMapMarkedAlt, FaRegBuilding,FaTelegramPlane,FaHeading,FaUser,FaKey,FaLink,FaBuilding
} from 'react-icons/fa';
import SettingsCompanyEBillsValidationSchema from "business-layer/SettingsCompanyEBillsValidationSchema";
import axios from "axios";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import CustomPhoneMask from 'Utils/PhoneMask';
import NumberMask from 'Utils/NumberMask';
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

function SettingsFirmaEfatura(props) {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isProcesssing, setIsProcessing] = useState(false);
    const [lockForm, setLockForm] = useState(true);

    const [eFaturaAyar, setEFaturaAyar] = useState('');

    const [cities, setCities] = useState([]);
    const [city, setCity] = useState('');
    const [districts, setDistricts] = useState([]);
    const [district_id, setDistrictId] = useState('');


    const formLock = () => { setLockForm(!lockForm); setActive(!active) };

    const getEFaturaAyar = async () => {
        try {
            let data = await axios.get("e-fatura-ayar");
            if (data.status === 200) {
                let result = data.data.data;
                setEFaturaAyar(result);
                setCity(result.il);
                setDistrictId(result.ilce);
                formik.setFieldValue("unvan", result?.unvan, true);
                formik.setFieldValue("kullanici_adi", result?.kullanici_adi, true);
                //formik.setFieldValue("sifre", result?.sifre, true);
                formik.setFieldValue("telefon", result?.telefon, true);
                formik.setFieldValue("email", result?.email, true);
                formik.setFieldValue("adres", result?.adres, true);
                formik.setFieldValue("posta_kodu", result?.posta_kodu, true);
                formik.setFieldValue("bina_no", result?.bina_no, true);
                formik.setFieldValue("vergi_dairesi", result?.vergi_dairesi, true);
                formik.setFieldValue("vkn", result?.vkn, true);
                formik.setFieldValue("mersis_no", result?.mersis_no, true);
                formik.setFieldValue("ticaret_sicil_no", result?.ticaret_sicil_no, true);
                setLoading(false);
            } else {
                setEFaturaAyar('');
                setLoading(false);
            }
        } catch (error) {
            setEFaturaAyar('');
            setLoading(false);
        }
    };

    const getCities = async () => {
        try {
            let data = await axios.get("cities");
            if (data.status === 200) {
                setCities(data.data.data);
            } else {
                setCities([]);
            }
        } catch (error) {
            setCities([]);
        }
    };

    const getDistricts = async (city_id) => {
        if (city_id !== undefined && city_id !== null && city_id > 0) {
            try {
                let data = await axios.get(`cities/${city_id}`);
                if (data.status === 200) {
                    setDistricts(data.data.data);
                } else {
                    setDistricts([]);
                }
            } catch (error) {
                setDistricts([]);
            }
        } else {
            setDistricts([]);
        }
    };

    //yup schema
    let companyEBillsValidationSchema = SettingsCompanyEBillsValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: {
            unvan: '',
            kullanici_adi:'',
            sifre:'',
            telefon: eFaturaAyar?.telefon,
            email: '',
            site_url: '',
            adres: '',
            il_id: '',
            ilce_id: '',
            posta_kodu: '',
            bina_adi: '',
            bina_no: '',
            vergi_dairesi: '',
            vkn: '',
            mersis_no: '',
            ticaret_sicil_no: '',
        },
        validationSchema: companyEBillsValidationSchema,
        onSubmit: values => {
            setIsProcessing(true);
            values.unvan = formik?.values?.unvan ? formik.values.unvan:eFaturaAyar?.unvan;
            values.kullanici_adi = formik?.values?.kullanici_adi ? formik.values.kullanici_adi:eFaturaAyar?.kullanici_adi;
            values.sifre = formik?.values?.sifre ? formik.values.sifre:eFaturaAyar?.sifre;
            values.telefon = formik?.values?.telefon ? formik.values.telefon:eFaturaAyar?.telefon;
            values.email = formik?.values?.email ? formik.values.email:eFaturaAyar?.email;
            values.site_url = formik?.values?.site_url ? formik.values.site_url:eFaturaAyar?.site_url;
            values.adres = formik?.values?.adres ? formik.values.adres:eFaturaAyar?.adres;
            values.il_id = formik?.values?.il_id ? formik.values.il_id : city?.id;
            values.ilce_id = formik?.values?.ilce_id ? formik.values.ilce_id : district_id?.id;
            values.posta_kodu = formik?.values?.posta_kodu ? formik.values.posta_kodu:eFaturaAyar?.posta_kodu;
            values.bina_adi = formik?.values?.bina_adi ? formik.values.bina_adi:eFaturaAyar?.bina_adi;
            values.bina_no = formik?.values?.bina_no  ? formik.values.bina_no:eFaturaAyar?.bina_no;
            values.vergi_dairesi = formik?.values?.vergi_dairesi ? formik.values.vergi_dairesi:eFaturaAyar?.vergi_dairesi;
            values.vkn = formik?.values?.vkn ? formik.values.vkn:eFaturaAyar?.vkn;
            values.mersis_no = formik?.values?.mersis_no ? formik.values.mersis_no:eFaturaAyar?.mersis_no;
            values.ticaret_sicil_no = formik?.values?.ticaret_sicil_no ? formik.values.ticaret_sicil_no:eFaturaAyar?.ticaret_sicil_no;
            AxiosCrudActions.CreateAsync(`e-fatura-ayar`, values).then((result) => {
               setIsProcessing(false);
            });
        },
    });




    useEffect(() => {
        if (city?.id) {
            getDistricts(city?.id);
        }
        getCities();
        getEFaturaAyar();
    }, []);


  
    return (
        <>
            {can('view-e-fatura-ayarlar') ?  
                <TabPanel value="2">
                {
                    loading ?
                        <div className="doctor-page-loading">
                            <LoadingOverlay />
                        </div>
                        :
                        <div className="d-doctor-container">
                            {
                                isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
                            }
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="d-flex align-items-center">
                                    <strong className="fs-5 fw-normal text text-secondary">E-Fatura Ayarları&nbsp;</strong>
                                </span>
                            </div>
                            <form  onSubmit={formik.handleSubmit}  className="col-xl-12 col-lg-12  col-md-12 col-sm-12 col-12">
                                <div className="row" >
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="unvan"
                                                variant='outlined'
                                                label="Unvan *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setEFaturaAyar(oldData => ({ ...oldData, unvan: e.target.value }));
                                                    formik.setFieldValue("unvan", e.target.value, false);
                                                }}
                                                value={eFaturaAyar?.unvan}
                                                onBlur={formik.handleBlur}
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputLabelProps={{ style: { color: formik.errors.unvan && formik.touched.unvan ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaHeading /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.unvan && formik.touched.unvan && <span className='cstm-input-error-validation-msg'>{formik.errors.unvan}</span>}
                                                error={formik.errors.unvan && formik.touched.unvan}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="kullanici_adi"
                                                variant='outlined'
                                                label="Kullanıcı Adı *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setEFaturaAyar(oldData => ({ ...oldData, kullanici_adi: e.target.value }));
                                                    formik.setFieldValue("kullanici_adi", e.target.value, false);
                                                }}
                                                value={eFaturaAyar?.kullanici_adi}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.kullanici_adi && formik.touched.kullanici_adi ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaUser /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.kullanici_adi && formik.touched.kullanici_adi && <span className='cstm-input-error-validation-msg'>{formik.errors.kullanici_adi}</span>}
                                                error={formik.errors.kullanici_adi && formik.touched.kullanici_adi}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="password"
                                                name="sifre"
                                                variant='outlined'
                                                label="Şifre *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setEFaturaAyar(oldData => ({ ...oldData, sifre: e.target.value }));
                                                    formik.setFieldValue("sifre", e.target.value, false);
                                                }}
                                                value={eFaturaAyar?.sifre || ""}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.sifre && formik.touched.sifre ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaKey /></InputAdornment>,
                                                }}

                                                // helperText={formik.errors.sifre && formik.touched.sifre && <span className='cstm-input-error-validation-msg'>{formik.errors.sifre}</span>}
                                                // error={formik.errors.sifre && formik.touched.sifre}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="telefon"
                                                variant='outlined'
                                                label="Telefon *"
                                                onChange={formik.handleChange}
                                                value={formik?.values?.telefon}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.telefon && formik.touched.telefon ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    inputComponent: CustomPhoneMask,
                                                    startAdornment: <InputAdornment position="start" ><FaPhoneAlt /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.telefon && formik.touched.telefon && <span className='cstm-input-error-validation-msg'>{formik.errors.telefon}</span>}
                                                error={formik.errors.telefon && formik.touched.telefon}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="email"
                                                variant='outlined'
                                                label="E-Posta *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setEFaturaAyar(oldData => ({ ...oldData, email: e.target.value }));
                                                    formik.setFieldValue("email", e.target.value, false);
                                                }}
                                                value={eFaturaAyar?.email}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.email && formik.touched.email ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaEnvelopeOpen /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.email && formik.touched.email && <span className='cstm-input-error-validation-msg'>{formik.errors.email}</span>}
                                                error={formik.errors.email && formik.touched.email}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="site_url"
                                                variant='outlined'
                                                label="Site URL"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setEFaturaAyar(oldData => ({ ...oldData, site_url: e.target.value }));
                                                    formik.setFieldValue("site_url", e.target.value, false);
                                                }}
                                                value={eFaturaAyar?.site_url}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.site_url && formik.touched.site_url ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaLink /></InputAdornment>,
                                                }}

                                                // helperText={formik.errors.site_url && formik.touched.site_url && <span className='cstm-input-error-validation-msg'>{formik.errors.site_url}</span>}
                                                // error={formik.errors.site_url && formik.touched.site_url}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="adres"
                                                variant='outlined'
                                                label="Adres *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setEFaturaAyar(oldData => ({ ...oldData, adres: e.target.value }));
                                                    formik.setFieldValue("adres", e.target.value, false);
                                                }}
                                                value={eFaturaAyar?.adres}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.adres && formik.touched.adres ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaMapMarkedAlt /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.adres && formik.touched.adres && <span className='cstm-input-error-validation-msg'>{formik.errors.adres}</span>}
                                                error={formik.errors.adres && formik.touched.adres}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                                <Autocomplete
                                                    options={cities}
                                                    loading={true}
                                                    disableClearable
                                                    getOptionLabel={(option) => option.il ? option.il : ""}
                                                    noOptionsText={"Seçenek Bulunamadı"}
                                                    value={city}
                                                    name="il_id"
                                                    onChange={(event, newValue) => {
                                                        event.preventDefault();
                                                        setCity(newValue);
                                                        setDistrictId(null);
                                                        setDistricts([]);
                                                        getDistricts(newValue?.id);
                                                    //  formik.setFieldValue("il_id", newValue?.id, true);
                                                    }}
                                                    size="small"
                                                    disabled={lockForm}
                                                    style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Şehir *"
                                                            InputLabelProps={{ style: { color: formik.errors.il_id && formik.touched.il_id ? '#e53e3e' : '#1976d2' } }}
                                                        />
                                                    )}
                                                    // helperText={formik.errors.il_id && formik.touched.il_id && <span className='cstm-input-error-validation-msg'>{formik.errors.il_id}</span>}
                                                    // error={formik.errors.il_id && formik.touched.il_id}
                                                />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                                <Autocomplete
                                                    options={districts}
                                                    getOptionLabel={(option) => option.ilce ? option.ilce :""}
                                                    noOptionsText={"Lütfen Şehir Seçiniz."}
                                                    disableClearable
                                                    value={district_id}
                                                    name="ilce_id"
                                                    onChange={(event, newValue) => {
                                                        setDistrictId(newValue);
                                                        //formik.setFieldValue("ilce_id", newValue?.id, true);
                                                    }}
                                                    size="small"
                                                    disabled={lockForm}
                                                    style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="İlçe *"
                                                            InputLabelProps={{ style: { color: formik.errors.ilce_id && formik.touched.ilce_id ? '#e53e3e' : '#1976d2' } }}
                                                        />
                                                    )}
                                                    // helperText={formik.errors.ilce_id && formik.touched.ilce_id && <span className='cstm-input-error-validation-msg'>{formik.errors.ilce_id}</span>}
                                                    // error={formik.errors.ilce_id && formik.touched.ilce_id}
                                                />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="posta_kodu"
                                                variant='outlined'
                                                label="Posta Kodu *"
                                                onChange={formik.handleChange}
                                                value={eFaturaAyar?.posta_kodu}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.posta_kodu && formik.touched.posta_kodu ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    inputComponent: NumberMask,
                                                    startAdornment: <InputAdornment position="start" ><FaEnvelopeOpenText /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.posta_kodu && formik.touched.posta_kodu && <span className='cstm-input-error-validation-msg'>{formik.errors.posta_kodu}</span>}
                                                error={formik.errors.posta_kodu && formik.touched.posta_kodu}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="bina_adi"
                                                variant='outlined'
                                                label="Bina Adı"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setEFaturaAyar(oldData => ({ ...oldData, bina_adi: e.target.value }));
                                                    formik.setFieldValue("bina_adi", e.target.value, false);
                                                }}
                                                value={eFaturaAyar?.bina_adi}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.bina_adi && formik.touched.bina_adi ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaBuilding /></InputAdornment>,
                                                }}

                                                // helperText={formik.errors.bina_adi && formik.touched.bina_adi && <span className='cstm-input-error-validation-msg'>{formik.errors.bina_adi}</span>}
                                                // error={formik.errors.bina_adi && formik.touched.bina_adi}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="bina_no"
                                                variant='outlined'
                                                label="Bina No *"
                                                onChange={formik.handleChange}
                                                value={eFaturaAyar?.bina_no}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.bina_no && formik.touched.bina_no ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    inputComponent: NumberMask,
                                                    startAdornment: <InputAdornment position="start" ><FaBuilding /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.bina_no && formik.touched.bina_no && <span className='cstm-input-error-validation-msg'>{formik.errors.bina_no}</span>}
                                                error={formik.errors.bina_no && formik.touched.bina_no}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="vergi_dairesi"
                                                variant='outlined'
                                                label="Vergi Dairesi *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setEFaturaAyar(oldData => ({ ...oldData, vergi_dairesi: e.target.value }));
                                                    formik.setFieldValue("vergi_dairesi", e.target.value, false);
                                                }}
                                                value={eFaturaAyar?.vergi_dairesi}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.vergi_dairesi && formik.touched.vergi_dairesi ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaRegBuilding /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.vergi_dairesi && formik.touched.vergi_dairesi && <span className='cstm-input-error-validation-msg'>{formik.errors.vergi_dairesi}</span>}
                                                error={formik.errors.vergi_dairesi && formik.touched.vergi_dairesi}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="vkn"
                                                variant='outlined'
                                                label="Vergi Kimlik No *"
                                                onChange={formik.handleChange}
                                                value={eFaturaAyar?.vkn}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.vkn && formik.touched.vkn ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    inputComponent: NumberMask,
                                                    startAdornment: <InputAdornment position="start" ><FaHashtag /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.vkn && formik.touched.vkn && <span className='cstm-input-error-validation-msg'>{formik.errors.vkn}</span>}
                                                error={formik.errors.vkn && formik.touched.vkn}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="mersis_no"
                                                variant='outlined'
                                                label="Mersis No *"
                                                onChange={formik.handleChange}
                                                value={eFaturaAyar?.mersis_no}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.mersis_no && formik.touched.mersis_no ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    inputComponent: NumberMask,
                                                    startAdornment: <InputAdornment position="start" ><FaHashtag /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.mersis_no && formik.touched.mersis_no && <span className='cstm-input-error-validation-msg'>{formik.errors.mersis_no}</span>}
                                                error={formik.errors.mersis_no && formik.touched.mersis_no}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4" >
                                            <TextField className='form-group'
                                                type="text"
                                                name="ticaret_sicil_no"
                                                variant='outlined'
                                                label="Ticaret Sicil No *"
                                                onChange={formik.handleChange}
                                                value={eFaturaAyar?.ticaret_sicil_no}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.ticaret_sicil_no && formik.touched.ticaret_sicil_no ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    inputComponent: NumberMask,
                                                    startAdornment: <InputAdornment position="start" ><FaHashtag /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.ticaret_sicil_no && formik.touched.ticaret_sicil_no && <span className='cstm-input-error-validation-msg'>{formik.errors.ticaret_sicil_no}</span>}
                                                error={formik.errors.ticaret_sicil_no && formik.touched.ticaret_sicil_no}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center" style={{ float: "right" }}>
                                    <ButtonM color="warning" variant="outlined"
                                        onClick={formLock}
                                        style={{ color: active ? "#F39D56" : "#888E99", border: active ? "#F39D56 1px solid" : "#888E99 1px solid", marginRight: "10px", }}
                                        startIcon={<FaRegEdit />}
                                    >
                                        Düzelt
                                    </ButtonM>
                                    <ButtonM color="success" variant="outlined" type="submit" disabled={lockForm || ! can('edit-e-fatura-ayarlar')}>
                                        {
                                            isProcesssing ? 
                                            (
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                    İşleniyor...
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <FaSave /> &nbsp;
                                                    {"Kaydet"}
                                                </>
                                            )
                                        }
                                    </ButtonM>
                                </div>
                            </form>
                        </div>
                }
                </TabPanel>
            :<NotPermission/>}
        </>
  )
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
export default connect(authPermissions)(SettingsFirmaEfatura);