import * as yup from 'yup';

const SettingsGeneralValidationSchema = () => {
    return yup.object().shape({

        site_basligi: yup.string()
            .min(2, "Site Başlığı en az 2 karakter olmalıdır.")
            .max(50, "Site Başlığı en fazla 50 karakter olmalıdır.")
            .required("Lütfen geçerli bir Site Başlığı bilgisi giriniz."),

        klinik_no: yup.string()
            .required("Lütfen geçerli bir Klinik No bilgisi giriniz."),



    });
}

export default SettingsGeneralValidationSchema;