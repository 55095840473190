import { TabPanel } from "@mui/lab";
import React, { useEffect, useState } from "react";
import AxiosCrudActions from "services/AxiosCrudCore";
import { useFormik } from 'formik';
import { InputAdornment, FormControl, TextField, Switch, styled, FormControlLabel, FormHelperText, Autocomplete, Button as ButtonM, MenuItem } from '@mui/material';
import { FaQuestionCircle, FaClock, FaEyeDropper, FaSave, FaRegEdit } from 'react-icons/fa';
import axios from "axios";
import TimeHourMask from 'Utils/TimeHourMask';
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import settingsAppointmentValidationSchema from "business-layer/SettingsAppointmentValidationSchema";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const SettingsRandevu = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#1172B6',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#f27963' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const [varsayılanTedaviler, setVarsayılanTedaviler] = useState([]);
    const [randevuAyar, setRandevuAyar] = useState();
    const [varsayılanTedavi, setVarsayılanTedavi] = useState();
    const [loading, setLoading] = useState(true);
    const [lockForm, setLockForm] = useState(true);
    const [active, setActive] = useState(false);
    const [gecmisRd, setGecmisRd] = useState();
    const [tedaviRengi, setTedaviRengi] = useState();
    const [randevuTs, setRandevuTs] = useState();
    const [isProcesssing, setIsProcessing] = useState(false);
    const formLock = () => { setLockForm(!lockForm); setActive(!active) };


    const getVarsayılanTedaviler = async () => {
        try {
            let data = await axios.get("randevu-tedaviler");
            if (data.status === 200) {
                let result = data.data.data;
                let allTreatment = result.map(({ id, aciklama }) => {
                    return { id: id, aciklama: aciklama };
                });
                setVarsayılanTedaviler(allTreatment);
            } else {
                setVarsayılanTedaviler([]);
            }
        } catch (error) {
            setVarsayılanTedaviler([]);
        }
    };

    const getRandevuAyar = async () => {
        try {
            let data = await axios.get("ayarlar/randevu-ayarlari");
            if (data.status === 200) {
                let result = data.data.data;
                setRandevuAyar(result);
                setVarsayılanTedavi(result?.varsayilan_tedavi);
                setGecmisRd(result?.gecmis_randevu_durumu === 1 ? true : false);
                setTedaviRengi(result?.randevu_tedavi_rengi === 1 ? true : false);
                setRandevuTs(result?.randevu_tedavi_suresi === 1 ? true : false);
                formik.setFieldValue("yenileme_suresi", result?.yenileme_suresi, true);
                setLoading(false);
            } else {
                setRandevuAyar('');
                setLoading(false);
            }
        } catch (error) {
            setRandevuAyar('');
            setLoading(false);
        }
    };

    const changeGecmisRd = () => {
        setGecmisRd(!gecmisRd);
    };
    const changeRandevuRengi = () => {
        setTedaviRengi(!tedaviRengi);
    };
    const changeRandevuTs = () => {
        setRandevuTs(!randevuTs);
    };

    let randevuValidationSchema = settingsAppointmentValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: {
            baslangic_saati: '',
            bitis_saati: '',
            zaman_araligi: '',
            varsayilan_tedavi: '',
            yenileme_suresi: '',
            gecmis_randevu_rengi: '',
            gelmeyen_hasta_rengi: '',
            gecmis_randevu_durumu: '',
            randevu_tedavi_rengi: '',
            randevu_tedavi_suresi: '',
        },
        validationSchema: randevuValidationSchema,
        onSubmit: values => {
            setIsProcessing(true);
            values.baslangic_saati = formik?.values?.baslangic_saati ? formik?.values?.baslangic_saati : randevuAyar?.baslangic_saati;
            values.bitis_saati = formik?.values?.bitis_saati ? formik?.values?.bitis_saati : randevuAyar?.bitis_saati;
            values.zaman_araligi = formik?.values?.zaman_araligi ? formik?.values?.zaman_araligi : randevuAyar?.zaman_araligi;
            values.varsayilan_tedavi = varsayılanTedavi ? varsayılanTedavi?.id : randevuAyar?.varsayilan_tedavi;
            values.yenileme_suresi = formik?.values?.yenileme_suresi ? formik?.values?.yenileme_suresi : randevuAyar?.yenileme_suresi;
            values.gecmis_randevu_rengi = formik?.values?.gecmis_randevu_rengi ? formik?.values?.gecmis_randevu_rengi : randevuAyar?.gecmis_randevu_rengi;
            values.gelmeyen_hasta_rengi = formik?.values?.gelmeyen_hasta_rengi ? formik?.values?.gelmeyen_hasta_rengi : randevuAyar?.gelmeyen_hasta_rengi;
            values.randevu_tedavi_rengi = tedaviRengi !== "" ? tedaviRengi === true ? 1 : 0 : randevuAyar?.gecmis_randevu_rengi;
            values.gecmis_randevu_durumu = gecmisRd !== "" ? gecmisRd === true ? 1 : 0 : randevuAyar?.gecmis_randevu_durumu;
            values.randevu_tedavi_suresi = randevuTs !== "" ? randevuTs === true ? 1 : 0 : randevuAyar?.randevu_tedavi_suresi;
            AxiosCrudActions.CreateAsync(`ayarlar/randevu-ayarlari`, values).then((result) => {
                setIsProcessing(false);
            });
        },
    });

    useEffect(() => {
        getRandevuAyar();
        getVarsayılanTedaviler();
    }, []);


    return (
        <>
            {can('view-randevu-ayarlar') ?   
                <TabPanel value="1">
                    {
                            loading ?
                            <div className="doctor-page-loading">
                                <LoadingOverlay/>
                            </div> 
                            :
                        
                            <div className="d-doctor-container">
                                {
                                    isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
                                }
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="d-flex align-items-center">
                                        <strong className="fs-5 fw-normal text text-secondary mb-3">Randevu Ayarları &nbsp;</strong>
                                    </span>
                                </div>
                                <form onSubmit={formik.handleSubmit} className="col-xl-12 col-lg-12  col-md-12 col-sm-12 col-12">
                                    <div className="row" >
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                            <FormControl fullWidth className="mt-2">
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="baslangic_saati"
                                                    variant='outlined'
                                                    label="Randevu Başlangıç Saati*"
                                                    onChange={(e) => {
                                                        setRandevuAyar(oldData => ({ ...oldData, baslangic_saati: e.target.value }));
                                                        formik.setFieldValue("baslangic_saati", e.target.value, false);
                                                    }}
                                                    value={randevuAyar?.baslangic_saati}
                                                    onBlur={formik.handleBlur}
                                                    InputLabelProps={{ style: { color: formik.errors.baslangic_saati && formik.touched.baslangic_saati ? '#e53e3e' : '#1976d2' } }}
                                                    autoComplete="off"
                                                    size='small'
                                                    disabled={lockForm}
                                                    style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                    InputProps={{
                                                        inputComponent: TimeHourMask,
                                                        startAdornment: <InputAdornment position="start" ><FaClock /></InputAdornment>,
                                                    }}
                                                    helperText={formik.errors.baslangic_saati && formik.touched.baslangic_saati && <span className='cstm-input-error-validation-msg'>{formik.errors.baslangic_saati}</span>}
                                                    error={formik.errors.baslangic_saati && formik.touched.baslangic_saati}
                                                />

                                            </FormControl>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                            <FormControl fullWidth className="mt-2">
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="bitis_saati"
                                                    variant='outlined'
                                                    label="Randevu Bitiş Saati*"
                                                    onChange={(e) => {
                                                        setRandevuAyar(oldData => ({ ...oldData, bitis_saati: e.target.value }));
                                                        formik.setFieldValue("bitis_saati", e.target.value, false);
                                                    }}
                                                    value={randevuAyar?.bitis_saati}
                                                    onBlur={formik.handleBlur}
                                                    InputLabelProps={{ style: { color: formik.errors.bitis_saati && formik.touched.bitis_saati ? '#e53e3e' : '#1976d2' } }}
                                                    autoComplete="off"
                                                    size='small'
                                                    disabled={lockForm}
                                                    style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                    InputProps={{
                                                        inputComponent: TimeHourMask,
                                                        startAdornment: <InputAdornment position="start" ><FaClock /></InputAdornment>,
                                                    }}
                                                    helperText={formik.errors.bitis_saati && formik.touched.bitis_saati && <span className='cstm-input-error-validation-msg'>{formik.errors.bitis_saati}</span>}
                                                    error={formik.errors.bitis_saati && formik.touched.bitis_saati}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                            <FormControl fullWidth className="mt-2">
                                                <TextField className='form-group'
                                                    select
                                                    name="zaman_araligi"
                                                    variant='outlined'
                                                    label="Randevu Zaman Aralığı*"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setRandevuAyar(oldData => ({ ...oldData, zaman_araligi: e.target.value }));
                                                        formik.setFieldValue("zaman_araligi", e.target.value, false);
                                                    }}
                                                    value={randevuAyar?.zaman_araligi}
                                                    onBlur={formik.handleBlur}
                                                    InputLabelProps={{ style: { color: formik.errors.zaman_araligi && formik.touched.zaman_araligi ? '#e53e3e' : '#1976d2' } }}
                                                    autoComplete="off"
                                                    size='small'
                                                    disabled={lockForm}
                                                    style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaClock /></InputAdornment>,
                                                    }}
                                                >
                                                    <MenuItem value={15}>15</MenuItem>
                                                    <MenuItem value={30}>30</MenuItem>
                                                    <MenuItem value={45}>45</MenuItem>
                                                </TextField>
                                                <FormHelperText style={{ color: '#856404', marginLeft: "unset" }}><FaQuestionCircle /> Randevu takviminde, randevu zaman aralığı dakika cinsinden zaman dilimi.</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                            <FormControl fullWidth className="mt-2">
                                                <Autocomplete
                                                    options={varsayılanTedaviler}
                                                    getOptionLabel={(option) => option.aciklama}
                                                    loading={true}
                                                    disableClearable
                                                    value={varsayılanTedavi}
                                                    noOptionsText={"Seçenek Bulunamadı"}
                                                    name="varsayilan_tedavi"
                                                    onChange={(event, newValue) => {
                                                        setVarsayılanTedavi(newValue);
                                                    }}
                                                    size="small"
                                                    disabled={lockForm}
                                                    style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {option.aciklama}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Randevu Varsayılan Tedavi*"
                                                        InputLabelProps={{ style: { color: formik.errors.varsayilan_tedavi && formik.touched.varsayilan_tedavi ? '#e53e3e' : '#1976d2' } }}
                                                        error={formik.errors.varsayilan_tedavi && formik.touched.varsayilan_tedavi}
                                                        helperText={formik.errors.varsayilan_tedavi && formik.touched.varsayilan_tedavi && <span className='cstm-input-error-validation-msg'>{formik.errors.varsayilan_tedavi}</span>}
                                                    />
                                                    }
                                                />
                                                <FormHelperText style={{ color: '#856404', marginLeft: "unset" }}><FaQuestionCircle /> Seçili olan tedavi otomatik olarak randevu verirken varsayılan olarak gelir.</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                            <FormControl fullWidth className="mt-2">
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="yenileme_suresi"
                                                    variant='outlined'
                                                    label="Randevu Yenilenme Süresi*"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setRandevuAyar(oldData => ({ ...oldData, yenileme_suresi: e.target.value }));
                                                        formik.setFieldValue("yenileme_suresi", e.target.value, false);
                                                    }}
                                                    value={randevuAyar?.yenileme_suresi}
                                                    onBlur={formik.handleBlur}
                                                    InputLabelProps={{ style: { color: formik.errors.yenileme_suresi && formik.touched.yenileme_suresi ? '#e53e3e' : '#1976d2' } }}
                                                    autoComplete="off"
                                                    size='small'
                                                    disabled={lockForm}
                                                    style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaClock /></InputAdornment>,
                                                    }}
                                                    helperText={formik.errors.yenileme_suresi && formik.touched.yenileme_suresi && <span className='cstm-input-error-validation-msg'>{formik.errors.yenileme_suresi}</span>}
                                                    error={formik.errors.yenileme_suresi && formik.touched.yenileme_suresi}
                                                />
                                                <FormHelperText style={{ color: '#856404', marginLeft: "unset" }}><FaQuestionCircle /> Randevu takviminde, güncel randevuları göstermek için saniye cinsinden zaman dilimi.</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-4">
                                            <FormControl fullWidth className="mt-2">
                                                <TextField className='form-group'
                                                    type="color"
                                                    name="gecmis_randevu_rengi"
                                                    variant='outlined'
                                                    label="Geçmiş Randevu Rengi*"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setRandevuAyar(oldData => ({ ...oldData, gecmis_randevu_rengi: e.target.value }));
                                                        formik.setFieldValue("gecmis_randevu_rengi", e.target.value, false);
                                                    }}
                                                    value={randevuAyar?.gecmis_randevu_rengi}
                                                    onBlur={formik.handleBlur}
                                                    InputLabelProps={{ style: { color: formik.errors.gecmis_randevu_rengi && formik.touched.gecmis_randevu_rengi ? '#e53e3e' : '#1976d2' } }}
                                                    autoComplete="off"
                                                    size='small'
                                                    disabled={lockForm}
                                                    style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaEyeDropper /></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                            <FormControl fullWidth className="mt-2">
                                                <TextField className='form-group'
                                                    type="color"
                                                    name="gelmeyen_hasta_rengi"
                                                    variant='outlined'
                                                    label="Gelmeyen Hasta Rengi*"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setRandevuAyar(oldData => ({ ...oldData, gelmeyen_hasta_rengi: e.target.value }));
                                                        formik.setFieldValue("gelmeyen_hasta_rengi", e.target.value, false);
                                                    }}
                                                    value={randevuAyar?.gelmeyen_hasta_rengi}
                                                    onBlur={formik.handleBlur}
                                                    InputLabelProps={{ style: { color: formik.errors.gelmeyen_hasta_rengi && formik.touched.gelmeyen_hasta_rengi ? '#e53e3e' : '#1976d2' } }}
                                                    autoComplete="off"
                                                    size='small'
                                                    disabled={lockForm}
                                                    style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaEyeDropper /></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-3">
                                            <FormControl fullWidth className="mt-4">
                                                <FormControlLabel
                                                    name="gecmis_randevu_durumu"
                                                    checked={gecmisRd}
                                                    onChange={e => changeGecmisRd(e.target.checked)}
                                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                                    label="Geçmiş Randevu"
                                                    disabled={lockForm}
                                                />
                                                <FormHelperText style={{ color: '#856404', marginLeft: "unset" }}><FaQuestionCircle /> Geçmiş randevular farklı renkte gösterilir.</FormHelperText>
                                            </FormControl>
                                        </div>
                                        {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pb-3">
                                            <FormControl fullWidth className="mt-4">
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                                    label="Randevu Sürükle Bırak"
                                                    disabled={lockForm}
                                                />
                                                <FormHelperText style={{ color: '#856404', marginLeft: "unset" }}><FaQuestionCircle /> Randevu takviminde, sürükle-bırak özelliğinle randevu tarihi veya saatinde değişiklik yapıldığında ekrana gelen uyarı.</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pb-3">
                                            <FormControl fullWidth className="mt-4">
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                                    label="Randevu Zamanı"
                                                    disabled={lockForm}
                                                />
                                                <FormHelperText style={{ color: '#856404', marginLeft: "unset" }}><FaQuestionCircle /> Randevu takviminde, randevusu olan hastanın randevu süresini uzatıldığında ekrana gelen uyarı.</FormHelperText>
                                            </FormControl>
                                        </div> */}
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-3">
                                            <FormControl fullWidth className="mt-4">
                                                <FormControlLabel
                                                    name="randevu_tedavi_rengi"
                                                    checked={tedaviRengi}
                                                    onChange={e => changeRandevuRengi(e.target.checked)}
                                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                                    label="Randevu Rengi"
                                                    disabled={lockForm}
                                                />
                                                <FormHelperText style={{ color: '#856404', marginLeft: "unset" }}><FaQuestionCircle /> Randevu takviminde, doktor  rengini kullanmak istiyorsanız aktif edin tedaviye göre renk kullanmak içinde pasif yapın.</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-3">
                                            <FormControl fullWidth className="mt-4">
                                                <FormControlLabel
                                                    name="randevu_tedavi_suresi"
                                                    checked={randevuTs}
                                                    onChange={e => changeRandevuTs(e.target.checked)}
                                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                                    label="Randevu Tedavi Süresi"
                                                    disabled={lockForm}
                                                />
                                                <FormHelperText style={{ color: '#856404', marginLeft: "unset" }}><FaQuestionCircle /> Randevu takviminde, verilen tedavinin belirtilen sürenin altına inmesini istiyorsanız pasif yapın.</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <span className="d-flex align-items-center" style={{ float: "right" }}>
                                        <ButtonM onClick={formLock} color="warning" variant="outlined" startIcon={<FaRegEdit />}
                                            style={{ color: active ? "#F39D56" : "#888E99", border: active ? "#F39D56 1px solid" : "#888E99 1px solid", marginRight: "10px", }}
                                        >
                                            Düzelt
                                        </ButtonM>
                                        <ButtonM color="success" type="submit" variant="outlined" disabled={lockForm || ! can('edit-randevu-ayarlar')} >
                                        {
                                            isProcesssing ? 
                                            (
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                    İşleniyor...
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <FaSave /> &nbsp;
                                                    {"Kaydet"}
                                                </>
                                            )
                                        }
                                    </ButtonM>{' '}
                                    </span>
                                </form>
                            </div>
                    }
                </TabPanel >
            :<NotPermission/>}
        </>
    );
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
  export default connect(authPermissions)(SettingsRandevu);


