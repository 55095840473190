import * as yup from 'yup';  

const CreateDoctorValidationSchema = () => {
    return yup.object().shape({

        isPasswordValid: yup.boolean(),

        name: yup.string()
                  .min(5,"Ad-soyad en az 5 karakter olmalıdır.")
                  .max(50,"Ad-soyad en fazla 50 karakter olmalıdır.")
                  .required("Lütfen geçerli bir ad-soyad bilgisi giriniz."),

        brans: yup.string()
                  .min(2,'Branş en az 2 karakter içermelidir')
                  .max(100,'Branş en fazla 100 karakter içermelidir')
                  .required("Lütfen branş giriniz."),
                  
        tel_no: yup.string()
                  .required("Lütfen cep telefon numarası giriniz."),

        password: yup
            .string()
            .min(8,"Şifre en az 8 karakter olmalıdır.")
            .when("isPasswordValid", {
                is: true,
                then: yup.string().required("Lütfen Şifre giriniz")
            }),

        password_confirmation: yup.string()
                    .oneOf([yup.ref('password'), null], 'Şifre uyuşmuyor')
                    .min(8,"Şifre en az 8 karakter olmalıdır.")
                    .when("isPasswordValid", {
                        is: true,
                        then: yup.string().required("Lütfen Şifre tekrarı giriniz")
                    }),

        email: yup.string()
                    .email("Lütfen geçerli bir email adresi giriniz.")
                    .max(50, "Email adresi en fazla 50 karakter olmalıdır.")
                    .matches(
                        /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@!#$%&'*+-/=?^_`{|}~.]+$/,
                        "E-posta yalnızca ingilizce karakter barındırabilir"
                    )
                    .required('Lütfen E-posta giriniz'),
                

   });
}   

export default CreateDoctorValidationSchema;

