import { Tooltip } from "@mui/material";
import GetTrDate from "extensions/GetTRDate";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { GetTriggerActionName } from "extensions/GetTriggerActionName";

export const HastaTedavilerLogColumns = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        flex: 1,
        headerAlign: "center",
        minWidth:100,
        maxWidth:100,
        hideable: true,
        editable: false,
        filterable: false
    },
    {
        field: "hasta",
        headerName: "Hasta", 
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.hasta?.hasta ? params?.row?.hasta?.hasta : ''
            )
        },
        valueGetter: (params) => `${params?.row?.hasta?.hasta ? params?.row?.hasta?.hasta : ''}`,
    },
    {
        field: "tedavi_tipi",
        headerName: "Tedavi Tipi", 
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.tedavi_tipi?.tedavi_tipi_adi ? params?.row?.tedavi_tipi?.tedavi_tipi_adi : ''
            )
        },
        valueGetter: (params) => `${params?.row?.tedavi_tipi?.tedavi_tipi_adi ? params?.row?.tedavi_tipi?.tedavi_tipi_adi : ''}`,
    },
    {
        field: "tedavi",
        headerName: "Tedavi", 
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                <Tooltip title={params?.row?.tedavi?.tedaviTanimi ? params?.row?.tedavi?.tedaviTanimi : ''} arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params?.row?.tedavi?.tedaviTanimi ? params?.row?.tedavi?.tedaviTanimi : ''}</span>
                </Tooltip>
                
            )
        },
        valueGetter: (params) => `${params?.row?.tedavi?.tedaviTanimi ? params?.row?.tedavi?.tedaviTanimi : ''}`,
    },
    {
        field: "doktor",
        headerName: "Doktor", 
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.doktor?.name ? params?.row?.doktor?.name : ''
            )
        },
        valueGetter: (params) => `${params?.row?.doktor?.name ? params?.row?.doktor?.name : ''}`,
    },
    {
        field: "fiyat",
        headerName: "Fiyat",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
    },
    {
        field: "kdv",
        headerName: "KDV",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
    },
    {
        field: "fatura_kesildi",
        headerName: "Fatura Durumu",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.fatura_kesildi ? "Fatura Kesildi" : "Fatura Kesilmedi"
            )
        },
        valueGetter: (params) => `${params?.row?.fatura_kesildi ? "Fatura Kesildi" : "Fatura Kesilmedi"}`
    },
    {
        field: "tarih",
        headerName: "Tarih",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                GetTrDate(params?.row?.tarih)
            )
        },
        valueGetter: (params) => `${GetTrDate(params?.row?.tarih)}`
    },
    {
        field: "disler",
        headerName: "Dişler",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
    },
    {
        field: "kokler",
        headerName: "Kökler",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
    },
    {
        field: "aciklama",
        headerName: "Açıklama",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return(
                <Tooltip title={params.row.aciklama} arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.aciklama}</span>
                </Tooltip>
            )
        }        
    },

    {
        field: "action",
        headerName: "İşlem",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTriggerActionName(params?.row?.action)
            )
        },
        valueGetter: (params) => GetTriggerActionName(params?.row?.action)
    },
    {
        field: "update_text",
        headerName: "Güncelleme İşlemi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "user",
        headerName: "Kullanıcı",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.user?.name ? params?.row?.user?.name : '' 
            )
        },
        valueGetter: (params) => `${params?.row?.user?.name ? params?.row?.user?.name : '' }`,
    },
    {
        field: "islem_tarihi",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)
            )
        },
        valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)}`
    },
];