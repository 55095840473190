import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import 'assets/css/pages/audit-log.css'
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";
import { FormControl, TextField, MenuItem,Autocomplete } from '@mui/material';
import {tr} from 'date-fns/locale'
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CarilerLogColumns } from "constants/CarilerLogTablecolumns";
import { GiderlerLogColumns } from "constants/GiderlerLogTableColumns";
import GetDateWithFormat from "extensions/GetDateWithFormat";
import { StokKartlariLogTableColumns } from "constants/StokKartlariLogTableColumns";
import { StokHareketlerLogColumns } from "constants/StokHareketlerLogColumns";
import { HastalarLogTableColumns } from "constants/HastalarLogTableColumns";
import { HastaTedavilerLogColumns } from "constants/HastaTedavilerLogColumns";
import { HastaFaturalarLogTableColumns } from "constants/HastaFaturalarLogTableColumns";
import { HastaRecetelerLogTableColumns } from "constants/HastaRecetelerLogTableColumns";
import { HastaHesapLogTableColumns } from "constants/HastaHesapLogTableColumns";
import { KullanicilarLogTableColumns } from "constants/KullanicilarLogTableColumns";

const Loglar = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }


    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const logTables = [
        {id: 1, name: 'Cariler',url: "cari-log", columns: CarilerLogColumns},
        {id: 2, name: 'Giderler',url: "gider-log", columns: GiderlerLogColumns},
        {id: 3, name: 'Stok Kartları', url: 'stok-karti-log', columns: StokKartlariLogTableColumns},
        {id: 4, name: 'Stok Hareketleri', url: 'stok-hareket-log', columns: StokHareketlerLogColumns},
        {id: 5, name: 'Hastalar', url: 'hasta-log', columns: HastalarLogTableColumns},
        {id: 6, name: 'Hasta Tedavileri', url: 'hasta-tedavi-log', columns: HastaTedavilerLogColumns},
        {id: 7, name: 'Hasta Faturalar', url : 'fatura-log', columns: HastaFaturalarLogTableColumns},
        {id: 8, name: 'Hasta Reçeteler', url : 'recete-log', columns: HastaRecetelerLogTableColumns},
        {id: 9, name: 'Hasta Hesapları', url : 'hasta-hesap-log', columns: HastaHesapLogTableColumns},
        {id: 10, name: 'Kullanıcılar', url : 'kullanicilar-log', columns: KullanicilarLogTableColumns},
    ];

    // columns 
    const [columns, setColumns] = useState(CarilerLogColumns);
    // filter states
    const [selectedTable, setSelectedTable] = useState(1);
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const [users, setUsers] = useState([{id:0,name:'Tümü'}]);
    const [selectedUser, setSelectedUser] = useState({id:0,name:'Tümü'});
    const [selectedUserInputValue, setSelectedUserInputValue] = useState('');

    // data-grid state
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    const getUsers = async () => {
        await axios.get('kullanicilar',{params: {hepsi: 1}})
                    .then(res => {
                        if(res?.status === 200 ){
                            setUsers(res?.data?.data);
                            setUsers(prevState => {return [{id:0, name:'Tümü'},...prevState] });
                        }
                    }).catch(e => {
                        setUsers([{id:0,name:'Tümü'}]);
                    })
    }

    useEffect(()=> {
        getUsers();
    },[]);
    // side effect
    useEffect(() => {
        let table = logTables.find((el) => el.id.toString() === selectedTable.toString());
        let url = table?.url;
        if(url !== undefined && url !== null){
            axios.get("/"+url, {
                params: {
                    user_id: (selectedUser?.id && selectedUser?.id > 0 ? selectedUser?.id :null),
                    start: GetDateWithFormat(startDate),
                    end: GetDateWithFormat(endDate),
                    filterModel: JSON.stringify(filterModel),
                    pageSize: pageSize,
                    page: page,
                    sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                    sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
                }
            }).then((res) => {
                setIsLoading(false);
                try {
                    if (res.data.data !== undefined && res.data.data !== null) {
                        setTotalCount(res.data.totalCount);
                        setData(res.data.data);
                        setColumns(table.columns);
                    }
                } catch (error) {
                    setData([]);
                    setColumns(CarilerLogColumns);
                }
            }).catch((err) => {
                setData([]);
                setColumns(CarilerLogColumns);
            });
        }
    }, [sortModel, filterModel, pageSize, page,selectedTable,startDate,endDate,selectedUser]);


    return (
        <>
         {can('view-loglar') ? 
            <div className="audit-log-container">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-2 mb-2">
                        <FormControl fullWidth>
                            <TextField
                                name="selected_table"
                                select
                                InputLabelProps={{ style: { color: '#1976d2' } }}
                                label="Modül"
                                size='small'
                                value={selectedTable}
                                onChange={(e) =>{
                                    e.preventDefault();
                                    setSelectedTable(e.target.value);
                                    setPage(0);
                                    // new 
                                    setSelectedUser({id:0,name:'Tümü'});
                                    setStartDate(null);
                                    setEndDate(null);
                                }}
                                InputProps={{
                                    // startAdornment: <InputAdornment position="start"><FaExclamation /></InputAdornment>,
                                }}>
                                {
                                    logTables.map((el,idx) => {
                                        return (
                                            <MenuItem key={idx} value={el.id}>{el.name}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </FormControl>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-2 mb-2">
                        <FormControl fullWidth>
                            <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                <DesktopDatePicker
                                    variant='outlined'
                                    label="Başlangıç Tarihi"
                                    inputFormat="dd-MM-yyyy"
                                    minDate={dayjs('01-01-1930')}
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} 
                                                                size="small"
                                                                name="start_date"
                                                                variant='outlined'
                                                                InputLabelProps={{ style: { color: '#1976d2' } }}
                                                                />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-2 mb-2">
                        <FormControl fullWidth>
                            <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                <DesktopDatePicker
                                    variant='outlined'
                                    label="Bitiş Tarihi"
                                    inputFormat="dd-MM-yyyy"
                                    minDate={dayjs('01-01-1930')}
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} 
                                                                size="small"
                                                                name="end_date"
                                                                variant='outlined'
                                                                InputLabelProps={{ style: { color: '#1976d2' } }}
                                                                />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-2 mb-2">
                        <FormControl fullWidth>
                            <Autocomplete
                                options={users}
                                loading={true}
                                getOptionLabel={option => option.name}
                                noOptionsText={'Seçenek Bulunamadı'}
                                value={selectedUser}
                                name="user_id"
                                disableClearable
                                label="Kullanıcı"
                                onChange={(event, newValue) => { 
                                    event.preventDefault();
                                    setSelectedUser(newValue);
                                    setPage(0);
                                }}
                                inputValue={selectedUserInputValue}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onInputChange={(event, newInputValue) => { setSelectedUserInputValue(newInputValue); }}
                                size="small"
                                renderOption={(props, option) => {
                                    return (
                                    <li {...props} key={option.id}>
                                        {option.name}
                                    </li>
                                    );
                                }}
                                renderInput={(params) => <TextField {...params}  label="Kullanıcı"/>}  
                            />
                        </FormControl>
                    </div>
                </div>
                <ThemeProvider theme={theme}>
                        <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                            <DataGrid
                                autoHeight={data.length > 0 ? true : false}
                                sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                loading={isLoading}
                                rows={data}
                                columns={columns}
                                // pagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowCount={totalCount} //serverdan gelen data total count gelecek
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                pagination
                                paginationMode="server"
                                // sorting
                                sortModel={sortModel}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                filterModel={filterModel}
                                filterMode="server"
                                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                // selection checkbox
                                //checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                // features
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                                // toolbar components
                                components={{
                                    Toolbar: GridToolbar,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    LoadingOverlay:LoadingOverlay
                                }}
                                disableColumnFilter
                                // component props
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: {
                                            fields: ["id","cari_adi", "hesap_tipi", "telefon","il","ilce","vergi_dairesi","vergi_numarasi","status","action","user","islem_tarihi"],
                                            fileName: "İlaclar",
                                            hideFooter: true,
                                            hideToolbar: true,
                                            disableToolbarButton: true,
                                        }, //sadece firsname  ve lastname alanını alır.
                                        csvOptions: {
                                            disableToolbarButton: true,
                                            fields: ["id","cari_adi", "hesap_tipi", "telefon","il","ilce","vergi_dairesi","vergi_numarasi","status","action","user","islem_tarihi"],
                                            fileName: 'Cariler_Sistem_Gecmisi',
                                            utf8WithBom: true,
                                        }, //sadece firstname ve lastName alanını alır.
                                        excelOptions: { disableToolbarButton: true },
                                        // quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Box>
                    </ThemeProvider>
            </div>
         :<NotPermission/>}
        </>
    );
};


const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(Loglar);

