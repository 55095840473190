import React, { useEffect, useState } from 'react';
import { TabPanel } from "@mui/lab";
import uploadIcon from 'assets/images/upload-file.jpg';
import { FaDownload, FaTimes, FaTrash } from "react-icons/fa";
import 'assets/css/pages/image-archive.css';
import { Checkbox, Tooltip } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from 'services/AxiosCrudCore';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import GetTrFullDateAndTimeWithFormat from 'extensions/GetTRDateWithFormat';
import { saveAs } from 'file-saver';
import {Button as ButtonM} from '@mui/material';
import FsLightbox from "fslightbox-react";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const PatientImageArchive = (props) => {
        const {auth} = props;
        const can = (permission) => {
          return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
        }
        const MySwal = withReactContent(Swal);
        const params = useParams();
        const patientId = params?.patientId;

        // user Images url 
        const [imagesUrl, setImagesUrl] = useState([]);
        const [updateImagesUrl, setUpdateImagesUrl] = useState(false);
        // new upload files
        const [files, setFiles] = React.useState(null);
        const [dragActive, setDragActive] = React.useState(false);
        // full image silider
        const [fullImageSlideToggle, setFullImageSlideToggle] = useState(false);
        const [fullImageSlideNumber, setFullImageSlideNumber] = useState(1);
        // ref
        const inputRef = React.useRef(null);
        // handle drag events
        const handleDrag = function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
            } else if (e.type === "dragleave") {
                setDragActive(false);
            }
        };
        
        // triggers when file is dropped
        const handleDrop = function(e) {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                // handleFiles(e.dataTransfer.files);
                setFiles(e.dataTransfer.files);
            }
        };
        
        // triggers when file is selected with click
        const handleChange = function(e) {
            e.preventDefault();
            if (e.target.files && e.target.files[0]) {
                // handleFiles(e.target.files);
                setFiles(e.target.files);
            }
        };
        
        // triggers the input when the button is clicked
        const onButtonClick = () => {
            inputRef.current.click();
        };

        const removeSelectedImage = (e,element) => {
            e.preventDefault();
            setFiles(prevState => {
                return Array.from(prevState).filter((el) => {return el.name !== element.name});
            })
        }

        const saveImages = (e) => {
            e.preventDefault();
            if(files !== undefined && files !== null && Array.from(files).length > 0 && patientId !== undefined && patientId !== null && patientId > 0){
                MySwal.fire({
                    title: "Görseller Eklensin mi",
                    text: `Seçilen görseller kaydedilsin mi?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Evet, Ekle',
                    cancelButtonText: 'Hayır, Vazgeç'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // create formData object
                        const formData = new FormData();
                        Array.from(files).forEach((file, index)=>{
                            formData.append("gorsel"+(index+1) , file);
                        });
                        formData.append("hasta_id",patientId);
                        AxiosCrudActions.CreateAsync('/hasta-gorselleri', formData,)
                            .then(result => {
                                if (result?.status === 201) {
                                    setUpdateImagesUrl(!updateImagesUrl)
                                    setFiles(null);
                                }
                            });
                    }
                })
            }
        }

        const removeImage = (e, id = null) => {
            e.preventDefault(); //prevent default action of button
            e.stopPropagation();
            if(id !== undefined && id !== null && id > 0){
                MySwal.fire({
                    title: "Resim silinsin mi?",
                    text: `${id} id'li resim silinecektir. Silme işlemini gerçekleştirdiğinizde geri alamazsınız!`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Evet, Sil',
                    cancelButtonText: 'Hayır,Vazgeç'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        AxiosCrudActions.DeleteAsync('/hasta-gorselleri', id)
                            .then(result => {
                                if (result?.status === 200) {
                                    setUpdateImagesUrl(!updateImagesUrl)
                                }
                            });
    
                    }
                })
            }
        }

        const getImagesUrl = async () => {
            await axios.get(`/hasta-gorselleri`, {params: {hasta_id: patientId}})
                    .then(res => {
                        if(res?.status === 200 ){
                            setImagesUrl(res?.data?.data);
                        }
                    }).catch(e => {
                        setImagesUrl([]);
                    })
        }

        useEffect(() => {
            getImagesUrl();
        },[updateImagesUrl])

    return (
        <> { can('view-hastalar-goruntu') ?
        <TabPanel value="5" className="image-archive-wrapper">
            {
                files !== undefined && files !== null && Array.from(files).length > 0? 
                <div className="selected-images-preview-wrap">
                    <div className='d-flex justify-content-between p-1'>
                        <h5 className="header">Önizleme</h5>
                        <div className='d-flex justify-content-between'>
                            <span>
                                <ButtonM variant='outlined' color="success" disabled={! can('create-hastalar-goruntu')} onClick={saveImages}>
                                    Kaydet
                                </ButtonM> 
                            </span>
                            &nbsp;
                            <span>
                                <ButtonM variant='outlined' color="warning"  onClick={(e) => {e.preventDefault(); setFiles(null)}}>
                                    Vazgeç
                                </ButtonM>
                            </span>
                        </div>
                    </div>
                    <div className="content">
                        {
                            Array.from(files).map((element, index) => {
                                let url = URL.createObjectURL(element);
                                return (
                                    <span className="item-wrap" key={index}>
                                        <Tooltip title="Sil" arrow placement='bottom-end' onClick={e => removeSelectedImage(e, element)}>
                                            <span className="remove-btn">
                                                <FaTimes />
                                            </span>
                                        </Tooltip>
                                        <img src={url} className="img" alt='uploadedImage...'/>
                                    </span> 
                                )
                            })
                        }
                    </div>
                </div>
                :
                null
            }
            <form className='img-upload-container' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                <div className={dragActive ? "content-wrap drag-active" : "content-wrap"}>
                    <div className="upload-icon-wrap">
                         <img src={uploadIcon} alt="upload..."/>
                    </div>
                    <p className="desc-wrap">
                        Resimleri buraya sürükleyip bırakın
                    </p>
                    <span className="sub-desc-wrap">
                        veya
                    </span>
                    { can('create-hastalar-goruntu') ?
                    <div className="upload-button">
                        <button onClick={onButtonClick}>
                            Dosyalara Gözat
                        </button>
                    </div>
                     :''}
                </div>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>
            <div className='p-i-a-divider-line'></div>
            <div className='patient-images-archive-wrap'>
                    <p className='header'>
                        Hasta Görselleri
                    </p>
                <div className='images-container'>
                    <div className='row'>
                        {
                            imagesUrl.map((element,idx) => {
                                return (
                                    <div className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-3' key={idx}>
                                        <div className='d-flex align-items-center justify-content-between' style={{paddingRight: '10px',paddingLeft: '10px'}}>
                                            <p className='m-0'>
                                                {GetTrFullDateAndTimeWithFormat(element?.tarih)}
                                            </p>
                                            { can('delete-hastalar-goruntu') ?
                                            <div className='d-flex align-items-center'>
                                                <Tooltip title="sil" arrow placement='bottom'>
                                                    <span className='delete p-i-a-cstm-delete-btn' onClick={e => removeImage(e, element?.id)}><FaTrash /></span>  
                                                </Tooltip>
                                            </div>
                                             :''}
                                        </div>
                                        <div className='i-card-item' onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setFullImageSlideToggle(!fullImageSlideToggle);
                                                setFullImageSlideNumber(idx + 1);
                                                }}>
                                            <div className='i-card'>
                                                <img src={element?.gorsel} alt="patientImage"/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <FsLightbox 
                        toggler={fullImageSlideToggle}
                        sources={
                            imagesUrl.map((el) => {
                                const img = new Image();
                                img.src = el?.gorsel;
                                if(el?.gorsel){
                                    return (
                                        <iframe
                                            src={el?.gorsel}    
                                            width={img.width}
                                            height={img.height}
                                        />
                                    )
                                }
                            })
                        }
                        slide={fullImageSlideNumber}
                    />
                </div>
            </div>
        </TabPanel>
        :''}</>
    )
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(PatientImageArchive);
