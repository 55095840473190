import { Tooltip } from "@mui/material";
import GetTrDate from "extensions/GetTRDate";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { GetTriggerActionName } from "extensions/GetTriggerActionName";

export const StokHareketlerLogColumns = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        flex: 1,
        headerAlign: "center",
        minWidth:100,
        maxWidth:100,
        hideable: true,
        editable: false,
        filterable: false
    },
    {
        field: "stok_adi",
        headerName: "Stok Adı",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "tip",
        headerName: "Hareket türü",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.tip ? "Giriş" : "Çıkış"
            )
        },
        valueGetter: (params) => `${params?.row?.tip ? "Giriş" : "Çıkış"}`,
    },
    {
        field: "miktar",
        headerName: "Miktar",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "tarih",
        headerName: "Tarih",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                GetTrDate(params?.row?.tarih)
            )
        },
        valueGetter: (params) => `${GetTrDate(params?.row?.tarih)}`
    },
    {
        field: "kullanan_personel",
        headerName: "Kullanan Personel",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.kullanan_personel?.name ? params?.row?.kullanan_personel?.name : ''
            )
        },
        valueGetter: (params) => `${params?.row?.kullanan_personel?.name ? params?.row?.kullanan_personel?.name : ''}`
    },
    {
        field: "birim_fiyat",
        headerName: "Birim Fiyat",
        minWidth: 130,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "fatura_no",
        headerName: "fatura No",
        minWidth: 180,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "temin_yeri",
        headerName: "Tedarik Yeri",
        minWidth: 130,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return(
                <Tooltip title={params.row.temin_yeri} arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.temin_yeri}</span>
                </Tooltip>
            )
        }
    },
    {
        field: "aciklama",
        headerName: "Açıklama",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return(
                <Tooltip title={params.row.aciklama} arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.aciklama}</span>
                </Tooltip>
            )
        }        
    },

    {
        field: "action",
        headerName: "İşlem",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTriggerActionName(params?.row?.action)
            )
        },
        valueGetter: (params) => GetTriggerActionName(params?.row?.action)
    },
    {
        field: "update_text",
        headerName: "Güncelleme İşlemi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "auth_id",
        headerName: "Kullanıcı",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.auth_id?.name
            )
        },
        valueGetter: (params) => `${params?.row?.auth_id?.name}`,
    },
    {
        field: "islem_tarihi",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)
            )
        },
        valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)}`
    },
];