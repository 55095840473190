import { TabPanel } from "@mui/lab";
import React, { useEffect, useState } from "react";
import AxiosCrudActions from "services/AxiosCrudCore";
import { useFormik } from 'formik';
import {
    InputAdornment, FormControl, TextField, FormHelperText,
    Button as ButtonM, Autocomplete, MenuItem, Stack , Paper 

} from '@mui/material';
import {
    FaHeading, FaUserMd, FaUserAlt, FaFileInvoice, FaCode,
    FaSearch, FaSearchDollar, FaCloudUploadAlt,
    FaClock, FaQuestionCircle, FaSave, FaRegEdit
} from 'react-icons/fa';
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import settingsGeneralValidationSchema from "business-layer/SettingsGeneralValidationSchema";
import axios from "axios";
import defaultImage from 'assets/images/no-logo.png';
import 'assets/css/pages/settings.css';
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const SettingsGenel = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }

    const [genelAyar, setGenelAyar] = useState('');
    const [tarifeler, setTarifeler] = useState([]);
    const [tarife, setTarife] = useState();
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState('');
    const [districts, setDistricts] = useState([]);
    const [district_id, setDistrictId] = useState('');
    const [lockForm, setLockForm] = useState(true);
    const [isProcesssing, setIsProcessing] = useState(false);
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(true);

    const [originalImageFile, setOriginalImageFile] = useState(null);
    const [file, setFile] = useState(defaultImage);

    const formLock = () => { setLockForm(!lockForm); setActive(!active) };

    const handleImageChange = (e) => {
        setOriginalImageFile(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const removeImage = (e) => {
        if (!lockForm) {
            setOriginalImageFile(null);
            setFile(defaultImage);
        }
    }

    const getGenelAyar = async () => {
        try {
            let data = await axios.get("ayarlar/genel-ayarlar");
            if (data.status === 200) {
                let result = data.data.data;
                setGenelAyar(result);
                setTarife(result.varsayilan_tarife);
                setCity(result.il);
                setDistrictId(result.ilce);
                formik.setFieldValue("site_basligi", result?.site_basligi, true);
                formik.setFieldValue("klinik_no", result?.klinik_no, true);
                setLoading(false);
            } else {
                setGenelAyar('');
                setLoading(false);
            }
        } catch (error) {
            setGenelAyar('');
            setLoading(false);
        }
    };


    const getTarifeler = async () => {
        try {
            let tarifeler = await axios.get("/tarifeler");
            if (tarifeler.status === 200) {
                setTarifeler(tarifeler.data.data);
            } else {
                setTarifeler([]);
            }
        } catch (error) {
            setTarifeler([]);
        }
    };

    const getCities = async () => {
        try {
            let data = await axios.get("cities");
            if (data.status === 200) {
                setCities(data.data.data);
            } else {
                setCities([]);
            }
        } catch (error) {
            setCities([]);
        }
    };

    const getDistricts = async (city_id) => {
        if (city_id !== undefined && city_id !== null && city_id > 0) {
            try {
                let data = await axios.get(`cities/${city_id}`);
                if (data.status === 200) {
                    setDistricts(data.data.data);
                } else {
                    setDistricts([]);
                }
            } catch (error) {
                setDistricts([]);
            }
        } else {
            setDistricts([]);
        }
    };

    // yup schema
    let settingsGenelValidationSchema = settingsGeneralValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: {
            site_basligi: '',
            pasif_doktorlar: '',
            pasif_kullanicilar: '',
            e_fatura_tipi: '',
            e_fatura_kodu: '',
            varsayilan_tarife_id: '',
            tum_tedavilerde_ara: '',
            il_id: '',
            ilce_id: '',
            para_birimi: '',
            klinik_no: '',
        },
        validationSchema: settingsGenelValidationSchema,
        onSubmit: values => {
            setIsProcessing(true);
            values.site_basligi = formik?.values?.site_basligi ? formik?.values?.site_basligi : genelAyar?.site_basligi;
            values.pasif_doktorlar = formik?.values?.pasif_doktorlar ? formik?.values?.pasif_doktorlar : genelAyar?.pasif_doktorlar;
            values.pasif_kullanicilar = formik?.values?.pasif_kullanicilar ? formik?.values?.pasif_kullanicilar : genelAyar?.pasif_kullanicilar;
            values.e_fatura_tipi = formik?.values?.e_fatura_tipi ? formik?.values?.e_fatura_tipi : genelAyar?.e_fatura_tipi;
            values.e_fatura_kodu = formik?.values?.e_fatura_kodu ? formik?.values?.e_fatura_kodu : genelAyar?.e_fatura_kodu;
            values.varsayilan_tarife_id = tarife ? tarife?.id : genelAyar?.varsayilan_tarife;
            values.tum_tedavilerde_ara = formik?.values?.tum_tedavilerde_ara ? formik?.values?.tum_tedavilerde_ara : genelAyar?.tum_tedavilerde_ara;
            values.il_id = city ? city?.id : genelAyar?.il?.id;
            values.ilce_id = district_id ? district_id?.id : genelAyar?.ilce?.id;
            values.para_birimi = formik?.values?.para_birimi ? formik?.values?.para_birimi : genelAyar?.para_birimi;
            values.klinik_no = formik?.values?.klinik_no ? formik?.values?.klinik_no : genelAyar?.klinik_no;

            AxiosCrudActions.CreateAsync(`ayarlar/genel-ayarlar`, values).then((result) => {
                setIsProcessing(false);
            });
        },
    });

    useEffect(() => {
        if (city?.id) {
            getDistricts(city?.id);
        }
        getCities();
        getTarifeler();
        getGenelAyar();
    }, []);

    return (
        <>
        {can('view-iletisim-ayarlar') ? 
            <TabPanel value="4">
                {
                    loading ?
                        <div className="doctor-page-loading">
                            <LoadingOverlay />
                        </div>
                        :
                        <div className="d-doctor-container">
                            {
                                isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
                            }
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="d-flex align-items-center">
                                    <strong className="fs-5 fw-normal text text-secondary">Genel Ayarlar &nbsp;</strong>
                                </span>
                            </div>
                            <form onSubmit={formik.handleSubmit} className="col-xl-12 col-lg-12  col-md-12 col-sm-12 col-12">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="text"
                                                name="site_basligi"
                                                variant='outlined'
                                                label="Başlık *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setGenelAyar(oldData => ({ ...oldData, site_basligi: e.target.value }));
                                                    formik.setFieldValue("site_basligi", e.target.value, false);
                                                }}
                                                value={genelAyar?.site_basligi}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.site_basligi && formik.touched.site_basligi ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaHeading /></InputAdornment>,
                                                }}

                                                helperText={formik.errors.site_basligi && formik.touched.site_basligi && <span className='cstm-input-error-validation-msg'>{formik.errors.site_basligi}</span>}
                                                error={formik.errors.site_basligi && formik.touched.site_basligi}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                select
                                                name="pasif_doktorlar"
                                                variant='outlined'
                                                label="Pasif Doktorlar *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setGenelAyar(oldData => ({ ...oldData, pasif_doktorlar: e.target.value }));
                                                    formik.setFieldValue("pasif_doktorlar", e.target.value, false);
                                                }}
                                                value={genelAyar?.pasif_doktorlar}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.pasif_doktorlar && formik.touched.pasif_doktorlar ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaUserMd /></InputAdornment>,
                                                }}
                                            >
                                                <MenuItem value={0}>Hayır Gizle</MenuItem>
                                                <MenuItem value={1}>Evet Göster</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                select
                                                name="pasif_kullanicilar"
                                                variant='outlined'
                                                label="Pasif Kullanıcılar *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setGenelAyar(oldData => ({ ...oldData, pasif_kullanicilar: e.target.value }));
                                                    formik.setFieldValue("pasif_kullanicilar", e.target.value, false);
                                                }}
                                                value={genelAyar?.pasif_kullanicilar}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.pasif_kullanicilar && formik.touched.pasif_kullanicilar ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaUserAlt /></InputAdornment>,
                                                }}
                                            >
                                                <MenuItem value={0}>Hayır Gizle</MenuItem>
                                                <MenuItem value={1}>Evet Göster</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                type="text"
                                                name="klinik_no"
                                                variant='outlined'
                                                label="Klinik No *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setGenelAyar(oldData => ({ ...oldData, klinik_no: e.target.value }));
                                                    formik.setFieldValue("klinik_no", e.target.value, false);
                                                }}
                                                value={genelAyar?.klinik_no}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.klinik_no && formik.touched.klinik_no ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaClock /></InputAdornment>,
                                                }}
                                                helperText={formik.errors.klinik_no && formik.touched.klinik_no && <span className='cstm-input-error-validation-msg'>{formik.errors.klinik_no}</span>}
                                                error={formik.errors.klinik_no && formik.touched.klinik_no}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                select
                                                name="para_birimi"
                                                variant='outlined'
                                                label="Para Birimi *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setGenelAyar(oldData => ({ ...oldData, para_birimi: e.target.value }));
                                                    formik.setFieldValue("para_birimi", e.target.value, false);
                                                }}
                                                value={genelAyar?.para_birimi}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.para_birimi && formik.touched.para_birimi ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaSearchDollar /></InputAdornment>,
                                                }}
                                            >
                                                <MenuItem value={1}>Türk Lirası</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4">
                                            <TextField className='form-group'
                                                select
                                                name="tum_tedavilerde_ara"
                                                variant='outlined'
                                                label="Tüm Tedavilerde Arama Yapma *"
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setGenelAyar(oldData => ({ ...oldData, tum_tedavilerde_ara: e.target.value }));
                                                    formik.setFieldValue("tum_tedavilerde_ara", e.target.value, false);
                                                }}
                                                value={genelAyar?.tum_tedavilerde_ara}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{ style: { color: formik.errors.tum_tedavilerde_ara && formik.touched.tum_tedavilerde_ara ? '#e53e3e' : '#1976d2' } }}
                                                autoComplete="off"
                                                size='small'
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaSearch /></InputAdornment>,
                                                }}
                                            >
                                                <MenuItem value={0}>Hayır Gizle</MenuItem>
                                                <MenuItem value={1}>Evet Göster</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4">
                                            <Autocomplete
                                                options={tarifeler}
                                                getOptionLabel={(option) => option.tarifeAdi}
                                                loading={true}
                                                disableClearable
                                                noOptionsText={"Seçenek Bulunamadı"}
                                                value={tarife}
                                                name="varsayilan_tarife_id"
                                                onChange={(event, newValue) => {
                                                    setTarife(newValue);
                                                    formik.setFieldValue('varsayilan_tarife_id', newValue?.id ? newValue?.id : 0, true);

                                                }}
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                size="small"
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={option.id}>
                                                            {option.tarifeAdi}
                                                        </li>
                                                    );
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label="Tarife *"
                                                    InputLabelProps={{ style: { color: formik.errors.varsayilan_tarife_id && formik.touched.varsayilan_tarife_id ? '#e53e3e' : '#1976d2' } }}
                                                    error={formik.errors.varsayilan_tarife_id && formik.touched.varsayilan_tarife_id}
                                                    helperText={formik.errors.varsayilan_tarife_id && formik.touched.varsayilan_tarife_id && <span className='cstm-input-error-validation-msg'>{formik.errors.varsayilan_tarife_id}</span>}
                                                />}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4">
                                            <Autocomplete
                                                options={cities}
                                                loading={true}
                                                disableClearable
                                                getOptionLabel={(option) => option?.il}
                                                noOptionsText={"Seçenek Bulunamadı"}
                                                value={city}
                                                name="il_id"
                                                onChange={(event, newValue) => {
                                                    event.preventDefault();
                                                    setCity(newValue);
                                                    setDistrictId(null);
                                                    setDistricts([]);
                                                    getDistricts(newValue?.id);
                                                }}
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                size="small"
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Şehir *"
                                                        InputLabelProps={{ style: { color: formik.errors.il_id && formik.touched.il_id ? '#e53e3e' : '#1976d2' } }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                                        <FormControl fullWidth className="mt-4">
                                            <Autocomplete
                                                options={districts}
                                                getOptionLabel={(option) => option?.ilce}
                                                noOptionsText={"Lütfen Şehir Seçiniz."}
                                                disableClearable
                                                value={district_id}
                                                name="ilce_id"
                                                onChange={(event, newValue) => {
                                                    setDistrictId(newValue);
                                                }}
                                                disabled={lockForm}
                                                style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                                                size="small"
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="İlçe *"
                                                        InputLabelProps={{ style: { color: formik.errors.ilce_id && formik.touched.ilce_id ? '#e53e3e' : '#1976d2' } }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                    {/* <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-3'>
                                                <Paper  style={{padding:"10px", backgroundColor: active ? "white" : "#f5f5f5"}}>
                                                    <label style={{color:"#1976d2"}}>Logo</label>
                                                    <div className='settings-logo-preview-wrap'>
                                                        <img src={file} alt="preview" style={{filter: active ? 'brightness(100%)' :  'brightness(95%)'}}/>
                                                    </div>
                                                    <FormControl fullWidth>
                                                        <input 
                                                            type="file" 
                                                            name='foto'
                                                            id="upload"
                                                            disabled={lockForm}
                                                            hidden
                                                            onChange={handleImageChange}/>
                                                        <div className='d-flex justify-content-center'>
                                                            <label className='patient-image-upload-btn' htmlFor="upload"><FaCloudUploadAlt /> Yükle</label>
                                                            <label className='patient-image-remove-btn' onClick={removeImage} >Kaldır</label>
                                                        </div>
                                                    </FormControl>
                                                </Paper>
                                    </div> */}
                                </div>
                                <div className="d-flex  align-items-center" style={{ float: "right" }}>
                                    <ButtonM color="warning" variant="outlined"
                                        onClick={formLock}
                                        style={{ color: active ? "#F39D56" : "#888E99", border: active ? "#F39D56 1px solid" : "#888E99 1px solid", marginRight: "10px", }}
                                        startIcon={<FaRegEdit />}
                                    >
                                        Düzelt
                                    </ButtonM>
                                    <ButtonM color="success" variant="outlined" type="submit" disabled={lockForm || ! can('edit-genel-ayarlar')}  >
                                    {
                                        isProcesssing ? 
                                        (
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                İşleniyor...
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <FaSave /> &nbsp;
                                                {"Kaydet"}
                                            </>
                                        )
                                    }
                                </ButtonM>
                                </div>
                            </form>
                        </div>
                }
            </TabPanel>
        :<NotPermission/>}
        </>
    );
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
export default connect(authPermissions)(SettingsGenel);