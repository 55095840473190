import React, { useEffect, useState } from "react";
import "assets/css/pages/patient-detail.css";
import { Button as ButtonM, Stack, Tooltip} from "@mui/material";
import { Card } from "reactstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { TabPanel } from "@mui/lab";
import {
    FaPlus,
    FaPrint,
    FaTrash,
    FaSearch,
} from "react-icons/fa";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { useNavigate, useParams } from "react-router-dom";
import preLoadingImg from 'assets/images/loading.gif';
import GetTrDate from "extensions/GetTRDate";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";
// date end

const PatientPrescription = (props) => {
    const {auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }

    const params = useParams();
    const MySwal = withReactContent(Swal);
    const [prescription, setPrescription] = useState([]);
    const [filteredPrescriptionMedicine, setFilteredPrescriptionMedicine] = useState([]);
    const [updateTable, setUpdateTable] = useState(false);
    const [loading,setLoading] = useState(true);
    const [patientInfo, setPatientInfo] = useState(null);
    const patientId = params?.patientId ? params.patientId : 0;
    const navigate = useNavigate();

    const createNewPrescription = (e) => {
        e.preventDefault();
        navigate('olustur');
    }

    const invoicePrescription = (e, id = null) => {
        e.preventDefault();
        if(id !== null && id > 0){
            navigate(`detay/${id}`);
        }
    }

    const deletePrescription = (e, id = null) => {
        e.preventDefault();
        e.stopPropagation()
        if(id !== null && id > 0){
            MySwal.fire({
                title: "Kayıt silinsin mi?",
                text: "silme işlemini gerçekleştirdiğinizde geri alamazsınız!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Hayır,Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    AxiosCrudActions.DeleteAsync('/receteler', id)
                        .then(result => {
                            if (result?.status === 200 && result?.data?.status) {
                                setUpdateTable(!updateTable);
                            }
                        });

                }
            })
        }
    }

    const getAllPrescription = async () => {
        await axios.get('/receteler', {params: {hasta_id: patientId}})
                .then(res => {
                    if(res?.status === 200 ){
                        setPrescription(res?.data?.data);
                        setPatientInfo(res?.data?.hasta);
                        setFilteredPrescriptionMedicine(res?.data?.data);
                    }
                }).catch(e => {
                    setPrescription([]);
                    setPatientInfo(null);
                    setFilteredPrescriptionMedicine([]);
                })
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
    }

    useEffect(() => {
        getAllPrescription();
    },[updateTable]);
    

    return (
        <>{ can('view-hastalar-recete') ? 
        <TabPanel value="4">
            <div className="patient-detail-container">
                <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-3" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                        <span className="d-flex align-items-center" style={{fontSize: '18px',fontWeight: 'normal', color: '#1172b6', opacity: '0.8'}}>
                            <strong>
                               Tüm Reçeteler&nbsp;
                            </strong>
                        </span>
                        <span className="d-inline-block">
                            <ButtonM color="success" variant="outlined" startIcon={<FaPlus />} disabled={! can('create-hastalar-recete')} onClick={createNewPrescription}>
                                Yeni Reçete 
                            </ButtonM>&nbsp;
                            <ButtonM color="warning" variant="outlined" startIcon={<FaPrint />} disabled={! can('create-hastalar-recete')} className="d-inline-block ml-2">
                                Yazdır
                            </ButtonM>
                        </span>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 900}} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" width='20%'>Ad Soyad</TableCell>
                                    <TableCell align="center" width='20%'>Doktor</TableCell>
                                    <TableCell align="center" width='20%'>Protokol No</TableCell>
                                    <TableCell align="center" width='20%'>Reçete Tarihi</TableCell>
                                    <TableCell align="center" width='20%'>İşlem</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            !loading ?
                            filteredPrescriptionMedicine?.length > 0 ? 
                            prescription.map((row) => (
                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center" width='20%'>{patientInfo}</TableCell>
                                    <TableCell align="center" width='20%'>{row?.doktor?.name}</TableCell>
                                    <TableCell align="center" width='20%'>{row?.protokol_no}</TableCell>
                                    <TableCell align="center" width='20%'>{GetTrDate(row?.recete_tarihi)}</TableCell>
                                    <TableCell align="center" width='20%'>
                                        <Stack direction="row" spacing={2} className="justify-content-center">
                                            <Tooltip title="reçete detay" arrow>
                                                <ButtonM variant="outlined" disabled={! can('edit-hastalar-recete')}
                                                    onClick={(e) => invoicePrescription(e, row?.id)}
                                                    color="info"><FaSearch /></ButtonM>
                                            </Tooltip>
                                            <Tooltip title="reçete sil" arrow>
                                                <ButtonM variant="outlined" disabled={! can('delete-hastalar-recete')}
                                                    onClick={(e) => deletePrescription(e, row?.id)}
                                                    color="error"><FaTrash /></ButtonM>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )):
                            <TableRow width="100%">
                                <TableCell colSpan={5} height={"100px"} align="center">
                                    <CustomNoRowsOverlay />
                                </TableCell>
                            </TableRow> 
                            :
                            <TableRow width="100%">
                                <TableCell>
                                    <div id="invoice-pre-loading-content">
                                        <div className="loading">
                                            <img src={preLoadingImg} alt="loading" width={110} height={110} />
                                            <div className="d-flex justify-content-center" style={{color: '#1172b6'}}>
                                                <span>Yükleniyor...</span>
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
        </TabPanel>
        :<NotPermission/>}</>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(PatientPrescription);

