
import * as yup from 'yup';  
import parse from "date-fns/parse";
const CreatePatientAccountPaymentValidationSchema = () => {
    return yup.object().shape({

        // tarih: yup.date()
        //         .transform(function (value, originalValue) {
        //         if (this.isType(value)) {
        //             return value;
        //         }
        //         const result = parse(originalValue, "dd.MM.yyyy", new Date());
        //         return result;
        //         })
        //         .typeError("Lütfen geçerli bir tarih giriniz")
        //         .required(),

        tutar:yup.number().min(1,'Sıfır değeri eklenemez').required('Lütfen para tutarı girin'),
 
   });
}   

export default CreatePatientAccountPaymentValidationSchema;

