
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import GetTrDate from "extensions/GetTRDate";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { GetTriggerActionName } from "extensions/GetTriggerActionName";
import { styled } from '@mui/material/styles';
import { tooltipClasses }  from '@mui/material/Tooltip';


const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
});


export const HastaFaturalarLogTableColumns = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        flex: 1,
        headerAlign: "center",
        minWidth:100,
        maxWidth:100,
        hideable: true,
        editable: false,
        filterable: false
    },
    {
        field: "faturaNo",
        headerName: "Fatura No",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "ettn",
        headerName: "ETTN",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "faturaTarihi",
        headerName: "Fatura Tarihi",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrFullDateAndTimeWithFormat(params?.row?.faturaTarihi + params?.row?.faturaSaati)
            )
        },
        valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params?.row?.faturaTarihi + params?.row?.faturaSaati)}`
    },
    {
        field: "hasta",
        headerName: "Hasta",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.hasta?.hasta ? params?.row?.hasta?.hasta : ""
            );
        },
        valueGetter: (params) => `${params?.row?.hasta?.hasta ? params?.row?.hasta?.hasta : ""}`
    },
    {
        field: "ad_soyad",
        headerName: "Ad Soyad",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "tc_no",
        headerName: "TC No",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "email",
        headerName: "Email",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "tel_no",
        headerName: "Tel No",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "vergiDairesi",
        headerName: "Vergi Dairesi",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "vergiNumarasi",
        headerName: "Vergi Numarasi",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "il",
        headerName: "İl",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.il?.il ? params?.row?.il?.il : ''
            )
        },
        valueGetter: (params) => `${params?.row?.il?.il ? params?.row?.il?.il : ''}`
    },
    {
        field: "ilce",
        headerName: "İlce",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.ilce?.ilce ? params?.row?.ilce?.ilce : ''
            )
        },
        valueGetter: (params) => `${params?.row?.ilce?.ilce ? params?.row?.ilce?.ilce : ''}`
    },
    {
        field: "adres",
        headerName: "Adres",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return(
                <Tooltip title={params.row.adres} arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.adres}</span>
                </Tooltip>
            )
        }        
    },
    {
        field: "faturaKalemleri",
        headerName: "Fatura Kalemleri",
        minWidth: 130,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return(
                <NoMaxWidthTooltip sx={{maxWidth: 'none'}}  title={
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 1000}} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Tarih</TableCell>
                                <TableCell align="center">Doktor</TableCell>
                                <TableCell align="center">Tedavi</TableCell>
                                <TableCell align="center">Dişler</TableCell>
                                <TableCell align="center">KDV</TableCell>
                                <TableCell align="center">Tutar</TableCell>
                                <TableCell align="center">Toplam</TableCell>
                                <TableCell align="center">Açıklama</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {params?.row?.faturaKalemleri.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{GetTrDate(row.tarih)}</TableCell>
                                <TableCell align="center">{row?.doktor?.name + (row?.doktor?.brans ? ' ' + row?.doktor?.brans : '')}</TableCell>
                                <TableCell align="center">{row.tedavi + ' - ' + row?.kokler}</TableCell>
                                <TableCell align="center">{row.disler}</TableCell>
                                <TableCell align="center">{row.kdv}</TableCell>
                                <TableCell align="center">{row.tutar}</TableCell>
                                <TableCell align="center">{row.toplam}</TableCell>
                                <TableCell align="center">{row.aciklama}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                } arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>+ {params?.row?.faturaKalemleri.length}</span>
                </NoMaxWidthTooltip>
            )
        }
    },
    {
        field: "aciklama",
        headerName: "Açıklama",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return(
                <Tooltip title={params.row.aciklama} arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.aciklama}</span>
                </Tooltip>
            )
        }        
    },
    {
        field: "action",
        headerName: "İşlem",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTriggerActionName(params?.row?.action)
            )
        },
        valueGetter: (params) => GetTriggerActionName(params?.row?.action)
    },
    {
        field: "update_text",
        headerName: "Güncelleme İşlemi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "user",
        headerName: "Kullanıcı",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.user?.name ? params?.row?.user?.name : ""
            )
        },
        valueGetter: (params) => `${params?.row?.user?.name ? params?.row?.user?.name : ""}`,
    },
    {
        field: "islem_tarihi",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)
            )
        },
        valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)}`
    },
];