import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import { Button as ButtonM } from "@mui/material";
import "assets/css/pages/definition.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { TabPanel } from "@mui/lab";
import CreateMedicineValidationSchema from "business-layer/CreateMedicineValidationSchema";
import { InputAdornment, FormControl, TextField, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaRegAddressBook,
    FaTimes,
    FaEdit,
    FaTrash,
    FaCheck,
    FaPills,
    FaFlask,
    FaClock,
    FaExclamation
} from "react-icons/fa";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const DefinitionIlac = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const columns = [
        {
            field: "id",
            headerName: "Sıra",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            align:"center",
            hideable: true,
            editable: false,
            headerAlign: "center"
        },
        {
            field: "ilac_adi",
            headerName: "İlaç Adı",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "doz",
            headerName: "Doz",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "kullanim",
            headerName: "Kullanım",
            minWidth: 200,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "status",
            headerName: "Durum",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.durum ? <FaCheck color="#1ca345" /> : <FaTimes color="#de3f44" />
                )
            }
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 250,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const deleteMedicine = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.ilac_adi + " silinsin mi?",
                        text: "silme işlemini gerçekleştirdiğinizde durumu pasif hale gelecektir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, Sil',
                        cancelButtonText: 'Hayır,Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync('/ilaclar', params.row.id)
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });

                        }
                    })
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="ilaç düzenle" arrow>
                            <ButtonM variant="outlined" onClick={(e) => editMedicine(e, params?.row)} disabled={! can('edit-ilaclar')} color="warning"><FaEdit /></ButtonM>
                        </Tooltip>
                        <Tooltip title="ilaç sil" arrow>
                            <ButtonM variant="outlined" onClick={deleteMedicine} disabled={! can('delete-ilaclar')} color="error"><FaTrash /></ButtonM>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];

    const [isProcesssing, setIsProcessing] = useState(false);
    const [aciklama, setAciklama] = useState('');
    const [modal, setModal] = useState(false);
    const [ilacId, setIlacId] = useState(0);
    const toggle = () => setModal(!modal);

    const handleAciklama = (event) => {
        setAciklama(event.target.value);
        formik.values.aciklama = event.target.value;
    }


    const initialMedicineInfo = {
        ilac_adi: '',
        doz: '',
        kullanim: '',
        aciklama: '',
    }

    // yup schema
    let medicineValidationSchema = CreateMedicineValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialMedicineInfo,
        validationSchema : medicineValidationSchema,
        onSubmit: values => {
            setIsProcessing(true);
            if (ilacId === 0) {
                AxiosCrudActions.CreateAsync('ilaclar', values).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            } else if (ilacId > 0) {
                AxiosCrudActions.UpdateAsync(`ilaclar/${ilacId}`, values).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            }
        },
    });

    const addNewMedicine = (event) => {
        event.preventDefault();
        formik.setValues({
            ilac_adi: '',
            doz: '',
            kullanim: '',
            aciklama: '',
        }, false)
        formik.setErrors({});
        setIlacId(0);
        setAciklama('');
        toggle();
    }

    const editMedicine = (event, values) => {
        event.preventDefault();
        formik.setValues({
            ilac_adi: values?.ilac_adi ? values?.ilac_adi : '',
            doz: values?.doz ? values?.doz : '',
            kullanim: values?.kullanim ? values?.kullanim : '',
            aciklama: values?.aciklama ? values?.aciklama : '',
            durum: values?.durum ? values?.durum : 0,
        }, true)
        formik.setErrors({});
        setIlacId(values?.id);
        setAciklama(values?.aciklama ? values.aciklama : '');
        toggle();
    }

    // data-grid state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    // side effect
    useEffect(() => {
        axios.get("ilaclar", {
            params: {
                filterModel: JSON.stringify(filterModel),
                pageSize: pageSize,
                page: page,
                sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    setData(res.data.data);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, [sortModel, filterModel, pageSize, page, updateDataGrid]);


    return (
        <TabPanel value="5" className="definitions-container">
            {
                isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
            }
            <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                    <strong className="fs-5 fw-normal text text-secondary">Tüm İlaçlar &nbsp;</strong>
                </span>
                {can('view-ilaclar') ? 
                    <ButtonM color="success" variant="outlined" startIcon={<FaPlus />} disabled={! can('create-ilaclar')}  onClick={addNewMedicine}>
                        Yeni İlaç
                    </ButtonM>
                :''}
                <Modal isOpen={modal} 
                       toggle={toggle} 
                       size="md"
                       modalTransition={{ timeout: 1 }}
                       backdropTransition={{ timeout: 1 }}
                       >
                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>İlaç Bilgileri</ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                                    <FormControl fullWidth className="mt-3">
                                        <TextField className='form-group'
                                            type="text"
                                            name="ilac_adi"
                                            variant='outlined'
                                            label="İlaç Adı"
                                            autoComplete="off"
                                            InputLabelProps={{ style: { color: formik.errors.ilac_adi && formik.touched.ilac_adi ? '#e53e3e' : '#1976d2' } }}
                                            onChange={formik.handleChange}
                                            value={formik.values.ilac_adi}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                            }}
                                            error={formik.errors.ilac_adi && formik.touched.ilac_adi}
                                            helperText={formik.errors.ilac_adi && formik.touched.ilac_adi && <span className='cstm-input-error-validation-msg'>{formik.errors.ilac_adi}</span>}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className="mt-3">
                                        <TextField className='form-group'
                                            type="text"
                                            name="doz"
                                            variant='outlined'
                                            label="Doz"
                                            autoComplete="off"
                                            InputLabelProps={{ style: { color: formik.errors.doz && formik.touched.doz ? '#e53e3e' : '#1976d2' } }}
                                            onChange={formik.handleChange}
                                            value={formik.values.doz}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><FaFlask /></InputAdornment>,
                                            }}
                                            error={formik.errors.doz && formik.touched.doz}
                                            helperText={formik.errors.doz && formik.touched.doz && <span className='cstm-input-error-validation-msg'>{formik.errors.doz}</span>}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className="mt-3">
                                        <TextField className='form-group'
                                            type="text"
                                            name="kullanim"
                                            variant='outlined'
                                            label="Kullanım"
                                            autoComplete="off"
                                            InputLabelProps={{ style: { color: formik.errors.kullanim && formik.touched.kullanim ? '#e53e3e' : '#1976d2' } }}
                                            onChange={formik.handleChange}
                                            value={formik.values.kullanim}
                                            onBlur={formik.handleBlur}
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><FaClock /></InputAdornment>,
                                            }}
                                            error={formik.errors.kullanim && formik.touched.kullanim}
                                            helperText={formik.errors.kullanim && formik.touched.kullanim && <span className='cstm-input-error-validation-msg'>{formik.errors.kullanim}</span>}
                                        />
                                    </FormControl>
                                </div>

                            </div>
                            <div>
                                <FormControl fullWidth >
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="Açıklama"
                                        multiline
                                        minRows={3}
                                        name="aciklama"
                                        placeholder="Açıklama..."
                                        autoComplete="off"
                                        InputLabelProps={{ style: { color: formik.errors.aciklama && formik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                                        value={aciklama}
                                        onChange={handleAciklama}
                                        onBlur={formik.handleBlur}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" ><FaRegAddressBook /></InputAdornment>,
                                        }}
                                        error={formik.errors.aciklama && formik.touched.aciklama}
                                        helperText={formik.errors.aciklama && formik.touched.aciklama && <span className='cstm-input-error-validation-msg'>{formik.errors.aciklama}</span>}
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <div>
                                    {
                                        ilacId > 0 &&
                                        <FormControl fullWidth>
                                            <TextField
                                                name='durum'
                                                select
                                                InputLabelProps={{ style: { color: '#1976d2' } }}
                                                label="Durum"
                                                size='small'
                                                defaultValue={formik.values.durum ? 1 : 0}
                                                value={formik.values.durum ? 1 : 0}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><FaExclamation /></InputAdornment>,
                                                }}>
                                                <MenuItem value={0}>Pasif</MenuItem>
                                                <MenuItem value={1}>Aktif</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    }
                                </div>
                            </div>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={ilacId > 0 ? ! can('edit-ilaclar') : ! can('create-ilaclar')} className="d-flex align-items-center" >
                                {
                                    isProcesssing ? 
                                    (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                            İşleniyor...
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <FaCloudUploadAlt /> &nbsp;
                                            {ilacId > 0 ? "Güncelle" :"Kaydet"}
                                        </>
                                    )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>

            {
                useMemo(() => 
                <>
                    {can('view-ilaclar') ?  
                        <ThemeProvider theme={theme}>
                            <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                                <DataGrid
                                    autoHeight={data.length > 0 ? true : false}
                                    sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                    loading={isLoading}
                                    rows={data}
                                    columns={columns}
                                    // pagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowCount={totalCount} //serverdan gelen data total count gelecek
                                    page={page}
                                    onPageChange={(newPage) => setPage(newPage)}
                                    pagination
                                    paginationMode="server"
                                    // sorting
                                    sortModel={sortModel}
                                    sortingMode="server"
                                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                    // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                    filterModel={filterModel}
                                    filterMode="server"
                                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                    // selection checkbox
                                    //checkboxSelection={true}
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                    }}
                                    // features
                                    experimentalFeatures={{ newEditingApi: true }}
                                    // toolbar components
                                    components={{
                                        Toolbar: GridToolbar,
                                        NoRowsOverlay: CustomNoRowsOverlay,
                                        LoadingOverlay: LoadingOverlay,
                                    }}
                                    disableColumnFilter
                                    disableSelectionOnClick
                                    // component props
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            printOptions: {
                                                fields: ["id", "ilac_adi", "doz", "kullanim"],
                                                fileName: "İlaclar",
                                                hideFooter: true,
                                                hideToolbar: true,
                                                disableToolbarButton: true,
                                            }, //sadece firsname  ve lastname alanını alır.
                                            csvOptions: {
                                                disableToolbarButton: false,
                                                fields: ["ilac_adi", "doz", "kullanim"],
                                                fileName: 'ilaclar',
                                                utf8WithBom: true,
                                            }, //sadece firstname ve lastName alanını alır.
                                            excelOptions: { disableToolbarButton: true },
                                            // quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                />
                            </Box>
                        </ThemeProvider>
                    :<NotPermission/>}
                </>
                    , [sortModel, filterModel, pageSize, page, updateDataGrid, data]
                )
            }


        </TabPanel>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
  export default connect(authPermissions)(DefinitionIlac);
