
import * as yup from 'yup';  

const CreateTariffValidationSchema = () => {
    return yup.object().shape({

        tarife_adi: yup.string()
                  .min(6,"Tarife ad en az 6 karakter olmalıdır.")
                  .max(50,"Tarife ad en fazla 50 karakter olmalıdır.")
                  .required("Lütfen geçerli tarife ad bilgisi giriniz."),

        

   });
}   

export default CreateTariffValidationSchema;