import { Tooltip } from "@mui/material";
import GetTrDate from "extensions/GetTRDate";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { GetTriggerActionName } from "extensions/GetTriggerActionName";

export const HastalarLogTableColumns = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        flex: 1,
        headerAlign: "center",
        minWidth:100,
        maxWidth:100,
        hideable: true,
        editable: false,
        filterable: false
    },
    {
        field: "ad_soyad",
        headerName: "Ad Soyad",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "tc_no",
        headerName: "TC Kimlik",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "dogum_tarihi",
        headerName: "Dogum Tarihi",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrDate(params?.row?.dogum_tarihi)
            )
        },
        valueGetter: (params) => `${GetTrDate(params?.row?.dogum_tarihi)}`
    },
    {
        field: "doktor",
        headerName: "Hekim",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.doktor?.name ? params?.row?.doktor?.name : ''
            )
        },
        valueGetter: (params) => `${params?.row?.doktor?.name ? params?.row?.doktor?.name : ''}`
    },
    {
        field: "cinsiyet",
        headerName: "Cinsiyet",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "tel_no",
        headerName: "Tel No",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "e_mail",
        headerName: "Email",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "il",
        headerName: "Şehir",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.il?.il ? params?.row?.il?.il : ""
            )
        },
        valueGetter: (params) => `${params?.row?.il?.il}`,
    },
    {
        field: "ilce",
        headerName: "İlçe",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.ilce?.ilce ? params?.row?.ilce?.ilce : ""
            )
        },
        valueGetter: (params) => `${params?.row?.ilce?.ilce}`,
    },
    {
        field: "adres",
        headerName: "Adres",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return(
                <Tooltip title={params.row.adres} arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.adres}</span>
                </Tooltip>
            )
        }
        
    },
    {
        field: "tarife",
        headerName: "Tarife",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.tarife?.tarifeAdi ? params?.row?.tarife?.tarifeAdi : ""
            )
        },
        valueGetter: (params) => `${params?.row?.tarife?.tarifeAdi}`,
    },
    {
        field: "tedavi_durumu",
        headerName: "Tedavi Durumu",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.tedavi_durumu?.name ? params?.row?.tedavi_durumu?.name : ""
            )
        },
        valueGetter: (params) => `${params?.row?.tedavi_durumu?.name}`,
    },
    {
        field: "referans_adi",
        headerName: "Referans Adi",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "kan_grubu",
        headerName: "Kan Grubu",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.kan_grubu?.name ? params?.row?.kan_grubu?.name : ""
            )
        },
        valueGetter: (params) => `${params?.row?.kan_grubu?.name}`,
    },
    {
        field: "kurum_sicil_no",
        headerName: "Kurum Sicil No",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "kontrol_periyodu",
        headerName: "Kontrol Periyodu",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.kontrol_periyodu?.name ? params?.row?.kontrol_periyodu?.name : ""
            )
        },
        valueGetter: (params) => `${params?.row?.kontrol_periyodu?.name}`,
    },
    {
        field: "durum",
        headerName: "Durum",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.durum ? "Aktif" : "Pasif"
            )
        },
        valueGetter: (params) => `${params?.row?.durum}`,
    },
    {
        field: "action",
        headerName: "İşlem",
        minWidth: 100,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTriggerActionName(params?.row?.action)
            )
        },
        valueGetter: (params) => GetTriggerActionName(params?.row?.action)
    },
    
    {
        field: "update_text",
        headerName: "Güncelleme İşlemi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "user",
        headerName: "Kullanıcı",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.user?.name
            )
        },
        valueGetter: (params) => `${params?.row?.user?.name}`,
    },
    {
        field: "islem_tarihi",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)
            )
        },
        valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)}`
    },
];