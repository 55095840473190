import { TabPanel } from "@mui/lab";
import React, { useEffect, useState } from "react";
import AxiosCrudActions from "services/AxiosCrudCore";
import { FormControl, TextField, Button as ButtonM, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import axios from "axios";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";
import { useParams } from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Anamnez = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }

    const MySwal = withReactContent(Swal);

    const params = useParams();
    const patientId = params?.patientId;

    const [questionsAndAnswer, setQuestionsAndAnswer] = useState([]);
    const [loading, setLoading] = useState(true);

    const getUserAnswer = () => {
        axios.get(`/anamnez/${patientId}`)
                    .then(res => {
                        let tempData = res?.data?.sorular.map((el,idx) => {
                            let answer = res?.data?.data.find((ans) => ans?.soru?.id == el?.id);
                            return {...el, cevap: answer?.cevap ? answer.cevap : 0, cevap_text: answer?.cevap_text ? answer.cevap_text : ""};
                        });
                        setQuestionsAndAnswer(tempData);
                        setLoading(false);
                    })
                    .catch(error => {
                        setQuestionsAndAnswer([]);
                        setLoading(false);
                    });
    };

    const saveAnamnezInfo = (e) => {
        e.preventDefault();
        MySwal.fire({
            title: "Bilgileri Kaydet!",
            text: "Anamnez bilgileri kaydedilsin mi?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Kaydet',
            cancelButtonText: 'Hayır,Vazgeç'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let tempSorular = questionsAndAnswer.map((el,idx) => {
                    return {soru_id: el?.id, cevap: el?.cevap, cevap_text: el?.cevap_text}
                });
                let data = {
                    hasta_id: patientId,
                    sorular: JSON.stringify(tempSorular)
                }
                AxiosCrudActions.CreateAsync('/anamnez', data)
                    .then(result => {
                        if (result?.status === 200 && result?.data?.status) {
                            MySwal.fire({
                                title: "Başarılı",
                                text: "Kayıt başarılı bir şekilde kaydedildi.",
                                icon: 'success'
                            })
                        }
                    });

            }
        })
    }

    
   
    useEffect(() => {
        getUserAnswer();
        // Promise.all([getUserAnswer(), getQuestions()]).then(res => setLoading(false)).catch(err => setLoading(false));
    }, []);

    return (
        <>
         {can('view-hastalar-tedavi') ? 
            <TabPanel value="6">
                {
                    loading ?
                        <div className="doctor-page-loading">
                            <LoadingOverlay />
                        </div>
                        :
                        <div className="row">
                            <div className="d-flex justify-content-between align-items-center mb-3" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                                <span className="d-flex align-items-center" style={{fontSize: '18px',fontWeight: 'normal', color: '#1172b6', opacity: '0.8'}}>
                                    <strong>
                                        Anamnez Bilgileri&nbsp;
                                    </strong>
                                </span>
                                <span className="d-inline-block">
                                    <ButtonM color="success" variant="outlined" startIcon={<FaTelegramPlane />} onClick={saveAnamnezInfo}>
                                        Kaydet 
                                    </ButtonM>&nbsp;
                                </span>
                            </div>

                            {
                                questionsAndAnswer.map((el,idx) => {
                                    return (
                                        <div className="xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-3" key={el?.id}>
                                            <div className="card p-1">
                                                <div style={{borderBottom: "1px dashed black", paddingBottom: "10px"}}><strong>Soru {el?.id}: </strong>{el?.soru}</div>
                                                <RadioGroup value={el?.cevap && el.cevap === 1 ? 1 : 0} 
                                                    onChange={(e) => {
                                                        setQuestionsAndAnswer(prevState => (
                                                            prevState.map((state) => {
                                                                if(state?.id == el?.id){
                                                                    return {...state, cevap: parseInt(e.target.value)}
                                                                }
                                                                return state;
                                                            })
                                                        ))
                                                    }}>
                                                    <div className="d-flex justify-content-between">
                                                        <span><FormControlLabel value={1} control={<Radio />} label="Evet" /></span>
                                                        <span><FormControlLabel value={0} control={<Radio />} label="Hayır" /></span>
                                                    </div>
                                                </RadioGroup>
                                                <FormControl fullWidth className="mt-2" >
                                                    <TextField className='form-group'
                                                        type="text"
                                                        variant='outlined'
                                                        onChange={(e) => {
                                                            setQuestionsAndAnswer(prevState => (
                                                                prevState.map((state) => {
                                                                    if(state?.id == el?.id){
                                                                        return {...state, cevap_text: e.target.value}
                                                                    }
                                                                    return state;
                                                                })
                                                            ))
                                                        }}
                                                        value={el?.cevap_text ? el.cevap_text : ''}
                                                        autoComplete="off"
                                                        size='small'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        // <div className="d-doctor-container">
                        //     {
                        //         isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
                        //     }
                        //     <div className="d-flex justify-content-between align-items-center">
                        //         <span className="d-flex align-items-center">
                        //             <strong className="fs-5 fw-normal text text-secondary">İletişim Ayarları &nbsp;</strong>
                        //         </span>
                        //     </div>
                        //     <form onSubmit={formik.handleSubmit} className="col-xl-12 col-lg-12  col-md-12 col-sm-12 col-12">
                        //         <div className="row" >
                        //             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                        //                 <FormControl fullWidth className="mt-4" >
                        //                     <TextField className='form-group'
                        //                         type="text"
                        //                         name="web_site"
                        //                         variant='outlined'
                        //                         label="Web Sitesi *"
                        //                         onChange={(e) => {
                        //                             e.preventDefault();
                        //                             setIletisimAyar(oldData => ({ ...oldData, web_site: e.target.value }));
                        //                             formik.setFieldValue("web_site", e.target.value, false);
                        //                         }}
                        //                         value={iletisimAyar?.web_site}
                        //                         onBlur={formik.handleBlur}
                        //                         InputLabelProps={{ style: { color: formik.errors.web_site && formik.touched.web_site ? '#e53e3e' : '#1976d2' } }}
                        //                         autoComplete="off"
                        //                         size='small'
                        //                         disabled={lockForm}
                        //                         style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                        //                         InputProps={{
                        //                             startAdornment: <InputAdornment position="start" ><FaDesktop /></InputAdornment>,
                        //                         }}

                        //                         helperText={formik.errors.web_site && formik.touched.web_site && <span className='cstm-input-error-validation-msg'>{formik.errors.web_site}</span>}
                        //                         error={formik.errors.web_site && formik.touched.web_site}
                        //                     />
                        //                 </FormControl>
                        //             </div>
                        //             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                        //                 <FormControl fullWidth className="mt-4">
                        //                     <TextField className='form-group'
                        //                         type="text"
                        //                         name="telefon"
                        //                         variant='outlined'
                        //                         label="Telefon *"
                        //                         onChange={(e) => {
                        //                             setIletisimAyar(oldData => ({ ...oldData, telefon: e.target.value }));
                        //                             formik.setFieldValue("telefon", e.target.value, false);
                        //                         }}
                        //                         value={iletisimAyar?.telefon}
                        //                         onBlur={formik.handleBlur}
                        //                         InputLabelProps={{ style: { color: formik.errors.telefon && formik.touched.telefon ? '#e53e3e' : '#1976d2' } }}
                        //                         autoComplete="off"
                        //                         size='small'
                        //                         disabled={lockForm}
                        //                         style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                        //                         InputProps={{
                        //                             inputComponent: CustomPhoneMask,
                        //                             startAdornment: <InputAdornment position="start" ><FaPhoneAlt /></InputAdornment>,
                        //                         }}
                        //                         helperText={formik.errors.telefon && formik.touched.telefon && <span className='cstm-input-error-validation-msg'>{formik.errors.telefon}</span>}
                        //                         error={formik.errors.telefon && formik.touched.telefon}
                        //                     />
                        //                 </FormControl>
                        //             </div>
                        //             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                        //                 <FormControl fullWidth className="mt-4">
                        //                     <TextField className='form-group'
                        //                         type="text"
                        //                         name="mobil"
                        //                         variant='outlined'
                        //                         label="Mobil"
                        //                         onChange={(e) => {
                        //                             setIletisimAyar(oldData => ({ ...oldData, mobil: e.target.value }));
                        //                             formik.setFieldValue("mobil", e.target.value, false);
                        //                         }}
                        //                         value={iletisimAyar?.mobil}
                        //                         onBlur={formik.handleBlur}
                        //                         InputLabelProps={{ style: { color: formik.errors.mobil && formik.touched.mobil ? '#e53e3e' : '#1976d2' } }}
                        //                         autoComplete="off"
                        //                         size='small'
                        //                         disabled={lockForm}
                        //                         style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                        //                         InputProps={{
                        //                             inputComponent: CustomPhoneMask,
                        //                             startAdornment: <InputAdornment position="start" ><FaMobileAlt /></InputAdornment>,
                        //                         }}
                        //                     />
                        //                 </FormControl>
                        //             </div>
                        //             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                        //                 <FormControl fullWidth className="mt-4">
                        //                     <TextField
                        //                         type="text"
                        //                         name="faks"
                        //                         variant='outlined'
                        //                         label="Faks"
                        //                         onChange={(e) => {
                        //                             setIletisimAyar(oldData => ({ ...oldData, faks: e.target.value }));
                        //                             formik.setFieldValue("faks", e.target.value, false);
                        //                         }}
                        //                         value={iletisimAyar?.faks}
                        //                         onBlur={formik.handleBlur}
                        //                         InputLabelProps={{ style: { color: formik.errors.faks && formik.touched.faks ? '#e53e3e' : '#1976d2' } }}
                        //                         autoComplete="off"
                        //                         size='small'
                        //                         disabled={lockForm}
                        //                         style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                        //                         InputProps={{
                        //                             inputComponent: CustomPhoneMask,
                        //                             startAdornment: <InputAdornment position="start" ><FaFax /></InputAdornment>,
                        //                         }}
                        //                     />
                        //                 </FormControl>
                        //             </div>
                        //             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                        //                 <FormControl fullWidth className="mt-4">
                        //                     <TextField className='form-group'
                        //                         type="text"
                        //                         name="email"
                        //                         variant='outlined'
                        //                         label="Email *"
                        //                         onChange={(e) => {
                        //                             e.preventDefault();
                        //                             setIletisimAyar(oldData => ({ ...oldData, email: e.target.value }));
                        //                             formik.setFieldValue("email", e.target.value, false);
                        //                         }}
                        //                         value={iletisimAyar?.email}
                        //                         onBlur={formik.handleBlur}
                        //                         InputLabelProps={{ style: { color: formik.errors.email && formik.touched.email ? '#e53e3e' : '#1976d2' } }}
                        //                         autoComplete="off"
                        //                         size='small'
                        //                         disabled={lockForm}
                        //                         style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                        //                         InputProps={{
                        //                             startAdornment: <InputAdornment position="start" ><FaEnvelopeOpen /></InputAdornment>,
                        //                         }}
                        //                         helperText={formik.errors.email && formik.touched.email && <span className='cstm-input-error-validation-msg'>{formik.errors.email}</span>}
                        //                         error={formik.errors.email && formik.touched.email}
                        //                     />
                        //                 </FormControl>
                        //             </div>
                        //             <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-4">
                        //                 <FormControl fullWidth className="mt-4">
                        //                     <TextField className='form-group'
                        //                         type="text"
                        //                         name="adres"
                        //                         variant='outlined'
                        //                         label="Adres *"
                        //                         onChange={(e) => {
                        //                             e.preventDefault();
                        //                             setIletisimAyar(oldData => ({ ...oldData, adres: e.target.value }));
                        //                             formik.setFieldValue("adres", e.target.value, false);
                        //                         }}
                        //                         value={iletisimAyar?.adres}
                        //                         onBlur={formik.handleBlur}
                        //                         InputLabelProps={{ style: { color: formik.errors.adres && formik.touched.adres ? '#e53e3e' : '#1976d2' } }}
                        //                         autoComplete="off"
                        //                         size='small'
                        //                         disabled={lockForm}
                        //                         style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                        //                         InputProps={{
                        //                             startAdornment: <InputAdornment position="start" ><FaMapMarkedAlt /></InputAdornment>,
                        //                         }}
                        //                         helperText={formik.errors.adres && formik.touched.adres && <span className='cstm-input-error-validation-msg'>{formik.errors.adres}</span>}
                        //                         error={formik.errors.adres && formik.touched.adres}
                        //                     />
                        //                 </FormControl>
                        //             </div>
                        //             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                        //                 <FormControl fullWidth className="mt-4">
                        //                     <TextField className='form-group'
                        //                         type="text"
                        //                         name="facebook"
                        //                         variant='outlined'
                        //                         label="Facebook"
                        //                         onChange={(e) => {
                        //                             e.preventDefault();
                        //                             setIletisimAyar(oldData => ({ ...oldData, facebook: e.target.value }));
                        //                             formik.setFieldValue("facebook", e.target.value, false);
                        //                         }}
                        //                         value={iletisimAyar?.facebook}
                        //                         onBlur={formik.handleBlur}
                        //                         InputLabelProps={{ style: { color: formik.errors.facebook && formik.touched.facebook ? '#e53e3e' : '#1976d2' } }}
                        //                         autoComplete="off"
                        //                         size='small'
                        //                         disabled={lockForm}
                        //                         style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                        //                         InputProps={{
                        //                             startAdornment: <InputAdornment position="start" ><FaFacebookF /></InputAdornment>,
                        //                         }}
                        //                     />
                        //                 </FormControl>
                        //             </div>
                        //             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                        //                 <FormControl fullWidth className="mt-4">
                        //                     <TextField className='form-group'
                        //                         type="text"
                        //                         name="twitter"
                        //                         variant='outlined'
                        //                         label="Twitter"
                        //                         onChange={(e) => {
                        //                             e.preventDefault();
                        //                             setIletisimAyar(oldData => ({ ...oldData, twitter: e.target.value }));
                        //                             formik.setFieldValue("twitter", e.target.value, false);
                        //                         }}
                        //                         value={iletisimAyar?.twitter}
                        //                         onBlur={formik.handleBlur}
                        //                         InputLabelProps={{ style: { color: formik.errors.twitter && formik.touched.twitter ? '#e53e3e' : '#1976d2' } }}
                        //                         autoComplete="off"
                        //                         size='small'
                        //                         disabled={lockForm}
                        //                         style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                        //                         InputProps={{
                        //                             startAdornment: <InputAdornment position="start" ><FaTwitter /></InputAdornment>,
                        //                         }}
                        //                     />
                        //                 </FormControl>
                        //             </div>
                        //             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pb-4">
                        //                 <FormControl fullWidth className="mt-4">
                        //                     <TextField className='form-group'
                        //                         type="text"
                        //                         name="instagram"
                        //                         variant='outlined'
                        //                         label="Instagram"
                        //                         onChange={(e) => {
                        //                             e.preventDefault();
                        //                             setIletisimAyar(oldData => ({ ...oldData, instagram: e.target.value }));
                        //                             formik.setFieldValue("instagram", e.target.value, false);
                        //                         }}
                        //                         value={iletisimAyar?.instagram}
                        //                         onBlur={formik.handleBlur}
                        //                         InputLabelProps={{ style: { color: formik.errors.instagram && formik.touched.instagram ? '#e53e3e' : '#1976d2' } }}
                        //                         autoComplete="off"
                        //                         size='small'
                        //                         disabled={lockForm}
                        //                         style={{ backgroundColor: active ? "white" : "#f5f5f5" }}
                        //                         InputProps={{
                        //                             startAdornment: <InputAdornment position="start" ><FaInstagram /></InputAdornment>,
                        //                         }}
                        //                     />
                        //                 </FormControl>
                        //             </div>
                        //         </div>
                        //         <div className="d-flex  align-items-center" style={{ float: "right" }}>
                        //             <ButtonM color="warning" variant="outlined"
                        //                 onClick={formLock}
                        //                 style={{ color: active ? "#F39D56" : "#888E99", border: active ? "#F39D56 1px solid" : "#888E99 1px solid", marginRight: "10px", }}
                        //                 startIcon={<FaRegEdit />}
                        //             >
                        //                 Düzelt
                        //             </ButtonM>
                        //             <ButtonM color="success" variant="outlined" type="submit" disabled={lockForm || ! can('edit-iletisim-ayarlar')} >
                        //             {
                        //                 isProcesssing ? 
                        //                 (
                        //                     <>
                        //                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        //                         İşleniyor...
                        //                     </>
                        //                 )
                        //                 :
                        //                 (
                        //                     <>
                        //                         <FaSave /> &nbsp;
                        //                         {"Kaydet"}
                        //                     </>
                        //                 )
                        //             }
                        //         </ButtonM>
                        //         </div>
                        //     </form>
                        // </div>
                }
            </TabPanel>
         :<NotPermission/>}
        </>
    );
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
export default connect(authPermissions)(Anamnez);