import React,{useEffect, useState} from "react";
import 'assets/css/header-nav/header-nav.css';
import defaultProfile from 'assets/images/profile-default.jpg';
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { LogoutAuthAction } from "redux/actions/AuthAction";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { Button as ButtonM, Tooltip, FormControl, TextField, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import UserValidationSchema from "business-layer/UserValidationSchema";
import {FaRegEyeSlash,FaRegEye, FaLock, FaPhoneAlt, FaShareAlt, FaMapMarkerAlt, FaInfo, FaEnvelope, FaRegUser, FaPowerOff, FaBars,FaSearch,FaRegDotCircle, FaCloudUploadAlt, FaTimes, FaPercentage} from 'react-icons/fa';
import CustomPhoneMask from "Utils/PhoneMask";


const  HeaderNav = (props) => {
    

    const { auth , logout} = props;

    let navigate = useNavigate();

    const [toggleProfileInfo,setToggleProfileInfo] = useState(false);

    const [profileDropdownStyle, setProfileDropdownStyle] = useState({
        maxHeight: '0',
        overflow: 'hidden',
        transition: 'max-height 0.2s ease-out',
    })

    const MySwal = withReactContent(Swal);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [isProcesssing, setIsProcessing] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    // handle profile dropdown
    const handleProfileDropdown = () => {
        setToggleProfileInfo(!toggleProfileInfo);
        if(!toggleProfileInfo){
            setProfileDropdownStyle({
                maxHeight: '300px',
                overflow: 'auto',
                transition: 'max-height 0.2s ease-out',
            });
        }else{
            setProfileDropdownStyle({
                maxHeight: '0',
                overflow: 'hidden',
                transition: 'max-height 0.2s ease-out',
            });
        }
    }
    // handle sidebar menu 
    const handleSidebarMenu = () => {
        props.setSideBarStyle({
            left: '0',
            transition: 'left 0.4s ease'
        });
    }

    // profile area
     // yup schema
     let userValidationSchema = UserValidationSchema();
     // formik schema
     const formik = useFormik({
         initialValues: {
            name: auth?.user?.user?.name,
            email: auth?.user?.user?.email,
            hakedis: auth?.user?.user?.hakedis ? parseInt(auth?.user?.user?.hakedis) : null,
            password: '',
            password_confirmation:'',
            tel_no: auth?.user?.user?.tel_no,
            adres: auth?.user?.user?.adres,
            brans: auth?.user?.user?.brans,
            durum: 1,
            isPasswordValid: false,
         },
         validationSchema : userValidationSchema,
         onSubmit: values => {
            if(formik.values.password !== formik.values.password_confirmation){
                formik.setErrors({password_confirmation:"Şifre uyuşmuyor"})
            }else if(auth?.user?.user?.brans !== null && auth?.user?.user?.brans !== "" && ((!formik.values.brans) || formik?.values?.brans.length < 3) ){
                formik.setErrors({brans:"Branş min 3 karakter olmalıdır."})
            }else{
                MySwal.fire({
                    title: "Uyarı",
                    text: "Hesap bilgileri güncellensin mi?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: "Evet, Güncelle",
                    cancelButtonText: 'Hayır,Vazgeç'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const { isPasswordValid, ...data } = values; // use destructoring method instead of removing element from object
                        setIsProcessing(true);
                        AxiosCrudActions.UpdateAsync(`kullanicilar/${auth?.user?.user?.id}`, data).then(result => {
                            setIsProcessing(false);
                            if (result?.data?.status) {
                                toggle();
                                MySwal.fire({
                                    title: "Başarılı",
                                    text: "Hesap bilgileri başarılı bir şekilde güncellendi. Lütfen tekrar giriş yapınız.",
                                    icon: 'success'
                                })
                                logout(navigate);
                            }else{
                                MySwal.fire({
                                    title: "Hata",
                                    text: "Hesap bilgileri güncellenemedi." + result?.response?.data?.message,
                                    icon: 'error'
                                })
                            }
                        });
                    }
                })
            }
         },
     });

    const updateProfile = (e) => {
        e.preventDefault();
        formik.setValues({
            name: auth?.user?.user?.name,
            email: auth?.user?.user?.email,
            hakedis: auth?.user?.user?.hakedis ? parseInt(auth?.user?.user?.hakedis) : null,
            password: '',
            password_confirmation:'',
            tel_no: auth?.user?.user?.tel_no,
            adres: auth?.user?.user?.adres,
            brans: auth?.user?.user?.brans,
            durum: 1,
            isPasswordValid: false,
        }, true)
        formik.setErrors({});
        toggle();
    } 

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            if (toggleProfileInfo)  {
                //setToggleProfileInfo(!toggleProfileInfo);
                setProfileDropdownStyle({
                    maxHeight: '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.2s ease-out',
                });
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[toggleProfileInfo]);

    return (
        <div className="header-wrap">
            <span className="info-menu-btn">
                <FaRegDotCircle  />
            </span>
            <button id="toggleSidebarBtn" type="button" onClick={handleSidebarMenu}>
                <FaBars />
            </button>
            <div className="nav-wrap">
                <button className="profile-wrap" type="button" onClick={handleProfileDropdown}>
                    {/* <span type="button" className="navigation-element calendar" >
                        <FaRegCalendar />
                    </span>
                    <span className="navigation-element notification" type="button">
                        <FaRegBell />
                        <span className="cstm-badge">33</span>
                    </span> */}
                    <span className="info">
                        <span className="user-name">
                            <span data-name>{auth.isLoggedIn && auth.user.user.name}</span>
                            <br />
                            <span className="user-role" data-role>{auth?.user?.user?.brans !== null && auth?.user?.user?.brans !== "" ? "Doktor" : "Kullanıcı"}</span>
                        </span>
                    </span>
                    <div className="avatar-image">
                        <img src={defaultProfile} alt="profile" />
                        <span className="status active"></span>
                    </div>

                     {/* dropdown menu */}
                     <ul className="cstm-dropdown-menu" style={profileDropdownStyle}>
                        <li>
                            <Link to="/" onClick={(e) => updateProfile(e)}><FaRegUser /> Hesabım</Link>
                        </li>
                        
                        <li>
                            <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    logout(navigate);
                                }}><FaPowerOff /> Çıkış</a>
                        </li>
                    </ul>

                </button>
                <div className="search-area">
                    <div className="input">
                        <input type="text" className="cstm-inpt"/>
                        <button type="button" className="search-btn">
                            <FaSearch />
                        </button>
                    </div>
                </div>

                {/* profile modal */}
                <Modal isOpen={modal} 
                       toggle={toggle} 
                       size="md"
                       modalTransition={{ timeout: 1 }}
                       backdropTransition={{ timeout: 1 }}
                       >
                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Hesap Bilgileri</ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                           <div className="d-flex justify-content-center">
                                <div className="col-11">
                                    <div className="row">
                                        <div className="mt-1">
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="name"
                                                    variant='outlined'
                                                    label="Ad Soyad"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.name && formik.touched.name ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.name}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaInfo /></InputAdornment>,
                                                    }}
                                                    error={formik.errors.name && formik.touched.name}
                                                    helperText={formik.errors.name && formik.touched.name && <span className='cstm-input-error-validation-msg'>{formik.errors.name}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="email"
                                                    variant='outlined'
                                                    disabled
                                                    label="Email"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.email && formik.touched.email ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaEnvelope /></InputAdornment>,
                                                    }}
                                                    error={formik.errors.email && formik.touched.email}
                                                    helperText={formik.errors.email && formik.touched.email && <span className='cstm-input-error-validation-msg'>{formik.errors.email}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="tel_no"
                                                    variant='outlined'
                                                    label="Telefon"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.tel_no && formik.touched.tel_no ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.tel_no}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        inputComponent: CustomPhoneMask,
                                                        startAdornment: <InputAdornment position="start" ><FaPhoneAlt /></InputAdornment>,
                                                    }}
                                                    error={formik.errors.tel_no && formik.touched.tel_no}
                                                    helperText={formik.errors.tel_no && formik.touched.tel_no && <span className='cstm-input-error-validation-msg'>{formik.errors.tel_no}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        {
                                            auth?.user?.user?.brans !== null && auth?.user?.user?.brans !== "" &&
                                            <>
                                                <div>
                                                    <FormControl fullWidth className="mt-3">
                                                        <TextField className='form-group'
                                                            type="text"
                                                            name="brans"
                                                            variant='outlined'
                                                            label="Branş"
                                                            autoComplete="off"
                                                            InputLabelProps={{ style: { color: formik.errors.brans && formik.touched.brans ? '#e53e3e' : '#1976d2' } }}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.brans}
                                                            onBlur={formik.handleBlur}
                                                            size='small'
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start" ><FaShareAlt /></InputAdornment>,
                                                            }}
                                                            error={formik.errors.brans && formik.touched.brans}
                                                            helperText={formik.errors.brans && formik.touched.brans && <span className='cstm-input-error-validation-msg'>{formik.errors.brans}</span>}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div>
                                                    <FormControl fullWidth className="mt-3">
                                                        <TextField className='form-group'
                                                            type="text"
                                                            name="hakedis"
                                                            variant='outlined'
                                                            disabled
                                                            label="Hakediş Yüzdesi"
                                                            autoComplete="off"
                                                            InputLabelProps={{ style: { color: formik.errors.hakedis && formik.touched.hakedis ? '#e53e3e' : '#1976d2' } }}
                                                            value={formik.values.hakedis}
                                                            size='small'
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start" ><FaPercentage /></InputAdornment>,
                                                            }}
                                                            error={formik.errors.hakedis && formik.touched.hakedis}
                                                            helperText={formik.errors.hakedis && formik.touched.hakedis && <span className='cstm-input-error-validation-msg'>{formik.errors.hakedis}</span>}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </>
                                        }
                                        <div>
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    variant='outlined'
                                                    label="Şifre"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.password && formik.touched.password ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaLock /></InputAdornment>,
                                                        endAdornment: <Tooltip title={showPassword ? "Şifreyi gizle" : "Şifreyi göster"} arrow>
                                                                        <button type="button" onClick={togglePassword} style={{background: 'none', border: 'none', outline: 'none'}}>
                                                                            {showPassword ? <FaRegEye /> : <FaRegEyeSlash /> }
                                                                        </button>
                                                                      </Tooltip>,
                                                    }}
                                                    error={formik.errors.password && formik.touched.password}
                                                    helperText={formik.errors.password && formik.touched.password && <span className='cstm-input-error-validation-msg'>{formik.errors.password}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    type={showPassword ? "text" : "password"}
                                                    name="password_confirmation"
                                                    variant='outlined'
                                                    label="Şifre (Tekrar)"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.password_confirmation && formik.touched.password_confirmation ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password_confirmation}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaLock /></InputAdornment>,
                                                        endAdornment: <Tooltip title={showPassword ? "Şifreyi gizle" : "Şifreyi göster"} arrow>
                                                                        <button type="button" onClick={togglePassword} style={{background: 'none', border: 'none', outline: 'none'}}>
                                                                            {showPassword ? <FaRegEye /> : <FaRegEyeSlash /> }
                                                                        </button>
                                                                      </Tooltip>,
                                                    }}
                                                    error={formik.errors.password_confirmation && formik.touched.password_confirmation}
                                                    helperText={formik.errors.password_confirmation && formik.touched.password_confirmation && <span className='cstm-input-error-validation-msg'>{formik.errors.password_confirmation}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mt-2">
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    name="adres"
                                                    multiline
                                                    rows={3}
                                                    variant='outlined'
                                                    label="Adres"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.adres && formik.touched.adres ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik?.values?.adres !== null ? formik.values.adres : ""}
                                                    onBlur={formik.handleBlur}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaMapMarkerAlt /></InputAdornment>,
                                                    }}
                                                    error={formik.errors.adres && formik.touched.adres}
                                                    helperText={formik.errors.adres && formik.touched.adres && <span className='cstm-input-error-validation-msg'>{formik.errors.adres}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                           </div>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} className="d-flex align-items-center" >
                                {
                                    isProcesssing ? 
                                    (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                            İşleniyor...
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <FaCloudUploadAlt /> &nbsp; Güncelle
                                        </>
                                    )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.authState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (navigate) => {
            dispatch(LogoutAuthAction(navigate))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);