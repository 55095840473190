import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import { Button as ButtonM} from "@mui/material";
import 'assets/css/auth/login.css';
import "assets/css/pages/expense.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { InputAdornment, FormControl, TextField } from '@mui/material';
import { useFormik } from 'formik';
import {FaRegEyeSlash,FaRegEye, FaLock, FaPhoneAlt, FaIdCard} from 'react-icons/fa';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaTimes,
    FaEdit,
    FaTrash,
    FaMapMarkerAlt,
    FaCheck,
    FaInfo,
    FaEnvelope
} from "react-icons/fa";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";
import UserValidationSchema from "business-layer/UserValidationSchema";
import CustomPhoneMask from "Utils/PhoneMask";


const Users = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const columns = [
        {
            field: "id",
            headerName: "ID",
            align: "center",
            flex: 1,
            headerAlign: "center",
            minWidth:100,
            maxWidth:100,
            hideable: true,
            editable: false,
            filterable: false
        },
        {
            field: "name",
            headerName: "Ad Soyad",
            minWidth: 180,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        
        {
            field: "tel_no",
            headerName: "Telefon",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "adres",
            headerName: "Adres",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: true, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return(
                    <Tooltip title={params.row.adres} arrow>
                        <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.adres}</span>
                    </Tooltip>
                )
            }     
        },
        {
            field: "durum",
            headerName: "Durum",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: false,
            renderCell: (params) => {
                return (
                    params?.row?.durum ? <FaCheck color="#1ca345" /> : <FaTimes color="#de3f44" />
                )
            },
            valueGetter: (params) => `${params?.row?.durum ? "Aktif" : "Pasif"}`
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 250,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const deleteUser = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.name + " adlı kullacı silinsin mi?",
                        text: "silme işlemini gerçekleştirdiğinizde durumu pasif hale gelecektir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, Sil',
                        cancelButtonText: 'Hayır,Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync('/kullanicilar', params.row.id)
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });

                        }
                    })
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Kullanıcı düzenle" arrow>
                            <ButtonM variant="outlined" disabled={! can('edit-kullanicilar')} onClick={(e) => editUser(e, params?.row)} color="warning"><FaEdit /></ButtonM>
                        </Tooltip>
                        <Tooltip title="Kullanıcı sil" arrow>
                            <ButtonM variant="outlined" disabled={! can('delete-kullanicilar')} onClick={deleteUser} color="error"><FaTrash /></ButtonM>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];

    const [userId, setUserId] = useState(0);
    const [isProcesssing, setIsProcessing] = useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    const initialUserInfo = {
        name: '',
        email: '',
        password: '',
        password_confirmation:'',
        tel_no: '',
        adres: '',
        durum: 1,
        isPasswordValid: true,
    }

    // yup schema
    let userValidationSchema = UserValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialUserInfo,
        validationSchema : userValidationSchema,
        onSubmit: values => {
            const { isPasswordValid, ...data } = values; // use destructoring method instead of removing element from object
            setIsProcessing(true);
            if (userId === 0) {
                AxiosCrudActions.CreateAsync('kullanicilar', data).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            } else if (userId > 0) {
                AxiosCrudActions.UpdateAsync(`kullanicilar/${userId}`, data).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            }
        },
    });

    const addNewUser = (event) => {
        event.preventDefault();
        formik.setValues(initialUserInfo, false)
        formik.setErrors({});
        setUserId(0);
        toggle();
    }

    const editUser = (event, values) => {
        event.preventDefault();
        formik.setValues({
            name: values?.name ? values?.name : '',
            email: values?.email ? values?.email : '',
            password: '',
            password_confirmation: '',
            tel_no: values?.tel_no ? values?.tel_no : '',
            adres: values?.adres ? values?.adres : '',
            isPasswordValid: false
        }, true)
        formik.setErrors({});
        setUserId(values?.id);
        toggle();   
    }

    // state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    // side effect
    useEffect(() => {
        axios.get("kullanicilar", {params:{
            filterModel: JSON.stringify(filterModel),
            pageSize: pageSize,
            page: page,
            sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
            sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
        }}).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    setData(res.data.data);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, [sortModel, filterModel, pageSize, page, updateDataGrid]);


  return (
    <div className="expense-container">
        {
              isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
        }
        <div className="d-flex justify-content-between align-items-center">
            <span className="d-flex align-items-center">
                <strong className="fs-5 fw-normal text text-secondary">&nbsp;Tüm Kullanıcılar</strong> 
            </span>
            {can('view-kullanicilar') ?  
                <ButtonM color="success" variant="outlined" disabled={! can('create-kullanicilar')} onClick={addNewUser} startIcon={<FaPlus />}>
                        Yeni Kullanıcı
                </ButtonM >
             :''}
            <Modal isOpen={modal} 
                       toggle={toggle} 
                       size="md"
                       modalTransition={{ timeout: 1 }}
                       backdropTransition={{ timeout: 1 }}
                       >
                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Kullanıcı Bilgileri</ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                           <div className="d-flex justify-content-center">
                                <div className="col-11">
                                    <div className="row">
                                        <div className="mt-1">
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="name"
                                                    variant='outlined'
                                                    label="Ad Soyad"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.name && formik.touched.name ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.name}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaInfo /></InputAdornment>,
                                                    }}
                                                    error={formik.errors.name && formik.touched.name}
                                                    helperText={formik.errors.name && formik.touched.name && <span className='cstm-input-error-validation-msg'>{formik.errors.name}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="email"
                                                    variant='outlined'
                                                    label="Email"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.email && formik.touched.email ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaEnvelope /></InputAdornment>,
                                                    }}
                                                    error={formik.errors.email && formik.touched.email}
                                                    helperText={formik.errors.email && formik.touched.email && <span className='cstm-input-error-validation-msg'>{formik.errors.email}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="tel_no"
                                                    variant='outlined'
                                                    label="Telefon"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.tel_no && formik.touched.tel_no ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.tel_no}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        inputComponent: CustomPhoneMask,
                                                        startAdornment: <InputAdornment position="start" ><FaPhoneAlt /></InputAdornment>,
                                                    }}
                                                    error={formik.errors.tel_no && formik.touched.tel_no}
                                                    helperText={formik.errors.tel_no && formik.touched.tel_no && <span className='cstm-input-error-validation-msg'>{formik.errors.tel_no}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    variant='outlined'
                                                    label="Password"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.password && formik.touched.password ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaLock /></InputAdornment>,
                                                        endAdornment: <Tooltip title={showPassword ? "Şifreyi gizle" : "Şifreyi göster"} arrow>
                                                                        <button type="button" onClick={togglePassword} style={{background: 'none', border: 'none', outline: 'none'}}>
                                                                            {showPassword ? <FaRegEye /> : <FaRegEyeSlash /> }
                                                                        </button>
                                                                      </Tooltip>,
                                                    }}
                                                    error={formik.errors.password && formik.touched.password}
                                                    helperText={formik.errors.password && formik.touched.password && <span className='cstm-input-error-validation-msg'>{formik.errors.password}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    type={showPassword ? "text" : "password"}
                                                    name="password_confirmation"
                                                    variant='outlined'
                                                    label="Şifre (Tekrar)"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.password_confirmation && formik.touched.password_confirmation ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password_confirmation}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaLock /></InputAdornment>,
                                                        endAdornment: <Tooltip title={showPassword ? "Şifreyi gizle" : "Şifreyi göster"} arrow>
                                                                        <button type="button" onClick={togglePassword} style={{background: 'none', border: 'none', outline: 'none'}}>
                                                                            {showPassword ? <FaRegEye /> : <FaRegEyeSlash /> }
                                                                        </button>
                                                                      </Tooltip>,
                                                    }}
                                                    error={formik.errors.password_confirmation && formik.touched.password_confirmation}
                                                    helperText={formik.errors.password_confirmation && formik.touched.password_confirmation && <span className='cstm-input-error-validation-msg'>{formik.errors.password_confirmation}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mt-2">
                                            <FormControl fullWidth className="mt-3">
                                                <TextField className='form-group'
                                                    name="adres"
                                                    multiline
                                                    rows={3}
                                                    variant='outlined'
                                                    label="Adres"
                                                    autoComplete="off"
                                                    InputLabelProps={{ style: { color: formik.errors.adres && formik.touched.adres ? '#e53e3e' : '#1976d2' } }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.adres}
                                                    onBlur={formik.handleBlur}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" ><FaMapMarkerAlt /></InputAdornment>,
                                                    }}
                                                    error={formik.errors.adres && formik.touched.adres}
                                                    helperText={formik.errors.adres && formik.touched.adres && <span className='cstm-input-error-validation-msg'>{formik.errors.adres}</span>}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                           </div>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={userId > 0 ? ! can('edit-kullanicilar') :! can('create-kullanicilar')} className="d-flex align-items-center" >
                                {
                                    isProcesssing ? 
                                    (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                            İşleniyor...
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <FaCloudUploadAlt /> &nbsp;
                                            {userId > 0 ? "Güncelle" :"Kaydet"}
                                        </>
                                    )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
        </div>
        {
            useMemo(() =>
            <>
                {can('view-kullanicilar') ?  
                    <ThemeProvider theme={theme}>
                        <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                            <DataGrid
                                autoHeight={data.length > 0 ? true : false}
                                sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                loading={isLoading}
                                rows={data}
                                columns={columns}
                                // pagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowCount={totalCount} //serverdan gelen data total count gelecek
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                pagination
                                paginationMode="server"
                                // sorting
                                sortModel={sortModel}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                filterModel={filterModel}
                                filterMode="server"
                                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                // selection checkbox
                                //checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                // features
                                experimentalFeatures={{ newEditingApi: true }}
                                // toolbar components
                                components={{
                                    Toolbar: GridToolbar,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    LoadingOverlay:LoadingOverlay
                                }}
                                disableColumnFilter
                                // component props
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: {
                                            fields: ["id", "name", "email", "tel_no","status"],
                                            fileName: "Kullanicilar",
                                            hideFooter: true,
                                            hideToolbar: true,
                                            disableToolbarButton: true,
                                        }, //sadece firsname  ve lastname alanını alır.
                                        csvOptions: {
                                            disableToolbarButton: false,
                                            fields: ["id", "name", "email", "tel_no","status"],
                                            fileName: 'Kullanicilar',
                                            utf8WithBom: true,
                                        }, //sadece firstname ve lastName alanını alır.
                                        excelOptions: { disableToolbarButton: true },
                                        // quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Box>
                    </ThemeProvider>
                 :<NotPermission/>}
            </>
                , [sortModel, filterModel, pageSize, page, updateDataGrid, data]
            )
        }
    </div>
  );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(Users);
