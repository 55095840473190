import * as React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from 'react-number-format';

const NumberMask = React.forwardRef(function NumberMask(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={0}
      // thousandSeparator
      //valueIsNumericString={false}
      // prefix="$"
    />
  );
});

NumberMask.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumberMask;
