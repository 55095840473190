import { toast } from "react-toastify";

const MessageType = {
    INFO: "INFO",
    SUCCESS: "SUCCESS",
    WARNING: "WARNING",
    ERROR: "ERROR",
    DEFAULT: "DEFAULT"
};

const ToastConfig = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const ToastMessage = (message, messageType) => {
    switch (messageType) {
        case MessageType.INFO:
            return toast.info(message, ToastConfig);
        case MessageType.SUCCESS:
            return toast.success(message, ToastConfig);
        case MessageType.WARNING:
            return toast.warn(message, ToastConfig);
        case MessageType.ERROR:
            return toast.error(message, ToastConfig);
        default:
            return toast(message, ToastConfig);
    }
}

const ToastComponent = {
    MessageType,
    ToastConfig,
    ToastMessage
};

export default ToastComponent;
