import React, { useState, useEffect } from "react";
import 'assets/css/pages/report.css';
import {
    FaAward, FaBirthdayCake, FaBook, FaCalendar, FaChartBar, FaChartLine,
    FaCheck, FaFlask, FaGlobeEurope, FaMinus, FaPrint, FaReceipt, FaStar, FaTachometerAlt,
    FaTelegramPlane, FaUserInjured, FaUsers, FaLiraSign, FaCashRegister, FaRegClock ,FaCalculator
} from "react-icons/fa";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaSyncAlt } from "react-icons/fa";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";
const Report = (props) => {
    const { auth } = props;
    const can = (permission) => {
        return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
    }
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [today, setToday] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    //const [allCardInfo, setAllCardInfo] = useState([
    // { to: "/raporlama/gelir-analizi", name: "Gelir", icon: <FaChartBar />, total: 0, todayTotal: 0, target: 'toplamGelir' },
    // { to: "/raporlama/tedavi-analizi", name: "Tedavi", icon: <FaChartLine />, total: 0, todayTotal: 0, target: 'toplamTedavi' },
    // { to: "/raporlama/tedavi-sayac-analizi", name: "Tedavi Sayaç", icon: <FaTachometerAlt />, total: 0, todayTotal: 0, target: 'none' },
    // { to: "/raporlama/hasta-analizi", name: "Hasta", icon: <FaUserInjured />, total: 0, todayTotal: 0, target: 'toplamHasta' },
    // { to: "/raporlama/laboratuvar-analizi", name: "Laboratuvar", icon: <FaFlask />, total: 0, todayTotal: 0, target: 'toplamIs' },
    // { to: "/raporlama/borc-analizi", name: "Borç", icon: <FaCashRegister />, total: 0, todayTotal: 0, target: 'toplamBorc' },
    // { to: "/raporlama/hakedis-analizi", name: "Hakediş", icon: <FaAward />, total: 0, todayTotal: 0, target: 'toplamHakedis' },
    // { to: "/raporlama", name: "Gider", icon: <FaChartLine />, total: 0, todayTotal: 0, target: 'toplamGider' },
    // { to: "/raporlama", name: "Defter", icon: <FaBook />, total: 0, todayTotal: 0, target: 'none' },
    // { to: "/raporlama", name: "Kontrol", icon: <FaCheck />, total: 0, todayTotal: 0, target: 'none' },
    // { to: "/raporlama", name: "Fatura", icon: <FaPrint />, total: 0, todayTotal: 0, target: 'none' },
    // { to: "/raporlama", name: "Doğum Günü", icon: <FaBirthdayCake />, total: 0, todayTotal: 0, target: 'none' },
    // { to: "/raporlama", name: "Klinik", icon: <FaGlobeEurope />, total: 0, todayTotal: 0, target: 'none' },
    // { to: "/raporlama", name: "Sms", icon: <FaTelegramPlane />, total: 0, todayTotal: 0, target: 'none' },
    // { to: "/raporlama", name: "Randevu", icon: <FaCalendar />, total: 0, todayTotal: 0, target: 'toplamRandevu' },
    // { to: "/raporlama", name: "Kullanıcı", icon: <FaUsers />, total: 0, todayTotal: 0, target: 'none' },

    // { to: "/raporlama", name: "Ted. Masraf", icon: <FaReceipt />, total: 0, todayTotal: 0, target: 'none' },
    // { to: "/raporlama", name: "Puanlama", icon: <FaStar />, total: 0, todayTotal: 0, target: 'none' },

    //]);

    const getTotalCardValue = () => {
        try {
            axios.get('/raporlama/liste')
                .then(res => {
                    setIsLoading(false);
                    setData(res.data.data);
                    setToday(res.data.bugun);
                })
        } catch (error) {
            setIsLoading(false);
            setData([]);
            setToday([]);
        }
    }

    useEffect(() => {
        getTotalCardValue();

    }, []);

    return (
        <div className="reports-page-container">
            <section>
                <div className="row">

                    {can('view-raporlama-gelir-analizi') ?
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/gelir-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Gelir Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaChartBar />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>
                                            {!isLoading ? '\xa0\xa0' + today?.toplamGelir : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Gelir:
                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamGelir : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}
                    {can('view-raporlama-tedavi-analizi') ?
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/tedavi-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Tedavi Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaChartLine />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>
                                            {!isLoading ? '\xa0\xa0' + today?.toplamTedavi : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Tedavi:
                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamTedavi : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}
                    {can('view-raporlama-tedavi-sayac-analizi') ?
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/tedavi-sayac-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Tedavi Sayaç Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaTachometerAlt />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>
                                            {!isLoading ? '\xa0\xa0' + today?.toplamTedaviSayac : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Tedavi Sayaç:
                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamTedaviSayac : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}

                    {can('view-raporlama-hasta-analizi') ?
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/hasta-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Hasta Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaUserInjured />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>
                                            {!isLoading ? '\xa0\xa0' + today?.toplamHasta : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Hasta:
                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamHasta : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}


                    {can('view-raporlama-laboratuvar-analizi') ?
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/gider-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Gider Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaChartBar />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>
                                            {!isLoading ? '\xa0\xa0' + today?.toplamGider : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Gider:
                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamGider : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}
                    {can('view-raporlama-laboratuvar-analizi') ?
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/laboratuvar-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Laboratuvar Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaFlask />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>
                                            {!isLoading ? '\xa0\xa0' + today?.toplamIs : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Laboratuvar:
                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamIs : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}


                    {can('view-raporlama-borc-analizi') ?
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/borc-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Borç Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaCashRegister />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>
                                            {!isLoading ? '\xa0\xa0' + today?.toplamBorc : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Borç:
                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamBorc : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}

                    {can('view-raporlama-hakedis-analizi') ?
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/hakedis-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Hakediş Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaAward />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>

                                            {!isLoading ? '\xa0\xa0' + today?.toplamHakedis : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Hakediş:

                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamHakedis : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}

                    {can('view-raporlama-planlama-analizi') ?   //planlama
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/planlama-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Planlama Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaRegClock />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>
                                            {!isLoading ? '\xa0\xa0' + today?.toplamPlanlama : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Planlama:
                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamPlanlama : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}

                    {can('view-raporlama-defter-analizi') ?   
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/defter-analizi') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Defter Analizi</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaCalculator />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>
                                            {!isLoading ? '\xa0\xa0' + today?.toplamGelirGiderFarki : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Gelir Gider Farkı:
                                    <span>
                                        <strong>
                                            {!isLoading ? '\xa0\xa0' + data?.toplamGelirGiderFarki : ""}
                                            {isLoading ? "\xa0\xa0" : ""}
                                            {isLoading ? <FaSyncAlt /> : ""}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''}


                    {/* {can('view-raporlama-faturalar') ?
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-4" onClick={() => { navigate('/raporlama/faturalar') }} style={{ cursor: "pointer" }}>
                            <div className="card-wrap">
                                <div className="card-header d-flex justify-content-between align-items-between">
                                    <h5 className="fs-6">Faturalar</h5>
                                    <span><FaChartLine /></span>
                                </div>
                                <div className="card-body">
                                    <span className="card-icon">
                                        <FaPrint />
                                    </span>
                                    <div className="card-content">
                                        Bugün: <strong>0</strong>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    Toplam Fatura:
                                    <span>
                                        <strong> 0 </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ''} */}

                </div>
            </section >
        </div >
    );
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(Report);