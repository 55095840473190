import React, { useEffect, useMemo, useRef, useState } from "react";
import "assets/css/pages/patient-detail.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { TabPanel } from "@mui/lab";
import { Button as ButtonM, Table, TableBody, TableCell, TableContainer, TableHead, TableRow , Paper, Stack, Tooltip, FormControl, TextField, Autocomplete, InputAdornment} from "@mui/material";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Card } from "reactstrap";
import {  FaEdit, FaPaperPlane, FaPills, FaPlus, FaPrint, FaTimes, FaTrash} from "react-icons/fa";
import { useFormik } from "formik";
import AxiosCrudActions from "services/AxiosCrudCore";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ConvertGlobalDateTime from "extensions/ConvertGlobalDateTime";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import preLoadingImg from 'assets/images/loading.gif';
import CreatePatientPrescriptionValidationSchema from "business-layer/CreatePatientPrescriptionValidationSchema";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {tr} from 'date-fns/locale';
import ReactToPrint from "react-to-print";
import DetailExportPDF from "./DetailExportPDF";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const PatientPrescriptionDetail = (props) => {
    const {auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    let detailPrintComponentRef = useRef();

    const navigate = useNavigate();
    const params = useParams();
    const MySwal = withReactContent(Swal);
    const patientId = params?.patientId;
    const prescriptionId = params?.id;

    const [medicines, setMedicines] = useState([]);
    const [filteredMedicines, setFilteredMedicines] = useState([]);
    const [prescriptionMedicines, setPrescriptionMedicines] = useState([]);
    const [indication, setIndication] = useState(null);
    const [indicationInputValue, setIndicationInputValue] = useState('');
    const [doctor, setDoctor] = useState(null);
    const [doctorInputValue, setDoctorInputValue] = useState('');
    const [doctors, setDoctors] = useState([]);
    const [indications, setIndications] = useState([]);
    const [loading,setLoading] = useState(true);
    const [updateMedicine, setUpdateMedicine] = useState(null);
    const [contactInfo, setContactInfo] = useState(null);
    const [prescriptionInfo, setPrescriptionInfo] = useState(null);
    const [isProcesssing, setIsProcessing] = useState(false);
    const [isProcesssingMed, setIsProcessingMed] = useState(false);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    const initialPrescriptionInfo = {
        hasta_id: patientId,
        doktor_id: 0,
        recete_tarihi: new Date(),
        protokol_no: '',
    };

    // yup schema
    let createPatientPrescriptionValidationSchema = CreatePatientPrescriptionValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialPrescriptionInfo,
        validationSchema : createPatientPrescriptionValidationSchema,
        onSubmit: values => {
            values.hasta_id = patientId;
            values.doktor_id = doctor?.id;
            values.recete_tarihi = ConvertGlobalDateTime(values.recete_tarihi);
            values.recete_ilaclar = JSON.stringify(prescriptionMedicines);
            values.id = prescriptionId;
            if(!prescriptionMedicines?.length){
                MySwal.fire({
                    title: "Uyarı",
                    text: "Lütfen ilaç ekleyiniz.",
                    icon: 'warning'
                })
            }else{
                MySwal.fire({
                    title: "Reçete Güncelle",
                    text: "Reçete güncelleme işlemi gerçekleştirilsin mi?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Evet, Güncelle',
                    cancelButtonText: 'Hayır, Vazgeç'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setIsProcessing(true);
                        AxiosCrudActions.UpdateAsync(`receteler/${prescriptionId}`, values).then(result => {
                            setIsProcessing(false);
                            if (result?.data?.status) {
                                navigate(`/hastalar/detay/${patientId}/recete`);
                            }
                        });
                    }
                })
            }
        },
    });

    // add medicine to Prescription List
    const addMedicineToPrescriptionList = (e, values = null) => {
        if(values !== null){
            let flag = prescriptionMedicines.some(el => el.id === values.id);
            if(!flag){
                setPrescriptionMedicines(prevState => [...prevState,values] );
            }
        }
    }

    // edit medicine in Prescription List
    const editMedicineInList = (e, values = null) => {
        e.preventDefault();
        if(values !== null){
            setUpdateMedicine(values);
            toggle();
        }
    }

    // update Medicine
    const updateUsingInfoMedicine = (e) => {
        e.preventDefault();
        setIsProcessingMed(true);
        if(updateMedicine !== undefined && updateMedicine !== null){
            const newState = prescriptionMedicines.map(obj => {
                // 👇️ if id equals 2, update country property
                if (obj?.id === updateMedicine?.id) {
                  return {...obj, doz:updateMedicine?.doz, kullanim: updateMedicine?.kullanim, aciklama: updateMedicine?.aciklama };
                }
                setIsProcessingMed(false);
                // 👇️ otherwise return object as is
                return obj;
            });
            setPrescriptionMedicines(newState);
            toggle();
        }

    }

    // delete medicine from Prescription List
    const deleteMedicineToPrescriptionList = (e, values = null) => {
        e.preventDefault();
        if(values !== null){
            let removedElementFromList = prescriptionMedicines.filter(el => el.id !== values.id);
            setPrescriptionMedicines(removedElementFromList);
        }
    }

    // search filter
    const filterWithSearchMedicine = (value = null) => {
        if(value !== null && value !== ""){
            setFilteredMedicines(medicines.filter(a => a.ilac_adi.toLowerCase().includes(value.toLowerCase())));
        }else{
            setFilteredMedicines(medicines);
        }
    }

    // set prescription Medicines from indication medicines
    const setIndicationMedicines = (event, values = null) => {
        event.preventDefault();
        setIndication(values);
        if(values !== undefined && values !== null){
            setPrescriptionMedicines(values?.ilaclar)
        }
    }

    // endikasyonlar
    const getIndications = async () => {
        try {
            let data = await axios.get('endikasyonlar');
            if(data.status === 200 ){
                setIndications(data?.data?.data);
                //setIndications(prev => ([{id: 0, endikasyon_adi: "Endikasyon seçiniz." }, ...prev]));
                // if(data?.data?.data.length > 0){
                //     setIndication(data?.data?.data[0])
                // }
            }
        } catch (error) {
            setIndications([]);
        }
    }
    // doktorlar
    const getDoctors = async () => {
        try {
            let data = await axios.get('kullanicilar?doktor=1');
            if(data.status === 200 ){
                setDoctors(data?.data?.data);
                // if(data?.data?.data.length > 0){
                //     setDoctor(data?.data?.data[0])
                // }
            }
        } catch (error) {
            setDoctors([]);
        }
    }
    // ilaclar
    const getMedicines = async () => {
        try {
            let data = await axios.get('ilaclar');
            if(data.status === 200 ){
                setMedicines(data?.data?.data);
                setFilteredMedicines(data?.data?.data);
            }
        } catch (error) {
            setMedicines([]);
            setFilteredMedicines([]);
        }
    }

    const getPrescriptionById = async () => {
        try {
            let data = await axios.get(`receteler/${prescriptionId}`);
            if(data.status === 200 ){
                const res = data?.data?.data;
                setPrescriptionMedicines(res?.ilaclar);
                setDoctor(res?.doktor);
                setPrescriptionInfo({
                    protokolNo: res?.protokol_no,
                    receteTarihi : res?.recete_tarihi,
                    hasta: res?.hasta
                })
                formik.setValues({
                    protokol_no: res?.protokol_no,
                    recete_tarihi: res?.recete_tarihi
                },false);
            }
        } catch (error) {
            setPrescriptionMedicines([]);
            setDoctor(null);
            setPrescriptionInfo(null);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    // contact settings
    const getContactSetting = async () => {
        try {
            let data = await axios.get('ayarlar/iletisim-ayarlari');
            if(data.status === 200 && data?.data?.data){
                setContactInfo(data.data.data);
            }
            else{
                setContactInfo(null);
            }
        } catch (error) {
            setContactInfo(null);
        }
    }

    useEffect(() => {
        getIndications();
        getDoctors();
        getMedicines();
        getContactSetting();
        getPrescriptionById();
    },[])


    return (
        <TabPanel value="4">
            {
                isProcesssing || isProcesssingMed ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
            }
            <div className="patient-detail-container ">
                <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                   
                    <div className="d-flex justify-content-between align-items-center" style={{paddingBottom: '5px'}}>
                        <span className="d-flex align-items-center" style={{fontSize: '16px',fontWeight: 'normal', color: '#1172b6', opacity: '0.8'}}>
                            <strong>
                                id - {prescriptionId} - Reçeteyi Güncelle&nbsp;
                            </strong>
                        </span>
                        <span>
                            <ReactToPrint
                                trigger={() =>
                                    <ButtonM color="warning" variant="outlined" type="button" disabled={! can('edit-hastalar-recete')} startIcon={<FaPrint />}>
                                        Yazdır
                                    </ButtonM>
                                }
                                content={() => detailPrintComponentRef}
                            />
                            <div style={{ display: "none" }}>
                                <DetailExportPDF
                                    medicinesInfo={prescriptionMedicines}
                                    prescriptionInfo={prescriptionInfo}
                                    contactInfo={contactInfo}
                                    ref={(el) => (detailPrintComponentRef = el)} />
                            </div>&nbsp;
                            <ButtonM color="info" variant="outlined" type="submit" disabled={! can('edit-hastalar-recete')}  onClick={formik.handleSubmit} >
                                                {
                                                    isProcesssing ?
                                                    (
                                                        <>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                            İşleniyor...
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <FaEdit /> &nbsp;
                                                            {"Güncelle"}
                                                        </>
                                                    )
                                                }
                            </ButtonM>
                        </span>
                    </div>
                    <div className="mb-2">
                        <div className="row">
                            {/* form ilaç bilgileri */}
                            
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
                            {can('edit-hastalar-recete') ? 
                                <form>
                                    <Card className="p-3 cstm-border-top">
                                        <div className="mt-1">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    options={indications}
                                                    loading={true}
                                                    getOptionLabel={option => option.endikasyon_adi}
                                                    noOptionsText={'Seçenek Bulunamadı.'}
                                                    value={indication}
                                                    onChange={(event, newValue) => {
                                                        setIndicationMedicines(event,newValue);
                                                    }}
                                                    inputValue={indicationInputValue}
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    onInputChange={(event, newInputValue) => { setIndicationInputValue(newInputValue); }}
                                                    size="small"
                                                    renderOption={(props, option) => {
                                                        return (
                                                        <li {...props} key={option.id}>
                                                            {option?.endikasyon_adi}
                                                        </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField {...params}
                                                                                    placeholder="Endikasyon seçiniz..."
                                                                                    label="Endikasyon"
                                                                                InputLabelProps={{shrink: true, style:{color:"#1976d2"}}}
                                                                            />}
                                                />
                                            </FormControl>
                                        </div>

                                        <div className="mt-3">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    options={doctors}
                                                    getOptionLabel={option => (option.name + (option?.brans ? ' - ' + option.brans : '' )) }
                                                    noOptionsText={'Seçenek Bulunamadı.'}
                                                    value={doctor}
                                                    disableClearable
                                                    name="doktor_id"
                                                    onChange={(event, newValue) => { setDoctor(newValue); }}
                                                    inputValue={doctorInputValue}
                                                    isOptionEqualToValue={(option, value) =>  option?.id === value?.id}
                                                    onInputChange={(event, newInputValue) => { setDoctorInputValue(newInputValue); }}
                                                    size="small"
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {(option.name + (option?.brans ? ' - ' + option.brans : '' )) }
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField {...params} 
                                                                                        label="Doktor"
                                                                                        InputLabelProps={{shrink: true, style:{color:"#1976d2"}}}/>}
                                                />
                                            </FormControl>
                                        </div>
                                        
                                        <div className="mt-3">
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    name="protokol_no"
                                                    value={formik.values.protokol_no}
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    size='small'
                                                    label="Protokol No"
                                                    InputLabelProps={{shrink: true, style:{color:"#1976d2"}}}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mt-3">
                                            <FormControl fullWidth>
                                                <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                                    <DesktopDatePicker
                                                        variant="outlined"
                                                        inputFormat="dd-MM-yyyy"
                                                        minDate={dayjs("01-01-1930")}
                                                        value={formik.values.recete_tarihi}
                                                        onChange={(newValue) => {
                                                            formik.setFieldValue('recete_tarihi', newValue);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            {...params}
                                                            size="small"
                                                            name="recete_tarihi"
                                                            variant="outlined"
                                                            label="Reçete Tarihi"
                                                            InputLabelProps={{shrink: true, style:{color:"#1976d2"}}}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                    </Card>
                                </form>
                                :<NotPermission/>}
                            </div>
                           
                            {/* ilac listesi */}
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
                                <Card className="p-3 cstm-border-top" style={{height: '240px'}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 style={{color: '#1172b6'}}>İlaç Listesi :</h6>
                                        <div>
                                            <FormControl fullWidth>
                                                <TextField className='form-group'
                                                    type="text"
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        if(/^\s/.test(event.target.value))
                                                            event.target.value = '';
                                                        filterWithSearchMedicine(event.target.value);
                                                    }}
                                                    size='small'
                                                    placeholder="ilaç ara..."
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <TableContainer component={Paper} >
                                        <Table size="small" stickyHeader style={{height: '100%', overflow: 'scroll'}}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" width='70%'>İlaç Adı</TableCell>
                                                    <TableCell align="center" width='30%'>İşlem</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {
                                                useMemo(() =>
                                                
                                                <TableBody>
                                                {
                                                    can('edit-hastalar-recete') ? 
                                                    filteredMedicines.map((row) => (
                                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            style={{backgroundColor: prescriptionMedicines.some(el => el.id === row.id) ? '#1172b6': '#ffffff'}}>
                                                            <TableCell align="left" width='70%'
                                                                style={{color: prescriptionMedicines.some(el => el.id === row.id) ? '#ffffff': '#000'}}>{row?.ilac_adi}</TableCell>
                                                            <TableCell align="center" width='30%' >
                                                                <Stack direction="row" spacing={2} className="justify-content-center">
                                                                    <Tooltip title={prescriptionMedicines.some(el => el.id === row.id) ? "Listeden çıkar" : "Listeye ekle" }  arrow>
                                                                        {
                                                                            prescriptionMedicines.some(el => el.id === row.id)
                                                                            ?
                                                                            <ButtonM onClick={(e) => deleteMedicineToPrescriptionList(e, row)} style={{color: 'white'}}><FaTrash /></ButtonM>
                                                                            :
                                                                            <ButtonM variant="outlined" onClick={(e) => addMedicineToPrescriptionList(e, row)} color="success"><FaPlus /></ButtonM>
                                                                        }
                                                                    </Tooltip>
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    :''
                                                }
                                                </TableBody>
                                                ,[filteredMedicines,prescriptionMedicines])
                                                
                                            }
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <h4 className="fs-5 fw-normal p-1 mb-3 mt-2" style={{color: '#1172b6'}}>Reçete İlaç Bilgileri:</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 1000}} size="small" className="cstm-border-top">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" width='20%'>İlaç Adı</TableCell>
                                    <TableCell align="center" width='15%'>Doz</TableCell>
                                    <TableCell align="center" width='15%'>Kullanım</TableCell>
                                    <TableCell align="center" width='40%'>Açıklama</TableCell>
                                    <TableCell align="center" width='10%'>İşlem</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                can('edit-hastalar-recete') ?
                                !loading ?
                                prescriptionMedicines.map((row) => (
                                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center" width='20%'>{row?.ilac_adi}</TableCell>
                                        <TableCell align="center" width='15%'>{row?.doz}</TableCell>
                                        <TableCell align="center" width='15%'>{row?.kullanim}</TableCell>
                                        <TableCell align="center" width='40%'>{row?.aciklama}</TableCell>
                                        <TableCell align="center" width='10%'>
                                            <Stack direction="row" spacing={2} className="justify-content-center">
                                                <Tooltip title="ilaç düzenle" arrow>
                                                    <ButtonM variant="outlined"
                                                        onClick={(e) => editMedicineInList(e, row)}
                                                        color="warning"><FaEdit /></ButtonM>
                                                </Tooltip>
                                                <Tooltip title="listeden çıkar" arrow>
                                                    <ButtonM variant="outlined"
                                                        onClick={(e) => deleteMedicineToPrescriptionList(e, row)}
                                                        color="error"><FaTimes /></ButtonM>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )) :
                                <TableRow width="100%">
                                    <TableCell>
                                        <div id="invoice-pre-loading-content">
                                            <div className="loading">
                                                <img src={preLoadingImg} alt="loading" width={110} height={110} />
                                                <div className="d-flex justify-content-center" style={{color: '#1172b6'}}>
                                                    <span>Yükleniyor...</span>
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                :''
                                }
                                <Modal isOpen={modal}
                                    toggle={toggle}
                                    size="md"
                                    modalTransition={{ timeout: 1 }}
                                    backdropTransition={{ timeout: 1 }}
                                    >
                                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>İlaç Kullanım Bilgileri</ModalHeader>
                                    <form onSubmit={formik.handleSubmit}>
                                        <ModalBody>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-10 p-3">
                                                    <div>
                                                        <FormControl fullWidth>
                                                            <TextField className='form-group'
                                                                type="text"
                                                                variant='outlined'
                                                                autoComplete="off"
                                                                value={updateMedicine?.ilac_adi || ''}
                                                                disabled
                                                                size='small'
                                                                label="İlaç Adı"
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                                }}
                                                                InputLabelProps={{
                                                                    style: { color: 'rgb(25, 118, 210)' },
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="mt-4">
                                                        <FormControl fullWidth>
                                                            <TextField className='form-group'
                                                                type="text"
                                                                variant='outlined'
                                                                autoComplete="off"
                                                                value={updateMedicine?.doz || ''}
                                                                size='small'
                                                                label="Doz"
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    setUpdateMedicine(prev => ({...prev, doz: e.target.value}));
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                                }}
                                                                InputLabelProps={{
                                                                    style: { color: 'rgb(25, 118, 210)' },
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="mt-4">
                                                        <FormControl fullWidth>
                                                            <TextField className='form-group'
                                                                type="text"
                                                                variant='outlined'
                                                                autoComplete="off"
                                                                value={updateMedicine?.kullanim || ''}
                                                                size='small'
                                                                label="Kullanım"
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    setUpdateMedicine(prev => ({...prev, kullanim: e.target.value}));
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                                }}
                                                                InputLabelProps={{
                                                                    style: { color: 'rgb(25, 118, 210)' },
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="mt-4">
                                                        <FormControl fullWidth>
                                                            <TextField className='form-group'
                                                                multiline
                                                                rows={2}
                                                                type="text"
                                                                variant='outlined'
                                                                autoComplete="off"
                                                                value={updateMedicine?.aciklama || ''}
                                                                size='small'
                                                                label="Açıklama"
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    setUpdateMedicine(prev => ({...prev, aciklama: e.target.value}));
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                                }}
                                                                InputLabelProps={{
                                                                    style: { color: 'rgb(25, 118, 210)' },
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary" type="submit" variant="contained" style={{ background: "#1172b6" }} className="d-flex align-items-center" onClick={updateUsingInfoMedicine} >
                                                    {
                                                        isProcesssingMed ?
                                                        (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                                İşleniyor...
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <FaPaperPlane /> &nbsp;
                                                                {"Kaydet"}
                                                            </>
                                                        )
                                                    }
                                            </Button>{' '}
                                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                                <FaTimes />&nbsp;Vazgeç
                                            </Button>
                                        </ModalFooter>
                                    </form>
                                </Modal>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
        </TabPanel>
    );
}
const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};
export default connect(authPermissions)(PatientPrescriptionDetail);