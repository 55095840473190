
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';


const PrivateRoute = (props) => {
    const { auth } = props;
    return auth.isLoggedIn ? <Outlet /> : <Navigate to="/login" />
}

const mapStateToProps = (state) => {
    return {
        auth: state.authState,
    }
}

export default connect(mapStateToProps, null)(PrivateRoute);


