import dayjs from 'dayjs';
const GetTrDate = (date = null) => {
    if(date !== null){
        try {
            let getJustDate = dayjs(date).format('DD-MM-YYYY');
            return getJustDate;
        } catch (error) {
            return null;
        }
    }
}

export default GetTrDate;