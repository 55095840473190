import dayjs from 'dayjs';
const GetDateWithFormat = (date = null) => {
    if(date !== null){
        try {
            let getJustDate = dayjs(date).get('year') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('date');
            return getJustDate;
        } catch (error) {
            return null;
        }
    }
}

export default GetDateWithFormat;