
import * as yup from 'yup';  

const CreatePatientValidationSchema = () => {
    return yup.object().shape({

        stok_adi: yup.string()
                .min(2,"Stok Adı en az 2 karakter olmalıdır.")
                .max(50,"Stok Adı en fazla 50 karakter olmalıdır.")
                .required("Lütfen geçerli bir stok adı bilgisi giriniz."),

   });
}   

export default CreatePatientValidationSchema;