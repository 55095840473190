import React, { useEffect } from "react";
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import 'assets/css/pages/laboratory.css';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

const Laboratory = () => {

    
    const location = useLocation();
    const navigate = useNavigate()

    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const tabLinkElements = [
        {to: "/laboratuvar/giden-isler", icon: <FaArrowRight />, label: "GİDEN İŞLER", value: "1"},
        {to: "/laboratuvar/gelen-isler", icon: <FaArrowLeft />, label: "GELEN İŞLER", value: "2"},
    ]

    useEffect(() => {
        const { pathname } = location;
        if(pathname === '/laboratuvar'){
           navigate('/laboratuvar/giden-isler');
           setValue("1");
        }
        else{
            for (const [index, element] of tabLinkElements.entries()) {
                if(element.to === pathname){
                    navigate(pathname);
                    setValue(element.value);
                    break;
                }
            }
        }
    },[location.pathname])

    return (
        <div className="laboratory-container">
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList 
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            >
                            {
                                tabLinkElements.map((element, index) => (
                                    <Tab key={element.value} component={Link} to={element.to} sx={{minWidth:"fit-content", flex:1}} icon={element.icon} iconPosition="start" label={element.label} value={element.value}/>
                                ))
                            }
                        </TabList>
                    </Box>
                    <Outlet />
                </TabContext>
            </Box>
        </div>
    )
}

export default Laboratory;