import React, { useEffect, useState } from 'react'
import 'assets/css/pages/reports/report-notebook-analysis.css';
import { FormControl, TextField, Autocomplete, Button as ButtonM, MenuItem } from '@mui/material';
import Box from "@mui/material/Box";
import {
  FaTimes,
  FaTh,
  FaClipboardList
} from "react-icons/fa";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import { trTR as turkishTr } from "@mui/material/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { tr } from 'date-fns/locale'
import axios from "axios";
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import GetTrDate from "extensions/GetTRDate";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

function NotebookAnalysis(props) {

    const { auth } = props;
    const can = (permission) => {
      return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
    }
  
    const navigate = useNavigate();
    const theme = createTheme(
      trTR, // x-data-grid translations
      turkishTr // core translations
    );
  
  
    const [selectDoctor, setSelectDoctor] = useState({ id: 0, name: 'Hepsi', value: null });
    const [paymentTypeIncome, setPaymentTypeIncome] = useState({ id: 1, odeme_sekli: 'Hepsi', value: null });
    const [paymentTypeExpence, setPaymentTypeExpence] = useState({ id: 1, odeme_sekli: 'Hepsi', value: null });
    const [priceDataIncome, setPriceDataIncome] = useState({ toplam: '0,00' });
    const [priceDataExpence, setPriceDataExpence] = useState({ toplam: '0,00' });
    const [priceDataDifference, setPriceDataDifference] = useState({ fark: '0,00' });
    const [selectCari, setSelectCari] = useState({ id: null, cari_adi: 'Hepsi'});
  
    const [doctors, setDoctors] = useState([]);
    const MySwal = withReactContent(Swal);
    const [startDateIncome, setStartDateIncome] = useState(Date.now());
    const [endDateIncome, setEndDateIncome] = useState(Date.now());
    const [startDateExpence, setStartDateExpence] = useState(Date.now());
    const [endDateExpence, setEndDateExpence] = useState(Date.now());
    
    
  
    const columnsIncome = [
      {
        field: "hasta",
        headerName: "Ad Soyad",
        minWidth: 100,
        maxWidth: 300,
        flex: 1,
        hideable: true,
        editable: false,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "doktor",
        headerName: "Doktor",
        minWidth: 200,
        flex: 1,
        align: "center",
        headerAlign: "center",
        editable: false,
        hideable: false, // cant hide this column
        sortable: true,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
          return (
            params?.row?.doktor ? params?.row?.doktor?.name : ""
          )
        }
      },
      {
        field: "tarih",
        headerName: "Ödeme Tarihi",
        minWidth: 100,
        flex: 1,
        align: "center",
        headerAlign: "center",
        editable: false,
        hideable: false, // cant hide this column
        sortable: true,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
          return (
            params?.row?.tarih ? GetTrDate(params?.row?.tarih) : ""
          )
        }
      },
      {
        field: "odeme_sekli",
        headerName: "Ödeme Şekli",
        minWidth: 200,
        flex: 1,
        align: "center",
        headerAlign: "center",
        editable: false,
        hideable: false, // cant hide this column
        sortable: true,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
          return (
            params?.row?.odeme_sekli ? params?.row?.odeme_sekli?.name : ""
          )
        }
      },
      {
        field: "tutar",
        headerName: "Ödenen Tutar",
        minWidth: 200,
        flex: 1,
        align: "center",
        headerAlign: "center",
        editable: false,
        hideable: false, // cant hide this column
        sortable: true,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
      }
    ];

    const columnsExpense = [
 
        {
            field: "tarih",
            headerName: "Ödeme Tarihi",
            minWidth: 100,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
              return (
                params?.row?.tarih ? GetTrDate(params?.row?.tarih) : ""
              )
            }
          },
        {
          field: "cari",
          headerName: "Cari",
          minWidth: 200,
          flex: 1,
          align: "center",
          headerAlign: "center",
          editable: false,
          hideable: false, // cant hide this column
          sortable: true,
          hide: false, //when data grid loaded, this column will hide
          filterable: true,
        },
        {
          field: "odeme_sekli",
          headerName: "Ödeme Şekli",
          minWidth: 200,
          flex: 1,
          align: "center",
          headerAlign: "center",
          editable: false,
          hideable: false, // cant hide this column
          sortable: true,
          hide: false, //when data grid loaded, this column will hide
          filterable: true,
          renderCell: (params) => {
            return (
              params?.row?.odeme_sekli ? params?.row?.odeme_sekli?.name : ""
            )
          }
        },
        {
            field: "aciklama",
            headerName: "Açıklama",
            minWidth: 100,
            maxWidth: 300,
            flex: 1,
            hideable: true,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
          field: "tutar",
          headerName: "Ödenen Tutar",
          minWidth: 200,
          flex: 1,
          align: "center",
          headerAlign: "center",
          editable: false,
          hideable: false, // cant hide this column
          sortable: true,
          hide: false, //when data grid loaded, this column will hide
          filterable: true,
        }
      ];
  
    // data-grid state
    const [dataIncome, setDataIncome] = useState([]);
    const [dataExpence, setDataExpence] = useState([]);
    const [cariler, setCariler] = useState([]);
    const [isIncomeLoading, setIncomeIsLoading] = useState(false);
    const [isExpenceLoading, setExpenceIsLoading] = useState(false);
    const [differrenceStatus, setdifferrenceStatus] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);

    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
      {
        field: "id",
        sort: "desc",
      },
    ]);
  
  
    const getDoctors = () => {
      try {
        axios.get('/kullanicilar?doktor=1')
          .then(res => {
            setDoctors(res.data.data);
            setDoctors(state => [{ id: 0, name: "Hepsi", value: null }, ...state]);
          })
      } catch (error) {
        setDoctors([])
      }
    }

    const getCariler = () => {
        try {
          axios.get('/cariler')
            .then(res => {
              setCariler(res.data.data);
              setCariler(state => [{ id: null, cari_adi: "Hepsi" }, ...state]);
            })
        } catch (error) {
            setCariler([])
        }
      }
  
    const getDate = (date) => {
      if (date !== undefined && date !== null) {
        try {
          let getJustDate = dayjs(date).get('date') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('year');
          return getJustDate;
        } catch (error) {
          return null;
        }
      }
    }
  
    const getReverseDate = (date) => {
      if (date !== undefined && date !== null) {
        try {
          let getJustDate = dayjs(date).get('year') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('date');
          return getJustDate;
        } catch (error) {
          return null;
        }
      }
    }
  
  
 
  
  
  
    const FilterIncome = () => {
      setIncomeIsLoading(true);
      axios.get("raporlama/gelir-analizi", {
        params: {
          baslangic: getReverseDate(startDateIncome) ? getReverseDate(startDateIncome) : null,
          bitis: getReverseDate(endDateIncome) ? getReverseDate(endDateIncome) : null,
          odeme_sekli: paymentTypeIncome?.value ? paymentTypeIncome?.value : null,
          doktor: selectDoctor?.id ? selectDoctor?.id : null,
          // filterModel: JSON.stringify(filterModel),
          // pageSize: pageSize,
          // page: page,
          // sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
          // sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
        }
      }).then((res) => {
        setIncomeIsLoading(false);
        try {
          if (res.data.data !== undefined && res.data.data !== null) {
            setTotalCount(res.data.totalCount);
            let datalist = res.data.data;
            let total = { toplam: res.data.toplam };
            setPriceDataIncome(total);
            setDataIncome(datalist);
          }
        } catch (error) {
            setDataIncome([]);
        }
      });
    }
  

    
  const FilterExpence = () => {
    setExpenceIsLoading(true);
    axios.get("raporlama/gider-analizi", {
      params: {
        baslangic: getReverseDate(startDateExpence) ? getReverseDate(startDateExpence) : null,
        bitis: getReverseDate(endDateExpence) ? getReverseDate(endDateExpence) : null,
        odeme_sekli: paymentTypeExpence?.value ? paymentTypeExpence?.value : null,
        cari: selectCari?.id ? selectCari?.id : null,setPriceDataDifference
        // filterModel: JSON.stringify(filterModel),
        // pageSize: pageSize,
        // page: page,
        // sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
        // sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
      }
    }).then((res) => {
      setExpenceIsLoading(false);
      try {
        if (res.data.data !== undefined && res.data.data !== null) {
          setTotalCount(res.data.totalCount);
          let datalist = res.data.data;
          let total = { toplam: res.data.toplam };
          setPriceDataExpence(total);
          setDataExpence(datalist);
        }
      } catch (error) {
        setDataExpence([]);
      }
    });
  }




   useEffect(() => {
  
       let difference = parseFloat(priceDataIncome?.toplam) - parseFloat(priceDataExpence?.toplam);
       (difference === '0,00') || (difference === 0)  ? setdifferrenceStatus(null):  difference < 0 ? setdifferrenceStatus(false) : setdifferrenceStatus(true);
       let total = { fark: parseFloat((priceDataIncome?.toplam.substring(0, priceDataIncome?.toplam.length - 3)) - (priceDataExpence?.toplam.substring(0, priceDataExpence?.toplam.length - 3))).toFixed(3)+',00' };
       setPriceDataDifference(total);

    
   }, [priceDataIncome,priceDataExpence])
   
  
    //side effect
    useEffect(() => {
      getDoctors();
      getCariler();
      setIncomeIsLoading(true);
      setExpenceIsLoading(true);
      axios.get("raporlama/gelir-analizi", {
        params: {
          baslangic: getReverseDate(startDateIncome) ? getReverseDate(startDateIncome) : null,
          bitis: getReverseDate(endDateIncome) ? getReverseDate(endDateIncome) : null,
          odeme_sekli: paymentTypeIncome?.value ? paymentTypeIncome?.value : null,
          doktor: selectDoctor?.id ? selectDoctor?.id : null,
          // filterModel: JSON.stringify(filterModel),
          // pageSize: pageSize,
          // page: page,
          // sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
          // sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
        }
      }).then((res) => {
        setIncomeIsLoading(false);
        try {
          if (res.data.data !== undefined && res.data.data !== null) {
            setTotalCount(res.data.totalCount);
            let datalist = res.data.data;
            let total = { toplam: res.data.toplam };
            setPriceDataIncome(total);
            setDataIncome(datalist);
            
          }
        } catch (error) {
            setDataIncome([]);
        }
      });

      axios.get("raporlama/gider-analizi", {
        params: {
          baslangic: getReverseDate(startDateExpence) ? getReverseDate(startDateExpence) : null,
          bitis: getReverseDate(endDateExpence) ? getReverseDate(endDateExpence) : null,
          odeme_sekli: paymentTypeExpence?.value ? paymentTypeExpence?.value : null,
          cari: selectCari?.id ? selectCari?.id : null,
          // filterModel: JSON.stringify(filterModel),
          // pageSize: pageSize,
          // page: page,
          // sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
          // sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
        }
      }).then((res) => {
        setExpenceIsLoading(false);
        try {
          if (res.data.data !== undefined && res.data.data !== null) {
            setTotalCount(res.data.totalCount);
            let datalist = res.data.data;
            let total = { toplam: res.data.toplam };
            setPriceDataExpence(total);
            setDataExpence(datalist);
          }
        } catch (error) {
          setDataExpence([]);
        }
      });

    }, []);

   




    

  return (
    <>
     {can('view-raporlama-defter-analizi') ?  
        <div className="report-notebook-analysis-container">
            <div className='d-flex justify-content-between'>
                <span>
                <h4 className='report-notebook-analysis-title'>Raporlama</h4>
                <span className="d-flex align-items-center">
                    <div><strong className="fs-5 fw-normal text text-secondary">Defter Analizi&nbsp;</strong></div>
                </span>
                </span>
                <div>
                <ButtonM className='report-notebook-analysis-button-bgColor'
                    variant="contained"
                    type="submit" startIcon={<FaTh />}
                    onClick={() => { navigate('/raporlama') }}
                >
                    Raporlama listesi
                </ButtonM>
                </div>
            </div>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{margin:'20px 0',display: 'flex',justifyContent:'center',padding:'10px',
            border:differrenceStatus === null ? '1px dashed gray' : differrenceStatus ? '1px dashed #00c853' : '1px dashed #f50057',
            backgroundColor:differrenceStatus === null ? '#e0e0e0' : differrenceStatus ? '#c8e6c9' : '#ffcdd2'}}>
            <b style={{color:differrenceStatus === null ? 'gray' : differrenceStatus ? '#00c853' : '#f50057'}}>FARK:&nbsp;&nbsp; {priceDataDifference?.fark} TRY</b>
            </div>
            <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                  <div style={{border:'1px solid #e0e0e0', padding:'20px', borderRadius:'10px'}}>
                    <div className='col-md-12 mt-3 mb-3' style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <b>GELİR TABLOSU</b>
                            <table>
                                <tr>
                                    <td><b>Toplam:&nbsp;</b></td>
                                    <td><b style={{ color: '#e53935' }}>{priceDataIncome.toplam}</b></td>
                                </tr>
                            </table>
                      </div>
                      <div className="row col-md-11 offset-md-1">
                            <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                                <FormControl fullWidth className="mt-2">
                                <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                    <DesktopDatePicker
                                    variant='outlined'
                                    label="Başlangıç Tarihi"
                                    inputFormat="dd-MM-yyyy"
                                    minDate={dayjs('01-01-1930')}
                                    value={startDateIncome}
                                    onChange={(newValue) => {
                                        setStartDateIncome(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        size="small"
                                        name="baslangic"
                                        variant='outlined'
                                    />}
                                    />
                                </LocalizationProvider>
                                </FormControl>
                            </div>
                            <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                                <FormControl fullWidth className="mt-2">
                                <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                                    <DesktopDatePicker
                                    variant='outlined'
                                    label="Bitiş Tarihi"
                                    inputFormat="dd-MM-yyyy"
                                    minDate={dayjs('01-01-1930')}
                                    value={endDateIncome}
                                    onChange={(newValue) => {
                                        setEndDateIncome(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        size="small"
                                        name="bitis"
                                        variant='outlined'
                                    />}
                                    />
                                </LocalizationProvider>
                                </FormControl>
                            </div>
                            <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                                <FormControl fullWidth className="mt-2">
                                <Autocomplete
                                    options={[
                                    { id: 1, odeme_sekli: 'Hepsi', value: null },
                                    { id: 2, odeme_sekli: 'Nakit', value: "Nakit" },
                                    { id: 3, odeme_sekli: 'Kredi Kartı', value: "Kredi Kartı" },
                                    { id: 4, odeme_sekli: 'Havale/EFT', value: "Havale/EFT" }
                                    ]}
                                    getOptionLabel={(option) => option.odeme_sekli}
                                    loading={true}
                                    disableClearable
                                    value={paymentTypeIncome}
                                    noOptionsText={"Seçenek Bulunamadı"}
                                    name="odeme_sekli"
                                    onChange={(event, newValue) => {
                                      setPaymentTypeIncome(newValue);
                                    }}
                                    size="small"
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                        {option.odeme_sekli}
                                        </li>
                                    );
                                    }}
                                    renderInput={(params) => <TextField
                                    {...params}
                                    label="Ödeme Türü"
                                    />
                                    }
                                />
                                </FormControl>
                            </div>
                            <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                                <FormControl fullWidth className="mt-2">
                                <Autocomplete
                                    options={doctors}
                                    getOptionLabel={(option) => option.name}
                                    loading={true}
                                    disableClearable
                                    value={selectDoctor}
                                    noOptionsText={"Seçenek Bulunamadı"}
                                    name="doktor"
                                    onChange={(event, newValue) => {
                                    setSelectDoctor(newValue);
                                    }}
                                    size="small"
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                        {option.name}
                                        </li>
                                    );
                                    }}
                                    renderInput={(params) => <TextField
                                    {...params}
                                    label="Doktor"
                                    />
                                    }
                                />
                                </FormControl>
                            </div>
                            <ButtonM className='col-xl-10 col-lg-10 col-md-11 col-sm-11 col-xs-9 m-2 report-notebook-analysis-button-bgColor'
                                variant="contained"
                                type="submit" startIcon={<FaClipboardList />}
                                onClick={FilterIncome}
                            >
                                Raporu Göster
                            </ButtonM>

                      </div>
                      <div className='row mt-2 mb-3'>
                        {
                        <ThemeProvider theme={theme}>
                            <Box
                            sx={{
                                width: "100%",
                                height: `${dataIncome.length > 0 ? "auto" : "400px"}`,
                            }}
                            >
                            <DataGrid
                                autoHeight={dataIncome.length > 0 ? true : false}
                                sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2 }}
                                loading={isIncomeLoading}
                                rows={dataIncome}
                                columns={columnsIncome}
                                // pagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowCount={totalCount} //serverdan gelen data total count gelecek
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                pagination
                                //paginationMode="server"
                                // sorting
                                sortModel={sortModel}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                filterModel={filterModel}
                                // filterMode="server"
                                onFilterModelChange={(newFilterModel) =>
                                setFilterModel(newFilterModel)
                                }
                                // selection checkbox
                                //checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                                }}
                                // features
                                experimentalFeatures={{ newEditingApi: true }}
                                // toolbar components
                                components={{
                                Toolbar: GridToolbar,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                LoadingOverlay: LoadingOverlay,
                                }}
                                disableColumnFilter
                                disableSelectionOnClick
                                // component props
                                componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    printOptions: {
                                    fields: ["id", "islem_adi"],
                                    fileName: "Laboratuvar Islemler",
                                    hideFooter: true,
                                    hideToolbar: true,
                                    disableToolbarButton: false,
                                    }, //sadece firsname  ve lastname alanını alır.
                                    csvOptions: {
                                    disableToolbarButton: false,
                                    fields: ["id", "islem_adi"],
                                    fileName: "Laboratuvar Islemler",
                                    utf8WithBom: true,
                                    }, //sadece firstname ve lastName alanını alır.
                                    excelOptions: { disableToolbarButton: true },
                                    // quickFilterProps: { debounceMs: 500 },
                                },
                                }}
                            />
                            </Box>
                        </ThemeProvider>
                        }
                      </div>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                  <div style={{border:'1px solid #e0e0e0', padding:'20px', borderRadius:'10px'}}>
                    <div className='col-md-12 mt-3  mb-3' style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <b>GİDER TABLOSU</b>
                        <table>
                            <tr>
                                <td><b>Toplam:&nbsp;</b></td>
                                <td><b style={{ color: '#e53935' }}>{priceDataExpence.toplam}</b></td>
                            </tr>
                        </table>
                    </div>
                    <div className="row col-md-11 offset-md-1">
                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                        <FormControl fullWidth className="mt-2">
                            <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                            <DesktopDatePicker
                                variant='outlined'
                                label="Başlangıç Tarihi"
                                inputFormat="dd-MM-yyyy"
                                minDate={dayjs('01-01-1930')}
                                value={startDateExpence}
                                onChange={(newValue) => {
                                setStartDateExpence(newValue);
                                }}
                                renderInput={(params) => <TextField {...params}
                                size="small"
                                name="baslangic"
                                variant='outlined'
                                />}
                            />
                            </LocalizationProvider>
                        </FormControl>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                        <FormControl fullWidth className="mt-2">
                            <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                            <DesktopDatePicker
                                variant='outlined'
                                label="Bitiş Tarihi"
                                inputFormat="dd-MM-yyyy"
                                minDate={dayjs('01-01-1930')}
                                value={endDateExpence}
                                onChange={(newValue) => {
                                setEndDateExpence(newValue);
                                }}
                                renderInput={(params) => <TextField {...params}
                                size="small"
                                name="bitis"
                                variant='outlined'
                                />}
                            />
                            </LocalizationProvider>
                        </FormControl>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                        <FormControl fullWidth className="mt-2">
                            <Autocomplete
                            options={[
                                { id: 1, odeme_sekli: 'Hepsi', value: null },
                                { id: 2, odeme_sekli: 'Nakit', value: "Nakit" },
                                { id: 3, odeme_sekli: 'Kredi Kartı', value: "Kredi Kartı" },
                                { id: 4, odeme_sekli: 'Havale/EFT', value: "Havale/EFT" }
                            ]}
                            getOptionLabel={(option) => option.odeme_sekli}
                            loading={true}
                            disableClearable
                            value={paymentTypeExpence}
                            noOptionsText={"Seçenek Bulunamadı"}
                            name="odeme_sekli"
                            onChange={(event, newValue) => {
                                setPaymentTypeExpence(newValue);
                            }}
                            size="small"
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            renderOption={(props, option) => {
                                return (
                                <li {...props} key={option.id}>
                                    {option.odeme_sekli}
                                </li>
                                );
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Ödeme Türü"
                            />
                            }
                            />
                        </FormControl>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                        <FormControl fullWidth className="mt-2">
                            <Autocomplete
                            options={cariler}
                            getOptionLabel={(option) => option.cari_adi}
                            loading={true}
                            disableClearable
                            value={selectCari}
                            noOptionsText={"Seçenek Bulunamadı"}
                            name="cari_adi"
                            onChange={(event, newValue) => {
                                setSelectCari(newValue);
                            }}
                            size="small"
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            renderOption={(props, option) => {
                                return (
                                <li {...props} key={option.id}>
                                    {option.cari_adi}
                                </li>
                                );
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Cari"
                            />
                            }
                            />
                        </FormControl>
                        </div>
                        <ButtonM className='col-xl-10 col-lg-10 col-md-11 col-sm-11 col-xs-9 m-2 report-notebook-analysis-button-bgColor'
                        variant="contained"
                        type="submit" startIcon={<FaClipboardList />}
                        onClick={FilterExpence}
                        >
                        Raporu Göster
                        </ButtonM>

                    </div>
                    <div className='row mt-2 mb-3'>
                        {
                            <ThemeProvider theme={theme}>
                            <Box
                                sx={{
                                width: "100%",
                                height: `${dataExpence.length > 0 ? "auto" : "400px"}`,
                                }}
                            >
                                <DataGrid
                                autoHeight={dataExpence.length > 0 ? true : false}
                                sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2 }}
                                loading={isExpenceLoading}
                                rows={dataExpence}
                                columns={columnsExpense}
                                // pagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowCount={totalCount} //serverdan gelen data total count gelecek
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                pagination
                                //paginationMode="server"
                                // sorting
                                sortModel={sortModel}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                filterModel={filterModel}
                                // filterMode="server"
                                onFilterModelChange={(newFilterModel) =>
                                    setFilterModel(newFilterModel)
                                }
                                // selection checkbox
                                //checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                // features
                                experimentalFeatures={{ newEditingApi: true }}
                                // toolbar components
                                components={{
                                    Toolbar: GridToolbar,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    LoadingOverlay: LoadingOverlay,
                                }}
                                disableColumnFilter
                                disableSelectionOnClick
                                // component props
                                componentsProps={{
                                    toolbar: {
                                    showQuickFilter: true,
                                    printOptions: {
                                        fields: ["id", "islem_adi"],
                                        fileName: "Laboratuvar Islemler",
                                        hideFooter: true,
                                        hideToolbar: true,
                                        disableToolbarButton: false,
                                    }, //sadece firsname  ve lastname alanını alır.
                                    csvOptions: {
                                        disableToolbarButton: false,
                                        fields: ["id", "islem_adi"],
                                        fileName: "Laboratuvar Islemler",
                                        utf8WithBom: true,
                                    }, //sadece firstname ve lastName alanını alır.
                                    excelOptions: { disableToolbarButton: true },
                                    // quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                                />
                            </Box>
                            </ThemeProvider>
                        }
                    </div>
                  </div>
                </div>
          </div>
        </div>
      :<NotPermission/>}
    </>
  )
}

const authPermissions = (state) => {
    return {
      auth: state.authState,
    };
};
  
export default connect(authPermissions)(NotebookAnalysis);


