import { Tooltip } from "@mui/material";
import GetTrDate from "extensions/GetTRDate";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { GetTriggerActionName } from "extensions/GetTriggerActionName";

export const KullanicilarLogTableColumns = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        flex: 1,
        headerAlign: "center",
        minWidth:100,
        maxWidth:100,
        hideable: true,
        editable: false,
        filterable: false
    },
    {
        field: "name",
        headerName: "Ad Soyad",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "email",
        headerName: "Email",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "tel_no",
        headerName: "Tel No",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "adres",
        headerName: "Adres",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return(
                <Tooltip title={params.row.adres} arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.adres}</span>
                </Tooltip>
            )
        }
        
    },
    {
        field: "brans",
        headerName: "Branş",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "hakedis",
        headerName: "Hakediş Yüzdesi",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "role",
        headerName: "Rol",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                !params.row.brans ? "Kullanıcı" : "Doktor"
            )
        },
        valueGetter: (params) => !params.row.brans ? "Kullanıcı" : "Doktor"
    },
    {
        field: "durum",
        headerName: "Durum",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.durum ? "Aktif" : "Pasif"
            )
        },
        valueGetter: (params) => `${params?.row?.durum}`,
    },
    {
        field: "action",
        headerName: "İşlem",
        minWidth: 100,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTriggerActionName(params?.row?.action)
            )
        },
        valueGetter: (params) => GetTriggerActionName(params?.row?.action)
    },
    
    {
        field: "update_text",
        headerName: "Güncelleme İşlemi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "user",
        headerName: "Kullanıcı",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.user?.name
            )
        },
        valueGetter: (params) => `${params?.row?.user?.name}`,
    },
    {
        field: "islem_tarihi",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)
            )
        },
        valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)}`
    },
];
