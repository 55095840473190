import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Button as ButtonM } from "@mui/material";
import "assets/css/pages/definition.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AxiosCrudActions from "services/AxiosCrudCore";
import { TabPanel } from "@mui/lab";
import {
    InputAdornment,
    FormControl,
    TextField,
    MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
    FaCloudUploadAlt,
    FaPlus,
    FaTimes,
    FaEdit,
    FaTrash,
    FaCheck,
    FaCogs,
    FaExclamation,
} from "react-icons/fa";
import CreateLabProcessValidationSchema from "business-layer/CreateLabProcessValidationSchema";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const DefinitionLaboratuvar = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const MySwal = withReactContent(Swal);

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const columns = [
        {
            field: "id",
            headerName: "Sıra",
            minWidth: 100,
            maxWidth:100,
            flex: 1,
            hideable: true,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "islem_adi",
            headerName: "İşlem Adı",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "status",
            headerName: "Durum",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return params?.row?.durum ? (
                    <FaCheck color="#1ca345" />
                ) : (
                    <FaTimes color="#de3f44" />
                );
            },
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 250,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const deleteLabProcess = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.islem_adi + " silinsin mi?",
                        text: "silme işlemini gerçekleştirdiğinizde durumu pasif hale gelecektir!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Evet, Sil",
                        cancelButtonText: "Hayır,Vazgeç",
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync(
                                "/laboratuvar-islem",
                                params.row.id
                            ).then((result) => {
                                if (result?.status === 200 && result?.data?.status) {
                                    setUpdateDataGrid(!updateDataGrid);
                                }
                            });
                        }
                    });
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="işlem düzenle" arrow>
                            <ButtonM
                                variant="outlined"
                                onClick={(e) => editNewLabProcess(e, params?.row)}
                                color="warning"
                                disabled={! can('edit-laboratuvar-islem')}
                            >
                                <FaEdit />
                            </ButtonM>
                        </Tooltip>
                        <Tooltip title="işlem sil" arrow>
                            <ButtonM
                                disabled={! can('delete-laboratuvar-islem')}
                                variant="outlined"
                                onClick={deleteLabProcess}
                                color="error"
                            >
                                <FaTrash />
                            </ButtonM>
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    const [modal, setModal] = useState(false);
    const [processId, setProcessId] = useState(0);
    const [isProcesssing, setIsProcessing] = useState(false);
    const toggle = () => setModal(!modal);

    const initialLabProcessInfo = {
        islem_adi: "",
    };

    // yup schema
    let labProcessValidationSchema = CreateLabProcessValidationSchema;
    // formik schema
    const formik = useFormik({
        initialValues: initialLabProcessInfo,
        validationSchema : labProcessValidationSchema,
        onSubmit: (values) => {
            setIsProcessing(true);
            if (processId === 0) {
                AxiosCrudActions.CreateAsync("laboratuvar-islem", values).then(
                    (result) => {
                        setIsProcessing(false);
                        if (result?.data?.status) {
                            setUpdateDataGrid(!updateDataGrid);
                            toggle();
                        }
                    }
                );
            } else if (processId > 0) {
                AxiosCrudActions.UpdateAsync(
                    `laboratuvar-islem/${processId}`,
                    values
                ).then((result) => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            }
        },
    });

    const addNewLabProcess = (event) => {
        event.preventDefault();
        formik.values.islem_adi = "";
        formik.setValues({
            islem_adi: "",
        }, false)
        formik.setErrors({});
        setProcessId(0);
        toggle();
    };

    const editNewLabProcess = (event, values) => {
        event.preventDefault();
        formik.setValues({
            islem_adi: values?.islem_adi ? values?.islem_adi : "",
            durum: values?.durum ? values?.durum : 0,
        }, true)
        formik.setErrors({});
        setProcessId(values?.id);
        toggle();
    };

    // data-grid state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    // side effect
    useEffect(() => {
        axios
            .get("laboratuvar-islem", {
                params: {
                    filterModel: JSON.stringify(filterModel),
                    pageSize: pageSize,
                    page: page,
                    sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                    sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
                },
            })
            .then((res) => {
                setIsLoading(false);
                try {
                    if (res.data.data !== undefined && res.data.data !== null) {
                        setTotalCount(res.data.totalCount);
                        setData(res.data.data);
                    }
                } catch (error) {
                    setData([]);
                }
            });
    }, [sortModel, filterModel, pageSize, page, updateDataGrid]);

    return (
        <TabPanel value="6" className="definitions-container">
            {
                isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
            }
            <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                    <strong className="fs-5 fw-normal text text-secondary">
                        Laboratuvar İşlemler &nbsp;
                    </strong>
                </span>
                {can('view-laboratuvar-islem') ? 
                    <ButtonM
                        color="success"
                        variant="outlined"
                        startIcon={<FaPlus />}
                        onClick={addNewLabProcess}
                        disabled={! can('create-laboratuvar-islem')}
                    >
                        Yeni İşlem
                    </ButtonM>
                :''}

                <Modal isOpen={modal} 
                       toggle={toggle} 
                       size="md"
                       modalTransition={{ timeout: 1 }}
                       backdropTransition={{ timeout: 1 }}
                       >
                    <ModalHeader
                        toggle={toggle}
                        style={{ color: "#1172b6", fontWeight: 200 }}
                    >
                        Laboratuvar İşlem Bilgisi
                    </ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                                    <FormControl fullWidth className="mt-3">
                                        <TextField
                                            className="form-group"
                                            type="text"
                                            name="islem_adi"
                                            variant="outlined"
                                            label="İşlem Adı"
                                            autoComplete="off"
                                            InputLabelProps={{
                                                style: {
                                                    color:
                                                        formik.errors.islem_adi && formik.touched.islem_adi
                                                            ? "#e53e3e"
                                                            : "#1976d2",
                                                },
                                            }}
                                            onChange={formik.handleChange}
                                            value={formik.values.islem_adi}
                                            onBlur={formik.handleBlur}
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FaCogs />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={
                                                formik.errors.islem_adi && formik.touched.islem_adi
                                            }
                                            helperText={
                                                formik.errors.islem_adi &&
                                                formik.touched.islem_adi && (
                                                    <span className="cstm-input-error-validation-msg">
                                                        {formik.errors.islem_adi}
                                                    </span>
                                                )
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div>
                                    {processId > 0 && (
                                        <FormControl fullWidth>
                                            <TextField
                                                name="durum"
                                                select
                                                InputLabelProps={{ style: { color: "#1976d2" } }}
                                                label="Durum"
                                                size="small"
                                                defaultValue={formik.values.durum}
                                                value={formik.values.durum}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FaExclamation />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            >
                                                <MenuItem value={0}>Pasif</MenuItem>
                                                <MenuItem value={1}>Aktif</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={processId > 0 ? ! can('edit-laboratuvar-islem') : ! can('delete-laboratuvar-islem')} className="d-flex align-items-center" >
                                {
                                    isProcesssing ? 
                                    (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                            İşleniyor...
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <FaCloudUploadAlt /> &nbsp;
                                            {processId > 0 ? "Güncelle" :"Kaydet"}
                                        </>
                                    )
                                }
                            </Button>{' '}
                            <Button
                                color="danger"
                                type="button"
                                onClick={toggle}
                                className="d-flex align-items-center"
                            >
                                <FaTimes />
                                &nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>

            {useMemo(() => (
                  <>
                  {can('view-laboratuvar-islem') ? 
                    <ThemeProvider theme={theme}>
                        <Box
                            sx={{
                                width: "100%",
                                height: `${data.length > 0 ? "auto" : "400px"}`,
                            }}
                        >
                            <DataGrid
                                autoHeight={data.length > 0 ? true : false}
                                sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2 }}
                                loading={isLoading}
                                rows={data}
                                columns={columns}
                                // pagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowCount={totalCount} //serverdan gelen data total count gelecek
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                pagination
                                paginationMode="server"
                                // sorting
                                sortModel={sortModel}
                                sortingMode="server"
                                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                filterModel={filterModel}
                                filterMode="server"
                                onFilterModelChange={(newFilterModel) =>
                                    setFilterModel(newFilterModel)
                                }
                                // selection checkbox
                                //checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                // features
                                experimentalFeatures={{ newEditingApi: true }}
                                disableColumnFilter
                                disableSelectionOnClick
                                // toolbar components
                                components={{
                                    Toolbar: GridToolbar,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    LoadingOverlay: LoadingOverlay,
                                }}
                                // component props
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: {
                                            fields: ["id", "islem_adi"],
                                            fileName: "Laboratuvar Islemler",
                                            hideFooter: true,
                                            hideToolbar: true,
                                            disableToolbarButton: true,
                                        }, //sadece firsname  ve lastname alanını alır.
                                        csvOptions: {
                                            disableToolbarButton: false,
                                            fields: ["islem_adi"],
                                            fileName: "Laboratuvar Islemler",
                                            utf8WithBom: true,
                                        }, //sadece firstname ve lastName alanını alır.
                                        excelOptions: { disableToolbarButton: true },
                                        // quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Box>
                    </ThemeProvider>
                 :<NotPermission/>}
                </>
                ),
                [sortModel, filterModel, pageSize, page, updateDataGrid, data]
            )}
        </TabPanel>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
export default connect(authPermissions)(DefinitionLaboratuvar);
