
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

    const MySwal = withReactContent(Swal)

    const ErrorMessage = (title = null , text = null) => {
        return MySwal.fire({
            icon: 'error',
            title: title !== null ? title : 'Oops...',
            text: text !== null ? text : 'İşlem gerçekleştirilemedi! Lütfen tekrar deneyiniz.'
        });
    }

    const SuccessMessage = (title = null, text = null) => {
        return MySwal.fire({
            title: title !== null ? title : 'Başarılı!',
            text: text !== null ? text : 'İşlem başarılı bir şekilde gerçekleştirildi.!',
            icon:'success'
        });
    }


    const CustomMessages = {
        ErrorMessage,
        SuccessMessage
    }

export default CustomMessages;
