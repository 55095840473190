const GetAppointmentStatusValue = (status = null) => {
    if(status !== null){
        try {
            switch(status){
                case "Geldi":
                    return 1;
                case "Bekliyor":
                    return 2;
                case "Tedavide":
                    return 3;
                case "Gelmedi":
                    return 4;
                default:
                    return 4;
            }
        } catch (error) {
            return 4;
        }
    }
}

export default GetAppointmentStatusValue;