
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
    {
        //id: createEventId(),
        start: todayStr+ 'T12:00:00',
        end:  todayStr+ 'T13:00:00',
        rendering: 'background',
        editable: false,
        startEditable: false,
        durationEditable: false,
        resourceEditable: false,
        overlap: true,
        display: 'background',
        backgroundColor: '#fc8484',
        borderColor: '#fc8484',
    },
    {
        groupId: 'lunchEvents', // recurrent events in this group move together
        daysOfWeek: ['0','1','2','3','4','5','6'],
        startTime: '12:00:00',
        endTime: '13:00:00',
        rendering: 'background',
        editable: false,
        startEditable: true,
        durationEditable: false,
        resourceEditable: false,
        overlap: true,
        display: 'background',
        backgroundColor: '#fc8484',
        borderColor: '#fc8484',
        start: '12:00:00',
        end: '13:00:00',
    },
]


