import React from "react";
import { Alert } from '@mui/material';


function NotPermission() {
  return (
    <div>
        <Alert severity="warning">Buraya erişim yetkiniz bulunmamaktadır..!</Alert>
    </div>
  )
}

export default NotPermission