import React,{ useEffect, useState } from "react";
import 'assets/css/pages/dashboard.css';
import { FaFileInvoice, FaCalendarAlt, FaHeartbeat, FaSyncAlt, FaUserInjured,FaUserFriends,FaExternalLinkAlt } from "react-icons/fa";
import axios from "axios";
import { TableContainer, Table, TableHead, TableRow,Button as ButtonM, TableBody, Paper,TableCell } from '@mui/material';
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import {useNavigate } from "react-router-dom";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const Dashboard = (props) => {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }
    const navigate = useNavigate();

    const [dashboardData, setDashboardData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getDashboardData = async () => {
        await axios.get('anasayfa')
                    .then(res => {
                        if(res?.status === 200 ){
                            setIsLoading(false);
                            setDashboardData(res?.data?.data);
                        }
                    }).catch(e => {
                        setIsLoading(false);
                        setDashboardData(null);
                    })
    }

    useEffect(() => {
        getDashboardData();
    },[]); 

    return (
        <>
            {can('view-dashboard') ? 
                <div className="dashboard-container">
                    <div className="header-wrap">
                        <h4>GENEL İSTATİSTİKLER</h4>
                    </div>
                    {/* istatistik container */}
                    <section>
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="card-wrap">
                                    <div className="card-header d-flex justify-content-between align-items-between">
                                        <h5>Hasta</h5>
                                        <span className="fs-6 fw-light">Bugün</span>
                                    </div>
                                    <div className="card-body">
                                        <span className="card-icon">
                                            <FaUserInjured />
                                        </span>
                                        <div className="card-content">
                                            Toplam Hasta: <span> <strong>{isLoading ? <FaSyncAlt /> : dashboardData?.hasta}</strong> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="card-wrap">
                                    <div className="card-header d-flex justify-content-between align-items-between">
                                        <h5>Tedavi</h5>
                                        <span className="fs-6 fw-light">Bugün</span>
                                    </div>
                                    <div className="card-body">
                                        <span className="card-icon">
                                            <FaHeartbeat />
                                        </span>
                                        <div className="card-content">
                                            Toplam Tedavi : <span> <strong>{isLoading ? <FaSyncAlt /> : dashboardData?.tedavi}</strong> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="card-wrap">
                                    <div className="card-header d-flex justify-content-between align-items-between">
                                        <h5>Randevu</h5>
                                        <span className="fs-6 fw-light">
                                            Bugün
                                        </span>
                                    </div>
                                    <div className="card-body">
                                        <span className="card-icon">
                                            <FaCalendarAlt />
                                        </span>
                                        <div className="card-content">
                                            Toplam Randevu : <span> <strong>{isLoading ? <FaSyncAlt /> : dashboardData?.randevu}</strong> </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="card-wrap">
                                    <div className="card-header d-flex justify-content-between align-items-between">
                                        <h5>Fatura</h5>
                                        <span className="fs-6 fw-light">
                                            Bugün
                                        </span>
                                    </div>
                                    <div className="card-body">
                                        <span className="card-icon">
                                            <FaFileInvoice />
                                        </span>
                                        <div className="card-content">
                                            Toplam Fatura : <span> <strong>{isLoading ? <FaSyncAlt /> : dashboardData?.fatura}</strong> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="dashboard-patients-section">
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="d-flex align-items-center">
                                <strong className="fs-5 fw-normal text text-secondary mb-2">Bugün Gelen Hastalar</strong>
                            </span>
                        </div>
                        <TableContainer component={Paper}>
                            <Table className="table table-striped-dashbord-patients" sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                <TableRow className="dashboard-patients-section-tableRow">
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell align="center">Hasta</TableCell>
                                    <TableCell align="center">Tc No</TableCell>
                                    <TableCell align="center">Tel No</TableCell>
                                    <TableCell align="center">Kayıt Tarihi</TableCell>
                                    <TableCell align="center">Cinsiyet</TableCell>
                                    <TableCell align="center">Durum</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dashboardData?.hastalar.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="center" component="td" >{row.id}</TableCell>
                                            <TableCell align="center" component="td" >{row.ad_soyad}</TableCell>
                                            <TableCell align="center" component="td" >{row.tc_no}</TableCell>
                                            <TableCell align="center" component="td" >{row.tel_no}</TableCell>
                                            <TableCell align="center" component="td" >{GetTrFullDateAndTimeWithFormat(row.kayit_tarihi)}</TableCell>
                                            <TableCell align="center" component="td" >{row.cinsiyet}</TableCell>
                                            <TableCell align="center" component="td">
                                                <ButtonM color="primary" variant="outlined" size="small" onClick={() => { navigate(`/hastalar/detay/${row.id}/tedavi`) }} style={{ cursor: "pointer" }}><FaExternalLinkAlt/>&nbsp;Detay</ButtonM> {/*hastalar/duzenle/*/}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {isLoading ?  "":
                            <ButtonM className="dashboard-patients-section-allPatients-button"  color="primary" variant="outlined" type="submit" startIcon={<FaUserFriends />}  onClick={() => { navigate('/hastalar') }} >
                            Tüm Hastalar
                            </ButtonM>}
                        </TableContainer>
                        {
                            isLoading ?    
                
                            <div style={{maxHeight:"500px"}}>
                                <LoadingOverlay />
                            </div>:""
                        }
                    </section>
                </div>
            :<NotPermission/>}
        </>
    );
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(Dashboard);
