import * as React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from 'react-number-format';

const CurrencyMask = React.forwardRef(function CurrencyMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={2}
      //thousandSeparator
      valueIsNumericString={true}
      allowLeadingZeros={false}
      fixedDecimalScale={true}

      decimalSeparator={","}
      //decimalPrecision={2}
      thousandSeparator={"."}
      
    />
  );
});

CurrencyMask.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CurrencyMask;
