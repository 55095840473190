import React from "react";
import 'assets/css/loading-page/loading-page.css';

const LoadingPage = () => {
    return (
        <div className="loading-page-wrapper">
            <div className="spinner-border text-secondary">
                
            </div>
        </div>
    )
}   

export default LoadingPage;