import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { GetTriggerActionName } from "extensions/GetTriggerActionName";

export const CarilerLogColumns = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        flex: 1,
        headerAlign: "center",
        minWidth:100,
        maxWidth:100,
        hideable: true,
        editable: false,
        filterable: false
    },
    {
        field: "cari_adi",
        headerName: "Cari Adı",
        minWidth: 180,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
    },
    {
        field: "hesap_tipi",
        headerName: "Hesap Tipi",
        minWidth: 130,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.hesap_tipi ? params?.row?.hesap_tipi?.name : ""
            )
        },
        valueGetter: (params) => `${params?.row?.hesap_tipi?.name}`,
    },
    
    {
        field: "vergi_dairesi",
        headerName: "Vergi Dairesi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
    },
    {
        field: "vergi_numarasi",
        headerName: "Vergi Numarası",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
    },
    {
        field: "telefon",
        headerName: "Telefon",
        minWidth: 135,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
    },
    {
        field: "il",
        headerName: "Şehir",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.il?.il ? params?.row?.il?.il : ""
            )
        },
        valueGetter: (params) => `${params?.row?.il?.il}`,
    },
    {
        field: "ilce",
        headerName: "İlçe",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: true, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                params?.row?.ilce?.ilce ? params?.row?.ilce?.ilce : ""
            )
        },
        valueGetter: (params) => `${params?.row?.ilce?.ilce}`,
    },
    {
        field: "status",
        headerName: "Durum",
        minWidth: 80,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.durum ? "Aktif" : "Pasif"
            )
        },
        valueGetter: (params) => params.row.durum ? "Aktif" : "Pasif"
    },
    {
        field: "action",
        headerName: "İşlem",
        minWidth: 100,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTriggerActionName(params?.row?.action)
            )
        },
        valueGetter: (params) => GetTriggerActionName(params?.row?.action)
    },
    {
        field: "update_text",
        headerName: "Güncelleme İşlemi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "user",
        headerName: "Kullanıcı",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.user?.name
            )
        },
        valueGetter: (params) => `${params?.row?.user?.name}`,
    },
    {
        field: "islem_tarihi",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)
            )
        },
        valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)}`
    },
];