
import * as yup from 'yup';  

const CreateMedicineValidationSchema = () => {
    return yup.object().shape({

        ilac_adi: yup.string()
                  .min(3,"ilaç adı en az 3 karakter olmalıdır.")
                  .max(50,"ilaç adı en fazla 50 karakter olmalıdır.")
                  .required("Lütfen geçerli bir ilaç adı giriniz."),

   });
}   

export default CreateMedicineValidationSchema;

