
import axios from "axios";
import CustomMessages from "./CustomSweetAlert2Messgaes";


const CreateAsync = async (url = null, params = null)  => {
    const data = params;
    data._method = 'POST';
    const header = {
        // 'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
    };
    try {
        const result = await axios.post(url,data,{headers: header});
        if(result?.status === 201 && result?.data?.status){
            CustomMessages.SuccessMessage('Başarılı', 'İşlem başarılı bir şekilde gerçekleştirildi.');
            return result;
        }else{
            return CustomMessages.ErrorMessage('Hata!', 'İşlem gerçekleştirilemedi! Lütfen tekrar deneyiniz.');
        }
    } catch (error) {
         CustomMessages.ErrorMessage('Hata!', 'İşlem sırasında hata gerçekleşti. Lütfen daha sonra tekrar deneyiniz.'); 
         return error;
    }
} 


const UpdateAsync = async (url = null, params = null)  => {
    const data = params;
    data._method = 'PATCH';
    const header = {
        // 'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data'
    };
    try {
        const result = await axios.post(url,data,{headers: header});
        if(result?.status === 200 && result?.data?.status){
            CustomMessages.SuccessMessage('Başarılı', 'İşlem başarılı bir şekilde güncellendi.');
            return result;
        }else{
            return CustomMessages.ErrorMessage('Hata!', 'Güncelleme Başarısız! Lütfen tekrar deneyiniz.');
        }
    } catch (error) {
            CustomMessages.ErrorMessage('Hata!', 'Güncelleme sırasında hata gerçekleşti. Lütfen daha sonra tekrar deneyiniz.');
            return error;
    }
} 


const DeleteAsync = async (url = null, id = null, payload = null) => {
    if(id !== null && id > 0){
        try {
            const result = await axios.delete(url+"/"+id, (payload !== null ? {params: payload} : null));
            if(result?.status === 200){
                CustomMessages.SuccessMessage('Başarılı', 'Kayıt başarılı bir şekilde silindi.');
                return result;
            }
            return CustomMessages.ErrorMessage('Hata!', 'Kayıt silinemedi! Lütfen tekrar deneyiniz.');
        } catch (error) {
            CustomMessages.ErrorMessage('Hata!', 'Kayıt silinemedi. Bazı hatalar algılandı.' + (error?.response?.data?.message ?? ''));
            return error;
        }
    }
    return CustomMessages.ErrorMessage('Hata!', 'Geçersiz parametre kullanımı.');
}

const DeleteManyAsync = async (url = null, ids = null) => {
    if(ids !== null && ids.length > 0){
        try {
            const result = await axios.delete(url, {params: {ids: ids} });
            if(result?.status === 200){
                CustomMessages.SuccessMessage('Başarılı', 'Seçilen kayıtlar başarılı bir şekilde silindi.');
                return result;
            }
            return CustomMessages.ErrorMessage('Hata!', 'Seçilen kayıtlar silinemedi! Lütfen tekrar deneyiniz.');
        } catch (error) {
            CustomMessages.ErrorMessage('Hata!', 'Seçilen kayıtlar silinemedi. Bazı hatalar algılandı.');
            return error;
        }
    }
    return CustomMessages.ErrorMessage('Hata!', 'Geçersiz parametre kullanımı.');
}


const AxiosCrudActions = {
    CreateAsync,
    DeleteAsync,
    UpdateAsync,
    DeleteManyAsync
}

export default AxiosCrudActions;