import * as React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from 'react-number-format';

const RateMask = React.forwardRef(function RateMask(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      valueIsNumericString={true}
      decimalScale={0}
      allowLeadingZeros={false}

      isAllowed={(values, sourceInfo) => {
        const { value } = values;
        return value < 101;
      }}
      
    />
  );
});

RateMask.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RateMask;
