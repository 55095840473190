
import * as yup from 'yup';  
import parse from "date-fns/parse";

const CreateAppointmentValidationSchema = () => {
    return yup.object().shape({

        hasta_id: yup.number()
                .min(1,"Lütfen hasta seçiniz.")
                .required("Lütfen hasta seçiniz."),

        doktor_id: yup.number()
                .min(1,"Lütfen doktor seçiniz.")
                .required("Lütfen doktor seçiniz."),

        randevu_tedavi_id: yup.number()
                .min(1,"Lütfen tedavi seçiniz.")
                .required("Lütfen tedavi seçiniz."),

        // baslangic_tarihi: yup.date()
        //             .transform(function (value, originalValue) {
        //             if (this.isType(value)) {
        //                 return value;
        //             }
        //             const result = parse(originalValue, "dd.MM.yyyy", new Date());
        //             return result;
        //             })
        //             .typeError("Lütfen geçerli bir başlangıç tarihi ve saati giriniz")
        //             .required(),

        // bitis_tarihi: yup.date()
        //             .transform(function (value, originalValue) {
        //             if (this.isType(value)) {
        //                 return value;
        //             }
        //             const result = parse(originalValue, "dd.MM.yyyy", new Date());
        //             return result;
        //             })
        //             .typeError("Lütfen geçerli bir bitiş tarihi ve saati giriniz")
        //             .required(),
                

    });
}   

export default CreateAppointmentValidationSchema;