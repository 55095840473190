import React, { useEffect, useMemo, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import "assets/css/pages/patients.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import {
  FaPlus, FaMinus, FaEdit, FaTrash, FaSearch, FaList, FaArrowsAltV, FaTimes, FaExclamation, FaExclamationCircle,
  FaHeading, FaCloudUploadAlt, FaSortAmountUp, FaLiraSign, FaFileInvoice, FaMapMarkerAlt, FaStickyNote, FaCheck
} from 'react-icons/fa';
import CreateStockCardValidationSchema from 'business-layer/CreateStockCardValidationSchema';
import CreateStockProcessValidationSchema from 'business-layer/CreateStockProcessValidationSchema';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import GetTrDate from "extensions/GetTRDate";
import { useFormik } from 'formik';
import { InputAdornment, FormControl, TextField, MenuItem, Autocomplete, Button } from '@mui/material';
import { Button as ButtonM, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import NumberMask from 'Utils/NumberMask';
import CurrencyMask from 'Utils/CurrencyMask';
import { tr } from 'date-fns/locale';
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

function Stocks(props) {
  const { auth } = props;
  const can = (permission) => {
    return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
  }

  const MySwal = withReactContent(Swal)

  const theme = createTheme(
    trTR, // x-data-grid translations
    turkishTr // core translations
  );

  const navigate = useNavigate();
  const columns = [
    {
      field: "stok_adi",
      headerName: "Stok Adı",
      minWidth: 150,
      flex: 1,
      editable: false,
      hideable: false, // cant hide this column
      sortable: true,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
    },
    {
      field: "mevcut_stok",
      headerName: "Mevcut Stok",
      minWidth: 50,
      flex: 1,
      editable: false,
      hideable: false, // cant hide this column
      sortable: false,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
      renderCell: (params) => {
        return params?.row?.mevcut_stok <= params?.row?.kritik_seviye ? <div style={{ fontWeight: "450", color: "red", fontSize: "16" }}> {params?.row?.mevcut_stok} </div> : <div style={{ fontWeight: "450", fontSize: "16" }}> {params?.row?.mevcut_stok} </div>
      }
    },
    {
      field: "kritik_seviye",
      headerName: "Kritik Seviye",
      minWidth: 50,
      flex: 1,
      editable: false,
      hideable: false, // cant hide this column
      sortable: false,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
    },
    {
      field: "birim",
      headerName: "Birim",
      minWidth: 100,
      flex: 1,
      editable: false,
      hideable: true, // cant hide this column
      sortable: true,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
      renderCell: (params) => {
        return params.row.birim?.name;
      }
    },
    {
      field: "stok_grubu",
      headerName: "Stok Grubu",
      minWidth: 100,
      flex: 1,
      editable: false,
      hideable: true, // cant hide this column
      sortable: true,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
      renderCell: (params) => {
        return params.row.stok_grubu?.name;
      }
    },
    {
      field: "durum",
      headerName: "Durum",
      minWidth: 50,
      flex: 1,
      editable: false,
      hideable: false, // cant hide this column
      sortable: false,
      hide: false, //when data grid loaded, this column will hide
      filterable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          params?.row?.durum ? <FaCheck color="#1ca345" /> : <FaTimes color="#de3f44" />
        )
      }
    },
    {
      field: "actions",
      headerName: "İşlemler",
      minWidth: 280,
      headerAlign: "center",
      flex: 1,
      align: "center",
      editable: false,
      disableExport: true,
      hideable: false,
      sortable: false,
      hide: false,
      filterable: false,
      //disableClickEventBubbling: true,
      renderCell: (params) => {


        const deleteStock = (e) => {
          e.preventDefault();
          e.stopPropagation();
          MySwal.fire({
            title: 'Emin misin?',
            text: "Bunu geri alamazsınız!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, Sil',
            cancelButtonText: 'Vazgeç'
          }).then(async (result) => {
            if (result.isConfirmed) {
              AxiosCrudActions.DeleteAsync('/stok-kartlari', params.row.id)
                .then(result => {
                  if (result?.status === 200 && result?.data?.status) {
                    setUpdateDataGrid(!updateDataGrid);
                  }
                });

            }
          })
        };

        const stockMovements = (e) => {
          e.preventDefault();
          e.stopPropagation();
          navigate(`/stok-hareketleri/${params.row.id}`, { state: { data: params.row } });
        };

        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="stok hareketleri" arrow>
              <Button variant="outlined" onClick={stockMovements} disabled={!can('view-stok-detay')} tooltip="stok hareketleri" color="info"><FaList /></Button>
            </Tooltip>
            <Tooltip title="stok işlemi" arrow>
              <Button variant="outlined" color="secondary" disabled={!can('view-stok-detay')} onClick={(e) => stockProcessModal(e, params?.row)}><FaArrowsAltV /></Button>
            </Tooltip>
            <Tooltip title="stok düzenle" arrow>
              <Button variant="outlined" onClick={(e) => editStockCard(e, params?.row)} disabled={!can('edit-stok')} color="warning"><FaEdit /></Button>
            </Tooltip>
            <Tooltip title="stok sil" arrow>
              <Button variant="outlined" onClick={deleteStock} disabled={!can('delete-stok')} color="error"><FaTrash /></Button>
            </Tooltip>
          </Stack>
        );
      },
    }
  ];

  // state
  const [updateDataGrid, setUpdateDataGrid] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [modalStockCard, setModalStockCard] = useState(false);
  const [modalStockProcess, setModalStockProcess] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [selectionModel, setSelectionModel] = useState([]); //selected row
  const [stokCardVal, setStokCardVal] = useState(0);
  const [addEditMeasureUnit, setAddEditMeasureUnit] = useState({ id: 1, name: "Adet" });
  const [addEditGroupStock, setAddEditGroupStock] = useState({ id: 1, name: "Dental" });
  const [stockCardData, setStokCardData] = useState('');
  const [stockProcessType, setStockProcessType] = useState('1');
  const [date, setDate] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(0);

  const [isProcesssingStockCard, setIsProcesssingStockCard] = useState(false);
  const [isProcesssingStockProcess, setIsProcesssingStockProcess] = useState(false);


  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);

  const toggleStockCard = () => { setModalStockCard(!modalStockCard); setModalStockProcess(false) };
  const toggleStockProcess = () => { setModalStockProcess(!modalStockProcess); setModalStockCard(false) };



  const initialStockCardInfo = {
    stok_adi: '',
    stok_kodu: '',
    stok_grubu: '',
    kritik_seviye: '',
    seri_no: '',
    barkod_no: '',
    aciklama: '',
    temin_yeri: '',
    birim: '',
  }

  // yup schema
  let stockCardValidationSchema = CreateStockCardValidationSchema();
  // formik schema
  const stockCardFormik = useFormik({
    initialValues: initialStockCardInfo,
    validationSchema: stockCardValidationSchema,
    onSubmit: values => {
      values.birim = addEditMeasureUnit?.id;
      values.stok_grubu = addEditGroupStock?.id;
      setIsProcesssingStockCard(true);
      if (stokCardVal === 0) {
        AxiosCrudActions.CreateAsync('stok-kartlari', values).then(result => {
          setIsProcesssingStockCard(false);
          if (result?.data?.status) {
            setUpdateDataGrid(!updateDataGrid);
            toggleStockCard();
          }
        });
      } else if (stokCardVal > 0) {
        AxiosCrudActions.UpdateAsync(`stok-kartlari/${stokCardVal}`, values).then(result => {
          setIsProcesssingStockCard(false);
          if (result?.data?.status) {
            setUpdateDataGrid(!updateDataGrid);
            toggleStockCard();
          }
        });
      }
    },
  });


  const getDate = (date) => {
    if (date !== undefined && date !== null) {
      try {
        let getJustDate = dayjs(date).get('year') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('date');
        return getJustDate;
      } catch (error) {
        return null;
      }
    }
  }


  const addNewStockCard = (event) => {
    event.preventDefault();
    stockCardFormik.setValues({
      stok_adi: '',
      stok_kodu: '',
      kritik_seviye: '',
      seri_no: '',
      barkod_no: '',
      aciklama: '',
      temin_yeri: ''
    }, false)
    stockCardFormik.setErrors({});
    setAddEditMeasureUnit({ id: 1, name: "Adet" });
    setAddEditGroupStock({ id: 1, name: "Dental" });
    setStokCardVal(0);
    toggleStockCard();
  }

  const editStockCard = (event, values) => {
    event.preventDefault();
    stockCardFormik.setValues({
      stok_adi: values?.stok_adi ? values?.stok_adi : '',
      stok_kodu: values?.stok_kodu ? values?.stok_kodu : '',
      kritik_seviye: values?.kritik_seviye ? values?.kritik_seviye : '',
      seri_no: values?.seri_no ? values?.seri_no : '',
      barkod_no: values?.barkod_no ? values?.barkod_no : '',
      aciklama: values?.aciklama ? values?.aciklama : '',
      temin_yeri: values?.temin_yeri ? values?.temin_yeri : '',
      durum: values?.durum ? values?.durum : '',
    }, false)
    stockCardFormik.setErrors({});
    setAddEditMeasureUnit(values?.birim);
    setAddEditGroupStock(values?.stok_grubu);
    setStokCardVal(values?.id);
    toggleStockCard();
  }


  const initialStockProcessInfo = {
    miktar: '',
    birim_fiyat: '',
    tarih: '',
    fatura_no: '',
    aciklama: '',
    temin_yeri: '',
    tip: '',
    user_id: '',
  }

  // yup schema
  let stockProcessValidationSchema = CreateStockProcessValidationSchema();

  const stockProcessFormik = useFormik({
    initialValues: initialStockProcessInfo,
    validationSchema: stockProcessValidationSchema,
    onSubmit: values => {
      setIsProcesssingStockProcess(true);
      values.tarih = getDate(date);
      values.tip = parseInt(stockProcessType);
      values.stok_id = parseInt(stockCardData?.id);
      AxiosCrudActions.CreateAsync(`stok-hareketleri`, values).then(result => {

        setIsProcesssingStockProcess(false);
        if (result?.data?.status) {
          setUpdateDataGrid(!updateDataGrid);
          toggleStockProcess();
        }
      });
    },
  });


  const stockProcessModal = (event, values) => {
    event.preventDefault();
    stockProcessFormik.setValues({
      miktar: stockCardData?.miktar ? stockCardData?.miktar : '',
      temin_yeri: '',
    }, false)
    stockProcessFormik.setErrors({});
    setStokCardData('');
    setStockProcessType('1')
    setStokCardData(values)
    toggleStockProcess();
  }

  const changeStockMovementType = (event) => {
    //event.preventDefault();
    setStockProcessType(event.target.value);
  }

  const handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const getUsers = () => {
    try {
      axios.get('/kullanicilar?doktor=1')
        .then(res => {
          let result = res.data.data;
          let allUsers = (result).map(({ id, name }) => { return { 'id': id, 'name': name } });
          setUsers(allUsers);
        })
    } catch (error) {
      setUsers([]);
    }
  }

  // side effect
  useEffect(() => {
      getUsers();
  }, []);

  // side effect
  useEffect(() => {
    axios.get("stok-kartlari", {
      params: {
         filterModel: JSON.stringify(filterModel),
         pageSize: pageSize,
         page: page,
         sortColumn: sortModel[0]?.field ? sortModel[0]?.field : "",
         sorting: sortModel[0]?.sort ? sortModel[0]?.sort : "",
      }
    }).then((res) => {
      setIsLoading(false);
      try {
        if (res.data.data !== undefined && res.data.data !== null) {
          setTotalCount(res.data.totalCount);
          setData(res.data.data);
        }
      } catch (error) {
        setData([]);
      }
    });

  }, [sortModel, filterModel, pageSize, page, updateDataGrid]);


  return (
    <div className="patients-container">
      {
        isProcesssingStockCard || isProcesssingStockProcess ? <div style={{ width: '100%', height: '100vh', position: 'fixed', top: '0', left: '0', bottom: '0', right: '0', overflow: "hidden", zIndex: 99999, background: 'transparent' }}></div> : null
      }
      <div className="d-flex justify-content-between align-items-center">
        <span className="d-flex align-items-center">
          <strong className="fs-5 fw-normal text text-secondary">Stoklar &nbsp;</strong>
        </span>
        {can('view-stok') ?
          <Button color="success" variant="outlined" startIcon={<FaPlus />} disabled={!can('create-stok')} onClick={addNewStockCard}>
            Yeni Stok
          </Button>
          : <NotPermission />}
      </div>


      <Modal isOpen={modalStockCard}
        toggle={toggleStockCard}
        size="lg"
        modalTransition={{ timeout: 1 }}
        backdropTransition={{ timeout: 1 }}
      >
        <ModalHeader toggle={toggleStockCard} style={{ color: "#1172b6", fontWeight: 200 }}>Ürün Stok Kartı</ModalHeader>
        <form onSubmit={stockCardFormik.handleSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="text"
                    name="stok_adi"
                    variant='outlined'
                    label="Stok Adı"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: stockCardFormik.errors.stok_adi && stockCardFormik.touched.stok_adi ? '#e53e3e' : '#1976d2' } }}
                    onChange={stockCardFormik.handleChange}
                    value={stockCardFormik.values.stok_adi}
                    onBlur={stockCardFormik.handleBlur}
                    size='small'
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaHeading /></InputAdornment>,
                    }}
                    error={stockCardFormik.errors.stok_adi && stockCardFormik.touched.stok_adi}
                    helperText={stockCardFormik.errors.stok_adi && stockCardFormik.touched.stok_adi && <span className='cstm-input-error-validation-msg'>{stockCardFormik.errors.stok_adi}</span>}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="text"
                    name="seri_no"
                    variant='outlined'
                    label="Seri No"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: stockCardFormik.errors.seri_no && stockCardFormik.touched.seri_no ? '#e53e3e' : '#1976d2' } }}
                    onChange={stockCardFormik.handleChange}
                    value={stockCardFormik.values.seri_no}
                    onBlur={stockCardFormik.handleBlur}
                    size='small'
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaSearch /></InputAdornment>,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-3">
                  <Autocomplete
                    name="birim"
                    options={[
                      { id: 1, name: 'Adet' },
                      { id: 2, name: 'Kutu' },
                      { id: 3, name: 'Kilogram' },
                      { id: 4, name: 'Gram' },
                      { id: 5, name: 'Litre' },
                      { id: 6, name: 'Mililitre' }
                    ]}
                    getOptionLabel={option => option?.name}
                    disableClearable
                    loading={true}
                    noOptionsText={'Seçenek Bulunamadı'}
                    value={addEditMeasureUnit}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(event, newValue) => {
                      event.preventDefault();
                      setAddEditMeasureUnit(newValue);
                    }}
                    onBlur={stockCardFormik.handleBlur}
                    size="small"
                    renderInput={(params) => <TextField
                      {...params}
                      label="Birim"
                    />}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="text"
                    name="temin_yeri"
                    variant='outlined'
                    label="Tedarik Yeri"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: stockCardFormik.errors.temin_yeri && stockCardFormik.touched.temin_yeri ? '#e53e3e' : '#1976d2' } }}
                    onChange={stockCardFormik.handleChange}
                    value={stockCardFormik.values.temin_yeri}
                    onBlur={stockCardFormik.handleBlur}
                    size='small'
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaSearch /></InputAdornment>,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="text"
                    name="kritik_seviye"
                    variant='outlined'
                    label="Kritik Stok Seviyesi"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: stockCardFormik.errors.kritik_seviye && stockCardFormik.touched.kritik_seviye ? '#e53e3e' : '#1976d2' } }}
                    onChange={stockCardFormik.handleChange}
                    value={stockCardFormik.values.kritik_seviye}
                    onBlur={stockCardFormik.handleBlur}
                    size='small'
                    InputProps={{
                      inputComponent: NumberMask,
                      startAdornment: <InputAdornment position="start" ><FaExclamationCircle /></InputAdornment>,
                    }}
                  />
                </FormControl>
                <div>
                  {
                    stokCardVal > 0 &&
                    <FormControl fullWidth className="mt-3">
                      <TextField
                        name='durum'
                        select
                        InputLabelProps={{ style: { color: '#1976d2' } }}
                        label="Durum"
                        size='small'
                        defaultValue={stockCardFormik.values.durum ? 1 : 0}
                        value={stockCardFormik.values.durum ? 1 : 0}
                        onChange={stockCardFormik.handleChange}
                        onBlur={stockCardFormik.handleBlur}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><FaExclamation /></InputAdornment>,
                        }}>
                        <MenuItem value={0}>Pasif</MenuItem>
                        <MenuItem value={1}>Aktif</MenuItem>
                      </TextField>
                    </FormControl>
                  }
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="text"
                    name="stok_kodu"
                    variant='outlined'
                    label="Stok Kodu"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: stockCardFormik.errors.stok_kodu && stockCardFormik.touched.stok_kodu ? '#e53e3e' : '#1976d2' } }}
                    onChange={stockCardFormik.handleChange}
                    value={stockCardFormik.values.stok_kodu}
                    onBlur={stockCardFormik.handleBlur}
                    size='small'
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaSearch /></InputAdornment>,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-3">
                  <TextField className='form-group'
                    type="text"
                    name="barkod_no"
                    variant='outlined'
                    label="Barkod"
                    autoComplete="off"
                    InputLabelProps={{ style: { color: stockCardFormik.errors.barkod_no && stockCardFormik.touched.barkod_no ? '#e53e3e' : '#1976d2' } }}
                    onChange={stockCardFormik.handleChange}
                    value={stockCardFormik.values.barkod_no}
                    onBlur={stockCardFormik.handleBlur}
                    size='small'
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaSearch /></InputAdornment>,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-3">
                  <Autocomplete
                    name="stok_grubu"
                    options={[
                      { id: 1, name: 'Dental' },
                      { id: 2, name: 'Tıbbi Malzeme' },
                      { id: 3, name: 'Sarf Malzeme' }
                    ]}
                    getOptionLabel={option => option?.name}
                    disableClearable
                    loading={true}
                    noOptionsText={'Seçenek Bulunamadı'}
                    value={addEditGroupStock}
                    onChange={(event, newValue) => {
                      event.preventDefault();
                      setAddEditGroupStock(newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onBlur={stockCardFormik.handleBlur}
                    size="small"
                    renderInput={(params) => <TextField
                      {...params}
                      label="Stok Grubu"
                    />}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-3">
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Açıklama"
                    multiline
                    minRows={stokCardVal > 0 ? 5 : 3}
                    name="aciklama"
                    placeholder="Açıklama..."
                    autoComplete="off"
                    InputLabelProps={{ style: { color: stockCardFormik.errors.aciklama && stockCardFormik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                    value={stockCardFormik.values.aciklama}
                    onChange={stockCardFormik.handleChange}
                    onBlur={stockCardFormik.handleBlur}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" ><FaSearch /></InputAdornment>,
                    }}
                  />
                </FormControl>

              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" type="submit" style={{ background: can('edit-stok') || can('create-stok') ? "#1172b6" : "#65A4D0", color: "white" }} disabled={stokCardVal > 0 ? !can('edit-stok') : !can('create-stok')} className="d-flex align-items-center" >
              {
                isProcesssingStockCard ?
                  (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                      İşleniyor...
                    </>
                  )
                  :
                  (
                    <>
                      <FaCloudUploadAlt /> &nbsp;
                      {stokCardVal > 0 ? "Güncelle" : "Kaydet"}
                    </>
                  )
              }
            </Button>{' '}
            <ButtonM color="danger" type="button" onClick={toggleStockCard} className="d-flex align-items-center">
              <FaTimes />&nbsp;Vazgeç
            </ButtonM>
          </ModalFooter>
        </form>
      </Modal>

      <Modal isOpen={modalStockProcess}
        toggle={toggleStockProcess}
        size="lg"
        modalTransition={{ timeout: 1 }}
        backdropTransition={{ timeout: 1 }}
      >
        <ModalHeader toggle={toggleStockProcess} style={{ color: "#1172b6", fontWeight: 200 }}><span style={{ color: "#9C27B0" }}>{stockCardData?.stok_adi} </span>Stok İşlemi</ModalHeader>
        <form onSubmit={stockProcessFormik.handleSubmit}>
          <ModalBody >
            <div style={{ borderLeft: `5px solid ${stockProcessType === '1' ? '#4caf50' : '#DC3545'}`, paddingLeft: "10px", borderRadius: "8px" }}>
              <div className="row">
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-2 pt-2'>
                  <div className='form-group patient-cstm-radio-btns'>
                    <div className='patient-cstm-gender-radio'>
                      <input
                        type="radio"
                        onChange={e => changeStockMovementType(e)}
                        defaultChecked
                        name="tip"
                        value="1"
                      /><label><FaPlus />Stok Girişi</label>
                    </div>
                    <div className='patient-cstm-gender-radio'>
                      <input
                        type="radio"
                        onChange={e => changeStockMovementType(e)}
                        name="tip"
                        value="0"
                      /><label><FaMinus />Stok Çıkışı</label>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                  <FormControl fullWidth className="mt-3">
                    <TextField className='form-group'
                      type="number"
                      name="miktar"
                      variant='outlined'
                      label="Miktar"
                      autoComplete="off"
                      InputLabelProps={{ style: { color: stockProcessFormik.errors.miktar && stockProcessFormik.touched.miktar ? '#e53e3e' : '#1976d2' } }}
                      onChange={stockProcessFormik.handleChange}
                      value={stockProcessFormik.values.miktar}
                      onBlur={stockProcessFormik.handleBlur}
                      size='small'
                      InputProps={{
                        startAdornment: <InputAdornment position="start" ><FaSortAmountUp /></InputAdornment>,
                      }}
                      error={stockProcessFormik.errors.miktar && stockProcessFormik.touched.miktar}
                      helperText={stockProcessFormik.errors.miktar && stockProcessFormik.touched.miktar && <span className='cstm-input-error-validation-msg'>{stockProcessFormik.errors.miktar}</span>}
                    />
                  </FormControl>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                  <FormControl fullWidth className="mt-3">
                    <TextField className='form-group'
                      disabled
                      style={{ backgroundColor: "#f5f5f5" }}
                      value={stockCardData?.birim?.name}
                      size='small'
                    />
                  </FormControl>
                </div>
                {
                  stockProcessType === '1' ?
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                      <FormControl fullWidth className="mt-3">
                        <TextField className='form-group'
                          type="text"
                          name="birim_fiyat"
                          variant='outlined'
                          label="Birim Fiyat"
                          autoComplete="off"
                          InputLabelProps={{ style: { color: stockProcessFormik.errors.birim_fiyat && stockProcessFormik.touched.birim_fiyat ? '#e53e3e' : '#1976d2' } }}
                          onChange={stockProcessFormik.handleChange}
                          value={stockProcessFormik.values.birim_fiyat}
                          onBlur={stockProcessFormik.handleBlur}
                          size='small'
                          InputProps={{
                            inputComponent: CurrencyMask,
                            startAdornment: <InputAdornment position="start" ><FaLiraSign /></InputAdornment>,
                          }}

                        />
                      </FormControl>
                    </div>
                    : ""
                }
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                  <FormControl fullWidth className="mt-3">
                    <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} >
                      <DesktopDatePicker
                        variant='outlined'
                        label="Tarihi"
                        inputFormat="dd-MM-yyyy"
                        minDate={dayjs('01-01-1930')}
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params}
                          size="small"
                          name="tarih"
                          variant='outlined'
                        />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </div>
                {
                  stockProcessType === '1' ?
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                      <FormControl fullWidth className="mt-3">
                        <TextField className='form-group'
                          type="text"
                          name="fatura_no"
                          variant='outlined'
                          label="Fatura No"
                          autoComplete="off"
                          InputLabelProps={{ style: { color: stockProcessFormik.errors.fatura_no && stockProcessFormik.touched.fatura_no ? '#e53e3e' : '#1976d2' } }}
                          onChange={stockProcessFormik.handleChange}
                          value={stockProcessFormik.values.fatura_no}
                          onBlur={stockProcessFormik.handleBlur}
                          onKeyDown={handleKeyDown}
                          size='small'
                          InputProps={{
                            startAdornment: <InputAdornment position="start" ><FaFileInvoice /></InputAdornment>,
                          }}

                        />
                      </FormControl>
                    </div> : ""
                }
                {
                  stockProcessType === '1' ?
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                      <FormControl fullWidth className="mt-3">
                        <TextField className='form-group'
                          type="text"
                          name="temin_yeri"
                          variant='outlined'
                          label="Temin Yeri"
                          autoComplete="off"
                          InputLabelProps={{ style: { color: stockProcessFormik.errors.temin_yeri && stockProcessFormik.touched.temin_yeri ? '#e53e3e' : '#1976d2' } }}
                          onChange={stockProcessFormik.handleChange}
                          value={stockProcessFormik.values.temin_yeri}
                          onBlur={stockProcessFormik.handleBlur}
                          size='small'
                          InputProps={{
                            startAdornment: <InputAdornment position="start" ><FaMapMarkerAlt /></InputAdornment>,
                          }}
                        />
                      </FormControl>
                    </div>
                    :
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                      <FormControl fullWidth className="mt-3">
                        <Autocomplete
                          options={users}
                          getOptionLabel={option => option.name}
                          loading={true}
                          noOptionsText={'Seçenek Bulunamadı'}
                          value={stockProcessFormik.user_id}
                          name="user_id"
                          onChange={(event, newValue) => {
                            setUserId(newValue);
                            stockProcessFormik.setFieldValue('user_id', newValue.id, true); //update formik value and validate true
                          }}
                          size="small"
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                          renderInput={(params) => <TextField
                            {...params}
                            label="Talep Eden"
                          />}

                        />
                      </FormControl>
                    </div>
                }
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-2 pt-2">
                  <FormControl fullWidth className="mt-3">
                    <TextField className='form-group'
                      id="outlined-multiline-flexible"
                      label="Açıklama"
                      multiline
                      minRows={2}
                      name="aciklama"
                      placeholder="Açıklama..."
                      autoComplete="off"
                      InputLabelProps={{ style: { color: stockProcessFormik.errors.aciklama && stockProcessFormik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                      value={stockProcessFormik.aciklama}
                      onChange={stockProcessFormik.handleChange}
                      onBlur={stockProcessFormik.handleBlur}
                      InputProps={{
                        startAdornment: <InputAdornment position="start" ><FaStickyNote /></InputAdornment>,
                      }}

                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" type="submit" style={{ background: can('create-stok-detay') ? "#1172b6" : "#65A4D0", color: "white" }} disabled={!can('create-stok-detay')} className="d-flex align-items-center" >
              {
                isProcesssingStockProcess ?
                  (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                      İşleniyor...
                    </>
                  )
                  :
                  (
                    <>
                      <FaCloudUploadAlt /> &nbsp;
                      {"Kaydet"}
                    </>
                  )
              }
            </Button>{' '}
            <ButtonM color="danger" type="button" onClick={toggleStockProcess} className="d-flex align-items-center">
              <FaTimes />&nbsp;Vazgeç
            </ButtonM>
          </ModalFooter>
        </form>
      </Modal>

      {
        useMemo(() =>
          <>
            {can('view-stok') ?
              <ThemeProvider theme={theme}>
                <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                  <DataGrid
                    autoHeight={data.length > 0 ? true : false}
                    sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                    loading={isLoading}
                    rows={data}
                    columns={columns}
                    // pagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowCount={totalCount} //serverdan gelen data total count gelecek
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    pagination
                    paginationMode="server"
                    // sorting
                    //sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                    filterModel={filterModel}
                    filterMode="server"
                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                    // selection checkbox
                    //checkboxSelection={true}
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    // features
                    experimentalFeatures={{ newEditingApi: true }}
                    // toolbar components
                    components={{
                      Toolbar: GridToolbar,
                      NoRowsOverlay: CustomNoRowsOverlay,
                      LoadingOverlay: LoadingOverlay
                    }}
                    disableColumnFilter
                    // disableSelectionOnClick
                    // component props
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: {
                          fields: ["stok_adi", "mevcut_stok", "kritik_seviye", "birim"],
                          fileName: "Stok Kartlari",
                          hideFooter: true,
                          hideToolbar: true,
                          disableToolbarButton: false,
                        }, //sadece firsname  ve lastname alanını alır.
                        csvOptions: {
                          disableToolbarButton: false,
                          fields: ["stok_adi", "mevcut_stok", "kritik_seviye"],
                          fileName: "Stok Kartlari",
                          utf8WithBom: true,
                        }, //sadece firstname ve lastName alanını alır.
                        excelOptions: { disableToolbarButton: true },
                        // quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                  />
                </Box>
              </ThemeProvider>
              : <NotPermission />}
          </>
          , [sortModel, filterModel, pageSize, page, updateDataGrid, data]
        )
      }
    </div>
  );
};


const authPermissions = (state) => {
  return {
    auth: state.authState,
  };
};

export default connect(authPermissions)(Stocks)


