import React, { useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import { Button as ButtonM, Autocomplete } from "@mui/material";
import "assets/css/pages/expense.css";
import { trTR as turkishTr } from "@mui/material/locale";
import axios from "axios";
import "assets/css/mui-data-grid/mui-data-grid.css";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosCrudActions from "services/AxiosCrudCore";
import { InputAdornment, FormControl, TextField, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaTimes,
    FaEdit,
    FaTrash,
    FaPills,
    FaFlask,
    FaPhoneAlt,
    FaMapMarkerAlt,
    FaCheck,
    FaSearch
} from "react-icons/fa";
import CurrentAccountValidationSchema from "business-layer/CurrentAccountValidationSchema";
import CustomPhoneMask from "Utils/PhoneMask";
import NumberMask from 'Utils/NumberMask';
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";


const Expense = (props) => {
    const { auth } = props;
    const can = (permission) => {
        return (auth.user.user_permissions || []).find((p) => p === permission) ? true : false;
    }
    const MySwal = withReactContent(Swal)

    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const navigate = useNavigate();
    const columns = [
        {
            field: "id",
            headerName: "ID",
            align: "center",
            flex: 1,
            headerAlign: "center",
            minWidth: 100,
            maxWidth: 100,
            hideable: true,
            editable: false,
            filterable: false
        },
        {
            field: "cari_adi",
            headerName: "Cari Adı",
            minWidth: 180,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "hesap_tipi",
            headerName: "Hesap Tipi",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.hesap_tipi ? params?.row?.hesap_tipi?.name : ""
                )
            },
            valueGetter: (params) => `${params?.row?.hesap_tipi?.name}`,
        },

        {
            field: "vergi_dairesi",
            headerName: "Vergi Dairesi",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "vergi_numarasi",
            headerName: "Vergi Numarası",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: true, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "telefon",
            headerName: "Telefon",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: true, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "il",
            headerName: "Şehir",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: true, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.il?.il ? params?.row?.il?.il : ""
                )
            },
            valueGetter: (params) => `${params?.row?.il?.il}`,
        },
        {
            field: "ilce",
            headerName: "İlçe",
            minWidth: 100,
            flex: 1,
            editable: false,
            hideable: true, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.ilce?.ilce ? params?.row?.ilce?.ilce : ""
                )
            },
            valueGetter: (params) => `${params?.row?.ilce?.ilce}`,
        },
        {
            field: "status",
            headerName: "Durum",
            minWidth: 150,
            flex: 1,
            editable: false,
            hideable: false, // cant hide this column
            sortable: false,
            hide: false, //when data grid loaded, this column will hide
            filterable: false,
            renderCell: (params) => {
                return (
                    params?.row?.durum ? <FaCheck color="#1ca345" /> : <FaTimes color="#de3f44" />
                )
            }
        },
        {
            field: "actions",
            headerName: "İşlemler",
            minWidth: 250,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            disableExport: true,
            hideable: false,
            sortable: false,
            hide: false,
            filterable: false,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                const deleteCurrentAccount = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    MySwal.fire({
                        title: params?.row?.cari_adi + " silinsin mi?",
                        text: "silme işlemini gerçekleştirdiğinizde durumu pasif hale gelecektir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet, Sil',
                        cancelButtonText: 'Hayır,Vazgeç'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            AxiosCrudActions.DeleteAsync('/cariler', params.row.id)
                                .then(result => {
                                    if (result?.status === 200 && result?.data?.status) {
                                        setUpdateDataGrid(!updateDataGrid);
                                    }
                                });

                        }
                    })
                };

                const detailCurrentAccount = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const detailURL = `/giderler/cari-detay/${params.row.id}`;
                    navigate(detailURL, { state: { cari_adi: params?.row?.cari_adi, hesap_tipi: params?.row?.hesap_tipi?.name } });
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Cari detay" arrow>
                            <ButtonM variant="outlined"
                                disabled={!can('view-giderler')}
                                onClick={detailCurrentAccount}
                                color="info"><FaSearch /></ButtonM>
                        </Tooltip>
                        <Tooltip title="Cari düzenle" arrow>
                            <ButtonM variant="outlined" disabled={!can('edit-cariler')} onClick={(e) => editCurrentAccount(e, params?.row)} color="warning"><FaEdit /></ButtonM>
                        </Tooltip>
                        <Tooltip title="Cari sil" arrow>
                            <ButtonM variant="outlined" disabled={!can('delete-cariler')} onClick={deleteCurrentAccount} color="error"><FaTrash /></ButtonM>
                        </Tooltip>
                    </Stack>
                );
            },
        }
    ];



    const [cariId, setCariId] = useState(0);
    const [city, setCity] = useState(null);
    const [cityInputValue, setCityInputValue] = useState('');
    const [district_id, setDistrictId] = useState(null);
    const [districtInputValue, setDistrictInputValue] = useState('');
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [isProcesssing, setIsProcessing] = useState(false);
    const [modal, setModal] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [checkDoctor, setCheckDoctor] = useState(null);
    const [doctor, setDoctor] = useState(null);
    const [doctorInputValue, setDoctorInputValue] = useState('');
    const [accountType, setAccountType] = useState(1);

    const toggle = () => setModal(!modal);


    const getCities = async () => {
        try {
            let data = await axios.get('cities');
            if (data.status === 200) {
                setCities(data.data.data);
            }
            else {
                setCities([]);
            }

        } catch (error) {
            setCities([]);
        }
    }

    const getDistricts = async (city_id) => {

        if (city_id !== undefined && city_id !== null && city_id > 0) {
            try {
                let data = await axios.get(`cities/${city_id}`);
                if (data.status === 200) {
                    setDistricts(data.data.data);
                }
                else {
                    setDistricts([]);
                }

            } catch (error) {
                setDistricts([]);
            }
        }
        else {
            setDistricts([]);
        }
    }

    const getDoctors = async () => {
        try {
            let data = await axios.get('kullanicilar?doktor=1');
            if (data.status === 200) {
                let result = data.data.data;
                let allDoctors = (result).map(({ id, name }) => { return { 'id': id, 'name': name } });
                setDoctors(allDoctors);
            }
            else {
                setDoctors([]);
            }

        } catch (error) {
            setDoctors([]);
        }
    }

    const initialCariInfo = {
        cari_adi: '',
        hesap_tipi: 1,
        doktor_id: '',
        vergi_dairesi: '',
        vergi_numarasi: '',
        telefon: '',
        il_id: 0,
        ilce_id: 0,
        adres: ''
    }

    // yup schema
    let currentAccountValidationSchema = CurrentAccountValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialCariInfo,
        validationSchema: currentAccountValidationSchema,
        onSubmit: values => {
            values.il_id = city?.id;
            values.ilce_id = district_id?.id;
            values.hesap_tipi = accountType;
            values.doktor_id = accountType === 6 ? doctor?.id : ''
            setIsProcessing(true);
            if (cariId === 0) {
                AxiosCrudActions.CreateAsync('cariler', values).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            } else if (cariId > 0) {
                AxiosCrudActions.UpdateAsync(`cariler/${cariId}`, values).then(result => {
                    setIsProcessing(false);
                    if (result?.data?.status) {
                        setUpdateDataGrid(!updateDataGrid);
                        toggle();
                    }
                });
            }
        },
    });

    const addNewCurrentAccount = (event) => {
        event.preventDefault();
        formik.setValues({
            cari_adi: '',
            hesap_tipi: '',
            vergi_dairesi: '',
            vergi_numarasi: '',
            telefon: '',
            il_id: 0,
            ilce_id: 0,
            adres: ''
        }, false)
        formik.setErrors({});
        setAccountType(1);
        setCheckDoctor(null);
        setDoctor(doctors[0]);
        setCariId(0);
        toggle();
    }

    const editCurrentAccount = (event, values) => {
        event.preventDefault();
        formik.setValues({
            cari_adi: values?.cari_adi ? values?.cari_adi : '',
            hesap_tipi: values?.hesap_tipi ? values?.hesap_tipi?.id : 1,
            doktor_id: values?.doktor_id ? values?.doktor_id : "",
            vergi_dairesi: values?.vergi_dairesi ? values?.vergi_dairesi : '',
            vergi_numarasi: values?.vergi_numarasi ? values?.vergi_numarasi : '',
            telefon: values?.telefon ? values?.telefon : '',
            il_id: values?.il?.id ? values?.il?.id : 0,
            ilce_id: values?.ilce?.id ? values?.ilce?.id : 0,
            adres: values?.adres ? values?.adres : ''
        }, true)
        formik.setErrors({});
        if (values?.doktor_id !== null) {
            setDoctor(values?.doktor_id);
            setCheckDoctor(6);
        } else {
            setCheckDoctor(null);
        }
        setAccountType(values?.hesap_tipi?.id)
        setCity(values?.il)
        setDistrictId(values?.ilce)
        if (values?.il?.id && values?.il?.id > 0) {
            getDistricts(values?.il?.id);
        }
        setCariId(values?.id);
        toggle();
    }

    // state
    const [updateDataGrid, setUpdateDataGrid] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    //side Effect 
    useEffect(() => {
        getCities();
    }, []);

    // side effect
    useEffect(() => {
        axios.get("cariler", {
            params: {
                filterModel: JSON.stringify(filterModel),
                pageSize: pageSize,
                page: page,
                sortColumn: sortModel[0]?.field ? sortModel[0].field : "",
                sorting: sortModel[0]?.sort ? sortModel[0].sort : "",
            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    setData(res.data.data);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, [sortModel, filterModel, pageSize, page, updateDataGrid]);

    useEffect(() => {
        getDoctors();
    }, [checkDoctor])


    return (
        <div className="expense-container">
            {
                isProcesssing ? <div style={{ width: '100%', height: '100vh', position: 'fixed', top: '0', left: '0', bottom: '0', right: '0', overflow: "hidden", zIndex: 99999, background: 'transparent' }}></div> : null
            }
            <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                    <strong className="fs-5 fw-normal text text-secondary">Tüm Cari Bilgileri &nbsp;</strong>
                </span>
                {can('view-cariler') ?
                    <ButtonM color="success" variant="outlined" disabled={!can('create-cariler')} onClick={addNewCurrentAccount} startIcon={<FaPlus />}>
                        Yeni cari
                    </ButtonM >
                    : ''}
                <Modal isOpen={modal}
                    toggle={toggle}
                    size="lg"
                    modalTransition={{ timeout: 1 }}
                    backdropTransition={{ timeout: 1 }}
                >
                    <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Cari Bilgileri</ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-3">
                                    <div>
                                        <FormControl fullWidth className="mt-2">
                                            <TextField className='form-group'
                                                type="text"
                                                name="cari_adi"
                                                variant='outlined'
                                                label="Cari Adı"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.cari_adi && formik.touched.cari_adi ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.cari_adi}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaPills /></InputAdornment>,
                                                }}
                                                error={formik.errors.cari_adi && formik.touched.cari_adi}
                                                helperText={formik.errors.cari_adi && formik.touched.cari_adi && <span className='cstm-input-error-validation-msg'>{formik.errors.cari_adi}</span>}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className="mt-4">
                                        <FormControl fullWidth>
                                            <TextField
                                                name='hesap_tipi'
                                                select
                                                InputLabelProps={{ style: { color: '#1976d2' } }}
                                                label="Hesap Tipi"
                                                size='small'
                                                defaultValue={accountType}
                                                value={accountType}
                                                onChange={(newValue) => {
                                                    setAccountType(newValue?.target?.value);
                                                    setCheckDoctor(newValue?.target?.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                InputProps={{
                                                    // startAdornment: <InputAdornment position="start"><FaExclamation /></InputAdornment>,
                                                }}>
                                                <MenuItem value={1}>Genel Gider</MenuItem>
                                                <MenuItem value={2}>Diş Deposu</MenuItem>
                                                <MenuItem value={3}>Laboratuvar</MenuItem>
                                                <MenuItem value={4}>İmplant Firması</MenuItem>
                                                <MenuItem value={5}>Personel</MenuItem>
                                                <MenuItem value={6}>Doktor</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </div>

                                    {
                                        checkDoctor === 6 ?
                                            <div className="mt-4">
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        options={doctors}
                                                        getOptionLabel={option => option.name}
                                                        loading={true}
                                                        noOptionsText={'Seçenek Bulunamadı'}
                                                        disableClearable
                                                        value={doctor}
                                                        name="doctor"
                                                        label="Hekim"
                                                        onChange={(event, newValue) => {
                                                            setDoctor(newValue);
                                                            formik.setFieldValue('doktor_id', newValue.id, true); //update formik value and validate true
                                                        }}
                                                        inputValue={doctorInputValue}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        onInputChange={(event, newInputValue) => { setDoctorInputValue(newInputValue); }}
                                                        size="small"
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key={option.id}>
                                                                    {option.name}
                                                                </li>
                                                            );
                                                        }}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            label="Hekim"
                                                            error={formik.errors.doktor_id && formik.touched.doktor_id}
                                                            helperText={formik.errors.doktor_id && formik.touched.doktor_id && <span className='cstm-input-error-validation-msg'>{formik.errors.doktor_id}</span>}
                                                        />}

                                                    />
                                                </FormControl>
                                            </div>
                                            : ""
                                    }


                                    <div className="mt-2">
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="vergi_dairesi"
                                                variant='outlined'
                                                label="Vergi Dairesi"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.vergi_dairesi && formik.touched.vergi_dairesi ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.vergi_dairesi}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaFlask /></InputAdornment>,
                                                }}
                                                error={formik.errors.vergi_dairesi && formik.touched.vergi_dairesi}
                                                helperText={formik.errors.vergi_dairesi && formik.touched.vergi_dairesi && <span className='cstm-input-error-validation-msg'>{formik.errors.vergi_dairesi}</span>}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className="mt-2">
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="vergi_numarasi"
                                                variant='outlined'
                                                label="Vergi Numarasi"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.vergi_numarasi && formik.touched.vergi_numarasi ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.vergi_numarasi}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    inputComponent: NumberMask,
                                                    startAdornment: <InputAdornment position="start" ><FaFlask /></InputAdornment>,
                                                }}
                                                error={formik.errors.vergi_numarasi && formik.touched.vergi_numarasi}
                                                helperText={formik.errors.vergi_numarasi && formik.touched.vergi_numarasi && <span className='cstm-input-error-validation-msg'>{formik.errors.vergi_numarasi}</span>}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-3">
                                    <div>
                                        <FormControl fullWidth className="mt-2">
                                            <TextField className='form-group'
                                                type="text"
                                                name="telefon"
                                                variant='outlined'
                                                label="Telefon"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.telefon && formik.touched.telefon ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.telefon}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    inputComponent: CustomPhoneMask,
                                                    startAdornment: <InputAdornment position="start" ><FaPhoneAlt /></InputAdornment>,
                                                }}
                                                error={formik.errors.telefon && formik.touched.telefon}
                                                helperText={formik.errors.telefon && formik.touched.telefon && <span className='cstm-input-error-validation-msg'>{formik.errors.telefon}</span>}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className="mt-4">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                options={cities}
                                                loading={true}
                                                getOptionLabel={option => option.il}
                                                noOptionsText={'Seçenek Bulunamadı'}
                                                value={city}
                                                name="il_id"
                                                label="Şehir"
                                                onChange={(event, newValue) => {
                                                    event.preventDefault();
                                                    setCity(newValue);
                                                    setDistrictId(null);
                                                    setDistricts([]);
                                                    getDistricts(newValue?.id);
                                                }}
                                                inputValue={cityInputValue}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                onInputChange={(event, newInputValue) => { setCityInputValue(newInputValue); }}
                                                size="small"
                                                renderInput={(params) => <TextField {...params} label="Şehir" />}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="mt-4">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                options={districts}
                                                getOptionLabel={option => option.ilce}
                                                noOptionsText={'Lütfen Şehir Seçiniz.'}
                                                value={district_id}
                                                name="ilce_id"
                                                label="İlçe"
                                                onChange={(event, newValue) => { setDistrictId(newValue); }}
                                                inputValue={districtInputValue}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                onInputChange={(event, newInputValue) => { setDistrictInputValue(newInputValue); }}
                                                size="small"
                                                renderInput={(params) => <TextField {...params} label="İlçe" />}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className="mt-2">
                                        <FormControl fullWidth className="mt-3">
                                            <TextField className='form-group'
                                                type="text"
                                                name="adres"
                                                variant='outlined'
                                                label="Adres"
                                                autoComplete="off"
                                                InputLabelProps={{ style: { color: formik.errors.adres && formik.touched.adres ? '#e53e3e' : '#1976d2' } }}
                                                onChange={formik.handleChange}
                                                value={formik.values.adres}
                                                onBlur={formik.handleBlur}
                                                size='small'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" ><FaMapMarkerAlt /></InputAdornment>,
                                                }}
                                                error={formik.errors.adres && formik.touched.adres}
                                                helperText={formik.errors.adres && formik.touched.adres && <span className='cstm-input-error-validation-msg'>{formik.errors.adres}</span>}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" type="submit" style={{ background: "#1172b6" }} disabled={cariId > 0 ? !can('edit-cariler') : !can('create-cariler')} className="d-flex align-items-center" >
                                {
                                    isProcesssing ?
                                        (
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                İşleniyor...
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <FaCloudUploadAlt /> &nbsp;
                                                {cariId > 0 ? "Güncelle" : "Kaydet"}
                                            </>
                                        )
                                }
                            </Button>{' '}
                            <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                <FaTimes />&nbsp;Vazgeç
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
            {
                useMemo(() =>
                    <>
                        {can('view-cariler') ?
                            <ThemeProvider theme={theme}>
                                <Box sx={{ width: "100%", height: `${data.length > 0 ? "auto" : "400px"}` }}>
                                    <DataGrid
                                        autoHeight={data.length > 0 ? true : false}
                                        sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2, }}
                                        loading={isLoading}
                                        rows={data}
                                        columns={columns}
                                        // pagination
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        rowCount={totalCount} //serverdan gelen data total count gelecek
                                        page={page}
                                        onPageChange={(newPage) => setPage(newPage)}
                                        pagination
                                        paginationMode="server"
                                        // sorting
                                        sortModel={sortModel}
                                        sortingMode="server"
                                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                        // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                        filterModel={filterModel}
                                        filterMode="server"
                                        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                        // selection checkbox
                                        //checkboxSelection={true}
                                        selectionModel={selectionModel}
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel(newSelectionModel);
                                        }}
                                        // features
                                        experimentalFeatures={{ newEditingApi: true }}
                                        // toolbar components
                                        components={{
                                            Toolbar: GridToolbar,
                                            NoRowsOverlay: CustomNoRowsOverlay,
                                            LoadingOverlay: LoadingOverlay
                                        }}
                                        disableColumnFilter
                                        // component props
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                printOptions: {
                                                    fields: ["id", "ilac_adi", "doz", "kullanim"],
                                                    fileName: "Cariler",
                                                    hideFooter: true,
                                                    hideToolbar: true,
                                                    disableToolbarButton: true,
                                                }, //sadece firsname  ve lastname alanını alır.
                                                csvOptions: {
                                                    disableToolbarButton: false,
                                                    fields: ["cari_adi", "hesap_tipi", "telefon", "il", "ilce", "vergi_dairesi", "vergi_numarasi"],
                                                    fileName: 'Cariler',
                                                    utf8WithBom: true,
                                                }, //sadece firstname ve lastName alanını alır.
                                                excelOptions: { disableToolbarButton: true },
                                                // quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                    />
                                </Box>
                            </ThemeProvider>
                            : <NotPermission />}
                    </>
                    , [sortModel, filterModel, pageSize, page, updateDataGrid, data]
                )
            }
        </div>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(Expense);
