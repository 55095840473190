
const ConvertFloatToStringTRFormat = (number = null) => {
    if(number !== undefined && number !== null){
        try {
            return Intl.NumberFormat('tr-TR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(Number.parseFloat(number).toFixed(2));
        } catch (error) {
            return '0.00';
        }
    }else{
        return '0.00';
    }
}

export default ConvertFloatToStringTRFormat;