import { Tooltip } from "@mui/material";
import GetTrDate from "extensions/GetTRDate";
import GetTrFullDateAndTimeWithFormat from "extensions/GetTRDateWithFormat";
import { GetTriggerActionName } from "extensions/GetTriggerActionName";

export const HastaHesapLogTableColumns = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        flex: 1,
        headerAlign: "center",
        minWidth:100,
        maxWidth:100,
        hideable: true,
        editable: false,
        filterable: false
    },
    {
        field: "hasta",
        headerName: "Hasta",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "doktor",
        headerName: "Doktor",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.doktor?.name ? params?.row?.doktor?.name : ""
            );
        },
        valueGetter: (params) => `${params?.row?.doktor?.name ? params?.row?.doktor?.name : ""}`
    },

    {
        field: "odeme_sekli",
        headerName: "Ödeme Şekli",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.odeme_sekli?.name ? params?.row?.odeme_sekli?.name : '' 
            )
        },
        valueGetter: (params) => `${params?.row?.odeme_sekli?.name ? params?.row?.odeme_sekli?.name : ''}`,
    },
    {
        field: "tutar",
        headerName: "Tutar",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "tarih",
        headerName: "Tarih",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return (
                GetTrDate(params?.row?.tarih)
            )
        },
        valueGetter: (params) => `${GetTrDate(params?.row?.tarih)}`
    },
    {
        field: "aciklama",
        headerName: "Açıklama",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: true,
        renderCell: (params) => {
            return(
                <Tooltip title={params.row.aciklama} arrow>
                    <span style={{whiteSpace:'noWrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{params.row.aciklama}</span>
                </Tooltip>
            )
        }        
    },
    {
        field: "action",
        headerName: "İşlem",
        minWidth: 120,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTriggerActionName(params?.row?.action)
            )
        },
        valueGetter: (params) => GetTriggerActionName(params?.row?.action)
    },
    {
        field: "update_text",
        headerName: "Güncelleme İşlemi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
    },
    {
        field: "user",
        headerName: "Kullanıcı",
        minWidth: 200,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                params?.row?.user?.name
            )
        },
        valueGetter: (params) => `${params?.row?.user?.name}`,
    },
    {
        field: "islem_tarihi",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        editable: false,
        hideable: false, // cant hide this column
        sortable: false,
        hide: false, //when data grid loaded, this column will hide
        filterable: false,
        renderCell: (params) => {
            return (
                GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)
            )
        },
        valueGetter: (params) => `${GetTrFullDateAndTimeWithFormat(params?.row?.islem_tarihi)}`
    },
];