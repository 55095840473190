import axios from "axios";
import { AuthActionType } from "redux/actions/AuthAction";
import jwt_decode from "jwt-decode";

const authState = {
    isLoggedIn: false,
    user: {
        expires_in: "",
        access_token: ""
    },
}

const getAuthState = () => {
    const auth = localStorage.getItem("auth");
    try {
        if (auth !== undefined && auth !== null) {
            const authobj = JSON.parse(auth);
            if (authobj !== undefined && authobj !== null && authobj.user !== undefined && authobj.user !== null && authobj.isLoggedIn) {
                const { expires_in, access_token } = authobj.user;
                if (expires_in !== undefined && expires_in !== null && access_token !== undefined && access_token !== null) {
                    if (new Date(expires_in) > new Date()) {
                        if (jwt_decode(access_token)) {
                            axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
                            return authobj;
                        }
                    }
                }
            }
        }
        return authState;
    } catch (error) {
        return authState;
    }
}

const newAuth = getAuthState();

const authreducer = (state = newAuth, action) => {
    switch (action.type) {
        case AuthActionType.LOGIN_SUCCESS:
            const newAuthState = {
                isLoggedIn: true,
                user: action.payload
            }
            axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload.access_token}`;
            localStorage.setItem("auth", JSON.stringify(newAuthState));
            return newAuthState
        
        case AuthActionType.LOGIN_FAIL:
            localStorage.removeItem("auth");
            return authState;
    
        case AuthActionType.LOGOUT_SUCCESS:
            localStorage.removeItem("auth");
            return authState;

        case AuthActionType.LOGOUT_FAIL:
            localStorage.removeItem("auth");
            return authState;

        case AuthActionType.REMOVE_LOGIN_INFO:
            localStorage.removeItem("auth");
            return authState;

        default:
            return state;
    }
}


export default authreducer;