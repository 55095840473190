import * as yup from 'yup';  

const LoginValidationSchema = () => {
    return yup.object().shape({
                email: yup.string()
                          .email("Lütfen geçerli bir email giriniz.")
                          .max(50,"email max 50 karakter olmalıdır.")
                          .required("Lütfen geçerli bir email giriniz."),

                password: yup.string()
                          .min(8, "Parola min 8 karakter olmalıdır.")
                          .max(20,"Parola max 20 karakter olmalıdır.")
                          .required('Lütfen parola giriniz'),
           });
};


export default LoginValidationSchema;