
import * as yup from 'yup';  

const CreatePatientValidationSchema = () => {
    return yup.object().shape({

        miktar: yup.string()
                .required("Lütfen geçerli bir stok miktarı bilgisi giriniz."),
   });
}   

export default CreatePatientValidationSchema;