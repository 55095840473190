import React, { useEffect, useState } from "react";
import {FaCalendar, FaCamera,FaFileInvoice, FaFileInvoiceDollar, FaInfoCircle, FaReceipt, FaTooth} from 'react-icons/fa';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import 'assets/css/pages/patient-detail.css';
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const DetailPatient = (props) => {
   
    let params = useParams();
    const location  = useLocation();
    const navigate = useNavigate()
    // const { state } = location;
    const [patientInfo, setPatientInfo] = useState(location.state?.patientData);

    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const tabLinkElements = [
        {to: `/hastalar/detay/${params?.patientId}/tedavi`, icon: <FaTooth />, label: "TEDAVİ", value: "1"},
        {to: `/hastalar/detay/${params?.patientId}/hesap`, icon: <FaFileInvoiceDollar />, label: "HESAP", value: "2"},
        {to: `/hastalar/detay/${params?.patientId}/fatura`, icon: <FaFileInvoice />, label: "FATURA", value: "3"},
        {to: `/hastalar/detay/${params?.patientId}/recete`, icon: <FaReceipt />, label: "REÇETE", value: "4"},
        {to: `/hastalar/detay/${params?.patientId}/goruntu`, icon: <FaCamera />, label: "GÖRÜNTÜ", value: "5"},
        {to: `/hastalar/detay/${params?.patientId}/anamnez`, icon: <FaInfoCircle />, label: "ANAMNEZ", value: "6"},
        {to: `/hastalar/detay/${params?.patientId}/randevu`, icon: <FaCalendar />, label: "RANDEVU", value: "7"},
    ]

    useEffect(() => {
            if(params?.patientId && params.patientId !== undefined && params.patientId !== null && params.patientId > 0){
                const { pathname } = location;
                if(pathname === `/hastalar/detay/${params.patientId}`){
                   navigate(`/hastalar/detay/${params.patientId}/tedavi`);
                   setValue("1");
                }
                else{
                    for (const [index, element] of tabLinkElements.entries()) {
                        if(element.to === pathname){
                            navigate(pathname);
                            setValue(element.value);
                            break;
                        }
                    }
                }
            }else{
                navigate('/hastalar');
            }
    },[location.pathname])

    return (
        <div className="patient-detail-container">
            <div style={{paddingLeft:'30px',borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                <span className="d-flex align-items-center" style={{fontSize: '16px',fontWeight: '400', color: '#1172b6', opacity: '0.8'}}>
                    <strong>
                    Hasta Adı&nbsp; <span> - {patientInfo?.ad_soyad}  </span>
                    </strong>
                </span>
            </div>  
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList 
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            >
                            {
                                tabLinkElements.map((element, index) => (
                                    <Tab key={element.value} component={Link} to={element.to} sx={{minWidth:"fit-content", flex:1}} icon={element.icon} iconPosition="start" label={element.label} value={element.value}/>
                                ))
                            }
                        </TabList>
                    </Box>
                    <Outlet />
                </TabContext>
            </Box>
        </div>
    )
}

export default DetailPatient;