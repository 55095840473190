import React, { useEffect, useState } from 'react'
import 'assets/css/pages/reports/report-treatment-analysis.css';
import { FormControl, TextField, Autocomplete, Button as ButtonM, MenuItem } from '@mui/material';
import Box from "@mui/material/Box";
import {
    FaTimes,
    FaTh,
    FaClipboardList
} from "react-icons/fa";
import { DataGrid, GridToolbar, trTR } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
import { trTR as turkishTr } from "@mui/material/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {tr} from 'date-fns/locale'
import axios from "axios";
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import GetTrDate from "extensions/GetTRDate";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

function TreatmentAnalysis(props) {
    const { auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }

    const navigate = useNavigate();
    const theme = createTheme(
        trTR, // x-data-grid translations
        turkishTr // core translations
    );

    const [doctors, setDoctors] = useState([]);
    const [tedaviler, setTedaviler] = useState([]);

    const [selectDoctor, setSelectDoctor] = useState({ id: 0, name: 'Hepsi', value: null });
    const [selectTedaviler, setSelectTedaviler] = useState(null);
    const [selectTreatmenType, setSelectTreatmenType] = useState({ id: 0, tedavi_turu: 'Hepsi', value: null });
    const [billType, setBillType] = useState({ id: 0, fatura_turu: 'Her ikisi', value: null });

    const MySwal = withReactContent(Swal);
    const [startDate, setStartDate] = useState(Date.now());
    const [endDate, setEndDate] = useState(Date.now());



    const columns = [
        {
            field: "id",
            headerName: "Hasta No",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            hideable: true,
            editable: false,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    params?.row?.hasta?.id ? params?.row?.hasta?.id : ""
                )
            }
        },
        {
            field: "hasta",
            headerName: "Ad Soyad",
            minWidth: 100,
            maxWidth: 300,
            flex: 1,
            hideable: true,
            editable: false,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    params?.row?.hasta?.hasta ? params?.row?.hasta?.hasta : ""
                )
            }
        },
        {
            field: "doktor",
            headerName: "Doktor",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.doktor?.name ? params?.row?.doktor?.name : ""
                )
            }
        },
        {
            field: "tarih",
            headerName: "Tarih",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.tarih ? GetTrDate(params?.row?.tarih) : ""
                )
            }
        },
        {
            field: "disler",
            headerName: "Diş No",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "tedavi",
            headerName: "Tedavi",
            minWidth: 300,
            maxWidth: 500,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.tedavi?.tedaviTanimi ? params?.row?.tedavi?.tedaviTanimi : ""
                )
            }
        },
        {
            field: "fiyat",
            headerName: "Fiyat",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
        },
        {
            field: "tedavi_tipi",
            headerName: "Tedavi Türü",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            editable: false,
            hideable: false, // cant hide this column
            sortable: true,
            hide: false, //when data grid loaded, this column will hide
            filterable: true,
            renderCell: (params) => {
                return (
                    params?.row?.tedavi_tipi?.tedavi_tipi_adi ? params?.row?.tedavi_tipi?.tedavi_tipi_adi : ""
                )
            }
        }
    ];

    // data-grid state
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [selectionModel, setSelectionModel] = useState([]); //selected row
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "desc",
        },
    ]);



    const getDoctors = () => {
        try {
            axios.get('/kullanicilar?doktor=1')
                .then(res => {
                    setDoctors(res.data.data);
                    setDoctors(state => [{ id: 0, name: "Hepsi", value: null }, ...state]);
                })
        } catch (error) {
            setDoctors([])
        }
    }
    const getTedaviler = async () => {
        try {
            let tedaviler = await axios.get('tedaviler');
            if (tedaviler.status === 200) {
                setTedaviler(tedaviler.data.data);
            } else {
                setTedaviler([]);
            }
        } catch (error) {
            setTedaviler([])
        }
    }

    const getDate = (date) => {
        if (date !== undefined && date !== null) {
            try {
                let getJustDate = dayjs(date).get('date') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('year');
                return getJustDate;
            } catch (error) {
                return null;
            }
        }
    }
    const getReverseDate = (date) => {
        if (date !== undefined && date !== null) {
            try {
                let getJustDate = dayjs(date).get('year') + "-" + (dayjs(date).get('month') + 1) + "-" + dayjs(date).get('date');
                return getJustDate;
            } catch (error) {
                return null;
            }
        }
    }



    const FilterReports = () => {
        setIsLoading(true);

        axios.get("raporlama/tedavi-analizi", {
            params: {
                baslangic: getReverseDate(startDate) ? getReverseDate(startDate) : null,
                bitis: getReverseDate(endDate) ? getReverseDate(endDate) : null,
                fatura_turu: billType?.value ? billType.value : null,
                tedaviler: selectTedaviler?.map(option => option.id),
                tedavi_turu: selectTreatmenType?.value ? selectTreatmenType.value : null,
                doktor: selectDoctor?.id ? selectDoctor.id : null,

            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    let datalist = res.data.data;
                    if (datalist.length > 0) {
                        let total = { id: 0, hasta: null, doktor: null, tarih: null, disler: null, tedavi: null, fiyat: 'Toplam:\xa0\xa0\xa0' + res.data.toplam, tedavi_tipi: null };
                        datalist.push(total);
                    }
                    setData(datalist);
                }
            } catch (error) {
                setData([]);
            }
        });
    }


    //side effect
    useEffect(() => {
        getDoctors();
        getTedaviler();


        setIsLoading(true);
        axios.get("raporlama/tedavi-analizi", {
            params: {
                baslangic: getReverseDate(startDate) ? getReverseDate(startDate) : null,
                bitis: getReverseDate(endDate) ? getReverseDate(endDate) : null,
                fatura_turu: billType?.value ? billType.value : null,
                tedaviler: selectTedaviler?.map(option => option.id),
                tedavi_turu: selectTreatmenType?.value ? selectTreatmenType.value : null,
                doktor: selectDoctor?.id ? selectDoctor.id : null,

            }
        }).then((res) => {
            setIsLoading(false);
            try {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setTotalCount(res.data.totalCount);
                    let datalist = res.data.data;
                    if (datalist.length > 0) {
                        let total = { id: 0, hasta: null, doktor: null, tarih: null, disler: null, tedavi: null, fiyat: 'Toplam:\xa0\xa0\xa0' + res.data.toplam, tedavi_tipi: null };
                        datalist.push(total);
                    }
                    setData(datalist);
                }
            } catch (error) {
                setData([]);
            }
        });
    }, []);



    return (
        <>
        {can('view-raporlama-tedavi-analizi') ?   
            <div className="report-treatment-analysis-container">
                <div className='d-flex justify-content-between'>
                    <span>
                        <h4 className='report-treatment-analysis-title'>Raporlama</h4>
                        <span className="d-flex align-items-center">
                            <div><strong className="fs-5 fw-normal text text-secondary">Tedavi Analizi&nbsp;</strong></div>
                        </span>
                    </span>
                    <div>
                        <ButtonM className='report-treatment-analysis-button-bgColor'
                            variant="contained"
                            type="submit" startIcon={<FaTh />}
                            onClick={() => { navigate('/raporlama') }}
                        >
                            Raporlama listesi
                        </ButtonM>
                    </div>
                </div>
                <div className='col-xl-9 col-lg-11 col-md-11 col-sm-11 col-xs-11 offset-xl-2 offset-md-1 offset-sm-1'>
                    <div className="row">
                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                            <FormControl fullWidth className="mt-2">
                                <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                    <DesktopDatePicker
                                        variant='outlined'
                                        label="Başlangıç Tarihi"
                                        inputFormat="dd-MM-yyyy"
                                        minDate={dayjs('01-01-1930')}
                                        value={startDate}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            size="small"
                                            name="baslangic"
                                            variant='outlined'
                                        />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                            <FormControl fullWidth className="mt-2">
                                <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns} > 
                                    <DesktopDatePicker
                                        variant='outlined'
                                        label="Bitiş Tarihi"
                                        inputFormat="dd-MM-yyyy"
                                        minDate={dayjs('01-01-1930')}
                                        value={endDate}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            size="small"
                                            name="bitis"
                                            variant='outlined'
                                        />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>

                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                            <FormControl fullWidth className="mt-2">
                                <Autocomplete
                                    multiple
                                    id="treatmentAutocomplete"
                                    options={tedaviler}
                                    getOptionLabel={(option) => option?.tedaviTanimi}
                                    filterSelectedOptions
                                    name="tedaviTanimi"
                                    loading={true}
                                    size="small"
                                    onChange={(e, selectedValues) => {
                                        e.preventDefault();
                                        setSelectTedaviler(selectedValues);
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option?.id}>
                                            {option?.tedaviTanimi}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tedaviler"
                                            placeholder="Tedavi Seçiniz"
                                        />
                                    )}
                                />
                            </FormControl>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                            <FormControl fullWidth className="mt-2">
                                <Autocomplete
                                    options={doctors}
                                    getOptionLabel={(option) => option.name}
                                    loading={true}
                                    disableClearable
                                    value={selectDoctor}
                                    noOptionsText={"Seçenek Bulunamadı"}
                                    name="doktor"
                                    onChange={(event, newValue) => {
                                        setSelectDoctor(newValue);
                                    }}
                                    size="small"
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Doktor"
                                    />
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                            <FormControl fullWidth className="mt-2">
                                <Autocomplete
                                    options={[
                                        { id: 0, tedavi_turu: 'Hepsi', value: null },
                                        { id: 1, tedavi_turu: 'Tedavi', value: 1 },
                                        { id: 2, tedavi_turu: 'Planlama 1', value: 2 },
                                        { id: 3, tedavi_turu: 'Planlama 2', value: 3 },
                                        { id: 4, tedavi_turu: 'Planlama 3', value: 4 }
                                    ]}
                                    getOptionLabel={(option) => option.tedavi_turu}
                                    loading={true}
                                    disableClearable
                                    value={selectTreatmenType}
                                    noOptionsText={"Seçenek Bulunamadı"}
                                    name="tedavi_turu"
                                    onChange={(event, newValue) => {
                                        setSelectTreatmenType(newValue);
                                    }}
                                    size="small"
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {option.tedavi_turu}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Tedavi Türü"
                                    />
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-11 col-sm-11 col-xs-11 m-1' >
                            <FormControl fullWidth className="mt-2">
                                <Autocomplete
                                    options={[
                                        { id: 0, fatura_turu: 'Her ikisi', value: null },
                                        { id: 1, fatura_turu: 'Faturalı', value: '1' },
                                        { id: 2, fatura_turu: 'Faturasız', value: '0' }
                                    ]}
                                    getOptionLabel={(option) => option.fatura_turu}
                                    loading={true}
                                    disableClearable
                                    value={billType}
                                    noOptionsText={"Seçenek Bulunamadı"}
                                    name="fatura_turu"
                                    onChange={(event, newValue) => {
                                        setBillType(newValue);
                                    }}
                                    size="small"
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {option.fatura_turu}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Fatura Türü"
                                    />
                                    }
                                />
                            </FormControl>
                        </div>
                        <ButtonM className='col-xl-10 col-lg-10 col-md-11 col-sm-11 col-xs-9 m-2 report-treatment-analysis-button-bgColor'
                            variant="contained"
                            type="submit" startIcon={<FaClipboardList />}
                            onClick={FilterReports}
                        >
                            Raporu Göster
                        </ButtonM>
                    </div>
                </div>
                <div className='row mt-5'>
                    {
                        <ThemeProvider theme={theme}>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: `${data.length > 0 ? "auto" : "400px"}`,
                                }}
                            >
                                <DataGrid
                                    autoHeight={data.length > 0 ? true : false}
                                    sx={{ mt: 2, p: 2, boxShadow: 2, borderRadius: 2 }}
                                    loading={isLoading}
                                    rows={data}
                                    columns={columns}
                                    // pagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowCount={totalCount} //serverdan gelen data total count gelecek
                                    page={page}
                                    onPageChange={(newPage) => setPage(newPage)}
                                    pagination
                                    // paginationMode="server"
                                    // sorting
                                    sortModel={sortModel}
                                    sortingMode="server"
                                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                    // columnVisibilityModel={{age: false}}  //ilk açılışta visible false
                                    filterModel={filterModel}
                                    // filterMode="server"
                                    onFilterModelChange={(newFilterModel) =>
                                        setFilterModel(newFilterModel)
                                    }
                                    // selection checkbox
                                    //checkboxSelection={true}
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                    }}
                                    // features
                                    experimentalFeatures={{ newEditingApi: true }}
                                    // toolbar components
                                    components={{
                                        Toolbar: GridToolbar,
                                        NoRowsOverlay: CustomNoRowsOverlay,
                                        LoadingOverlay: LoadingOverlay,
                                    }}
                                    disableColumnFilter
                                    disableSelectionOnClick
                                    
                                    // component props
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            printOptions: {
                                                fields: ["id", "islem_adi"],
                                                fileName: "Laboratuvar Islemler",
                                                hideFooter: true,
                                                hideToolbar: true,
                                                disableToolbarButton: false,
                                            }, //sadece firsname  ve lastname alanını alır.
                                            csvOptions: {
                                                disableToolbarButton: false,
                                                fields: ["id", "islem_adi"],
                                                fileName: "Laboratuvar Islemler",
                                                utf8WithBom: true,
                                            }, //sadece firstname ve lastName alanını alır.
                                            excelOptions: { disableToolbarButton: true },
                                            // quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                />
                            </Box>
                        </ThemeProvider>
                    }
                </div>
            </div>
        :<NotPermission/>}
        </>
    )
}

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
  };
  
export default connect(authPermissions)(TreatmentAnalysis);