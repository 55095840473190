import * as yup from 'yup';  
import parse from "date-fns/parse";
const CreatePatientInvoiceValidationSchema = () => {
    return yup.object().shape({

        ad_soyad: yup.string()
                  .min(6,"Ad Soyad en az 6 karakter olmalıdır.")
                  .max(50,"Ad Soyad en fazla 50 karakter olmalıdır.")
                  .required("Lütfen geçerli bir Ad Soyad giriniz."),

        tc_no: yup.string()
                .min(11,"Lütfen 11 haneli TC kimlik numarası giriniz.")
                .max(11,"Lütfen 11 haneli TC kimlik numarası giriniz.")
                .required("Lütfen 11 haneli TC kimlik numarası giriniz."),

        adres: yup.string()
                .min(3,"Adres en az 6 karakter olmalıdır.")
                .max(200,"Adres en fazla 200 karakter olmalıdır.")
                .required("Lütfen geçerli bir Adres giriniz."),
   });
}   

export default CreatePatientInvoiceValidationSchema;

