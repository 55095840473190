
import React, { useEffect, useRef, useState } from "react";
import "assets/css/pages/patient-detail.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TabPanel } from "@mui/lab";
import { Button as ButtonM, Table, TableBody, TableCell, TableContainer, TableHead, TableRow , Paper, Tooltip, FormControl, TextField} from "@mui/material";
import { Card } from "reactstrap";
import { FaPrint, FaTrash} from "react-icons/fa";
import GetTrDate from "extensions/GetTRDate";
import preLoadingImg from 'assets/images/loading.gif';
import TopChinImg from 'assets/images/TopBottomChin/oral-ust-cene.svg';
import BottomChinImg from 'assets/images/TopBottomChin/oral-alt-cene.svg';
import { StringParseWithSignToArray } from "extensions/StringParseWithSignToArray";

const PatientInvoiceDetail = () => {

    const params = useParams();
    const [invoiceDetail, setInvoiceDetail] = useState(null);
    const [loading,setLoading] = useState(true);

    const eInvoicePrintiframeContentRef = useRef();

    const getAllTreatmentOfInvoice = async () => {
        await axios.get(`/faturalar/${params?.id}`)
                    .then(res => {
                        if(res?.status === 200 ){
                            setInvoiceDetail(res?.data?.data);
                        }
                    }).catch(e => {
                        setInvoiceDetail([]);
                    })
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
    }   

    const eInvoicePrint = (e) => {
        e.preventDefault();
        //var pri = document.getElementById("p_e_invoice_print_iframe_content").contentWindow;
        var pri = eInvoicePrintiframeContentRef.current?.contentWindow; //usstteki ile aynı sadece useRef kullandık
        // contentwindow iframe elementi uzerinde mevcut bir ozellik 
        pri.document.open(); // documanı ac
        pri.document.write(invoiceDetail?.content); //icerigi yaz
        pri.document.close(); // documanı kapat
        pri.focus(); // focus
        pri.print(); //print
    }

    useEffect(() => {
        if(params?.id !== null && params?.id > 0){
            getAllTreatmentOfInvoice();
        }
    }, []);

    return (
        <TabPanel value="3">
            <div className="patient-detail-container ">
                <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-3" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                        {
                            loading && 
                            <div id="invoice-pre-loading-content">
                                <div className="loading">
                                    <img src={preLoadingImg} alt="loading" width={110} height={110} 
                                    // style={{border: '4px solid #ffffff', borderRadius: '50%', padding: '15px'}} 
                                    />
                                    <div className="d-flex justify-content-center" style={{color: '#1172b6'}}>
                                        <span>Yükleniyor...</span>
                                    </div>
                                </div>
                            </div>
                        }
                        <span className="d-flex align-items-center" style={{fontSize: '16px',fontWeight: 'normal', color: '#1172b6', opacity: '0.8'}}>
                            <strong>
                                <span> {invoiceDetail?.faturaNo} - </span> Numaralı Fatura Detayları  &nbsp;
                            </strong>
                        </span>
                        <span>
                             <iframe ref={eInvoicePrintiframeContentRef} id="p_e_invoice_print_iframe_content" style={{height: '0', width: '0', position: 'absolute'}}></iframe>
                             <ButtonM color="warning" variant="outlined" onClick={eInvoicePrint} type="button" startIcon={<FaPrint />} >
                                    E-Fatura Yazdır
                            </ButtonM>
                        </span>
                    </div>
                    <div className="mb-2">

                    <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        name="ad_soyad"
                                        label="Ad Soyad"
                                        variant='outlined'
                                        autoComplete="off"
                                        InputLabelProps={{shrink:true, style: {color:  '#1976d2'}}}
                                        value={invoiceDetail?.ad_soyad || ''}
                                        id="outlined-start-adornment"
                                        size='small'
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        name="tc_no"
                                        label="TC Kimlik / VKN:"
                                        variant='outlined'
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true, style: {color:'#1976d2'}}}
                                        value={invoiceDetail?.tc_no || ''}
                                        size='small'
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                       InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        name="tel_no"
                                        label="Tel No:"
                                        variant='outlined'
                                        autoComplete="off"
                                        InputLabelProps={{shrink:true,style: {color:'#1976d2'}}}
                                        value={invoiceDetail?.tel_no || ''}
                                        size='small'
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        name="email"
                                        label="Email:"
                                        variant='outlined'
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                        value={invoiceDetail?.email || ''}
                                        size='small'
                                       
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        name="sehir"
                                        label="Şehir:"
                                        variant='outlined'
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                        value={invoiceDetail?.il?.il || ''}
                                        size='small'
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                       InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        name="ilce"
                                        label="İlçe"
                                        variant='outlined'
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                        value={invoiceDetail?.ilce?.ilce || ''}
                                        size='small'
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        name="adres"
                                        label="Adres:"
                                        variant='outlined'
                                        autoComplete="off"
                                        InputLabelProps={{shrink:true, style: {color:'#1976d2'}}}
                                        size='small'
                                        value={invoiceDetail?.adres || ''}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        label="Fatura No"
                                        name="fatura_numarası"
                                        variant='outlined'
                                        autoComplete="off"
                                        value={invoiceDetail?.faturaNo || ''}
                                        size='small'
                                        InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        label="Vergi Numarası"
                                        name="vergi_numarası"
                                        variant='outlined'
                                        autoComplete="off"
                                        value={invoiceDetail?.vergiNumarasi || ''}
                                        size='small'
                                        InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField className='form-group'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        label="Vergi Dairesi"
                                        name="vergi_dairesi"
                                        variant='outlined'
                                        autoComplete="off"
                                        value={invoiceDetail?.vergiDairesi || ''}
                                        size='small'
                                        InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
                                <FormControl fullWidth>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        label="Açıklama"
                                        name="aciklama"
                                        variant='outlined'
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true, style: {color: '#1976d2'}}}
                                        value={invoiceDetail?.aciklama || ''}
                                        size='small'
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <h4 className="fs-4 fw-normal p-2 mb-2 mt-2" style={{color: '#1172b6'}}>Fatura Kalemleri:</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 1000}} size="small" className="cstm-border-top">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" width='15%'>Tarih</TableCell>
                                    <TableCell align="center" width='15%'>Diş</TableCell>
                                    <TableCell align="center" width='20%'>Tedavi</TableCell>
                                    <TableCell align="center" width='15%'>Doktor</TableCell>
                                    <TableCell align="center" width='15%'>Tutar</TableCell>
                                    <TableCell align="center" width='5%'>KDV</TableCell>
                                    <TableCell align="center" width='15%'>Toplam</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceDetail?.faturaKalemleri.map((row) => (
                                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center" width='15%'>{GetTrDate(row?.tarih)}</TableCell>
                                        <TableCell align="center" width='15%'>
                                            {
                                                StringParseWithSignToArray(row?.disler, '-').length > 1 
                                                ?
                                                <Tooltip title={row?.disler} arrow>
                                                    <img src={row?.konum === 1 ? TopChinImg : BottomChinImg}  alt="teeth" />
                                                </Tooltip>
                                                :
                                                row?.disler
                                            }
                                        </TableCell>
                                        <TableCell align="center" width='20%'>{row?.tedavi}</TableCell>
                                        <TableCell align="center" width='15%'>{row?.doktor?.name}</TableCell>
                                        <TableCell align="center" width='15%'>{row?.tutar}</TableCell>
                                        <TableCell align="center" width='5%'>{row?.kdv}</TableCell>
                                        <TableCell align="center" width='15%'>{row?.toplam}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="mt-4 d-flex justify-content-end">
                       <div style={{width: '300px',paddingRight: '40px', fontSize: '18px', opacity: '0.8'}}>
                            <p>
                                <strong style={{width: '130px',display: 'inline-block', textAlign:'end', paddingRight: '10px',marginTop: '0'}}>Ara Toplam:</strong> <span>{invoiceDetail?.toplam} TRY</span> <br/>   
                            </p>   
                            <p>
                                <strong style={{width: '130px',display: 'inline-block', textAlign:'end', paddingRight: '10px',marginTop: '0'}}>KDV Toplam:</strong> <span>{invoiceDetail?.kdv} TRY</span> <br/>   
                            </p>
                            <p>
                                <strong style={{width: '130px',display: 'inline-block', textAlign:'end', paddingRight: '10px',marginTop: '0'}}>Genel Toplam:</strong> <span>{invoiceDetail?.genelToplam} TRY</span>     
                            </p> 
                       </div>
                    </div>
                </Card>
            </div>
        </TabPanel>
    );
}

export default PatientInvoiceDetail;