import { Navigate, Outlet } from 'react-router-dom';
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const PermissionRoute = (props) => {
        const { auth } = props;
        return (auth.user.user_permissions || []).find((p)=> p === props.permission) ? <Outlet /> :<NotPermission/> 
        //<Navigate to="/dashboard" />
    }

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};
export default  connect(authPermissions,null) (PermissionRoute);