import * as yup from 'yup';  
import parse from "date-fns/parse";
const CreatePatientPrescriptionValidationSchema = () => {
    return yup.object().shape({

        
        protokol_no: yup.number()
                .required("Lütfen geçerli bir Protokol Numarası giriniz."),
             
        // recete_tarihi: yup.date()
        //                     .transform(function (value, originalValue) {
        //                     if (this.isType(value)) {
        //                         return value;
        //                     }
        //                     const result = parse(originalValue, "dd.MM.yyyy", new Date());
        //                     return result;
        //                     })
        //                     .typeError("Lütfen geçerli bir Reçete Tarihi giriniz.")
        //                     .required(),
   });
}   

export default CreatePatientPrescriptionValidationSchema;

