import React, { useEffect, useMemo, useState } from "react";
import "assets/css/pages/patient-detail.css";
import { Autocomplete, Button as ButtonM, Stack, Tooltip} from "@mui/material";
import { Card } from "reactstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { InputAdornment, FormControl, TextField, MenuItem } from '@mui/material';
import { TabPanel } from "@mui/lab";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    FaCloudUploadAlt,
    FaPlus,
    FaTimes,
    FaLiraSign,
    FaInfoCircle,
    FaEdit,
    FaTrash,
} from "react-icons/fa";
import { useFormik } from "formik";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {tr} from 'date-fns/locale'
import CreatePatientAccountPaymentValidationSchema from "business-layer/CreatePatientAccountPaymentValidationSchema";
import CurrencyMask from "Utils/CurrencyMask";
import GetDateWithFormat from "extensions/GetDateWithFormat";
import AxiosCrudActions from "services/AxiosCrudCore";
import { useParams } from "react-router-dom";
import GetTrDate from "extensions/GetTRDate";
import CustomNoRowsOverlay from "components/mui-data-grid/NoRowsOverlay";
import LoadingOverlay from "components/mui-data-grid/LoadingOverlay";
// date end
import TopChinImg from 'assets/images/TopBottomChin/oral-ust-cene.svg';
import BottomChinImg from 'assets/images/TopBottomChin/oral-alt-cene.svg';
import { StringParseWithSignToArray } from "extensions/StringParseWithSignToArray";
import ConvertStringToFloat from "extensions/ConvertStringToFloat";
import { connect } from "react-redux";
import NotPermission from "pages/NotPermission";

const PatientAccount = (props) => {
    const {auth} = props;
    const can = (permission) => {
       return (auth.user.user_permissions || []).find((p)=> p === permission) ? true: false;
    }

    const params = useParams();
    const MySwal = withReactContent(Swal);

    const [patientId, setPatientId] = useState(params?.patientId ? params.patientId : 0);
    const [paymentMade, setPaymentMade] = useState([]);
    const [totalDebt, setTotalDebt] = useState(0);
    const [remainingDebt, setRemainingDebt] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [overpaid, setOverpaid] = useState(0);
    const [updatePatientsMovementsTable, setUpdatePatientsMovementsTable] = useState(false);

    const [doktors, setDoktors] = useState([]);
    const [doktorValue, setDoktorValue] = useState(null);
    const [doktorInputValue, setDoktorInputValue] = useState('');

    const [processId, setProcessId] = useState(0);

    //send waiting for response
    const [isProcesssing, setIsProcessing] = useState(false);

    const [filteredTreatments, setFilteredTreatments] = useState([]);
    const [filteredPaymentMade, setFilteredPaymentMade] = useState([]);
    const [loadingTreatments, setLoadingTreatments] = useState(true);
    const [loadingPaymentMade, setLoadingPaymentMade] = useState(true);
    //yapilan tedaviler
    const [patientTreatments, setPatientTreatments] = useState([]);

    // modal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);



    const initialPaymnetInfo = {
        aciklama: '',
        doktor_id: 0,
        tutar: 0,
        odeme_sekli: 1,
        tarih: new Date()
    };

    // yup schema
    let patientPaymentValidationSchema = CreatePatientAccountPaymentValidationSchema();
    // formik schema
    const formik = useFormik({
        initialValues: initialPaymnetInfo,
        validationSchema : patientPaymentValidationSchema,
        onSubmit: values => {
            values.tarih = GetDateWithFormat(values?.tarih);
            values.doktor_id = doktorValue?.id;
            values.hasta_id = patientId;
            setIsProcessing(true);
            if(patientId > 0 && patientId !== null){
                if(processId === 0){
                    AxiosCrudActions.CreateAsync('hasta-hesaplari', values).then(result => {
                        setIsProcessing(false); 
                        if (result?.data?.status) {
                            setUpdatePatientsMovementsTable(!updatePatientsMovementsTable);
                            toggle();
                        }
                    });
                }else{
                    AxiosCrudActions.UpdateAsync(`hasta-hesaplari/${processId}`, values).then(result => {
                        setIsProcessing(false);
                        if (result?.data?.status) {
                            setUpdatePatientsMovementsTable(!updatePatientsMovementsTable);
                            toggle();
                        }
                    });
                }
            }else{
                MySwal.fire({
                    title: "Hata",
                    text: "Hatalı parametre kullanımı. İşleminiz gerçekleştirilemedi.",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Evet, İşaretle',
                    cancelButtonText: 'Hayır,Vazgeç'
                })
            }
        },
    });

    const addNewPayment = (event) => {
        event.preventDefault();
        formik.setValues({
            aciklama: '',
            doktor_id: 0,
            tutar: 0,
            odeme_sekli: 1,
            tarih: new Date()
        }, false)
        setProcessId(0);
        formik.setErrors({});
        toggle();
    }

    const editPayment = (e, value = null) => {
        e.preventDefault();
        if(value !== null){
            formik.setValues({
                aciklama: value?.aciklama ? value.aciklama : '',
                doktor_id: value?.doktor?.id ? value.doktor.id : 0,
                tutar: value?.tutar ? ConvertStringToFloat(value.tutar) : 0,
                odeme_sekli: value?.odeme_sekli?.id ? value.odeme_sekli.id : 1,
                tarih: value?.tarih ? value.tarih : new Date()
            }, true);
            setDoktorValue(value?.doktor);
            setProcessId(value?.id)
            formik.setErrors({});
            toggle();
        }
    }

    const deletePayment = (e, id = null) => {
        e.preventDefault();
        e.stopPropagation()
        if(id !== null && id > 0){
            MySwal.fire({
                title: "Kayıt silinsin mi?",
                text: "silme işlemini gerçekleştirdiğinizde geri alamazsınız!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Hayır,Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    AxiosCrudActions.DeleteAsync('/hasta-hesaplari', id)
                        .then(result => {
                            if (result?.status === 200 && result?.data?.status) {
                                setUpdatePatientsMovementsTable(!updatePatientsMovementsTable);
                            }
                        });

                }
            })
        }
    }

    const getPatientsTreatments = async () => {
        if(patientId !== null && patientId > 0){
            await axios.get(`yapilan-tedaviler/${patientId}`)
                            .then(res => {
                                if(res?.status === 200 ){
                                    setPatientTreatments(res?.data?.data);
                                    setFilteredTreatments(res?.data?.data);
                                }
                            }).catch(e => {
                                setPatientTreatments([]);
                                setFilteredTreatments([]);
                                setLoadingTreatments(false);
                            })
        }
        setLoadingTreatments(false);
    }
   

    const getPatientAccountMovements = async () => {
        if(patientId !== null && patientId > 0){
            await axios.get('hasta-hesaplari', {params: {hasta_id : patientId}})
                            .then(res => {
                                if(res?.status === 200 ){
                                    setPaymentMade(res?.data?.data)
                                    setTotalDebt(res?.data?.toplamTedaviTutari);
                                    setRemainingDebt(res?.data?.kalanBorc);
                                    setTotalPaid(res?.data?.alinanOdemeler);
                                    setOverpaid(res?.data?.fazlaOdenen);
                                    setFilteredPaymentMade(res?.data?.data);
                                }
                        }).catch(e => {
                                setPaymentMade([])
                                setTotalDebt(0);
                                setRemainingDebt(0);
                                setTotalPaid(0);
                                setOverpaid(0);
                                setFilteredPaymentMade([]);
                        })
        }
        setLoadingPaymentMade(false);
    }

    const getDoktors = async () => {
        await axios.get('kullanicilar', {params: {doktor: 1}})
                    .then(res => {
                        if(res?.status === 200 ){
                            setDoktors(res?.data?.data);
                            if(res?.data?.data && res.data.data[0] !== undefined && res.data.data[0] !== null){
                                setDoktorValue(res.data.data[0]);
                            }
                        }
                    }).catch(e => {
                        setDoktors([]);
                    })
    }

    useEffect(() => {
        getPatientsTreatments();
        getDoktors();
    }, []);

    useEffect(() => {
        getPatientAccountMovements();
    },[updatePatientsMovementsTable]);
    

    return (
            <TabPanel value="2">
                {
                    isProcesssing ? <div style={{width: '100%',height:'100vh', position: 'fixed',top:'0',left:'0',bottom:'0',right:'0', overflow:"hidden", zIndex:99999,background:'transparent'}}></div>: null
                }
                <div className="patient-detail-container">
                    <div className="row">
                        {/* Yapilan tedaviler */}
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                            { can('view-hastalar-hesap') ?
                                <>
                                <div className="d-flex justify-content-between align-items-center mb-2" style={{borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px'}}>
                                    <span className="d-flex align-items-center" style={{fontSize: '14px',fontWeight: 'bold', color: '#1172b6', opacity: '0.8'}}>
                                        <strong>
                                            Yapılan Tedaviler  &nbsp;
                                        </strong>
                                    </span>
                                </div>
                                <div className="mb-1">
                                    {
                                        remainingDebt !== null && !remainingDebt.toString().includes("-") &&
                                        <div className="total-remaining-info-btn m-1">
                                            <span>Kalan Borç:&nbsp;</span>
                                            <span>{remainingDebt}</span>
                                            <span> TRY</span>
                                        </div>
                                    }
                                    <div className="total-pay-info-btn m-1">
                                        <span>Toplam Tutar:&nbsp;</span>
                                        <span>{totalDebt} TRY</span>
                                    </div>
                                </div>
                                </>
                                :''}
                                {/* table */}
                                {
                                    useMemo(() =>
                                        can('view-hastalar-hesap') ? 
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 750}} size="small">
                                                    <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" width='15%'>Tarih</TableCell>
                                                        <TableCell align="center" width='5%'>Diş</TableCell>
                                                        <TableCell align="center" width='40%'>Tedavi</TableCell>
                                                        <TableCell align="center" width='20%'>Doktor</TableCell>
                                                        <TableCell align="center" width='20%'>Toplam</TableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {
                                                    !loadingTreatments ? 
                                                    filteredTreatments?.length > 0 ? 
                                                    patientTreatments.map((row) => (
                                                        <TableRow key={row.id} 
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } , '& td, & tr': { color: !row?.fatura_kesildi ? '#000000de': '#ff9800' , fontSize: '12px', fontWeight: 'normal'}}}>
                                                            <TableCell align="center" width='15%'>{GetTrDate(row?.tarih)}</TableCell>
                                                            <TableCell align="center" width='5%'>
                                                                {
                                                                    StringParseWithSignToArray(row?.disler, '-').length > 1 
                                                                    ?
                                                                    <Tooltip title={row?.disler} arrow>
                                                                        <img src={row?.konum === 1 ? TopChinImg : BottomChinImg}  alt="teeth" />
                                                                    </Tooltip>
                                                                    :
                                                                    row?.disler
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center" width='40%'>{row?.tedavi + (row?.kokler ? ' - '+row.kokler : '')}</TableCell>
                                                            <TableCell align="center" width='20%'>{row?.doktor?.name}</TableCell>
                                                            <TableCell align="center" width='20%'>{row?.toplam} TRY</TableCell>
                                                        </TableRow>
                                                    
                                                    )):
                                                    <TableRow width="100%">
                                                        <TableCell colSpan={5} height={"100px"} align="center">
                                                            <CustomNoRowsOverlay />
                                                        </TableCell>
                                                    </TableRow> 
                                                    :
                                                    <TableRow width="100%">
                                                        <TableCell colSpan={5} height={"100px"} align="center">
                                                            <LoadingOverlay />
                                                        </TableCell>
                                                    </TableRow>
                                                    }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        :<NotPermission/>
                                    ,[patientTreatments])
                                }
                            </Card>
                        </div>
                        {/* Alinan odemeler */}
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <Card sx={{ width: "100%" }} className="cstm-border-top card p-3 mb-3">
                             
                                { can('view-hastalar-hesap') ?
                                <><div className="d-flex justify-content-between align-items-center mb-2" style={{ borderBottom: '1px dashed #cbcbcb', paddingBottom: '10px' }}>
                                    <span className="d-flex align-items-center" style={{ fontSize: '14px', fontWeight: 'bold', color: '#1172b6', opacity: '0.8' }}>
                                        <strong>
                                            Alınan Ödemeler  &nbsp;
                                        </strong>
                                    </span>
                                    <span>
                                        <ButtonM color="success" variant="outlined" disabled={! can('create-hastalar-hesap')} onClick={addNewPayment} startIcon={<FaPlus />} sx={{ marginTop: '5px' }}>
                                            Ödeme
                                        </ButtonM> &nbsp;
                                    </span>
                                    <Modal isOpen={modal}
                                        toggle={toggle}
                                        size="md"
                                        modalTransition={{ timeout: 1 }}
                                        backdropTransition={{ timeout: 1 }}
                                    >
                                        <ModalHeader toggle={toggle} style={{ color: "#1172b6", fontWeight: 200 }}>Ödeme Bilgileri</ModalHeader>
                                        <form onSubmit={formik.handleSubmit}>
                                            <ModalBody className="d-flex justify-content-center">
                                                <div className="row col-11 mt-3">
                                                    <div className="pb-4">
                                                        <FormControl fullWidth>
                                                            <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns}>
                                                                <DesktopDatePicker
                                                                    variant='outlined'

                                                                    minDate={dayjs('01-01-1930')}
                                                                    inputFormat="dd-MM-yyyy"
                                                                    value={formik.values.tarih}
                                                                    onChange={(newValue) => {
                                                                        formik.setFieldValue('tarih', newValue, true);
                                                                    } }
                                                                    name="tarih"
                                                                    size="small"
                                                                    renderInput={(params) => <TextField {...params}
                                                                        label="Tarih"
                                                                        InputLabelProps={{ style: { color: '#1976d2' } }}
                                                                        variant='outlined'
                                                                        error={formik.errors.tarih && formik.touched.tarih}
                                                                        helperText={formik.errors.tarih && formik.touched.tarih && <span className='cstm-input-error-validation-msg'>{formik.errors.tarih}</span>} />} />
                                                            </LocalizationProvider>
                                                        </FormControl>
                                                    </div>
                                                    <div className="pb-4">
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                name='odeme_sekli'
                                                                select
                                                                InputLabelProps={{ style: { color: '#1976d2' } }}
                                                                label="Ödeme Şekli"
                                                                size='small'
                                                                defaultValue={formik.values?.odeme_sekli ? formik.values.odeme_sekli : 1}
                                                                value={formik.values?.odeme_sekli ? formik.values.odeme_sekli : 1}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}>
                                                                <MenuItem value={1}>Nakit</MenuItem>
                                                                <MenuItem value={2}>Kredi Kartı</MenuItem>
                                                                <MenuItem value={3}>Havale/EFT</MenuItem>
                                                            </TextField>
                                                        </FormControl>
                                                    </div>
                                                    <div className="pb-4">
                                                        <FormControl fullWidth>
                                                            <TextField className='form-group'
                                                                type="text"
                                                                name="tutar"
                                                                variant='outlined'
                                                                label="Tutar"
                                                                autoComplete="off"
                                                                InputLabelProps={{ style: { color: formik.errors.tutar && formik.touched.tutar ? '#e53e3e' : '#1976d2' } }}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.tutar}
                                                                onBlur={formik.handleBlur}
                                                                size='small'
                                                                InputProps={{
                                                                    inputComponent: CurrencyMask,
                                                                    startAdornment: <InputAdornment position="start"><FaLiraSign /></InputAdornment>
                                                                }}
                                                                error={formik.errors.tutar && formik.touched.tutar}
                                                                helperText={formik.errors.tutar && formik.touched.tutar && <span className='cstm-input-error-validation-msg'>{formik.errors.tutar}</span>} />
                                                        </FormControl>
                                                    </div>
                                                    <div className="pb-4">
                                                        <FormControl fullWidth>
                                                            <Autocomplete
                                                                id="doktorId"
                                                                options={doktors}
                                                                loading={true}
                                                                getOptionLabel={option => option?.name + ' - ' + option?.brans}
                                                                noOptionsText={'Seçenek Bulunamadı'}
                                                                value={doktorValue}
                                                                label="Doktor"
                                                                disableClearable
                                                                onChange={(event, newValue) => {
                                                                    event.preventDefault();
                                                                    setDoktorValue(newValue);
                                                                } }
                                                                inputValue={doktorInputValue}
                                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                onInputChange={(event, newInputValue) => { setDoktorInputValue(newInputValue); } }
                                                                size="small"
                                                                renderOption={(props, option) => {
                                                                    return (
                                                                        <li {...props} key={option.id}>
                                                                            {option?.name + ' - ' + option?.brans}
                                                                        </li>
                                                                    );
                                                                } }
                                                                renderInput={(params) => <TextField {...params} label="Doktor" />} />
                                                        </FormControl>
                                                    </div>
                                                    <div className="pb-4">
                                                        <FormControl fullWidth>
                                                            <TextField className='form-group'
                                                                type="text"
                                                                name="aciklama"
                                                                variant='outlined'
                                                                label="Açıklama"
                                                                autoComplete="off"
                                                                placeholder="..."
                                                                InputLabelProps={{ style: { color: formik.errors.aciklama && formik.touched.aciklama ? '#e53e3e' : '#1976d2' } }}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.aciklama}
                                                                onBlur={formik.handleBlur}
                                                                id="outlined-start-adornment"
                                                                size='small'
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start"><FaInfoCircle /></InputAdornment>
                                                                }} />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="danger" type="button" onClick={toggle} className="d-flex align-items-center">
                                                    <FaTimes />&nbsp;Vazgeç
                                                </Button>{' '}
                                                <Button color="primary" type="submit" style={{ background: "#1172b6" }} className="d-flex align-items-center">
                                                    {isProcesssing ?
                                                        (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                                                İşleniyor...
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <FaCloudUploadAlt /> &nbsp;
                                                                {processId > 0 ? "Güncelle" : "Kaydet"}
                                                            </>
                                                        )}
                                                </Button>
                                            </ModalFooter>
                                        </form>
                                    </Modal>
                                </div><div className="mb-1">
                                        {overpaid !== null && !overpaid.toString().includes("-") &&
                                            <div className="total-overpaid-info-btn m-1">
                                                <span>Fazla Ödenen:&nbsp;</span>
                                                <span>{overpaid}</span>
                                                <span> TRY</span>
                                            </div>}
                                        <div className="total-pay-info-btn m-1">
                                            <span>Toplam Ödenen:&nbsp;</span>
                                            <span>{totalPaid}</span>
                                            <span> TRY</span>
                                        </div>
                                    </div></>
                                 :''}
                                 
                                {/* table */}
                                {
                                    useMemo(() => 
                                        can('view-hastalar-hesap') ?  
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 750}} size="small">
                                                    <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" width='15%'>Tarih</TableCell>
                                                        <TableCell align="center" width='20%'>Ödeme Türü</TableCell>
                                                        <TableCell align="center" width='20%'>Doktor</TableCell>
                                                        {/* <TableCell align="center" width='20%'>Açıklama</TableCell> */}
                                                        <TableCell align="center" width='20%'>Toplam</TableCell>
                                                        <TableCell align="center" width='25%'>İşlem</TableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {
                                                    !loadingPaymentMade?
                                                    filteredPaymentMade?.length > 0 ? 
                                                    paymentMade.map((row) => (
                                                        <TableRow key={row.id} 
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } , '& td, & tr': {fontSize: '12px', fontWeight: 'normal'}}}>
                                                            <TableCell align="center" width='15%'>{GetTrDate(row?.tarih)}</TableCell>
                                                            <TableCell align="center" width='15%'>{row?.odeme_sekli?.name}</TableCell>
                                                            <TableCell align="center" width='20%'>{row?.doktor?.name}</TableCell>
                                                            {/* <TableCell align="center" width='20%'>{row?.aciklama}</TableCell> */}
                                                            <TableCell align="center" width='20%'>{row?.tutar} TRY</TableCell>
                                                            <TableCell align="center" width='20%'>
                                                                <Stack direction="row" spacing={2}>
                                                                    <Tooltip title="ödeme düzenle" arrow>
                                                                        <ButtonM variant="outlined" disabled={! can('edit-hastalar-hesap')}
                                                                            onClick={(e) => editPayment(e, row)}
                                                                            color="warning"><FaEdit /></ButtonM>
                                                                    </Tooltip>
                                                                    <Tooltip title="ödeme sil" arrow>
                                                                        <ButtonM variant="outlined"  disabled={! can('delete-hastalar-hesap')}
                                                                            onClick={(e) => deletePayment(e, row?.id)}
                                                                            color="error"><FaTrash /></ButtonM>
                                                                    </Tooltip>
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    )):
                                                    <TableRow width="100%">
                                                        <TableCell colSpan={6} height={"100px"} align="center">
                                                            <CustomNoRowsOverlay />
                                                        </TableCell>
                                                    </TableRow>  
                                                    :
                                                    <TableRow width="100%">
                                                        <TableCell colSpan={6} height={"100px"} align="center">
                                                            <LoadingOverlay />
                                                        </TableCell>
                                                    </TableRow>
                                                    }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        :<NotPermission/>
                                    ,[paymentMade])
                                }
                            </Card>
                        </div>
                    </div>
                </div>
            </TabPanel>
    );
};

const authPermissions = (state) => {
    return {
        auth: state.authState,
    };
};

export default connect(authPermissions)(PatientAccount);
